var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var defaultState = {
        expanded: false,
        isPromoCode: true,
        isCorporateDiscount: false,
        isExpanded: false,
        discount: null,
        applyDisabled: true,
        promoCode: null
    };

    state = state || defaultState;

    switch (action.type) {
        case actionTypes.DISCOUNT_CODES.SET_APPLY_DISABLED:
            return {
                ...state,
                applyDisabled: action.applyDisabled
            };

        case actionTypes.DISCOUNT_CODES.SET_CORPORATE_DISCOUNT:
            return {
                ...state,
                corporateDiscount: action.discount,
                applyDisabled: false,
                promoCode: null
            };

        case actionTypes.DISCOUNT_CODES.WIPEOUT:
            return Object.assign({}, state, defaultState);

        case actionTypes.DISCOUNT_CODES.TOGGLE_DISCOUNT_TYPE:
            var isToggled = action.isToggled,
                isPromoCode = isToggled !== undefined ? !isToggled : !state.isPromoCode,
                isCorporateDiscount = isToggled !== undefined ? isToggled :state.isPromoCode,
                applyDisabled = action.applyDisabled === undefined ? state.applyDisabled : action.applyDisabled;

            return {
                ...state,
                isPromoCode,
                isCorporateDiscount,
                applyDisabled 
            };

        case actionTypes.DISCOUNT_CODES.TOGGLE_EXPAND:
            return {
                ...state,
                isExpanded: action.isExpanded !== undefined ? action.isExpanded : !state.isExpanded
            };

        default:
            return state;
    }
};
