var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Testimonials;

Testimonials = React.createClass({
    componentDidMount: function componentDidMount() {
        var site = this.props.currentCentre;

        if(site && !this.props.staffId) {
            this.fetchTestimonials(site.info.site_id);
        }
    },

    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        var site = nextProps.currentCentre;

        if(site && !this.props.currentCentre && !this.props.staffId) {
            this.fetchTestimonials(site.info.site_id);
        } else if(!site || this.props.staffId) {
            this.props.dispatch(actions.testimonials.wipeout());
        }
    },


    fetchTestimonials: function fetchTestimonials(siteID) {
        var tags = [];

        if (siteID) {
            tags.push('site-' + siteID);
        }

        this.props.dispatch(actions.testimonials.fetchTestimonials(tags));
    },

    render: function render() {

        if (!this.props.currentCentre || !this.props.results || !this.props.results.length || this.props.isRenewals || this.props.isLessons) {
            return null;
        }

        var content = this.props.content,
            results = [],
            testimonial,
            i;

        for (i = 0; i < this.props.results.length; i++) {
            testimonial = this.props.results[i];

            results.push(<li className='testimonials__list-item ' key={'testimonial_' + i}>
                <p className='testimonials__text'>{ testimonial.copy }</p>
                <p className='testimonials__meta'>{ testimonial.author }, { testimonial.location }</p>
            </li>);
        }

        return (
            <section className='sidebar-module'>
                <h3 className='sidebar-module__title'>{ content.title }</h3>
                <ul className='sidebar-module__list'>
                    { results }
                </ul>
            </section>
        );
    }
});

function mapStateToProps(state) {
    return {
        currentCentre: state.centreFinder.selected,
        content: state.app.content.testimonials,
        results: state.testimonials.results,
        staffId: state.staffLogin.staffId
    };
}

module.exports = connect(mapStateToProps)(Testimonials);
