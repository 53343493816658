var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    ConfirmRemoveDiscount;

ConfirmRemoveDiscount = React.createClass({
    _onClose: function _onClose(e) {
        e.preventDefault();

        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    _onConfirm: function _onConfirm(e) {
        e.preventDefault();
        this.props.dispatch(actions.discounts.removeDiscount());
        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    render: function render() {
        var content = this.props.content;

        return <Modal title={ content.title } id={ this.props.id } className='modal--confirm'>
            <p dangerouslySetInnerHTML={ {__html: content.desc } }></p>
            <div className='modal__buttons'>
                <Button className='button button--greyed-out' onClick={ this._onClose }>{ content.buttonCancel }</Button>
                <Button className='button button--primed' onClick={ this._onConfirm }>{ content.buttonYes }</Button>
            </div>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.confirmRemoveDiscount
    };
}

module.exports = connect(mapStateToProps)(ConfirmRemoveDiscount);
