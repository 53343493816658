const InCentrePayment = require("../inCentrePayment/index");

var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions"),
    viewUtils = require("../../utils/viewUtils"),
    Button = require("../common/button"),
    DirectDebitContent = require("./content"),
    DirectDebitPage;

DirectDebitPage = React.createClass({
    displayName: 'DirectDebitPage',

    getInitialState: function() {
        var expanded = 0;

        for (var i = 0; i < this.props.users.length; i++) {
            if (!this.props.users[i].guardian) {
                expanded = i;
                break;
            }
        }

        return {
            error: false,
            expanded: expanded
        };
    },

    _redirectIfPropsNotValid: function _redirectIfPropsNotValid(props) {
        if (!props.userTotal) {
            props.dispatch(actions.stages.goHome());
            props.dispatch(actions.stages.setStep(0));
        }
    },

    componentWillMount: function componentWillMount() {
        this._redirectIfPropsNotValid(this.props);
    },

    componentWillUpdate: function componentWillUpdate(nextProps) {
        this._redirectIfPropsNotValid(nextProps);
    },

    componentDidMount: function componentDidMount() {
        window.scrollTo(0, 0);
        this._handleMoreInfoClick(1);
    },

    _back: function _back() {
        this.props.dispatch(actions.stages.back());
    },

    _print: function _print() {
        window.print();
    },

    _showInstructionModal: function _showInstructionModal(user, key, e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal(key, user));
    },

    _handleMoreInfoClick: function _handleMoreInfoClick(key, e) {
        if (e) {
            e.preventDefault();
        }

        var oldElet = document.querySelector(
                ".direct-debit-page__user-content-" + this.state.expanded
            ),
            elt = document.querySelector(
                ".direct-debit-page__user-content-" + key
            );

        if (this.state.expanded && key === this.state.expanded) {
            this.props.dispatch(actions.directDebitPage.collapseResult(key));
            this.setState({ expanded: null });
            oldElet && viewUtils.furl(oldElet);
        } else {
            this.props.dispatch(actions.directDebitPage.expandResult(key));
            this.setState({ expanded: key });
            oldElet && viewUtils.furl(oldElet);
            elt && viewUtils.furl(elt);
        }
    },

    _next: function _next() {
        if (this.props.bankDetails.paymentUrl) {
            this.props.dispatch(actions.app.showLoader(true));
            window.location = this.props.bankDetails.paymentUrl;
        } else {
            this.setState({ error: true });
            setTimeout(function scrollToError() {
                const el = document.querySelector('.direct-debit-page__error');
                if (!el) {
                    return;
                }
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }, 9);
        }
    },

    render: function() {
        var content = this.props.content,
            title = content.titleConfirmDetails,
            userContent = [],
            debit = "Debit",
            paymentsErrors,
            ddContent = "",
            userButton,
            expanded,
            user,
            i;

        const hasError = this.props.payments.error ||
            this.props.payments.gatewayError ||
            this.state.error;

        if (
            0 === this.props.dt &&
            12 === this.props.d &&
            !this.props.pricing.hasMonthlyFees
        ) {
            this.props.dispatch(actions.stages.goHome());
            this.props.dispatch(actions.stages.setStep(3));
            return null;
        }

        if (hasError) {
            paymentsErrors = (
                <div className="direct-debit-page__error">
                    <h4>Error Processing Payment</h4>
                    <p>{content.errorText}</p>
                </div>
            );
        }

        if (this.props.users.length > 1) {
            debit = "Debits";
        }

        if (this.props.users.length > 1) {
            for (i = 0; i < this.props.users.length; i++) {
                expanded =
                    this.state.expanded && i === this.state.expanded
                        ? true
                        : false;
                user = this.props.users[i];

                if (expanded) {
                    userButton = (
                        <Button
                            key={"ddc_btn_" + i}
                            onClick={this._handleMoreInfoClick.bind(null, i)}
                            className="button__more-info button__more-info--expanded"
                        >
                            {content.infoButton.hide}
                        </Button>
                    );
                } else {
                    userButton = (
                        <Button
                            key={"ddc_btn_" + i}
                            onClick={this._handleMoreInfoClick.bind(null, i)}
                            className="button__more-info"
                        >
                            {content.infoButton.show}
                        </Button>
                    );
                }

                if (
                    user.info.bankAccountReference &&
                    user.info.bankAccountReference.value &&
                    !user.guardian
                ) {
                    userContent.push(
                        <div
                            className="direct-debit-page__user"
                            key={"ddc_" + i}
                        >
                            <div className="direct-debit-page__user-header">
                                <div className="direct-debit-page__user-title">
                                    <h4
                                        className={
                                            "direct-debit-page__user-title-text direct-debit-page__user-title-text--" +
                                            user.typeDesc.toLowerCase()
                                        }
                                    >
                                        {user.info.firstName.value +
                                            " " +
                                            user.info.lastName.value}
                                    </h4>
                                </div>
                                <div className="direct-debit-page__user-button">
                                    {userButton}
                                </div>
                            </div>
                            <div
                                className={
                                    "direct-debit-page__user-content direct-debit-page__user-content-" +
                                    i
                                }
                            >
                                <div className="inner">
                                    <DirectDebitContent
                                        content={this.props.content}
                                        index={i}
                                        user={user}
                                        pricing={this.props.pricing}
                                        startDate={this.props.startDate}
                                        showModal={this._showInstructionModal}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
            }

            ddContent = <div>{userContent}</div>;
        } else {
            ddContent = (
                <DirectDebitContent
                    content={this.props.content}
                    index={i}
                    key={"ddc_" + i}
                    user={this.props.users[0]}
                    pricing={this.props.pricing}
                    startDate={this.props.startDate}
                    showModal={this._showInstructionModal}
                />
            );
        }

        return (
            <div className="page direct-debit-page">
                <div className="container">
                    <nav className="direct-debit-page__nav">
                        <Button
                            className="direct-debit-page__nav-button direct-debit-page__nav-button--print"
                            onClick={this._print}
                        >
                            {content.print}
                        </Button>
                    </nav>
                    <header className="direct-debit-page__header">
                        <h1 className="direct-debit-page__title">{title}</h1>
                        {!this.props.isStaff && (
                            <Button className={'button' + (!hasError ? '' : ' disabled')} onClick={this._next}>
                                {content.payButton.replace("{debit}", debit)}
                            </Button>
                        )}
                    </header>
                    {ddContent}

                    {this.props.isStaff && <InCentrePayment />}

                    {!this.props.isStaff ||
                        ( !this.props.settings.in_centre_payments && (
                            <div className="direct-debit-page__confirmation">
                                <div className="direct-debit-page__instruction">
                                    {content.payConfirmationText.replace(
                                        "{debit}",
                                        debit
                                    )}
                                </div>
                                <Button className="button" onClick={this._next}>
                                    {content.payButton.replace(
                                        "{debit}",
                                        debit
                                    )}
                                </Button>
                            </div>
                        ))}
                    {paymentsErrors}
                    <div className="direct-debit-page__dd-guarantee">
                        <div className="direct-debit-page__dd-guarantee-logo"></div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: content.guaranteeText
                            }}
                        ></p>
                    </div>
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        bankDetails: state.bankDetails,
        content: state.app.content.directDebitPage,
        settings: state.app.settings,
        d: state.selections.duration,
        dt: state.selections.durationType,
        payments: state.payments,
        pricing: state.selections.pricing,
        startDate: state.selections.currentSelectedStartDate,
        users: state.selections.users.objects,
        userTotal: state.selections.users.total,
        isStaff: !!state.staffLogin.staffId
    };
}

module.exports = connect(mapStateToProps)(DirectDebitPage);
