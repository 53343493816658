var validateIsEmail         = require('validator/lib/isEmail'), // eslint-disable-line no-unused-vars
    validateIsMobilePhone   = require('validator/lib/isMobilePhone').default, // eslint-disable-line no-unused-vars
    httpFetch               = require('../utils/httpFetch'),

    config                  = require('../config'),

    exports;

module.exports = exports = {

    isAlpha: function isAlpha(string){
        var alpha = /^[a-zA-Z .'-]+$/i;
        return string && alpha.test(string);
    },

    isBankAccountNumber: function isBankAccountNumber(string) {
        var account = /^[\d]{8}$/;
        return string && account.test(string);
    },

    isBankAccountSortCode: function isBankAccountSortCode(string) {
        var dashed = /^[\d]{2}-[\d]{2}-[\d]{2}$/;
        var nodash = /^[\d]{6}$/;
        var res1 = string && dashed.test(string);
        var res2 = string && nodash.test(string);
        return res1 || res2;
    },

    isBankHolderName: function isBankHolderName(string) {
        return string.length <= 25;
    },

    isEmail: function isEmail(string) {
        return string && validateIsEmail(string);
    },

    isPostCode: function isPostCode(string) {
        return string && !!string.match(/\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i);
    },

    isLandline: function isLandline(string) {
        var landline = /^(01|02|03)((\s*)\d){8,9}$/;
        return string && landline.test(string);
    },

    isMobilePhone: function isMobilePhone(string) {
        return string && validateIsMobilePhone(string, 'en-GB');
    },

    isNumeric: function isNumeric(string){
        var numeric = /^(0|[1-9][0-9]*)$/;
        return string && numeric.test(string);
    },

    isPhone: function isPhone(string) {
        return exports.isMobilePhone(string) || exports.isLandline(string);
    },

    isTrue: function isTrue(string) {
        return string === true || string === 'true';
    },

    validateMaskedBank: function validateMaskedBank (key, value, options) {
        var rules = options.rules || {},
            rule = rules[key],
            valid = true,
            errors = {};

        options = options || {};

        if (rule && (!rule.except || !Object.keys(rule.except).length || !options[Object.keys(rule.except)[0]])) {

            if (rule.required) {
                if (!value) {
                    valid = false;
                    errors.required = true;

                } else {
                    valid = true;
                }
            }
        }

        return Promise.resolve({
            key: key,
            value: value,
            valid: valid,
            validationErrors: errors
        });
    },

    validate: function validate (key, value, options, user) {

        var rules = options.rules || {},
            rule = rules[key],
            valid = true,
            errors = {},

            validatorKey,
            params,
            url;

        options = options || {};

        if(user && user.typeDesc && user.typeDesc.toLowerCase() === 'junior') {
            rule.required = true;
        } else if(user && user.typeDesc && user.typeDesc.toLowerCase() !== 'junior') {
            rule.required = false;
        }

        if (rule && (!rule.except || !Object.keys(rule.except).length || !options[Object.keys(rule.except)[0]])) {

            if (rule.required) {
                if ((!value && value !== 0) || (value < 0) ) {
                    valid = false;
                    errors.required = true;

                } else {
                    valid = true;
                }

            } else if (!value) {
                valid = true;

                return Promise.resolve({
                    key: key,
                    value: value,
                    valid: valid,
                    validationErrors: errors
                });
            }

            if (valid) {
                if (rule.validators) {
                    for (validatorKey in rule.validators) {
                        if (exports[validatorKey] && !exports[validatorKey](value)) {
                            valid = false;
                            errors[validatorKey] = true;

                            return Promise.resolve({
                                key: key,
                                value: value,
                                valid: valid,
                                validationErrors: errors
                            });
                        }
                    }
                }

                const {
                    remoteValidation: {
                        email = false,
                        phone = false,
                        fsgPostcode = false,
                    } = {}
                } = config.disabledFeatures || {};

                if(rule.remoteValidator) {
                    if (!email && rule.remoteValidator.validateEmail) {
                        url = config.services.gbg.urls.validateEmail;
                        params = {
                            email: value
                        };

                    } else if (!phone && rule.remoteValidator.validatePhone) {
                        url = config.services.gbg.urls.validatePhone;
                        params = {
                            number: value,
                            type: 'UNKNOWN'
                        };
                    } else if (!fsgPostcode & rule.remoteValidator.validateFsgPostcode) {
                        url = config.services.fsg.urls.checkPostcode;
                        params = {
                            postCode: value
                        };
                    }

                    if (url) {
                        return httpFetch.fetch(url, {
                            method: 'GET',
                            params: params,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(function(response) {
                            var validationErrors = null;

                            if (response.validationCodes && response.validationCodes.item && response.validationCodes.item.length) {
                                validationErrors = response.validationCodes.item;
                            } else {
                                validationErrors = response.errors;
                            }

                            return {
                                key: key,
                                value: value,
                                valid: response.valid,
                                validationErrors: validationErrors
                            };
                        })
                        .catch(function() {
                            errors.serverError = 'validator unavailable';

                            return {
                                key: key,
                                value: value,
                                valid: valid,
                                validationErrors: errors
                            };
                        });

                    } else {
                        return Promise.resolve({
                            key: key,
                            value: value,
                            valid: valid,
                            validationErrors: errors
                        });
                    }
                }

                if (rule.range) {
                    if (options.range) {
                        if (value < options.range.min || value > options.range.max) {
                            valid = false;
                            errors.outOfRange = true;
                        }
                    } else if (value < 0 || value > 115) {
                        valid = false;
                        errors.outOfRange = true;
                    }
                }
            }
        }

        return Promise.resolve({
            key: key,
            value: value,
            valid: valid,
            validationErrors: errors
        });
    }
};
