var React = require("react"),
    connect = require("react-redux").connect,
    CentreGeoLoc = require("./centreGeoloc"),
    CentreResults = require("./centreResults"),
    cookie = require("react-cookie"),
    actions = require("../../../actions"),
    Button = require("../../common/button"),
    CentreFinder;

import FacilityFilter from "./facility-filter";

const hasSiteIdParam = new URLSearchParams(location.search).has('siteid');

CentreFinder = React.createClass({
    componentDidMount() {
        this.props.dispatch(actions.centreFinder.fetchAvailableFacilities());
    },

    componentWillUnmount() {
        this.props.dispatch(
            actions.centreFinder.clearFacilityFilterSearchTerm()
        );
    },

    _logoutUser: function logoutUser(userId = 0) {
        var self = this;

        this.props.dispatch(actions.user.logout()).then(function() {
            self.props.dispatch(actions.selections.cleanUser(userId));
            self.props.dispatch(actions.personalDetails.unfreeze(userId));
            self.props.dispatch(actions.selections.validating(false));
        });
    },

    render: function() {
        var content = this.props.isBooking
                ? this.props.bookingContent
                : this.props.content[this.props.flow[this.props.showStep]],
            centreFacilities = this.props.centreFacilities,
            user = this.props.user,
            selectionView,
            facilitiesNotice = "",
            loggedInState = "",
            self = this,
            contentTitle = content.title,
            contentDescription = content.description;

        // use pre selected title and description if partner is injected into the URL
        if (this.props.preselectedPartner) {
            contentTitle = this.props.isBooking
                ? this.props.preselectedPartner.response
                      .booking_profile_reg_title
                : this.props.preselectedPartner.response.gym_profile_reg_title;
            contentDescription = this.props.isBooking
                ? this.props.preselectedPartner.response
                      .booking_profile_reg_title
                : this.props.preselectedPartner.response
                      .gym_profile_reg_introductory;
        }

        selectionView = "";
        if (!this.props.staffName || !cookie.load("favouriteCentre")) {
            selectionView = (
                <div className="module-container">
                    <CentreGeoLoc hasSiteIdParam={hasSiteIdParam}>
                        { (this.props.hasResults || this.props.lookupCentreByFacilityNameTerm) && !this.props.isLessons && <FacilityFilter /> }
                    </CentreGeoLoc>
                    <CentreResults
                        isBooking={this.props.isBooking}
                        isGymProfile={this.props.isGymProfile}
                    />
                </div>
            );
        }

        if (user.loggedIn) {
            loggedInState = (
                <div className="module-container">
                    <div className="form__error-zone centre-finder__notice">
                        <span className="form__error ">
                            You are currently logged in as{" "}
                            {user.user.info.firstName} {user.user.info.lastName}{" "}
                            ({user.user.info.email}). Not{" "}
                            {user.user.info.firstName}? Please{" "}
                            <a onClick={() => this._logoutUser()}>logout</a>.
                        </span>
                    </div>
                </div>
            );
        }

        if (centreFacilities.length) {
            var facilitiesList = centreFacilities.join(", ");

            facilitiesNotice = (
                <div className="module-container">
                    <div className="form__error-zone centre-finder__notice">
                        <span className="form__error ">
                            Centres are currently being filtered by the
                            following facilites:{" "}
                            <span className="centre-finder__notice-list">
                                {facilitiesList}
                            </span>
                            <br />
                            <a onClick={self.props.onClearFacilities}>
                                Clear filter
                            </a>
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className="centre-finder">
                <h1 className="content__title">{contentTitle}</h1>
                <div
                    className="content__intro"
                    dangerouslySetInnerHTML={{ __html: contentDescription }}
                ></div>

                {loggedInState}
                {facilitiesNotice}

                {selectionView}
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centreFacilities: state.centreFinder.centreFacilities,
        hasResults: state.centreFinder.results.length > 0,
        content: {
            0: state.app.content.stage1.centreFinder,
            1: state.app.content.stage1.centreFinder,
            2: state.app.content.stage1.centreFinder,
            5: state.app.content.lessons.discover.main,
            6: state.app.content.lessons.levels.main,
            7: state.app.content.lessons.selectLessons.main
        },
        bookingContent: state.app.content.bookingProfile.centreFinder,
        flow: state.stages.flow,
        showStep: state.stages.showStep,
        user: state.user,
        preselectedPartner: state.selections.preselectedPartner,
        isLessons: (state.lessons || {}).inView && (state.lessons || {}).lessonType,
        lookupCentreByFacilityNameTerm: state.centreFinder.lookupCentreByFacilityNameTerm
    };
}

module.exports = connect(mapStateToProps)(CentreFinder);
