var React           = require('react'),
    Button          = require('../common/button'),
    CurrentExtras;

CurrentExtras = React.createClass({

    render: function() {

        if (!this.props.user || !this.props.user.subscriptions.length || this.props.isLessons) {
            return null;
        }

        var content = this.props.content,
            self = this,
            currentSubscriptions = [],
            subscriptions = [],
            subscription,
            i;

        currentSubscriptions = this.props.user.subscriptions.filter(function(s) {
            return self.props.centre.info.site_id === s.SiteID;
        });
        
        if (!currentSubscriptions || !currentSubscriptions.length) {
            return null;
        }
        
        for (i = 0; i < currentSubscriptions.length; i++) {
            subscription = currentSubscriptions[i];
            subscriptions.push(<li key={ 'current_extra_' + i } className="current-extras__list-item">{ subscription.Description }</li>);
        }
        
        return (
            <div className="current-extras">
                <h2 className="module__title">{ content.title.replace('{sitename}', this.props.centre.info.name)}</h2>
                <ul className="current-extras__list">{ subscriptions }</ul>
                <Button className="button current-extras__cancel-button">{ content.buttonCancel }</Button>
            </div>
        );
    }
});

CurrentExtras.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    user: React.PropTypes.object
};

module.exports = CurrentExtras;
