import actions from "./actionTypes";
import config from "../config";

// Used for self reference
let exports = {};

module.exports = exports = {
    /**
     * Handles prefetching of maps subscription data
     */
    prefetch() {
        return (dispatch, getState) => {
            // Maps
            fetch(config.services.subscriptionGroupMappings.urls.get + `?groups=MAP01`)
                .then(response => response.json())
                .then(groups => {
                    dispatch( exports.fill( 'MAP01', groups['subsciptions'] ) ); // @todo fix spelling mistake in the API!
                });

            // Addons
            fetch( config.services.subscriptionGroupMappings.urls.get + `?groups=MAP02` )
                .then( response => response.json() )
                .then( groups => {
                    dispatch( exports.fill( 'MAP02', groups['subsciptions'] ) ); // @todo fix spelling mistake in the API!
                } );
        };
    },

    /**
     * Fills store with applicable subscription ids in relation to a sub-group
     * @param {string} subscriptionGroupName Name of subscription group
     * @param {Array} subscriptions          List of subscriptions
     */
    fill ( subscriptionGroupName, subscriptions) {
        return {
            type: actions.SITE_BANDING.FILL_APPLICABLE_SUBSCRIPTION_GROUPS,
            subscriptionGroupName,
            subscriptions
        }
    },

    /**
     * Toggles the map's visibility
     * @param {boolean} isOn Determines if the map is currently displayed
     */
    toggleMap(isOn) {
        return {
            type: actions.SITE_BANDING.TOGGLE_MAP,
            isOn
        }
    },

    /**
     * Toggles the entire "show more centres" pane's visibility
     * @param {boolean} isOn Determines if the "show more centres" pane is currently displayed
     */
    toggleShowCentres(isOn) {
        return {
            type: actions.SITE_BANDING.TOGGLE_SHOW_CENTRES,
            isOn
        }
    }
};
