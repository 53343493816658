// var exports;

module.exports = exports = {


    /**
     * [trackEvent description]
     * @param  {[type]} data [description]
     * @return {[type]}      [description]
     */
    trackEvent:  function trackEvent(data) {

        if (!data || data === undefined || data === null) {
            return;
        }

        if (!data.category || !data.action) {
            return;
        }

        var eventCategory = data.category || null;
        var eventAction = data.action || null;
        var eventLabel = data.label || null;
        var eventValue = data.value || null;

        // custom dimensions
        var dimension1 = data.dimension1 || null; // centre
        var dimension2 = data.dimension2 || null; // members number
        var dimension3 = data.dimension3 || null; // facilties
        var dimension4 = data.dimension4 || null; // user type
        var dimension5 = data.dimension5 || null; // payment type
        var dimension6 = data.dimension6 || null; // subscription count

        window.ga('send', 'event', eventCategory, eventAction, eventLabel ? eventLabel : null, eventValue ? eventValue : null);

        dimension1 ? window.ga('set', 'dimension5', dimension1) : null;
        dimension2 ? window.ga('set', 'dimension6', dimension2) : null;
        dimension3 ? window.ga('set', 'dimension3', dimension3) : null;
        dimension4 ? window.ga('set', 'dimension4', dimension4) : null;
        dimension5 ? window.ga('set', 'dimension7', dimension5) : null;
        dimension6 ? window.ga('set', 'dimension8', dimension6) : null;

        dimension6 ? window.ga('set', 'metric1', data.dimension6) : null;
    },


    /**
     * [trackPageView description]
     * @param  {[type]} data [description]
     * @return {[type]}      [description]
     */
    trackPageView: function trackPageView(data) {

        if (!data || data === undefined || data === null) {
            return;
        }

        var title = data.title || null;
        // var page = data.page || null;
        var location = data.location || null;

        if (location) {
            location = window.location.protocol + '//' + location;
        }

        window.ga('send', {
            hitType: 'pageview',
            title: title,
            location: location
            //page: page
        });
    },


    /**
     * [trackUserTiming description]
     * @param  {[type]} data [description]
     * @return {[type]}      [description]
     */
    trackUserTiming: function trackUserTiming(data) {

        if (!data || data === undefined || data === null) {
            return;
        }

        var category = data.category || null;
        var timingVar = data.timingVar || null;
        var value = data.value || null;

        window.ga('send', {
            hitType: 'timing',
            timingCategory: category,
            timingVar: timingVar,
            timingValue: value
        });

    }

};
