var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    state = state || {
        user: null,
        loggedIn: false,
        loginTime: null
    };

    switch (action.type) {

        case actionTypes.USER.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: true,
                loginTime: Date.now()
            });

        case actionTypes.USER.LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                user: null,
                loginTime: null,
                loggedIn: false
            });

        case actionTypes.USER.PROFILE_RECEIVED:
            return Object.assign({}, state, {
                user: action.profile
            });
                
        default:
            return state;
    }
};
