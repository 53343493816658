var React = require("react"),
    viewUtils = require("../../../utils/viewUtils"),
    CentreModel = require("../../../models/centre"),
    Button = require("../../common/button"),
    actions = require("../../../actions"),
    utils = require("../../../utils/"),
    FacilitiesPanel;

// Redux
import { connect } from "react-redux";
import Notice from '../../common/notices/notice';
import { requiresProofOfEligibility } from '../../common/proof-of-eligibility';

// Site banding map viewer that deals with displaying tier / banding information
import BandedCentresMapViewer from "../siteBanding/banded-centres-map-viewer";

FacilitiesPanel = React.createClass({
    /**
     * Dispatch new action to retrieve map specific subscription ids
     * @returns void
     */
    componentDidMount() {
        this.props.dispatch(actions.siteBanding.prefetch());
    },

    _showTooltip: function _showTooltip(id, e) {
        e && e.preventDefault();

        var target = e.currentTarget;
        e && e.preventDefault();

        if (!viewUtils.hasClass(target, "facilities-panel__help-icon")) {
            target = target.querySelector(".facilities-panel__help-icon");
        }

        viewUtils.addClass(target, "facilities-panel__help-icon--show-tooltip");
    },

    _hideTooltip: function _hideTooltip(id, e) {
        e && e.preventDefault();

        var target = e.currentTarget;
        e && e.preventDefault();

        if (!viewUtils.hasClass(target, "facilities-panel__help-icon")) {
            target = target.querySelector(".facilities-panel__help-icon");
        }

        viewUtils.removeClass(
            target,
            "facilities-panel__help-icon--show-tooltip"
        );
    },

    _onChangeFacility: function _onChangeFacility(userId, facilityId, e) {
        if (e.target.checked && facilityId !== this.selectedFacility) {
            this.props.onChangeFacility &&
                this.props.onChangeFacility(userId, facilityId, e.target.value);
        }
    },

    _onChangeFacilitySite: function _onChangeFacilitySite(
        userId,
        facilityId,
        e
    ) {
        if (e.target.value !== this.selectedPermittedSites) {
            this.props.onChangeFacilitySite &&
                this.props.onChangeFacilitySite(
                    userId,
                    facilityId,
                    e.target.value
                );
        }
    },

    _onChangeFacilityTime: function _onChangeFacilityTime(
        userId,
        facilityId,
        e
    ) {
        if (e.target.value !== this.selectedPermittedTimes) {
            this.props.onChangeFacilityTime &&
                this.props.onChangeFacilityTime(
                    userId,
                    facilityId,
                    e.target.value
                );
        }
    },

    _handleMoreDetails: function _handleMoreDetails(key, e) {
        e.preventDefault();

        var elt = document.querySelector(
                ".facilities-panel__list-item-details-list-" + key
            ),
            button = document.querySelector(
                ".facilities-panel__list-item-details-button-" + key
            );

        if (button.classList.contains("button__more-info--expanded")) {
            button.classList.remove("button__more-info--expanded");
        } else {
            button.classList.add("button__more-info--expanded");
            elt.style.height = "auto";
        }

        // elt && viewUtils.furl( elt, null, true );
    },

    /**
     * Simply toggles the ability to "view" other centres
     */
    allowShowingMoreCentres() {
        this.props.dispatch(
            actions.siteBanding.toggleShowCentres(
                !this.props.showMoreCentresIsVisible
            )
        );
    },

    render: function() {
        // We need each facility in a list, with different properties
        //  adjusted based on corresponding tags from the user selection.
        // We use "1 month" as the base duration to further filter
        //  the available facilities for the target membership type.
        var smallScreens =
                ["bp-s", '"bp-s"', "bp-sm", '"bp-sm"'].indexOf(
                    this.props.breakpoint
                ) !== -1,
            content = this.props.content,
            centre = this.props.centre,
            user = this.props.user,
            durationType = 0,
            facilities = [],
            staffTags = centre.staffTypes,
            isStaff = this.props.staffId ? true : false,
            duration = 1,
            freeSwimGymTag = "0000-FSG",
            availableSubscriptions,
            linkedMembershipNotice,
            facilitySitesControls,
            facilityTimesControls,
            matchingSubscriptions,
            membershipControls,
            selectedMembership,
            notAvailableToSub,
            permittedSites,
            permittedTimes,
            helpMembership,
            allFacilities,
            sitesOptions,
            timesOptions,
            monthlyDiscount,
            annualDiscount,
            optionClass,
            siteToMatch,
            optionsArea,
            description,
            helpCentre,
            liClasses,
            facility,
            helpTime,
            checked,
            options,
            siteKey,
            timeKey,
            label,
            clash,
            radio,
            name,
            time,
            help,
            site,
            key,
            i,
            j;

        // Can we show the map? Essentially we are checking that the available subscriptions to this user
        // actually contain the sub id that's contained in MAP01
        const canShowMap = user.availableSubscriptions
            .some( subscription =>
                this.props.siteBanding.MAP01.includes( subscription.id )
            );
        // Type of membership (adult, concession etc)
        const doesRequireProofOfEligibility = requiresProofOfEligibility(user);

        // Iterating over each facility.
        for (j = 0; j < centre.permittedFacilitiesTags.length; j++) {
            liClasses = "module__list-item facilities-panel__list-item--" + key;
            optionClass = "";
            key = centre.permittedFacilitiesTags[j].id;

            if (
                !centre.filteredFacilities[user.type] ||
                !centre.filteredFacilities[user.type][key]
            ) {
                continue;
            }

            facility = centre.filteredFacilities[user.type][key];
            facilitySitesControls = [];
            facilityTimesControls = [];
            sitesOptions = [];
            timesOptions = [];
            description = null;
            (helpMembership = null), (linkedMembershipNotice = null);
            (helpCentre = null), (helpTime = null), (help = null);

            options = [];
            checked = false;
            clash = false;
            radio = label = null;

            availableSubscriptions = [];

            if (
                this.props.centre.filteredFacilities[user.type] &&
                this.props.centre.filteredFacilities[user.type][key] &&
                this.props.centre.filteredFacilities[user.type][key][
                    user.facilities[key].permittedSites
                ] &&
                this.props.centre.filteredFacilities[user.type][key][
                    user.facilities[key].permittedSites
                ][user.facilities[key].permittedTimes]
            ) {
                availableSubscriptions = this.props.centre.filteredFacilities[
                    user.type
                ][key][user.facilities[key].permittedSites][
                    user.facilities[key].permittedTimes
                ];
            }

            matchingSubscriptions = CentreModel.filterSubscriptions({
                councilStaff: !!this.props.staffPromo,
                durationType: durationType,
                duration: this.props.promo === "fsg" ? 12 : 1,
                exclude: this.props.promo !== "fsg" ? freeSwimGymTag : null,
                staffTags: staffTags,
                isStaff: isStaff,
                groupRestrictions: this.props.groupRestrictions,
                subscriptions: availableSubscriptions
            });

            // This was added to deal with the issue of displaying addons as main subs
            // now that they are required to be tagged with ADULT etc. and would otherwise render out.
            const addOnTagIds = centre.addonsTags.map(tag => tag.id);
            const subscriptionTags = matchingSubscriptions.reduce(
                (subscriptionTags, matchingSub) => [
                    ...subscriptionTags,
                    ...matchingSub.tags
                ],
                []
            );

            const subscriptionIsAddOn = subscriptionTags.some(r =>
                addOnTagIds.includes(r)
            );

            if (subscriptionIsAddOn) continue;

            if (matchingSubscriptions && matchingSubscriptions.length) {
                // Building the radio buttons only if the user has selected this facility
                if (
                    user.hasMadeSelection &&
                    user.facilities[key] &&
                    user.facilities[key].selected
                ) {
                    liClasses =
                        "module__list-item module__list-item--expanded facilities-panel__list-item--" +
                        key;
                    optionClass = "facilities-panel__options";
                    this.selectedFacility = key;

                    helpMembership = (
                        <div
                            tabIndex="0"
                            className="facilities-panel__help-item"
                            onFocus={this._showTooltip.bind(null, "facilities")}
                            onBlur={this._hideTooltip.bind(null, "facilities")}
                        >
                            <span className="facilities-panel__help-label">
                                <h4>{content.tooltipMemberhsipTitle}</h4>
                            </span>
                            <span
                                className="facilities-panel__help-icon"
                                onMouseEnter={this._showTooltip.bind(
                                    null,
                                    "facilities"
                                )}
                                onMouseLeave={this._hideTooltip.bind(
                                    null,
                                    "facilities"
                                )}
                            ></span>
                            <span className="facilities-panel__help-tooltip">
                                {content.tooltipMemberhsipContent}
                            </span>
                        </div>
                    );

                    helpCentre = (
                        <div
                            tabIndex="0"
                            className="facilities-panel__help-item"
                            onFocus={this._showTooltip.bind(null, "location")}
                            onBlur={this._hideTooltip.bind(null, "location")}
                        >
                            <span className="facilities-panel__help-label">
                                <h4>{content.tooltipCentresTitle}</h4>
                            </span>
                            <span
                                className="facilities-panel__help-icon"
                                onMouseEnter={this._showTooltip.bind(
                                    null,
                                    "location"
                                )}
                                onMouseLeave={this._hideTooltip.bind(
                                    null,
                                    "location"
                                )}
                            ></span>
                            <span className="facilities-panel__help-tooltip">
                                {content.tooltipCentresContent}
                            </span>
                        </div>
                    );

                    helpTime = (
                        <div
                            tabIndex="0"
                            className="facilities-panel__help-item"
                            onFocus={this._showTooltip.bind(null, "times")}
                            onBlur={this._hideTooltip.bind(null, "times")}
                        >
                            <span className="facilities-panel__help-label">
                                <h4>{content.tooltipTimesTitle}</h4>
                            </span>
                            <span
                                className="facilities-panel__help-icon"
                                onMouseEnter={this._showTooltip.bind(
                                    null,
                                    "times"
                                )}
                                onMouseLeave={this._hideTooltip.bind(
                                    null,
                                    "times"
                                )}
                            ></span>
                            <span className="facilities-panel__help-tooltip">
                                {content.tooltipTimesContent}
                            </span>
                        </div>
                    );

                    if (smallScreens) {
                        help = null;
                    } else {
                        help = (
                            <div className="facilities-panel__help">
                                {helpMembership}
                                {helpCentre}
                                {helpTime}
                            </div>
                        );
                    }

                    // if the user has made a selection for sites for this facility
                    siteToMatch =
                        user.facilities[key] &&
                        user.facilities[key].permittedSites;
                    // if the user has made a selection for sites for this facility
                    // timeToMatch = user.facilities[key] && user.facilities[key].permittedTimes;
                    for (siteKey in facility) {
                        if (!sitesOptions.includes(siteKey)) {
                            sitesOptions.push(siteKey);
                        }

                        if (siteToMatch && facility[siteToMatch]) {
                            for (timeKey in facility[siteToMatch]) {
                                if (!timesOptions.includes(timeKey)) {
                                    timesOptions.push(timeKey);
                                }
                            }
                        }
                    }

                    if (!timesOptions.length) {
                        for (timeKey in facility[siteKey]) {
                            if (!timesOptions.includes(timeKey)) {
                                timesOptions.push(timeKey);
                            }
                        }
                    }

                    permittedSites = utils.getFacilitiesObjectsFromIds(
                        centre.permittedSites,
                        sitesOptions
                    );
                    permittedTimes = utils.getFacilitiesObjectsFromIds(
                        centre.permittedTimes,
                        timesOptions
                    );

                    // Building permitted sites
                    for (i = 0; i < permittedSites.length; i++) {
                        site = permittedSites[i];
                        checked = false;

                        if (
                            user.facilities[key] &&
                            user.facilities[key].selected &&
                            site.id === user.facilities[key].permittedSites
                        ) {
                            this.selectedPermittedSites = site.id;
                            checked = true;
                        }

                        name = this.props.id + "_centres_scope_" + key;

                        radio = (
                            <input
                                key={"centre_radio_" + key + "_" + i}
                                className="forms__input--radio"
                                type="radio"
                                onChange={this._onChangeFacilitySite.bind(
                                    null,
                                    this.props.index,
                                    key
                                )}
                                name={name}
                                id={name + "_" + i}
                                value={site.id}
                                checked={checked}
                            />
                        );
                        label = (
                            <label
                                key={"centre_label_" + key + "_" + i}
                                htmlFor={name + "_" + i}
                            >
                                <span></span>
                                <div dangerouslySetInnerHTML={ { __html: site.desc } }></div>

                                &nbsp;
                                {/* Are we allowed to show the "show me more centres" button? */}
                                {/* Essentially, this will allow the customer to view the "other" centres to entice them into upgrading */}
                                {/* By default we actually hide this view because we don't believe the traffic will be great */}
                                {canShowMap ? (
                                    <a
                                        href="#!"
                                        onClick={this.allowShowingMoreCentres}
                                        key="showMeMoreCentres"
                                    >
                                        {
                                            this.props.siteBandingContent
                                                .showMeMoreCentres
                                        }
                                    </a>
                                ) : null}
                            </label>
                        );

                        options.push(
                            <li key={"r_sites_" + key + "_" + i}>
                                {radio}
                                {label}
                            </li>
                        );
                    }

                    facilitySitesControls = (
                        <div className="facilities-panel__list-section">
                            {helpCentre}
                            <ul className="facilities-panel__list-item-column facilities-panel__options">
                                {options}
                            </ul>
                        </div>
                    );

                    // Building permitted times
                    options = [];

                    for (i = 0; i < permittedTimes.length; i++) {
                        time = permittedTimes[i];
                        checked = false;

                        if (
                            user.facilities[key] &&
                            user.facilities[key].selected &&
                            time.id === user.facilities[key].permittedTimes
                        ) {
                            this.selectedPermittedTimes = time.id;
                            checked = true;
                        }

                        name = this.props.id + "_times_scope_" + key;

                        radio = (
                            <input
                                key={"time_radio_" + key + "_" + i}
                                className="forms__input--radio"
                                type="radio"
                                onChange={this._onChangeFacilityTime.bind(
                                    null,
                                    this.props.index,
                                    key
                                )}
                                name={name}
                                id={name + "_" + i}
                                value={time.id}
                                checked={checked}
                            />
                        );
                        label = (
                            <label
                                key={"time_label_" + key + "_" + i}
                                htmlFor={name + "_" + i}
                            >
                                <span></span>
                                {time.desc}
                            </label>
                        );

                        options.push(
                            <li key={"r_times_" + key + "_" + i}>
                                {radio}
                                {label}
                            </li>
                        );
                    }

                    facilityTimesControls = (
                        <div className="facilities-panel__list-section">
                            {helpTime}
                            <ul className="facilities-panel__list-item-column facilities-panel__list-item-column--times facilities-panel__options">
                                {options}
                            </ul>
                        </div>
                    );

                    checked = true;

                    var currentSub = null;

                    for (var x = 0; x < matchingSubscriptions.length; x++) {
                        var matchingSub = matchingSubscriptions[x];
                        var findSub = user.availableSubscriptions.find(function(sub) {
                            return sub.id === matchingSub.id;
                        });

                        if (findSub && findSub !== null) {
                            currentSub = findSub;
                        }

                        if (currentSub && currentSub.duration === this.props.duration) {
                            break;
                        }
                    }

                    var discountCopy = null;

                    // Pre-configured discount
                    if (currentSub && currentSub.discountDesc) {
                        const { duration, durationType } = currentSub;
                        const isMonthly = durationType === 0 && duration === 1;
                        const regularPrice = Object.entries(currentSub.price)
                            .sort()[0][1]
                            .find(x => x.desc === "regular");
                        const {
                            amountInPence,
                            discount: {
                                priceBeforeDiscountInPence,
                                discountValueInPence
                            }
                        } = regularPrice;

                        const originalPrice = utils.formatPrice(
                            priceBeforeDiscountInPence
                        );
                        const discountPrice = utils.formatPrice(amountInPence);
                        const savePrice = utils.formatPrice(
                            discountValueInPence
                        );

                        discountCopy = (
                            <div className="facilities-panel__notice">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: currentSub.discountDesc
                                    }}
                                />
                                <div className="facilities-panel__discount">
                                    <div className="facilities-panel__discount__row">
                                        <span>Price before discount:</span>
                                        &nbsp;<strong>{originalPrice}</strong>
                                    </div>
                                    <div className="facilities-panel__discount__row">
                                        <span>After discount:</span>
                                        &nbsp;<strong>{discountPrice}</strong>
                                        <span />
                                    </div>
                                    <div className="facilities-panel__discount__row--big">
                                        <span>You save:</span>
                                        &nbsp;<strong>{savePrice}</strong>
                                        &nbsp;
                                        {isMonthly ? <em>per month</em> : ""}
                                    </div>
                                </div>
                            </div>
                        );
                    } else if (
                            currentSub.price.now.some( subscription => {
                                // The list price must be zero
                                return subscription.amountInPence == 0.00 &&
                                    // There must be no preconfigued dsicounts
                                    !subscription.discountDesc &&

                                    // Must match "Admin fee"
                                    /Admin Fee/i.test( subscription.desc );
                            } )
                        ) {
                            // Free items!
                            discountCopy = (
                                <div className="facilities-panel__notice">
                                    <div
                                        dangerouslySetInnerHTML={ {
                                            __html: this.props.freeItemWording
                                        } }
                                    ></div>
                                </div>
                            );
                        }
                    //check if this sub has linked member subs discount available
                    if (
                        currentSub &&
                        user.linkedMemberDiscount &&
                        (user.linkedMemberDiscount.annual ||
                            user.linkedMemberDiscount.monthly)
                    ) {
                        annualDiscount = utils.formatPrice(
                            user.linkedMemberDiscount.annual
                        );
                        monthlyDiscount = utils.formatPrice(
                            user.linkedMemberDiscount.monthly
                        );

                        linkedMembershipNotice = (
                            <div className="facilities-panel__notice">
                                <p>{content.linkedMembershipNotice}</p>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: content.linkedMembershipNoticeAnnual.replace(
                                            "{price}",
                                            annualDiscount
                                        )
                                    }}
                                ></p>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: content.linkedMembershipNoticeMonthly.replace(
                                            "{price}",
                                            monthlyDiscount
                                        )
                                    }}
                                ></p>
                            </div>
                        );
                    }

                    description = (
                        <div className="facilities-panel__list-item-details">
                            <h5 className="facilities-panel__list-item-details-title">
                                {content.whatYoullGet}
                            </h5>
                            <Button
                                key={"btn-info-" + key}
                                className={
                                    "button__more-info button__more-info--expanded contextual-information facilities-panel__list-item-details-button-" +
                                    (this.props.id + "_" + key)
                                }
                                onClick={this._handleMoreDetails.bind(
                                    null,
                                    this.props.id + "_" + key
                                )}
                            >
                                {content.whatYoullGetShow}
                            </Button>
                            <div
                                className={
                                    "facilities-panel__list-item-details-list facilities-panel__list-item-details-list-" +
                                    (this.props.id + "_" + key)
                                }
                            >
                                <ul className="inner contextual-information">
                                    <li>
                                        <b>{content.facilities}:</b>{" "}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.centre
                                                    .permittedFacilities[key]
                                                    .comment
                                            }}
                                        ></span>
                                    </li>
                                    <li>
                                        <b>{content.centre}:</b>{" "}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.centre
                                                    .permittedSites[
                                                    user.facilities[key]
                                                        .permittedSites
                                                ].comment
                                            }}
                                        ></span>
                                        &nbsp;
                                        {/* Are we allowed to show the "show me more centres" button? */}
                                        {/* Essentially, this will allow the customer to view the "other" centres to entice them into upgrading */}
                                        {/* By default we actually hide this view because we don't believe the traffic will be great */}
                                        {canShowMap ? (
                                            <div className="facilities-panel__notice facilities-panel__notice--map">
                                                <div>
                                                    <a
                                                        href="#!"
                                                        onClick={
                                                            this
                                                                .allowShowingMoreCentres
                                                        }
                                                        key="showMeMoreCentres"
                                                    >
                                                        {
                                                            this.props
                                                                .siteBandingContent
                                                                .showMeMoreCentres
                                                        }
                                                    </a>{" "}
                                                    or your upgrade options to
                                                    include more.
                                                </div>
                                            </div>
                                        ) : null}
                                    </li>
                                    <li>
                                        <b>{content.times}:</b>{" "}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.centre
                                                    .permittedTimes[
                                                    user.facilities[key]
                                                        .permittedTimes
                                                ].comment
                                            }}
                                        ></span>
                                    </li>
                                    <li>
                                        { this.props.doesRequireProofOfEligibility(this.props.user) && this.props.concessionMessage && <Notice message={ this.props.concessionMessage } /> }
                                    </li>
                                </ul>

                                {/* Centre banding that sits just outside facility information */}
                                {canShowMap &&
                                this.props.showMoreCentresIsVisible
                                    ? [
                                          <BandedCentresMapViewer
                                              key={
                                                  this.props.centre.info.site_id
                                              }
                                              selectedCentre={
                                                  this.props.centre.info
                                              }
                                          />
                                      ]
                                    : null}
                            </div>

                            {discountCopy}
                        </div>
                    );
                } else {
                    checked = false;
                }

                let clashingSubs = user.activeSubscriptions.filter(
                    activeSub => {
                        return user.availableSubscriptions.some(
                            sub =>
                                sub.id.toLowerCase() ===
                                activeSub.SubTypeId.toLowerCase()
                        );
                    }
                );

                if (clashingSubs.length) {
                    console.debug(
                        "active sub clashing with selected subs",
                        clashingSubs
                    );
                }

                // //check if user already has this subscription applied
                // if(user.activeSubscriptions && user.activeSubscriptions.length) {
                //     for(var l = 0; l < user.activeSubscriptions.length; l++) {
                //         for(var m = 0; m < centre.permittedFacilities[key].subs.length; m++) {
                //             if(user.activeSubscriptions[l].SubTypeId.toLowerCase() === centre.permittedFacilities[key].subs[m].id.toLowerCase()) {
                //                 clash = true;

                //                 break;
                //             }
                //         }
                //     }

                //     if(clash) {
                //         notAvailableToSub = <span className='form__error'>{ content.userAlreadyHasSub }</span>;
                //     } else {
                //         notAvailableToSub = '';
                //     }
                // }

                if (smallScreens) {
                    optionsArea = (
                        <div className="facilities-panel__options-area">
                            {helpMembership}
                            <div
                                className={
                                    "facilities-panel__list-item-column facilities-panel__list-item-column--parent " +
                                    optionClass
                                }
                            >
                                <input
                                    type="radio"
                                    className="forms__input--checkbox"
                                    onChange={this._onChangeFacility.bind(
                                        null,
                                        this.props.index,
                                        key
                                    )}
                                    name={this.props.id}
                                    id={this.props.id + "_" + key}
                                    checked={checked}
                                    disabled={clash}
                                />
                                <label htmlFor={this.props.id + "_" + key} dangerouslySetInnerHTML={{
                                    __html: `<span></span>${centre.permittedFacilities[key].desc}`
                                }}>
                                </label>
                                {notAvailableToSub}
                            </div>
                            <div className="facilities-panel__list-section-container">
                                {facilitySitesControls}
                                {facilityTimesControls}
                            </div>
                            {linkedMembershipNotice}
                        </div>
                    );
                } else {
                    optionsArea = (
                        <div>
                            <div className="facilities-panel__list-section">
                                {helpMembership}
                                <div
                                    className={
                                        "facilities-panel__list-item-column " +
                                        optionClass
                                    }
                                >
                                    <input
                                        type="radio"
                                        className="forms__input--checkbox"
                                        onChange={this._onChangeFacility.bind(
                                            null,
                                            this.props.index,
                                            key
                                        )}
                                        name={this.props.id}
                                        id={this.props.id + "_" + key}
                                        checked={checked}
                                        disabled={clash}
                                    />
                                    <label htmlFor={this.props.id + "_" + key}>
                                        <span></span>
                                        <div dangerouslySetInnerHTML={{__html: centre.permittedFacilities[key].desc.replace(/<span\b[^>]*>(.*?)<\/span>/gi, '$1')}}></div>
                                    </label>
                                    {notAvailableToSub}
                                </div>
                            </div>
                            {facilitySitesControls}
                            {facilityTimesControls}
                            {linkedMembershipNotice}
                        </div>
                    );
                }

                facilities.push(
                    <li key={key} className={liClasses}>
                        {help}
                        {optionsArea}
                        {description}
                    </li>
                );
            }
        }

        if (facilities.length) {
            allFacilities = (
                <ul className="module__list facilities-panel__list">
                    {facilities}
                </ul>
            );
        } else if (!facilities.length && user.type === "freeprofile") {
            allFacilities = (
                <div className="facilities-panel__no-facilities">
                    <p>{content.noFacilitiesForFree}</p>
                </div>
            );

            if (user.guardian) {
                allFacilities = (
                    <div className="facilities-panel__no-facilities">
                        <p>{content.noFacilitiesForGuardian}</p>
                    </div>
                );
            }
        } else {
            allFacilities = (
                <div className="facilities-panel__no-facilities">
                    <p>{content.noFacilitiesForType}</p>
                </div>
            );
        }

        return (
            <div className="facilities-panel__section">
                {membershipControls}
                {allFacilities}
            </div>
        );
    }
});

FacilitiesPanel.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    id: React.PropTypes.string,
    index: React.PropTypes.number,
    breakpoint: React.PropTypes.string,
    groupRestrictions: React.PropTypes.array,
    onChangeFacility: React.PropTypes.func,
    onChangeFacilitySite: React.PropTypes.func,
    onChangeFacilityTime: React.PropTypes.func,
    onShowMore: React.PropTypes.func,
    promo: React.PropTypes.string,
    staffPromo: React.PropTypes.string,
    user: React.PropTypes.object
};

/**
 * Mapping state to props. Mostly used to determine if we "show" the
 * banded centres map viewer in relation to sub ids
 * @param {mixed} state Redux store
 */
const mapStateToProps = state => {
    return {
        freeItemWording: state.app.content.stage1.facilitiesSelection.freeItem,

        // Content
        siteBandingContent: {
            ...state.app.content.siteBanding
        },
        siteBanding: state.siteBanding,

        // Is the "show more centres" currently visible
        showMoreCentresIsVisible: state.siteBanding.showMoreCentresIsVisible,
        renderableCentres: state.centreFinder.results.length,
        concessionMessage: state.app.content.notices.concession.message,

        // Type of membership (adult, concession etc)
        // ONLY execute this in joinonline, not lessons online
        doesRequireProofOfEligibility: user => !state.lessons.inView && requiresProofOfEligibility( user )
    };
};

module.exports = connect(mapStateToProps)(FacilitiesPanel);
