var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/noStagesHeader'),
    Component       = require('../components/confirm');

module.exports = [{

    path: '/confirm',

    handler: function () {
        return {
            noBg: true,
            header: <Header />,
            component: <Component />
        };
    }
}];
