var actionTypes     = require('./actionTypes.js');

module.exports = exports = {
    setPage: function setPage() {
        return {
            type: actionTypes.BOOKING.SET_PAGE
        };
    },

    setGymProfile: function setGymProfile() {
        return {
            type: actionTypes.BOOKING.SET_GYM_PROFILE
        };
    },

    setUrlParams: function setUrlParams(params) {
        return {
            type: actionTypes.BOOKING.SET_URL_PARAMS,
            params: params
        };
    },

    getConcessionOptions: function getConcessionOptions() {
        return {
            type: actionTypes.BOOKING.GET_CONCESSION_OPTIONS
        };
    }
};
