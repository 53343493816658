import React from "react";
import { BrightcovePlayer } from "./brightcove-player";
import { VimeoPlayer } from "./vimeo-player";

// @todo eventually we will be removing this and sticking with Vimeo player
// We can keep these until that time
export class VideoPlayer extends React.Component {
    render () {
        const { video_provider_name } = this.props.video;

        return video_provider_name === 'Brightcove'
            ? <BrightcovePlayer
                video={ this.props.video }
                player_id={ this.props.brightcove_player_id || 'default' }
                account_id="713339181001"
            ></BrightcovePlayer>
            : <VimeoPlayer video={ this.props.video }></VimeoPlayer>;
    }
}