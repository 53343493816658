var centreModel = require('./centre'),
    userModel   = require('./user'),
    viewUtils   = require('../utils/viewUtils'),
    moment      = require('moment'),
    lodash      = require('lodash');
const { duration } = require('moment');
const { textOverrides } = require('../utils/TextOverrides');

module.exports = exports = {
    getSubscriptionsForMembershipType: function getSubscriptionsForMembershipType(options) {
        var subscriptions = [],
            key,
            s;

        for (key in options.subscriptions) {
            s = options.subscriptions[key];

            if (options.durationType === s.durationType && options.duration === s.duration && s.tags.find(function(t) { return t.toLowerCase().match(options.type); })) {
                subscriptions.push(s);
            }
        }

        return subscriptions;
    },

    hasCorporateDiscount: function hasCorporateDiscount(user, duration) {
        var selectedSub = user.availableSubscriptions && user.availableSubscriptions.find(sub => sub.duration === duration);

        if (!selectedSub || selectedSub.discountDesc !== 'Corporate') {
            return false;
        }

        var discountedSub = selectedSub.price.now.find(price => price.discount && (price.discount.discountValueInPence > 0));
        return !!discountedSub;
    },

    setSubscriptionsForUsers: function setSubscriptionsForUsers(options) {
        let store = require('../store');

        if (!options.users.objects.length) {
            return {
                noSubscriptionsFound: true
            };
        }

        // var anyUserHasCorporateDiscount = !!options.users.objects.find(user => this.hasCorporateDiscount(user, options.duration));
        var anyUserHasCorporateDiscount = options.discount && options.discount.type === 'corporate';

        var noSubscriptionsFound = true,
            onlyFreeProfile = userModel.onlyFreeProfile(options.users),
            // hasJuniors = !!options.users.objects.find(function(u) { return /junior/.test(u.type.toLowerCase()); }),
            masterSubsIds = [],
            sortedUsers = [],
            usersSubs = [],
            // matchingSubscription,
            // fullPayingAssigned,
            // usersForInnerLoop,
            // isAdult,
            originalSubs,
            user,
            keys,
            subs,
            i,
            o,
            u,
            j;//,
            // k;

        // get logged in users profile
        // get their relations
        if(options.loggedInUser) {
            var loggedInUserRelations = Object.values(options.loggedInUser.relations || []);
            var loggedInUserSubIds = [];

            //get all subs id from relation subscriptions
            for(i = 0; i < loggedInUserRelations.length; i++) {
                var loggedInSubs = loggedInUserRelations[i].subscriptions;
                for (var d = 0; d < loggedInSubs.length; d++) {
                    loggedInUserSubIds.push(loggedInSubs[d].SubTypeId);
                }
            }
        }


        if(options.fetchedUsers) {
            var fetchedUsersSubIds = [];

            for(var o = 0; o < options.fetchedUsers.length; o++) { // for each fetched user
                if (options.fetchedUsers[o]) {

                    var fetchedUserRelations = options.fetchedUsers[o].relations;

                    if(fetchedUserRelations) {
                        for(var g = 0; g < fetchedUserRelations.length; g++) {
                            var fetchedUserSubs = fetchedUserRelations[g].subscriptions;
                            for (var q = 0; q < fetchedUserSubs.length; q++) {
                                fetchedUsersSubIds.push(fetchedUserSubs[q].SubTypeId);
                            }
                        }
                    }
                }
            }
        }


        // Keep a reference so we can sort without mutating the original users objects
        for (i = 0; i < options.users.objects.length; i++) {
            sortedUsers.push(options.users.objects[i]);
        }

        // This puts juniors and students the start of the array.
        sortedUsers.sort(function(a, b) {
            var aJunior = a.typeDesc.toLowerCase() === 'junior' || a.typeDesc.toLowerCase() === 'student',
                bJunior = b.typeDesc.toLowerCase() === 'junior' || b.typeDesc.toLowerCase() === 'student';

            var aStudent = a.typeDesc.toLowerCase() === 'student',
                bStudent = b.typeDesc.toLowerCase() === 'student';

            if ((aJunior && bJunior) || (aStudent && bStudent)) {
                return 0;
            }

            if (aJunior || aStudent) {
                return -1;
            }

            if (bJunior || bStudent) {
                return 1;
            }

            return 0;
        });

        for (i = 0; i < sortedUsers.length; i++) {
            user = sortedUsers[i];
            subs = [];
            originalSubs = null;

            // LINKABLE     = The ones with discounts
            // NON_LINKABLE = The master subscriptions the linkable ones reference.
            usersSubs[i] = usersSubs[i] || {
                linkable: [],
                nonLinkable: []
            };

            keys = Object.keys(user.facilities);

            for (j = 0; j < keys.length; j++) {
                // Only using the subscriptions corresponding to the
                //  selected membership (all / gym / swim / etc...).
                if (user.facilities[keys[j]].selected &&
                    options.filteredSubscriptions[user.type] &&
                    options.filteredSubscriptions[user.type][keys[j]] &&
                    options.filteredSubscriptions[user.type][keys[j]][user.facilities[keys[j]].permittedSites] &&
                    options.filteredSubscriptions[user.type][keys[j]][user.facilities[keys[j]].permittedSites][user.facilities[keys[j]].permittedTimes]
                ) {
                    subs = subs.concat(options.filteredSubscriptions[user.type][keys[j]][user.facilities[keys[j]].permittedSites][user.facilities[keys[j]].permittedTimes]);
                    originalSubs = subs;
                }
            }

            subs && subs.forEach(function(s) {
                // If this is also a linked membership subscription, save it.
                if (s.linkedMemberRestrictionIds) {
                    usersSubs[i].linkable.push(s);
                } else {
                    usersSubs[i].nonLinkable.push(s);
                }
            });
        }

        // At this point, all user potential subscriptions will have been
        //  filtered by duration, and linkability.

        // If we only have one user, there's no point
        //  in trying to work-out discounts.
        // Or if anyUserHasCorporateDiscount then do not calculate discounts
        if (1 === sortedUsers.length && options.loggedInUser === null && options.fetchedUsers === null) {

            //if is a user on the Lessons journey
            if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                subs = [];

                for(var l in user.lesson.selectedLessons) {
                    var lesson = user.lesson.selectedLessons[l];

                    //if has a subId set
                    if(lesson.subId) {
                        //can they get the discounted lesson sub
                        if(user.lesson.discountUser && lesson.discountSubId) {
                            //does the discounted sub exist at that centre
                            if(options.centreLessons[lesson.discountSubId]) {
                                subs.push(options.centreLessons[lesson.discountSubId]);
                            } else {
                                subs.push(options.centreLessons[lesson.subId]);
                            }
                        } else {
                            subs.push(options.centreLessons[lesson.subId]);
                        }
                    }
                }
            } else {
                subs = centreModel.filterSubscriptions({
                    subscriptions: usersSubs[0].nonLinkable,
                    groupRestrictions: options.groupRestrictions
                });
            }

            sortedUsers[0].availableSubscriptions = subs;

            if (subs && subs.length) {
                noSubscriptionsFound = false;
            }

            return {
                noSubscriptionsFound: onlyFreeProfile ? false : noSubscriptionsFound
            };
        }



        //add logged in user sub ids to mastersubsids array
        if(options.loggedInUser) {
            for (var p = 0; p < loggedInUserSubIds.length; p++) {
                masterSubsIds.push(loggedInUserSubIds[p]);
            }
        }

        // if user fetches their details, also apply subs of relations
        if(options.fetchedUsers && options.fetchedUsers.length) {
            for (var t = 0; t < fetchedUsersSubIds.length; t++) {
                masterSubsIds.push(fetchedUsersSubIds[t]);
            }
        }

        for (i = 0 ; i < sortedUsers.length; i++) {
            user = sortedUsers[i];
            user.availableSubscriptions = [];

            if (
                !usersSubs[i].linkable.length || // User is not linkable, because they have no linkable subs
                anyUserHasCorporateDiscount || // User is not linkable, because they have a corporate code
                // User is not linkable, because they are buying a swimming lesson
                (user.lesson && user.lesson.level) || // Sometimes this is an array, sometimes not - something to do with multilessons?
                (user.lesson && user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length)
            ) {

                //if is a user on the Lessons journey
                if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                    subs = [];

                    for(var l in user.lesson.selectedLessons) {
                        var lesson = user.lesson.selectedLessons[l];

                        //if has a subId set
                        if(lesson.subId) {
                            //can they get the discounted lesson sub
                            if(user.lesson.discountUser && lesson.discountSubId) {
                                //does the discounted sub exist at that centre
                                if(options.centreLessons[lesson.discountSubId]) {
                                    subs.push(options.centreLessons[lesson.discountSubId]);
                                } else {
                                    subs.push(options.centreLessons[lesson.subId]);
                                }
                            } else {
                                subs.push(options.centreLessons[lesson.subId]);
                            }
                        }
                    }
                } else {
                    subs = centreModel.filterSubscriptions({
                        subscriptions: usersSubs[i].nonLinkable,
                        groupRestrictions: options.groupRestrictions
                    });
                }

                let state = store.getState();

                user.availableSubscriptions = subs
                    .filter( state.selections.considerPreselectedSubscriptionsOnly
                        // If we have "considerPreselectedSubscriptionsOnly" then ensure we filter out those that are not preselected
                        ? ( subscription => (state.selections.preselectedSubs || '').indexOf( subscription.id ) >= 0 )

                        // "considerPreselectedSubscriptionsOnly" defaults to allow all
                        : () => true)

                user.linkedMemberDiscount = null;

                if (subs && subs.length) {
                    noSubscriptionsFound = false;
                }

                // Push all those subscriptions IDs as masterSubsIds
                //  so they can be linked TO from other users,
                //  because this is in a loop and masterSubsIds was defined outside it.
                subs.forEach(function(s) {
                    if (!masterSubsIds.includes(s.id)) {
                        masterSubsIds.push(s.id);
                    }
                });

                usersSubs[i].processed = true;

            } else {
                let state = store.getState();

                if (state.selections.preselectedSubs) {
                    user.availableSubscriptions = originalSubs.filter(x=> state.selections.preselectedSubs.indexOf(x.id) >= 0);

                    return {
                        noSubscriptionsFound: false
                    };
                }

                subs = centreModel.filterSubscriptions({
                    subscriptions: usersSubs[i].linkable,
                    groupRestrictions: options.groupRestrictions
                });

                //for each linkable user (ie. adults) check againts other members for discount, using mastersubsids as a reference
                subs.forEach(function(s) {
                    // Look through the masterSubsIds, for one included in the linked restrictions list for s
                    if (masterSubsIds.find(function(ms) { return s.linkedMemberRestrictionIds.includes(ms); })) {
                        if (!user.availableSubscriptions.find(function(sub) { return sub.id === s.id; })) {

                            // Add s to the list
                            user.availableSubscriptions.push(s);
                        }
                    }
                });

                // filter out subs with the same duration to leave only the lowest priced
                if (user.availableSubscriptions.length) {
                    user.availableSubscriptions = lodash(user.availableSubscriptions)
                        .orderBy(['duration', 'listPriceInPence'])
                        .uniqBy('duration')
                        .value();
                }

                if (!user.availableSubscriptions.length) {
                    subs = centreModel.filterSubscriptions({
                        subscriptions: usersSubs[i].nonLinkable,
                        groupRestrictions: options.groupRestrictions
                    });

                    user.availableSubscriptions = subs;


                    // filter out subs with the same duration to leave only the lowest priced
                    if (user.availableSubscriptions.length) {
                        user.availableSubscriptions = lodash(user.availableSubscriptions)
                            .orderBy(['duration', 'listPriceInPence'])
                            .uniqBy('duration')
                            .value();
                    }

                    subs.forEach(function(s) {
                        if (!masterSubsIds.includes(s.id)) {
                            masterSubsIds.push(s.id);
                        }
                    });
                }

                //check discount for linkedmember
                if(originalSubs && user.availableSubscriptions) {
                    for(var x = 0; x < user.availableSubscriptions.length; x++) {
                        if(user.availableSubscriptions[x].duration === 12) {
                            var annualDiscountedPrice = user.availableSubscriptions[x].listPriceInPence;
                        }

                        if(user.availableSubscriptions[x].duration === 1) {
                            var monthlyDiscountedPrice = user.availableSubscriptions[x].listPriceInPence;
                        }

                        for(var y = 0; y < originalSubs.length; y++) {
                            if(originalSubs[y].id.indexOf('EAP') !== -1) {
                                var annualFullPrice = originalSubs[y].listPriceInPence;
                            }

                            if(originalSubs[y].id.indexOf('EAD') !== -1) {
                                var monthlyFullPrice = originalSubs[y].listPriceInPence;
                            }
                        }
                    }
                }

                var annualDiscount = annualFullPrice - annualDiscountedPrice;
                var monthlyDiscount = monthlyFullPrice - monthlyDiscountedPrice;

                user.linkedMemberDiscount = {
                    annual: annualDiscount,
                    monthly: monthlyDiscount
                };

                if (subs && subs.length) {
                    noSubscriptionsFound = false;
                }
            }
        }

        return {
            noSubscriptionsFound: onlyFreeProfile ? false : noSubscriptionsFound
        };
    },
    //
    // filterLinkableSubs: function filterLinkableSubs(subs, isAdult, hasJuniors) {
    //     var newSubs;
    //
    //     if (isAdult && hasJuniors) {
    //         newSubs = subs.filter(function(s) {
    //             var subParts = s.id.toLowerCase().match(/\d+(.+)/);
    //
    //             return /ef/.test(subParts[1].toLowerCase());
    //         });
    //
    //     } else if (isAdult && !hasJuniors) {
    //         newSubs = subs.filter(function(s) {
    //             var subParts = s.id.toLowerCase().match(/\d+(.+)/);
    //
    //             return /e2/.test(subParts[1].toLowerCase());
    //         });
    //
    //     } else {
    //         newSubs = subs;
    //     }
    //
    //     return newSubs;
    // },

    // If no user objects exists, will return the lowest price for the options.
    getPrice: function getPrice(options) {
        if (!options.users.total) {
            return { lowestPrice: exports.getLowestPrice(options) };
        } else {
            return exports.getPriceWithUsers(options);
        }
    },

    // Add missing DD payments that occur when a user has a DD addon
    fixLessonPricesWithAddons: function fixLessonPricesWithAddons(options) {
        let {
            prices,
            d,
            dt
        } = options;

        let users = prices.periods[dt][d].users;

        users.forEach((u, i) => {
            let rangeKeys = Object.keys(u);

            if (rangeKeys.length < 3) {
                return;
            }

            let periodA = rangeKeys[1];
            let periodB = rangeKeys[2];
            let monthSubsA = u[periodA].subscriptions;
            let monthSubsB = u[periodB].subscriptions;

            monthSubsA.forEach(subA => {
                let hasSub = monthSubsB.some(subB => subB.id === subA.id);

                if (!hasSub) {
                    let monthA = moment(periodA).add(1, 'months').format('MMMM');
                    let monthB = moment(periodB).add(1, 'months').format('MMMM');

                    u[periodB].additionalAmount += subA.amount;

                    let priceItem = Object.assign({}, subA, {
                        desc: subA.desc.replace(monthA, monthB)
                    });

                    monthSubsB.push(priceItem);
                }
            });
        });

        return prices;
    },

    getLowestPrice: function getLowestPrice(options) {
        var subscriptions = exports.getSubscriptionsForMembershipType({
            subscriptions: options.subscriptions,
            type: 'adult',
            durationType: options.dt,
            duration: 1
        });

        var subs = subscriptions,
            price,
            i;

        if (subs.length) {
            // Only using the first available subscription mathing the current
            //  durationType and duration if more than one are available.
            // This is the wrong thing to do, but we don't have any other way
            //  to filter subscriptions given the crap data we get...
            price = subs[0].listPriceInPence;

            for (i = 0; i < subs.length; i++) {
                if (subs[i].listPriceInPence < price) {
                    price = subs[i].listPriceInPence;
                }
            }
        }

        return price;
    },

    getPriceWithUsers: function getPriceWithUsers(options) {
        var defaultPeriodPriceStructure = {
                total: 0,
                extras: {
                    items: {},
                    total: 0
                },
                fees: {
                    total: 0,
                    adminTotal: 0,
                    adminPayable: 0,
                    adminDiscounts: 0,
                    lastMonthsTotal: 0,
                    other:[]
                },
                ranges: {},
                priceOverridesPerUser: []
            },
            prices = {
                // This is here to avoid having to loop over ranges and
                //  periods when all we need to know is if there are
                //  ALSO monthly fees when having seleted the annual
                //  payment duration.
                hasMonthlyFees: false,
                periods: {}
            },
            seenDurations = {},
            isLessonUser = false,
            feeIds = {},
            subs = [],

            userSubscriptions,
            lessonActivity,
            otherDuration,
            userTotal,
            extraKey,
            rangeKey,
            extras,
            period,
            subKey,
            extra,
            user,
            sub,
            sdt, // sub-duration-type
            sd, // sub-duration
            i,
            j;

        for (i = 0; i < options.users.objects.length; i++) {
            user = options.users.objects[i];
            extras = user.extras;
            seenDurations = {};

            let userFees = {};

            // If a deeplink user (deeplink addons only at this time),
            //  ignore all subscriptions as we're only using extras.
            if (user.typeDesc !== 'deeplink') {
                subs = user.availableSubscriptions.sort((a, b) => b.duration - a.duration);
            }

            if (subs.length) {
                for (j = 0; j < subs.length; j++) {
                    sub = subs[j];
                    sdt = sub.durationType;
                    sd = sub.duration;

                    if (
                        sdt === 0
                        && sd === 1
                        && prices.periods[0]
                        && prices.periods[0][12]
                        && prices.periods[0][12].ranges.now.total === 0
                    ) {
                        continue;
                    }

                    // Creating price structure if not present
                    // Also creating here the reciprocal price structure so it's
                    //  available to use immediately.
                    prices.periods[sdt] = prices.periods[sdt] || {};
                    prices.periods[sdt][sd] = prices.periods[sdt][sd] || JSON.parse(JSON.stringify(defaultPeriodPriceStructure));

                    if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                        for(var l in user.lesson.selectedLessons) {
                            var lesson = user.lesson.selectedLessons[l];
                            var lessonSub = user.lesson.discountUser && lesson.discountSubId ? lesson.discountSubId : lesson.subId;

                            if(lesson.subId && sub.id === lesson.subId || sub.id === lesson.discountSubId) {
                                isLessonUser = true;
                                lessonActivity = lesson.activity;
                            }
                        }
                    } else {
                        //ONLY DO THIS FOR NON-LESSONS SUBS

                        // This will ensure only the first subscription of
                        //  each duration will be used.
                        // It's probably not the best, but I believe it's
                        //  what the data structure will be anyway:
                        //  1 per month, 1 per year (12 months).
                        if (seenDurations[sdt] && seenDurations[sdt][sd]) {
                            continue;
                        }

                        seenDurations[sdt] = seenDurations[sdt] || {};
                        seenDurations[sdt][sd] = true;
                    }

                    exports._calculateFees({
                        uid: i,
                        sub: sub,
                        periods: prices.periods,
                        feesIds: feeIds,
                        userFees: userFees,
                        isExtra: false,
                        dt: options.dt,
                        d: options.d,
                        isLessonUser: isLessonUser,
                        isLessonDiscountUser: user.lesson.discountUser,
                        lessonActivity: lessonActivity,
                        priceOverridesPerUser: prices.periods[sdt][sd].priceOverridesPerUser || []
                    });
                }
            }

            function findSubWithMostPriceEntries(subs) {
                let priceLength = 0;
                let subWithMostPrices = null;

                subs.forEach(sub => {
                    const { now, ...pricesWithoutNow } = sub;
                    const priceArrayLength = pricesWithoutNow.length;

                    if (subWithMostPrices === null) {
                        priceLength = priceArrayLength;
                        subWithMostPrices = sub;
                        return;
                    }

                    if (priceArrayLength > priceLength) {
                        priceLength = priceArrayLength;
                        subWithMostPrices = sub;
                    }
                });

                return subWithMostPrices;
            }

            function addMissingDates(sub, extra) {
                for (let date in sub) {
                    if (sub.hasOwnProperty(date) && !extra.hasOwnProperty(date)) {
                        const lastDate = Object.keys(extra).pop();

                        extra[date] = extra[lastDate];
                    }
                }
            }

            const subWithMostPrices = findSubWithMostPriceEntries(subs.filter(sub => sub.duration === 1));

            if (Object.keys(extras).length) {
                for (let tag in extras) {
                    if (extras.hasOwnProperty(tag) && typeof extras[tag] === 'object') {
                        for (let extraKey in extras[tag]) {
                            if(extras[tag][extraKey].duration === 1 && extras[tag][extraKey].durationType === 0) {
                                addMissingDates(subWithMostPrices.price, extras[tag][extraKey].price);
                            }
                        }
                    }
                }
            }

            if (Object.keys(extras).length) {
                for (extraKey in extras) {
                    const mainSub = subs.find(sub => sub.duration === 1);
                    extra = extras[extraKey];

                    // If no option for this extra group has been selected,
                    //  or the selected option isn't the current one in the loop,
                    //  conitnue without processing it.
                    if (!options.extras[extraKey]) {
                        continue;
                    }

                    for (subKey in extra) {
                        if (options.extras[extraKey] !== subKey) {
                            continue;
                        }

                        sub = extra[subKey];
                        sdt = sub.durationType;
                        sd = sub.duration;
                        otherDuration = (12 === sd) ? 1 : 12;

                        // Creating price structure if not present
                        // Also creating here the reciprocal price structure so it's
                        //  available to use immediately.
                        prices.periods[sdt] = prices.periods[sdt] || {};
                        prices.periods[sdt][sd] = prices.periods[sdt][sd] || JSON.parse(JSON.stringify(defaultPeriodPriceStructure));
                        prices.periods[sdt][otherDuration] = prices.periods[sdt][otherDuration] || JSON.parse(JSON.stringify(defaultPeriodPriceStructure));

                        period = prices.periods[sdt][sd];

                        period.extras.items[sub.id] = period.extras.items[sub.id] || { extra: sub, count: 0};
                        period.extras.items[sub.id].count ++;
                        period.extras.total += sub.listPriceInPence;

                        exports._calculateFees({
                            uid: i,
                            sub: sub,
                            periods: prices.periods,
                            feesIds: feeIds,
                            isExtra: true,
                            dt: options.dt,
                            d: options.d,
                            priceOverridesPerUser: prices.periods[sdt][sd].priceOverridesPerUser || []
                        });
                    }
                }
            }
        }

        for (sdt in prices.periods) {
            for (sd in prices.periods[sdt]) {
                period = prices.periods[sdt][sd];

                period.users = period.users || [];

                for (i = 0; i < options.users.objects.length; i++) {
                    for (rangeKey in period.ranges) {
                        userTotal = 0;
                        userSubscriptions = [];

                        period.users[i] = period.users[i] || {};

                        if (period.ranges[rangeKey].users && period.ranges[rangeKey].users[i] && period.ranges[rangeKey].users[i].length) {
                            for (j = 0; j < period.ranges[rangeKey].users[i].length; j++) {
                                sub = period.ranges[rangeKey].users[i][j];
                                userTotal +=  sub.amount;
                                userSubscriptions.push(sub);
                            }
                        }

                        period.users[i][rangeKey] = {
                            additionalAmount: userTotal,
                            subscriptions: userSubscriptions
                        };
                    }
                }
            }
        }

        // This is here to avoid having to loop over ranges and
        //  periods when all we need to know is if there are
        //  also EXTRAS monthly fees when having s                                                                                                                                                                                                                                                                                            eleted the annual
        //  payment duration.

        if (prices.periods && prices.periods[0] && prices.periods[0][1] && prices.periods[0][1].extras && prices.periods[0][1].extras.total) {
            prices.hasMonthlyFees = true;

        } else {
            prices.hasMonthlyFees = false;
        }

        // If user is in the lesson journey add missing DD payments
        // occurs when a user has a DD addon
        if (user && user.lesson && user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length && sd) {
            prices = exports.fixLessonPricesWithAddons({
                prices,
                dt: options.dt,
                d: options.d,
            });
        }

        return prices;
    },

    _createRange: function _createRange(periods, rangeKey, dt, uid) {
        var k;

        for (k in periods[dt]) {
            periods[dt][k].ranges[rangeKey] = periods[dt][k].ranges[rangeKey] || {
                total: 0,
                users: []
            };

            if (undefined !== uid && null !== uid) {
                periods[dt][k].ranges[rangeKey].users[uid] = periods[dt][k].ranges[rangeKey].users[uid] || [];
            }
        }
    },

    getFuturePrice: function getFuturePrice(prices, monthlyPrice) {
        if(prices) {
            var regularSub = prices.find(function(obj) {
                return obj.desc === 'regular';
            });

            if(regularSub) {
                var regularPrice = regularSub.amountInPence;
                //if discount exists then use that value instead
                if (regularPrice === 0 && regularSub.discount && regularSub.discount.priceBeforeDiscountInPence > 0) {
                    regularPrice = regularSub.discount.priceBeforeDiscountInPence;
                }

                //if the current price is different from the future price
                //change the description to note the change
                if(regularPrice !== monthlyPrice) {
                    return regularPrice;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },


    dayOfWeekToNumber: function(dayName) {
        const days = {
            'Sunday': 0,
            'Monday': 1,
            'Tuesday': 2,
            'Wednesday': 3,
            'Thursday': 4,
            'Friday': 5,
            'Saturday': 6
        };

        // Ensure the first letter is capitalized to match the keys in the 'days' object
        const dayNameCapitalized = dayName.charAt(0).toUpperCase() + dayName.slice(1).toLowerCase();

        return days.hasOwnProperty(dayNameCapitalized) ? days[dayNameCapitalized] : null;
    },


    getRemainingDays: function getRemainingDays(activity, searchMonth = moment(window.now).format('M')) {
        let year = moment(window.now).year();
        let currentMonth = moment(window.now).month() + 1; // January is 1, February is 2, etc.
        searchMonth = parseInt(searchMonth, 10);
    
        // If the current month is November or December and we're looking for January, we adjust to January next year.
        if ((currentMonth === 11 || currentMonth === 12) && searchMonth === 1) {
            year++;
        }
    
        // Set the Christmas break for the search month. If it's January, we use the previous December's dates.
        const isJanuary = searchMonth === 1;
        const christmasBreakStart = isJanuary ? moment(`${year - 1}-12-23`, 'YYYY-MM-DD') : moment(`${year}-12-23`, 'YYYY-MM-DD');
        const christmasBreakEnd = isJanuary ? moment(`${year}-01-05`, 'YYYY-MM-DD') : moment(`${year + 1}-01-05`, 'YYYY-MM-DD');

    
        // Initialize the first lesson date in the given month and year
        let lessonDate = moment(`${year}-${searchMonth}-01`, 'YYYY-MM').day(this.dayOfWeekToNumber(activity.weekday));

        if (lessonDate.month() + 1 < searchMonth || lessonDate.month() === 11 && searchMonth === 1) {
            lessonDate.add(1, 'week');
        }
    
        const activityStartDate = moment(activity.first_session_date, 'YYYY/MM/DD');
        let lessonsInMonth = [];
        
        while (lessonDate.month() + 1 === searchMonth && lessonDate.year() === year) {
            if (!lessonDate.isBetween(christmasBreakStart, christmasBreakEnd, 'day', '[]')) {
                lessonsInMonth.push(lessonDate.format('YYYY-MM-DD'));
            }
            lessonDate.add(1, 'week');
        }
    
        // Filter out lesson dates before the activity's start date.
        lessonsInMonth = lessonsInMonth.filter(date => moment(date).isSameOrAfter(activityStartDate));
    
        console.debug(`Eligible lessons in ${searchMonth}/${year}:`, lessonsInMonth);
        return lessonsInMonth.length;
    },
    

    // Calculate fees is called on EACH subscription, addon, or extra.
    _calculateFees: function _calculateFees(options) {
        var sdt = options.sub.durationType,
            isExtra = options.isExtra,
            sd = options.sub.duration,
            uid = options.uid,
            sub = options.sub,
            discountText = '',
            isProRata = false,
            store = require('../store'),
            cutoffDate = store.getState().app.cutoffDate,

            priceDescForRegex,
            otherDuration,
            lessonSubId,
            priceDesc,
            otherFee,
            rangeKey,
            thefee,
            amount,
            period,
            prices,
            price,
            range,
            k;

        var monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        period = options.periods[sdt][sd];

        // Adding the subscription price to the total for the
        //  corresponding period (1 month, or 1 year).
        period.total += sub.listPriceInPence;

        // If there are no elements in the price section, exit.
        if (!Object.keys(sub.price).length) {
            return;
        }

        if (0 === sub.durationType || sub.durationType === 1) {
            otherDuration = (12 === sub.duration) ? 1 : 12;

            //if is lessons user
            if(options.isLessonUser && options.lessonActivity) {
                var newProduct,
                    pricePerLesson = 0,
                    monthlyPrice,
                    daysRemaining,
                    newSubPrice = {},
                    newNowPrices = [],
                    originalSub = Object.assign(sub.price),
                    nextMonth = moment(window.now).add(1, 'months').month(),
                    startingDate = moment(window.now).add(1, 'months').startOf('month').format('YYYY-MM-DD');

                var subCopy = lodash.cloneDeep(sub);

                lessonSubId = options.isLessonDiscountUser && options.lessonActivity.discount_prices.sub_id ? options.lessonActivity.discount_prices.sub_id : options.lessonActivity.prices.sub_id;

                 // Pre-pass to compute pricePerLesson and monthlyPrice
                 subCopy.price.now.forEach((priceEntry) => {
                    const desc = (priceEntry.desc || '').toLowerCase().trim();
                    const singleSession = '1 sess';
                
                    if (desc.includes(singleSession)) {
                        // Set pricePerLesson to the discounted price if it exists and is valid, else use the regular amountInPence
                        pricePerLesson = (priceEntry.amountInPence === 0 && priceEntry.discount && priceEntry.discount.priceBeforeDiscountInPence > 0)
                            ? priceEntry.discount.priceBeforeDiscountInPence
                            : priceEntry.amountInPence;
                    }
                });

                for (var l = 0; l < sub.price.now.length; l++) {
                    var fee = {},
                        extraMonth = {},
                        subPrice = sub.price.now[l].amountInPence,
                        discount = sub.price.now[l].discount,
                        discountedPrice,
                        discountDesc,
                        regularPrice,
                        regularSub,
                        futurePrice;

                    newProduct = {};
                    priceDescForRegex = sub.price.now[l].desc.toLowerCase().replace(/[\s-]+/g, '');

                    //if discount exists then use that value instead
                    if (subPrice === 0 && discount && discount.priceBeforeDiscountInPence > 0) {
                        subPrice = discount.priceBeforeDiscountInPence;
                    }

                    if(!priceDescForRegex.match(/lastmonth/) && !priceDescForRegex.match(/admin/) && !priceDescForRegex.match(/lessonsin/) && !priceDescForRegex.match(/current/)) {
                        const singleSession = ' 1 sess';
                        const isSession = (sub.price.now[l].desc || '').toLowerCase().includes(singleSession);
                        if (!isSession) {
                            fee.amountInPence = subPrice;
                            fee.desc = sub.price.now[l].desc;
                        }
                        if (isSession) {
                            //get the price of the remaining lessons in this month
                            fee.pricePerLesson = pricePerLesson;
                            fee.daysRemaining = daysRemaining = exports.getRemainingDays(options.lessonActivity);
                            fee.amountInPence = pricePerLesson * daysRemaining;
                            fee.desc = 'current month';
                            if (daysRemaining) {
                                console.debug(`Price per lesson (${pricePerLesson}) x remaining days (${daysRemaining}) = ${fee.amountInPence}`);
                            }
                        }
                    } else if(priceDescForRegex.match(/lastmonth/)) {
                        //get the monthly price from the subscription and create new object
                        monthlyPrice = subPrice;
                        fee.desc = 'lessons in ' + monthNames[nextMonth];

                        if (subPrice === 0 && discount && discount.priceBeforeDiscountInPence > 0) {
                            discountedPrice = discount.priceBeforeDiscountInPence - discount.discountValueInPence;
                        }

                        //if we are past the cut off date (18th)
                        //add the another month to the upfront payment
                        if(moment(window.now).date() > cutoffDate) {
                            nextMonth = moment(window.now).add(2, 'months').month();
                            startingDate = moment(window.now).add(2, 'months').startOf('month').format('YYYY-MM-DD'); //change the start date of the direct debit to the next month

                            //checking for future price changes
                            futurePrice = this.getFuturePrice(sub.price[startingDate], monthlyPrice);

                            nextMonth = moment().add(2, 'months').month();
                            startingDate = moment().add(2, 'months').startOf('month').format('YYYY-MM-DD'); //change the start date of the direct debit to the next month

                            let extraMonthPrice = futurePrice ? futurePrice : monthlyPrice;

                            if (monthNames[moment(window.now).month()] === 'November') {
                                // Extra month is january when we're in November.
                                extraMonthPrice = this.getRemainingDays(options.lessonActivity, 1) * pricePerLesson; // TODO: logic to calculate price for January;
                            }

                            extraMonth = {
                                amountInPence: extraMonthPrice,
                                desc: 'lessons in ' + monthNames[nextMonth],
                                isOneOff: true,
                                productId: sub.price.now[l].productId
                            };
                           
                        }

                        //resetting next month
                        nextMonth = moment(window.now).add(1, 'months').month();

                
                        // if it's November, then we need change the sub price for december,
                        // it's December, we need to change sub price for january.


                        if (monthNames[nextMonth] === 'December') {
                            subPrice = this.getRemainingDays(options.lessonActivity, 12) * pricePerLesson; 
                        }


                        if (monthNames[nextMonth] === 'January') {
                            subPrice = this.getRemainingDays(options.lessonActivity, 1) * pricePerLesson; 
                        }

                    } else if(priceDescForRegex.match(/lessonsin/)) {
                        //this blocks repeats what happens in the previous block
                        //only needed because of the loop - the previous changes will get overwritten without it
                        monthlyPrice = subPrice;


                        if (subPrice === 0 && discount && discount.priceBeforeDiscountInPence > 0) {
                            discountedPrice = discount.priceBeforeDiscountInPence - discount.discountValueInPence;
                        }

                        //if a second month was added
                        //update the start date of the direct debit
                        if(moment(window.now).date() > cutoffDate) {
                            nextMonth = moment(window.now).add(2, 'months').month();

                            if(priceDescForRegex.match(monthNames[nextMonth].toLowerCase())) {
                                startingDate = moment(window.now).add(2, 'months').startOf('month').format('YYYY-MM-DD');
                            }
                        }
                    }

                    //make sure the discounted monthly prices dont affect the current month price
                    var newProductPrice;
                    if(fee.desc === 'current month') {
                        newProductPrice = fee.amountInPence;
                    } else {
                        newProductPrice = fee.amountInPence || (discountedPrice ? discountedPrice : subPrice);
                    }

                    newProduct = {
                        amountInPence: newProductPrice,
                        desc: textOverrides.setSub(sub).replace(fee.desc || sub.price.now[l].desc),
                        discountDesc: discountDesc,
                        isOneOff: true,
                        productId: sub.price.now[l].productId
                    };

                    if (newProduct.amountInPence !== 0) {
                        // assignExtras(newProduct, sub.price.now[l]);
                        newNowPrices.push(newProduct);
                    }

                    if (extraMonth.amountInPence) {
                        // assignExtras(extraMonth, sub.price.now[l]);
                        newNowPrices.push(extraMonth);
                    }
                }

                // sort prices to that current is at the start, and fee at the end
                newNowPrices.sort((a, b) => {
                    return /current month/i.test(a.desc) ? -1 : /admin fee/i.test(a.desc) ? 1 : 0;
                });

                newSubPrice.now = newNowPrices;

                const newRegularPrice = originalSub[startingDate].find(x => x.desc.match(/regular/i));

                newSubPrice[startingDate] = [];
                newSubPrice[startingDate].push({
                    amountInPence: newRegularPrice?.amountInPence || 0, 
                    desc: 'regular',
                    priceChanged: false
                });

                //price change
                for (rangeKey in originalSub) {
                    //ignore the now object
                    //only continue if the date is the same as or after the starting date (which is when the direct debit is scheduled to start)
                    if(rangeKey !== 'now' && (moment(rangeKey).isSame(startingDate) || moment(rangeKey).isAfter(startingDate))) {
                        var dateRange = originalSub[rangeKey];

                        for (var s = 0; s < dateRange.length; s++) {
                            //is there a price change and is it different from the last month
                            if((dateRange[s].isPriceChange || dateRange[s].priceChanged) && dateRange[s].amountInPence !== monthlyPrice) {
                                //update the exsiting date object
                                if(newSubPrice[rangeKey]) {
                                    newSubPrice[rangeKey][0].amountInPence = dateRange[s].amountInPence;
                                    newSubPrice[rangeKey][0].priceChanged = true;
                                //create new date object if there is a price change after the direct debit has started
                                } else {
                                    newSubPrice[rangeKey] = [];
                                    newSubPrice[rangeKey].push({
                                        amountInPence: dateRange[s].amountInPence,
                                        desc: 'regular',
                                        priceChanged: true
                                    });
                                }

                                //update monthly price to compare against next months price
                                monthlyPrice = dateRange[s].amountInPence;
                            }
                        }
                    }
                }

                sub.price = newSubPrice;
            }

            let originalDiscount;

            (function calculateDicountTotal() {
                if (sub.price['now']) {
                    const regularPriceItem = sub.price['now'].find(x => x.desc.match(/last.?month|regular/i)) || {};
                    const { discount } = regularPriceItem;

                    if (discount && discount.discountType) {
                        originalDiscount = discount;

                        period.discountTotal = (period.discountTotal | 0) + discount.discountValueInPence;
                        return;
                    }
                }

                period.discountTotal = (period.discountTotal | 0);
            })();

            const rangeKeys = Object.keys(sub.price).sort((a, b) => {
                if (a === 'now') {
                    return -1;
                }

                if (b === 'now') {
                    return 1;
                }

                if (a < b) {
                    return -1;
                }

                if (a > b) {
                    return 1;
                }

                return 0;
            });

            // For each rangeKey in the price object.
            rangeKeys.forEach(rangeKey => {
                exports._createRange(options.periods, rangeKey, sdt, uid);

                range = period.ranges[rangeKey];
                prices = sub.price[rangeKey];

                // Since the regular price for an anual membership doesn't appear in the 'one-off' costs
                //  but we need to treat them as one-offs for the purpose of presentation and payment,
                //  we have to add it to the list of items for that user in this period range.
                // If that cost was in an 'annual' product desc in the 'now' price, it would
                //  align the way the price structure is used with the monthly subscriptions.
                if (
                    (sub.durationType === 0 && sub.duration === 12) ||
                    sub.durationType === 1
                ) {
                    thefee = {
                        id: sub.id,
                        desc: sub.desc,
                        discountDesc: sub.discountDesc,
                        amount: sub.listPriceInPence,
                        originalDiscount
                    };

                    period.ranges[rangeKey].total += sub.listPriceInPence;
                    period.ranges[rangeKey].users[uid].push(thefee);

                    if (isExtra) {
                        // if range is 'now', also add to other period now range
                        if ("now" === rangeKey) {
                            options.periods[0][1].ranges[rangeKey].total +=
                                sub.listPriceInPence;
                            options.periods[0][1].ranges[rangeKey].users[
                                uid
                            ].push(thefee);
                        }
                    }
                }

                for (k = 0; k < prices.length; k++) {
                    price = prices[k];
                    // lowercasing and removing spaces and hyphens
                    priceDescForRegex = price.desc.toLowerCase().replace(/[\s-]+/g, '');

                    /*if (price.discount) {
                        // TODO: discount information should go on the object, and formatted in the view, not here
                        discountText = ' - DISCOUNTED FROM £' +  + (price.discount.priceBeforeDiscountInPence / 100).toFixed(2);
                    } else {
                        discountText = '';
                    }*/

                    amount = price.amountInPence;

                    if ('now' !== rangeKey) {
                        priceDesc = price.desc;

                        if (price.isProRata) {

                            isProRata = true;

                            if (price.desc === 'pro-rata current') {
                                priceDesc = 'Your subscriptions from ' + viewUtils.formatDateWithTextMonths(sub.startDate, monthNames) + ' to ' + viewUtils.formatDateWithTextMonths(sub.endDate, monthNames) + '.';
                            } else if (price.desc === 'pro-rata next') {
                                priceDesc = 'Your subscriptions from ' + viewUtils.formatDateWithTextMonths(sub.startDate, monthNames) + ' to ' + viewUtils.formatDateWithTextMonths(sub.endDate, monthNames) + '.';
                            } else {
                                priceDesc = sub.desc + ' - ' + price.desc;
                            }

                        } else if (priceDescForRegex.match(/regular/)) {
                            isProRata = false;
                            priceDesc = sub.desc;
                        }


                        thefee = {
                            id: sub.id,
                            desc: textOverrides.setSub(sub).replace(priceDesc),
                            amount: amount,
                            isProRata: isProRata
                        };

                        if (price.discount) {
                            thefee.originalDiscount = price.discount;
                            thefee.discountDesc = sub.discountDesc;
                        }

                        range.total += amount;
                        // assignExtras(thefee, price);
                        range.users[uid].push(thefee);

                        if (isExtra) {
                            // Since this is an extra, also adding
                            //  it to the other period.
                            options.periods[0][otherDuration].ranges[rangeKey].total += amount;
                            options.periods[0][otherDuration].ranges[rangeKey].users[uid].push(thefee);
                        }

                    } else {

                        if (priceDescForRegex.match(/regular/)) {
                            // Ignoring regular from the 'now' section as they
                            //  shouldn't be here anymore.
                            continue;

                        } else {
                            // Processing pure admin fees
                            if (priceDescForRegex.match(/adminfee/)) { // replace with the "split('-')[1] === 1148" thingy when ready
                                period.fees.adminTotal += amount;
                                priceDesc = sub.desc + ' - admin fee';

                                if (amount === 0 && price.discount && price.discount.discountValueInPence > 0) {
                                    period.fees.adminTotal += price.discount.priceBeforeDiscountInPence;
                                    period.fees.adminDiscounts += price.discount.discountValueInPence;
                                }

                                if (isExtra) {
                                    options.periods[0][otherDuration].fees.adminTotal += amount;
                                }

                                options.priceOverridesPerUser[uid] = options.priceOverridesPerUser[uid] || [];

                                //  If admin fee has already been seen for this sub duration, add it to discounts
                                //  and reset the local amount so the summation
                                //  doesn't increase the total.
                                if (options.feesIds[price.productId + '-' + sub.duration] && lessonSubId !== sub.id) {
                                    period.fees.adminDiscounts += amount;

                                    if (isExtra) {
                                        options.periods[0][otherDuration].fees.adminDiscounts += amount;
                                    }

                                    amount = 0;

                                    if (!lessonSubId && !options.priceOverridesPerUser[uid].find(function(p) { return p.productId === price.productId; })) {
                                        options.priceOverridesPerUser[uid].push({
                                            productId: price.productId,
                                            overrideCode: 'repeatedFee',
                                            subId: sub.id
                                        });
                                    }
                                } else {
                                    // Set adminfee flag for this sub duration
                                    options.feesIds[price.productId + '-' + sub.duration] = true;
                                    period.fees.adminPayable += amount;

                                    if (isExtra) {
                                        options.periods[0][otherDuration].fees.adminPayable += amount;
                                    }

                                    //if is lesson sub add admin to the priceOverrides
                                    if(lessonSubId === sub.id) {
                                        if(!options.userFees[price.productId]) {
                                            options.userFees[price.productId] = true;

                                            options.priceOverridesPerUser[uid].push({
                                                productId: price.productId,
                                                overridePriceInPence: price.amountInPence,
                                                subId: sub.id
                                            });
                                        } else {
                                            amount = 0;

                                            options.priceOverridesPerUser[uid].push({
                                                productId: price.productId,
                                                overrideCode: 'repeatedFee',
                                                subId: sub.id
                                            });
                                        }
                                    } else {
                                        options.priceOverridesPerUser[uid].push({
                                            productId: price.productId,
                                            overridePriceInPence: price.amountInPence,
                                            subId: sub.id,
                                            ignore: true
                                        });
                                    }
                                }
                            } else {
                                if (priceDescForRegex.match(/lastmon/)) { // replace with the "split('-')[1] === 1149" thingy when ready
                                    period.fees.lastMonthsTotal += amount;
                                    priceDesc = sub.desc;

                                    if (isExtra) {
                                        options.periods[0][otherDuration].fees.lastMonthsTotal += amount;
                                    }

                                // If neither a last month or an admin fee, add it to the list of others.
                                } else {
                                    otherFee = {
                                        desc: price.desc + discountText,
                                        amount: amount
                                    };

                                    if (price.discount) {
                                        otherFee.originalDiscount = price.discount;
                                    }

                                    // fees.other is for the price breakdown in the sidebar.
                                    // assignExtras(otherFee, price);
                                    period.fees.other.push(otherFee);
                                    priceDesc = sub.desc + ' - ' + price.desc;

                                    if (isExtra) {
                                        options.periods[0][otherDuration].fees.other.push(otherFee);
                                    }

                                    //if is lesson sub add each item to the priceOverrides
                                    if(lessonSubId === sub.id) {
                                        options.priceOverridesPerUser[uid] = options.priceOverridesPerUser[uid] || [];
                                        var duplicateProductIndex = options.priceOverridesPerUser[uid].findIndex(function(p) { return p.productId === price.productId; });

                                        //if product ID already exist, add the amount to that object
                                        if(duplicateProductIndex !== -1) {
                                            options.priceOverridesPerUser[uid][duplicateProductIndex].overridePriceInPence = options.priceOverridesPerUser[uid][duplicateProductIndex].overridePriceInPence + price.amountInPence;
                                        } else {
                                            options.priceOverridesPerUser[uid].push({
                                                productId: price.productId,
                                                overridePriceInPence: price.amountInPence,
                                                subId: sub.id
                                            });
                                        }
                                    }
                                }
                            }

                            // This has to be at the bottom here as we need to be
                            //  able to change the amount along the way for
                            //  discounted fees.
                            thefee = {
                                id: price.productId,
                                desc: priceDesc + discountText,
                                amount: amount
                            };

                            if (priceDescForRegex.match(/lastmon/)){
                                thefee.isLastMonth = true;
                            }

                            if (price.discount) {
                                thefee.originalDiscount = price.discount;
                                thefee.discountDesc = sub.discountDesc;
                            }

                            period.ranges[rangeKey].total += amount;
                            // assignExtras(thefee, price);
                            period.ranges[rangeKey].users[uid].push(thefee);

                            // Only adding to the total if that fee is for the current period, or an extra
                            period.fees.total += amount;

                            if (isExtra) {
                                // Since this is an extra, also adding
                                //  it to the other period.
                                options.periods[0][otherDuration].ranges[rangeKey].total += amount;
                                options.periods[0][otherDuration].ranges[rangeKey].users[uid].push(thefee);

                                options.periods[0][otherDuration].fees.total += amount;
                            }
                        }
                    }
                }
            });
        }
    }
};

// this ensures that no data is lost down the line
function assignExtras(subject = {}, values = {}) {
    Object.entries(values).forEach(([key, val]) => {
        const valCurrent = subject[key];
        const keyLow = key.toLowerCase();
        const keyOther = Object.keys(subject).map(keyCurrent => keyCurrent.toLowerCase())
            .find(keyCurrent => (keyCurrent.includes(keyLow) || keyLow.includes(keyCurrent))) || '';
        if (
            !val ||
            valCurrent === val ||
            typeof val === 'object' ||
            subject[keyOther] === val
        ) return;
        let keyToUse = key;
        while (subject[keyToUse]) {
            keyToUse = `_${keyToUse}`;
        }
        subject[keyToUse] = val;
    });
}
