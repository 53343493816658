// Actions
import actions from "../actions/actionTypes";

/**
 * Deals with updating state in relation to applicable maps subscription group & ids
 * @param {Function} state Redux store
 * @param {array} action   Redux action with payload
 */
module.exports = ( state, action ) => {
    switch ( action.type ) {
        // Updates state to reflect subscription groups relating to MAP
        case actions.SITE_BANDING.FILL_APPLICABLE_SUBSCRIPTION_GROUPS:
            return {
                ...state,
                
                // Update state with subscription groups
                [action.subscriptionGroupName]: action.subscriptions
            };
            break;

        // Updates state to reflect the current visibility of the map
        case actions.SITE_BANDING.TOGGLE_MAP:
            return {
                ...state,
                mapIsVisible: action.isOn
            };
            break;

        // Updates state to reflect the current visibility of the "show more centres" pane
        case actions.SITE_BANDING.TOGGLE_SHOW_CENTRES:
            return {
                ...state,
                showMoreCentresIsVisible: action.isOn
            };
            break;

        default:
            // Initial maps state (empty)
            return state || { 'MAP01': [], 'MAP02': [], mapIsVisible: false, showMoreCentresIsVisible: false };
            break;
    }
};
