var React           = require('react'),

    Button          = require('../../common/button'),
    Input           = require('../../common/forms/input'),
    SmartDate       = require('../../common/forms/smartDate'),

    centreModel     = require('../../../models/centre'),

    UserDetailsContent;

UserDetailsContent = React.createClass({

    render: function() {
        var user = this.props.user,
            content = this.props.content,
            loggedInUser = this.props.loggedInUser,
            confirmButton,
            chooseUser,
            userFields,
            firstName,
            firstNameValid,
            firstNameDisabled = false,
            firstNamePlaceholder = content.fields.firstName.placeholder,
            isGeneric = this.props.isGeneric,
            showGenericError = this.props.showGenericError,
            buttonProps = {
                className: 'button',
                onClick: this.props.nextModule
            },
            buttonText = content.buttonNext;

        if(user.id) {
            if(loggedInUser.loggedIn && user.loggedInUser) {
                firstName = loggedInUser.user.info.firstName;
                firstNamePlaceholder = loggedInUser.user.info.firstName;
                firstNameValid = true;
                firstNameDisabled = true;
            } else {
                firstName = (user.info && user.info.firstName && user.info.firstName.value) || '';
                firstNameValid = (this.props.validationErrors && this.props.validationErrors.firstName && (!user.info || !user.info.firstName || !user.info.firstName.valid)) ? false : (!user.info || !user.info.firstName || user.info.firstName.valid);
            }

            var nameInput = (<Input className='personal-details__input'
                    input={ {
                        onBlur: this.props.onChangeInput.bind(null, 'firstName'),
                        onChange: this.props.onChangeInput.bind(null, 'firstName'),
                        placeholder: firstNamePlaceholder,
                        required: true,
                        disabled: firstNameDisabled
                    } }
                    label={ {
                        text: content.fields.firstName.label
                    } }
                    name='details_first_name'
                    type='text'
                    valid={ firstNameValid }
                    value={ firstName }
                />);

            var dateInput,
                siteId = this.props.centre.info.site_id;

            if(user.id === siteId + '-JUNIOR' || user.id === '0000-JUNIOR') {
                var age;

                if(user.info && ![undefined, null].includes(user.info.age)) {
                    age = user.info.age;

                    if(user.info.age < 3) {
                        age = user.info.ageInMonths + ' months';
                    }
                }

                console.log('user.info', user.info)

                dateInput = (<SmartDate className='personal-details__input--date'
                        input={ {
                            onBlur: this.props.onChangeInput.bind(null, 'dob'),
                            onChange: this.props.onChangeInput.bind(null, 'dob'),
                            placeholder: content.fields.dob.placeholder,
                            required: true
                        } }
                        label={ {
                            text: content.fields.dob.label
                        } }
                        name='details_dob'
                        disableOnChange={false}
                        skipInlineValidation={false}
                        valid={ (this.props.validationErrors && this.props.validationErrors.dob && (!user.info || !user.info.dob || !user.info.dob.valid)) ? false : (!user.info || !user.info.dob || user.info.dob.valid) }
                        invalidAgeRange={ (this.props.validationErrors && this.props.validationErrors.dob && this.props.validationErrors.dob.outOfRange) }
                        value={ (user.info && user.info.dob && user.info.dob.value) }
                        age={age || 0}
                        ageInMonths={user.info.ageInMonths || 0}
                        lessonType={this.props.lessonType}
                    />);
            }

            userFields = (<div>
                { nameInput }
                { dateInput }
            </div>);

            confirmButton = <Button {...buttonProps}>{ buttonText }</Button>;
        }

        var adultType = centreModel.getMembershipTypeFor(this.props.centre, 'adult'),
            juniorType = centreModel.getMembershipTypeFor(this.props.centre, 'junior');
        const options = [];

        if (adultType) {
            options.push({
                value: adultType.id,
                text: 'Adult (16+)'
            });
        }

        if (juniorType) {
            options.push({
                value: juniorType.id,
                text: 'Child'
            });
        }

        if(!this.props.singleUserType) {
            chooseUser = <ul className='personal-details__options-list'>
                    <li>
                        <Input className='personal-details__input--radio personal-details__input--radio-lessons'
                            input={ {
                                onChange: this.props.onUpdateUser.bind(null, 'id'),
                                className:  'forms__input--radio'
                            } }
                            label={ {
                                text: content.title.replace('[lesson]', this.props.lessonName)
                            } }
                            options={options}
                            name='radio_member'
                            radioLabelClassName={ 'personal-details__radio-label' }
                            type='radio'
                            valid={ true }
                            value={ (user.id) }
                        />
                    </li>
                </ul>;
        } else {
            var siteId = this.props.centre.info.site_id,
                userType = this.props.singleUserType.replace(siteId, '0000');

            chooseUser = (
                <div className="personal-details__input--radio personal-details__input--radio-lessons">
                    <label><span></span>{ content.title.replace('[lesson]', this.props.lessonName) }</label>
                    {!isGeneric && (
                        <p className='personal-details__subtitle'>{ content.subtitle[userType].replace('[lesson]', this.props.lessonName) }</p>
                    )}
                </div>
            );
        }

        return (<div className='module-container' id='userDetails'>
            <div className={'module ' + (!this.props.activeModule ? 'module--inactive' : '')}>
                <div className='personal-details__container personal-details__container--lessons'>
                    { chooseUser }
                    { userFields }
                </div>

                { confirmButton }
            </div>
        </div>);
    }
});

UserDetailsContent.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    onChooseType: React.PropTypes.func,
    onChangeInput: React.PropTypes.func,
    nextModule: React.PropTypes.func,
    user: React.PropTypes.object,
    valid: React.PropTypes.bool,
    lessonType: React.PropTypes.string,
    lessonName: React.PropTypes.string,
};

module.exports = UserDetailsContent;
