var exports;

module.exports = exports = {
    priceLevelCentres: {
        '0183' : {
            ageLevels: {
                senior: {
                    level: 'OX-SNR',
                    max: 120,
                    min: 64
                },
                adult: {
                    level: 'OX-AD',
                    max: 63,
                    min: 16
                },
                junior: {
                    level: 'OX-JUN',
                    max: 15,
                    min: 0
                }
            },
            concessionOptions: [
                {
                    text: 'Disabled',
                    value: 'OX-DIS'
                }, {
                    text: 'Student',
                    value: 'OX-CON'
                }, {
                    text: 'Job Seekers',
                    value: 'OX-CON'
                }, {
                    text: 'Income Support',
                    value: 'OX-CON'
                }, {
                    text: 'Universal Credit',
                    value: 'OX-CON'
                }
            ]
        }
    },

    normalizeCentreData: function normalizeCentreData(data, options) {
        var permittedFacilitiesTagId = 'facilities',
            permittedSitesTagId = 'centres',
            permittedTimesTagId = 'permittedtimes',
            membershipTypesTagId = 'membershiptypes',
            staffTypesTagId = 'staff',
            lessonsTypesTagId = 'lessons',
            shortTagId = 'short',
            addOnsTagId = 'addons',
            extrasTagId = 'extras',

            tagGroupWhiteList = [membershipTypesTagId, permittedFacilitiesTagId, permittedSitesTagId, permittedTimesTagId, staffTypesTagId, lessonsTypesTagId, shortTagId],

            centre = {
                info: {},
                subscriptions: {},
                titles: {},
                addOnGroups: data.subscriptions.addOnGroups
            },

            tagsBlackList = options.tagsBlackList,
            tagsWhiteList = options.tagsWhiteList,
            subscriptions = data.subscriptions,
            filteredPermittedSites = {},
            filteredPermittedTimes = {},
            permittedFacilities = {},
            filteredFacilities = {},
            filteredLessons = {},
            foundFacilities = [],
            tagGroups = {},
            addons = {},
            extras = {},

            permittedFacilitiesTags,
            permittedSitesTags,
            permittedTimesTags,
            membershipTypes,
            lessonTypes,
            permittedSites,
            permittedTimes,
            staffTypes,
            extrasTags,
            addonsTags,
            tagGroup,
            tagId,
            info,
            tags,
            tag,
            sub,
            f, y, s, t,
            i, j, k, l, m,
            d, n;

        info = Object.assign({}, data);
        delete info.subscriptions;
        centre.info = info;

        if (options.subIds) {
            subscriptions.subscriptions = exports.filterSubscriptions({ subscriptions : subscriptions.subscriptions, subIds: options.subIds });
        }

        if (subscriptions && subscriptions.titles) {
            for (i = 0; i < subscriptions.titles.length; i++) {
                t = subscriptions.titles[i];
                centre.titles[t.id] = t;
            }
        }

        if (subscriptions && subscriptions.tagGroups) {
            for (i = 0; i < subscriptions.tagGroups.length; i++) {
                tagGroup = subscriptions.tagGroups[i];

                tags = tagGroup.tags.slice();
                tagId = tagGroup.id.toLowerCase();

                if (addOnsTagId === tagId) {
                    addonsTags = tagGroup.tags && tagGroup.tags.map(function(a) { return {
                        id: a.id,
                        groupTitle: a.desc,
                        groupDesc: a.comment,
                        imageUrl: a.image_url,
                        videoId: a.video_id,
                        videoProvider: a.video_provider,
                        sortOrder: a.sortOrder
                    }; });
                }

                if (extrasTagId === tagId) {
                    extrasTags = tagGroup.tags && tagGroup.tags.map(function(e) { return {
                        id: e.id,
                        groupTitle: e.desc,
                        groupDesc: e.comment,
                        imageUrl: e.image_url,
                        videoId: e.video_id,
                        videoProvider: e.video_provider,
                        sortOrder: e.sortOrder
                    }; });
                }

                // Only adding a member to the root centre objects
                //  if it has tags, and if it's in the whitelist
                if (tagGroupWhiteList.includes(tagId) &&
                    tagGroup.tags &&
                    tagGroup.tags.length)
                {
                    tagGroups[tagId] = tags;
                }
            }
        }

        membershipTypes = (tagGroups[membershipTypesTagId] || []).sort(function (a, b) { return a.sortOrder - b.sortOrder; });
        permittedFacilitiesTags = tagGroups[permittedFacilitiesTagId].sort(function (a, b) { return a.sortOrder - b.sortOrder; });
        permittedSitesTags = permittedSites = tagGroups[permittedSitesTagId].sort(function (a, b) { return a.sortOrder - b.sortOrder; });
        permittedTimesTags = permittedTimes = tagGroups[permittedTimesTagId].sort(function (a, b) { return a.sortOrder - b.sortOrder; });

        if(tagGroups[lessonsTypesTagId] && tagGroups[lessonsTypesTagId].length) {
            lessonTypes = tagGroups[lessonsTypesTagId].sort(function (a, b) { return a.sortOrder - b.sortOrder; });
        }

        if(tagGroups[staffTypesTagId] && tagGroups[staffTypesTagId].length) {
            staffTypes = tagGroups[staffTypesTagId].sort(function (a, b) { return a.sortOrder - b.sortOrder; });
        }

        for (l = 0; l < subscriptions.subscriptions.length; l++) {
            sub = subscriptions.subscriptions[l];

            centre.subscriptions[sub.id] = sub;

            for (m = 0; m < membershipTypes.length; m++) {
                y = membershipTypes[m];

                for (i = 0; i < permittedFacilitiesTags.length; i++) {
                    f = permittedFacilitiesTags[i];
                    permittedFacilities[f.id] = f;

                    for (j = 0; j < permittedSites.length; j++) {
                        s = permittedSites[j];

                        for (k = 0; k < permittedTimes.length; k++) {
                            t = permittedTimes[k];

                            if(tagsBlackList && tagsBlackList.some(s => sub.tags.includes(s))) {
                                continue;
                            }

                            if(tagsWhiteList && !tagsWhiteList.some(s => sub.tags.includes(s))) {
                                continue;
                            }

                            if (sub.tags && sub.tags.includes(y.id) &&
                                sub.tags.includes(f.id) &&
                                sub.tags.includes(s.id) &&
                                sub.tags.includes(t.id)) {

                                foundFacilities.push(f);

                                filteredFacilities[y.id] = filteredFacilities[y.id] || {};
                                filteredFacilities[y.id][f.id] = filteredFacilities[y.id][f.id] || {};
                                filteredFacilities[y.id][f.id][s.id] = filteredFacilities[y.id][f.id][s.id] || {};
                                filteredFacilities[y.id][f.id][s.id][t.id] = filteredFacilities[y.id][f.id][s.id][t.id] || [];

                                if(filteredFacilities[y.id][f.id][s.id][t.id].indexOf(sub) === -1) {
                                    filteredFacilities[y.id][f.id][s.id][t.id].push(sub);
                                }

                                if(permittedFacilities[f.id]['subs'] && permittedFacilities[f.id]['subs'].length) {
                                    permittedFacilities[f.id]['subs'].push(sub);
                                } else {
                                    permittedFacilities[f.id]['subs'] = [];
                                    permittedFacilities[f.id]['subs'].push(sub);
                                }
                            }
                        }
                    }
                }
            }

            if(lessonTypes) {
                for (d = 0; d < lessonTypes.length; d++) {
                    n = lessonTypes[d];

                    if (sub.tags && sub.tags.includes(n.id)) {
                        if(!filteredLessons[sub.id]) {
                            filteredLessons[sub.id] = sub;
                        }
                    }
                }
            }

            if(sub.tags) {
                for (j = 0; j < sub.tags.length; j++) {
                    tagId = sub.tags[j];

                    tag = addonsTags && addonsTags.find(function(t) { return t.id === tagId; });

                    if (tag) {
                        addons[tagId] = addons[tagId] || {
                            title: sub.desc,
                            desc: sub.webComments,
                            groupTitle: tag.groupTitle,
                            groupDesc: tag.groupDesc,
                            imageUrl: tag.imageUrl,
                            videoId: tag.videoId,
                            videoProvider: tag.videoProvider,
                            sortOrder: tag.sortOrder,
                            subs:[]
                        };

                        addons[tagId].subs.push(sub);
                    }

                    tag = extrasTags && extrasTags.find(function(t) { return t.id === tagId; });

                    if (tag) {
                        extras[tagId] = extras[tagId] || {
                            title: sub.desc,
                            desc: sub.webComments,
                            groupTitle: tag.groupTitle,
                            groupDesc: tag.groupDesc,
                            imageUrl: tag.imageUrl,
                            videoId: tag.videoId,
                            videoProvider: tag.videoProvider,
                            sortOrder: tag.sortOrder,
                            subs: []
                        };

                        extras[tagId].subs.push(sub);
                    }
                }
            }
        }


        for (i = 0; i < permittedSitesTags.length; i++) {
            s = permittedSitesTags[i];
            filteredPermittedSites[s.id] = s;
        }

        for (i = 0; i < permittedTimesTags.length; i++) {
            t = permittedTimesTags[i];
            filteredPermittedTimes[t.id] = t;
        }

        // Here, we should have only the facilities that have been found
        //  in a subscription, and a matching list of subscriptions,
        //  sorted by membership type, facilities they offer, permitted
        //  sites then permitted times
        // Anything else to be done to further filter subscriptions will
        //  need to be done in the components using them.
        if(lessonTypes) {
            centre.lessonTypes = lessonTypes;
        }
        centre.membershipTypes = membershipTypes;
        centre.permittedFacilities = permittedFacilities;
        centre.filteredFacilities = filteredFacilities;
        centre.filteredLessons = filteredLessons;
        centre.permittedSites = filteredPermittedSites;
        centre.permittedTimes = filteredPermittedTimes;
        centre.staffTypes = staffTypes;
        centre.extras = extras;
        centre.addons = addons;
        centre.permittedFacilitiesTags = permittedFacilitiesTags;
        centre.extrasTags = extrasTags;
        centre.addonsTags = addonsTags;
        centre.defaultOptions = exports.getDefaultOptionsForUserTypes(membershipTypes, centre);
        centre.facilities = foundFacilities.dedupeBy('id');

        // Ethnicity
        Object.entries(data).forEach(([key, value]) => {
            if (key.indexOf('ethnic') === -1) return;
            centre[key] = value;
        })
        return centre;
    },

    getDefaultOptionsForUserTypes: function getDefaultOptionsForUserTypes(membershipTypes, centre) {
        var defaults = {},
            defaultCentre,
            defaultTime,
            facilityId,
            facilities,
            keys,
            type,
            i;

        for (i = 0; i < membershipTypes.length; i++) {
            type = membershipTypes[i].id;

            defaults[type] = {};

            facilities = centre.filteredFacilities[type];

            for (facilityId in facilities) {
                keys = Object.keys(facilities[facilityId]);

                if (1 === keys.length || !keys.includes('anyCentre')) {
                    defaultCentre = keys[0];

                } else {
                    defaultCentre = 'anyCentre';

                }

                keys = Object.keys(facilities[facilityId][defaultCentre]);

                if (1 === keys.length || !keys.includes('anytime')) {
                    defaultTime = keys[0];

                } else {
                    defaultTime = 'anytime';

                }

                defaults[type][facilityId] = {
                    defaultCentre: defaultCentre,
                    defaultTime: defaultTime
                };
            }
        }

        return defaults;
    },

    getCommentsForTag: function getCommentsForTag(centre, sub) {
        if(!sub) {
            return '';
        }

        var tags = sub.tags,
            tagTypes = centre.permittedFacilities;

        for(var i = 0; i < tags.length; i++) {
            if(tagTypes[tags[i]]) {
                return tagTypes[tags[i]].comment;
            }
        }
        return '';
    },

    getMembershipTypeFor: function getMembershipTypeFor(centre, key) {
        var regex = new RegExp(key, 'i');
        return centre.membershipTypes.find(function(m) {
            return regex.test(m.id.toLowerCase());
        });
    },

    filterSubscriptions: function filterSubscriptions(options) {
        var filtered = [],
            councilFreeSwimGymTag = 'FsgCouncil',
            freeSwimGymTag = '0000-FSG',
            okToAdd = true,
            sub,
            i;


        if (options.subscriptions && options.subscriptions.length) {

            for (i = 0; i < options.subscriptions.length; i++) {
                sub = options.subscriptions[i];
                sub.clash = false;
                okToAdd = true;

                //If is sub has satff tag, only include if staff is logged in
                if(!options.isStaff && options.staffTags) {
                    for(var s = 0; s < options.staffTags.length; s++) {
                        if(sub.tags.includes(options.staffTags[s].id)) {
                            okToAdd = false;
                            break;
                        }
                    }

                    if(!okToAdd) {
                        continue;
                    }
                }

                // Exclude these subscriptions
                if (options.exclude &&
                    sub.tags.includes(options.exclude)) {
                    continue;
                }

                if (options.councilStaff && sub.tags.includes(options.exclude)) {
                    continue;
                }

                // Exclude council tariffs where applicable
                if (sub.tags.includes(freeSwimGymTag)) {

                    var isCouncilSub = !!sub.tags.filter(tag => {
                        return tag.indexOf(councilFreeSwimGymTag) > -1;
                    }).length;

                    if (options.councilStaff && !isCouncilSub) {
                        continue;
                    }

                    if (!options.councilStaff && isCouncilSub) {
                        continue;
                    }
                }

                // This part deals with excluding subscriptions not contating
                //  a group tag present in the groupRestrictions parameters.
                if (options.groupRestrictions && options.groupRestrictions.length && sub.groups && sub.groups.length) {
                    okToAdd = !!sub.groups.find(function(g) {
                        return options.groupRestrictions.includes(g);
                    });

                    if (!okToAdd) {
                        continue;
                    }
                }

                // If we pass some subscription IDs, only include those.
                if (options.subIds && !options.subIds.includes(sub.id)) {
                    continue;
                }

                filtered.push(sub);
            }
        }

        return filtered;
    }
};
