var React               = require('react'),
    connect             = require('react-redux').connect,

    actions             = require('../../actions'),
    config              = require('../../config'),
    utils               = require('../../utils'),
    UserModel           = require('../../models/user'),
    Faqs                = require('../faqs'),

    Subscriptions       = require('./subscriptions'),
    OwedSubscriptions   = require('./owedSubscriptions'),
    PaymentBreakdown    = require('./paymentBreakdown'),

    Button              = require('../common/button'),
    FooterSecurity      = require('../common/footerSecurity'),
    Loader              = require('../common/loader'),

    scrollToComponent   = require('react-scroll-to-component'),

    Renewals;

Renewals = React.createClass({

    getInitialState: function getInitialState() {
        return {
            errorMessage: null,
            renewedSubs: []
        };
    },

    _validateForm: function _validateForm(e) {
        e.preventDefault();

        this.props.dispatch(actions.selections.renewSelections(function (response) {

            this.props.dispatch(actions.app.showLoader(true));
            this.forceUpdate();
            window.location = response.paymentUrl;

        }.bind(this)));
    },

    payNow: function payNow(url, e) {

        this.props.dispatch(actions.app.showLoader(true));
        this.forceUpdate();
        window.location = url;

        e.preventDefault();
    },


    _onChangeInput: function _onChangeInput(sub) {
        var renewedSubs = this.state.renewedSubs;

        if(renewedSubs.indexOf(sub) !== -1) {
            renewedSubs.splice(renewedSubs.indexOf(sub), 1);
        } else {
            renewedSubs.push(sub);
        }

        this.setState({
            renewedSubs: renewedSubs
        });

        this.props.dispatch(actions.selections.setRenewalSubs(this.state.renewedSubs));
    },

    render: function render() {
        var content = this.props.content.renewals.content,
            btnText = content.buttonMakePayment,
            buttonProps = {
                onClick: this._validateForm,
                className: 'button simple-payment__button'
            },
            postSelectionsError,
            owedSubscriptions,
            paymentBreakdown,
            paymentButton,
            subscriptions,
            errors;

        if (this.props.postSelectionsError) {
            postSelectionsError = <span className='form__error personal-details__error'>Something has gone wrong. We’re really sorry about this. You can try again. You’re also welcome to join in-centre.</span>;

            errors = <div className='form__error-zone'>
                { postSelectionsError }
            </div>;
        }

        if(this.props.owedSubs) {
            owedSubscriptions = <OwedSubscriptions
                content={ this.props.content.renewals.owedSubscriptions }
                memberSubs={ this.props.memberSubs }
                owedSubs={ this.props.owedSubs }
                payNow={ this.payNow }
            />;
        }

        if(this.props.memberSubs) {
            subscriptions = <Subscriptions
                content={ this.props.content.renewals.subscriptions }
                memberSubs={ this.props.memberSubs }
                onChangeInput={ this._onChangeInput }
                owedSubs={ this.props.owedSubs }
                renewedSubs={ this.state.renewedSubs }
                showFSG={ this.props.showFSG }
            />;
        }

        if(this.state.renewedSubs) {
            if(this.state.renewedSubs.length) {
                paymentBreakdown = <PaymentBreakdown
                    content={ this.props.content.renewals.paymentBreakdown }
                    renewedSubs={ this.state.renewedSubs }
                    memberSubs={ this.props.memberSubs }
                />;

                paymentButton = (<div className='module-container'>
                        <div className='module module--inactive'>
                            <Button { ...buttonProps }>{ btnText }</Button>
                            { errors }
                        </div>
                    </div>);
            }
        }

        return (<div className='container'>
            <h1 className='content__title'>{ content.title }</h1>
            <p className='content__intro'>{ content.description }</p>

            {!this.props.memberSubs ?
                <div className='loader__wrapper'>
                    <ul className="loader__spinner">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            :
                <div>
                    { owedSubscriptions }

                    <div className='simple-payment'>
                        { subscriptions }

                        { paymentBreakdown }
                        { paymentButton }
                    </div>
                </div>
            }

            <Faqs isRenewals={ true} />
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content,
        postSelectionsError: state.selections.postSelectionsError,
        user: state.user.user
    };
}

module.exports = connect(mapStateToProps)(Renewals);
