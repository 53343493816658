var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions"),
    history = require("../../utils/history"),
    Button = require("../common/button"),
    Modal = require("../common/modal"),
    PaymentCancelConfirmModal;

PaymentCancelConfirmModal = React.createClass({
    getInitialState: function getInitialState() {
        return {
            isCancelDisabled: false,
            isResetButtonVisible: false,
            cancelledPaymentsList: []
        };
    },

    _onOk: function _onRetry() {
        const self = this;

        this.props
            .dispatch(actions.selections.cancelSelections())
            .then(data => {
                this.props.dispatch(actions.app.showLoader(false));

                const payments = self._formatCancelledPaymentsList(data);
                const allPaymentsSuccessful = self._isAllPaymentCancellationsWereSuccessful(
                    payments
                );

                if (allPaymentsSuccessful) {
                    this.setState({
                        isCancelDisabled: true,
                        cancelledPaymentsList: payments,
                        isResetButtonVisible: true
                    });
                } else {
                    this.setState({
                        isCancelDisabled: !allPaymentsSuccessful,
                        cancelledPaymentsList: payments
                    });
                }
            });
    },

    _isAllPaymentCancellationsWereSuccessful: function _isCancelButtonDisabled(
        payments
    ) {
        return payments
            .reduce(
                (subscriptions, payment) => [
                    ...subscriptions,
                    ...payment.subscriptions
                ],
                []
            )
            .every(subscription => subscription.status === "success");
    },

    _formatCancelledPaymentsList: function _formatCancelledPaymentsList(
        payments
    ) {
        return Object.keys(payments.members).map(function(mrmId) {
            return {
                mrmId,
                subscriptions: payments.members[mrmId]
            };
        });
    },

    _onCancel: function _onCancel() {
        this.props.dispatch(actions.app.hideModal("paymentCancelConfirm"));
    },

    _renderCancelledPayments: function _renderCancelledPayments() {
        return this.state.cancelledPaymentsList.map(cancelledPayment => {
            return (
                <div key={cancelledPayment.mrmId}>
                    <p>Results:</p>
                    <p>
                        <strong>Member ID: {cancelledPayment.mrmId}</strong>
                    </p>
                    <ul>
                        {cancelledPayment.subscriptions.map(subscription => {
                            return (
                                <li key={subscription.subsciption}>
                                    <strong>{subscription.subsciption} </strong>
                                    - {subscription.status}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    },

    _resetJourney: function _resetJourney() {
        this.props.dispatch(actions.stages.goHome());
        this.props.dispatch(actions.centreFinder.wipeout());
        this.props.dispatch(actions.selections.wipeout());
        this.props.dispatch(actions.memberships.wipeout());
        this.props.dispatch(actions.personalDetails.wipeout());
        this.props.dispatch(actions.app.showLoader(false));
        this.props.dispatch(actions.app.hideModal("paymentCancelConfirm"));
    },

    render: function render() {
        var { modal } = this.props;
        const resetButton = (
            <Button
                className="button button--primed"
                onClick={this._resetJourney}
                key="reset"
            >
                Start Again
            </Button>
        );

        const cancelButton = (
            <Button
                className="button button--greyed-out"
                onClick={this._onCancel}
                key="cancel"
            >
                {modal.buttonCancel}
            </Button>
        );

        return (
            <Modal title={modal.title} className="modal--confirm" noClose>
                {!this.state.isCancelDisabled && (
                    <p dangerouslySetInnerHTML={{ __html: modal.text }}></p>
                )}

                {this._renderCancelledPayments()}

                <div className="modal__buttons">
                    {this.state.isResetButtonVisible
                        ? resetButton
                        : cancelButton}

                    {!this.state.isCancelDisabled && (
                        <Button
                            className="button button--primed"
                            onClick={this._onOk}
                        >
                            {modal.buttonOk}
                        </Button>
                    )}
                </div>
            </Modal>
        );
    }
});

function mapStateToProps(state) {
    return {
        modal: state.app.content.modals.paymentCancelConfirm
    };
}

module.exports = connect(mapStateToProps)(PaymentCancelConfirmModal);
