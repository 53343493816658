module.exports = {
    APP: {
        DEBUG: 'APP_DEBUG',
        DEEP_LINK: 'APP_DEEP_LINK',
        ERROR: 'APP_ERROR',
        EXPAND_PRICE_BREAKDOWN: 'APP_EXPAND_PRICE_BREAKDOWN',
        HIDE_MODAL: 'APP_HIDE_MODAL',
        PROFILE_LOADED: 'APP_PROFILE_LOADED',
        RESET_ALL: 'APP_RESET_ALL',
        SESSION_TIMEOUT: 'APP_SESSION_TIMEOUT',
        SET_BREAKPOINT: 'APP_SET_BREAKPOINT',
        SHOW_LOADER: 'APP_SHOW_LOADER',
        SHOW_LOADER_CENTRE_FINDER: 'APP_SHOW_LOADER_CENTRE_FINDER',
        SHOW_MODAL: 'APP_SHOW_MODAL',
        START_SESSION: 'APP_START_SESSION',
        STARTED: 'APP_STARTED',
        TOGGLE_SIDEBAR: 'APP_TOGGLE_SIDEBAR',
        SET_CUTOFF_DATE: 'APP_SET_CUTTOFF_DATE',
        SET_KIOSK_HOME_URL: 'APP_SET_KIOSK_HOME_URL',
        SET_TAGS_BLACK_LIST: 'APP_SET_TAGS_BLACK_LIST',
        SET_TAGS_WHITE_LIST: 'APP_SET_TAGS_WHITE_LIST'
    },
    BANK_DETAILS: {
        CHANGE_FIELD: 'BANK_DETAILS_CHANGE_FIELD',
        FORMS_VALIDATED: 'BANK_DETAILS_FORMS_VALIDATED',
        SET_DETAILS: 'BANK_DETAILS_SET_DETAILS',
        SET_PAYMENT_URL: 'BANK_DETAILS_SET_PAYMENT_URL',
        SET_START_DATE: 'BANK_DETAILS_SET_START_DATE',
        VALIDATING: 'BANK_DETAILS_VALIDATING',
        WIPEOUT: 'BANK_DETAILS_WIPEOUT'
    },
    BOOKING: {
        SET_PAGE: 'BOOKING_SET_PAGE',
        SET_GYM_PROFILE: 'BOOKING_SET_GYM_PROFILE',
        SET_URL_PARAMS: 'BOOKING_SET_URL_PARAMS',
        GET_CONCESSION_OPTIONS: 'BOOKING_GET_CONCESSION_OPTIONS'
    },
    CENTRE_FINDER: {
        CENTRE_DATA_RECEIVED: 'CENTRE_FINDER_CENTRE_DATA_RECEIVED',
        CENTRE_SELECTED: 'CENTRE_FINDER_CENTRE_SELECTED',
        GEOLOC_RESULTS_RECEIVED: 'CENTRE_FINDER_GEOLOC_RESULTS_RECEIVED',
        MORE_RESULTS_RECEIVED: 'CENTRE_FINDER_MORE_RESULTS_RECEIVED',
        RESET_GEOLOC_RESULTS: 'CENTRE_FINDER_RESET_GEOLOC_RESULTS',
        RESULTS_RECEIVED: 'CENTRE_FINDER_RESULTS_RECEIVED',
        SELECT_CENTRE: 'CENTRE_FINDER_SELECT_CENTRE',
        SET_FACILITIES: 'CENTRE_FINDER_SET_FACILITIES',
        SET_LESSON: 'CENTRE_FINDER_SET_LESSON',
        SET_FILTERED_FACILITIES: 'CENTRE_FINDER_SET_FILTERED_FACILITIES',
        SET_TEXT_OVERRIDES: 'CENTRE_FINDER_SET_TEXT_OVERRIDES',
        SET_LOCATION: 'CENTRE_FINDER_SET_LOCATION',
        SET_BUTTON_TEXT: 'CENTRE_FINDER_SET_BUTTON_TEXT',
        TOGGLE_EXPANDED_CENTRE: 'CENTRE_FINDER_TOGGLE_EXPANDED_CENTRE',
        WIPEOUT: 'CENTRE_FINDER_WIPEOUT',
        SET_FACILITY_FILTER_SEARCH_TERM: 'SET_FACILITY_FILTER_SEARCH_TERM',
        CLEAR_FACILITY_FILTER_SEARCH_TERM: 'CLEAR_FACILITY_FILTER_SEARCH_TERM',
        SET_FACILITY_FILTER_TERM_LIST: 'SET_FACILITY_FILTER_TERM_LIST'
    },
    DIRECT_DEBIT_PAGE: {
        TOGGLE_EXPANDED_USER: 'DIRECT_DEBIT_PAGE_TOGGLE_EXPANDED_USER',
    },
    DISCOUNTS: {
        DISCOUNT_SELECTED: 'DISCOUNTS_DISCOUNT_SELECTED',
        DISCOUNTS_RECEIVED: 'DISCOUNTS_DISCOUNTS_RECEIVED',
        DISCOUNT_REMOVED: 'DISCOUNTS_DISCOUNT_REMOVED',
        EXPAND_DISCOUNT: 'DISCOUNTS_EXPAND_DISCOUNT',
        WIPEOUT: 'DISCOUNTS_WIPEOUT'
    },
    DISCOUNT_CODES: {
        WIPEOUT: 'DISCOUNT_CODES_WIPEOUT',
        TOGGLE_EXPAND: 'DISCOUNT_TOGGLE_EXPANDED',
        TOGGLE_DISCOUNT_TYPE: 'DISCOUNT_TOGGLE_DISCOUNT_TYPE',
        SET_CORPORATE_DISCOUNT: 'DISCOUNT_SET_CORPORATE_DISCOUNT',
        SET_PROMO_CODE: 'DISCOUNT_SET_PROMO_CODE',
        SET_APPLY_DISABLED: 'DISCOUNT_SET_APPLY_DISABLED'
    },
    FAQS: {
        RESULTS_RECEIVED: 'FAQS_RESULTS_RECEIVED',
        VOTE_SUBMITTED: 'FAQS_VOTE_SUBMITTED',
        ANSWER_SCORING: 'FAQS_ANSWER_SCORING'
    },
    LESSONS: {
        ADD_CLASSES: 'LESSONS_ADD_CLASSES',
        ATTACH_CALL_ERROR: 'LESSONS_ATTACH_CALL_ERROR',
        CLEAR_ELIGIBILITY_USER: 'LESSONS_CLEAR_ELIGIBILITY_USER',
        CLEAR_LEVELS: 'LESSONS_CLEAR_LEVELS',
        CLEAR_SELECTIONS: 'LESSONS_CLEAR_SELECTIONS',
        GET_TYPES: 'LESSONS_GET_TYPES',
        SET_CENTRE_TYPES: 'LESSONS_SET_CENTRE_TYPES',
        SET_ELIGIBILITY_OPTIONS: 'LESSONS_SET_ELIGIBILITY_OPTIONS',
        SET_ELIGIBILITY: 'LESSONS_SET_ELIGIBILITY',
        SET_ERROR: 'LESSONS_SET_ERROR',
        SET_LEVELS_FOR_USER: 'LESSONS_SET_LEVELS_FOR_USER',
        SET_TIMETABLE: 'LESSONS_SET_TIMETABLE',
        SET_TYPE: 'LESSONS_SET_TYPE',
        SET_TIMER: 'LESSONS_SET_TIMER',
        SET_PAYMENT_TIMER: 'LESSONS_SET_PAYMENT_TIMER',
        UPDATE_USER: 'LESSONS_UPDATE_USER',
        UPDATE_SELECTED_LESSONS: 'LESSONS_UPDATE_SELECTED_LESSONS',
        SET_VIEW: 'LESSONS_SET_VIEW',
        WIPEOUT: 'LESSONS_WIPEOUT'
    },
    LIVECHAT: {
        HIDE: 'LIVECHAT_HIDE',
        OFFLINE: 'LIVECHAT_OFFLINE',
        ONLINE: 'LIVECHAT_ONLINE',
        READY: 'LIVECHAT_READY',
        SHOW: 'LIVECHAT_SHOW'
    },
    MEMBERSHIPS: {
        FREEZE: 'MEMBERSHIPS_FREEZE',
        MEMBERSHIPS_SELECTED: 'MEMBERSHIPS_MEMBERSHIPS_SELECTED',
        RESET: 'MEMBERSHIPS_RESET',
        SPECIAL_PANEL_VISIBILITY: 'MEMBERSHIPS_SPECIAL_PANEL_VISIBILITY',
        UNFREEZE: 'MEMBERSHIPS_UNFREEZE',
        WIPEOUT: 'MEMBERSHIPS_WIPEOUT'
    },
    PAYMENTS: {
        'REPORT': 'PAYMENTS_REPORT',
        'TAGGING_FIRED': 'PAYMENTS_TAGGING_FIRED',
        'WIPEOUT': 'PAYMENTS_WIPEOUT'
    },
    PERSONAL_DETAILS: {
        ADDRESS_SUGGESTIONS_LOADING: 'PERSONAL_DETAILS_ADDRESS_SUGGESTIONS_LOADING',
        ADDRESS_DETAILS_RECEIVED: 'PERSONAL_DETAILS_ADDRESS_DETAILS_RECEIVED',
        ADDRESS_SUGGESTIONS_RECEIVED: 'PERSONAL_DETAILS_ADDRESS_SUGGESTIONS_RECEIVED',
        FREEZE: 'PERSONAL_DETAILS_FREEZE',
        FREEZE_ALL: 'PERSONAL_DETAILS_FREEZE_ALL',
        FREEZE_USER: 'PERSONAL_DETAILS_FREEZE_USER',
        SET_MEDICAL_CONDITIONS_LIST: 'PERSONAL_DETAILS_SET_MEDICAL_CONDITIONS_LIST',
        TOGGLE_USER_LOGIN: 'PERSONAL_DETAILS_TOGGLE_USER_LOGIN',
        UNFREEZE: 'PERSONAL_DETAILS_UNFREEZE',
        UNFREEZE_ALL: 'PERSONAL_DETAILS_UNFREEZE_ALL',
        UNFREEZE_USER: 'PERSONAL_DETAILS_UNFREEZE_USER',
        USE_MANUAL_ADDRESS_ENTRY: 'PERSONAL_DETAILS_USE_MANUAL_ADDRESS_ENTRY',
        WIPEOUT: 'PERSONAL_DETAILS_WIPEOUT'
    },
    RESTRICTIONS: {
        'RESTRICT': 'RESTRICT'
    },
    SALES_LINES: {
        RESULTS_RECEIVED: 'SALES_LINES_RESULTS_RECEIVED',
        WIPEOUT: 'SALES_LINES_WIPEOUT'
    },
    SAVE_SELECTIONS: {
        GET_SELECTIONS: 'SAVE_SELECTIONS_GET_SELECTIONS',
        SELECTIONS_RECEIVED: 'SAVE_SELECTIONS_SELECTIONS_RECEIVED',
        TOKEN_RECEIVED: 'SAVE_SELECTIONS_TOKEN_RECEIVED',
        USER_DATA_VALIDATED: ' SAVE_SELECTIONS_USER_DATA_VALIDATED',
        VALIDATING: 'SAVE_SELECTIONS_VALIDATING'
    },
    SITE_BANDING: {
        FILL_APPLICABLE_SUBSCRIPTION_GROUPS: 'FILL_APPLICABLE_SUBSCRIPTION_GROUPS',
        TOGGLE_MAP: 'TOGGLE_MAP',
        TOGGLE_SHOW_CENTRES: 'TOGGLE_SHOW_CENTRES',
    },
    SELECTIONS: {
        APPLY_USER_DATA: 'SAVE_SELECTIONS_APPLY_USER_DATA',
        CHANGE_DURATION: 'SELECTIONS_CHANGE_DURATION',
        CHANGE_DATA: 'SELECTIONS_CHANGE_DATA',
        CHANGE_BANK_FIELD: 'SELECTIONS_CHANGE_BANK_FIELD',
        CHANGE_DATA_VALIDITY: 'SELECTIONS_CHANGE_DATA_VALIDITY',
        CHANGE_PROMO_TERMS: 'SELECTIONS_CHANGE_PROMO_TERMS',
        CONSIDER_PRESELECTED_SUBSCRIPTIONS_ONLY: 'CONSIDER_PRESELECTED_SUBSCRIPTIONS_ONLY',
        CREATE_DEEPLINK_USER: 'SELECTIONS_CREATE_DEEPLINK_USER',
        CLEAN_USER: 'SELECTIONS_CLEAN_USER',
        CONVERT_TO_JUNIOR: 'SELECTIONS_CONVERT_TO_JUNIOR',
        DEEPLINK_ADDONS: 'SELECTIONS_DEEPLINK_ADDONS',
        DEEPLINK_GROUPS: 'SELECTIONS_DEEPLINK_GROUPS',
        DESELECT_EXTRA: 'SELECTIONS_DESELECT_EXTRA',
        MAKE_UNIQUE_BANK_DETAILS: 'SELECTIONS_MAKE_UNIQUE_BANK_DETAILS',
        POST: 'SELECTIONS_POST',
        POST_RESPONSE_RECEIVED: 'SELECTIONS_POST_RESPONSE_RECEIVED',
        POST_REPORTING_AS_COMPLETE: 'POST_REPORTING_TRANSACTION_AS_COMPLETE',
        POST_SELECTIONS_ERROR: 'SELECTIONS_POST_SELECTIONS_ERROR',
        POST_SELECTIONS_SAVE_RESPONSE: 'SELECTIONS_POST_SELECTIONS_SAVE_RESPONSE',
        PRESELECT_USERS: 'PRESELECT_USERS',
        PRESELECT_SUBS: 'PRESELECT_SUBS',
        PRESELECT_SUBS_PROCESSED: 'PRESELECT_SUBS_PROCESSED',
        PRESELECT_PARTNER: 'PRESELECT_PARTNER',
        PRESELECT_GROUPS: 'PRESELECT_GROUPS',
        PROFILE_RECEIVED: 'SELECTIONS_PROFILE_RECIEVED',
        RECALCULATE_EXTRAS: 'RECALCULATE_EXTRAS',
        CHECK_EXTRAS: 'CHECK_EXTRAS',
        REMOVE_USER: 'SELECTIONS_REMOVE_USER',
        REMOVE_LESSON_FROM_USER: 'SELECTIONS_REMOVE_LESSON_FROM_USER',
        SELECT_EXTRA: 'SELECTIONS_SELECT_EXTRA',
        SET_DATA_FOR_USER: 'SELECTIONS_SET_DATA_FOR_USER',
        SET_EMMERGENCY_DETAILS: 'SELECTIONS_SET_EMMERGENCY_DETAILS',
        SET_CLASHED_USERS: 'SELECTIONS_SET_CLASHED_USERS',
        SET_USER_EXPIRED_SUBSCRIPTIONS: 'SELECTIONS_SET_USER_EXPIRED_SUBSCRIPTIONS',
        SET_IS_DISCOUNT_APPLIED: 'SELECTIONS_SET_IS_DISCOUNT_APPLIED',
        SET_EXPIRED_SHORT_USERS: 'SET_EXPIRED_SHORT_USERS',
        SET_EXISTING_USERS: 'SELECTIONS_SET_EXISTING_USERS',
        SET_EXTRA_FOR_USER: 'SELECTIONS_SET_EXTRA_FOR_USER',
        SET_FACILITY_FOR_USER: 'SELECTIONS_SET_FACILITY_FOR_USER',
        SET_FACILITY_PERMITTED_SITES_FOR_USER: 'SELECTIONS_SET_FACILITY_PERMITTED_SITES_FOR_USER',
        SET_FACILITY_PERMITTED_TIMES_FOR_USER: 'SELECTIONS_SET_FACILITY_PERMITTED_TIMES_FOR_USER',
        SET_LESSON_FOR_USER: 'SELECTIONS_SET_LESSON_FOR_USER',
        SET_MATCHING_USERS: 'SELECTIONS_SET_MATCHING_USERS',
        SET_PROMO: 'SET_PROMO',
        SET_RENEWAL_SUBS: 'SET_RENEWAL_SUBS',
        SET_STAFF_PROMO: 'SET_STAFF_PROMO',
        SET_USER_DISCOUNT_PROMO: 'SET_USER_DISCOUNT_PROMO',
        SET_START_DATE_OFFSET: 'SELECTIONS_SET_START_DATE_OFFSET',
        SET_TOTAL_PRICE: 'SET_TOTAL_PRICE',
        SET_USERS: 'SELECTIONS_SET_USERS',
        SET_USER_PROMO_CODE: 'SELECTIONS_SET_USER_PROMO_CODE',
        SET_REPORTING_ID: 'SET_REPORTING_TRANSACTION_ID',
        USE_LEAD_CONTACT: 'SELECTIONS_USE_LEAD_CONTACT',
        USE_LEAD_EMMERGENCY_DETAILS: 'SELECTIONS_USE_LEAD_EMMERGENCY_DETAILS',
        USE_LEAD_BANK_DETAILS: 'SELECTIONS_USE_LEAD_BANK_DETAILS',
        USER_FETCHED: 'SELECTIONS_USER_FETCHED',
        VALIDATING: 'SELECTIONS_VALIDATING',
        WIPEOUT: 'SELECTIONS_WIPEOUT',
    },
    STAGES: {
        SET_FLOW: 'STAGES_SET_FLOW',
        SET_STEP: 'STAGES_SET_STEP',
        SHOW_STEP: 'STAGES_SHOW_STEP',
        DISABLE_STEP_NAVBAR: 'STAGES_DISABLE_STEP_NAVBAR',
        ENABLE_STEP_NAVBAR: 'STAGES_ENABLE_STEP_NAVBAR',
        SET_SHORT_MEMBERSHIPS_PATH: 'SET_SHORT_MEMBERSHIPS_PATH',
        APPLICABLE_ADDON_SUBSCRIPTION_IDS: 'APPLICABLE_ADDON_SUBSCRIPTION_IDS',
        UNMOUNT_APPLICABLE_ADDON_SUBSCRIPTION_IDS: 'UNMOUNT_APPLICABLE_ADDON_SUBSCRIPTION_IDS'
    },
    STAFF: {
        STORE: 'STAFF_STORE',
        SHOW_LOGIN: 'STAFF_SHOW_LOGIN',
        APPLICABLE_ADDON_SUBSCRIPTION_IDS: 'APPLICABLE_ADDON_SUBSCRIPTION_IDS'
    },
    STAFF_SEARCH: {
        CHANGE_DATA: 'STAFF_SEARCH_CHANGE_DATA',
        CHANGE_DATA_VALIDITY: 'STAFF_SEARCH_CHANGE_DATA_VALIDITY',
        REMOVE_RESULTS: 'STAFF_REMOVE_RESULTS',
        SET_SEARCH_RESULTS: 'STAFF_SET_SEARCH_RESULTS',
        VALIDATING: 'STAFF_SEARCH_VALIDATING',
        WIPEOUT: 'STAFF_SEARCH_WIPEOUT'
    },
    TESTIMONIALS: {
        RESULTS_RECEIVED: 'TESTIMONIALS_RESULTS_RECEIVED',
        WIPEOUT: 'TESTIMONIALS_WIPEOUT'
    },
    USER: {
        CHECK_PROFILE: 'USER_CHECK_PROFILE',
        DELETE_PROFILE: 'USER_DELETE_PROFILE',
        LOGIN: 'USER_LOGIN',
        LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
        LOGOUT: 'USER_LOGOUT',
        LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
        PROFILE_RECEIVED: 'USER_PROFILE_RECEIVED',
        UNAUTHORIZED: 'USER_UNAUTHORIZED'
    }
};
