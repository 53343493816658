var React = require('react'),

    utils           = require('../../utils'),
    viewUtils       = require('../../utils/viewUtils'),

    Button          = require('../common/button'),
    SmartDate       = require('../common/forms/smartDate'),
    moment          = require('moment'),


    StartDate;

StartDate = React.createClass({
    displayName: 'StartDate',
    fields: {},

    getInitialState: function getInitialState() {
        return {
            date: this._getOffsetInputValue(this.props.startDateOffset.days),
            daysOffset: this.props.startDateOffset.days || 0,
            duration: this.props.duration,
            maxDaysOffset: utils.getOffsetDaysFromNow(this.props.originalMaxStartDate),
            valid: true
        };
    },

    componentDidUpdate: function componentDidUpdate() {
        this.setState({
            duration: this.props.duration
        });
    },

    shouldComponentUpdate: function shouldComponentUpdate(newProps, newState) {
        var shouldUpdate = false;

        if (newProps.maxStartDate !== this.props.maxStartDate ||
            newProps.startDateOffset.days !== this.props.startDateOffset.days ||
            newState.daysOffset !== this.state.daysOffset ||
            this.state.duration !== this.props.duration ||
            this.props.centerData !== newProps.centerData
        ) {
            shouldUpdate = true;
        }

        return shouldUpdate;
    },

    _getOffsetInputValue: function _getOffsetInputValue(daysOffset) {
        var inputDate;

        if (!daysOffset) {
            inputDate = utils.getDateWithoutTime(new Date().toString());

        } else {
            inputDate = utils.getDateWithOffset(new Date().toString(), { days: daysOffset });
        }

        return inputDate;
    },

    _unmountField: function unmountField(name) {
        delete this.fields[name];
    },

    _onValidateField: function _onValidateField(name, valid, errors) {
        var formValid = true,
            formErrors = [],
            key;

        this.fields[name] = {
            valid: valid,
            errors: errors
        };

        for (key in this.fields) {
            if (!this.fields[key].valid) {
                formValid = false;
                formErrors.push({
                    name: key,
                    errors: this.fields[key].errors
                });
            }
        }

        this.setState({
            valid: formValid,
            validationErrors: formErrors
        });
    },

    _validateForm: function _validateForm(e) {
        e.preventDefault();

        if (this.state.valid) {
            this._changeStartDateOffset(this.state.daysOffset);

        } else {
            this.setState({
                showErrors: true
            });
        }
    },

    _onChangeDateInput: function _onChangeDateInput(value) {
        var dateParts = utils.getDateParts(value),
            valueDate,
            newState,
            nbDays;

        if (dateParts && dateParts.day) {
            valueDate = new Date(dateParts.year, (dateParts.month - 1), dateParts.day);
            nbDays = utils.getOffsetDaysFromNow(valueDate);
            newState;
        }

        if (nbDays !== undefined) {
            newState = {
                daysOffset: nbDays
            };

            if (nbDays < 0 || nbDays > this.state.maxDaysOffset) {
                newState.valid = false;
            } else {
                newState.valid = true;
            }

            this.props.onCheckStartDate(newState.valid);
            this.setState(newState);
        }
    },

    _onChangeDate: function _onChangeDate(nbDays) {
        if (nbDays !== this.state.daysOffset) {
            this.setState({
                daysOffset: nbDays,
                valid: true
            });

            this._changeStartDateOffset(nbDays);
        }

        this.props.onCheckStartDate(true);
    },

    _changeStartDateOffset: function _changeStartDateOffset (days) {
        var date = this._getOffsetInputValue(days),
            dateParts = utils.getDateParts(date);

        this.props.onChangeStartDateOffset(days, new Date(dateParts.year, (dateParts.month - 1), dateParts.day));
    },

    render: function() {
        var content = this.props.content,
            todayChecked = !this.state.daysOffset,
            tomorrowChecked = this.state.daysOffset === 1,
            anotherChecked = this.state.daysOffset > 1 || this.state.daysOffset < 0,
            originalMaxStartDateTimestamp = this.props.originalMaxStartDate && this.props.originalMaxStartDate.getTime && this.props.originalMaxStartDate.getTime(),// + (24 * 3600 * 1000),
            errorMessage = !this.state.valid ? <p className='start-date__error-message'>{ this.props.content.errorMessage }</p> : null,
            buttonDisabled = !this.state.valid || this.state.daysOffset === this.props.startDateOffset.days,
            buttonProps = {
                className: 'button start-date__picker-button' + ( buttonDisabled ? ' button--disabled' : ''),
                disabled: buttonDisabled,
                onClick: this._validateForm
            },
            inputValue = this._getOffsetInputValue(this.state.daysOffset),
            users = this.props.users.objects,
            dateNotice,
            startDateIsRenewal = this.props.startDateIsRenewal;

        if (users && users.length) {
            for (var i = 0; i < users.length; i++ ) {
                var availableSubs = users[i].availableSubscriptions;

                for (var j = 0; j < availableSubs.length; j++ ) {
                    var sub = availableSubs[j];

                    if(sub.isAdjustedStartDate && this.state.duration === sub.duration) {
                        // dateNotice = <div className='form__error-zone'>
                        //         <span className='form__error personal-details__error'>{ content.adjustedDate.replace('{subscriptionName}', sub.desc).replace('{actualStartDate}', viewUtils.formatDate(sub.startDate)) }</span>
                        //     </div>;

                        return null;
                    }
                }
            }
        }

        var endDate = null;

        if (this.props.durationType === 1) {
            const formattedEndDate = (input = '') => {
                let date = input;
                const subEndDateParts = date.split('-');
                if (subEndDateParts.length === 3) {
                    const d = `0${subEndDateParts[2]}`.substr(-2);
                    const m = `0${subEndDateParts[1]}`.substr(-2);
                    const y = subEndDateParts[0];
                    date = `${d}/${m}/${y}`;
                }
                return date;
            };

            endDate = (
                <div className='start-date__end-dates'>
                    <p>Membership end dates:</p>
                    {this.props.users.objects.map((u, j) => (
                        <div className='start-date__end-dates__item' key={ 'u_' + j }>
                            <div className='start-date__end-dates__item__username'>
                                { u.info.firstName.value + ' ' + u.info.lastName.value } <span>({ u.typeDesc })</span>
                            </div>
                            {u.availableSubscriptions.map((sub, k) =>
                                <div className='start-date__end-dates__item__date' key={ 'k_' + k }>
                                    { sub.desc } ends:
                                    <span>
                                        { formattedEndDate(sub.endDate) }
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        return <div className='module-container'>
            <div className='module module--inactive'>
                <div className='start-date'>
                    <h2>{ content.title }</h2>
                    <p>{ content.description }</p>

                    { dateNotice }

                    { startDateIsRenewal &&
                        <p className="start-date__info">{ content.startDateIsRenewal}</p>
                    }

                    <div className='start-date__options'>
                        <input onChange={ this._onChangeDate.bind(null, 0) } className='forms__input--radio' type='radio' name='quick_option' id='quick_option_today' value='0' checked={ todayChecked } />
                        <label htmlFor='quick_option_today'><span></span>{ content.today }</label>

                        <input onChange={ this._onChangeDate.bind(null, 1) } className='forms__input--radio' type='radio' name='quick_option' id='quick_option_tomorrow' value='1' checked={ tomorrowChecked } />
                        <label htmlFor='quick_option_tomorrow'><span></span>{ content.tomorrow }</label>

                        <input onChange={ this._onChangeDate.bind(null, 2) } className='forms__input--radio' type='radio' name='quick_option' id='quick_option_another' value='1' checked={ anotherChecked } />
                        <label htmlFor='quick_option_another'><span></span>{ content.anotherDate }</label>
                    </div>

                    { anotherChecked && <div>
                        <p className='start-date__choose-a-date'>{ content.orChoseADate.replace('{date}', viewUtils.formatDate(this.props.originalMaxStartDate)) }</p>
                        { errorMessage }
                        <div className='start-date__picker'>
                            <SmartDate className=''
                                input={ {
                                    onChange: this._onChangeDateInput,
                                    placeholder: content.fields.startDate.placeholder
                                } }
                                name={ 'membership_start_date'}
                                range={ {
                                    min: utils.getDateWithoutTime(Date.now()).getTime(),
                                    max: originalMaxStartDateTimestamp
                                } }
                                valid={ this.state.valid }
                                value={ inputValue }
                                />

                            <Button { ...buttonProps } >{ content.buttonSubmit }</Button>
                        </div>
                    </div> }
                </div>
                { endDate }
            </div>
        </div>;
    }
});

StartDate.propTypes = {
    content: React.PropTypes.object,
    duration: React.PropTypes.number,
    originalMaxStartDate: React.PropTypes.object,
    onChangeStartDateOffset: React.PropTypes.func,
    onCheckStartDate: React.PropTypes.func,
    startDateOffset: React.PropTypes.object,
    users: React.PropTypes.object,
    centerData: React.PropTypes.object,
    startDateIsRenewal: React.PropTypes.bool
};

module.exports = StartDate;
