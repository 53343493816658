var React                   = require('react'),
    connect                 = require('react-redux').connect,
    FocusTrap               = require('focus-trap-react'),

    BookingTimeout          = require('./bookingTimeout'),
    ConfirmCentreReset      = require('./confirmCentreReset'),
    ConfirmExtrasChange     = require('./confirmExtrasChange'),
    ConfirmLevel            = require('./confirmLevel'),
    ConfirmRemoveDiscount   = require('./confirmRemoveDiscount'),
    ConvenientSession       = require('./convenientSession'),
    CorporateDiscount       = require('./corporateDiscount'),
    directDebit             = require('./directDebit'),
    Discount                = require('./discount'),
    FavouriteCentre         = require('./favouriteCentre'),
    NoDiscount              = require('./noDiscount'),
    Error                   = require('./error'),
    PaymentCancelConfirm    = require('./paymentCancelConfirm'),
    CompletePaymentAtTill   = require('./completePaymentAtTill'),
    PaymentFound            = require('./paymentFound'),
    PaymentNotFound         = require('./paymentNotFound'),
    RemoveLessonsUser       = require('./removeLessonsUser'),
    SaveSelections          = require('./saveSelections'),
    Success                 = require('./success'),
    TimedOut                = require('./timedOut'),
    Video                   = require('./video'),

    Modals;

Modals = React.createClass({
    getInitialState: function getInitialState() {
        return {
            activeTrap: false,
            map: {
                'bookingTimeout': BookingTimeout,
                'confirmCentreReset': ConfirmCentreReset,
                'confirmExtrasChange': ConfirmExtrasChange,
                'confirmLevel': ConfirmLevel,
                'confirmRemoveDiscount': ConfirmRemoveDiscount,
                'convenientSession': ConvenientSession,
                'completePaymentAtTill': CompletePaymentAtTill,
                'corporateDiscount': CorporateDiscount,
                'directDebit': directDebit,
                'discount': Discount,
                'favouriteCentre': FavouriteCentre,
                'noDiscount': NoDiscount,
                'error': Error,
                'paymentCancelConfirm': PaymentCancelConfirm,
                'paymentFound': PaymentFound,
                'paymentNotFound': PaymentNotFound,
                'removeLessonsUser': RemoveLessonsUser,
                'saveSelections': SaveSelections,
                'success': Success,
                'timedOut': TimedOut,
                'video': Video
            }
        };
    },

    componentDidMount: function componentDidMount() {
        this.mountTrap();
    },

    componentWillUnmount: function componentWillUnmount() {
        this.unmountTrap();
    },

    mountTrap: function mountTrap() {
        this.setState({ activeTrap: true });
    },

    unmountTrap: function unmountTrap() {
        this.setState({ activeTrap: false });
    },

    render: function render() {
        var modals = [],
            key;

        for (key in this.props.showModals) {
            if (this.state.map[key]) {
                modals.push(React.createElement(this.state.map[key], { key: key, id: key, options: this.props.showModals[key].options }));
            }
        }

        if (modals.length) {
            return (<FocusTrap focusTrapOptions={{ onDeactivate: this.unmountTrap }}>
                <div className='modal__bg'></div>
                { modals }
            </FocusTrap>);

        } else {
            return null;
        }
    }
});

function mapStateToProps(state) {
    return {
        showModals: state.app.showModals
    };
}

module.exports = connect(mapStateToProps)(Modals);
