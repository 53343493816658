const modelItem = { sub_type_group: '', desc_search: '', desc_replace: '', additional_info: '', case_sensitive: false, created_at: '' };
const modelSub = { id: '', groups: [] };
const modelItems = { 0: modelItem };

export default class TextOverrides {
    constructor() {
        this.groups = [''];
        this.overrides = modelItems;
    }

    /** @return {TextOverrides} Singleton */
    static getInstance() {
        if (!TextOverrides.instance) {
            TextOverrides.instance = new this;
        }
        return TextOverrides.instance;
    }

    // private methods
    getItemsForGroups() {
        const groups = this.getGroups();
        const items = this.getOverrides().filter((item = modelItem) => {
            const tags = this.getGroups(item.sub_type_group.split(','));
            return tags.find((tag = '') => groups.includes(tag));
        });
        return items;
    }

    getItemsMatch(input = '') {
        return this.getItemsForGroups().filter((item = modelItem) =>
            stringMatch(input, item.desc_search));
    }

    getGroups(input = []) {
        return (input.length ? input : this.groups)
            .map((tag = '') => tag.trim().toUpperCase())
            .sort();
    }

    getOverrides() {
        return Object.values(this.overrides)
            .sort((itemA, itemB) => itemA.created_at.localeCompare(itemB.created_at));
    }

    // public setters
    setSub(sub = modelSub) {
        return this.setGroups(sub.groups);
    }

    setGroups(groups = []) {
        if (
            JSON.stringify(groups) ===
            JSON.stringify(this.groups)
        ) {
            return this;
        }
        this.groups = groups;
        return this;
    }

    setOverrides(overrides = modelItems) {
        if (
            JSON.stringify(overrides) ===
            JSON.stringify(this.overrides)
        ) {
            return this;
        }
        this.overrides = overrides;
        return this;
    }

    // public methods
    replace(input = '') {
        if (!input) {
            return input;
        }
        return this.getItemsMatch(input)
            .filter((item = modelItem) => item.desc_replace)
            .reduce((sum = '', item = modelItem) => {
                const { desc_search, desc_replace, case_sensitive } = item;
                const search = case_sensitive ? desc_search : new RegExp(desc_search, 'ig');
                return sum.replace(search, desc_replace);
            }, input);
    }

    getReplacement(input = '') {
        const replace = this.replace(input);
        return replace === input ? '' : replace;
    }

    getInfo(input = '') {
        if (!input) {
            return input;
        }
        return this.getItemsMatch(input)
            .filter((item = modelItem) => item.additional_info)
            .reduce((sum = [], item = modelItem) => [...sum, item.additional_info], [])
            .join('. ');
    }
}

export const textOverrides = TextOverrides.getInstance();

function stringMatch(inputA = '', inputB = '', sensitive = false) {
    const stringA = String(inputA);
    const stringB = String(inputB);
    const trimA = (sensitive ? stringA.toLowerCase() : stringA).trim();
    const trimB = (sensitive ? stringB.toLowerCase() : stringB).trim();
    return trimA.includes(trimB) || trimB.includes(trimA);
}
