var React               = require('react'), // eslint-disable-line no-unused-vars
    RetrieveSelections  = require('../components/retrieveSelections');

module.exports = [{

    path: '/selections/:token?',

    handler: function (params) {
        return {
            component: <RetrieveSelections token={ params.token } />
        };
    }
}];
