var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/header'),
    Component       = require('../components/lessons/');

module.exports = [{

    path: '/lessons',

    stage: true,

    handler: function (params, query) {

        return {
            header: <Header showStages={ true } />,
            component: <Component query={ query } />
        };
    }
}];
