import React from "react";
import { connect } from "react-redux";
import Button from "../../common/button";

// Configuration
import config from "../../../config";

// Actions
import actions from "../../../actions";
import { LegendIcon } from "../../common/google/maps/legend-icon";

class CentreSummary extends React.Component {
    /**
     * Expands information
     * @param {mixed} centreId    This is simply the "index" in the unordered list
     * @param {MouseEvent} e         Mouse click event
     */
    moreInformation(centreId, e) {
        // Prevent default & event bubbling
        e.preventDefault();

        // Expand / collapse state
        // Invoke parent method for toggling information (@todo manage side effects!)
        this.props.toggleDetailedCentreInformation(centreId);
    }

    /**
     * Change centre event (resets selection)
     * @param {MouseClick} e Mouse click event
     */
    changeCentre(e) {
        // Prevent default & event bubbling
        e.preventDefault();

        // Show "reset" modal to prompt user
        this.props.dispatch(
            actions.app.showModal("confirmCentreReset", {
                redirectTo: `/?siteid=${this.props.centre.site_id}`
            })
        );
    }

    /**
     * @todo bring in components dynamically - there is a lot of inline conditions that make it difficult to understand
     */
    render() {
        /**
         * Determines which icon to show for upgrades
         *
         * @todo move this outside of this react component because it's generic
         * @todo move this outside this block because this will be defined every re-render
         * @todo move conditionals outside and make it more dynamic! Named function does too many things here
         *
         * @param {number} centreBand
         * @param {number} selectedBand
         * @param {mixed} upgradableBand
         */
        const whichMarker = (centreBand, selectedCentreBand, upgradeBand) => {
            // Already included
            if (this.props.isSelected) {
                return (
                    <LegendIcon
                        inline={true}
                        key="icon-selected"
                        colour={config.services.googleapis.markericon.sameCentreColour}
                    ></LegendIcon>
                );
            }

            // If this centre is within an upgrade band, it's an orange marker
            // We say it's "orange" if the centre's band sits between the selected band and the ugprade band

            console.log('----');
            console.log('site_id',this.props.centre.site_id);
            console.log(`upgradeBand: ${upgradeBand}`);
            console.log(`centreBand: ${centreBand}`);
            console.log(`selectedCentreBand: ${selectedCentreBand}`);

            console.log('show blue', upgradeBand &&
            centreBand > selectedCentreBand &&
            centreBand <= upgradeBand)

            console.log('----');

            if (
                upgradeBand &&
                centreBand &&
                centreBand > selectedCentreBand &&
                centreBand <= upgradeBand
            ) {
                console.log('80');
                return (
                    <LegendIcon
                        inline={true}
                        key="icon-upgradable"
                        colour={config.services.googleapis.markericon.includedInUpgradeColour}
                    ></LegendIcon>
                );
            }

            return centreBand > selectedCentreBand || !centreBand ? (
                <LegendIcon
                    inline={true}
                    key="icon-out-of-tier"
                    colour={config.services.googleapis.markericon.notIncludedColour}
                ></LegendIcon>
            ) : (
                <LegendIcon
                    inline={true}
                    key="icon-within-tier"
                    colour={config.services.googleapis.markericon.includedColour}
                ></LegendIcon>
            );
        };

        return (
            <div className="centre-finder__results-actions">
                <h4 className="centre-finder__results-item-name-inline">
                    <span className="centre-finder__results-item-icon">
                        {whichMarker(
                            this.props.centre.banding,
                            this.props.selectedCentreBanding,
                            this.props.showUpgradesUntil
                        )}
                    </span>

                    {this.props.centre.name}
                    {this.props.showUpgradeHeadlinePrices &&
                    !this.props.isSelected &&
                    this.props.centre.banding > this.props.selectedCentreBanding
                        ? [
                              <br key="headline_prices_br" />,
                              <small key="headline_prices">
                                  {this.props.centre
                                      .headline_monthly_price_in_pence > 0.0 ? (
                                      <span key={"headline-price"}>
                                          £
                                          {(
                                              this.props.centre
                                                  .headline_monthly_price_in_pence /
                                                  100 || 0.0
                                          ).toFixed(2)}{" "}
                                          monthly
                                      </span>
                                  ) : null}
                                  {this.props.centre
                                      .headline_monthly_price_in_pence > 0.0 &&
                                  this.props.centre
                                      .headline_annual_price_in_pence > 0.0 ? (
                                      <span key={"headline-breaker"}> / </span>
                                  ) : null}
                                  {this.props.centre
                                      .headline_annual_price_in_pence > 0.0 ? (
                                      <span key={"headline-annual-price"}>
                                          £
                                          {(
                                              this.props.centre
                                                  .headline_annual_price_in_pence /
                                                  100 || 0.0
                                          ).toFixed(2)}{" "}
                                          annually
                                      </span>
                                  ) : null}
                              </small>
                          ]
                        : null}
                </h4>
                <span className="centre-finder__results-item-distance">
                    {(this.props.centre[this.props.distanceProp] >= 0.0
                        ? this.props.centre[this.props.distanceProp]
                        : this.props.centre.distance
                    ).toFixed(1) + " mi"}
                </span>
                <span
                    className="centre-finder__results-item-more"
                    style={{ marginLeft: "auto" }}
                >
                    {/* Information button */}
                    <Button
                        key={"ci" + this.props.centre.site_id}
                        className={`button__more-info ${
                            this.props.isExpanded
                                ? "button__more-info--expanded"
                                : ""
                        }`}
                        onClick={this.moreInformation.bind(
                            this,
                            this.props.centre.site_id
                        )}
                    >
                        {this.props.content.info}
                    </Button>
                </span>

                {/* Shall we even show the option to change centres? */}
                {this.props.showChangeCentreButton ? (
                    <span className="centre-finder__results-item-select">
                        {/* Rendered "change" button if this centre isn't already selected */}
                        {!this.props.isSelected ? (
                            <Button
                                key={"cs" + this.props.centre.site_id}
                                className="button"
                                onClick={this.changeCentre.bind(this)}
                            >
                                {this.props.content.buttonSelect}
                            </Button>
                        ) : (
                            <Button
                                key={"cs" + this.props.centre.site_id}
                                className="button button--disabled"
                                readOnly
                            >
                                {this.props.content.buttonSelected}
                            </Button>
                        )}
                    </span>
                ) : null}
            </div>
        );
    }
}

/**
 * State lenses
 * @param {object} state Redux store
 */
const mapStateToProps = state => {
    return {
        // CMS fed text
        content: {
            ...state.app.content.stage1.centreDetails,
            ...state.app.content.siteBanding
        }
    };
};

export default connect(mapStateToProps)(CentreSummary);
