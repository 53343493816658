var React = require('react'),
    actions = require('../../actions'),
    connect = require('react-redux').connect,
    Content = require('./content'),
    Sidebar = require('../sidebar'),
    Stage1Index;

Stage1Index = React.createClass({
    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if (this.props.preselectionSubProcessed !== newProps.preselectionSubProcessed && newProps.preselectionSubProcessed) {
            this.props.dispatch(actions.stages.setStep(2));
            this.props.dispatch(actions.stages.showStep(2));
        }
    },
    render: function() {
        return (
            <div className="page">
                <Content />
                <Sidebar />
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        preselectionSubProcessed: state.selections.preselectionSubProcessed,

    };
}
module.exports = connect(mapStateToProps)(Stage1Index);
