var React           = require('react'),

    Button          = require('../../common/button'),
    Membership;

import { connect } from "react-redux";

import Notice from '../../common/notices/notice';
import { requiresProofOfEligibility } from '../../common/proof-of-eligibility';

Membership = React.createClass({
    render: function() {
        var content = this.props.content,
            details = this.props.details,
            title = details.desc,
            titleClass = details.id,
            index = this.props.index,
            self = this,
            Panel = this.props.specialPanel,

            showMoreButton,
            panelInstance,
            description;

        const iconClass = ( titleClass === 'freeprofile' ) ? titleClass : title;

        showMoreButton = <Button className={`button__more-info button__more-info--${ iconClass } membership-${ details.id }`} onClick={ self.props.onShowMore }>{ content.buttonShowMore }</Button>;
        description = <div className='membership-selection__item-description'>{ details.comment }</div>;

        if (Panel) {
            panelInstance = <Panel adultType={ this.props.adultType } />;
        }

        if (this.props.expanded) {
            showMoreButton = <Button className={ `button__more-info button__more-info--expanded membership-${ details.id }` } onClick={ self.props.onShowMore }>{ content.buttonShowMore }</Button>;
        }

        return (<li className='module__list-item'>
            <div className='membership-selection__results-meta'>
                <div className='membership-selection__item-title'><h4 className={'memberships__type memberships__type--' + iconClass}>{ title }</h4></div>
                <div className='membership-selection__item-options'>
                    <div className='membership-selection__item-more'>{ showMoreButton }</div>
                    <div className='membership-selection__item-controls'>
                        <Button key={ 'bmm_' + this.props.index } className='membership-selection__item-controls-button membership-selection__item-controls-button--minus' onClick={ this.props.onRemove.bind(null, details.id) }><span>-</span></Button>
                        <span className='membership-selection__item-controls-count'>{ this.props.count || 0 }</span>
                        <Button key={ 'bmp_' + this.props.index } className='membership-selection__item-controls-button membership-selection__item-controls-button--plus' onClick={ this.props.onAdd.bind(null, details.id) }><span>+</span></Button>
                    </div>
                </div>
                <div className={ 'module__list-item-details module__list-item-details--membership-selection-' + (index + 1)}>
                    <div className='inner'>
                        { description }

                        <br />

                        {/* Notice message to ensure customers understand proof of eligibility on first visit */}
                        {/* Obviously if there's no message we don't realy want to display an empty alert */}
                        { this.props.doesRequireProofOfEligibility( this.props.details ) && this.props.concessionMessage && <Notice message={ this.props.concessionMessage } /> }
                    </div>
                </div>
                { panelInstance }
            </div>
        </li>);
    }
});

Membership.propTypes = {
    adultType: React.PropTypes.string,
    content: React.PropTypes.object,
    count: React.PropTypes.number,
    details: React.PropTypes.object,
    expanded: React.PropTypes.bool,
    index: React.PropTypes.number,
    onShowMore: React.PropTypes.func,
    onAdd: React.PropTypes.func,
    onRemove: React.PropTypes.func,
    users: React.PropTypes.object
};

module.exports = connect(state => ({
    concessionMessage: state.app.content.notices.concession.message,

    // Type of membership (adult, concession etc)
    // ONLY execute this in joinonline, not lessons online
    doesRequireProofOfEligibility: details => !state.lessons.inView && requiresProofOfEligibility( details )
}))(Membership);
