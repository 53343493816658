var React           = require('react'),
    connect         = require('react-redux').connect,
    config          = require('../../config'),
    actions         = require('../../actions'),
    KioskHeader         = require('./kioskHeader'),

    Header;

Header = React.createClass({
    _goHome: function (e) {
        e.preventDefault();
        this.props.dispatch(actions.stages.goHome());
    },

    render: function() {
        var content = this.props.content,
            homepageUrl = config.services.app.urls.homepage,
            headerClass;

        headerClass = 'header header--no-stages';

        return (
            <header className={ headerClass }>
                { this.props.kioskHomeUrl && (
                    <KioskHeader 
                        kioskHomeUrl={ this.props.kioskHomeUrl } 
                        logoutUrl={ config.services.app.urls.logout } 
                        showLogout={ this.props.isUserLoggedIn } 
                    /> 
                )}
                <div className='header__band'>
                    <div className='header__logo-container'>
                        <a key='gohome' href={ homepageUrl } onClick={ this._goHome } title={ content.title }>
                            <img className='header__logo mobile' src='/img/joinonline/logo-block-white.svg' />
                            <img className='header__logo desktop' src='/img/joinonline/logo-block.svg' />
                        </a>
                    </div>
                </div>
            </header>
        );
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.header,
        kioskHomeUrl: state.app.kioskHomeUrl,
        isUserLoggedIn: state.user.loggedIn
    };
}

module.exports = connect(mapStateToProps)(Header);
