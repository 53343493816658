import Header from '../components/common/header';
import Confirm from '../components/confirm';
import history from './history';
import React from 'react';

const DEFAULT_APP_MODE_KEY = 'appmode'; // from main.js
const DEFAULT_APP_MODE = '/memberships';
const DEFAULT_CONFIRM_PAGE = '/confirm';

export const confirmPathsMap = {
    '/memberships': '/confirm-join',
    '/lessons': '/confirm-lessons',
    '/short-memberships': '/confirm-shortmemberships',
    '/gym-profile': '/confirm-gym-profile',
    '/booking-profile': '/confirm-booking-profile'
};

export const confirmRoutes = Object.entries(
    confirmPathsMap
).map(([origin, path]) => makeConfirmPageRoute(origin, path));

export function isConfirmPage() {
    const { pathname } = window.location;
    const confirmPattern = /confirm*/;
    const isConfirmPage = confirmPattern.test(pathname);
    const confirmPage = getConfirmationPage();
    const onConfirmPage = pathname === confirmPage;
    return isConfirmPage || onConfirmPage;
}

export function goToConfirmPage() {
    const { pathname } = window.location;
    const confirmPage = getConfirmationPage();
    if (pathname === confirmPage) {
        return null; // already at destination
    }
    return history.push(confirmPage);
}

/** @return {string} confirmPage like "/confirm" */
function getConfirmationPage() {
    const appMode =
        sessionStorage.getItem(DEFAULT_APP_MODE_KEY) || DEFAULT_APP_MODE;
    const confirmPage = confirmPathsMap[appMode] || DEFAULT_CONFIRM_PAGE;
    return confirmPage;
}

function makeConfirmPageRoute(origin, path) {
    return {
        path,

        handler: function() {
            return {
                noBg: true,
                header: <Header />,
                component: <Confirm origin={origin} path={path} />
            };
        }
    };
}
