var debug           = require('debug')('actions-payments'),
    actionTypes     = require('./actionTypes.js'),
    config          = require('../config'),
    httpFetch       = require('../utils/httpFetch'),
    appActions      = require('./app');

module.exports = exports = {
    report: function report(data, callback) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.app.urls.paymentReport, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                params: {
                    ...data.payload,
                    source_type: window.location.pathname.split('/').filter(l => l.length).pop()
                }
            }, true)
            .then(function() {
                dispatch(exports.setStatus({
                    status: data.payload.status
                }));
                dispatch(appActions.showLoader(false));

                callback && callback();
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
                dispatch(exports.setStatus({
                    status: data.payload.status,
                    error:  e.message
                }));
                dispatch(appActions.showLoader(false));

                callback && callback(e);
            });
        };
    },

    setStatus: function setStatus(data) {
        return {
            error: data.error,
            gatewayError:  data.gatewayError,
            status: data.status,
            type: actionTypes.PAYMENTS.REPORT
        };
    },

    taggingFired: function taggingFired() {
        return {
            type: actionTypes.PAYMENTS.TAGGING_FIRED
        };
    },

    wipeout: function wipeout(data) {
        return {
            type: actionTypes.PAYMENTS.WIPEOUT
        };
    }
};
