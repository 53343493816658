var React           = require('react'),

    FrozenFacilitiesPanel;

FrozenFacilitiesPanel = React.createClass({

    render: function() {
        var facilities = [],
            selectedCentre,
            selectedTime,
            facility,
            key;

        for (key in this.props.user.facilities) {
            facility = this.props.user.facilities[key];

            if (facility.selected) {
                selectedCentre = this.props.centre.permittedSites[facility.permittedSites];
                selectedTime = this.props.centre.permittedTimes[facility.permittedTimes];

                facilities.push(<div key={ this.props.id + '_u_' + key } className='facilities-panel__selected-list'>
                    <span className='facilities-panel__list-item-label' dangerouslySetInnerHTML={ {__html: `${this.props.centre.permittedFacilities[key].desc}, ` } } />
                    <span className='facilities-panel__list-item-value' dangerouslySetInnerHTML={ {__html: `${selectedCentre.desc}, ` } } />
                    <span className='facilities-panel__list-item-value' dangerouslySetInnerHTML={ {__html: selectedTime.desc } } />
                </div>);
            }
        }

        return (<div className='facilities-panel__section'>
            { facilities }
        </div>);
    }
});

FrozenFacilitiesPanel.propTypes = {
    id: React.PropTypes.string,
    index: React.PropTypes.number,
    user: React.PropTypes.object,
    centre: React.PropTypes.object,
};

module.exports = FrozenFacilitiesPanel;
