var React           = require('react'),
    connect         = require('react-redux').connect,
    cookie          = require('react-cookie'),

    actions         = require('../../actions'),
    Faqs            = require('../faqs'),

    CentreFinder    = require('../stage1/centreFinder'),
    Timetable       = require('./timetable'),
    Levels          = require('./levels'),
    Lessons         = require('./selectLessons'),

    FooterSecurity  = require('../common/footerSecurity'),

    LessonsContent;

LessonsContent = React.createClass({

    getInitialState() {
        return {
            centre: this.props.centre
        };
    },

    componentDidUpdate() {
        if (!this.props.centre) {
            let headerBand = $('.stages-band:visible').get(0);
            let pageTop = headerBand.offsetTop + headerBand.offsetHeight;
            window.scrollTo(0, $('.module-container').get(0).offsetTop - pageTop);
        }
    },

    componentWillReceiveProps(nextProps) {
        if (this.props.staffName) {
            let favouriteCentre = cookie.load('favouriteCentre');
            let newCentre = nextProps &&
                nextProps.centre &&
                nextProps.centre.info &&
                nextProps.centre.info.site_id;

            if (newCentre && !this.state.centre && (!favouriteCentre || favouriteCentre != newCentre)) {
                this.props.dispatch(actions.app.showModal('favouriteCentre'));
            }
        }

        this.setState({
            centre: nextProps.centre
        });
    },

    resetStaffId: function resetStaffId() {
        this.props.dispatch(actions.staffLogin.logout());
        this.props.dispatch(actions.staffLogin.showLogin(true));

        window.dataLayer.push({
            staffLogin: 'false'
        });
    },

    render: function() {
        var pageContent;

        if (this.state.centre) {
            switch(this.props.flow[this.props.showStep]) {
                case 5:
                    pageContent = <Timetable />;
                    break;
                case 6:
                    pageContent = (<Levels />);
                    break;
                case 7:
                    pageContent = <Lessons />;
                    break;
            }
        } else {
            pageContent = <Faqs />;
        }

        return (
            <div className='container'>
                { this.props.staffName &&
                    <h3 onClick={this.resetStaffId} className="staff-reset-link">Applied Staff: {this.props.staffName} ({this.props.staffId}). Reset?</h3>
                }

                <CentreFinder staffName={this.props.staffName} />

                { pageContent }

            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.lessons,
        flow: state.stages.flow,
        lessons: state.lessons,
        showStep: state.stages.showStep,
        staffId: state.staffLogin.staffId,
        staffName: state.staffLogin.staffName,
        user: state.user.loggedIn
    };
}

module.exports = connect(mapStateToProps)(LessonsContent);
