var React           = require('react'),
    moment          = require('moment'),

    viewUtils       = require('../../utils/viewUtils'),
    Input           = require('../common/forms/input'),

    renewBlacklist  = require('./renewBlacklist'),

    Subscription;

Subscription = React.createClass({
    render: function() {
        var content = this.props.content,
            memberSubs = this.props.memberSubs,
            owedSubs = this.props.owedSubs,
            renewalAvailable = false,
            availableSubs = [],
            renewableSubs,
            noSubsMessage,
            i;

        if(memberSubs) {
            for(var sub in memberSubs) {
                var isOwedSub = false,
                    currentSub = memberSubs[sub],
                    currentDate = moment(window.now).format('x'),
                    earliestRenewal = moment(currentSub.EarliestRenewal).format('x'),
                    latestRenewal = moment(currentSub.LatestRenewal).format('x');

                for(var owed in owedSubs) {
                    if(owedSubs[owed].subs) {
                        for(var i = 0; i < owedSubs[owed].subs.length; i++) {
                            if(owedSubs[owed].subs[i] === currentSub.SubscriptionRef) {
                                isOwedSub = true;
                                break;
                            }
                        }
                    }
                }

                if(currentSub.is_fsg && !this.props.showFSG) {
                    continue;
                }

                if(currentDate > earliestRenewal && currentDate < latestRenewal && !isOwedSub) {

                    var blacklist = renewBlacklist.blacklist.filter(subId => {return currentSub.SubTypeId === subId; });
                    renewalAvailable = blacklist.length === 0;

                    availableSubs.push(<div className="renewals__input-container" key={sub}>
                        <Input className='renewals__input'
                            input={ {
                                onChange: this.props.onChangeInput.bind(null, currentSub),
                                className:  'forms__input--checkbox'
                            } }
                            label={ {
                                text: currentSub.Description
                            } }
                            name={ 'renew_sub_' + sub}
                            type='checkbox'
                            valid={ true }
                            inputFirst={ true }
                            value={ this.props.renewedSubs.indexOf(currentSub) !== -1 }
                        />
                        <ul className='renewals__dates'>
                            <li>{content.subStart} { viewUtils.formatDate(currentSub.StartDate) }</li>
                            <li>{content.subEnd}  { viewUtils.formatDate(currentSub.EndDate) }</li>
                            <li>{content.subLatestRenewal}  { viewUtils.formatDate(currentSub.LatestRenewal) }</li>
                        </ul>
                    </div>);
                }
            }

            if(renewalAvailable) {
                renewableSubs = (<form className='renewals__form'>
                        <fieldset>
                            { availableSubs }
                        </fieldset>
                    </form>);
            } else {
                noSubsMessage = (<span className='form__error '>
                        { content.noSubsMessage }
                    </span>);
            }
        }

        return <div className='module-container'>
            <div className='module module--inactive'>
                <div className='renewals__subscriptions'>
                    <h2>{ content.title }</h2>
                    <p>{ content.description }</p>

                    { noSubsMessage }
                    { renewableSubs }
                </div>
            </div>
        </div>;
    }
});

Subscription.propTypes = {
    content: React.PropTypes.object,
    onChangeInput: React.PropTypes.func
};

module.exports = Subscription;
