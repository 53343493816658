/**
 * Gets users out of state
 * @param {object} state Redux store object
 * @returns array
 */
const users = state => state.selections.users.objects;

/**
 * Merge arrays
 * @param  {...any} data Arrays to merge
 * @returns array
 */
const merge = ( ...data ) => [].concat( data );

/**
 * Gets selected subscriptions out of user object (may be defaulted)
 * 
 * @todo actually update state to ensure we have "selected" subscriptions - this is code smell
 * 
 * @param {Object} user User selections
 * @returns array
 */
const selectedSubscriptions = ( duration, durationType ) => user => {
    return merge(
        // Main subscriptions
        user.availableSubscriptions
            .filter( subscription =>
                subscription.duration == duration &&
                subscription.durationType == durationType
            ),

        // Selected add-ons
        Object.values( user.extras )
            .flatMap( extras => Object.values( extras ) )
    ).flat();
};

/**
 * Builds up data structure for google analytics datalayer
 * @param {string} centreName Name of centre
 * @param {string} virtualPageName Page name
 * @returns object
 */
const productInformationForGA = ( centreName, virtualPageName ) => subscription => {
    return {
        id: subscription.id,
        name: virtualPageName,
        brand: centreName,
    };
};

/**
 * Builds up datalayer for Google Analytics (#516)
 * 
 * @param {string} centreName Name of centre
 * @param {string} virtualPageName Name of virtual page
 * @param {object} state Redux store object
 * @returns mixed
 */
export const products = centreName => virtualPageName => state => users( state )
    .flatMap( selectedSubscriptions( state.selections.duration, state.selections.durationType ) )
    .flatMap(
        productInformationForGA( centreName, virtualPageName )
    );

/**
 * Casts to 'undefined' if there are no products (for GA)
 * @param {array} products Products on offer
 * @returns array|undefined
 */
export const castToUndefinedIfEmpty = products => products.length ? products : undefined;

/**
 * Determines whether all selections have been made
 * @param {object} state Redux store object
 * @returns boolean
 */
export const hasSelections = state => {
    return state.memberships.selected && state.selections.allUsersHaveSelections;
};