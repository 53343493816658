var React           = require('react'),
    connect         = require('react-redux').connect,
    actions         = require('../../actions'),
    Sidebar         = require('../sidebar'),
    Content         = require('./content'),
    Stage2Index;

Stage2Index = React.createClass({
    _redirectIfPropsNotValid: function _redirectIfPropsNotValid(props) {
        if (!props.hasCentre || (!props.deeplinkAddons && !props.hasSelectedMemberships)) {
            props.dispatch(actions.stages.goHome());
            props.dispatch(actions.stages.setStep(0));
        }
    },

    componentWillMount: function componentWillMount() {
        this._redirectIfPropsNotValid(this.props);
    },

    componentWillUpdate: function componentWillUpdate(nextProps) {
        this._redirectIfPropsNotValid(nextProps);
    },

    render: function() {
        return (<div className='page'>
                    <Content />
                    <Sidebar />
                </div>);
    }
});

function mapStateToProps(state) {
    return {
        deeplinkAddons: state.selections.deeplinkAddons,
        hasCentre: state.centreFinder.selected,
        hasSelectedMemberships: state.memberships.selected,
        user: state.user
    };
}

module.exports = connect(mapStateToProps)(Stage2Index);
