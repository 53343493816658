var React           = require('react'),
    connect         = require('react-redux').connect,
    config          = require('../../config'),
    actions         = require('../../actions'),
    scrollToComponent = require('react-scroll-to-component'),

    Header;

Header = React.createClass({
    _goHome: function (e) {
        e.preventDefault();
        this.props.dispatch(actions.stages.goHome());
    },

    /**
     * [_scrollToFAQs description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    _scrollToFAQs: function(e) {
        e.preventDefault();
        var faqSection = document.getElementById('faqs');
        scrollToComponent(faqSection, {
            align: 'top',
            offset: -100
        });
    },

    render: function() {
        var enquiryUrl = config.services.app.urls.enquiries,
            content = this.props.content,
            homepageUrl = config.services.app.urls.homepage,
            headerClass;

        headerClass = 'header header--no-stages';

        return (<header className={ headerClass }>
                <div className='header__band'>
                    <div className='header__logo-container'>
                        <a key='gohome' href={ homepageUrl } onClick={ this._goHome } title={ content.title }>
                            <img className='header__logo mobile' src='/img/joinonline/logo-block-white.svg' />
                            <img className='header__logo desktop' src='/img/joinonline/logo-block.svg' />
                        </a>
                    </div>

                    <div className='header__enquiries header__enquiries--transparent'>
                        <a href={ enquiryUrl } className='header__enquiries__title' target='_blank'>{ content.enquiries_text }</a>
                        <p className='header__enquiries__text'>Or <a onClick={ this._scrollToFAQs }>{ content.faqs_text }</a></p>
                    </div>
                </div>
            </header>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.header
    };
}

module.exports = connect(mapStateToProps)(Header);
