var React           = require('react'),
    
    Button          = require('../../common/button'),
    Input           = require('../../common/forms/input'),

    scrollToComponent = require('react-scroll-to-component'),

    EligibilityForm;

var distanceOptions = {
        default: [
            {
                text: '0-5 metres',
                value: 3
            },
            {
                text: '5-10 metres',
                value: 7
            },
            {
                text: '10-20 metres',
                value: 15
            },
            {
                text: '20-25 metres',
                value: 22
            },
            {
                text: '25-50 metres',
                value: 30
            },
            {
                text: '50-200 metres',
                value: 100
            },
            {
                text: '200-400 metres',
                value: 300
            },
            {
                text: '400+ metres',
                value: 500
            }
        ]
    };

EligibilityForm = React.createClass({

    getInitialState() {
        return {
            distanceConfirmed: !this.props.distanceConfirmed,
            inputs: [],
            options: this.props.eligibilityOptions
        };
    },

    componentDidMount() {
        var form = document.getElementById('eligibilityForm');
        scrollToComponent(form, {
            align: 'top',
            offset: -100
        });
    },

    buildForm: function buildForm(question) {
        var options = question || this.state.options,
            content = this.props.content,
            user = this.props.user,
            inputList,
            text,
            inputs = [];
        
        switch(options.question.type) {
            case 'education':
            case 'confident':
            case 'statement':
                text = content.form[options.question.type];
                var choicesArray = [];

                for(var choice in text.choices) {
                    choicesArray.push({
                        value: text.choices[choice].value,
                        text: text.choices[choice].label
                    });
                }

                inputs.push(<li key={'inputs-' + options.question.type}>
                        <Input className='personal-details__input--radio'
                            input={ {
                                onChange: this.props.onChangeEligibility.bind(null, options.question.type, options.question),
                                className:  'forms__input--radio'
                            } }
                            label={ {
                                text: text.label.replace('[name]', user.info.firstName.value)
                            } }
                            options={ choicesArray }
                            name={'radio_' + options.question.type}
                            radioLabelClassName={ 'personal-details__radio-label' }
                            type='radio'
                            valid={ true }
                            value={ (user.eligibility && user.eligibility[options.question.type]) }
                        />
                    </li>);

                if(user.eligibility && user.eligibility[options.question.type]) {
                    var response = user.eligibility[options.question.type];

                    if(options.question[response]) {
                        if(options.question[response].question) {
                            inputs.push(<div key={'input-wrapper_' + options.question[response].type}>
                                    { this.buildForm(options.question[response]) }
                                </div>);

                        }
                    }
                }

                inputList = (<ul className='personal-details__options-list'>
                        { inputs }
                    </ul>);
                break;
            case 'distance':
                text = content.form['distance'];
                var buttonProps = {
                        className: 'button' + (!(user.eligibility && user.eligibility.distance) ? ' button--disabled' : ''),
                        disabled: !(user.eligibility && user.eligibility.distance),
                        onClick: this.props.onUpdateDistance.bind(null, options.question)
                    },
                    buttonText = content.form['distance'].buttonText;

                inputs.push(<span className='form__error form__error--lessons' key='disclaimer'>
                        { this.props.disclaimers.swimLengths }
                    </span>);

                inputs.push(<div key={'inputs-' + options.question.type}>
                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.props.onChangeEligibility.bind(null, 'distance', null),
                                required: true
                            } }
                            label={ {
                                text: text.label.replace('[name]', user.info.firstName.value).replace('[lesson]', this.props.lessonName)
                            } }
                            name='input_distance'
                            options={ distanceOptions[this.props.lessonCategory] ? distanceOptions[this.props.lessonCategory] : distanceOptions['default'] }
                            placeholder={ text.placeholder }
                            type='select'
                            valid={ true }
                            value={ (user.eligibility && user.eligibility.distance) || '' }
                        />

                        <Button {...buttonProps}>{ buttonText }</Button>
                    </div>);

                inputs.push(<div className='personal-details__options-link' key='link'>
                        <a className='personal-details__link' href={ this.props.enquiryLink } target='_blank'>{ this.props.links.distance }</a>
                    </div>);

                if(this.props.distanceConfirmed && user.eligibility && user.eligibility['distance']) {
                    if(options.question.criteria) {
                        for(var o = 0; o < options.question.criteria.length; o++) {
                            var distanceOption = options.question.criteria[o];

                            if(user.eligibility['distance'] >= distanceOption['min'] && user.eligibility['distance'] < distanceOption['max']) {

                                if(distanceOption.question) {
                                    inputs.push(<div key='inputs_distance'>
                                            { this.buildForm(distanceOption) }
                                        </div>);
                                }

                                break;
                            }
                        }
                    }
                }

                inputList = (<div>{ inputs }</div>);
                break;
        }

        return inputList;
    },

    render: function() {
        return (<div className='module-container' id="eligibilityForm">
            <div className={'module ' + (!this.props.activeModule ? 'module--inactive' : '')}>
                <div className='personal-details__container'>
                    { this.buildForm() }
                </div>
            </div>
        </div>);
    }
});

EligibilityForm.propTypes = {
    content: React.PropTypes.object,
    eligibilityOptions: React.PropTypes.object,
    user: React.PropTypes.object
};

module.exports = EligibilityForm;
