var React           = require('react'),
    connect         = require('react-redux').connect,

    config          = require('../../config'),
    actions         = require('../../actions'),

    Button          = require('../common/button'),

    utils           = require('../../utils'),

    SidebarHelp;

SidebarHelp = React.createClass({
    _showLivechat: function _showLivechat() {
        this.props.dispatch(actions.liveChat.show());
    },

    _hideLivechat: function _hideLivechat() {
        this.props.dispatch(actions.liveChat.hide());
    },

    render: function render() {
        var centreId = this.props.centre && this.props.centre.info && this.props.centre.info.site_id,
            enquiryUrl = config.services.app.urls.enquiries + (centreId ? '&siteid=' + centreId : ''),
            content = this.props.content,
            chatBtnText,
            chatBlock,
            lessons = this.props.lessons,
            lessonType = utils.getLessonType(lessons),
            enquiriesChoices;

        // TODO: change chat buttons to reflect design (available agents
        //  should be a true representation of what the LC API tells us)
        if (this.props.livechatReady) {

            if (this.props.livechatOnline) {
                chatBtnText = content.liveChatAgentsAvailable;

            } else {
                chatBtnText = content.liveChatOffline;
            }

            chatBlock = <div className='sidebar__help-chat'>
                <p className='sidebar__help-text'>{ content.liveChatWithUs }</p>
                <Button className='sidebar__help-link' onClick={ this._showLivechat }>{ chatBtnText }</Button>
                {/* <Button className='button' onClick={ this._hideLivechat }>{ content.buttonHideLiveChat }</Button> */}
            </div>;
        }


        if(lessons && lessons.inView) {
            enquiriesChoices = config.services.lessons.enquiries[lessonType] || config.services.lessons.enquiries['default'];
            enquiryUrl = config.services.lessons.urls.enquiry + enquiriesChoices + (centreId ? '&siteid=' + centreId : '');
        } 

        return (<div className='sidebar-module'>
            <h3 className='sidebar-module__title'>{ content.title }</h3>
            <div className='sidebar__help-enquiry'>
                <p className='sidebar__help-text'>{ content.makeAnEnquiry }</p>
                <a className='sidebar__help-link' href={ enquiryUrl} target="_blank">{ content.enquiryLinkText }</a>
            </div>
            { chatBlock}
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.sidebar.help,
        lessons: state.lessons,
        livechatOnline: state.liveChat.online,
        showLivechat: state.liveChat.show,
        livechatReady: state.liveChat.ready
    };
}

module.exports = connect(mapStateToProps)(SidebarHelp);
