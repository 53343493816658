var actionTypes = require('../actions/actionTypes.js'),
    moment = require('moment');

module.exports = function (state, action) {
    state = state || {
        attachCallError: null,
        attachCallErrorCode: null,
        category: null,
        centreTypesList: null,
        eligibilityOptions: null,
        errors: null,
        inView: false,
        levelInfo: null,
        isMulti: false,
        paymentTimerActive: false,
        prices: null,
        timeoutStarted: false,
        timeoutTime: null,
        timetable: null,
        timetableToggle: false,
        typeId: null,
        typesList: null,
        user: {
            category: null,
            chosenLevel: null,
            eligibility: null,
            ineligible: false,
            altJourney: null,
            levels: null,
            levelName: null,
            id: null,
            info: {},
            loggedInUser: false,
            recommendedLevel: null,
            selectedLessons: {},
            unlockedLevels: null
        }
    };

    switch (action.type) {

        case actionTypes.LESSONS.ADD_CLASSES:
            var levelInfo = Object.assign({}, state.levelInfo);

            if(action.classes) {
                if(levelInfo[action.levelInfo.level] ) {
                    levelInfo[action.levelInfo.level].classes = action.classes;
                } else {
                    levelInfo[action.levelInfo.level] = {};
                    levelInfo[action.levelInfo.level].classes = action.classes;

                    for(var info in action.levelInfo) {
                        levelInfo[action.levelInfo.level][info] = action.levelInfo[info];
                    }
                }
            }

            return Object.assign({}, state, {
                levelInfo: levelInfo
            });

        case actionTypes.LESSONS.ATTACH_CALL_ERROR:
            return Object.assign({}, state, {
                attachCallError: action.error,
                attachCallErrorCode: action.code
            });

        case actionTypes.LESSONS.CLEAR_ELIGIBILITY_USER:
            return Object.assign({}, state, {
                user: {
                    category: null,
                    chosenLevel: null,
                    eligibility: null,
                    ineligible: false,
                    altJourney: null,
                    levels: null,
                    levelName: null,
                    id: null,
                    info: {},
                    loggedInUser: false,
                    recommendedLevel: null,
                    selectedLessons: {},
                    unlockedLevels: null
                }
            });

        case actionTypes.LESSONS.CLEAR_LEVELS:
            var user = Object.assign({}, state.user);
            user.levels = null;
            user.selectedLessons = {};
            user.unlockedLevels = null;

            return Object.assign({}, state, {
                user: user
            });

        case actionTypes.LESSONS.CLEAR_SELECTIONS:
            var user = Object.assign({}, state.user);
            user.selectedLessons = {};

            return Object.assign({}, state, {
                user: user
            });

        case actionTypes.LESSONS.GET_TYPES:

            return Object.assign({}, state, {
                typesList: action.typesList
            });

        case actionTypes.LESSONS.SET_CENTRE_TYPES:
            return Object.assign({}, state, {
                centreTypesList: action.typesList
            });

        case actionTypes.LESSONS.SET_ELIGIBILITY_OPTIONS:
            var user = Object.assign({}, state.user);

            if(action.eligibilityOptions && action.eligibilityOptions.level) {
                user.recommendedLevel = action.eligibilityOptions.level;
            }

            return Object.assign({}, state, {
                eligibilityOptions: action.eligibilityOptions,
                user: user
            });

        case actionTypes.LESSONS.SET_ELIGIBILITY:
            user = Object.assign({}, state.user);

            if(!user.eligibility) {
                user.eligibility = {};
            }

            if(user.levels !== 'static') {
                user.levels = null;
                user.chosenLevel = null;
            }

            for(var i in action.data.options) {
                user.eligibility[i] = action.data.options[i];
            }

            return Object.assign({}, state, {
                user: user
            });

        case actionTypes.LESSONS.SET_ERROR:

            var errors = Object.assign({}, state.errors);

            if(action.stage && action.userId !== -1) {
                errors = {};

                if(typeof action.userId !== "undefined" && action.userId !== -1) {
                    errors[action.userId] = {};

                    if(action.sessionIndex) {
                        errors[action.userId][action.sessionIndex] = true;
                        errors[action.userId].errorCode = action.errorCode ? action.errorCode : 0;
                    } else {
                        errors[action.userId][action.stage] = true;
                        errors[action.userId].errorCode = action.errorCode ? action.errorCode : 0;
                    }
                } else {
                    errors[action.stage] = true;
                    errors.errorCode = action.errorCode ? action.errorCode : 0;
                }
            } else {
                errors = null;
            }

            return Object.assign({}, state, {
                errors: errors
            });

        case actionTypes.LESSONS.SET_LEVELS_FOR_USER:
            user = Object.assign({}, state.user);

            user.levels = action.levels;
            user.levelName = action.levelName;
            user.ineligible = action.ineligible;

            return Object.assign({}, state, {
                user: user
            });

        case actionTypes.LESSONS.SET_TIMETABLE:
            return Object.assign({}, state, {
                timetable: action.timetable,
                timetableToggle: action.availability,
                prices: action.prices
            });

        case actionTypes.LESSONS.SET_TYPE:
            return Object.assign({}, state, {
                category: action.category,
                isMulti: action.isMulti,
                typeId: action.typeId,
                lessonType: action.lessonType,
                isGeneric: action.isGeneric,
                inView: true
            });

        case actionTypes.LESSONS.UPDATE_USER:
            user = Object.assign({}, state.user);

            if(action.data.info && action.data.info[action.key]) {
                for(i in action.data.info) {
                    user.info[i] = action.data.info[i];

                    //reset all user settings if dob changes
                    if(i === 'dob') {
                        user.category = null;
                        user.chosenLevel = null;
                        user.eligibility = null;
                        user.ineligible = false;
                        user.altJourney = null;
                        user.levelName = null;
                        user.loggedInUser = false;
                        user.recommendedLevel = null;
                        user.selectedLessons = {};
                        user.unlockedLevels = null;

                        if(user.levels !== 'static') {
                            user.levels = null;
                        }
                    }
                }
            } else {
                if(action.key === 'id') {
                    if(action.isLoggedIn) {
                        user.loggedInUser = true;
                    } else {
                        user.loggedInUser = false;
                    }

                    //reset all user settings if id (audlt, junior) changes
                    user.category = null;
                    user.chosenLevel = null;
                    user.eligibility = null;
                    user.ineligible = false;
                    user.info = {};
                    user.altJourney = null;
                    user.levelName = null;
                    user.recommendedLevel = null;
                    user.selectedLessons = {};
                    user.unlockedLevels = null;

                    if(user.levels !== 'static') {
                        user.levels = null;
                    }

                    if(action.data[action.key].toLowerCase().indexOf('adult') !== -1) {
                        user.category = 'Adult (16+)';
                        user.info.ageInMonths = 195;
                    }
                }

                if(!action.key) {
                    for(i in action.data) {
                        user[i] = action.data[i];

                        if(action.data['chosenLevel'] && i !== 'ineligible') {
                            if(i === 'lessonType') {
                                var exists;

                                //check if the user has already selected a lesson with the same lesson type
                                for(var l in user.selectedLessons) {
                                    if(action.data['lessonType'] && action.data['lessonType'] === user.selectedLessons[l].lessonType) {
                                        exists = user.selectedLessons[l].chosenLevel;
                                        break;
                                    }
                                };

                                //if it exists, delete it
                                if(exists) {
                                    delete user.selectedLessons[exists];
                                }
                            }

                            if(i === 'chosenLevel') {
                                user.selectedLessons[action.data[i]] = user.selectedLessons[action.data[i]] || {};
                                user.selectedLessons[action.data[i]][i] = action.data[i];
                            } else {
                                user.selectedLessons[action.data['chosenLevel']] = user.selectedLessons[action.data['chosenLevel']] || {};
                                user.selectedLessons[action.data['chosenLevel']][i] = action.data[i];
                            }
                        }
                    }
                } else {
                    user[action.key] = action.data[action.key];
                }

            }

            return Object.assign({}, state, {
                user: user
            });

        case actionTypes.LESSONS.SET_VIEW:
            return Object.assign({}, state, {
                inView: true
            });

        case actionTypes.LESSONS.SET_TIMER:
            var time = moment().valueOf(),
                timeoutStarted = true;

            if(action.clear) {
                time = null;
                timeoutStarted = false;
            }

            return Object.assign({}, state, {
                timeoutStarted: timeoutStarted,
                timeoutTime: time
            });

        case actionTypes.LESSONS.SET_PAYMENT_TIMER:
            return Object.assign({}, state, {
                paymentTimerActive: action.set
            });

        case actionTypes.LESSONS.UPDATE_SELECTED_LESSONS:
            var user = Object.assign({}, state.user),
                selectedLessons = user.selectedLessons;

            if(action.levelName) {
                selectedLessons[action.level] = selectedLessons[action.level] || {};

                selectedLessons[action.level].chosenLevel = action.level;
                selectedLessons[action.level].levelName = action.levelName;
            } else {
                delete selectedLessons[action.level];
            }

            return Object.assign({}, state, {
                user: user
            });

        case actionTypes.LESSONS.WIPEOUT:
            return Object.assign({}, state, {
                attachCallError: null,
                attachCallErrorCode: null,
                eligibilityOptions: null,
                errors: null,
                levelInfo: null,
                paymentTimerActive: false,
                prices: null,
                timeoutStarted: false,
                timeoutTime: null,
                timetableToggle: false,
                timetable: false,
                user: {
                    category: null,
                    chosenLevel: null,
                    eligibility: null,
                    ineligible: false,
                    altJourney: null,
                    levels: null,
                    levelName: null,
                    id: null,
                    info: {},
                    loggedInUser: false,
                    recommendedLevel: null,
                    selectedLessons: {},
                    unlockedLevels: null
                }
            });

        default:
            return state;
    }
};
