var React           = require('react'),
    connect         = require('react-redux').connect,

    config          = require('../../config'),
    actions         = require('../../actions'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    TimedOutModal;

TimedOutModal = React.createClass({
    getInitialState: function getInitialState() {
        return {
            step: config.app.modalTimeoutDisplay,
            timeoutId: null
        };
    },

    componentDidMount: function() {
        var self = this,
            timeoutId = setInterval(function() {
                if (self.state.step > 1) {
                    self.setState({ step: self.state.step - 1 });

                } else {
                    self._resetApp();
                }
            }, 1000);
        if (this.state.timeoutId !== timeoutId) {
            this.setState({ timeoutId: timeoutId });
        }
    },

    shouldComponentUpdate: function shouldComponentUpdate(newProps, newState) {
        if (newState.timeoutId !== this.state.timeoutId) {
            return false;
        }

        return true;
    },

    _clearInterval: function _clearInterval() {
        clearInterval(this.state.timeoutId);
        this.setState({ timeoutId: null });
    },

    _resetApp: function _resetApp() {
        this._clearInterval();

        //OLD RESET METHOD
        //this.props.dispatch(actions.app.resetAll());

        //release any lessons bookings
        for(var i = 0; i < this.props.users.length; i++) {
            var user = this.props.users[i];

            if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                for(var l in user.lesson.selectedLessons) {
                    var lesson = user.lesson.selectedLessons[l];

                    if(!user.guardian && lesson.bookingRef) {
                        this.props.dispatch(actions.lessons.releaseReservation(i, lesson.level));
                    }
                }
            }
        }

        this.props.dispatch(actions.selections.wipeout());
        this.props.dispatch(actions.personalDetails.wipeout());
        this.props.dispatch(actions.bankDetails.wipeout());
        this.props.dispatch(actions.discounts.wipeout());

        if('success' === this.props.payments.status) {
            this.props.dispatch(actions.payments.wipeout());
        }

        this.props.dispatch(actions.lessons.wipeout());

        // reset centre selection
        this.props.dispatch(actions.centreFinder.wipeout());
        // reset memberships and facilities
        this.props.dispatch(actions.memberships.unfreeze());
        this.props.dispatch(actions.memberships.resetSelection());
        this.props.dispatch(actions.memberships.hideSpecialPanel());

        if(!this.props.isBooking) {
            this.props.dispatch(actions.stages.goHome());
            this.props.dispatch(actions.stages.showStep(0));
            this.props.dispatch(actions.stages.setStep(0));
        }

        this.props.dispatch(actions.app.hideModal(this.props.id));

        if (window.parent !== window) {
            window.top && window.top.postMessage('GO_KIOSK_HOME', '*');
            window.location = window.location.protocol + '//' + window.location.hostname + '/logout';
        }
    },

    _onClose: function _onClose() {
        this._clearInterval();

        this.props.dispatch(actions.app.start());
        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    _onConfirmNo: function _onConfirmNo(e) {
        e.preventDefault();
        this._resetApp();
    },

    _onConfirmYes: function _onConfirmYes(e) {
        e.preventDefault();
        this._onClose();
    },

    render: function render() {
        var content = this.props.content;

        return (<Modal title={ content.title } onClose={ this._onClose } id={ this.props.id } className='modal--confirm'>
            <p>{ content.text }<span className='timeout-modal__counter'>{ this.state.step }</span></p>
            <div className='modal__buttons'>
                <Button className='button button--greyed-out' onClick={ this._onConfirmNo }>{ content.buttonNo }</Button>
                <Button className='button button--primed' onClick={ this._onConfirmYes }>{ content.buttonYes }</Button>
            </div>
        </Modal>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.timedOut,
        lessons: state.lessons,
        payments: state.payments,
        users: state.selections.users.objects
    };
}

module.exports = connect(mapStateToProps)(TimedOutModal);
