var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Button          = require('../common/button'),
    Input           = require('../common/forms/input'),
    SaveSelections  = require('../common/saveSelectionsButton'),
    FooterSecurity  = require('../common/footerSecurity'),

    httpFetch       = require('../../utils/httpFetch'),

    config       = require('../../config'),

    StaffLoginContent;

StaffLoginContent = React.createClass({

    getInitialState: function getInitialState() {
        return {
            error: false,
            errorText: '',
            formValid: false,
            password: '',
            staffId: ''
        };
    },

    _validateForm: function _validateForm() {
        var self = this;

        // If the form is currently in an invalid state, do not submit it
        if (!this.state.formValid) {
            return ;
        }

        if(this.state.staffId) {
            this.props.dispatch(actions.app.showLoader(true));

            return httpFetch.fetch(config.services.staff.urls.login, {
                method: 'POST',
                params: {
                    'id': self.state.staffId,
                    'password': self.state.password
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                if(response.success === true) {
                    self.props.dispatch(actions.staffLogin.store(response.user.id, response.user.name));
                    self.props.dispatch(actions.staffLogin.showLogin(false));

                    self.props.dispatch(actions.app.showLoader(false));
                    self.setState({
                        error: false
                    });
                } else {
                    self.props.dispatch(actions.app.showLoader(false));
                    self.setState({
                        error: true,
                        errorText: self.props.content.error.generic
                    });
                }
            });
        }
    },

    _onChangeInput: function _onChangeInput(field, value) {
        var state = this.state;
        state[field] = value;

        state.formValid = true;
        state.error = false;

        // Validate - staff id must be a number
        if (isNaN(this.state.staffId)) {
            state.error = true;
            state.errorText = this.props.content.error.staffId;
            state.formValid = false;
        }

        // Validate - password has max 10 chars
        if (field === 'password') {
            if (value.length > 10) {
                state.error = true;
                state.errorText = this.props.content.error.password;
                state.formValid = false;
            }
        }

        this.setState({
            state
        });
    },

    submit: function(e) {
        e.preventDefault();

        clearTimeout(this.delay);
        this.delay = setTimeout(this._validateForm, 500);
    },

    render: function() {
        var errorState,
            content = this.props.content;

        if (this.state.error) {
            errorState = <span className='form__error '>
                    { this.state.errorText }
                </span>;
        }

        return (
            <div className='container'>
                <div className='module-container'>
                    <div className='module centre-geoloc staff-login'>
                        <h2 className="module__title">{ content.title }</h2>
                        <div className="staff-login__content">
                            <legend>{ content.intro }</legend>
                            <div className='login-form'>
                                <form className='personal-details__login-form' onSubmit={ this.submit.bind(this) }>
                                    <Input
                                        input={ {
                                            className: 'login__input',
                                            onChange: this._onChangeInput.bind(null, 'staffId'),
                                            placeholder: content.fields.staffId.placeholder,
                                            autoFocus: true,
                                            required: true
                                        } }
                                        label={ {
                                            text: content.fields.staffId.label
                                        } }
                                        minLength={ 3 }
                                        disableHints={ true }
                                        name='staff_id'
                                        type='text'
                                        valid={ true }
                                        value={ this.state.staffId }
                                    />

                                    <Input
                                        input={ {
                                            className: 'login__input',
                                            onChange: this._onChangeInput.bind(null, 'password'),
                                            placeholder: content.fields.password.placeholder,
                                            required: true
                                        } }
                                        label={ {
                                            text: content.fields.password.label
                                        } }
                                        maxLength={ 10 }
                                        autocompleteOff={ true }
                                        disableHints={ true }
                                        name='staff_password'
                                        type='password'
                                        valid={ true }
                                        value={ this.state.password }
                                    />
                                    <div>
                                        {content.fields.password.comment}
                                    </div>

                                    {errorState}

                                    <Button className='button'>Submit</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.staffLogin,
        nextCount: state.nextCount
    };
}

module.exports = connect(mapStateToProps)(StaffLoginContent);
