var React           = require('react'),
    connect         = require('react-redux').connect,

    Button          = require('../../common/button'),
    Icons           = require('../../common/icons'),

    utils           = require('../../../utils/'),
    CentreDetails;

CentreDetails = React.createClass({

    render: function() {
        var smallScreens = (['bp-s', '"bp-s"', 'bp-sm', '"bp-sm"'].indexOf(this.props.breakpoint) !== -1),
            currentStep = this.props.flow[this.props.showStep],
            content = this.props.content,
            details = this.props.details.info,
            index = this.props.index,
            lessons = this.props.lessons,
            users = this.props.users.objects,
            openingTimesList = null,
            facilitiesList = null,
            centrePageLink = null,
            openingTimes = [],
            staticMap = null,
            facilities = [],
            chosenLevels = [],
            mapLink = null,
            address = null,
            today = null,
            self = this,

            itemClass = '',
            infoButton,
            actionButton,
            actionButtonCentre,
            selectedTitle,
            distance,
            day,
            key,
            i;

        infoButton = <Button key={ 'ci' + details.site_id } className='button__more-info' onClick={ self.props.onShowMore }>{ content.info }</Button>;
        actionButton = <Button key={ 'cs' + details.site_id } className='button' onClick={ self.props.onSelectCentre }>{ content.buttonSelect }</Button>;
        actionButtonCentre = <Button tabIndex={ this.props.expanded ? 0 : -1 } key={ 'csc' + details.site_id } className='button centre-finder__results-details-button' onClick={ self.props.onSelectCentre }>{ content.buttonSelectCentre }</Button>;

        if (this.props.selected) {
            actionButton = <Button key={ 'cc' + details.site_id } className='button button--change' onClick={ self.props.onChangeCentre }>{ content.buttonChange }</Button>;
            actionButtonCentre = <Button tabIndex={ this.props.expanded ? 0 : -1 } key={ 'ccc' + details.site_id } className='button button--change centre-finder__results-details-button' onClick={ self.props.onChangeCentre }>{ content.buttonChangeCentre }</Button>;
            selectedTitle = <h2 className='module__title module__title--inactive'>You have selected:</h2>;
            itemClass = 'module__list-item--inactive';

            if(this.props.expanded) {
                infoButton = <Button key={ 'ci' + details.site_id } className={'button__more-info button__more-info--expanded'} onClick={ self.props.onShowMore }>{ content.info }</Button>;
                actionButton = <Button key={ 'cc' + details.site_id } className='button button--change button--primed' onClick={ self.props.onChangeCentre }>{ content.buttonChange }</Button>;
                actionButtonCentre = <Button tabIndex={ this.props.expanded ? 0 : -1 } key={ 'ccc' + details.site_id } className='button button--change button--primed centre-finder__results-details-button' onClick={ self.props.onChangeCentre }>{ content.buttonChangeCentre }</Button>;
            }

        } else if (this.props.expanded) {
            infoButton = <Button key={ 'ci' + details.site_id } className={'button__more-info button__more-info--expanded'} onClick={ self.props.onShowMore }>{ content.info }</Button>;
            actionButton = <Button key={ 'cs' + details.site_id } className='button button--primed' onClick={ self.props.onSelectCentre }>{ content.buttonSelect }</Button>;
            actionButtonCentre = <Button tabIndex={ this.props.expanded ? 0 : -1 } key={ 'csc' + details.site_id } className='button button--primed centre-finder__results-details-button' onClick={ self.props.onSelectCentre }>{ content.buttonSelectCentre }</Button>;
            itemClass = 'module__list-item--expanded';
        }

        if (details.opening_times) {
            if( details.opening_times.today) {
                today = <div className='centre-finder__results-details-today'>
                    { content.openToday } <span>{ details.opening_times.today[0] }-{ details.opening_times.today[1] }</span>
                    <Button key={ 'ci' + details.site_id } className='button__more-info centre-finder__results-details-times' onClick={ self.props.onShowMoreTimes }>&nbsp;</Button>
                </div>;
            }

            if( details.opening_times.standard && Object.keys(details.opening_times.standard).length) {
                for (key in details.opening_times.standard) {
                    day = details.opening_times.standard[key];
                    openingTimes.push(<li key={ 'co_' + key } className='centre-finder__results-details-list-item'>{ key } <span>{ day[0] }-{ day[1] }</span></li>);
                }

                if (smallScreens) {
                    actionButtonCentre = '';
                }

                openingTimesList = <div className='centre-finder__results-details-col'>
                    <h5>{ content.openingTimes }</h5>
                    { today }
                    <div className={'centre-finder__results-details-list-container centre-finder__results-details-list-container-' + (index + 1) }>
                        <ul className='centre-finder__results-details-list'>{ openingTimes }</ul>
                    </div>
                    { this.props.canChange && actionButtonCentre }
                </div>;
            }
        }

        if (details.highlighted_facilities && details.highlighted_facilities.length) {
            for (i = 0; i < details.highlighted_facilities.length; i++) {
                facilities.push(<li key={ 'cf_' + i } className='centre-finder__results-details-list-item'>{ details.highlighted_facilities[i] }</li>);
            }

            facilitiesList =  <div className='centre-finder__results-details-col centre-finder__results-details-col--facilities'>
                <h5>{ content.facilities }</h5>
                <ul className='centre-finder__results-details-list'>{ facilities }</ul>
            </div>;
        }

        if (details.legacy_wordpress_url) {
            centrePageLink = <a tabIndex={ this.props.expanded ? 0 : -1 } className='centre-finder__results-details-link link--external' target="_blank" href={ details.legacy_wordpress_url }>{ content.buttonViewCentrePage }</a>;
        }

        if (details.static_map_url) {
            staticMap = <img className='centre-finder__results-details-map' src={ details.static_map_url } alt={ details.name + ' Location Map'}/>;
        } else {
            staticMap = <img className='centre-finder__results-details-map' src='/img/joinonline/staticMap.png' alt={ details.name + ' Location Map'}/>;
        }

        if (details.address) {
            address = <div className='centre-finder__results-details-address'>{ details.address }</div>;
        }

        if (details.lat && details.lon) {
            mapLink = <a tabIndex={ this.props.expanded ? 0 : -1 } className='centre-finder__results-details-link link--external' target="_blank" href={ 'http://www.google.com/maps/place/' + details.lat + ',' + details.lon }>{ content.buttonViewOnMap }</a>;
        }

        if (details.distance !== null && details.distance !== undefined) {
            distance = details.distance.toFixed(1) + 'mi';

        } else {
            distance = '\u00a0';
        }

        if(currentStep === 6 && lessons && lessons.inView && users && users.length) {
            var lessonType = utils.getLessonType(lessons);

            for(var x = 0; x < users.length; x++) {
                var user = users[x],
                    color = '#DE1F9C',
                    typeDesc = user.typeDesc,
                    idInType = user.idInType + 1,
                    firstName = (user.info.firstName && user.info.firstName.value),
                    lastName = (user.info.lastName && user.info.lastName.value),
                    priceDiscount,
                    userName,
                    level,
                    price;

                if (firstName) {
                    userName = ((firstName && lastName) ? firstName + ' ' + lastName : firstName );
                } else {
                    userName = typeDesc + ' ' + idInType;
                }

                if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                    for(var l in user.lesson.selectedLessons) {
                        var lesson = user.lesson.selectedLessons[l];

                        if(lesson.levelName) {
                            var level = lessons.levelInfo[l],
                                price = level.price && level.price !== 0 ? content.levelPrice.replace('[amount]', utils.formatPrice(level.price)) : null,
                                priceDiscount = level.priceDiscount,
                                lessonTypeId;

                            if(level) {
                                lessonTypeId = level.classes.lesson_type_id;

                                if(lessonTypeId) {
                                    lessonType = utils.getLessonType(lessons, false, lessonTypeId);
                                }
                            }

                            if(user.lesson.discountUser && priceDiscount !== 0) {
                                price = content.levelPrice.replace('[amount]', utils.formatPrice(priceDiscount));
                            }

                            chosenLevels.push(<div className='level-breakdown__chosen' key={'user_' + x + l}>
                                <div className='level-breakdown__chosen-icon'>
                                    <Icons icon={lessonType} color={color} iconUrl={level.iconUrl}/>
                                </div>
                                <div className='level-breakdown__chosen-details'>
                                    <div className='level-breakdown__chosen-name'>{ userName }:</div>
                                    <h4>{ lesson.levelName } <span>{ price }</span></h4>
                                    <p>{ level.comment }</p>
                                </div>

                                <Button className='level-breakdown__chosen-button' onClick={ this.props.removeLessonsUser.bind(null, x, l) }>x</Button>
                            </div>);
                        }
                    }
                } else if(user.guardian) {
                    chosenLevels.push(<div className='level-breakdown__chosen' key={'user_' + x}>
                        <div className='level-breakdown__chosen-icon'>
                            <Icons icon={lessonType} color={color}/>
                        </div>
                        <div className='level-breakdown__chosen-details'>
                            <div className='level-breakdown__chosen-name'>{ content.freeUserType }</div>
                            <h4>{ userName }</h4>
                        </div>
                    </div>);
                }
            }
        }

        if (smallScreens) {
            return (<li className={'centre-finder__results-list-item module__list-item ' + itemClass}>
                { selectedTitle }
                <div className='centre-finder__results-meta'>
                    <h4 className='centre-finder__results-item-name'>{ details.name || details.info.siteDesc }</h4>
                </div>
                <div className={ 'module__list-item-details module__list-item-details--centre-finder-' + (index + 1) }>
                    <div className='inner'>
                        { openingTimesList }
                        <div className='centre-finder__results-details-col'>
                            { staticMap }
                            <div>{ address }</div>
                            <div>{ mapLink }</div>
                            <div>{ centrePageLink }</div>
                        </div>
                    </div>
                </div>
                <div className='centre-finder__results-actions'>
                    <span className='centre-finder__results-item-distance'>{ distance }</span>
                    <span className='centre-finder__results-item-more'>{ infoButton }</span>

                    {this.props.canChange &&
                    <span className='centre-finder__results-item-select' data-recite-skip="true">{ actionButton }</span>}
                </div>

                { chosenLevels }
            </li>);
        } else {
            return (<li className={'centre-finder__results-list-item module__list-item ' + itemClass}>
                { selectedTitle }
                <div className='centre-finder__results-actions'>
                    <h4 className='centre-finder__results-item-name'>{ details.name || details.info.siteDesc }</h4>
                    <span className='centre-finder__results-item-distance'>{ distance }</span>
                    <span className='centre-finder__results-item-more'>{ infoButton }</span>

                    {this.props.canChange &&
                    <span className='centre-finder__results-item-select' data-recite-skip="true">{ actionButton }</span>}
                </div>
                <div className={ 'module__list-item-details module__list-item-details--centre-finder-' + (index + 1) }>
                    <div className='inner'>
                        <div className='centre-finder__results-details-col'>
                            <h5>{ content.locationTitle }</h5>
                            { staticMap }
                            <div>{ address }</div>
                            <div>{ mapLink }</div>
                            <div>{ centrePageLink }</div>
                        </div>
                        { facilitiesList }
                        { openingTimesList }
                    </div>
                </div>

                { chosenLevels }
            </li>);
        }
    }
});

CentreDetails.propTypes = {
    canChange: React.PropTypes.bool,
    content: React.PropTypes.object,
    details: React.PropTypes.object,
    expanded: React.PropTypes.bool,
    index: React.PropTypes.number,
    breakpoint: React.PropTypes.string,
    selected: React.PropTypes.bool,
    onShowMore: React.PropTypes.func,
    onShowMoreTimes: React.PropTypes.func,
    onSelectCentre: React.PropTypes.func,
    onChangeCentre: React.PropTypes.func
};

function mapStateToProps(state) {
    return {
        flow: state.stages.flow,
        showStep: state.stages.showStep
    };
}

module.exports = connect(mapStateToProps)(CentreDetails);
