var React = require("react"),
    connect = require("react-redux").connect,
    sivin = require("scroll-into-view-if-needed").default,
    cookie = require("react-cookie"),
    HotKeys = require("react-hotkeys").HotKeys,
    EventListener = require("react-event-listener").default,
    queryString = require("query-string"),
    moment = require("moment"),
    classNames = require("classnames"),
    actions = require("../actions"),
    config = require("../config"),
    utils = require("../utils"),
    routes = require("../routes"),
    router = require("../utils/router"),
    liveChat = require("../utils/liveChat"),
    history = require("../utils/history"),
    UserModel = require("../models/user"),
    FooterSecurity = require("../components/common/footerSecurity"),
    Modals = require("./modals"),
    StaffLogin = require("./staffLogin"),
    Loader = require("./common/loader"),
    keyMap = {
        tabUp: "shift+tab",
        tabDown: "tab"
    },
    deepLinkParams = [
        "gclid",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
        "addons",
        "corporateid",
        "discountid",
        "extras",
        "eastaff",
        "groups",
        "siteid",
        "site",
        "startdate",
        "promo",
        "adults",
        "juniors",
        "seniors",
        "concessions",
        "is_staff",
        "facility",
        "lessonid",
        "cutoffdate",
        "partner",
        "facilityfilter",
        "answerscoring"
    ],
    App;

const { goToConfirmPage, isConfirmPage } = require('../utils/confirmPage');

require("@babel/polyfill");

App = React.createClass({
    getInitialState: function getInitialState() {
        return {
            breakpoint: null,
            clearedForDeeplink: false,
            deeplinkGroupFetced: false,
            fetchingProfile: false,
            fetchingCentre: false,
            loadingLiveChat: false,
            navigatingWithKeyboard: false,
            prevLocation: {
                pathname: null,
                query: null
            },
            route: {
                header: null,
                component: null
            }
        };
    },

    componentWillMount: function componentWillMount() {
        var self = this;

        // Registering resize event on window.
        this._handleResizeDebounced = window.debounce(function() {
            self._handleResize();
        }, 200);

        // Calling _handleResize once to set the current
        //  breakpoint for components to use at app load.
        this._handleResize();

        this.props.dispatch(actions.stages.setFlow(this.props.flow));
        this.props.dispatch(actions.stages.prepareStep(location.pathname));

        this.props.dispatch(actions.staffLogin.checkLoggedIn());

        // Passing on props to single method dealing with them.
        this._actOnProps(this.props);

        this.props.dispatch(actions.app.start());

        if (!this._hasDeepLinkParams(queryString.parse(location.search))) {
            this.props.dispatch(actions.app.start());
        }

        if (/^\/short-memberships/.test(location.pathname)) {
            this._setNewRoute("short-memberships");
        } else if (
            /^\/memberships/.test(location.pathname) ||
            /^\/$/.test(location.pathname)
        ) {
            this._setNewRoute("memberships");
        } else if (/^\/lessons/.test(location.pathname)) {
            this._setNewRoute("lessons");

            //default to swimming until multi lesson released
            var query = queryString.parse(location.search);
            var lessonId = query.lessonid ? query.lessonid : null;

            if (lessonId) {
                this.props.dispatch(actions.lessons.setType(lessonId));
            }
        }

        sessionStorage.setItem("approute", location.pathname);
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        // Passing on props to single method dealing with them.
        this._actOnProps(newProps);

        // This is not a redux connected class, so we have to pass
        //  the props manually.
        liveChat.setState(this.props);

        // If live chat has loaded and fired its own callback, it will
        //  have hooked into the store (see utils/liveChat.js)
        //  and updated this prop/
        if (this.props.livechatReady) {
            // If we're transitioning visibility
            if (this.props.showLivechat !== newProps.showLivechat) {
                if (!newProps.showLivechat) {
                    liveChat.hide();
                } else {
                    liveChat.show();
                }
            }

            // When we receive user data on login, pass it on to liveChat.
            if (this.props.user !== newProps.user) {
                liveChat.setCustomerData({ id: newProps.user.mrmId });
            }
        }
    },

    _actOnProps: function _actOnProps(newProps) {
        // Wait for the store to have fully rehydrated before doing anything.
        if (!newProps.rehydrated) {
            return;
        }

        var dataLayerStaffLogin = window.dataLayer.filter(
            x => x.staffLogin !== undefined
        )[0];

        if (dataLayerStaffLogin) {
            var staffLogin = !!this.props.staffId;
            staffLogin = staffLogin.toString();
            if (dataLayerStaffLogin.staffLogin !== staffLogin) {
                dataLayerStaffLogin.staffLogin = staffLogin;

                window.dataLayer.push({
                    staffLogin: staffLogin
                });
            }
        }

        //if a favourite centre has been set on device
        //and a staff member is logged in
        //and the centre is the same as the one being selected (if trying to change centre)
        //set the selected centre as default
        var centreCookie = cookie.load("favouriteCentre");
        if (
            centreCookie &&
            this.props.staffId &&
            !this.props.centre &&
            (!newProps.centre ||
                (newProps.centre &&
                    newProps.centre.info &&
                    newProps.centre.info.site_id === centreCookie))
        ) {
            this.props.dispatch(
                actions.centreFinder.selectCentre(centreCookie, {
                    tagsWhiteList: newProps.tagsWhiteList,
                    tagsBlackList: newProps.tagsBlackList
                })
            );
        }

        // If the user tries to navigate backwards from a successful
        //  payment page, the app goes to the confirmation page straight away.
        if (
            "success" === newProps.payments.status &&
            newProps.users.objects.length !== 0 &&
            !isConfirmPage()
        ) {
            return goToConfirmPage();
        }

        if (newProps.lessons.inView && newProps.lessons.timeoutStarted) {
            newProps.dispatch(actions.lessons.startTimerOnRefresh());
        }

        var query = queryString.parse(location.search),
            newState = {},
            loggedInUser,
            newUserData,
            userId,
            user;

        // Call fetching profile regardless of being logged in or not.
        // Since login is supposed to be maintained by anothe realm of
        //  the product, we can only place a call and check it's response
        //  status and response object to determine if we have a logged-in
        //  user or not, and act based on this.
        if (!newProps.profileLoaded && !this.state.fetchingProfile) {
            this.setState({ fetchingProfile: true });
            newProps.dispatch(actions.app.hideModal());
            newProps.dispatch(
                actions.user.fetchProfile(true, function() {
                    newProps.dispatch(actions.app.profileLoaded());
                })
            );
            return;
        }

        // Once the fetchProfile call has completed, whether or not we have a
        //  logged-in user, profileLoaded will be set to true so we can
        //  continue on the app start process.
        if (newProps.profileLoaded) {
            loggedInUser = newProps.user;

            // Reset profile fetching status
            if (this.state.fetchingProfile) {
                newState.fetchingProfile = false;
            }

            // Only reapply user data if the first member doesn't have neither a firstName or lastName.
            // This is to prevent overriding the store if the customer has already changed values and refreshes the page.
            // Apply to a lead user only OR any FSG user as this could be a junior (who can't be leads)
            // So try the lead user first, and fall through to a junior if the age doesn't match
            for (userId = 0; userId < newProps.users.objects.length; userId++) {
                user = newProps.users.objects[userId];
                if (
                    user &&
                    ((user.lead &&
                        UserModel.getAgeValue(loggedInUser.user) > 15) || // lead user, and logged in as adult
                        (user.typeDesc == "Junior" &&
                            UserModel.getAgeValue(loggedInUser.user) <= 15)) && // junior use, and logged in as NOT adult
                    (!user.info ||
                        (!user.info.firstName && !user.info.lastName)) &&
                    loggedInUser &&
                    loggedInUser.loggedIn &&
                    loggedInUser.user &&
                    loggedInUser.user.info.firstName &&
                    loggedInUser.user.info.lastName
                ) {
                    break;
                }
                user = null; // so that we don't retain the last entry, if there was no match
            }

            if (user) {
                newUserData = loggedInUser.user;
                newUserData.isLoggedInUser = true;

                newProps.dispatch(
                    actions.selections.changeData(userId, newUserData)
                );

                // if is lessons journey, get medical details for user
                if (loggedInUser.user && this.props.lessons.inView) {
                    newProps.dispatch(
                        actions.user.getMedicalDetails(
                            loggedInUser.user.info.mrmId,
                            userId
                        )
                    );
                }

                if (loggedInUser.user.directDebit) {
                    newProps.dispatch(
                        actions.bankDetails.setDetails({
                            account: {
                                holderName:
                                    loggedInUser.user.directDebit.account
                                        .holderName,
                                sortCode:
                                    loggedInUser.user.directDebit.account
                                        .sortCode,
                                accountNumber:
                                    loggedInUser.user.directDebit.account
                                        .accountNumber
                            },
                            collections:
                                loggedInUser.user.directDebit.collections,
                            totals: loggedInUser.user.directDebit.totals
                        })
                    );
                }

                newProps.dispatch(
                    actions.user.getExpiredSubscriptions(
                        loggedInUser.user.info.mrmId,
                        userId
                    )
                );

                newProps.dispatch(actions.personalDetails.freezeUser(userId));
                newProps.dispatch(
                    actions.personalDetails.unfreeze(userId, "subscriptions")
                );
            }
        }

        if (newProps.started) {
            // Processing deep link parameters from the query string
            // We return here because _processDeeplinks will redirect
            //  after calling the right actions.
            if (this._hasDeepLinkParams(query)) {
                this._processDeeplinks(newProps, query);
                return;
            }

            // If liveChat is not yet ready to use, and we're not already
            //  in the process of loadign it, start its load process
            //  with the current user data if present.
            if (
                !this.props.livechatReady &&
                !this.state.loadingLiveChat &&
                loggedInUser
            ) {
                this._loadLiveChat(loggedInUser.user);
            }

            // Only trying to resolve a new route if path and
            //  query params have changed.
            // Not checking for hash changes as they shouldn't
            //  be part of route matches in the router.
            if (
                this.state.prevLocation.pathname !== location.pathname ||
                this.state.prevLocation.query !== location.query
            ) {
                // If the new path is not one that's present in the
                //  selected flow or a general path, redirect to home,
                // of if 'lessons' route, change the flow and journey
                if (
                    !newProps.allowedPaths.includes(location.pathname) &&
                    !router.findRoute(routes.otherRoutes, location.pathname)
                ) {
                    return this.props.dispatch(actions.stages.goHome());
                }

                newState.route = router.resolve(routes, location);
                this.state.prevLocation.pathname = location.pathname;
                this.state.prevLocation.query = location.query;
            }
        }

        this.setState(newState);
    },

    // Deep link parameters are in the array defined
    //  at the top of this class.
    // If no parameters for deep link purposes are given,
    //  this will return false and avoid a call to _processDeeplinks
    _hasDeepLinkParams: function _hasDeepLinkParams(query) {
        var hasParams = false,
            keys = Object.keys(query),
            i;

        for (i = 0; i < keys.length; i++) {
            if (deepLinkParams.includes(keys[i].toLowerCase())) {
                hasParams = true;
                break;
            }
        }

        return hasParams;
    },

    // Takes care of firing the right actions based on the deep link
    //  parameters present in the query string.
    _processDeeplinks: function _processDeeplinks(props, query) {
        var addons = query.addOns || query.addons || query.extras,
            centreId = query.siteId || query.siteid || query.SiteID,
            discount =
                query.discountId ||
                query.discountid ||
                query.corporateId ||
                query.corporateid,
            corporateId = query.corporateId || query.corporateid,
            groups = query.groups,
            newCentreInfoRequired = this._newCentreInfoRequired(props),
            redirUrl = location.pathname,
            adults = query.adults,
            students = query.students,
            juniors = query.juniors,
            seniors = query.seniors,
            concessions = query.concessions,
            eaStaff = query.eastaff || query.eaStaff,
            isCouncilStaff = query.is_staff && query.is_staff === "1",
            promo = query.promo,
            gclid = query.gclid,
            facility = query.facility,
            facilityFilter = query.facilityFilter || query.facilityfilter,
            lesson = query.lessonid,
            subid = query.subid,
            considerPreselectedSubscriptionsOnly =
                ['1', 'true'].includes(query.considerPreselectedSubscriptionsOnly),
            group = query.group,
            partner = query.partner,
            utm_tracking = {
                utm_source: query.utm_source,
                utm_medium: query.utm_medium,
                utm_campaign: query.utm_campaign,
                utm_term: query.utm_term,
                utm_content: query.utm_content
            },
            cutoffDate = query.cutoffdate || query.cutoffDate,
            options = {
                tagsWhiteList: props.tagsWhiteList,
                tagsBlackList: props.tagsBlackList
            },
            startDate = query.startdate || query.startDate,
            trackingCookieValue;

        props.dispatch(actions.faqs.answerScoring(query[['answerScoring', 'answerscoring'].find(key => query[key] !== undefined)]));

        if (gclid) {
            trackingCookieValue = encodeURIComponent(gclid);

            if (config.app.env === "local") {
                document.cookie = `tracking=${trackingCookieValue}; path=/; SameSite=Lax; domain=${config.services.everyoneActive.baseUrl}`;
            } else {
                document.cookie = `tracking=${trackingCookieValue}; path=/; SameSite=None; domain=${config.services.everyoneActive.baseUrl}; Secure`;
            }
            // cookie.save('tracking', gclid, {domain: config.services.everyoneActive.baseUrl}); // removed due to react-cookie not able to set SameSite in our version
        }

        if (utm_tracking.utm_source) {
            trackingCookieValue = encodeURIComponent(
                JSON.stringify(utm_tracking)
            );

            if (config.app.env === "local") {
                document.cookie = `utm_tracking=${trackingCookieValue}; path=/; SameSite=Lax; domain=${config.services.everyoneActive.baseUrl}`;
            } else {
                document.cookie = `utm_tracking=${trackingCookieValue}; path=/; SameSite=None; domain=${config.services.everyoneActive.baseUrl}; Secure`;
            }
            // cookie.save('utm_tracking', utm_tracking, {domain: config.services.everyoneActive.baseUrl}); // removed due to react-cookie not able to set SameSite in our version
        }

        // Since we're deep linking, we can cleanup all the current
        //  store data. It's not envisioned right now to allow
        //  deep-linking while in the process of filling in a form,
        //  which wouldn't work anyway since new tab = new empty store.
        // _processDeeplinks will be called on newProps, so we only need
        //  to do it once or we'll risk deleting data fetched for the
        //  deeplinking to work, like profile or centre data.
        if (!this.state.clearedForDeeplink) {
            // Only clear the currently selected centre data if we need to.
            // This fixes the issue around deep linking in an addon selection
            //  where the new centre data would be cleared before we re-render.
            if ((addons && newCentreInfoRequired) || !addons) {
                props.dispatch(actions.centreFinder.wipeout());
            }
            props.dispatch(actions.memberships.wipeout());

            // Deep linked centre id
            // We do in fact want to wipeout selections here
            if (centreId) {
                props.dispatch(actions.selections.wipeout());
            }

            props.dispatch(actions.bankDetails.wipeout());

            if (!this.state.clearedForDeeplink) {
                this.setState({ clearedForDeeplink: true });
            }
        }

        // EA-62 - Deep linked facility name filter (e.g. filter by all centres that have a Pool)
        if (facilityFilter) {
            props.dispatch(
                actions.centreFinder.setFacilityFilterSearchTerm(facilityFilter)
            );
        }

        // #22 - ensure selections are wiped if we are deep linking a centreid only
        if (centreId && Object.keys(query).length == 1) {
            props.dispatch(actions.selections.wipeout());
        }

        // #22 - ensure selections are wiped if we are deep linking a centreid and EAStaff
        if (eaStaff && centreId && Object.keys(query).length == 2) {
            props.dispatch(actions.selections.wipeout());
        }

        if (addons && props.user.loggedIn) {
            // Dispatchign this first so it's directly available
            //  to filter all centre subscriptions when the
            //  select centre fetch returns with data.
            props.dispatch(
                actions.selections.deeplinkAddons(addons.split(","))
            );

            // This will keep being hit until the selected centre has been fetched.
            if (newCentreInfoRequired) {
                if (!this.state.fetchingCentre) {
                    this.setState({ fetchingCentre: true });

                    return props.dispatch(
                        actions.centreFinder.selectCentre(
                            props.user.user.info.siteId,
                            {
                                subIds: addons.split(","),
                                tagsWhiteList: props.tagsWhiteList,
                                tagsBlackList: props.tagsBlackList
                            }
                        )
                    );
                }

                return;

                // Once we have the right centre, we can continue with the redirect.
            } else {
                // Here we will know if we have the required addon/extra from the
                //  filtered centre data. If there is no subscription containing
                //  the deep linked addon, we redirect to '/' with a hard reset.
                // Maybe inform the user that nothing was found?
                if (
                    !props.centre.subscriptions ||
                    !Object.keys(props.centre.subscriptions).length
                ) {
                    props.dispatch(actions.centreFinder.wipeout());
                    props.dispatch(actions.memberships.wipeout());
                    props.dispatch(actions.selections.wipeout());
                    props.dispatch(actions.bankDetails.wipeout());

                    return history.push("/");
                }

                this.setState({ fetchingCentre: false });
            }

            if (
                props.flow !== props.availableFlows.existingCustomer.buyAddons
            ) {
                props.dispatch(
                    actions.stages.setFlow(
                        props.availableFlows.existingCustomer.buyAddons
                    )
                );
            }

            props.dispatch(
                actions.selections.createDeeplinkUser(
                    props.user.user,
                    addons.split(",")
                )
            );

            redirUrl = "/add-ons";
        }

        if (partner && !props.preselectedPartner) {
            props.dispatch(actions.selections.preloadPartner(partner));
        }

        // Preselects number of members in membership form
        if (adults || students || juniors || seniors || concessions) {
            var users = {
                adults: adults,
                students: students,
                juniors: juniors,
                seniors: seniors,
                concessions: concessions
            };

            this.props.dispatch(actions.selections.preselectUsers(users));
        }

        // Preselects subs or groups for deplink [subid, group]
        if (
            promo !== "fsg" &&
            (adults || students || juniors || seniors || concessions)
        ) {
            if (subid) {
                const subIdToUpperCase = subid.toUpperCase();

                props.dispatch(actions.selections.preselectSubs(subIdToUpperCase));
                props.dispatch(
                    actions.selections.considerPreselectedSubscriptionsOnly(
                        considerPreselectedSubscriptionsOnly
                    )
                );
            }

            if (group && !this.state.deeplinkGroupFetced) {
                // fire event to fetch all subsciptions for the specified group(s)
                props.dispatch(actions.selections.preloadGroup(group));

                this.setState({
                    deeplinkGroupFetced: true
                });

                return;
            }

            if (group && !props.preselectedSubsByGroups) {
                // wait until group is loaded
                return;
            }
        }

        if (discount) {
            let discountSplit = discount.split("-");

            if (discountSplit.length > 1) {
                props.dispatch(
                    actions.selections.setUserDiscountPromo(discount)
                );
            } else {
                props.dispatch(
                    actions.discounts.discountSelected(
                        utils.buildDiscountCode({
                            type: !!corporateId ? "corporate" : "code",
                            value: discount
                        }),
                        true
                    )
                );
            }
        }

        if (groups) {
            props.dispatch(
                actions.selections.deeplinkGroups(groups.split(","))
            );
        }

        // Pre-load map state when a siteId has been deep linked
        const mapState = () => {
            // Ensure maps state is loaded correctly by firing up centre finder
            props.dispatch(
                actions.centreFinder.findCentres({
                    page: this.props.centreFinder.currentPage + 1,
                    lat: this.props.centreFinder.selected.info.lat,
                    lng: this.props.centreFinder.selected.info.lon,

                    // We have no way of knowing what the location is so we use
                    // the deep linked centre as the initial lat/lng
                    distanceFromLat: this.props.centreFinder.selected.info.lat,
                    distanceFromLon: this.props.centreFinder.selected.info.lon,

                    // "stinky centre" of the one we have chosen
                    alwaysInclude: [
                        this.props.centreFinder.selected.info.site_id
                    ]
                })
            );
        };

        if (
            /booking-profile/.test(location.pathname) ||
            /gym-profile/.test(location.pathname)
        ) {
            var options = {};
            var bookingUsers = {};

            redirUrl = /booking-profile/.test(location.pathname)
                ? "/booking-profile"
                : "/gym-profile";
            bookingUsers["freeprofile"] = 1;
            centreId = query.Site || query.site || query.siteId || query.siteid;

            props.dispatch(actions.booking.setUrlParams(query));
            props.dispatch(actions.booking.getConcessionOptions());
            props.dispatch(actions.personalDetails.toggleUserLogin(0, false));

            if (facility) {
                options = Object.assign({}, options, {
                    facilities: facility.split(",")
                });

                if (centreId) {
                    props
                        .dispatch(
                            actions.centreFinder.selectCentre(centreId, options)
                        )
                        .then(() => {
                            props.dispatch(actions.selections.wipeout());
                            props.dispatch(
                                actions.selections.setUsers(bookingUsers)
                            );

                            // Dispatch centre finder
                            mapState();
                        });
                } else {
                    props.dispatch(
                        actions.centreFinder.setFacilities(facility.split(","))
                    );
                }
            } else if (centreId) {
                props
                    .dispatch(
                        actions.centreFinder.selectCentre(centreId, options)
                    )
                    .then(() => {
                        props.dispatch(
                            actions.selections.setUsers(bookingUsers)
                        );

                        // Dispatch centre finder
                        mapState();
                    });
            }
        } else if (/lessons/.test(location.pathname)) {
            if (lesson) {
                props.dispatch(actions.lessons.fetchTypes(lesson));

                if (centreId) {
                    props.dispatch(
                        actions.centreFinder.selectCentre(centreId, options)
                    );
                }
            }
        } else if (centreId) {
            props
                .dispatch(actions.centreFinder.selectCentre(centreId, options))
                .then(() => {
                    // Dispatch centre finder
                    mapState();
                });
        }

        if (eaStaff) {
            props.dispatch(actions.staffLogin.showLogin(eaStaff === "true"));
        }

        if (promo) {
            props.dispatch(actions.selections.setPromo(promo));

            if (isCouncilStaff) {
                props.dispatch(actions.selections.setStaffPromo(promo));
            }

            if (promo === "fsg") {
                // Restricts user from changing membership type and centre
                props.dispatch(
                    actions.restrictions.setRestrictions(true, promo)
                );
            }
        }

        // store cut-off date
        if (cutoffDate) {
            props.dispatch(actions.app.setCutoffDate(cutoffDate));
        }

        // store start date
        if (startDate) {
            let today = moment().startOf("day");
            let start = moment(startDate).startOf("day");
            let offsetDays = null;

            if (start.isValid()) {
                offsetDays = start.diff(today, "days");
            }

            if (offsetDays === null) {
                console.error("startDate invalid", startDate);
            } else if (offsetDays < 0) {
                console.error("startDate cannot be before today");
            } else if (offsetDays > 7) {
                console.error("startDate cannot be greater than 7 days");
            } else {
                props.dispatch(
                    actions.selections.setStartDateOffset(
                        { days: offsetDays },
                        start.toISOString()
                    )
                );
            }
        }

        // Preselects number of members in membership form
        if (adults || juniors || seniors || concessions) {
            var users = {
                adults: adults,
                juniors: juniors,
                seniors: seniors,
                concessions: concessions
            };
            this.props.dispatch(actions.selections.preselectUsers(users));
        }

        if (location.pathname + location.search === redirUrl) {
            return;
        }

        if (location.search.toLowerCase().indexOf('utm') !== -1) {
            setTimeout(() => {
                if (location.pathname + location.search === redirUrl) {
                    return;
                }
               return history.replace(redirUrl);
            }, 500); // seems to keep the url intact long enough
        }

        return history.replace(redirUrl);
    },

    //switch flows if different route
    _setNewRoute: function _setNewRoute(path) {
        switch (path) {
            case "memberships":
                this.props.dispatch(
                    actions.app.setTagsBlackList("0000-SHORT", "0000-PARTNER")
                );
                this.props.dispatch(actions.app.resetTagsWhiteList());
                this.props.dispatch(actions.selections.changeDuration(1, 0));
                this.props.dispatch(
                    actions.stages.setFlow(
                        this.props.availableFlows.newCustomer.newMembership
                    )
                );
                break;
            case "short-memberships":
                this.props.dispatch(actions.app.setTagsWhiteList("0000-SHORT"));
                this.props.dispatch(actions.app.resetTagsBlackList());
                this.props.dispatch(actions.selections.changeDuration(1, 1));
                this.props.dispatch(actions.stages.setShortMembershipsPath());
                this.props.dispatch(
                    actions.stages.setFlow(
                        this.props.availableFlows.newCustomer.newMembership
                    )
                );
                break;
            case "lessons":
                var query = queryString.parse(location.search);
                var lessonId = query.lessonid ? query.lessonid : null;
                this.props.dispatch(actions.lessons.setView());
                this.props.dispatch(
                    actions.stages.setFlow(
                        this.props.availableFlows.lessonsCustomer.newMembership
                    )
                );
                this.props.dispatch(actions.lessons.fetchTypes(lessonId));
                break;
            default:
                break;
        }
    },

    _newCentreInfoRequired: function _newCentreInfoRequired(props) {
        return (
            !props.centre ||
            !props.centre.info ||
            !props.user.loggedIn ||
            !props.user.user ||
            (!props.user.user.info &&
                !props.centre.info.site_id ===
                    parseInt(props.user.user.info.siteId, 10))
        );
    },

    _loadLiveChat: function _loadLiveChat(user) {
        this.setState({ loadingLiveChat: true });

        // Only starting live chat after the app component was mounted, and only
        //  if the flag is active and we have a license.
        if (
            config.services.livechat.enabled &&
            config.services.livechat.license
        ) {
            var liveChatOptions = {
                dispatch: this.props.dispatch,
                license: config.services.livechat.license
            };

            if (user && user.mrmId) {
                liveChatOptions.customVariables = {
                    mrmId: user.mrmId,
                    name:
                        user.salutation +
                        " " +
                        user.first_name +
                        " " +
                        user.last_name
                };
            }

            liveChat.setState(this.props);
            liveChat.load(liveChatOptions);
        }
    },

    _handleFocus: function _handleFocus() {
        if (!this.state.navigatingWithKeyboard) {
            return;
        }

        if (document.activeElement) {
            sivin(document.activeElement, {
                block: "center",
                inline: "center"
            });
        }

        // sivin only moves the element 'in view', which means
        //  in the window. Wether it's visible or hidden behind
        //  a fixed header, it doesn't care.
        // Therefore, here we check if the element's top is also
        //  above the bottom of the header and scroll the view
        //  accordingly if it is.
        var header = document.querySelector(".header"),
            viewportBounds = document.activeElement.getBoundingClientRect(),
            headerBounds = header.getBoundingClientRect(),
            headerHeight = headerBounds.height,
            top = viewportBounds.top;

        if (top < headerHeight) {
            document.body.scrollTop -= headerHeight;
        }

        if (this.state.navigatingWithKeyboard) {
            this.setState({ navigatingWithKeyboard: false });
        }
    },

    _handleHotkeys: function _handleHotkeys() {
        if (!this.state.navigatingWithKeyboard) {
            this.setState({ navigatingWithKeyboard: true });
        }
    },

    _navigateWithMouse: function _navigateWithMouse() {
        if (this.state.navigatingWithKeyboard) {
            this.setState({ navigatingWithKeyboard: false });
        }
    },

    _handleResize: function _handleResize() {
        var w = window,
            d = document,
            documentElement = d.documentElement,
            body = d.getElementsByTagName("body")[0],
            width =
                w.innerWidth || documentElement.clientWidth || body.clientWidth,
            height =
                w.innerHeight ||
                documentElement.clientHeight ||
                body.clientHeight,
            afterElement = window.getComputedStyle
                ? window.getComputedStyle(document.body, ":after")
                : false,
            breakpoint;

        if (afterElement) {
            breakpoint = afterElement.getPropertyValue("content");

            if (breakpoint !== this.props.breakpoint) {
                // Depending on the browser used, double quoted values
                //  can be returned by getPropertyValue 'content'.
                // If you need to do comparision with the actual
                //  breakpoiint string in your component, make sure you test
                //  for both like:
                //       `if (breakpoint === 'bp-l' || breakpoint === '"bp-l"')``
                //
                // Another way is to declare which breakpoint you intend to
                //  link to some functionality and use indexOf like
                //      'if ('['bp-l', '"bp-l"', 'bp-xl', '"bp-xl"'].indexOf(bp) !== -1)`
                //
                // You could also use regular expressions to test that.
                this.props.dispatch(
                    actions.app.setBreakpoint(breakpoint, {
                        width: width,
                        height: height
                    })
                );
            }
        }
    },

    render: function() {
        var handlers = {
                tabUp: this._handleHotkeys,
                tabDown: this._handleHotkeys
            },
            outerClass = classNames("app", {
                "app--full-width": this.state.route && this.state.route.noBg
            }),
            appClass = classNames(
                "app-inner",
                Object.keys(this.props.showModals).map(x => x + "--open"),
                {
                    "app-inner--open": this.props.showSidebar,
                    "modal--open": Object.keys(this.props.showModals).length > 0
                }
            );

        if (this.props.isKiosk) {
            outerClass += " kiosk";
        }

        if (
            (!this.props.rehydrated ||
                !this.props.profileLoaded ||
                (!this.props.started && this.props.timedOut)) &&
            !this.props.showStaffLogin
        ) {
            return <Loader />;
        } else {
            return (
                <div className={outerClass}>
                    <div
                        onClick={this._navigateWithMouse}
                        onFocus={this._handleFocus}
                    >
                        {this.state.route.header}
                        <HotKeys
                            className={appClass}
                            keyMap={keyMap}
                            handlers={handlers}
                        >
                            {this.props.showStaffLogin ? (
                                <StaffLogin />
                            ) : (
                                this.state.route.component
                            )}
                        </HotKeys>
                        <FooterSecurity />
                        <Modals />
                        <Loader />
                    </div>

                    <EventListener
                        target="window"
                        onResize={this._handleResizeDebounced}
                    />
                </div>
            );
        }
    }
});

function mapStateToProps(state) {
    return {
        isKiosk: !!state.app.kioskHomeUrl,
        allowedPaths: state.stages.allowedPaths,
        availableFlows: state.stages.availableFlows,
        breakpoint: state.app.breakpoint,
        centre: state.centreFinder.selected,
        centreFinder: state.centreFinder,
        discounts: state.discounts,
        duration: state.selections.duration,
        durationType: state.selections.durationType,
        flow: state.stages.flow,
        fetchedUsers: state.selections.fetchedUsers,
        isRestricted: state.restrictions.restricted,
        lessons: state.lessons,
        livechatReady: state.liveChat.ready,
        payments: state.payments,
        profileLoaded: state.app.profileLoaded,
        promo: state.selections.promo,
        pricing: state.selections.pricing,
        routing: state.routing,
        showLoader: state.app.showLoader,
        showLivechat: state.liveChat.show,
        showSidebar: state.app.showSidebar,
        showStaffLogin: state.staffLogin.showLogin,
        staffId: state.staffLogin.staffId,
        staffSearch: state.staffSearch,
        started: state.app.started,
        steps: state.stages.steps,
        timedOut: state.app.timedOut,
        user: state.user,
        users: state.selections.users,
        preselectedPartner: state.selections.preselectedPartner,
        preselectedSubsByGroups: state.selections.preselectedSubsByGroups,
        showModals: state.app.showModals
    };
}

module.exports = connect(mapStateToProps)(App);
