require('./utils/polyfills');

var compose                 = require('redux').compose,
    createStore             = require('redux').createStore,
    applyMiddleware         = require('redux').applyMiddleware,
    autoRehydrate           = require('redux-persist').autoRehydrate,
    thunkMiddleware         = require('redux-thunk').default,
    appStore                = require('./reducers'),
    historyModule           = require('./utils/history'),
    syncHistoryWithStore    = require('react-router-redux').syncHistoryWithStore;

var store = compose(autoRehydrate())(createStore)(
    appStore,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(thunkMiddleware));

// This is all we need to do sync browser history with the location
// state in the store.
syncHistoryWithStore( historyModule, store );

window.store = store;

module.exports = store;