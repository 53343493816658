/* global globalPageData */
var exports;

// provides a global 'fetch' method.
require('isomorphic-fetch');

const requestCache = {};

function getHash(...args) {
    var str = JSON.stringify(args);

    return str.split('').reduce((prevHash, currVal) =>
      (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
}

module.exports = exports = {
    checkStatus: function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;

        } else {
            return exports.parseJSON(response)
                .then(function(errBody) {
                    var error;
                    // errBody contains the json response from the server

                    if(errBody.message) {
                        error = new Error(errBody.message);
                        error.message = errBody.message;
                        error.attemptsRemaining = errBody.attempts_remaining;
                        error.status = response.status || 500;
                    } else {
                        error = new Error(errBody.error);
                        error.status = response.status || 500;
                    }

                    throw error;
                });
        }
    },

    parseJSON: function parseJSON(response) {
        var ct = response.headers.get('Content-Type');
        if (ct && ct.indexOf('application/json') !== -1) {
            return response.json();

        } else {
            return response;
        }
    },

    timeoutPromise: function timeoutPromise(timeout, err, promise) {
        return new Promise(function(resolve,reject) {
            promise.then(resolve,reject);
            setTimeout(reject.bind(null,err), timeout);
        });
    },

    // Do NOT rename 'fetchData' to 'fetch' as this is what
    //  'isomorphic-fetch' provides as a global. You'd create
    //  an infinite loop in this method.
    fetch: function fetchData(url, options, noXsrf) {

        var method = options.method.toLowerCase(),
            timeoutError = new Error('request timed out for ' + url),
            timedOut = false;

        options.headers = options.headers || {};

        if(!noXsrf) {
            options.headers['X-CSRF-TOKEN'] = globalPageData.csrfToken;
        }

        options.credentials = 'include';

        if (options.params) {
            if (Object.keys(options.params).length) {
                if ('get' === method) {
                    var esc = encodeURIComponent,
                        query,
                        sep = '?';

                    query = Object.keys(options.params)
                            .map(function (k) { return esc(k) + '=' + esc(options.params[k]); })
                            .join('&');

                    if (-1 !== url.indexOf('?')) {
                        sep = '&';
                    }

                    url = url + sep + query;

                } else if ('post' === method || 'put' === method || 'patch' === method) {
                    options.body = JSON.stringify(options.params);
                }
            }

            delete options.params;
        }

        var hash = getHash(url, options);

        if (!requestCache[hash]) {
            requestCache[hash] = exports.timeoutPromise(60000, timeoutError, fetch(url, options))
                .then(function(response) {
                    if (timedOut) {
                        throw timeoutError;
                    }
                    delete requestCache[hash];

                    return response;
                })
                .then(exports.checkStatus)
                .then(exports.parseJSON)
                .catch(function(e){
                    delete requestCache[hash];

                    throw e;
                });
        }

        return requestCache[hash];
    }
};
