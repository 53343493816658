var React           = require('react'),
    connect         = require('react-redux').connect,

    Content         = require('./content'),
    Sidebar         = require('../sidebar'),

    StaffLogin;

StaffLogin = React.createClass({
    render: function() {
        return (<div className='page'>
                    <Content />
                    <Sidebar />
                </div>);
    }
});

module.exports = connect()(StaffLogin);
