import React from "react";

export class BrightcovePlayer extends React.Component {
    render () {
        // We've got both brightcove_id and video_id for historical and evolved purposes
        // @todo remove this entire thing eventually
        const { video: { brightcove_id, video_id }, player_id, account_id } = this.props;

        return ( <div>
            <video
                data-video-id={ video_id || brightcove_id }
                data-account={ account_id }
                data-player={ player_id }
                data-embed="default"
                data-application-id
                className="video-js"
                controls
                style={ { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, width: '100%', height: '100%' } }>
            </video>
            <script defer src={ `https://players.brightcove.net/${ account_id }/${ player_id }_default/index.min.js` }></script>
        </div> );
    }
}