var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var defaultState = {
        error: null,
        gatewayError: null,
        status: null,
        taggingFired: false
    };

    state = state || defaultState;

    switch (action.type) {

        case actionTypes.PAYMENTS.REPORT:
            return Object.assign({}, state, {
                status: action.status,
                gatewayError: action.gatewayError,
                error: action.error // If there is an error in the code, the gateway might have reported a success status, and the payment processed, but our code threw an error.
            });

        case actionTypes.PAYMENTS.TAGGING_FIRED:
            return Object.assign({}, state, {
                taggingFired: true
            });

        case actionTypes.PAYMENTS.WIPEOUT:
            return defaultState;

        default:
            return state;
    }
};
