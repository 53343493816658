// TODO: This should extend a sidebar Expander component
var React           = require('react'),
    connect         = require('react-redux').connect,
    Button          = require('../common/button'),
    SidebarDiscounts;

SidebarDiscounts = React.createClass({
    render: function render() {

        if (!(this.props.details && this.props.details.promotionCodeApplied)) {
            return null;
        }

        if (!this.props.centre || !this.props.centre.info || !this.props.discount) {
            return null;
        }

        var content = this.props.content,
            discount = this.props.discount,
            discountName,
            discountText,
            isCorporateDiscount = discount.type === 'corporate';

        if (isCorporateDiscount) {
            discountName = discount.details.companyName;
            discountText = <span className='sidebar-discounts__discount-text'>{ content.corporateProofText }</span>;

        } else {
            discountName = discount.details.name;
        }

        return (
            <div className={ 'sidebar-expander sidebar-discounts' + (this.props.expanded ? ' sidebar-expander--expanded' : ' sidebar-expander--collapsed') }>
                <Button key='sidebar-discounts-toggle' className='sidebar-expander__toggle' onClick={ this.props.toggleDetails }>{ content.discountApplied }<span className='sidebar-expander__icon' aria-label={ this.props.expanded ? 'collapse' : 'expand' }></span></Button>
                <div className='sidebar-expander__details sidebar-discounts__details'>
                    <div className='inner'>
                        <span className='sidebar-discounts__discount-name'><span className='sidebar-discounts__discount-icon'></span>{ discountName }</span>
                        { discountText }
                        { !isCorporateDiscount && this.props.details.discountDesc }
                        <Button key='sdr' className='sidebar-discounts__remove-button' tabIndex={ this.props.expanded ? '0' : '-1' } onClick={ this.props.removeDiscount }>{ content.removeDiscount }</Button>
                    </div>
                </div>
            </div>
        );
    }
});

SidebarDiscounts.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    details: React.PropTypes.object,
    discount: React.PropTypes.object,
    expanded: React.PropTypes.bool,
    removeDiscount: React.PropTypes.func,
    toggleDetails: React.PropTypes.func
};

module.exports = connect()(SidebarDiscounts);
