var React       = require('react'),

    utils       = require('../../utils'),
    viewUtils   = require('../../utils/viewUtils'),

    Button          = require('../common/button'),

    PaymentBreakdown;

PaymentBreakdown = React.createClass({
    getInitialState: function getInitialState() {
        return {
            expanded: {}
        };
    },

    componentDidUpdate: function componentDidUpdate() {
        var key,
            elt;

        for (key in this.state.expanded) {
            elt = document.querySelector('.payment-breakdown__block-details--' + key);
            elt && viewUtils.adjustHeight(elt);
        }
    },

    _handleExpandClick: function _handleExpandClick(key, e) {
        e.preventDefault();

        var elt = document.querySelector('.payment-breakdown__block-details--' + key),
            newExpanded = Object.assign({}, this.state.expanded);

        if (this.state.expanded && this.state.expanded[key]) {
            delete newExpanded[key];
            this.setState({ expanded: newExpanded });
            elt && viewUtils.furl(elt);
        } else{
            newExpanded[key] = true;
            this.setState({ expanded: newExpanded });
            elt && viewUtils.furl(elt);
        }
    },

    _buildBlocks: function _buildBlocks() {
        var content = this.props.content,
            renewedSubs = this.props.renewedSubs,
            priceItems = [],
            blocks = [],
            items = [],
            total = 0,
            key = 1,
            expanded = this.state.expanded[key],
            i;

        for(i = 0; i < renewedSubs.length; i++) {
            var currentSub = renewedSubs[i];

            total += currentSub.NextCollectionAmount*100;

            priceItems.push(<li className='payment-breakdown__item' key={ 'pi_' + i }>
                <span className={'payment-breakdown__item-label'}>{ currentSub.Description }</span>
                <span className='payment-breakdown__item-amount'>{ utils.formatPrice(currentSub.NextCollectionAmount*100) }</span>
            </li>);
        }

        items.push(<div className='payment-breakdown__item-container' key='p'>
            { priceItems }
        </div>);

        blocks.push(<li className='payment-breakdown__block' key={ 'r_' + key }>
            <span className='payment-breakdown__block-title'><span className='payment-breakdown__block-title-text'>{ content.titleNow }</span></span>
            <span className={ 'payment-breakdown__payment-method payment-breakdown__payment-method--card' }>
                { content.paymentMethod }<span className='payment-breakdown__payment-method-type'>{ content.paymentMethodCard }</span>
            </span>
            <div className='payment-breakdown__item payment-breakdown__item--total'>
                <span className='payment-breakdown__item-label'>{ content.total }</span>
                <span className='payment-breakdown__item-amount'>{ utils.formatPrice(total) }</span>
                <Button className={ 'button__more-info payment-breakdown__item-button' + (expanded ? ' button__more-info--expanded' : '') } onClick={ this._handleExpandClick.bind(null, key) }>{ expanded ? content.hideBreakdown : content.seeBreakdown }</Button>
            </div>
            <div className={ 'payment-breakdown__block-details payment-breakdown__block-details--expanded payment-breakdown__block-details--' + key }>
                <div className='inner'>
                    <ul className='payment-breakdown__block-details-list'>
                        { items }
                    </ul>
                </div>
            </div>
        </li>);

        return <ul className='payment-breakdown__blocks'>{ blocks }</ul>;
    },

    render: function() {

        var content = this.props.content,
            titleText = content.annualCost,
            blocks = this._buildBlocks();

        return <div className='module-container'>
            <div className='module module--inactive'>
                <div className='payment-breakdown'>
                    { blocks }
                </div>
            </div>
        </div>;
    }
});

PaymentBreakdown.propTypes = {
    content: React.PropTypes.object
};

module.exports = PaymentBreakdown;
