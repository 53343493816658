var React = require('react'), // eslint-disable-line no-unused-vars
    Header = require('../components/common/header'),
    Component = require('../components/stage1'),
    actions = require('../actions');

module.exports = [
    {
        path: '/short-memberships',
        // stage: false,

        handler: function(params, query) {

            return {
                header: <Header showStages={true} />,
                component: <Component query={query} />
            };
        }
    }
];
