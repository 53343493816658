var validateIsEmail = require('validator/lib/isEmail'), // eslint-disable-line no-unused-vars
    validateIsMobilePhone = require('validator/lib/isMobilePhone'), // eslint-disable-line no-unused-vars
    httpFetch = require('../utils/httpFetch'),
    config = require('../config'),
    exports;

window.config = config;

module.exports = exports = {
    getVoucher: function getVoucher(voucherCode, promoCode) {
        var url = config.services.voucher.urls.get;

        return httpFetch.fetch(url, {
            method: 'GET',
            params: {
                vouchercode: voucherCode
            },
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    },

    validateVoucher: function validateVoucher(voucherCode, centreId) {
        var url = config.services.voucher.urls.validate;

        return httpFetch.fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            params: {
                vouchercode: voucherCode,
                LocationID: centreId
            }
        });
    },

    confirmRedeemVoucher: function confirmRedeemVoucher(redemptionId) {
        var url = config.services.voucher.urls.confirm;

        return httpFetch.fetch(`${url}/${redemptionId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            params: {
                RedemptionID: redemptionId
            }
        });
    }
};
