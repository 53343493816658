var React           = require('react'),
    Price;

Price = React.createClass({
    render: function () {
        var amount = this.props.amount,
            currency = this.props.currency || '£',
            sign = null;

        if (amount < 0) {
            sign = <span className='price__sign'>-</span>;
            amount = -amount;
        }

        amount = (amount / 100).toFixed(2);

        // If amount isn't a number, or we wish to convert falsey to dash dash
        if ( isNaN( amount ) || ( this.props.denoteZeroAsDash && !this.props.amount )) {
            amount = '--.--';
        }

        return (
            <span className='price'>
                { sign }
                <span className='price__currency'>
                    { currency }
                </span>
                <span className='price__amount'>
                    { amount }
                    { this.props.asterisk }
                </span>
            </span>
        );
    }
});

Price.propTypes = {
    amount: React.PropTypes.number,
    asterisk: React.PropTypes.string,
    currency: React.PropTypes.string
};

module.exports = Price;
