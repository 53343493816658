var debug               = require('debug')('actions-selections'),
    actionTypes         = require('./actionTypes.js'),
    appActions          = require('../actions/app.js'),
    stagesActions       = require('../actions/stages.js'),
    centreFinderActions = require('../actions/centreFinder.js'),
    membershipsActions  = require('../actions/memberships.js'),
    selectionsActions   = require('../actions/selections.js'),
    userModel           = require('../models/user'),
    httpFetch           = require('../utils/httpFetch'),

    config              = require('../config'),

    exports;

module.exports = exports = {
    changeUserData: function changeUserData(field, value) {
        return function(dispatch) {
            dispatch(exports.validating(true));

            var result = {
                    valid: false,
                    value: value,
                    validationErrors: {}
                },
                promise;

            if ('label' === field) {
                if (!value) {
                    result.valid = false;
                    result.validationErrors = { required: true };
                } else {
                    result.valid = true;
                }

                promise = Promise.resolve(result);

            } else {
                promise = userModel.validateField(field, value);
            }

            return promise.then(function(result) {
                dispatch(exports.userDataValidated(field, result));
                dispatch(exports.validating(false));
            });
        };
    },

    save: function save(payload, callback) {
        var url = config.services.memberships.urls.selections,
            data = {
                email: payload.email,
                firstName: payload.firstName,
                lastName: payload.lastName,
                label: payload.label,
                value: {}
            },
            method = 'POST',
            modalContent,
            extras,
            centre,
            users,
            state,
            i;

        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));

            state = getState();
            centre = state.centreFinder.selected;
            users = JSON.parse(JSON.stringify(state.selections.users));
            extras = state.selections.extras;
            modalContent = state.app.content.modals.saveSelections;

            if (centre && centre.info.site_id) {
                data.value.centreId = centre.info.site_id;
            }

            if (users && users.total > 0) {
                // Resetting unnecessary data before storing
                for (i = 0; i < users.objects.length; i++) {
                    users.objects[i].info = { obust: true };
                    users.objects[i].bankDetails.collections = { obust: true };
                    users.objects[i].bankDetails.fields = { obust: true };
                    users.objects[i].bankDetails.errors = { obust: true };
                    users.objects[i].bankDetails.paymentUrl = null;
                    users.objects[i].bankDetails.total = null;
                    users.objects[i].bankDetails.unique = null;
                    users.objects[i].bankDetails.valid = null;
                    users.objects[i].bankDetails.validationInProgress = null;
                    users.objects[i].isLoggedInUser = false;
                    delete users.objects[i].directDebit;
                }

                data.value.users = users;
            }

            if (extras) {
                data.value.extras = extras;
            }

            // If we've got a token, we know it's an update
            if (payload.token) {
                method = 'PUT';
                url += '/' + payload.token;
            }

            return httpFetch.fetch(url, {
                method: method,
                params: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                // If PUT, then there is no response.
                if (response && response.key) {
                    dispatch(exports.tokenReceived(payload.email, response.key));
                }
                callback && callback();
                dispatch(appActions.showLoader(false));
                dispatch(appActions.showModal('success', { text: modalContent.success }));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
                dispatch(appActions.error(e));
                dispatch(appActions.showLoader(false));
            });
        };
    },

    tokenReceived: function tokenReceived(email, token) {
        return {
            type: actionTypes.SAVE_SELECTIONS.TOKEN_RECEIVED,
            email: email,
            token: token
        };
    },

    userDataValidated: function userDataValidated(field, result) {
        return {
            type: actionTypes.SAVE_SELECTIONS.USER_DATA_VALIDATED,
            field: field,
            valid: result.valid,
            value: result.value,
            validationErrors: result.validationErrors
        };
    },

    retrieveSelections: function retrieveSelections(token) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.memberships.urls.selections + '/' + token, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                var data = {};

                // if we've got a centre, reset everything and apply
                if (response.centreId) {
                    // Order here is important since select centre will
                    //  also be used by the memberships reducer.
                    // Possibly not the best, but that's to avoid going
                    //  over all centre subscriptions when doing anything
                    //  with memberships.
                    dispatch(selectionsActions.wipeout());
                    dispatch(centreFinderActions.resetSelection());
                    dispatch(centreFinderActions.selectCentre(response.centreId));

                    if (response.users) {
                        data.users = response.users;
                    }

                    if (response.extras) {
                        data.extras = response.extras;
                    }

                    dispatch(selectionsActions.applyUserData(data));

                    dispatch(membershipsActions.selectMemberships());
                    dispatch(membershipsActions.freeze());

                    dispatch(exports.selectionsReceived(response));
                }

                dispatch(stagesActions.goHome());
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
                dispatch(appActions.error(e));
                dispatch(stagesActions.goHome());
                dispatch(appActions.showLoader(false));
            });
        };
    },

    selectionsReceived: function selectionsReceived(selections) {
        return {
            type: actionTypes.SAVE_SELECTIONS.SELECTIONS_RECEIVED,
            selections: selections
        };
    },

    validating: function validating(status) {
        return {
            type: actionTypes.SAVE_SELECTIONS.VALIDATING,
            validating: status
        };
    }
};
