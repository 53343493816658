var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/header'),
    Component       = require('../components/stage3');

module.exports = [{

    path: '/about-you',

    stage: true,

    handler: function () {

        return {
            header: <Header showStages={ true } />,
            component: <Component />
        };
    }
}];
