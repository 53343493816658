var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    ErrorModal;

ErrorModal = React.createClass({
    _onClose: function _onClose(e) {
        e.preventDefault();

        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    render: function render() {
        var content = this.props.content;

        return <Modal title={ content.title } id={ this.props.id } className='modal--error'>
            <p>{ JSON.stringify(this.props.error.stack) }</p>
            <Button className='button' onClick={ this._onClose }>{ this.content.buttonClose }</Button>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.error,
        error: state.app.error,
        usersCount: state.selections.users.count
    };
}

module.exports = connect(mapStateToProps)(ErrorModal);
