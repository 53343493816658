var React = require('react'),
    connect = require('react-redux').connect,

    actions = require('../../actions'),
    utils = require('../../utils'),

    Button = require('../common/button'),
    Modal = require('../common/modal'),

    PaymentNotFoundModal;

PaymentNotFoundModal = React.createClass({
    getInitialState: function getInitialState() {
        return {};
    },

    _onRetry: function _onRetry() {
        this.props.dispatch(actions.app.showLoader(true));

        setTimeout(() => {
            this.props.dispatch(actions.directDebitPage.checkPaid());
        }, 1000);
    },

    _onCancel: function _onCancel() {
        this.props.dispatch(actions.app.hideModal('paymentNotFound'));
    },

    render: function render() {
        var { modal } = this.props,
            invoiceIds = this.props.options.invoiceIds;
        // To be added in later, maybe
        // const missingInvoiceIds = this.props.options.missingInvoiceIds || '';

        return (
            <Modal title={modal.title} className='modal--confirm'>
                <p dangerouslySetInnerHTML={{ __html: modal.text.replace('{invoiceIds}', invoiceIds) }}></p>
                {/* {missingInvoiceIds && <p>The following invoices are no longer available for payment and may have timed out {missingInvoiceIds}.</p>} */}
                <div className='modal__buttons'>
                    <Button className='button button--greyed-out' onClick={this._onCancel}>{modal.buttonCancel}</Button>
                    <Button className='button button--primed' onClick={this._onRetry}>{modal.buttonRetry}</Button>
                </div>
            </Modal>
        );
    }
});

function mapStateToProps(state) {
    return {
        modal: state.app.content.modals.paymentNotFound
    };
}

module.exports = connect(mapStateToProps)(PaymentNotFoundModal);
