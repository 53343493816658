var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var defaultState = {
        isBooking: false,
        urlParams: {}
    };

    state = state || defaultState;

    switch (action.type) {

        case actionTypes.BOOKING.SET_PAGE:
            return Object.assign({}, state, {
                isBooking: true,
                isGymProfile: false,

            });

        case actionTypes.BOOKING.SET_GYM_PROFILE:
            return Object.assign({}, state, {
                isBooking: false,
                isGymProfile: true,
            });

        case actionTypes.BOOKING.SET_URL_PARAMS:
            return Object.assign({}, state, {
                urlParams: action.params
            });

        case actionTypes.BOOKING.GET_CONCESSION_OPTIONS:
            return Object.assign({}, state, {

            });

        default:
            return state;
    }
};
