// TODO: This should extend a sidebar Expander component
var React           = require('react'),
    connect         = require('react-redux').connect,
    UserDiscountPromo;

UserDiscountPromo = function (props) {
    if (!props.userDiscountPromo) {
        return null;
    }

    return (
        <div className='sidebar-user-discount-promo'>
            <div className='inner'>
                <span className='sidebar-user-discount-promo__description'>
                    { props.content.topText     }
                </span>
                <span className='sidebar-user-discount-promo__discount-code'>
                    { props.userDiscountPromo }
                </span>
                <span className='sidebar-user-discount-promo__caveat'>
                    { props.content.bottomText}
                </span>
            </div>
        </div>
    );
};

UserDiscountPromo.propTypes = {
};

function mapStateToProps(state) {
    return {
        userDiscountPromo: state.selections.userDiscountPromo,
        content: state.app.content.sidebar.userDiscountPromo
    };
}

module.exports = connect(mapStateToProps)(UserDiscountPromo);
