var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {

    state = state || {
        results: null
    };

    switch (action.type) {

        case actionTypes.TESTIMONIALS.RESULTS_RECEIVED:
            return Object.assign({}, state, {
                results: action.results
            });

        case actionTypes.TESTIMONIALS.WIPEOUT:
            return  Object.assign({}, state, {
                results: null
            });

        default:
            return state;
    }
};
