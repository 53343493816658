var actionTypes         = require('./actionTypes.js'),
    selectionsActions   = require('./selections.js'),
    appActions          = require('./app.js'),
    centreActions       = require('./centreFinder.js'),
    discountActions     = require('./discounts.js'),
    voucher             = require('../utils/voucher.js'),
    utils           = require('../utils'),

    exports;

const voucherCodeSuffixes = ['EASTAFFW', 'EASTAFFSE', 'EASTAFFE'];

module.exports = exports = {
    setApplyDisabled: function(applyDisabled) {
        return {
            type: actionTypes.DISCOUNT_CODES.SET_APPLY_DISABLED,
            applyDisabled
        };
    },

    setCorporateDiscount: function(discount) {
        return {
            type: actionTypes.DISCOUNT_CODES.SET_CORPORATE_DISCOUNT,
            discount
        };
    },

    toggleExpanded: function(isExpanded) {
        return {
            type: actionTypes.DISCOUNT_CODES.TOGGLE_EXPAND,
            isExpanded
        };
    },

    toggleDiscountType: function(isToggled) {
        return function (dispatch, getState) {
            var state = getState(),
                isApplyDisabled = true;

            var userHasPromo = state.selections.users.objects.some(x => x.promoCode.code);

            if (state.discountCodes.isCorporateDiscount && userHasPromo && !state.selections.promo) {
                isApplyDisabled = false;
            }

            if (state.discountCodes.isPromoCode && !state.discounts.discount) {
                isApplyDisabled = false;
            }

            dispatch(exports.setApplyDisabled(isApplyDisabled));

            dispatch({
                type: actionTypes.DISCOUNT_CODES.TOGGLE_DISCOUNT_TYPE,
                isToggled
            });
        };
    },

    applyDiscount: function(content) {
        return async function (dispatch, getState) {
            var state = getState(),
                centerId = parseInt(state.centreFinder.selected.info.site_id);

            dispatch(selectionsActions.setIsDiscountApplied(null));

            if (state.discountCodes.isCorporateDiscount) {
                dispatch(appActions.showLoader(true));

                dispatch(centreActions.selectCentre(state.centreFinder.selected.info.site_id, {
                    discount: state.discountCodes.corporateDiscount,
                    quite: true
                }));

                dispatch(exports.setApplyDisabled(true));
            }
            else if (state.discountCodes.isPromoCode) {
                var promoCode = null,
                    users = [...state.selections.users.objects];

                dispatch(discountActions.removeDiscount());
                dispatch(appActions.showLoader(true));

                try {
                    for (var i = 0; i < users.length; i++) {
                        var userPromo = users[i].promoCode,
                            [codePrefix, codeSuffix] = userPromo.code ? userPromo.code.split('-') : [],
                            vailationResult = null;

                            if (codeSuffix && voucherCodeSuffixes.includes(codeSuffix.toUpperCase()) && (!state.staffLogin || !state.staffLogin.staffId)) {
                            let staffError = state.app.content.directDebitPage.codeStaffOnly;

                            dispatch(selectionsActions.setUserPromoCode({
                                code: userPromo.code.trim(),
                                userId: i,
                                isValid: false,
                                errorMsg: staffError
                            }));

                            throw staffError;
                        }

                        try {
                            dispatch(appActions.showLoader(true));

                            //  skip validation for promo code if the user left the field blank, and there are more then one user present and at least one promo code is filled in.
                            let filledPromoCountCount = users.filter(user => { return user.promoCode && user.promoCode.code && user.promoCode.code.length > 0; }).length;
                            if ( (userPromo.code && userPromo.code.length) || users.length === 1 || filledPromoCountCount === 0) {
                                vailationResult = await voucher.validateVoucher(userPromo.code, centerId);
                                if(vailationResult.errors.length > 0) {
                                    dispatch(selectionsActions.setIsDiscountApplied(false));
                                    throw true;
                                }
                            }
                        }
                        catch (error) {
                            dispatch(selectionsActions.setUserPromoCode({
                                code: userPromo.code.trim(),
                                userId: i,
                                isValid: false,
                                errorMsg: vailationResult.errors ?  vailationResult.errors[0] : 'unkonw error'
                            }));

                            throw error;
                        }

                        var setObj = {
                            code: userPromo.code,
                            userId: i,
                            isValid: null
                        };

                        if(!promoCode && codePrefix) {
                            setObj.isValid = true;
                            promoCode = codePrefix;
                        }
                        else if (!codePrefix) {
                            setObj.isValid = null;
                            setObj.errorMsg = null;
                        }
                        else if (promoCode && promoCode !== codePrefix) {
                            setObj.isValid = false;
                            setObj.errorMsg = content.errorCodeInSession;
                        }
                        else {
                            setObj.isValid = true;
                        }

                        if(vailationResult) {
                            setObj.redemptionId = vailationResult.RedemptionID;
                        }

                        dispatch(selectionsActions.setUserPromoCode(setObj));
                    }

                    dispatch(discountActions.discountSelected(utils.buildDiscountCode({
                        type: 'code',
                        value: promoCode
                    })));

                    dispatch(centreActions.selectCentre(state.centreFinder.selected.info.site_id, {
                        removeDiscountOnError: true
                    })).then(function() {
                        let state = getState();
                        let isDiscountApplied = false;
                        let durationType = state.selections.durationType;

                        if (state.selections.pricing.periods && state.selections.pricing.periods[durationType]) {
                            isDiscountApplied = Object.values( state.selections.pricing.periods[ durationType ] )
                                .flatMap( period => Object.values( period.ranges )
                                    .flatMap( periodRange => periodRange.users ) )
                                .flat()
                                .some( subscription => !!subscription.discountDesc )
                        }

                        dispatch(selectionsActions.setIsDiscountApplied(isDiscountApplied));
                    });

                } catch (error) {
                    dispatch(appActions.showLoader(false));

                    return null;
                }
            }
        };
    },

    wipeout: function() {
        return {
            type: actionTypes.DISCOUNT_CODES.WIPEOUT
        };
    }
};
