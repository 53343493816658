var React           = require('react'),
    connect         = require('react-redux').connect,
    cookie          = require('react-cookie'),

    actions          = require('../../actions'),
    config          = require('../../config'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    FavouriteCentre;

FavouriteCentre = React.createClass({
    onClose: function onClose(e) {
        e.preventDefault();

        this.props.dispatch(actions.app.hideModal(this.props.id));
    },


    saveCentre: function saveCentre() {
        cookie.save('favouriteCentre', this.props.centre.info.site_id, {domain: config.services.everyoneActive.baseUrl});
        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    render: function render() {
        var content = this.props.content;

        return <Modal title={ content.title.replace('{centre}', this.props.centre.info.name) } id={ this.props.id } className='modal--confirm'>
            <div className='modal__buttons'>
                <Button className='button button--greyed-out' onClick={ this.onClose }>{ content.buttonDecline }</Button>
                <Button className='button button--primed' onClick={ this.saveCentre }>{ content.buttonAccept }</Button>
            </div>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.favouriteCentre,
        centre: state.centreFinder.selected
    };
}

module.exports = connect(mapStateToProps)(FavouriteCentre);
