var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var defaultState = {
        frozen: false,
        selected: false,
        showSpecialPanel: null
    };

    state = state || defaultState;

    switch (action.type) {
        case actionTypes.MEMBERSHIPS.MEMBERSHIPS_SELECTED:
            return Object.assign({}, state, {
                selected: true
            });

        // This one is only a flow step to proceed to the next user journey.
        case actionTypes.MEMBERSHIPS.RESET:
            return Object.assign({}, state, {
                selected: false
            });

        case actionTypes.MEMBERSHIPS.SPECIAL_PANEL_VISIBILITY:
            return Object.assign({}, state, {
                showSpecialPanel: action.id
            });

        case actionTypes.MEMBERSHIPS.FREEZE:
            return Object.assign({}, state, {
                frozen: true
            });

        case actionTypes.MEMBERSHIPS.UNFREEZE:
            return Object.assign({}, state, {
                frozen: false
            });

        case actionTypes.MEMBERSHIPS.WIPEOUT:
            return Object.assign({}, state, defaultState);

        default:
            return state;
    }
};
