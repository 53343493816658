import { push } from "../common/google/analytics/datalayer";

var React = require("react"),
    connect = require("react-redux").connect,
    cookie = require("react-cookie"),
    actions = require("../../actions"),
    viewUtils = require("../../utils/viewUtils"),
    utils = require("../../utils"),
    config = require("../../config"),
    ConfirmUser = require("./confirmUser"),
    Booking = require("./booking"),
    FooterSecurity = require("../common/footerSecurity"),
    ConfirmPage;

ConfirmPage = React.createClass({
    getInitialState: function getInitialState() {
        var expanded = {};

        for (var i = 0; i < this.props.users.length; i++) {
            if (!this.props.users[i].guardian) {
                expanded[i] = true;
                break;
            }
        }

        return {
            usersConfirmed: false,
            expanded: expanded
        };
    },

    _redirectIfPropsNotValid: function _redirectIfPropsNotValid(props) {
        if (!props.currentCentre || !props.users || props.users.length === 0) {
            props.dispatch(actions.stages.goHome());
            props.dispatch(actions.stages.setStep(0));
            props.dispatch(actions.stages.showStep(0));
        }
    },

    componentWillMount: function componentWillMount() {
        var users = this.props.users;

        this._redirectIfPropsNotValid(this.props);

        if (!this.state.usersConfirmed) {
            for (var i = 0; i < users.length; i++) {
                var user = users[i];

                if (
                    user.lesson.selectedLessons &&
                    Object.keys(user.lesson.selectedLessons).length
                ) {
                    for (var l in user.lesson.selectedLessons) {
                        var lesson = user.lesson.selectedLessons[l];

                        if (!users[i].guardian && lesson.bookingRef) {
                            this.props.dispatch(
                                actions.lessons.confirmPayment(
                                    lesson.bookingRef
                                )
                            );
                        }
                    }
                }
            }

            //send enquiry to SCV if additional support was requested by member
            this.props.dispatch(actions.lessons.formatEnquiryForSCV());

            this.setState({ usersConfirmed: true });
        }

        if (this.props.lessons && this.props.lessons.inView) {
            this.props.dispatch(actions.lessons.refreshTimer("clear"));
        }
    },

    componentDidMount: function componentDidMount() {
        window.scrollTo(0, 0);
        this._handleMoreInfoClick(0);

        var discounts = this.props.discounts,
            corpDiscount = "",
            promoDiscount = "",
            productName = "Membership",
            duration = this.props.selections.duration,
            durationType = this.props.selections.durationType,
            adminAmount =
                this.props.pricing &&
                this.props.pricing.periods &&
                this.props.pricing.periods[durationType] &&
                this.props.pricing.periods[durationType][duration].fees
                    .adminPayable,
            durationName = {
                1: "monthly",
                12: "annually"
            },
            revenue = adminAmount,
            products = [],
            adminId;

        for (var i = 0; i < this.props.users.length; i++) {
            var user = this.props.users[i];
            var sub;

            if (!user.guardian) {
                var elt = document.querySelector(
                    ".module__list-item-details--confirm-page-" + i
                );
                elt && viewUtils.furl(elt);
            }

            //creating product object for GA
            if (this.props.lessons && this.props.lessons.inView) {
                productName =
                    "Lessons - " + utils.getLessonType(this.props.lessons);
            }

            if (discounts && discounts.discount) {
                if (discounts.discount.type !== "code") {
                    corpDiscount = discounts.discount.id;
                } else {
                    promoDiscount = discounts.discount.id.toUpperCase();
                }
            }

            if (
                user.availableSubscriptions &&
                user.availableSubscriptions.length
            ) {
                for (var x = 0; x < user.availableSubscriptions.length; x++) {
                    sub = user.availableSubscriptions[x];

                    if (
                        sub.duration === duration &&
                        sub.durationType === durationType
                    ) {
                        products.push({
                            name: productName,
                            id: sub.id,
                            price: (sub.listPriceInPence / 100).toFixed(2),
                            brand: this.props.currentCentre.info.name,
                            quantity: 1,
                            dimension9: durationName[sub.duration],
                            dimension10: user.typeDesc
                        });

                        if (!adminId && sub.price.now && sub.price.now.length) {
                            for (var y = 0; y < sub.price.now.length; y++) {
                                if (
                                    sub.price.now[y].desc
                                        .toLowerCase()
                                        .indexOf("admin") !== -1
                                ) {
                                    adminId = sub.price.now[y].productId;
                                    break;
                                }
                            }
                        }

                        revenue += sub.listPriceInPence;
                    }
                }
            } else if (user.type === "freeprofile") {
                products.push({
                    name: productName,
                    id: user.typeDesc,
                    price: 0,
                    brand: this.props.currentCentre.info.name,
                    quantity: 1,
                    dimension9: user.typeDesc,
                    dimension10: user.typeDesc
                });
            }

            // put the extras into the main subscriptions payload
            for (var key in user.extras) {
                var subTypes = user.extras[key];

                for (var key2 in subTypes) {
                    sub = subTypes[key2];

                    if (sub.durationType === durationType) {
                        products.push({
                            name: sub.desc,
                            id: sub.id,
                            price: (sub.listPriceInPence / 100).toFixed(2),
                            quantity: 1,
                            brand: this.props.currentCentre.info.name,
                            dimension9: durationName[sub.duration],
                            dimension10: user.typeDesc
                        });

                        revenue += sub.listPriceInPence;
                    }
                }
            }
        }

        if (adminAmount) {
            products.push({
                name: "Admin Fee",
                id: adminId,
                price: (adminAmount / 100).toFixed(2),
                quantity: 1
            });
        }

        if (!this.props.taggingFired) {
            push({
                event: "transaction success",
                ecommerce: {
                    currencyCode: "GBP",
                    purchase: {
                        actionField: {
                            id: window.sessionId,
                            revenue: (revenue / 100).toFixed(2),
                            tax: "0",
                            coupon: corpDiscount || promoDiscount || undefined
                        },
                        products: products
                    }
                }
            });

            //prevent tag being fired multiple times
            this.props.dispatch(actions.payments.taggingFired());
        }
    },

    componentWillUpdate: function componentWillUpdate(nextProps) {
        this._redirectIfPropsNotValid(nextProps);
    },

    _handleMoreInfoClick: function _handleMoreInfoClick(key, e) {
        if (e) {
            e.preventDefault();
        }

        var elt = document.querySelector(
                ".module__list-item-details--confirm-page-" + key
            ),
            expanded = Object.assign({}, this.state.expanded);

        if (this.state.expanded[key]) {
            delete expanded[key];

            this.setState({ expanded: expanded });
            elt && viewUtils.furl(elt);
        } else {
            expanded[key] = true;
            this.setState({ expanded: expanded });
            elt && viewUtils.furl(elt);
        }
    },

    reset: function reset(options) {

        let staffLogin = "";
        let setPasswordUrl = "";

        if (options.user) {
            const params = (new URL(options.user.info.profileUrl.value)).searchParams;
            const token = params.get("token");
            setPasswordUrl = `${window.location.protocol}${config.services.selfServe.urls.setPassword}?lmt=${token}`;
        }

        if (this.props.isStaffMember) {
            staffLogin = "?eastaff=true";
        }

        this.props.dispatch(actions.app.showLoader(true));
        this.props.dispatch(actions.selections.wipeout());
        this.props.dispatch(actions.personalDetails.wipeout());
        this.props.dispatch(actions.bankDetails.wipeout());
        this.props.dispatch(actions.payments.wipeout());
        this.props.dispatch(actions.discounts.wipeout());

        this.props.dispatch(actions.lessons.wipeout());

        // reset centre selection
        this.props.dispatch(actions.centreFinder.wipeout());
        // reset memberships and facilities
        this.props.dispatch(actions.memberships.unfreeze());
        this.props.dispatch(actions.memberships.resetSelection());
        this.props.dispatch(actions.memberships.hideSpecialPanel());

        if (this.props.renewalSubs) {
            window.location = config.services.app.urls.profile;
        } else if (options.logout) {
            let gotoUrl =
                window.location.protocol +
                "//" +
                window.location.hostname +
                "/logout" +
                staffLogin;
            const redirectPath = 'redirect_to='+encodeURIComponent(sessionStorage['appmode']);
            gotoUrl += (!staffLogin) ? '?'+redirectPath : '&'+redirectPath;
            window.location = gotoUrl;
        } else if (options.user && this.props.kioskHomeUrl) {
            window.location =
                this.props.kioskHomeUrl +
                "iframe/?iURL=" +
                encodeURIComponent(
                    setPasswordUrl
                );
        } else if (options.user) {
            window.location =
            setPasswordUrl
        }
    },

    render: function() {
        var content = this.props.content,
            users = [],
            centreName,
            multipleUsers = false,
            logoutButton,
            contentBlock,
            leadUser,
            i,
            x;

        if (this.props.currentCentre) {
            centreName = this.props.currentCentre.info.name;
        }

        if (this.props.isBooking) {
            for (var u = 0; u < this.props.users.length; u++) {
                if (!this.props.users[u].guardian) {
                    contentBlock = (
                        <Booking
                            bookingParams={this.props.bookingParams}
                            centreName={centreName}
                            content={content}
                            dispatch={this.props.dispatch}
                            passwordContent={this.props.passwordContent}
                            reset={this.reset}
                            user={this.props.users[u]}
                            guardianUser={this.props.users.find(u => u.guardian)}
                        />
                    );
                }
            }
        } else {
            if (this.props.users.length >= 2) {
                multipleUsers = true;
            }

            leadUser = this.props.users.find(function(u) {
                return u.lead;
            });

            for (i = 0; i < this.props.users.length; i++) {
                if (!this.props.users[i].guardian) {
                    users.push(
                        <ConfirmUser
                            centreName={centreName}
                            centre={
                                this.props.currentCentre
                                    ? this.props.currentCentre
                                    : null
                            }
                            content={content}
                            expanded={this.state.expanded[i]}
                            index={i}
                            leadUser={leadUser}
                            key={"u_" + i}
                            onShowMore={this._handleMoreInfoClick.bind(null, i)}
                            user={this.props.users[i]}
                            reset={this.reset}
                            multipleUsers={multipleUsers}
                            isStaffMember={this.props.isStaffMember}
                        />
                    );
                }
            }

            logoutButton = (
                <button
                    className="button__return"
                    onClick={this.reset.bind(null, { logout: true })}
                >
                    Logout and return to start
                </button>
            );

            contentBlock = <div>{users}</div>;
        }

        return (
            <div className="page confirm-page">
                <div className="container">
                    {logoutButton}
                    {contentBlock}
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    let users = state.selections.users.objects;
    if (sessionStorage.getItem('users:objects') && (!users || !users.length)) {
        users = JSON.parse(sessionStorage.getItem('users:objects'));
        setTimeout(() => sessionStorage.removeItem('users:objects'), 3000);
    }
    let currentCentre = state.centreFinder.selected;
    if (sessionStorage.getItem('centre:selected') && !currentCentre) {
        currentCentre = JSON.parse(sessionStorage.getItem('centre:selected'));
        setTimeout(() => sessionStorage.removeItem('centre:selected'), 3000);
    }

    return {
        kioskHomeUrl: state.app.kioskHomeUrl,
        bookingParams: state.booking.urlParams,
        content: state.app.content.confirmPage,
        currentCentre,
        discounts: state.discounts,
        isBooking: state.booking.isBooking,
        isStaffMember: !!state.staffLogin.staffId,
        lessons: state.lessons,
        loggedInUser: state.user.user,
        passwordContent: state.app.content.passwordForm,
        pricing: state.selections.pricing,
        selections: state.selections,
        staffId: state.staffLogin.staffId,
        stageStep: state.stages.showStep,
        taggingFired: state.payments.taggingFired,
        users
    };
}

module.exports = connect(mapStateToProps)(ConfirmPage);
