import React from "react";

export class Legend extends React.Component {
    render() {
        return (
            <div className="site-banding-panel__help">
                { this.props.children }
            </div>
        );
    }
}