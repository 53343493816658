import ButtonSelect from '../common/buttonSelect';

const React = require('react');

function ButtonExtras(props) {
    const {
        error = '',
        onDeselect,
        onSelect,
        textActive = 'Selected',
        textInactive = 'Select',
        ...rest
    } = props;

    const content = props.checked ? textActive : textInactive;
    const className = ((props.className || '') + (!props.error ? '' : ` input--attention`)).trim();

    return (
        <ButtonSelect {...rest} className={className} onClick={handleClick} title={error}>
            {content}
        </ButtonSelect>
    );

    function handleClick(e) {
        const { target } = e;
        const { value } = target;
        if (props.disabled || value === undefined) {
            return;
        }
        return value ? onSelect(e) : onDeselect(e);
    }
}

ButtonExtras.propTypes = {
    onDeselect: React.PropTypes.func,
    onSelect: React.PropTypes.func,
};

export default ButtonExtras;
