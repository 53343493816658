var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../../actions'),
    history         = require('../../../utils/history'),

    DesktopStages   = require('./desktop'),
    MobileStages    = require('./mobile'),

    Stages;

Stages = React.createClass({
    componentWillMount: function componentWillMount() {
        this.props.dispatch(actions.stages.showStepFromPath(history.location.pathname));
    },

    componentWillUpdate: function componentWillUpdate() {
        this.props.dispatch(actions.stages.showStepFromPath(history.location.pathname));
    },

    // This is needed to only allow navigation to stages previously visited.
    _handleStageClick: function _handleStageClick(e) {

        var step = e.currentTarget.getAttribute('data-step'),
            path = e.currentTarget.getAttribute('data-path');

        e.preventDefault();

        if (parseInt(step, 10) <= this.props.currentStep &&
            path !== history.location.pathname) {

            this.props.dispatch(actions.stages.showStep(parseInt(step, 10)));
        }
    },

    _next: function _next() {
        this.props.dispatch(actions.stages.next());
    },

    _back: function _back() {
        this.props.dispatch(actions.stages.back());
    },

    render: function() {
        if ((['bp-m', '"bp-m"', 'bp-l', '"bp-l"', 'bp-xl', '"bp-xl"', 'bp-xxl', '"bp-xxl"'].indexOf(this.props.breakpoint) !== -1)) {
            if(this.props.lessons.typesList || this.props.lessons.typeId) {
                return <MobileStages next={ this._next } back={ this._back } classes='stages-band--lessons' />;
            } else {
                return <DesktopStages handleStageClick={ this._handleStageClick } />;
            }
        } else {
            return <MobileStages next={ this._next } back={ this._back } />;
        }
    }
});

function mapStateToProps(state) {
    return {
        breakpoint: state.app.breakpoint,
        currentStep: state.stages.currentStep,
        routing: state.routing,
        lessons: state.lessons
    };
}

module.exports = connect(mapStateToProps)(Stages);
