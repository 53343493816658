var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/header'),
    Component       = require('../components/stage1'),
    actions     = require('../actions');

module.exports = [{

    path: /^((\/?|\/memberships)(\/)?(\?.*)?$)/i,
    stage: true,

    handler: function (params, query) {
        
        return {
            header: <Header showStages={ true } />,
            component: <Component query={ query } />
        };
    }
}];
