const React = require('react');

const defaultClassNameRoot = 'button';
const defaultClassNameBase = `${defaultClassNameRoot}__select`;

function ButtonSelect(props) {
    const {
        checked = false,
        children = '',
        className = '',
        classNameBase = defaultClassNameBase,
        classNameCloseBase = `${props.classNameBase || defaultClassNameBase}-close`,
        classNameContentBase = `${props.classNameBase || defaultClassNameBase}-content`,
        classNameRoot = defaultClassNameRoot,
        disabled = false,
        hidden = false,
        name = '',
        icon = '×',
        value = '',
        ...rest
    } = props;

    const classNameWrap = `${classNameBase} ${classNameBase}--${!disabled ? '' : 'dis'}abled ${classNameBase}--${checked ? '' : 'un'}checked${!className ? '' : ` ${className}`}${!hidden ? '' : ' hide'}`;
    const classNameContent = `${classNameBase}-content ${classNameRoot} ${classNameRoot}--${checked ? 'success' : 'active'}${!disabled ? '' : ` ${classNameRoot}--disabled`}`;
    const classNameClose = `${classNameCloseBase} ${classNameRoot} ${classNameRoot}--${(checked && !disabled) ? 'white ' : 'transparent in'}active`;

    return !children ? null : (
        <div {...{ ...rest, className: classNameWrap }}>
            <button {...{ className: classNameContent, hidden, name, value }}>
                {children}
            </button>
            <button {...{ className: classNameClose, hidden, name, value: '' }}>
                <span className={`${classNameCloseBase}-icon ${classNameCloseBase}-space`}>
                    {icon}
                </span>
                <span className={`${classNameCloseBase}-icon ${classNameCloseBase}-display`}>
                    {icon}
                </span>
            </button>
        </div>
    );
}

export default ButtonSelect;
