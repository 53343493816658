const { redirectToPayment } = require('../../utils/redirects');

var React               = require('react'),
    connect             = require('react-redux').connect,
    StickyContainer     = require('react-sticky').StickyContainer,

    actions             = require('../../actions'),
    viewUtils           = require('../../utils/viewUtils'),

    PartnerLogo     = require('./partnerLogo'),
    CentreInfo          = require('./centreInfo'),
    Discounts           = require('./discounts'),
    Help                = require('./help'),
    Links               = require('./links'),
    PriceBreakdown      = require('./priceBreakdown'),
    Pricing             = require('./pricing'),
    UserDiscountPromo   = require('./userDiscountPromo'),

    SalesLines          = require('./salesLines'),
    Testimonials        = require('./testimonials'),

    Button              = require('../common/button'),

    UserModel       = require('../../models/user'),

    Sidebar;

Sidebar = React.createClass({
    displayName: 'Sidebar',

    getInitialState: function() {
        return {
            discountDetails: null,
            hasFee: null,
        };
    },

    handleDiscountDetails: function(props) {
        var user,
            subscription;

        for (var i = 0; i < props.users.objects.length; i++) {
            user = props.users.objects[i];

            subscription = user.availableSubscriptions.find(function(s) {
                return s.duration === props.duration
                && s.durationType === props.durationType
                || s.durationType === 1
                && s.durationType === props.durationType;
            });

            if (!subscription) {
                continue;
            }
        }

        if (subscription) {
            this.setState({
                discountDetails: {
                    discountDesc: subscription.discountDesc || '',
                    promotionCodeApplied: !!subscription.promotionCodeApplied || subscription.discountDesc === 'Corporate'
                }
            });
        }
    },

    componentWillMount: function() {
        this.handleDiscountDetails(this.props);
    },

    componentDidMount: function() {
        this.processPricingData(this.props);
    },

    componentWillReceiveProps: function(nextProps) {
        var durationHasChanged = nextProps.duration !== this.props.duration;
        var discountHasChanged = (nextProps.discount !== this.props.discount);
        var stepHasChanged = (nextProps.stageStep !== this.props.stageStep);

        if (durationHasChanged || discountHasChanged || stepHasChanged) {
            this.handleDiscountDetails(nextProps);
        }

        this.processPricingData(nextProps);
    },

    processPricingData: function(props) {
        const {duration, durationType, dispatch, pricing} = props;

        if (pricing && pricing.periods) {
            if (durationType === 0) {
                if (duration === 1 ) {
                    if (pricing.periods[0] && pricing.periods[0][1]) {
                        this.monthlyCost = pricing.periods[0][1].total;
                        this.oneOffCosts = pricing.periods[0][1].fees.total;
                    }

                    if (pricing.periods[0] && pricing.periods[0][12]) {
                        this.oneOffCosts += pricing.periods[0][12].extras.total;
                    }
                } else if (duration === 12 ) {
                    if(pricing.periods[0] && pricing.periods[0][1]) {
                        this.monthlyCost = pricing.periods[0][1].extras.total;
                    }
                    if (pricing.periods[0] && pricing.periods[0][12]) {
                        this.oneOffCosts = pricing.periods[0][12].total + pricing.periods[0][12].fees.total;
                    }
                }

                dispatch(actions.selections.setTotalPrice({
                    monthly: this.monthlyCost,
                    oneOff: this.oneOffCosts
                }));
                this.setState({ hasFee: this.monthlyCost || this.oneOffCosts });
            } else if (durationType === 1) {
                // short time membership
                this.monthlyCost = 0;
                this.oneOffCosts = 0;

                for (var index in pricing.periods[durationType]) {
                    this.oneOffCosts += pricing.periods[durationType][index].fees.total
                        + pricing.periods[durationType][index].total
                        + pricing.periods[durationType][index].extras.total;
                }

                if (pricing.periods[0] && pricing.periods[0][12]) {
                    this.oneOffCosts += pricing.periods[0][12].total;
                    this.monthlyCost += pricing.periods[0][12].fees.total;
                }

                dispatch(actions.selections.setTotalPrice({
                    monthly: this.monthlyCost,
                    oneOff: this.oneOffCosts
                }));
                this.setState({ hasFee: this.monthlyCost || this.oneOffCosts });
            }
        }
    },

    _onChangeDuration: function _onChangeDuration(duration) {
        if (this.props.duration == duration) {
            return;
        }
        this.props.dispatch(actions.selections.checkExtras({
            duration,
            success: () => this.props.dispatch(actions.selections.changeDuration(duration))
        }));
    },

    _toggleSidebar: function _toggleSidebar() {
        document.querySelector('html').classList.toggle('noscroll');
        document.querySelector('body').classList.toggle('noscroll');
        this.props.dispatch(actions.app.toggleSidebar());
    },

    _showDiscountModal: function _showDiscountModal(key, e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal(key));
    },

    _removeDiscount: function _removeDiscount(e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal('confirmRemoveDiscount'));
    },

    _toggleDiscountDetails: function _toggleDiscountDetails(e) {
        e.preventDefault();

        var elt = document.querySelector('.sidebar-discounts__details');

        this.props.dispatch(actions.discounts.expandDiscount(!this.props.discountExpanded));
        elt && viewUtils.furl(elt);
    },

    _togglePriceBreakdownDetails: function _togglePriceBreakdownDetails(e) {
        e.preventDefault();

        var elt = document.querySelector('.price-breakdown__details');

        this.props.dispatch(actions.app.expandPriceBreakDown(!this.props.priceBreakdownExpanded));
        elt && viewUtils.furl(elt);
    },

    _next: function _next() {
        var self = this,
            currentStage = this.props.stageFlow[this.props.stageStep],
            props = this.props;

        // TODO: ALL THOSE BLOCKS ARE DUPLICATED FROM THE COMPONENTS THEMSELVES. UPDATE THERE IF YOU UPDATE HERE!
        if (this.props.isRenewals) {
            this.props.dispatch(actions.selections.renewSelections(function (response) {

                this.props.dispatch(actions.app.showLoader(true));
                window.location = response.paymentUrl;

            }.bind(this)));
        } else if (0 === currentStage) {
            this.props.dispatch(actions.memberships.freeze());
            this.props.dispatch(actions.stages.next());

        } else if (1 === currentStage) {

            this.props.dispatch(actions.personalDetails.unfreezeAll());
            this.props.dispatch(actions.stages.next());

        } else if (3 === currentStage) {

            this._validateUsers(this.props.users)
                .then(function (result) {
                    if (result.valid) {
                        if (0 === this.props.durationType && 12 === this.props.duration && !this.props.pricing.hasMonthlyFees) {
                            this.props.dispatch(actions.selections.postSelections(function () {

                                var paymentTypeAnnual = 'Paid Annual';
                                var freeAccountFound;
                                var subscriptionCount = 0;

                                for (var z = 0; z < this.props.users.objects.length; z++) {
                                    var user = this.props.users.objects[z];

                                    if (user.type === 'freeprofile') {
                                        freeAccountFound = true;
                                    } else {
                                        subscriptionCount++;
                                    }

                                    // loop over and count each users extras
                                    for (var key in this.props.users.objects[z].extras) {   // eslint-disable-line no-unused-vars
                                        subscriptionCount++;
                                    }
                                }

                                if (freeAccountFound) {
                                    paymentTypeAnnual = 'Paid Annual & Free Membership';
                                }

                                redirectToPayment(this.props);
                            }.bind(this)));

                        } else {
                            return this.props.dispatch(actions.bankDetails.validateForms(function() {
                                props.dispatch(actions.app.showLoader(true));

                                this.props.dispatch(actions.selections.postSelections(function () {
                                    var paymentTypeMonthly = 'Paid Monthly';
                                    var freeAccountFound;
                                    var subscriptionCount = 0;
                                    var promises = [];

                                    for (var z = 0; z < this.props.users.objects.length; z++) {
                                        var user = this.props.users.objects[z];

                                        if (user.type === 'freeprofile') {
                                            freeAccountFound = true;
                                        } else {
                                            subscriptionCount++;
                                        }

                                        // loop over and count each users extras
                                        for (var key in user.extras) {   // eslint-disable-line no-unused-vars
                                            subscriptionCount++;
                                        }

                                        //transfer lessons booking from Member 0 to user
                                        if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                                            for(var l in user.lesson.selectedLessons) {
                                                var lesson = user.lesson.selectedLessons[l];

                                                if(lesson.bookingRef && !user.lesson.bookingAttached) {
                                                    promises.push(actions.lessons.attachBooking(user, z, lesson.bookingRef, l));
                                                }
                                            }
                                        }
                                    }

                                    self.props.dispatch(actions.app.showLoader(true));
                                    self.props.dispatch(actions.lessons.attachCallError(null, null));

                                    //if user has lessons bookings, transfer bookings
                                    Promise.all(promises)
                                        .then(function (results) {
                                            var success = true,
                                                error,
                                                code;

                                            props.dispatch(actions.app.showLoader(false));

                                            for(var r = 0; r < results.length; r++) {
                                                error = results[r].error;
                                                code = results[r].code;

                                                if(error) {
                                                    success = false;
                                                    break;
                                                } else {
                                                    var lesson = {};
                                                    lesson.selectedLessons = {};
                                                    lesson.selectedLessons[results[r].lesson] = {
                                                        bookingRef: results[r].booking,
                                                        bookingAttached: true
                                                    };

                                                    var data = {
                                                        lesson: lesson
                                                    };

                                                    self.props.dispatch(actions.selections.changeData(results[r].userId, data));
                                                }
                                            }

                                            //continue if all bookings are successfully transferred
                                            if(success) {
                                                //refresh timer for lesson bookings if in lessons journey
                                                if(self.state.lessons && self.state.lessons.inView) {
                                                    self.props.dispatch(actions.lessons.refreshTimer('payment'));
                                                }

                                                if (freeAccountFound) {
                                                    paymentTypeMonthly = 'Paid Monthly & Free Membership';
                                                }

                                                self.props.dispatch(actions.app.showLoader(false));

                                                self.props.dispatch(actions.stages.next());
                                            } else if(error) {
                                                self.props.dispatch(actions.app.showLoader(false));
                                                self.props.dispatch(actions.lessons.attachCallError(error, code));
                                            }
                                        });
                                }.bind(this)));
                            }.bind(this)));
                        }
                    } else {

                        this.setState({
                            validationErrors: result.validationErrors,
                            valid: false
                        });

                        if (result.validationErrors) {
                            window.requestAnimationFrame(function() {
                                var invalidFields = document.querySelectorAll('.input--invalid');
                                scrollToComponent(invalidFields[0], {
                                    align: 'top',
                                    offset: -100
                                });
                            });
                        }

                        this.props.dispatch(actions.selections.validating(false));
                    }
                }.bind(this));

        } else {
            this.props.dispatch(actions.stages.next());
        }
    },

    _validateUsers: function _validateUsers(users) {
        var promises = [],
            valid = true,
            isStaff = this.props.staffId ? true : false,
            errors = [],
            i;

        for (i = 0; i < users.length; i++) {
            if (1 === this.props.duration || (12 === this.props.duration && (Object.keys(users[i].extras).length))) {
                if (users[i].lead || !users[i].useLeadBankDetails) {
                    if(users[i].type.toLowerCase() !== 'freeprofile' || (users[i].typeDesc.toLowerCase() === 'freeprofile' && Object.keys(users[i].extras).length)) {
                        promises.push(UserModel.validateBankDetails(users[i], null, isStaff));
                    }
                }
            }
        }

        return Promise.all(promises)
            .then(function (results) {
                for (i = 0; i < results.length; i++) {
                    if (!results[i].valid) {
                        errors[i] = results[i].errors,
                        valid = false;

                    } else {
                        errors[i] = {};
                    }
                }

                return {
                    validationErrors: errors,
                    valid: valid
                };
            });
    },

    render: function() {
        const { props } = this;
        const { preselectedSubs, duration, durationType } = props;

        // Check the user's available subscription for any preselected sub and set the duration.
        if (this.props.users.objects[0] && this.props.users.objects[0].availableSubscriptions) {
            var selectedSub = this.props.users.objects[0].availableSubscriptions.find(sub => sub.id === preselectedSubs);

            console.debug([this.props.users.objects, duration, durationType, selectedSub]);

            if (selectedSub && (selectedSub.duration !== duration || selectedSub.durationType !== durationType)) {
                // Change the duration.
                this.props.dispatch(actions.selections.changeDuration(selectedSub.duration, selectedSub.durationType));

                // // We've done the job of selecting the sub, clear out any preselected subs.
                // this.props.dispatch(actions.selections.preselectSubs(''));
                // this.props.dispatch(actions.selections.considerPreselectedSubscriptionsOnly(false));
            }
        }

        var content = this.props.content.general,
            isBooking = this.props.isBooking ? this.props.isBooking : false,
            pricingPeriodUnavailable,
            isRenewals = this.props.isRenewals ? this.props.isRenewals : false,
            isLessons = this.props.lessons && this.props.lessons.inView ? true : false,
            currentStage = this.props.stageFlow[this.props.stageStep],
            promoCodeActive = (this.props.discount && this.props.discount.details) ? true : false,
            sidebarClass = '',
            buttonDisabled,
            pricingBlock,
            actionButton,
            buttonProps,
            centreInfo,
            expanders,
            ddNeeded,
            btnText,
            links;

        if (currentStage === 1) {
            actionButton = <div className='sidebar__cta-container'><Button className='button button--active sidebar__next-button' onClick={ this._next }><span className='sidebar__next-button-next'>{ this.props.content.general.next }</span><span className='sidebar__next-button-text'>{ content.buttonMoreAboutYou }</span></Button></div>;
        }

        if(!isBooking) {
            if(isRenewals) {
                centreInfo = <CentreInfo centre={ this.props.centre } content={ this.props.content.centreInfo } />;

                pricingBlock = <div className='sidebar__secure-payment'>
                    <span className='sidebar__secure-payment-title'>{ this.props.content.securePayment.title }</span>
                    <p className='sidebar__secure-payment-text'>{ this.props.content.securePayment.text }</p>
                </div>;

                btnText = content.buttonMakePayment;
                buttonDisabled = true;

                if(this.props.renewalSubs) {
                    if(this.props.renewalSubs.length) {
                        buttonDisabled = false;
                    } else {
                        buttonDisabled = true;
                    }
                }

                buttonProps = {
                    className: 'button sidebar__next-button' + (buttonDisabled ? ' button--disabled' : ' button--active'),
                    disabled: buttonDisabled ? true : false,
                    onClick: this._next
                };

                actionButton = <div className='sidebar__cta-container'><Button { ...buttonProps }><span className='sidebar__next-button-next'>{ this.props.content.general.next }</span><span className='sidebar__next-button-text'>{ btnText }</span></Button></div>;

            } else if ((this.props.deeplinkAddons && currentStage !== 2) || currentStage !== 3) {

                centreInfo = <CentreInfo centre={ this.props.centre } content={ this.props.content.centreInfo } />;

                if (this.props.deeplinkAddons || (currentStage !== 0 && currentStage !== 5 && currentStage !== 6 && currentStage !== 7)) {
                    expanders = <div className='sidebar-expanders'>
                        <PriceBreakdown centre={ this.props.centre }
                            content={ this.props.content.priceBreakdown }
                            duration={ this.props.duration }
                            durationType={ this.props.durationType }
                            expanded={ this.props.priceBreakdownExpanded }
                            isLessons={ isLessons }
                            price={ this.props.pricing }
                            toggleDetails={ this._togglePriceBreakdownDetails }
                            users={ this.props.users }
                        />
                        <Discounts centre={ this.props.centre }
                            discount={ this.props.discount }
                            details={ this.state.discountDetails }
                            content={ this.props.content.discounts }
                            expanded={ this.props.discountExpanded }
                            removeDiscount={ this._removeDiscount }
                            showModal={ this._showDiscountModal }
                            toggleDetails={ this._toggleDiscountDetails }
                        />
                    </div>;
                }

                pricingBlock = (
                    <div>
                        <UserDiscountPromo />
                        <Pricing
                            breakpoint={ this.props.breakpoint }
                            centre={ this.props.centre }
                            content={ this.props.content }
                            changeDuration={ this._onChangeDuration }
                            currentStep={ currentStage }
                            deeplinkAddons={ this.props.deeplinkAddons }
                            duration={ this.props.duration }
                            durationType={ this.props.durationType }
                            isLessons={ isLessons }
                            membershipsSelected={ this.props.membershipsSelected }
                            usersHaveSelections={ this.props.allUsersHaveSelections }
                            users={ this.props.users }
                            pricing={ this.props.pricing }
                        />
                        { expanders }
                    </div>
                );

                if(currentStage !== 0 && !isLessons) {
                    links = <Links
                        allUsersHaveSelections={ this.props.allUsersHaveSelections }
                        centre={ this.props.centre }
                        content={ this.props.content.discounts }
                        discount={ this.props.discount }
                        showModal={ this._showDiscountModal }
                        removeDiscount={ this._removeDiscount }
                    />;
                }

            } else if (currentStage === 3) {

                centreInfo = <CentreInfo centre={ this.props.centre } content={ this.props.content.centreInfo } />;

                if (this.props.pricing.hasMonthlyFees) {
                    pricingBlock = (
                        <div className='sidebar__secure-payment'>
                            <span className='sidebar__secure-payment-title'>{ this.props.content.securePayment.title }</span>
                            <p className='sidebar__secure-payment-text'>{ this.props.content.securePayment.text }</p>
                        </div>
                    );
                }

                ddNeeded = this.props.durationType === 0
                    && this.props.duration === 1
                    || this.props.duration === 12
                    && this.props.pricing.periods[this.props.durationType][1]
                    && Object.keys(this.props.pricing.periods[this.props.durationType][1].extras.items).length;

                pricingPeriodUnavailable = (!this.props.pricing.periods || !this.props.pricing.periods[this.props.durationType] || !this.props.pricing.periods[this.props.durationType][this.props.d]);

                buttonProps = {
                    className: 'button sidebar__next-button' + (buttonDisabled ? ' button--disabled' : ' button--active'),
                    disabled: buttonDisabled ? true : false,
                    onClick: this._next
                };

                if (ddNeeded) {
                    btnText = content.buttonConfirmDD;
                    buttonDisabled = pricingPeriodUnavailable ||
                    this.props.bankDetails.validating ||
                    !this.props.bankDetailsAllValid ||
                    this.props.bankDetails.validationInProgress ||
                    promoCodeActive &&
                    !Object.values(this.props.promoTerms).every(x=> x.valid);
                } else {
                    btnText = this.state.hasFee ? content.buttonMakePayment : content.buttonConfirmFreeMembership;
                    buttonDisabled = promoCodeActive ? this.props.promoTerms &&
                    !!Object.values(this.props.promoTerms).every(x=> x.valid) : false;
                }

                actionButton = <div className='sidebar__cta-container'><Button { ...buttonProps }><span className='sidebar__next-button-next'>{ this.props.content.general.next }</span><span className='sidebar__next-button-text'>{ btnText }</span></Button></div>;

                if(!isLessons && this.props.durationType === 0) {
                    links = <Links
                        allUsersHaveSelections={ this.props.allUsersHaveSelections }
                        centre={ this.props.centre }
                        content={ this.props.content.discounts }
                        discount={ this.props.discount }
                        showModal={ this._showDiscountModal }
                        removeDiscount={ this._removeDiscount }
                    />;
                }
            }
        } else {
            centreInfo = <CentreInfo centre={ this.props.centre } content={ this.props.content.centreInfo } />;
        }

        if (viewUtils.checkIE()) {
            sidebarClass = 'sidebar--ie';
        }

        if (
            this.props.preselectedPartner
            && this.props.pricing
            && this.props.pricing.periods
            && Object.keys(this.props.pricing.periods).length === 0
            ) {
            pricingBlock = null;
        }

        return (<StickyContainer style={{background:'#1b2326'}}>
            <div className={'sidebar ' + sidebarClass}>
                <div className="sidebar__inner">
                    <div className='sidebar__header'>
                        <PartnerLogo />
                        { this.props.showSidebar ? <Button className='sidebar__toggle' onClick={ this._toggleSidebar }><span></span></Button> : null }
                        <h1 className='sidebar__title--mobile'>{ content.mobileTitle }</h1>
                        <h1 className='sidebar__title--desktop'>{ content.title }</h1>
                    </div>
                    <div className="sidebar__wrapper">
                        <div className='sidebar__content'>
                            { centreInfo }
                            { pricingBlock }
                            { actionButton }

                            { links }

                            <Help />
                            <SalesLines isRenewals={ isRenewals } isLessons={ isLessons } />
                            <Testimonials isRenewals={ isRenewals } isLessons={ isLessons } />
                        </div>
                    </div>
                </div>
            </div>
        </StickyContainer>);
    }

});

function mapStateToProps(state) {
    return {
        allUsersHaveSelections: state.selections.allUsersHaveSelections,
        bankDetails: state.bankDetails,
        bankDetailsAllValid: state.selections.bankDetailsAllValid,
        breakpoint: state.app.breakpoint,
        centre: state.centreFinder.selected,
        content: state.app.content.sidebar,
        considerPreselectedSubscriptionsOnly: state.selections.considerPreselectedSubscriptionsOnly,
        duration: state.selections.duration,
        durationType: state.selections.durationType,
        deeplinkAddons: state.selections.deeplinkAddons,
        discount: state.discounts.discount,
        discountExpanded: state.discounts.expanded,
        extras: state.selections.extras,
        lessons: state.lessons,
        isBooking: state.booking.isBooking,
        membershipsSelected: state.memberships.selected,
        preselectedPartner: state.selections.preselectedPartner,
        preselectedSubs: state.selections.preselectedSubs,
        priceBreakdownExpanded: state.app.priceBreakdownExpanded,
        pricing: state.selections.pricing,
        promoTerms: state.selections.promoTerms,
        renewalSubs: state.selections.renewalSubs,
        showSidebar: state.app.showSidebar,
        staffId: state.staffLogin.staffId,
        stageFlow: state.stages.flow,
        stageStep: state.stages.showStep,
        users: state.selections.users,
        settings: state.app.settings
    };
}

module.exports = connect(mapStateToProps)(Sidebar);
