var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    CentreResetModal;

CentreResetModal = React.createClass({
    _onClose: function _onClose(e) {
        e.preventDefault();

        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    _onConfirm: function _onConfirm(e) {
        e.preventDefault();
        
        // Hard redirect (useful for external URIs)
        if (this.props.modalConfiguration.hasOwnProperty('redirectTo')) {
            return window.location = this.props.modalConfiguration.redirectTo;
        }

        // reset selectionns and personal details
        this.props.dispatch(actions.selections.wipeout());
        this.props.dispatch(actions.personalDetails.wipeout());
        this.props.dispatch(actions.bankDetails.wipeout());

        this.props.dispatch(actions.lessons.wipeout());

        // reset centre selection
        this.props.dispatch(actions.centreFinder.resetSelection());
        // reset memberships and facilities
        this.props.dispatch(actions.memberships.unfreeze());
        this.props.dispatch(actions.memberships.resetSelection());
        this.props.dispatch(actions.memberships.hideSpecialPanel());

        if(!this.props.isBooking) {
            this.props.dispatch(actions.stages.goHome());
        }

        // Hide reset centre selection confirmation modal
        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    render: function render() {
        var content = this.props.content;

        return <Modal title={ content.title } id={ this.props.id } className='modal--confirm modal--confirm-centre-reset'>
            <p dangerouslySetInnerHTML={ {__html: content.desc } }></p>
            <div className='modal__buttons'>
                <Button className='button button--greyed-out' onClick={ this._onClose }>{ content.buttonCancel }</Button>
                <Button className='button button--primed' onClick={ this._onConfirm }>{ content.buttonYes }</Button>
            </div>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.centreReset,
        modalConfiguration: state.app.showModals.confirmCentreReset.options || {},
        isBooking: state.booking.isBooking,
        lessons: state.lessons
    };
}

module.exports = connect(mapStateToProps)(CentreResetModal);
