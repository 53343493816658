const { confirmRoutes } = require('../utils/confirmPage');

var toRegExp = require("path-to-regexp"),
    // Order here is important as it will use the first match in the router.
    routes = [
        require("./renewals"),
        require("./addOns"),
        require("./confirm"),
        require("./directDebit"),
        require("./membership"),
        require("./shortMembership"),
        require("./bookingProfile"),
        require("./gymProfile"),
        require("./personalDetails"),
        require("./selections"),
        require("./payments"),
        require("./lessons"),
        require("./setLevel"),
        require("./selectLesson"),
        require("./inCentrePayment"),
        confirmRoutes
    ],
    expandedRoutes = {
        appRoutes: [],
        stageRoutes: [],
        otherRoutes: [],
        error404: require("./error404")
    },
    expandedRoute,
    routePaths,
    pattern,
    route,
    keys,
    i,
    j;

for (i = 0; i < routes.length; i++) {
    routePaths = routes[i];

    for (j = 0; j < routePaths.length; j++) {
        route = routePaths[j];

        keys = [];
        pattern = route.pattern ? route.pattern : toRegExp(route.path, keys);

        expandedRoute = {
            keys: keys,
            handler: route.handler,
            path: route.path,
            pattern: pattern
        };

        if (route.stage) {
            expandedRoutes.stageRoutes.push(expandedRoute);
        } else {
            expandedRoutes.otherRoutes.push(expandedRoute);
        }

        expandedRoutes.appRoutes.push(expandedRoute);
    }
}

module.exports = expandedRoutes;
