var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/header'),
    Component       = require('../components/errors/404');

module.exports = [{

    path: '/error',

    handler: function () {
        return {
            header: <Header />,
            component: <Component />
        };
    }
}];
