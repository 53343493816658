var actionTypes = require('./actionTypes.js'),
    appActions = require('../actions/app.js'),
    httpFetch = require('../utils/httpFetch'),
    history = require('../utils/history'),
    config = require('../config');

module.exports = exports = {
    collapseResult: function collapseResult() {
        return {
            type: actionTypes.DIRECT_DEBIT_PAGE.TOGGLE_EXPANDED_USER,
            key: null
        };
    },

    expandResult: function expandResult(key) {
        return {
            type: actionTypes.DIRECT_DEBIT_PAGE.TOGGLE_EXPANDED_USER,
            key: key
        };
    },

    checkPaid: function checkPaid() {
        return async function (dispatch, getState) {
            const state = getState();
            const members = (state.selections.membersResponse || {}).members || {};

            const memberIds = Object.values(members)
                .reduce((sum = [], item = {}) => [...sum, (item.id || 0)], [])
                .filter((id, i, self) => id && self.indexOf(id) === i).sort();
            const salesInvoiceIds = Object.values(members)
                .reduce((sum = [], item = {}) => [...sum, ...Object.values(item.salesInvoiceIds || {})], [])
                .filter((id, i, self) => self.indexOf(id) === i).sort();

            if (
                !memberIds.length ||
                !salesInvoiceIds.length
            ) {
                throw new Error('Store error');
            }

            dispatch(appActions.showLoader(true));

            const responseMap = await getResponseMap(members);

            const paidSalesInvoiceIds = getInvoiceIds(responseMap, 'PaidSalesInvoices');

            const payableSalesInvoiceIds = getInvoiceIds(responseMap, 'PayableSalesInvoices');

            const allPaid = salesInvoiceIds.every((id = 0) => paidSalesInvoiceIds.includes(id));

            const unpaidSalesInvoiceIds = salesInvoiceIds.filter((id = 0) => {
                return !paidSalesInvoiceIds.includes(id);
            });

            // if this is not empty, you probably need to start over (30 minute timeout / cleanup)
            const missingSalesInvoiceIds = salesInvoiceIds.filter((id = 0) => {
                return !paidSalesInvoiceIds.includes(id) && !payableSalesInvoiceIds.includes(id);
            });

            // an invoice id has 3 states, paid (true), payable (false) and missing (null)
            const summary = salesInvoiceIds.reduce((sum = {}, id = 0) => {
                const paid = paidSalesInvoiceIds.includes(id);
                const payable = payableSalesInvoiceIds.includes(id);
                sum[id] = paid || (payable ? false : null);
                return sum;
            }, {});

            if (!allPaid) {
                dispatch(exports.paymentNotFound(unpaidSalesInvoiceIds, missingSalesInvoiceIds));
            }
            if (allPaid) {
                dispatch(appActions.hideModal('completePaymentAtTill'));
                dispatch(appActions.hideModal('paymentNotFound'));

                history.push('/payment/status/success');
            }
            dispatch(appActions.showLoader(false));
            return summary;
        }

        function getInvoiceIds(responseMap, kind = '') {
            return Object.values(responseMap).reduce((sum = [], item = {}) => {
                const section = item[kind];
                if (!section) {
                    console.debug(`Section is missing: ${kind}`);
                    return sum;
                }
                if (!(section || {}).SalesInvoice) {
                    console.debug('Sales invoice(s) is missing');
                    return sum;
                }
                const salesInvoices = Array.isArray(section.SalesInvoice) ? section.SalesInvoice : [section.SalesInvoice];
                if (!salesInvoices.length) {
                    return sum;
                }
                const salesInvoicesIds = salesInvoices.reduce((sumIn = [], itemIn = {}) => [...sumIn, (itemIn.ID || 0)], []);
                return [...sum, ...salesInvoicesIds];
            }, [])
                .filter((num, i, self) => num && self.indexOf(num) === i).sort();
        }

        async function getResponseMap(members) {
            const map = {};
            for (const member of members) {
                if (!member) continue;
                const response = await getInvoiceResponse(member);
                map[member.id] = response;
            }
            return map;
        }

        function getInvoiceResponse(member) {
            if (!(member || {}).invoiceUrl) {
                return null;
            }
            const url = member.invoiceUrl;
            return httpFetch.fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
        }
    },

    completePaymentAtTill: function completePaymentAtTill() {
        return function (dispatch) {
            dispatch(appActions.showModal('completePaymentAtTill'));
        };
    },

    paymentCancelConfirm: function paymentCancelConfirm() {
        return function (dispatch) {
            dispatch(appActions.showModal('paymentCancelConfirm'));
        };
    },

    paymentFound: function paymentFound() {
        return function (dispatch) {
            dispatch(appActions.hideModal('paymentNotFound'));

            dispatch(appActions.showModal('paymentFound'));
        };
    },

    paymentNotFound: function paymentNotFound(invoiceIds, missingInvoiceIds = []) {
        return function (dispatch) {
            dispatch(appActions.hideModal('completePaymentAtTill'));

            dispatch(appActions.showModal('paymentNotFound', {
                invoiceIds: invoiceIds.join(', '),
                missingInvoiceIds: missingInvoiceIds.join(', ')
            }));
        };
    }
};
