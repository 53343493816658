var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Content         = require('./content'),
    Sidebar         = require('../sidebar'),

    GymProfileIndex;

GymProfileIndex = React.createClass({
    displayName: 'GymProfileIndex',

    componentDidMount: function componentDidMount() {
        this.props.dispatch(actions.booking.setGymProfile());
    },

    render: function() {
        return (<div className='page'>
                    <Content />
                    <Sidebar />
                </div>);
    }
});

module.exports = connect()(GymProfileIndex);
