var React       = require('react'),

    utils       = require('../../utils/'),
    viewUtils   = require('../../utils/viewUtils'),

    AdvanceNotice;

AdvanceNotice = React.createClass({
    render: function() {
        // To render advance notice, there must be a change.
        // If we don't have totals, or only one time period (current one)
        //  then we simply can't render the notice.
        if (!this.props.totals || !Object.keys(this.props.totals).length ) {
            return null;
        }

        var content = this.props.content,
            totals = this.props.totals,
            keys = Object.keys(totals).sort(),

            currentDebit = totals[keys[0]] * 100,

            pricing = this.props.pricing,
            pricePeriods = pricing && pricing.periods && pricing.periods[0] && pricing.periods[0][1].ranges,
            debits = [],

            lastRegularDebitAmount,
            debitTemplate,
            debitAmount,
            debitDate,
            amount,
            i;

        if (pricePeriods) {
            for (i = 0; i < keys.length; i++) {
                if (pricing.periods[0][1].ranges[keys[i]]) {
                    lastRegularDebitAmount = pricing.periods[0][1].ranges[keys[i]];

                } else if (!lastRegularDebitAmount) {
                    continue;
                }

                amount = lastRegularDebitAmount.total;

                debitAmount = amount + totals[keys[i]] * 100;
                debitDate = viewUtils.formatDate(keys[i], '/');

                if (!i) {
                    debitTemplate = content.nextDebit;

                } else if (i === keys.length - 1) {
                    debitTemplate = content.regularDebit;

                } else {
                    debitTemplate = content.debitOn;
                }

                debits.push(<p key={ 'debit_' + i } dangerouslySetInnerHTML={ {__html: debitTemplate.replace('{amount}', '<span class="advance-notice__em">' + utils.formatPrice(debitAmount) + '</span>').replace('{date}', debitDate) } }></p>);
            }
        }

        return (<div className='advance-notice'>
            <h2 className='advance-notice__title'>{ content.title }</h2>
            <div className='advance-notice__col'>
                <p className='advance-notice__em' dangerouslySetInnerHTML={ {__html: content.noticeOfChangeWithUs } }></p>
                <p className='advance-notice__em' dangerouslySetInnerHTML={ {__html: content.currentDebit.replace('{amount}', utils.formatPrice(currentDebit)) } }></p>
            </div>
            <div className='advance-notice__col'>
                <p className='advance-notice__em'>{ content.thisWillChange }</p>
                { debits }
            </div>
        </div>);
    }
});

AdvanceNotice.propTypes = {
    content: React.PropTypes.object,
    pricing: React.PropTypes.object
};

module.exports = AdvanceNotice;
