var React = require('react');

const classNameBase = 'ea-tooltip';
const classNameOpen = 'open';
const classNameClosed = 'closed';
const hideDelaySeconds = 1;

const tooltip = {
    displayName: 'Tooltip',
    getInitialState() {
        return {
            active: null,
            hover: false
        };
    },
    componentWillUnmount() {
        const active = this.state.active;
        if (!active) return;
        active.parentNode.removeChild(active);
    },
    render() {
        const { children = '', icon = '?' } = this.props;
        if (!children) return null;
        return (
            <span className={classNameBase} onMouseEnter={this.setEnter} onMouseLeave={this.setLeave}>
                <span className={`${classNameBase}_content ${classNameClosed}`}>{children}</span>
                <span className={`${classNameBase}_icon`}>{icon}</span>
            </span>
        );
    },
    setOpen(input = null) {
        const active = input || this.state.active;
        if (!active) return;
        active.classList.add(classNameOpen);
        active.classList.remove(classNameClosed);
    },
    setClosed() {
        const { active, hover } = this.state;
        if (!active || hover) return;
        active.classList.add(classNameClosed);
        active.classList.remove(classNameOpen);
    },
    setEnter(e) {
        const { currentTarget } = e;
        const active = this.state.active || currentTarget.children[0];
        const rect = currentTarget.getBoundingClientRect();
        const rectActive = active.getBoundingClientRect();
        const posX = window.scrollX + rect.left + (rect.width / 2);
        const posY = window.scrollY + rect.top - (rect.height / 2) - rectActive.height;
        active.style.left = `${posX}px`;
        active.style.top = `${posY}px`;
        active.style.width = `${rectActive.width}px`;
        this.setOpen(active);
        const stateNew = {
            ...this.state,
            active,
            hover: true
        };
        if (!this.state.active) {
            document.body.appendChild(active);
        }
        this.setState(stateNew);
    },
    setLeave(e) {
        const stateNew = { ...this.state, hover: false };
        this.setState(stateNew);
        setTimeout(this.setClosed, 1000 * hideDelaySeconds);
    }
};

export const Tooltip = React.createClass(tooltip);

export default Tooltip;
