const { virtualPageTitle } = require( "../../../../common/google/analytics/lessons" );
const { castToUndefinedIfEmpty, products, hasSelections } = require( "../../../../common/google/analytics/products" );
const { push } = require( "../../common/google/analytics/datalayer" );

window.$ = window.jQuery = require("jquery");
var React = require("react"),
    Draggable = require("react-draggable"),
    connect = require("react-redux").connect,
    EventListener = require("react-event-listener").default,
    moment = require("moment"),
    Faqs = require("../../faqs"),
    config = require("../../../config"),
    actions = require("../../../actions"),
    Button = require("../../common/button"),
    Input = require("../../common/forms/input"),
    viewUtils = require("../../../utils/viewUtils"),
    utils = require("../../../utils/"),
    Schedule = require("../schedule"),
    scrollToComponent = require("react-scroll-to-component"),
    TimetableIndex;

TimetableIndex = React.createClass({
    getInitialState() {
        return {
            availablityLoader: false,
            day: 0,
            emptyDay: false,
            scrollPosition: 0,
            scrollTabPosition: 0,
            scrollerWidth: 80,
            showSelect: false,
            timetable: this.props.lessons.timetable || null,
            taggingFired: false
        };
    },

    componentWillMount() {
        var self = this;

        // Registering resize event on window.
        this.handleResizeDebounced = window.debounce(function() {
            self.handleResize();
        }, 200);

        // Calling _handleResize once to set the current
        //  breakpoint for components to use at app load.
        this.handleResize();

        //fetch the timetable and prices if not already fetched
        if (
            !this.props.lessons.timetable ||
            !this.props.lessons.timetable.times
        ) {
            this.props.dispatch(
                actions.lessons.fetchTimetable(
                    this.props.lessons.typeId,
                    this.props.centre.info.site_id,
                    this.props.lessons.timetableToggle
                )
            );
            this.props.dispatch(
                actions.lessons.fetchCentreTypes(this.props.centre.info.site_id)
            );

            //if we dont have the lesson category, fetch it and set the type
            if (!this.props.lessons.category) {
                this.props.dispatch(
                    actions.lessons.setType(this.props.lessons.typeId)
                );
            }
        }
    },

    componentDidMount() {
        $(".timetable__content").on("scroll touchmove", this.handleScroll);

        scrollToComponent(document.getElementById("timetable"), {
            align: "top",
            offset: -100
        });

        if (!this.state.taggingFired) {
            push({
                event: "virtual pageview",
                virtualPagePath: "/virtual/lessons/timetable/",
                virtualPageTitle: "Join - Everyone Active – Lessons - Timetable",
                products: this.props.products
            });
            this.setState({
                taggingFired: true
            });
        }
    },

    componentWillReceiveProps(newProps) {
        if (this.props !== newProps) {
            if (this.props.breakpoint !== newProps.breakpoint) {
                return;
            }

            if (
                newProps.lessons.timetable &&
                newProps.lessons.timetable.times
            ) {
                //set today as active day on timetable
                var timetable = newProps.lessons.timetable.times,
                    today = moment(window.now).day(),
                    isEmpty = Object.keys(timetable[today]).length === 0,
                    scrollerWidth = 80,
                    contentWidth;

                this.setState({
                    day: today,
                    emptyDay: isEmpty,
                    scrollerWidth: scrollerWidth,
                    timetable: newProps.lessons.timetable,
                    availablityLoader: false
                });
            }
        }
    },

    componentDidUpdate(prevProps, prevState) {
        if (this.state.showSelect !== prevState.showSelect) {
            scrollToComponent(document.getElementById("lesson_select"), {
                align: "top",
                offset: -150
            });
        }
    },

    handleResize: function() {
        var levels = this.state.timetable && this.state.timetable.levels;

        if (!levels || Object.keys(levels).length > 1) {
            if (
                [
                    "bp-m",
                    '"bp-m"',
                    "bp-l",
                    '"bp-l"',
                    "bp-xl",
                    '"bp-xl"',
                    "bp-xxl",
                    '"bp-xxl"'
                ].indexOf(this.props.breakpoint) === -1
            ) {
                $(".timetable").width($(window).width());
            } else {
                $(".timetable").width("100%");
            }
        }
    },

    buildTimetable: function() {
        if (this.state.timetable && !this.state.availablityLoader) {
            var isMobile =
                    [
                        "bp-m",
                        '"bp-m"',
                        "bp-l",
                        '"bp-l"',
                        "bp-xl",
                        '"bp-xl"',
                        "bp-xxl",
                        '"bp-xxl"'
                    ].indexOf(this.props.breakpoint) === -1,
                currentDay = this.state.day,
                daysOfWeek = this.props.general.dayNamesLong,
                hours = this.state.timetable.hours[currentDay],
                day = this.state.timetable.times[currentDay],
                levels = this.state.timetable.levels,
                staffLogin = !!this.props.staffId,
                content = this.props.content,
                timetableContent = [],
                timetableStages = [],
                timetableTimes = [],
                scrollerContent = [],
                headerContent = [],
                scrollingClass = "",
                columnAmount = 0,
                hoursArray = [],
                scrollRows = [],
                earliestTime = 30,
                latestTime = 0,
                skipArray = [],
                headerBlock,
                emptyBlock,
                rows = [],
                hasHours = Object.keys(hours).length !== 0,
                hasTimes = Object.keys(day).length !== 0;

            for (var i = 0; i < daysOfWeek.length; i++) {
                headerContent.push(
                    <li
                        key={"days-" + i}
                        className={this.state.day === i ? "active" : ""}
                        onClick={this.changeDay.bind(null, i)}
                    >
                        {daysOfWeek[i]}
                    </li>
                );
            }

            if (hasHours) {
                var firstHour = isMobile ? 8 : parseInt(hours[0].split(":")[0]);
                var lastHour = isMobile
                    ? 22
                    : parseInt(hours[hours.length - 1].split(":")[0]);

                //check to see if there are any big gaps of time
                if (!isMobile) {
                    for (var t = 0; t < hours.length; t++) {
                        if (hours[t + 1]) {
                            var hour = parseInt(hours[t].split(":")[0]),
                                nextHour = parseInt(hours[t + 1].split(":")[0]),
                                gap = nextHour - hour;

                            if (gap >= 3) {
                                for (
                                    var empty = hour + 2;
                                    empty < nextHour - 1;
                                    empty++
                                ) {
                                    skipArray.push(empty);
                                }
                            }
                        }
                    }
                }

                for (var time = firstHour; time < lastHour + 1; time++) {
                    var nextIsEmpty = false;

                    earliestTime = earliestTime <= time ? earliestTime : time;
                    latestTime = latestTime >= time ? latestTime : time + 2;

                    if (skipArray.indexOf(time) === -1) {
                        columnAmount = columnAmount + 2;
                        hoursArray.push(time + ":00", time + ":30");

                        if (skipArray.indexOf(time + 1) !== -1) {
                            nextIsEmpty = true;
                        }

                        timetableTimes.push(
                            <div
                                className="timetable__column"
                                key={"time-" + currentDay + time}
                            >
                                <div className="timetable__head">
                                    {time + ":00"}
                                </div>
                            </div>
                        );

                        timetableTimes.push(
                            <div
                                className={
                                    "timetable__column " +
                                    (nextIsEmpty
                                        ? "timetable__column--next-empty"
                                        : "")
                                }
                                key={"time-0" + currentDay + time}
                            >
                                <div className="timetable__head">
                                    {time + ":30"}
                                </div>
                            </div>
                        );

                        if (time === lastHour) {
                            timetableTimes.push(
                                <div
                                    className="timetable__column timetable__column--end"
                                    key="time-end"
                                >
                                    <div className="timetable__column-inner">
                                        <span>
                                            {content.timetable.endOfClasses}
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    }
                }
            }

            for (var level in levels) {
                var times = day[level],
                    color = "#" + levels[level].group_color,
                    lastTime = "",
                    labels = [],
                    scrollLabels = [],
                    visibility;

                if (hasTimes && times) {
                    for (var s = 0; s < times.length; s++) {
                        visibility = staffLogin
                            ? times[s]["filter_visibility"] === 0 ||
                              times[s]["filter_visibility"] === 1
                            : times[s]["filter_visibility"] === 0 ||
                              times[s]["filter_visibility"] === 2;
                        if (
                            times[s]["time"] &&
                            times[s]["time"] !== lastTime &&
                            times[s]["lesson_access"] !== 1 &&
                            visibility
                        ) {
                            var labelInfo = this.getLabelPosition(
                                    times[s],
                                    earliestTime,
                                    latestTime,
                                    skipArray
                                ),
                                labelInfoPer = this.getLabelPosition(
                                    times[s],
                                    earliestTime,
                                    latestTime,
                                    skipArray,
                                    "percent"
                                ),
                                sessionFull = times[s]["is_full"];

                            labels.push(
                                <span
                                    className={
                                        "timetable__label " +
                                        (sessionFull ? "full" : "")
                                    }
                                    key={"label-" + currentDay + level + s}
                                    style={{
                                        backgroundColor: sessionFull
                                            ? "#FFF"
                                            : color,
                                        border: sessionFull
                                            ? "1px solid " + color
                                            : "",
                                        color: color,
                                        left:
                                            Object.keys(levels).length > 1
                                                ? labelInfo.xPos
                                                : "",
                                        width: labelInfo.width
                                    }}
                                >
                                    {sessionFull ? "Full" : ""}
                                    <span className="timetable__label-time">
                                        {times[s]["time"]}
                                    </span>
                                </span>
                            );

                            scrollLabels.push(
                                <span
                                    className={
                                        "timetable__label " +
                                        (sessionFull ? "full" : "")
                                    }
                                    key={
                                        "label-scroll-" + currentDay + level + s
                                    }
                                    style={{
                                        backgroundColor: sessionFull
                                            ? "#FFF"
                                            : color,
                                        border: sessionFull
                                            ? "1px solid " + color
                                            : "",
                                        color: color,
                                        left: labelInfoPer.xPos,
                                        width: labelInfoPer.width
                                    }}
                                ></span>
                            );

                            lastTime = times[s]["time"];
                        }
                    }
                }

                rows.push(
                    <div
                        className="timetable__row"
                        key={"row-" + currentDay + level}
                    >
                        {labels}
                    </div>
                );

                scrollRows.push(
                    <div
                        className="timetable__row"
                        key={"row-scroll-" + currentDay + level}
                        style={{
                            height: 80 / Object.keys(levels).length + "%"
                        }}
                    >
                        {scrollLabels}
                    </div>
                );
            }

            emptyBlock = (
                <div className="timetable__empty">
                    <span>{content.timetable.noClasses}</span>
                </div>
            );

            timetableContent.push(
                <div
                    key={"day-" + currentDay}
                    className={
                        "timetable__day " +
                        (!hasTimes ? "timetable__day--empty" : "")
                    }
                >
                    {rows}
                    {!hasTimes && emptyBlock}
                </div>
            );

            scrollerContent.push(
                <div
                    key={"day-scroll-" + currentDay}
                    className="timetable__day"
                >
                    {scrollRows}
                </div>
            );

            for (level in levels) {
                if (
                    levels[level].filter_visibility &&
                    (levels[level].filter_visibility.includes(0) ||
                        (staffLogin &&
                            levels[level].filter_visibility.includes(1)) ||
                        (!staffLogin &&
                            levels[level].filter_visibility.includes(2)))
                ) {
                    timetableStages.push(
                        <div className="timetable__row" key={"level-" + level}>
                            <span
                                className="timetable__label"
                                style={{
                                    backgroundColor:
                                        "#" + levels[level]["group_color"]
                                }}
                            >
                                <span className="timetable__label--full">
                                    {levels[level]["group_name"]}
                                </span>
                                <span className="timetable__label--abbrev">
                                    {levels[level]["group_abbrev"]}
                                </span>
                            </span>
                        </div>
                    );
                }
            }

            if (this.state.scrollPosition >= 50) {
                scrollingClass = "timetable__body--scrolling";
            }

            //show tabs on desktop and dropdown on mobile
            if (
                [
                    "bp-m",
                    '"bp-m"',
                    "bp-l",
                    '"bp-l"',
                    "bp-xl",
                    '"bp-xl"',
                    "bp-xxl",
                    '"bp-xxl"'
                ].indexOf(this.props.breakpoint) !== -1
            ) {
                headerBlock = (
                    <ul className="timetable__header">{headerContent}</ul>
                );
            } else {
                headerBlock = (
                    <div className="timetable__slider">
                        <button
                            className="timetable__slider-arrow timetable__slider-prev"
                            onClick={this.changeDay.bind(
                                null,
                                this.state.day === 0 ? 6 : this.state.day - 1
                            )}
                        ></button>
                        <div>{daysOfWeek[this.state.day]}</div>
                        <button
                            className="timetable__slider-arrow timetable__slider-next"
                            onClick={this.changeDay.bind(
                                null,
                                this.state.day === 6 ? 0 : this.state.day + 1
                            )}
                        ></button>
                    </div>
                );
            }

            var width = $(window).width() * ((latestTime * 100) / 2200),
                lessonCount = this.getLessonCount();

            if (lessonCount > 0 && lessonCount < 10) {
                return (
                    <Schedule
                        lessons={this.props.lessons}
                        staffLogin={staffLogin}
                    ></Schedule>
                );
            } else if (Object.keys(levels).length > 1) {
                return (
                    <div className="timetable">
                        {headerBlock}
                        <div className="timetable__scroller-container">
                            <div className="timetable__scroller">
                                {scrollerContent}
                                <div
                                    className="timetable__scroller__tab-container"
                                    style={{
                                        width: width + "px",
                                        maxWidth: $(window).width()
                                    }}
                                >
                                    <Draggable
                                        axis="x"
                                        bounds="parent"
                                        handle=".timetable__scroller__tab"
                                        defaultPosition={{ x: 0, y: 0 }}
                                        onDrag={this.onScrollerDrag}
                                        onStart={this.onScrollerClick}
                                        onMouseDown={this.onScrollerClick}
                                    >
                                        <div
                                            className="timetable__scroller__tab"
                                            style={{
                                                width:
                                                    this.state.scrollerWidth +
                                                    "px"
                                            }}
                                        ></div>
                                    </Draggable>
                                </div>
                            </div>
                        </div>
                        <div className={"timetable__body " + scrollingClass}>
                            <div className="timetable__stages">
                                {timetableStages}
                            </div>
                            <div
                                className={
                                    "timetable__content " +
                                    (this.state.emptyDay
                                        ? "timetable__content--empty"
                                        : "")
                                }
                                onScroll={this.hideLabels}
                            >
                                <div className="timetable__background">
                                    {timetableTimes}
                                </div>
                                <div className="timetable__times">
                                    {timetableContent}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="timetable timetable--single">
                        {headerBlock}
                        <div className="timetable__body">
                            <div
                                className={
                                    "timetable__content " +
                                    (this.state.emptyDay
                                        ? "timetable__content--empty"
                                        : "")
                                }
                            >
                                <div className="timetable__times">
                                    {timetableContent}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className="loader__wrapper">
                    <ul className="loader__spinner">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            );
        }
    },

    changeDay: function(index) {
        var timetable = this.state.timetable.times,
            isEmpty = Object.keys(timetable[index]).length === 0;

        this.setState({
            day: index,
            emptyDay: isEmpty
        });
    },

    handleScroll: function() {
        //get percentage of scroll
        var scrollPercentage =
            (100 * $(".timetable__content")[0].scrollLeft) /
            ($(".timetable__content")[0].scrollWidth -
                $(".timetable__content")[0].clientWidth);

        //get width of container
        var scrollBorder =
            $(".timetable__scroller__tab-container").width() - 80;

        //get percentage in pixels compared to window width
        var scrollPos = ($(window).width() * scrollPercentage) / 100;

        //prevent the tab scrolling past 80 pixels from right of border
        if (scrollBorder <= scrollPos) {
            scrollPos = scrollBorder;
        }

        //set xPos of tab
        $(".timetable__scroller__tab").css(
            "transform",
            "translate(" + scrollPos + "px, 0px)"
        );

        this.setState({
            scrollPosition: $(".timetable__content").scrollLeft(),
            scrollTabPosition: scrollPos
        });
    },

    onScrollerClick: function() {
        $(".timetable__scroller__tab").css(
            "transform",
            "translate(" + this.state.scrollTabPosition + "px, 0px)"
        );
    },

    onScrollerDrag: function(e, data) {
        var scrollPercentage =
            (100 * data.x) / $(".timetable__scroller__tab-container").width();
        if ($(".timetable__scroller__tab-container").width() - 80 <= data.x) {
            scrollPercentage = 100;
        }
        var contentWidth =
            $(".timetable__content")[0].scrollWidth -
            $(".timetable__content")[0].clientWidth;

        $(".timetable__content").scrollLeft(
            (contentWidth * scrollPercentage) / 100
        );

        this.setState({
            scrollPosition: $(".timetable__content").scrollLeft(),
            scrollTabPosition: data.x
        });
    },

    getLabelPosition: function(session, earliest, latest, skipArray, type) {
        var sessionSplit = session["time"].split(":"),
            columnWidth = 72,
            columnAmount,
            earliestTime,
            latestTime,
            gapAmount,
            duration;

        //convert 24hr format time to hundreds eg. 16:30 -> 1650, 8:00 -> 800 etc.
        var sessionTime =
            parseInt(sessionSplit[0]) * 100 +
            (parseInt(sessionSplit[1]) / 60) * 100;

        if (type === "percent") {
            earliestTime = 800;
            latestTime = 2200;

            var fullWidth = (latestTime - earliestTime) / 50,
                difference = (sessionTime - earliestTime) / 50,
                xPos = (difference / fullWidth) * 100;

            duration =
                ((session["duration"] * 2) / (latestTime - earliestTime)) *
                    100 -
                1;

            return {
                xPos: xPos + "%",
                width: duration + "%"
            };
        } else {
            //subtract the earliest time from the class time, divide by 50 to get amount of columns and multiply by column width for x-pos
            earliestTime = parseInt(earliest) * 100;
            columnAmount = sessionTime - earliestTime;

            //check if time falls between any gaps
            //if it does set gapAmount to number of array elements the session time appears after
            for (var i = 1; i < skipArray.length; i++) {
                if (
                    parseInt(sessionSplit[0]) > skipArray[i - 1] &&
                    parseInt(sessionSplit[0]) < skipArray[i]
                ) {
                    gapAmount = i;
                }
            }

            if (!gapAmount) {
                //if time does not fall between any gaps, check if it falls after all the gaps
                //if it does, remove a column for each hour that is skipped
                if (
                    parseInt(sessionSplit[0]) > skipArray[skipArray.length - 1]
                ) {
                    columnAmount =
                        sessionTime - skipArray.length * 100 - earliestTime;
                }
            } else {
                columnAmount = sessionTime - gapAmount * 100 - earliestTime;
            }

            xPos = (columnAmount / 50) * columnWidth;

            //multiply duration by 2 to get width, calculate extra padding (12px per extra amount after 30mins)
            //30min session = 60px (60 + 0), 60min session = 132px (120 + 12), 90min session = 204px (180 + 24) etc.
            duration =
                session["duration"] * 2 + (session["duration"] / 30 - 1) * 12;

            return {
                xPos: xPos + "px",
                width: duration + "px"
            };
        }
    },

    goHome: function(e) {
        e.preventDefault();

        this.props.dispatch(actions.centreFinder.resetSelection());
        this.props.dispatch(actions.stages.goHome());
    },

    onLessonSelect: function onLessonSelect(value) {
        this.props.dispatch(actions.lessons.setType(value));
        this.props.dispatch(
            actions.lessons.fetchTimetable(
                value,
                this.props.centre.info.site_id,
                false
            )
        );

        this.setState({
            availablityLoader: true
        });
    },

    next: function next(e) {
        e.preventDefault();

        this.props.dispatch(actions.stages.next());
    },

    toggleAvailability: function() {
        this.props.dispatch(
            actions.lessons.fetchTimetable(
                this.props.lessons.typeId,
                this.props.centre.info.site_id,
                !this.props.lessons.timetableToggle
            )
        );

        this.setState({
            availablityLoader: true
        });
    },

    getLessonCount: function() {
        if (!this.props.lessons) {
            return null;
        }

        var timetable = this.props.lessons.timetable;

        var lessonsCount = timetable.times.reduce(function(count, dayObj) {
            Object.keys(dayObj).forEach(function(lessonType) {
                const typeLessons = dayObj[lessonType];

                count += typeLessons.length;
            });

            return count;
        }, 0);

        return lessonsCount;
    },

    render: function() {
        var content = this.props.content,
            lessons = this.props.lessons,
            noPrices =
                !lessons.prices ||
                !lessons.prices.prices ||
                lessons.prices.prices.length === 0,
            buttonProps = {
                className: "button " + (noPrices ? "button--disabled" : ""),
                onClick: this.next,
                disabled: !lessons.prices ? true : false
            },
            buttonText = content.main.buttonNext,
            prices = lessons.prices
                ? lessons.discountedUser
                    ? lessons.prices.discounts
                    : lessons.prices.prices
                : [],
            lessonType = utils.getLessonType(lessons, true),
            weekdays = this.props.general.weekdayNames,
            priceCount = utils.formatLessonPrice(lessons, weekdays),
            priceChildArray = [],
            priceJuniorArray = [],
            priceAdultArray = [],
            extraOptions,
            actionButton,
            lessonSelect,
            link,
            staffLogin = !!this.props.staffId;

        const allowForward = lessons && lessons.centreTypesList && lessons.centreTypesList.some(centreType => {
            return (
                centreType.filter_visibility &&
                (centreType.filter_visibility.includes(0) ||
                    (staffLogin && centreType.filter_visibility.includes(1)) ||
                    (!staffLogin && centreType.filter_visibility.includes(2)))
            );
        });

        actionButton = allowForward && (
            <Button {...buttonProps}>{buttonText}</Button>
        );

        var dayType = "",
            member,
            duration,
            amount;

        for (member in priceCount) {
            if (member.indexOf("Child") !== -1) {
                priceCount[member] = utils.sortObjectByPropertyName(
                    priceCount[member]
                ); //sort object so duration goes low to high

                for (duration in priceCount[member]) {
                    for (amount in priceCount[member][duration]) {
                        if (
                            Object.keys(priceCount[member][duration]).length > 1
                        ) {
                            dayType = priceCount[member][duration][amount];
                        } else {
                            dayType = "";
                        }
                        if (content.prices[member][duration]) {
                            priceChildArray.push(
                                <div
                                    className="stage-options__prices-level"
                                    key={"price_" + member + duration + amount}
                                >
                                    <span className="stage-options__prices-title">
                                        {content.prices[member][
                                            duration
                                        ].replace("{day}", dayType)}
                                        :
                                    </span>
                                    <span className="stage-options__prices-amount">
                                        {content.prices.amount.replace(
                                            "[amount]",
                                            amount
                                        )}
                                    </span>
                                </div>
                            );
                        }
                    }
                }
            } else if (member.indexOf("Junior") !== -1) {
                priceCount[member] = utils.sortObjectByPropertyName(
                    priceCount[member]
                ); //sort object so duration goes low to high

                for (duration in priceCount[member]) {
                    for (amount in priceCount[member][duration]) {
                        if (
                            Object.keys(priceCount[member][duration]).length > 1
                        ) {
                            dayType = priceCount[member][duration][amount];
                        } else {
                            dayType = "";
                        }

                        if (content.prices[member][duration]) {
                            priceJuniorArray.push(
                                <div
                                    className="stage-options__prices-level"
                                    key={"price_" + member + duration + amount}
                                >
                                    <span className="stage-options__prices-title">
                                        {content.prices[member][
                                            duration
                                        ].replace("{day}", dayType)}
                                        :
                                    </span>
                                    <span className="stage-options__prices-amount">
                                        {content.prices.amount.replace(
                                            "[amount]",
                                            amount
                                        )}
                                    </span>
                                </div>
                            );
                        }
                    }
                }
            } else if (member.indexOf("Adult") !== -1) {
                priceCount[member] = utils.sortObjectByPropertyName(
                    priceCount[member]
                ); //sort object so duration goes low to high

                for (duration in priceCount[member]) {
                    for (amount in priceCount[member][duration]) {
                        if (
                            Object.keys(priceCount[member][duration]).length > 1
                        ) {
                            dayType = priceCount[member][duration][amount];
                        } else {
                            dayType = "";
                        }
                        if (content.prices[member][duration]) {
                            priceAdultArray.push(
                                <div
                                    className="stage-options__prices-level"
                                    key={"price_" + member + duration + amount}
                                >
                                    <span className="stage-options__prices-title">
                                        {content.prices[member][
                                            duration
                                        ].replace("{day}", dayType)}
                                        :
                                    </span>
                                    <span className="stage-options__prices-amount">
                                        {content.prices.amount.replace(
                                            "[amount]",
                                            amount
                                        )}
                                    </span>
                                </div>
                            );
                        }
                    }
                }
            }
        }

        extraOptions = (
            <div className="stage-options">
                <div className="stage-options__prices">
                    {priceChildArray}
                    {priceJuniorArray}
                    {priceAdultArray}
                </div>

                {actionButton}

                {this.props.lessons.timetable && (
                    <div className="stage-options__text">
                        <h5>{content.timetable.switchLesson.title}</h5>

                        <p>
                            <a href="" className="link" onClick={this.goHome}>
                                Click here
                            </a>{" "}
                            {content.timetable.switchLesson.text}
                        </p>
                    </div>
                )}
            </div>
        );

        if (lessons.centreTypesList) {
            var listOptions = [
                {
                    value: null,
                    text: "Please select",
                    disabled: true
                }
            ];

            for (var i = 0; i < lessons.centreTypesList.length; i++) {
                if (
                    lessons.centreTypesList[i].filter_visibility &&
                    (lessons.centreTypesList[i].filter_visibility.includes(0) ||
                        (staffLogin &&
                            lessons.centreTypesList[
                                i
                            ].filter_visibility.includes(1)) ||
                        (!staffLogin &&
                            lessons.centreTypesList[
                                i
                            ].filter_visibility.includes(2)))
                ) {
                    listOptions.push({
                        value: lessons.centreTypesList[i].id,
                        text: viewUtils.capitalizeFirstLetter(
                            lessons.centreTypesList[i].name
                        )
                    });
                }
            }

            lessonSelect = (
                <Input
                    className="personal-details__input"
                    input={{
                        onChange: this.onLessonSelect,
                        required: true
                    }}
                    name={"lesson_select"}
                    type="select"
                    options={listOptions}
                    value={lessons && lessons.typeId}
                />
            );
        }

        if (utils.getLessonType(lessons) === "swimming") {
            link = (
                <a
                    className="timetable__title-link link--external"
                    target="_blank"
                    href={config.services.lessons.urls.stagesPage}
                >
                    {content.timetable.findOutMore}
                </a>
            );
        }

        return (
            <div id="timetable">
                <div className="timetable__container">
                    <div className="module-container">
                        <div className="timetable__title">
                            <h2>
                                {content.timetable.title.replace(
                                    "{lesson}",
                                    lessonType
                                )}
                            </h2>
                            {link}

                            {lessonSelect}

                            {/* <div
                                className={
                                    "view-toggle " +
                                    (this.state.availablityLoader
                                        ? "view-toggle--disabled"
                                        : "")
                                }
                            >
                                <span className="view-toggle__text">
                                    {content.timetable.availablityText}
                                </span>

                                <div className="view-toggle__switch">
                                    <input
                                        className="view-toggle__input"
                                        type="checkbox"
                                        id="view-toggle"
                                        onClick={this.toggleAvailability}
                                        checked={
                                            this.props.lessons.timetableToggle
                                        }
                                    />
                                    <label
                                        className="view-toggle__slider"
                                        htmlFor="view-toggle"
                                    ></label>
                                </div>
                            </div> */}
                        </div>

                        {this.buildTimetable()}
                    </div>

                    {extraOptions}
                </div>

                <Faqs />
                <EventListener
                    target="window"
                    onResize={this.handleResizeDebounced}
                />
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        breakpoint: state.app.breakpoint,
        centre: state.centreFinder.selected,
        content: state.app.content.lessons.discover,
        general: state.app.content.general,
        flow: state.stages.flow,
        lessons: state.lessons,
        staffId: state.staffLogin.staffId,

        // GA
        products: hasSelections(state) ? castToUndefinedIfEmpty(
            products( state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined )( virtualPageTitle( state, 'Membership' ) )( state )
        ) : [{
            id: undefined,
            name: virtualPageTitle(state, 'Membership'),
            brand: state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined,
        }]
    };
}

module.exports = connect(mapStateToProps)(TimetableIndex);
