var React           = require('react'),
    connect         = require('react-redux').connect,
    Autosuggest     = require('react-autosuggest'),

    SmartSelect;

SmartSelect = React.createClass({
    getInitialState: function() {
        return {
            suggestions: [],
            tags: this.props.value || [],
            value: ''
        };
    },

    componentWillMount: function componentWillMount() {
        if (!this.props.input.onChange) {
            this.props.input.onChange = function () {}; // no-op
        }

        this.originalOnChange = (function() { return this.props.input.onChange; }.bind(this))();

        this.props.input.onChange = this.onChangeInput;

        this.onChangeDebounced = window.debounce(function (value) {
            this.delayedOnChange.apply(this, [value]);
        }, 200);
    },

    originalOnChange: function () {},

    onChangeInput: function onChangeInput(event, data) {
        if (data.newValue !== undefined && data.newValue !== null) {
            this.setState({ 
                value: data.newValue
            });
        }
    },

    delayedOnChange: function delayedOnChange(value) {
        this.originalOnChange(value);
    },

    onRemoveTag: function onRemoveTag(index) {
        var tags = this.state.tags;

        tags.splice(index, 1);
        this.originalOnChange(tags);

        this.setState({ 
            tags: tags
        });
    },

    getSuggestions: function getSuggestions(value) {
        var inputValue = value.toLowerCase(),
            inputLength = inputValue.length,
            suggestions = this.props.suggestions;

        return inputLength === 0 ? []
            : suggestions.filter(function (suggestion) {
                var keep = suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

                return keep;
            });
    },

    // When suggestion is clicked, Autosuggest needs to populate the input field
    // based on the clicked suggestion.
    getSuggestionValue: function getSuggestionValue(suggestion) {
        return suggestion.name;
    },

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested: function onSuggestionsFetchRequested(data) {
        this.setState({
            suggestions: this.getSuggestions(data.value),
        });
    },

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsClearRequested: function onSuggestionsClearRequested() {
        this.setState({ 
            suggestions: []
        });
    },

    // This function is called when suggestion is selected with tap/click, or 'enter'
    onSuggestionSelected: function onSuggestionSelected(event, data) {
        var tags = this.state.tags;
        tags.push(data.suggestionValue);

        this.originalOnChange(tags);

        this.setState({ 
            suggestions: this.props.suggestions,
            tags: tags,
            value: ''
        });
    },

    renderSuggestion: function renderSuggestion(suggestion) {
        return (
            <span>{ suggestion.name }</span>
        );
    },

    render: function render() {
        var props = this.props,
            label = props.label || {},
            tags = this.state.tags,
            labelProps = {
                className: label.className || '',
                htmlFor: props.name
            },
            disabled = false,
            classes = [],
            resultClass = '',
            tagsItems = [],
            confirmedTags = 0,
            tagsList,
            labelText,
            labelElt,
            key;

        if (props.className) {
            classes = classes.concat(props.className.split(' '));
        }

        if(tags && tags.length) {
            for(var t = 0; t < tags.length; t++) {
                if(tags[t]) {
                    var tag = (tags[t].length > 30) ? tags[t].substr(0, 29) + '...' : tags[t];
                    confirmedTags++;

                    tagsItems.push(<span className='smart-select__tag' key={'tag_' + t}>
                        { tag }
                        <span className='smart-select__tag-name'>{ tags[t] }</span>
                        <span className='smart-select__tag-close' onClick={ this.onRemoveTag.bind(null, t) }>x</span>
                    </span>);
                }
            }

            if(confirmedTags >= this.props.hasMax) {
                disabled = true;
                classes.push('personal-details__input--disabled');
            }

            tagsList = <div className='smart-select__tags'>
                { tagsItems }
            </div>;
        }

        // Autosuggest will pass through all these props to the input field.
        var inputProps = {
                placeholder: props.input.placeholder,
                value: this.state.value,
                onChange: this.onChangeInput,
                disabled: disabled
            },
            theme = {
                container:            'autosuggest__container ' + resultClass,
                containerOpen:        'autosuggest__container--open',
                suggestionsContainer: 'autosuggest__suggestions-container',
                suggestionsList:      'autosuggest__suggestions-list autosuggest__suggestions-list--medical',
                suggestion:           'autosuggest__suggestion',
                suggestionFocused:    'autosuggest__suggestion--focused',
                sectionContainer:     'autosuggest__section-container',
                sectionTitle:         'autosuggest__section-title'
            };

        var autosuggest = (
            <Autosuggest
                suggestions={ this.state.suggestions }
                onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
                onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                onSuggestionSelected={ this.onSuggestionSelected }
                getSuggestionValue={ this.getSuggestionValue }
                renderSuggestion={ this.renderSuggestion }
                inputProps={ inputProps }
                theme={ theme } />
        );

        for (key in label) {
            if ('text' === key) {
                labelText = label[key];
            } else {
                if(!label[key] !== null && label[key] !== undefined) {
                    labelProps[key] = label[key];
                }
            }
        }

        labelElt = <label { ...labelProps }>{ labelText }</label>;

        return (<div className={ classes.join(' ') }>
            { labelElt }

            <div className='smart-select__container'>
                { tagsList }
                { autosuggest }
            </div>
        </div>);
    }
});

SmartSelect.propTypes = {
    className: React.PropTypes.string,
    id: React.PropTypes.string,
    inputFirst: React.PropTypes.bool,
    name: React.PropTypes.string,
    onChange: React.PropTypes.func,
    placeholder: React.PropTypes.string
};

module.exports = connect()(SmartSelect);
