var React           = require('react'),
    connect         = require('react-redux').connect,
    moment          = require('moment'),
    config          = require('../../config'),
    viewUtils       = require('../../utils/viewUtils'),

    LessonsTimeout;

LessonsTimeout = React.createClass({

    getInitialState: function getInitialState() {
        return {
            step: '--:--',
            timeoutId: null
        };
    },

    componentWillMount() {
        this.setTimer();
    },

    componentWillReceiveProps(nextProps) {
        this.clearTimer();
        this.setTimer();
    },

    componentWillUnmount() {
        this.clearTimer();
    },

    setTimer: function setTimer() {
        var self = this,
            timeoutId = setInterval(function() {
                var now = moment().valueOf(),
                    timeoutTime = self.props.lessons.timeoutTime,
                    difference = now - timeoutTime,
                    timeoutSeconds = self.props.lessons.paymentTimerActive ? config.app.paymentTimeout : config.app.lessonTimeout;

                var secondsSinceCountdownStarted = (timeoutSeconds * 1000) - Math.round(difference),
                    tempTime = moment.duration(secondsSinceCountdownStarted),
                    remaining = viewUtils.zeroPad(tempTime.minutes(), 2) + ':' + viewUtils.zeroPad(tempTime.seconds(), 2);

                if(secondsSinceCountdownStarted >= 0) {
                    self.setState({ step: remaining });
                } else {
                    self.setState({ step: '00:00' });
                }
            }, 1000);

        this.setState({ timeoutId: timeoutId });
    },

    clearTimer: function clearTimer() {
        if(this.state.timeoutId) {
            clearInterval(this.state.timeoutId);
            this.setState({ timeoutId: null });
        }
    },

    render: function() {
        if(!this.props.lessons.timeoutTime) {
            return null;
        }
        
        return (<div className='lessons__timeout'>
            <span className='form__error form__error--lessons'>
                { this.props.content.replace('[time]', this.state.step) }
            </span>
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.lessons.timer,
        lessons: state.lessons,
        started: state.app.started
    };
}

module.exports = connect(mapStateToProps)(LessonsTimeout);
