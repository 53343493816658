var React           = require('react'),
    connect         = require('react-redux').connect,

    Button          = require('../../common/button'),

    MobileStages;

MobileStages = React.createClass({

    getInitialState: function() {
        return {
            scrollPosition: 0
        };
    },

    handleScroll: function() {
        this.setState({
            scrollPosition: window.pageYOffset
        });
    },

    componentDidMount: function componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    },

    componentWillUnmount: function componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    },

    render: function render() {
        var content = this.props.content,
            currentFlowStep = this.props.flow[this.props.showStep],
            nextFlowStep = this.props.flow[this.props.showStep + 1],
            previousFlowStep = this.props.flow[this.props.showStep - 1],
            stageCount = 0,
            self = this,
            title = '',
            scrollingClass,
            currentStage,
            prevButton,
            nextButton,
            stg,
            i;

        // Counting the total stages to show in the nav bar.
        this.props.flow.map(function (s) {
            stg = self.props.steps[self.props.flow[s-1]];
            stg && stg.navBar && stageCount++;
        });

        // Find the current stage from the current step
        if (this.props.steps[currentFlowStep]) {
            currentStage = this.props.steps[currentFlowStep];

        // If not found, loop over all staged to attemtp and determine it.
        } else {
            for (i = 0; i < this.props.flow.length; i++) {
                if (i === this.props.showStep) {
                    currentStage = this.props.steps[currentFlowStep];
                    continue;
                }
            }
        }

        if(this.state.scrollPosition >= 50 && !this.props.classes) {
            scrollingClass = 'stages-band--hidden';
        } else {
            scrollingClass = '';
        }

        // If we don't have a stage at this point, use the first in the flow.
        if (!currentStage) {
            currentStage = this.props.steps[this.props.flow[0]];
        }

        if (nextFlowStep !== undefined && nextFlowStep !== null && this.props.currentStep > this.props.showStep) {
            nextButton = <Button className={'stage__button stage__button--next' } onClick={ this.props.next }></Button>;
        }

        if (previousFlowStep !== undefined && previousFlowStep !== null && this.props.showStep > 0) {
            prevButton = <Button className={'stage__button stage__button--previous' } onClick={ this.props.back }></Button>;
        }

        if (content && content['stage' + (currentFlowStep + 1) ] && content['stage' + (currentFlowStep + 1) ].title) {
            title = content['stage' + (currentFlowStep + 1) ].title;
        }

        return (
            <nav className={'stages-band stages-band--mobile ' + scrollingClass + ' ' + (this.props.classes || '')}>
                <div className={ 'stages stages--active-' + (self.props.showStep + 1) + ' stages--' + stageCount } >
                    <div key={'stages-' + i} className='stage stage--active'>
                        { prevButton }
                        <div>
                            <span className='stage__step stage__step--mobile'>{ this.props.showStep + 1 } of { stageCount } |</span>
                            <span className='stage__title'>{ title }</span>
                        </div>
                        { nextButton }
                    </div>
                </div>
            </nav>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.stages,
        currentStep: state.stages.currentStep,
        flow:  state.stages.flow,
        steps:  state.stages.steps,
        showStep: state.stages.showStep
    };
}

module.exports = connect(mapStateToProps)(MobileStages);
