var React           = require('react'),
    connect         = require('react-redux').connect,
    actions         = require('../actions'),
    utils           = require('../utils/'),
    viewUtils       = require('../utils/viewUtils'),
    Faqs;
const HIDE_FAQ_SCORING_BUTTONS = false;
const listExcludeUseful = [];

Faqs = React.createClass({
    displayName: 'Faqs',
    componentWillMount: function componentWillMount() {
        this.fetchFAQs();
    },

    getInitialState: function () {
        return {
            expanded: null,
            voted: 0
        };
    },

    componentDidUpdate: function (prevProps) {
        if (
            this.props.lessonTypeId !== prevProps.lessonTypeId ||
            this.props.preselectedPartner !== prevProps.preselectedPartner
        ) {
            this.fetchFAQs();
        }
    },

    fetchFAQs: function fetchFAQs(siteID) {
        var tags;
        const nextStep = (this.props.currentStep || 0) + 1;

        if(this.props.isRenewals) {
            tags = ['joinonline-renewals'];
        } else {
            if(this.props.lessons && this.props.lessons.inView) {
                var lessonType = utils.getLessonType(this.props.lessons, false, this.props.lessonTypeId);

                tags = ['lessons-' + nextStep];

                if(lessonType) {
                    tags.push(lessonType + '-' + nextStep);
                }
            } else {
                tags = ['joinonline-' + nextStep];
            }
        }

        if (siteID) {
            tags.push('site-' + siteID);
        }

        if ((this.props.preselectedPartner || {}).name) {
            tags.push(this.props.preselectedPartner.name + '-' + nextStep);
        }
        this.props.dispatch(actions.faqs.fetchFAQs(tags));
    },

    showAnswer: function showAnswer(index) {

        var oldElet = document.querySelector('.faqs__answer-' + this.state.expanded),
            elt = document.querySelector('.faqs__answer-' + index);

        if (this.state.expanded && index === this.state.expanded) {
            this.setState({ expanded: null });
            oldElet && viewUtils.furl(oldElet);
        } else{
            this.setState({ expanded: index });
            oldElet && viewUtils.furl(oldElet);
            elt && viewUtils.furl(elt);
        }
    },

    handleUsefulClick: function handleUsefulClick(e) {
        e.stopPropagation();
        const { target } = e;
        const { value } = target;
        const text = target.textContent.trim();

        return this.props.dispatch(actions.faqs.vote(value, text))
            .then(([result, error]) => {
                if (error) {
                    target.style.boxShadow = '0 0 5px 1px red';
                    return setTimeout(() => target.style.boxShadow = '', 5000);
                }
                this.setState({ ...this.state, voted: this.state.voted + 1 });
            });
    },

    render: function () {
        const { props = {} } = this;
        const { votes = {}, answerScoring = true } = props;
        const shouldHideFaqHelpful = !answerScoring || isFaqHelpfulExcluded();

        var content = this.props.content,
            results = [],
            expanded,
            html,
            faq,
            i;

        if (!this.props.results || !this.props.results.length) {
            return null;
        }

        for (i = 0; i < this.props.results.length; i++) {
            faq = this.props.results[i];
            html = faq.answer;
            expanded = this.state.expanded === (i+1) ? 'faqs__item--expanded' : '';

            let {
                info: {
                    'site_id': siteId = '',
                    'name': siteName = ''
                } = {}
            } = this.props.currentCentre || {};

            html = html.replace(/\{siteId\}/ig, siteId);
            html = html.replace(/\{siteName\}/ig, siteName);

            const id = faq.id;
            const vote = votes[id] || { id };

            results.push(<li className={'faqs__item ' + expanded } key={'faq_' + i} onClick={ this.showAnswer.bind(null, (i+1)) } >
                <div className='faqs__question'>{ faq.question }</div>
                <div className={'faqs__answer faqs__answer-' + (i+1)}>
                    <div className='inner'>
                        <div className='faqs__answer-text'>
                            <div className='faqs__answer-content' dangerouslySetInnerHTML={{ __html: html }} />
                            <FaqHelpful {...vote} content={content} onClick={this.handleUsefulClick} hidden={HIDE_FAQ_SCORING_BUTTONS || shouldHideFaqHelpful} />
                        </div>
                    </div>
                </div>
            </li>);
        }

        return (<div className='faqs' id='faqs'>
            <h2 className='content__title'>{ content.title }</h2>
            <ul className='faqs__list'>
                { results }
            </ul>
        </div>);
    }
});

function FaqHelpful(props = {}) {
    const { id = 0, choice = '', choices = [], complete, content = {}, hidden = false, onClick, time } = props;
    if (!choices.length && Object.values(content).length) {
        choices.push(content.yes);
        choices.push(content.no);
    }
    if (hidden || !choices.length) {
        return null;
    }
    const expired = checkExpire();
    const selected = !expired && complete && choice || '';
    return (
        <div className={`faqs__answer-helpful${choice ? ' disabled' : ''}`} disabled={!expired && choice}>
            <span className="mr-75">{content.findThisHelpful}</span>
            <span className="text-nowrap">
                {choices.map(item =>
                    <button
                        className={`faqs__helpful-choice button--subtle mr-75 ${selected === item ? '' : 'in'}active`}
                        type="button" value={id} onClick={onClick} key={item} disabled={selected}
                    >{item}</button>
                )}
            </span>
        </div>
    );

    function checkExpire() {
        const self = checkExpire;
        if (!time) return false;
        if ((self._memo || {})[time] !== undefined) {
            return self._memo[time];
        }
        const date = new Date(time);
        date.setMonth(date.getMonth() + 1);
        const result = (new Date) > date;
        if (!self._memo) {
            self._memo = {};
        }
        self._memo[time] = result;
        return result;
    }
}

function isFaqHelpfulExcluded() {
    const url = (window.location.pathname + window.location.search).toLowerCase();
    const found = listExcludeUseful.find(exclude => url.includes(exclude));
    return found || '';
}

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.faqs,
        results: state.faqs.results,
        votes: state.faqs.votes,
        answerScoring: state.faqs.answerScoring,
        lessons: state.lessons,
        lessonTypeId: state.lessons.typeId,
        currentStep: state.stages.showStep,
        currentCentre: state.centreFinder.selected,
        preselectedPartner: state.selections.preselectedPartner
    };
}

module.exports = connect(mapStateToProps)(Faqs);
