var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/header'),
    Stage4          = require('../components/stage4'),
    PaymentHandler  = require('../components/paymentHandler');

module.exports = [{
    //for lessons bookings only
    path: '/payment/status/success/:reservation',

    handler: function () {
        return {
            component: <PaymentHandler status={'success'} />
        };
    }
},{

    path: '/payment/status/check',
    handler: function () {
        const params = (new URL(location.href)).searchParams;
        if (params.get('success') === 'true') {
            return {
                component: <PaymentHandler status={'success'} />
            };
        }
        return {
            component: <PaymentHandler status={'failure'}/>
        };
    }
},{

    path: '/payment/status/success',

    handler: function () {
        return {
            component: <PaymentHandler status={'success'} />
        };
    }
},{

    path: '/payment/status/failure/:code?',

    handler: function () {
        return {
            component: <PaymentHandler status={'failure'}/>
        };
    }
},{

    path: '/payment',

    stage: true,

    handler: function () {
        return {
            header: <Header showStages={ true } />,
            component: <Stage4 />
        };
    }
}];
