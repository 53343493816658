var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/noStagesHeader'),
    Component       = require('../components/bookingProfile');

module.exports = [{

    path: '/booking-profile',

    handler: function (params, query) {

        return {
            header: <Header />,
            component: <Component />
        };
    }
}];
