/**
 * Determines if lessons is currently being viewed
 * @param {object} state Redux store object
 * @returns boolean
 */
const isLessons = state => state.lessons.inView;

/**
 * Extracts lesson name from state
 * @param {object} state Redux store object
 * @returns string
 */
const selectedLessonName = state => `Lessons - ${ state.lessons.lessonType }`;

/**
 * Extracts virtual page title from state depending on whether this is a lesson or not
 * @param {object} state Redux store object
 * @param {mixed} other Uses other value if this isn't a lesson
 * @returns string
 */
export const virtualPageTitle = (state, other) => isLessons(state) ? selectedLessonName(state) : other;