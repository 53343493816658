var actions         = require('../actions'),
    exports;

module.exports = exports = {
    setState: function setState(state) {
        exports.livechatReady = state.livechatReady;
        exports.showLivechat = state.showLivechat;
    },

    load: function load(options) {
        exports.dispatch = options.dispatch;
        exports.license = options.license;

        var lc = document.createElement('script'),
            s = document.getElementsByTagName('script')[0];

        // https://developers.livechatinc.com/javascript-api/#on-before-load
        window.LC_API.on_before_load = function() { // eslint-disable-line camelcase
            // Don't hide the chat window only if visitor
            //  is currently chatting with an agent
            if (! exports.showLivechat || window.LC_API.visitor_engaged() === false && !exports.livechatReady) {
                window.LC_API.hide_chat_window();
            }
        };

        // https://developers.livechatinc.com/javascript-api/#on-after-load
        window.LC_API.on_after_load = function () { // eslint-disable-line camelcase
            exports._onAfterLoad();

            if (window.LC_API.visitor_engaged()) {
                exports.minimize();
            }
        };

        // https://developers.livechatinc.com/javascript-api/#on-chat-window-opened
        window.LC_API.on_chat_window_opened = function() { // eslint-disable-line camelcase
            // This overrides the state temporarily because when a chat has ended
            //  and the customer clicks on "restart chat", the liveChat api
            //  stupidly calls again "onload" and it's lifecycle and that breaks
            //  the implementation using redux.
            exports.showLivechat = true;
            exports._onOpened();
        };

        // https://developers.livechatinc.com/javascript-api/#on-chat-window-minimized
        window.LC_API.on_chat_window_minimized = function () { // eslint-disable-line camelcase
            exports._onMinimized();
        };

        // https://developers.livechatinc.com/javascript-api/#on-chat-window-hidden
        window.LC_API.on_chat_window_hidden = function() { // eslint-disable-line camelcase
            exports._onHidden();
        };

        // https://developers.livechatinc.com/javascript-api/#on-chat-state-changed
        window.LC_API.on_chat_state_changed = function (data) { // eslint-disable-line camelcase
            exports._onStateChanged(data);
        };

        // https://developers.livechatinc.com/javascript-api/#on-chat-started
        window.LC_API.on_chat_started = function() { // eslint-disable-line camelcase
        };

        // https://developers.livechatinc.com/javascript-api/#on-chat-ended
        window.LC_API.on_chat_ended = function() { // eslint-disable-line camelcase
        };

        window.__lc.license = exports.license;
        window.__lc.params = exports._formatCustomParams(options.customVariables);

        lc.type = 'text/javascript';
        lc.async = true;
        lc.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';

        s.parentNode.insertBefore(lc, s);
    },

    _formatCustomParams: function _formatCustomParams(data) {
        var customVariables = [],
            key;

        for (key in data) {
            customVariables.push({ name: key, value: data[key] });
        }

        return customVariables;
    },

    hide: function hide() {
        window.LC_API.hide_chat_window();
    },

    show: function show() {
        window.LC_API.open_chat_window();
    },

    minimize: function minimize() {
        window.LC_API.minimize_chat_window();
    },

    setCustomerData: function (data) {
        window.LC_API.set_custom_variables(exports._formatCustomParams(data));
    },

    setCustomerDetails: function setCustomerDetails() {
        var customVariables = [
            { name: 'page', value: 'Store' },
            { name: 'user_id', value: '12345' }
        ];

        window.LC_API.set_custom_variables(customVariables);
    },

    _onAfterLoad: function _onAfterLoad() {
        exports._livechatReady();

        if (window.LC_API.agents_are_available()) {
            // your code here which will fire only
            // when your agents are available to chat
            exports.dispatch(actions.liveChat.online());
        }
    },

    _onStateChanged: function _onStateChanged(data) {
        // offline – chat agents are not available to chat.
        // online_for_chat – chat agents are available to chat. Visitor entering the chat will be connected to the agent immediately.
        // online_for_queue – chat agents are
        switch (data.state) {

            case 'offline':
                exports.dispatch(actions.liveChat.offline());
                break;

            case 'online_for_chat':
            case 'online_for_queue':
                exports.dispatch(actions.liveChat.online());

        }
    },

    // Instruct the react app the livechat is ready.
    _livechatReady: function _livechatReady() {
        exports.dispatch(actions.liveChat.ready());
    },

    // Instruct the react to close the livechat window.
    _onMinimized: function _onMinimized() {
        if (!window.LC_API.visitor_engaged()) {
            exports.dispatch(actions.liveChat.hide());
        }
    },

    // Instruct the react to close the livechat window.
    _online: function _online() {
        exports.dispatch(actions.liveChat.online());
    },

    // Instruct the react to close the livechat window and set correct state.
    _onHidden: function _onHidden() {
        exports.dispatch(actions.liveChat.hide());
    },

    // Instruct the react to show the livechat window and set correct state.
    _onOpened: function _onOpened() {
        exports.dispatch(actions.liveChat.show());
    }
};
