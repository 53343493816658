var React           = require('react'),
    Button;

Button = React.createClass({
    getInitialState: function getInitialState() {
        return { focus: false };
    },

    componentWillMount: function componentWillMount() {
        var selfProps = this.props,
            props = {},
            key;

        for (key in selfProps) {
            if ('onClick' === key) {
                this.originalOnClick = (function() { return selfProps.onClick; }.bind(this))();

            } else {
                props[key] = selfProps[key];
            }
        }

        this.props = props;
    },

    _handleOnClick: function _handleOnClick() {
        // place analytics call here

        this.originalOnClick && this.originalOnClick.apply(this, arguments);
    },

    _handleMouseDown: function _handleMouseDown() {
        if (!this.state.focus) {
            this.setState({ focus: true });
        }
    },

    _handleBlur: function _handleBlur() {
        if (this.state.focus) {
            this.setState({ focus: false });
        }
    },

    render: function () {
        var props = Object.assign({}, this.props);

        props.className = (props.className || '') + (this.state.focus ? ' focus' : '');

        return <button {...props} onMouseDown={this._handleMouseDown} onBlur={ this._handleBlur } onClick={ this._handleOnClick }>{ this.props.children }</button>;
    }
});

module.exports = Button;
