var actionTypes = require("../actions/actionTypes.js"),
    pricingModel = require("../models/pricing"),
    userModel = require("../models/user"),
    utils = require("../utils");

module.exports = function(state, action) {
    var baseUserObject = {
            bankDetails: {
                collections: null,
                errors: {},
                fields: {},
                paymentUrl: null,
                total: null,
                unique: false,
                validationInProgress: false,
                valid: false
            },
            promoCode: {
                code: null,
                isValid: null,
                errorMsg: null,
                redemptionId: null,
                redemptionConfirmed: null
            },
            canLead: false,
            extras: {},
            expiredSubscriptions: [],
            expiredShortUsers: [],
            facilities: {},
            fallbackAge: false,
            fromUserForm: false,
            hasMadeSelection: false,
            guardian: false,
            idInType: null,
            info: {},
            isDiscountApplied: null,
            isFresh: true,
            isFSFG: false,
            invalidForAgeRange: false,
            isLoggedInUser: false,
            lead: false,
            lesson: {},
            linkedMemberRestrictionIds: null,
            linkedMemberDiscount: null,
            activeSubscriptions: [],
            type: null,
            typeDesc: null,
            useLeadContact: false,
            useLeadEmmergencyDetails: false,
            useLeadBankDetails: false,
            reportingTransactionId: null,
        },
        defaultUsersState = {
            count: {},
            hasLead: false,
            objects: [],
            total: 0
        },
        defaultState = {
            allUsersHaveSelections: false,
            bankDetailsAllValid: false,
            clashedUsers: [],
            expiredShortUsers: [],
            content: window.appContent,
            // #9 only consider preselected subscriptions (standalone addons)
            considerPreselectedSubscriptionsOnly: false,
            currentSelectedStartDate: utils.getDateWithoutTimeString(
                new Date()
            ),
            deeplinkAddons: null,
            deeplinkGroups: [],
            defaultOptions: {},
            centreLessons: {},
            discount: null,
            duration: 1,
            durationType: 0,
            existingUsers: [],
            extras: {},
            fetchedUsers: [],
            filteredSubscriptions: {},
            loggedInUser: null,
            matchingUsers: [],
            membersResponse: null,
            maxStartDate: null,
            memberships: [],
            paymentUrl: null,
            postSelectionsError: null,
            postSelectionsErrorCode: null,
            preselectedUsers: null,
            preselectedSubs: null,
            preselectionSubProcessed: null,
            preselectedPartner: null,
            preselectedSubsByGroups: null,
            promo: null,
            promoTerms: {
                promoCodePref: false
            },
            staffPromo: null,
            userDiscountPromo: null,
            pricing: null,
            startDateOffset: null,
            startDate: null,
            startDateIsRenewal: false,
            subscriptions: {},
            totalPrice: {},
            users: Object.assign({}, defaultUsersState),
            validating: false
        },
        EXTRA_MEMBERSHIP_TYPES = [],
        filteredSubscriptions = {},
        newExtras,
        newState,
        usersObj,
        result,
        price,
        users,
        user,
        sub,
        key,
        id,
        i,
        j;

    state = state || defaultState;

    EXTRA_MEMBERSHIP_TYPES.push({
        comment: state.content.stage1.membershipSelection.freeProfileComment,
        desc: state.content.stage1.membershipSelection.freeProfileTitle,
        guardianDesc: state.content.stage1.membershipSelection.guardianTitle,
        id: "freeprofile",
        extraMemberSubs: ["NC1"],
        static: true
    });

    switch (action.type) {
        case actionTypes.DISCOUNTS.DISCOUNT_SELECTED:
            return Object.assign({}, state, {
                discount: {
                    ...action.discount,
                    isDeepLinkDiscount: action.isDeepLinkDiscount
                }
            });

        case actionTypes.DISCOUNTS.DISCOUNT_REMOVED:
            return Object.assign({}, state, {
                discount: null
            });

        // Select centre applies a default set of initial data based on the
        //  centre information received.
        // It does NOT merge new data with already existing one.
        case actionTypes.CENTRE_FINDER.CENTRE_SELECTED:
            var types = userModel.userTypesOrder,
                defaultOptions = {},
                centreLessons = {},
                subscriptions = {},
                memberships = [],
                membershipTypes,
                em;

            if (action.centre) {
                if (action.centre.membershipTypes) {
                    // TODO: ordering of membershipTypes by order attribute.
                    // At the moment they are done by userTypesOrder
                    membershipTypes = action.centre.membershipTypes.slice(); // array

                    for (i = 0; i < types.length; i++) {
                        for (j = 0; j < membershipTypes.length; j++) {
                            if (membershipTypes[j].id === types[i]) {
                                memberships.push(
                                    membershipTypes.splice(j, 1)[0]
                                );
                                break;
                            }
                        }
                    }

                    if (action.isLessons) {
                        memberships = membershipTypes;
                    } else {
                        // filter out membership type that have no matching subscriptions
                        memberships = membershipTypes.filter(type => {
                            return Object.values(
                                action.centre.subscriptions
                            ).some(x => x.tags.includes(type.id));
                        });
                    }
                }

                if (action.centre.subscriptions) {
                    if (state.deeplinkAddons) {
                        for (key in action.centre.subscriptions) {
                            if (
                                state.deeplinkAddons.length &&
                                state.deeplinkAddons.includes(
                                    action.centre.subscriptions[key].id
                                )
                            ) {
                                subscriptions[key] = JSON.parse(
                                    JSON.stringify(
                                        action.centre.subscriptions[key]
                                    )
                                );
                            }
                        }
                    } else {
                        // Cloning subscriptions here to be able to modify them directly
                        //  without modifying the original ones.
                        subscriptions = JSON.parse(
                            JSON.stringify(action.centre.subscriptions)
                        );
                    }
                }

                if (action.centre.filteredFacilities) {
                    filteredSubscriptions = Object.assign(
                        {},
                        action.centre.filteredFacilities
                    );
                }

                if (action.centre.defaultOptions) {
                    defaultOptions = Object.assign(
                        {},
                        action.centre.defaultOptions
                    );
                }

                if (action.centre.filteredLessons) {
                    centreLessons = Object.assign(
                        {},
                        action.centre.filteredLessons
                    );
                }
            }

            for (i = 0; i < EXTRA_MEMBERSHIP_TYPES.length; i++) {
                em = EXTRA_MEMBERSHIP_TYPES[i];

                if (
                    !memberships.find(function(m) {
                        return m.id === em.id;
                    })
                ) {
                    memberships.push(em);
                }
            }

            // When a new site info is fetched, this ensures
            //  that prices are re-calculated properly with new
            //  extras applied to all users.
            if (
                state.users &&
                state.users.objects &&
                state.users.objects.length
            ) {
                for (i = 0; i < state.users.objects.length; i++) {
                    user = state.users.objects[i];

                    for (key in user.extras) {
                        for (id in user.extras[key]) {
                            if (subscriptions[id]) {
                                user.extras[key][id] = subscriptions[id];
                            }
                        }
                    }
                }
            }

            // When a new site info is fetched, this ensures
            //  that prices are re-calculated properly with new
            //  subscriptions applied to all users.

            // This WILL re-filter all subscriptions and attempt
            //  to select the same ones that have been selected
            //  at the point where the new site info json
            //  was fetched.
            result = pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: state.fetchedUsers,
                users: state.users || {},
                filteredSubscriptions: filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration
            });

            price = pricingModel.getPrice({
                subscriptions: subscriptions,
                users: state.users || defaultUsersState,
                extras: state.extras || {},
                d: state.duration,
                dt: state.durationType
            });

            if ("now" === action.date) {
                action.date = new Date();
            }

            return Object.assign({}, state, {
                defaultOptions: defaultOptions,
                centreLessons: centreLessons,
                memberships: memberships,
                subscriptions: subscriptions,
                subscriptionsAvailable: !result.noSubscriptionsFound,
                filteredSubscriptions: filteredSubscriptions,
                users: state.users || defaultUsersState,
                pricing: price,
                extras: state.extras || {},
                maxStartDate: state.maxStartDate || null,
                currentSelectedStartDate: utils.getDateWithoutTimeString(
                    action.date
                ),
                startDateOffset: state.startDateOffset || {},
                startDate: state.startDate || null,
                allUsersHaveSelections: userModel.usersHaveSelections(
                    state.users || defaultUsersState
                )
            });

        case actionTypes.SELECTIONS.APPLY_USER_DATA:
            price = pricingModel.getPrice({
                d: state.duration,
                dt: state.durationType,
                extras: action.extras || state.extras,
                subscriptions: state.subscriptions,
                users: action.users
            });

            newState = {
                users: action.users,
                pricing: price,
                maxStartDate:
                    state.maxStartDate ||
                    utils.getMinNoOfStartDaysDate(action.users.objects),
                allUsersHaveSelections: userModel.usersHaveSelections(
                    action.users
                )
            };

            if (action.extras) {
                newState.extras = action.extras;
            }

            return Object.assign({}, state, newState);

        case actionTypes.SELECTIONS.CHANGE_BANK_FIELD:
            users = JSON.parse(JSON.stringify(state.users));

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                user.isFresh = false;

                user.bankDetails.fields[action.field] = action.data;

                user.bankDetails.valid = true;

                for (key in user.bankDetails.fields) {
                    if (!user.bankDetails.fields[key].valid) {
                        user.bankDetails.errors =
                            user.bankDetails.fields[key].validationErrors;
                        user.bankDetails.valid = false;

                        break;
                    }
                }

                newState = Object.assign({}, state, {
                    users: users,
                    bankDetailsAllValid: userModel.usersBankDetailsValid(
                        users.objects
                    )
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.CHANGE_PROMO_TERMS:
            newState = Object.assign({}, state, {
                promoTerms: {
                    ...state.promoTerms,
                    [action.field || "promoCodePref"]: action.data
                }
            });

            return newState || state;

        case actionTypes.SELECTIONS.MAKE_UNIQUE_BANK_DETAILS:
            users = JSON.parse(JSON.stringify(state.users));

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                user.bankDetails.unique = action.value;

                newState = Object.assign({}, state, {
                    users: users,
                    bankDetailsAllValid: userModel.usersBankDetailsValid(
                        users.objects
                    )
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.SET_REPORTING_ID:
            newState = Object.assign({}, state, {
                reportingTransactionId: action.id
            });
            return newState || state;

        case actionTypes.SELECTIONS.CHANGE_DATA:
            // Deep object clone.
            users = JSON.parse(JSON.stringify(state.users));

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                if (user) {
                    user.isFresh = false;

                    if (action.data.info) {
                        for (key in action.data.info) {
                            user.info[key] = action.data.info[key];
                        }

                        if (user.info.dob) {
                            if (
                                user.info.dob.validationErrors &&
                                user.info.dob.validationErrors.outOfRange
                            ) {
                                user.invalidForAgeRange = true;
                            } else {
                                user.invalidForAgeRange = false;
                            }
                        }
                    }

                    if (action.data.isLoggedInUser) {
                        user.isLoggedInUser = action.data.isLoggedInUser;

                        if (
                            action.data.info &&
                            action.data.info.age &&
                            action.data.info.age > 115
                        ) {
                            user.fallbackAge = true;
                        }
                    }

                    //if adding a lesson to user
                    if (action.data.lesson) {
                        for (key in action.data.lesson) {
                            //if adding to the selected lessons object (when booking a lesson)
                            if (key === "selectedLessons") {
                                for (var l in action.data.lesson
                                    .selectedLessons) {
                                    var selected =
                                        user.lesson.selectedLessons[l] || {};

                                    //if the object already exists, loop through the data and add to it
                                    //otherwise just replace it
                                    if (Object.keys(selected).length) {
                                        for (var s in action.data.lesson
                                            .selectedLessons[l]) {
                                            user.lesson.selectedLessons[l][s] =
                                                action.data.lesson.selectedLessons[
                                                    l
                                                ][s];
                                        }
                                    } else {
                                        user.lesson.selectedLessons[l] =
                                            action.data.lesson.selectedLessons[
                                                l
                                            ];
                                    }
                                }
                            }
                        }
                    }

                    if (action.data.fromUserForm) {
                        user.fromUserForm = action.data.fromUserForm;
                    }

                    if (action.data.subscriptions) {
                        user.activeSubscriptions = action.data.subscriptions;

                        //if a user has a sub that is part of a SubTypeGroupId containing 'CEN',
                        //set the user as a discount for lessons
                        for (
                            var i = 0;
                            i < user.activeSubscriptions.length;
                            i++
                        ) {
                            if (
                                user.activeSubscriptions[i].SubTypeGroupIds &&
                                user.activeSubscriptions[i].SubTypeGroupIds
                                    .length
                            ) {
                                for (
                                    var s = 0;
                                    s <
                                    user.activeSubscriptions[i].SubTypeGroupIds
                                        .length;
                                    s++
                                ) {
                                    if (
                                        /cen/.test(
                                            user.activeSubscriptions[
                                                i
                                            ].SubTypeGroupIds[s].toLowerCase()
                                        )
                                    ) {
                                        user.lesson.discountUser = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    if (action.data.directDebit) {
                        user.directDebit = user.directDebit || {};

                        for (key in action.data.directDebit) {
                            user.directDebit[key] =
                                action.data.directDebit[key];
                        }
                    }
                }

                if (action.data.updatePrice) {
                    result = pricingModel.setSubscriptionsForUsers({
                        discount: state.discount,
                        loggedInUser: state.loggedInUser,
                        fetchedUsers: state.fetchedUsers,
                        users: users,
                        filteredSubscriptions: state.filteredSubscriptions,
                        groupRestrictions: state.deeplinkGroups,
                        durationType: state.durationType,
                        duration: state.duration,
                        centreLessons: state.centreLessons
                    });

                    price = pricingModel.getPrice({
                        subscriptions: state.subscriptions,
                        users: users,
                        extras: state.extras,
                        d: state.duration,
                        dt: state.durationType
                    });
                }

                newState = Object.assign({}, state, {
                    users: users,
                    pricing: action.data.updatePrice ? price : state.pricing,
                    allUsersHaveSelections: userModel.usersHaveSelections(
                        users
                    ),
                    bankDetailsAllValid: userModel.usersBankDetailsValid(
                        users.objects
                    )
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.CHANGE_DATA_VALIDITY:
            // Deep object clone.
            users = JSON.parse(JSON.stringify(state.users));

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                if (action.data.info) {
                    for (key in action.data.info) {
                        user.info[key] = user.info[key] || {
                            value: null,
                            valid: true,
                            validationErrors: {}
                        };

                        user.info[key].valid = action.data.info[key].valid;
                        user.info[key].validationErrors =
                            action.data.info[key].validationErrors || null;
                    }
                }

                newState = Object.assign({}, state, {
                    users: users
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.CHANGE_DURATION:
            return Object.assign({}, state, {
                duration: action.duration || state.duration,
                durationType: action.durationType || state.durationType
            });

        case actionTypes.SELECTIONS.SET_TOTAL_PRICE:
            return Object.assign({}, state, {
                totalPrice: action.totalPrice
            });

        case actionTypes.SELECTIONS.CREATE_DEEPLINK_USER:
            users = {
                count: {
                    deeplink: 1
                },
                hasLead: true,
                objects: [],
                total: 1
            };

            user = Object.assign({}, baseUserObject);

            user.type = key;
            user.typeDesc = "deeplink";
            user.idInType = 0;
            user.canLead = true;
            user.lead = true;
            user.isLoggedInUser = true;

            if (action.user) {
                for (key in action.user) {
                    user.info[key] = action.user[key];

                    if ("dob" === key) {
                        result = userModel.getAge(
                            user,
                            state.duration,
                            state.durationType
                        );
                        user.info.age = result.age;
                    }
                }
            }

            if (
                users.objects.length === 0 &&
                state.discount &&
                state.discount.id
            ) {
                user.promoCode.code = state.discount.id;
            }

            users.objects.push(user);

            result = pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: state.fetchedUsers,
                users: users,
                filteredSubscriptions: state.filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration
            });

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: users,
                extras: state.extras,
                d: state.duration,
                dt: state.durationType,
                cutoffDate: (state.app || {}).cutoffDate || 18
            });

            return Object.assign({}, state, {
                users: users,
                subscriptionsAvailable: !result.noSubscriptionsFound,
                pricing: price,
                maxStartDate:
                    state.maxStartDate ||
                    utils.getMinNoOfStartDaysDate(users.objects),
                allUsersHaveSelections: userModel.usersHaveSelections(users)
            });

        case actionTypes.SELECTIONS.CONVERT_TO_JUNIOR:
            users = Object.assign({}, state.users);

            if (!action.siteId) {
                throw "siteId not set";
            }

            action.user.type = action.siteId + "-JUNIOR";
            action.user.typeDesc = "Junior";

            users.objects[0] = action.user;
            users.count["freeprofile"] = 0;
            users.count[action.siteId + "-JUNIOR"] = 1;

            return Object.assign({}, state, {
                users: users
            });

        case actionTypes.SELECTIONS.DEEPLINK_ADDONS:
            return Object.assign({}, state, {
                deeplinkAddons: action.addons
            });

        case actionTypes.SELECTIONS.DEEPLINK_GROUPS:
            return Object.assign({}, state, {
                deeplinkGroups: action.groups
            });

        case actionTypes.SELECTIONS.DESELECT_EXTRA:
            newExtras = Object.assign({}, state.extras);
            delete newExtras[action.tag];

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: state.users,
                extras: newExtras,
                d: state.duration,
                dt: state.durationType,
                cutoffDate: (state.app || {}).cutoffDate || 18
            });

            return Object.assign({}, state, {
                extras: newExtras,
                pricing: price
            });

        case actionTypes.SELECTIONS.POST_SELECTIONS_SAVE_RESPONSE:
            return Object.assign({}, state, {
                membersResponse: action.response
            });

        case actionTypes.SELECTIONS.POST_SELECTIONS_ERROR:
            let code = 0;
            if (action.error && action.error.match("conflict existing links")) {
                code = 3;
            }
            if (action.error && action.error.match("230")) {
                // full error text: "230 : Failed to save."
                code = 230;
            }
            return Object.assign({}, state, {
                postSelectionsError: action.error,
                postSelectionsErrorCode: code
            });

        case actionTypes.SELECTIONS.REMOVE_LESSON_FROM_USER:
            users = Object.assign({}, state.users);
            var lessons = users.objects[action.userId].lesson;

            if (
                lessons.selectedLessons &&
                Object.keys(lessons.selectedLessons).length &&
                lessons.selectedLessons[action.lesson]
            ) {
                delete lessons.selectedLessons[action.lesson];
            }

            result = pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: state.fetchedUsers,
                users: users,
                filteredSubscriptions: state.filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration,
                centreLessons: state.centreLessons
            });

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: users,
                extras: state.extras,
                d: state.duration,
                dt: state.durationType
            });

            return Object.assign({}, state, {
                users: users,
                subscriptionsAvailable: !result.noSubscriptionsFound,
                pricing: price,
                maxStartDate:
                    state.maxStartDate ||
                    utils.getMinNoOfStartDaysDate(users.objects),
                allUsersHaveSelections: userModel.usersHaveSelections(users)
            });

        case actionTypes.SELECTIONS.SELECT_EXTRA:
            newExtras = Object.assign({}, state.extras);
            newExtras[action.tag] = action.id;

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: state.users,
                extras: newExtras,
                d: state.duration,
                dt: state.durationType
            });

            return Object.assign({}, state, {
                extras: newExtras,
                pricing: price
            });

        case actionTypes.SELECTIONS.SET_CLASHED_USERS:
            return Object.assign({}, state, {
                clashedUsers: action.clashedUsers
            });

        case actionTypes.SELECTIONS.SET_USER_EXPIRED_SUBSCRIPTIONS:
            newState = Object.assign({}, state);

            newState.users.objects[action.uid].expiredSubscriptions =
                action.expiredSubscriptions;

            return newState;

        case actionTypes.SELECTIONS.SET_EXISTING_USERS:
            return Object.assign({}, state, {
                existingUsers: action.existingUsers
            });

        case actionTypes.SELECTIONS.SET_MATCHING_USERS:
            return Object.assign({}, state, {
                matchingUsers: action.matchingUsers
            });

        case actionTypes.SELECTIONS.SET_EXPIRED_SHORT_USERS:
            return Object.assign({}, state, {
                expiredShortUsers: action.expiredShortUsers
            });

        case actionTypes.SELECTIONS.SET_EXTRA_FOR_USER:
            users = Object.assign({}, state.users);
            if (users.objects.length >= action.userId) {
                (user = users.objects[action.userId]),
                    (sub = state.subscriptions[action.extraId]);

                if (action.value && sub) {
                    user.extras[action.tag] = user.extras[action.tag] || {};
                    user.extras[action.tag][action.extraId] = sub;
                } else {
                    if (user.extras[action.tag]) {
                        delete user.extras[action.tag];
                    }
                }

                pricingModel.setSubscriptionsForUsers({
                    discount: state.discount,
                    loggedInUser: state.loggedInUser,
                    fetchedUsers: state.fetchedUsers,
                    users: users,
                    filteredSubscriptions: state.filteredSubscriptions,
                    groupRestrictions: state.deeplinkGroups,
                    durationType: state.durationType,
                    duration: state.duration,
                    centreLessons: state.centreLessons
                });

                price = pricingModel.getPrice({
                    subscriptions: state.subscriptions,
                    users: users,
                    extras: state.extras,
                    d: state.duration,
                    dt: state.durationType
                });

                newState = Object.assign({}, state, {
                    users: users,
                    pricing: price
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.SET_USERS:
            var leadFound = false,
                first = true,
                total = 0,
                existingUser,
                membership,
                count,
                fId,
                nb,
                fetchedUsers = null;

            users = Object.assign({}, state.users);

            usersObj = [];

            for (key in action.users) {
                count = 0;
                nb = action.users[key];

                while (nb--) {
                    existingUser = null;
                    for (i = 0; i < users.objects.length; i++) {
                        if (users.objects[i].type === key) {
                            if (!leadFound) {
                                leadFound = users.objects[i].lead;
                            }

                            existingUser = users.objects.splice(i, 1)[0];
                            break;
                        }
                    }

                    if (existingUser) {
                        user = existingUser;
                    } else {
                        membership = state.memberships.find(function(m) {
                            return key === m.id;
                        });

                        if (!membership) {
                            continue;
                        }

                        user = Object.assign({}, baseUserObject);

                        user.type = key;
                        user.typeDesc = membership.desc;
                        user.idInType = count;
                        user.canLead = userModel.canLead(membership.desc);

                        if (state.promo && state.promo === "fsg") {
                            user.isFSG = true;
                        }

                        if (key === "freeprofile") {
                            if (action.isLinked) {
                                user.guardian = true;
                                user.typeDesc = "Parent / Guardian";
                            } else {
                                user.guardian = false;
                                user.typeDesc = "Free Card / Pay As You Go";
                            }
                        }

                        if (action.userData) {
                            if (action.userData.info) {
                                for (var value in action.userData.info) {
                                    user.info[value] =
                                        action.userData.info[value];
                                }
                            }

                            if (action.userData.lesson) {
                                user.lesson = action.userData.lesson;
                            }
                        }

                        if (
                            membership.extraMemberSubs &&
                            membership.extraMemberSubs.length
                        ) {
                            user.extraMemberSubs = membership.extraMemberSubs;
                        }

                        if (state.defaultOptions[key]) {
                            for (fId in state.defaultOptions[key]) {
                                user.facilities[fId] = {
                                    selected: first ? "on" : "off",
                                    permittedSites:
                                        state.defaultOptions[key][fId]
                                            .defaultCentre,
                                    permittedTimes:
                                        state.defaultOptions[key][fId]
                                            .defaultTime
                                };

                                first = false;
                            }
                        }
                    }

                    count++;
                    total++;
                    first = true;
                    usersObj.push(user);
                }
            }

            usersObj.sort(function(a, b) {
                const ai = userModel.userTypesOrder.indexOf(
                    a.typeDesc.toLowerCase()
                );
                const bi = userModel.userTypesOrder.indexOf(
                    b.typeDesc.toLowerCase()
                );

                if (ai < bi) {
                    return -1;
                }

                if (ai > bi) {
                    return 1;
                }

                return 0;
            });

            users.count = action.users || {};
            users.objects = usersObj;
            users.total = total;

            userModel.setLead(users);

            result = pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: state.fetchedUsers,
                users: users,
                filteredSubscriptions: state.filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration,
                centreLessons: state.centreLessons
            });

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: users,
                extras: state.extras,
                d: state.duration,
                dt: state.durationType
            });

            const defaultDuration = Math.min.apply(
                null,
                users.objects.map(u => {
                    return Math.min.apply(
                        null,
                        u.availableSubscriptions.map(x => x.duration)
                    );
                })
            );

            return Object.assign({}, state, {
                duration:
                    defaultDuration === Infinity
                        ? state.duration
                        : defaultDuration,
                users: users,
                pricing: price,
                subscriptionsAvailable: !result.noSubscriptionsFound,
                maxStartDate:
                    state.maxStartDate ||
                    utils.getMinNoOfStartDaysDate(users.objects),
                allUsersHaveSelections: userModel.usersHaveSelections(users)
            });

        case actionTypes.SELECTIONS.SET_FACILITY_FOR_USER:
            // need a deep colone of the object instead of object.assign as was working weird in case of multiple calls
            users = JSON.parse(JSON.stringify(state.users));

            if (state.durationType === 1) {
                users.objects.forEach(u => {
                    u.extras = {};
                });
            }

            if (users.objects.length >= action.userId) {
                // find the current user, who is doing the selecting
                user = users.objects[action.userId];

                // Mark all of the facilities as unselected
                for (key in user.facilities) {
                    user.facilities[key].selected = false;
                }

                // if the selected facility does not exist in the user, create it
                if (!user.facilities[action.facilityId]) {
                    user.facilities[action.facilityId] = {
                        selected: action.value,
                        permittedSites:
                            state.defaultOptions[user.type][action.facilityId]
                                .defaultCentre,
                        permittedTimes:
                            state.defaultOptions[user.type][action.facilityId]
                                .defaultTime
                    };
                    user.hasMadeSelection = false;

                    // otherwise just mark this facility as selected
                } else {
                    user.facilities[action.facilityId].selected = action.value;
                    user.hasMadeSelection = true;
                }

                // update the pricing model, having set the facility
                pricingModel.setSubscriptionsForUsers({
                    discount: state.discount,
                    loggedInUser: state.loggedInUser,
                    fetchedUsers: state.fetchedUsers,
                    users: users,
                    filteredSubscriptions: state.filteredSubscriptions,
                    groupRestrictions: state.deeplinkGroups,
                    durationType: state.durationType,
                    duration: state.duration
                });

                price = pricingModel.getPrice({
                    subscriptions: state.subscriptions,
                    users: users,
                    extras: state.extras,
                    d: state.duration,
                    dt: state.durationType
                });

                // fix for annual only memberships
                let duration = state.duration;
                let durationType = state.durationType;

                if (user.availableSubscriptions.length === 1) {
                    duration = user.availableSubscriptions[0].duration;
                    durationType = user.availableSubscriptions[0].durationType;
                }

                newState = Object.assign({}, state, {
                    duration,
                    durationType,
                    users: users,
                    pricing: price,
                    maxStartDate:
                        state.maxStartDate ||
                        utils.getMinNoOfStartDaysDate(users.objects),
                    allUsersHaveSelections: userModel.usersHaveSelections(users)
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.SET_FACILITY_PERMITTED_SITES_FOR_USER:
            users = Object.assign({}, state.users);

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                var defaultTime =
                        state.defaultOptions[user.type][action.facilityId]
                            .defaultTime,
                    defaultCentre =
                        state.defaultOptions[user.type][action.facilityId]
                            .defaultCentre,
                    sitesForFacility =
                        state.filteredSubscriptions[user.type][
                            action.facilityId
                        ];

                if (!user.facilities[action.facilityId]) {
                    user.facilities[action.facilityId] = {
                        selected: "on",
                        permittedSites: defaultCentre,
                        permittedTimes: defaultTime
                    };
                }

                user.facilities[action.facilityId].permittedSites =
                    action.value;

                // If there is a selected permitted time already, but no permitted site for it,
                //  we need to revert the permitted time to the default for the newly selected
                //  permiited time.
                if (
                    !sitesForFacility[action.value][
                        user.facilities[action.facilityId].permittedTimes
                    ]
                ) {
                    if (sitesForFacility[action.value][defaultTime]) {
                        user.facilities[
                            action.facilityId
                        ].permittedTimes = defaultTime;
                    } else {
                        var keys = Object.keys(sitesForFacility[action.value]);
                        user.facilities[action.facilityId].permittedTimes =
                            sitesForFacility[action.value][keys[0]];
                    }
                }

                pricingModel.setSubscriptionsForUsers({
                    discount: state.discount,
                    loggedInUser: state.loggedInUser,
                    fetchedUsers: state.fetchedUsers,
                    users: users,
                    filteredSubscriptions: state.filteredSubscriptions,
                    groupRestrictions: state.deeplinkGroups,
                    durationType: state.durationType,
                    duration: state.duration
                });

                price = pricingModel.getPrice({
                    subscriptions: state.subscriptions,
                    users: users,
                    extras: state.extras,
                    d: state.duration,
                    dt: state.durationType
                });

                newState = Object.assign({}, state, {
                    users: users,
                    pricing: price,
                    maxStartDate:
                        state.maxStartDate ||
                        utils.getMinNoOfStartDaysDate(users.objects)
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.SET_FACILITY_PERMITTED_TIMES_FOR_USER:
            users = Object.assign({}, state.users);

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                if (!user.facilities[action.facilityId]) {
                    user.facilities[action.facilityId] = {
                        selected: "on",
                        permittedSites:
                            state.defaultOptions[user.type][action.facilityId]
                                .defaultCentre,
                        permittedTimes:
                            state.defaultOptions[user.type][action.facilityId]
                                .defaultTime
                    };
                }

                user.facilities[action.facilityId].permittedTimes =
                    action.value;

                pricingModel.setSubscriptionsForUsers({
                    discount: state.discount,
                    loggedInUser: state.loggedInUser,
                    fetchedUsers: state.fetchedUsers,
                    users: users,
                    filteredSubscriptions: state.filteredSubscriptions,
                    groupRestrictions: state.deeplinkGroups,
                    durationType: state.durationType,
                    duration: state.duration
                });

                price = pricingModel.getPrice({
                    subscriptions: state.subscriptions,
                    users: users,
                    extras: state.extras,
                    d: state.duration,
                    dt: state.durationType
                });

                newState = Object.assign({}, state, {
                    users: users,
                    pricing: price,
                    maxStartDate:
                        state.maxStartDate ||
                        utils.getMinNoOfStartDaysDate(users.objects)
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.SET_LESSON_FOR_USER:
            users = Object.assign({}, state.users);
            user = users.objects[action.userId];

            if (action.subId) {
                user.hasMadeSelection = true;
            } else {
                user.hasMadeSelection = false;
            }

            pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: state.fetchedUsers,
                users: users,
                filteredSubscriptions: state.filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration,
                centreLessons: state.centreLessons
            });

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: users,
                extras: state.extras,
                d: state.duration,
                dt: state.durationType
            });

            newState = Object.assign({}, state, {
                users: users,
                pricing: price,
                maxStartDate:
                    state.maxStartDate ||
                    utils.getMinNoOfStartDaysDate(users.objects),
                allUsersHaveSelections: userModel.usersHaveSelections(users)
            });

            return newState || state;

        case actionTypes.SELECTIONS.SET_RENEWAL_SUBS:
            return Object.assign({}, state, {
                renewalSubs: action.subs
            });

        case actionTypes.SELECTIONS.SET_START_DATE_OFFSET:
            return Object.assign({}, state, {
                startDateOffset: action.dateOffset,
                startDate: action.date,
                startDateIsRenewal: action.isRenewal
            });

        case actionTypes.SELECTIONS.USE_LEAD_CONTACT:
            users = Object.assign({}, state.users);

            if (users.objects.length >= action.userId) {
                users.objects[action.userId].isFresh = false;
                users.objects[action.userId].useLeadContact = action.use;
            }

            return Object.assign({}, state, { users: users });

        case actionTypes.SELECTIONS.USE_LEAD_EMMERGENCY_DETAILS:
            users = Object.assign({}, state.users);

            if (users.objects.length >= action.userId) {
                users.objects[action.userId].isFresh = false;
                users.objects[action.userId].useLeadEmmergencyDetails =
                    action.use;
            }

            return Object.assign({}, state, { users: users });

        case actionTypes.SELECTIONS.SET_EMMERGENCY_DETAILS:
            users = Object.assign({}, state.users);

            if (users.objects.length >= action.userId) {
                users.objects[action.userId].isFresh = false;
                users.objects[action.userId].useLeadEmmergencyDetails =
                    action.value;
            }

            return Object.assign({}, state, { users: users });

        case actionTypes.SELECTIONS.USE_LEAD_BANK_DETAILS:
            users = JSON.parse(JSON.stringify(state.users));

            if (users.objects.length >= action.userId) {
                users.objects[action.userId].isFresh = false;
                users.objects[action.userId].useLeadBankDetails = action.value;

                newState = Object.assign({}, state, {
                    users: users,
                    bankDetailsAllValid: userModel.usersBankDetailsValid(
                        users.objects
                    )
                });
            }

            return newState || state;

        case actionTypes.SELECTIONS.VALIDATING:
            return Object.assign({}, state, {
                validating: action.validating
            });

        case actionTypes.SELECTIONS.WIPEOUT:
            return Object.assign({}, defaultState);

        case actionTypes.SELECTIONS.PRESELECT_USERS:
            return Object.assign({}, state, {
                preselectedUsers: action.users
            });

        case actionTypes.SELECTIONS.PRESELECT_SUBS:
            return Object.assign({}, state, {
                preselectedSubs: action.preselectedSubs
            });

        case actionTypes.SELECTIONS.PRESELECT_SUBS_PROCESSED:
            return Object.assign({}, state, {
                preselectionSubProcessed: action.preselectionSubProcessed
            });

        case actionTypes.SELECTIONS.PRESELECT_PARTNER:
            return Object.assign({}, state, {
                preselectedPartner: action.preselectedPartner
            });

        case actionTypes.SELECTIONS.PRESELECT_GROUPS:
            return Object.assign({}, state, {
                preselectedSubsByGroups: action.preselectedSubsByGroups
            });

        case actionTypes.SELECTIONS.PROFILE_RECEIVED:
            return Object.assign({}, state, {
                loggedInUser: action.profile
            });

        case actionTypes.SELECTIONS.SET_PROMO:
            return Object.assign({}, state, {
                promo: action.promo
            });

        case actionTypes.SELECTIONS.SET_STAFF_PROMO:
            return Object.assign({}, state, {
                staffPromo: action.promo
            });

        case actionTypes.SELECTIONS.SET_USER_DISCOUNT_PROMO:
            return Object.assign({}, state, {
                userDiscountPromo: action.userDiscountPromo
            });

        case actionTypes.SELECTIONS.USER_FETCHED:
            var newFetchedUsers = state.fetchedUsers || [];

            if (action.fetchedUser) {
                newFetchedUsers.push(action.fetchedUser);
            }

            pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: newFetchedUsers,
                users: state.users,
                filteredSubscriptions: state.filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration,
                centreLessons: state.centreLessons
            });

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: state.users || defaultUsersState,
                extras: state.extras || {},
                d: state.duration,
                dt: state.durationType
            });

            return Object.assign({}, state, {
                fetchedUsers: newFetchedUsers,
                pricing: price
            });

        case actionTypes.SELECTIONS.CLEAN_USER:
            users = Object.assign({}, state.users);

            if (users.objects.length >= action.userId) {
                user = users.objects[action.userId];

                fetchedUsers = state.fetchedUsers.filter(function(el) {
                    return (
                        el.mrmId !==
                        (user.info && user.info.mrmId && user.info.mrmId.value)
                    );
                });

                user.activeSubscriptions = {};
                user.info = {};
                user.bankDetails.collections = {};
                user.bankDetails.fields = {};
                user.bankDetails.errors = {};
                user.bankDetails.paymentUrl = null;
                user.bankDetails.total = null;
                user.bankDetails.unique = null;
                user.bankDetails.valid = null;
                user.bankDetails.validationInProgress = null;
                user.isLoggedInUser = false;
                user.fallbackAge = false;

                delete user.directDebit;

                pricingModel.setSubscriptionsForUsers({
                    discount: state.discount,
                    loggedInUser: state.loggedInUser,
                    fetchedUsers: fetchedUsers,
                    users: state.users,
                    filteredSubscriptions: state.filteredSubscriptions,
                    groupRestrictions: state.deeplinkGroups,
                    durationType: state.durationType,
                    duration: state.duration,
                    centreLessons: state.centreLessons
                });

                price = pricingModel.getPrice({
                    subscriptions: state.subscriptions,
                    users: state.users || defaultUsersState,
                    extras: state.extras || {},
                    d: state.duration,
                    dt: state.durationType
                });

                return Object.assign({}, state, {
                    fetchedUsers: fetchedUsers,
                    users: users,
                    pricing: price
                });
            }

            return;

        case actionTypes.SELECTIONS.CONSIDER_PRESELECTED_SUBSCRIPTIONS_ONLY:
            return {
                ...state,

                considerPreselectedSubscriptionsOnly: action.considerPreselectedSubscriptionsOnly
            };
            break;

        case actionTypes.SELECTIONS.REMOVE_USER:
            var fetchedUsers = state.fetchedUsers || [];
            users = Object.assign({}, state.users);
            user = users.objects[action.userId];

            users.objects.splice([action.userId], 1);
            users.count[user.type]--;
            users.total--;

            fetchedUsers = fetchedUsers.filter(function(el) {
                return (
                    el.mrmId !==
                    (user.info && user.info.mrmId && user.info.mrmId.value)
                );
            });

            pricingModel.setSubscriptionsForUsers({
                discount: state.discount,
                loggedInUser: state.loggedInUser,
                fetchedUsers: fetchedUsers,
                users: state.users,
                filteredSubscriptions: state.filteredSubscriptions,
                groupRestrictions: state.deeplinkGroups,
                durationType: state.durationType,
                duration: state.duration,
                centreLessons: state.centreLessons
            });

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: state.users,
                extras: state.extras || {},
                d: state.duration,
                dt: state.durationType
            });

            return Object.assign({}, state, {
                fetchedUsers: fetchedUsers,
                users: users,
                pricing: price,
                allUsersHaveSelections: userModel.usersHaveSelections(users)
            });

        case actionTypes.SELECTIONS.RECALCULATE_EXTRAS:
            users = state.users || [];
            var subscription,
                subGroups,
                inAGroup = false;

            for (key in action.centre.addons) {
                var e =
                    action.extraType === "addon"
                        ? action.centre.addons[key]
                        : action.centre.extras[key];
                var subs = e && e.subs;
                var extraSelected = subs.find(
                    function(s) {
                        return s.id === state.extras[key];
                    }.bind(this)
                );

                for (i = 0; i < users.objects.length; i++) {
                    user = users.objects[i];

                    subscription = user.availableSubscriptions.find(function(
                        s
                    ) {
                        return (
                            (s.duration === state.duration &&
                                s.durationType === state.durationType) ||
                            s.durationType === 1
                        );
                    });

                    if (extraSelected && subscription) {
                        subGroups = action.centre.addOnGroups.filter(function(
                            g
                        ) {
                            return subscription.groups.includes(g.groupId);
                        });

                        inAGroup = subGroups.find(function(g) {
                            var foundInAGroup = false,
                                i;

                            for (i = 0; i < g.addOnGroupIds.length; i++) {
                                if (
                                    extraSelected.groups.includes(
                                        g.addOnGroupIds[i]
                                    )
                                ) {
                                    foundInAGroup = true;
                                    break;
                                }
                            }

                            return foundInAGroup;
                        });

                        if (!inAGroup) {
                            if (user.extras[key]) {
                                delete user.extras[key][state.extras[key]];
                            }
                        }
                    }
                }
            }

            price = pricingModel.getPrice({
                subscriptions: state.subscriptions,
                users: users,
                extras: state.extras,
                d: state.duration,
                dt: state.durationType
            });

            return Object.assign({}, state, {
                users: users,
                pricing: price
            });

        case actionTypes.SELECTIONS.SET_IS_DISCOUNT_APPLIED:
            return Object.assign({}, state, {
                isDiscountApplied: action.isDiscountApplied
            });

        case actionTypes.SELECTIONS.SET_USER_PROMO_CODE:
            var userObj = state.users.objects[action.userId],
                promoCode = userObj.promoCode;

            userObj.promoCode = {
                code: action.code === undefined ? promoCode.code : action.code,
                isValid:
                    action.isValid === undefined
                        ? promoCode.isValid || null
                        : action.isValid,
                redemptionId:
                    action.redemptionId === undefined
                        ? promoCode.redemptionId || null
                        : action.redemptionId,
                redemptionConfirmed:
                    action.redemptionConfirmed === undefined
                        ? promoCode.redemptionConfirmed || null
                        : action.redemptionConfirmed,
                errorMsg:
                    action.errorMsg === undefined
                        ? promoCode.errorMsg || null
                        : action.errorMsg
            };

            state.users.objects[action.userId] = {
                ...userObj
            };

            return {
                ...state
            };

        default:
            return state;
    }
};
