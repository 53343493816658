var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/renewalsHeader'),
    Component       = require('../components/renewals');

module.exports = [{

    path: '/renewals',

    handler: function () {

        return {
            header: <Header />,
            component: <Component />
        };
    }
}];
