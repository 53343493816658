var React           = require('react'),
    connect         = require('react-redux').connect,

    Loader;

Loader = React.createClass({

    /**
     * Render
     * @return {Object}
     */
    render: function() {

        if (!this.props.show && !this.props.showCentreFinder) {
            return null;
        }

        return (<div className='loader'>
            <div className='loader__wrapper'>
                <ul className="loader__spinner">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.loader,
        show: state.app.showLoader,
        showCentreFinder: state.app.showLoaderCentreFinder
    };
}

module.exports = connect(mapStateToProps)(Loader);
