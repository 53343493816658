var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions"),
    Button = require("./button"),
    scrollToComponent = require("react-scroll-to-component"),
    Modal;

Modal = React.createClass({
    componentDidMount: function() {
        window.addEventListener("keydown", this._handleKeyDown);
        this._scrollToModal();
    },

    componentWillUnmount: function() {
        window.removeEventListener("keydown", this._handleKeyDown);
    },

    /**
     * [_scrollToModal description]
     * @return {[type]} [description]
     */
    _scrollToModal: function() {
        var modal = document.getElementsByClassName("modal__wrapper")[0];
        scrollToComponent(modal, {
            align: "top",
            offset: -100
        });
    },

    _handleKeyDown: function _handleKeyDown(e) {
        // Close on escape key pressed.
        if (e.keyCode === 27) {
            this._onHideModal(e);
        }
    },

    _onHideModal: function _onHideModal(e) {
        e.preventDefault();

        //if (this.props.onClose) {
        //    this.props.onClose();
        //}

        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    render: function() {
        var closeButton = this.props.noClose ? null : (
                <Button className="modal__close" onClick={this._onHideModal}>
                    <span className="a11y">
                        {this.props.content.buttonClose}
                    </span>
                </Button>
            ),
            title = this.props.title ? (
                <span className="modal__title">{this.props.title}</span>
            ) : null,
            header = null;

        if (closeButton || title) {
            header = (
                <header className="modal__header">
                    {closeButton}
                    {title}
                </header>
            );
        }

        return (
            <div className={"modal " + this.props.className}>
                <div className="modal__wrapper">
                    {header}
                    <div className="modal__content">{this.props.children}</div>
                </div>
            </div>
        );
    }
});

Modal.propTypes = {
    onSubmit: React.PropTypes.func,
    onClose: React.PropTypes.func,
    noClose: React.PropTypes.bool,
    title: React.PropTypes.string,
    id: React.PropTypes.string
};

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.base
    };
}

module.exports = connect(mapStateToProps)(Modal);
