var actionTypes     = require('./actionTypes.js');

module.exports = {

    hide: function hide() {
        return {
            type: actionTypes.LIVECHAT.HIDE
        };
    },

    offline: function offline() {
        return {
            type: actionTypes.LIVECHAT.OFFLINE
        };
    },

    online: function online() {
        return {
            type: actionTypes.LIVECHAT.ONLINE
        };
    },

    ready: function ready() {
        return {
            type: actionTypes.LIVECHAT.READY
        };
    },

    show: function show() {
        return {
            type: actionTypes.LIVECHAT.SHOW
        };
    }
};
