// Adapted from https://github.com/fullstackreact/google-maps-react
// Replaced Map with an object.
var counter = 0,
    scriptMap = window._scriptMap || Object.create(null),
    ScriptCache;

ScriptCache = (function(global) {
    global._scriptMap = global._scriptMap || scriptMap;
    return function ScriptCache(scripts) {
        var Cache = {};

        Cache._onLoad = function(key) {
            return function (cb) {
                var stored = scriptMap[key];

                if (stored) {
                    stored.promise.then(function () {
                        stored.error ? cb(stored.error) : cb(null, stored);
                        return stored;
                    });
                }
            };
        };

        Cache._scriptTag = function (key, src) {
            if (!scriptMap[key]) {
                var tag = document.createElement('script'),
                    initialState,
                    promise;

                promise = new Promise(function (resolve, reject) {
                    var body = document.getElementsByTagName('body')[0],
                        handleResult,
                        cleanup,
                        cbName;

                    tag.type = 'text/javascript';
                    tag.async = false; // Load in order

                    cbName = `loaderCB${counter++}${Date.now()}`;

                    cleanup = function () {
                        if (global[cbName] && typeof global[cbName] === 'function') {
                            global[cbName] = null;
                            delete global[cbName];
                        }
                    };

                    handleResult = function (state) {
                        return function (evt) {
                            var stored = scriptMap[key];

                            if (state === 'loaded') {
                                stored.resolved = true;
                                resolve(src);
                            } else if (state === 'error') {
                                stored.errored = true;
                                reject(evt);
                            }
                            stored.loaded = true;

                            cleanup();
                        };
                    };

                    tag.onload = handleResult('loaded');
                    tag.onerror = handleResult('error');
                    tag.onreadystatechange = function() {
                        handleResult(tag.readyState);
                    };

                    // Pick off callback, if there is one
                    if (src.match(/callback=CALLBACK_NAME/)) {
                        src = src.replace(/(callback=)[^\&]+/, `$1${cbName}`);
                        window[cbName] = tag.onload;

                    } else {
                        tag.addEventListener('load', tag.onload);
                    }

                    tag.addEventListener('error', tag.onerror);

                    tag.src = src;
                    body.appendChild(tag);

                    return tag;
                });

                initialState = {
                    loaded: false,
                    error: false,
                    promise: promise,
                    tag
                };

                scriptMap[key] = initialState;
            }

            return scriptMap[key];
        };

        Object.keys(scripts).forEach(function(key) {
            var script = scripts[key],
                tag;

            tag = window._scriptMap[key] ?
                    window._scriptMap[key].tag :
                    Cache._scriptTag(key, script);

            Cache[key] = {
                tag: tag,
                onLoad: Cache._onLoad(key),
            };
        });

        return Cache;
    };
})(window);

module.exports = ScriptCache;
