const { virtualPageTitle } = require( '../../../common/google/analytics/lessons' );
const { castToUndefinedIfEmpty, products, hasSelections } = require( '../../../common/google/analytics/products' );
const { push } = require( '../common/google/analytics/datalayer' );

var React           = require('react'),
    connect         = require('react-redux').connect,
    cookie          = require('react-cookie'),

    actions         = require('../../actions'),
    config          = require('../../config'),

    CentreFinder    = require('./centreFinder'),
    Memberships     = require('./membershipSelection'),
    Facilities      = require('./facilitiesSelection'),
    Faqs            = require('../faqs'),
    Button          = require('../common/button'),
    SaveSelections  = require('../common/saveSelectionsButton'),
    FooterSecurity  = require('../common/footerSecurity'),

    Stage1Content;

Stage1Content = React.createClass({

    getInitialState: function getInitialState() {
        return {
            nextCount: 0,
            valid: this._usersValid(this.props.users)
        };
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if (this.props.frozen !== newProps.frozen || JSON.stringify(this.props.users) !== JSON.stringify(newProps.users)) {
            var newValid = this._usersValid(newProps.users);

            if (newValid !== this.state.valid) {
                this.setState({
                    valid: newValid
                });
            }
        }

        if(this.props.centre !== newProps.centre && newProps.centre && newProps.centre.info && newProps.centre.info.site_id && this.props.staffId) {
            var centreCookie = cookie.load('favouriteCentre');

            if(!centreCookie) {
                this.props.dispatch(actions.app.showModal('favouriteCentre'));
            }
        }
    },


    /**
     * [_getSelectedFacilities description]
     * @param  {[type]} users [description]
     * @return {[type]}       [description]
     */
    _getSelectedFacilities: function _getSelectedFacilities(users) {

        var response = [];

        for (var i = 0; i < users.length; i++) {
            for (var key in users[i].facilities) {
                if (users[i].facilities[key].selected === 'on') {
                    response.push((this.props.centre.permittedFacilities[key].desc).trim());
                }
            }

            if (users[i].type === 'freeprofile') {
                response.push('Free Account');
            }
        }

        if (response.length === 1) {
            response = response[0];
        } else {
            response = response.sort();
        }

        return response;
    },


    _validateForm: function _validateForm(e) {
        e.preventDefault();

        if (this.props.frozen || this.props.nbUsers <= 1 || this._usersValid(this.props.users)) {
            push( {
                event: "virtual pageview",
                virtualPagePath: "/virtual/memberships-options-selected/",
                virtualPageTitle: "Join - Everyone Active – Memberships Options",
                products: this.props.products
            } )

            this._next();

        } else {
            this.setState({
                valid: false
            });
        }
    },

    _usersValid: function _usersValid(users) {
        var user,
            i;

        // If only one user is selected, we'll not display the
        //  name fields, to we consider it valid anyway.
        // Validation is only used to go to the next stage here.
        if (users.length === 1) {
            return true;
        }

        for (i = 0; i < users.length; i++) {
            user = users[i];

            // To be valid for this page, a user must have
            //  a valid firstName and lastName
            if (!user.info || !user.info.firstName || !user.info.firstName.valid || !user.info.lastName || !user.info.lastName.valid) {
                return false;
            }
        }

        return true;
    },

    _next: function _next() {
        // TODO: THIS BLOCK IS DUPLICATED IN THE SIDEBAR, UPDATE THERE IF YOU UPDATE HERE!
        this.props.dispatch(actions.memberships.freeze());
        this.props.dispatch(actions.stages.next());

        // We want to "hide" the "show me more centres" viewer at this point
        this.props.dispatch( actions.siteBanding.toggleShowCentres(false));
    },

    _resetStaffId: function _resetStaffId() {
        this.props.dispatch(actions.staffLogin.logout());
        this.props.dispatch(actions.staffLogin.showLogin(true));

        window.dataLayer.push({
            staffLogin: 'false'
        });
    },

    render: function() {
        var content = this.props.content,
            self = this,
            subscriptionsAvailable = this.props.subscriptionsAvailable,
            invalidUnfrozenState = !this.props.frozen && !this.state.valid,
            disabled = invalidUnfrozenState || !subscriptionsAvailable || !this.props.allUsersHaveSelections,
            buttonProps = {
                className: 'button' + (disabled ? ' button--disabled' : ''),
                disabled: disabled,
                onClick: self._validateForm
            },
            buttonText = invalidUnfrozenState ? content.buttonFillNames : (!subscriptionsAvailable ? content.buttonNoSubscriptionsFound : content.buttonExtras),
            saveSelectionsButton,
            extraOptions,
            actionButton;

        if (this.props.centre && this.props.nbUsers && this.props.membershipSelected) {
            actionButton = <Button {...buttonProps}>{ buttonText }</Button>;

            if (this.props.allUsersHaveSelections) {
                saveSelectionsButton = <SaveSelections />;
            }

            extraOptions = <div className='stage-options'>
                    { saveSelectionsButton }
                    { actionButton }
                </div>;
        }

        return (<div className='container'>
            {this.props.staffName &&
            <h3 onClick={this._resetStaffId} className="staff-reset-link">Applied Staff: {this.props.staffName} ({this.props.staffId}). Reset?</h3>}

            <CentreFinder staffName={this.props.staffName} />
            <Memberships />
            <Facilities />
            { extraOptions }
            <Faqs />
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        allUsersHaveSelections: state.selections.allUsersHaveSelections,
        centre: state.centreFinder.selected,
        content: state.app.content.stage1.content,
        discounts: state.discounts,
        membershipSelected: state.memberships.selected,
        frozen: state.memberships.frozen,
        staffId: state.staffLogin.staffId,
        staffName: state.staffLogin.staffName,
        subscriptionsAvailable: state.selections.subscriptionsAvailable,
        nbUsers: state.selections.users.total,
        users: state.selections.users.objects,
        nextCount: state.nextCount,

        // GA
        products: hasSelections(state) ? castToUndefinedIfEmpty(
            products( state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined )( virtualPageTitle( state, 'Membership' ) )( state )
        ) : [ {
            id: undefined,
            name: virtualPageTitle( state, 'Membership' ),
            brand: state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined,
        } ]
    };
}

module.exports = connect(mapStateToProps)(Stage1Content);
