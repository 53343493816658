var React = require("react");
const config = require('../../config');


const Booking = React.createClass({
    redirectToProfileArea: function() {
        const params = (new URL(this.props.user.info.profileUrl.value)).searchParams;
        const token = params.get('token');
        const redirectUrl = `${window.location.protocol}${config.services.selfServe.urls.setPassword}?lmt=${token}`;
        window.location = redirectUrl;
    },

    getFullName: function(user) {
        return `${user.info.firstName.value} ${user.info.lastName.value}`;
    },

    render: function() {
        const {
            content: {
                booking: { title, description, guardianNotice }
            },
            user,
            guardianUser
        } = this.props;

        const guardianMessage = `${guardianNotice.replace(
            "{name}",
            this.getFullName(user)
        )}`;

        return (
            <div className="confirm-booking">
                <h1 className="content__title">{title}</h1>
                <p className="content__intro">{description}</p>

                {guardianUser && (
                    <div className="module-container">
                        <div className="form__error-zone centre-finder__notice">
                            <span className="form__error">
                                {guardianMessage}
                            </span>
                        </div>
                    </div>
                )}

                <button
                    className="button confirm-page__button"
                    onClick={this.redirectToProfileArea}
                >
                    Continue
                </button>
            </div>
        );
    }
});

Booking.propTypes = {
    content: React.PropTypes.object,
    user: React.PropTypes.object
};

module.exports = Booking;
