/**
 * Filters out addons based on their subscriptions.
 * Each addons subscription is filtered based on their tags
 * e.g. JAP% will not be included
 * @param {array} addonsWithSubscriptions applicable addons with their subscriptions
 */
export const filterOutNonAddons = applicableAddonIds => ( [ tagName, addon ] ) => {
    return [
        tagName,

        // Addon details
        {
            ...addon,

            // Create fresh list of subscriptions for this addon
            // by filtering out non-addon group ids
            subs: filterSubscriptions( applicableAddonIds )( addon.subs )
        }
    ];
};

/**
 * Filters out any add-on that doesn't have any subscriptions attached to it
 * This may be because there's no JAD subscription ids for instance
 * @param {object} subscriptions
 */
export const noSubscriptions = ( [ tagName, addon ] ) => {
    return addon.subs.length > 0;
};

/**
 * Filters out subscriptions based on non-addon group ids
 * @param {object} subscriptions
 */
export const filterSubscriptions = applicableAddonIds => subscriptions => {
    return subscriptions
        .filter( subscription => {
            return applicableAddonIds.includes( subscription.id );
        } );
};