const { getFeesTotal } = require('../../utils/stateSelectors');

// TODO: This should extend a sidebar Expander component
var React           = require('react'),
    connect         = require('react-redux').connect,

    utils           = require('../../utils'),

    Button          = require('../common/button'),
    viewUtils       = require('../../utils/viewUtils'),

    PriceBreakdown;

PriceBreakdown = React.createClass({
    displayName: 'PriceBreakdown',

    componentDidUpdate: function componentDidUpdate() {
        if (this.props.expanded) {
            var elt = document.querySelector('.price-breakdown__details');
            elt && viewUtils.adjustHeight(elt);
        }
    },

    render: function render() {
        if (!this.props.centre || !this.props.users.total) {
            return null;
        }

        var price = this.props.price,
            monthPrices,
            yearPrices,
            monthlyTotal,
            annualTotal,
            users = this.props.users.objects,
            isLessons = this.props.isLessons,
            content = this.props.content,
            subscriptions = [],
            monthlyExtras = [],
            annualExtras = [],
            otherFees = [],
            lessonFees = [],
            otherExtrasNode = [],
            self = this,

            typeDesc,
            idInType,
            firstName,
            lastName,
            userName,

            feesDiscountNode,
            otherExtrasTotal,
            lastMonthNode,
            subscription,
            otherExtras,
            feesNode,
            extraObj,
            ageNote,
            amount,
            fees,
            user,
            key,
            i;

        const {
            durationType,
            duration
        } = this.props;

        const monthlyView = durationType === 0 && duration === 1;
        const annualView = durationType === 0 && duration === 12;
        const shortView = durationType === 1;

        if (durationType === 0) {
            if (price && price.periods[0]) {
                monthPrices = price.periods[0][1];
                yearPrices = price.periods[0][12];
            }

            const monthlyDiscount = monthPrices && monthPrices.discountTotal || 0
            const annualDiscount = yearPrices && yearPrices.discountTotal || 0

            monthlyTotal = (monthPrices && monthPrices.total - monthlyDiscount) || 0;
            annualTotal = (yearPrices && yearPrices.total) || 0;
        } else if (price && Object.entries(price?.periods).length) {

            monthPrices = price.periods[0] && price.periods[0][1],
            yearPrices = Object
                .values(price.periods[1])
                .reduce(function(period, item){
                    period.total += item.total;
                    period.extras.total += item.extras.total;
                    period.fees.adminDiscounts += item.fees.adminDiscounts;
                    period.fees.adminPayable += item.fees.adminPayable;
                    period.fees.adminTotal += item.fees.adminTotal;
                    period.fees.lastMonthsTotal += item.fees.lastMonthsTotal;
                    period.fees.other = period.fees.other.concat(item.fees.other);
                    period.fees.total += item.fees.total;

                    period.priceOverridesPerUser = period.priceOverridesPerUser.concat(item.priceOverridesPerUser);

                    period.extras.items = Object.assign(period.extras.items || {}, item.extras.items || {});

                    return period;
                }, Object.assign({
                    total: 0,
                    priceOverridesPerUser: [],
                    extras: {
                        total: 0
                    },
                    fees: {
                        adminDiscounts: 0,
                        adminPayable: 0,
                        adminTotal: 0,
                        other: [],
                        total: 0
                    }
                }, price.periods[0] ? price.periods[0][12] : null));

            var total = Object.values(price.periods[1]).reduce((total, x) => total += x.total, 0);

            monthlyTotal = 0;
            annualTotal = total;
        }

        // Processing each user's subscriptions to fund the first for each duration.
        for (i = 0; i < users.length; i++) {
            user = users[i];

            subscription = user.availableSubscriptions.find(function(s) {
                return s.duration === self.props.duration
                && s.durationType === self.props.durationType
                || s.durationType === 1
                && s.durationType === self.props.durationType;
            });

            if (!subscription) {
                continue;
            }

            amount = (subscription && subscription.listPriceInPence) || 0;

            if (monthlyView && subscription.price['now']) {
                const regularPriceItem = subscription.price['now'].find(x => x.desc.match(/last.?month|regular/i)) || {};
                const { discount } = regularPriceItem;

                if (discount && discount.discountType) {
                    const {
                        discountValueInPence,
                    } = discount;

                    amount = amount - discountValueInPence;
                }
            }

            ageNote = '';

            typeDesc = user.typeDesc;
            idInType = user.idInType + 1;
            firstName = (user.info.firstName && user.info.firstName.value);
            lastName = (user.info.lastName && user.info.lastName.value);

            if (users.length === 1 && firstName) {
                userName = ((firstName && lastName) ? firstName + ' ' + lastName : (firstName ? firstName : ''));
            } else {
                userName = typeDesc + ' ' + idInType + ((firstName && lastName) ? ': ' + firstName + ' ' + lastName : (firstName ? ': ' + firstName : ''));
            }

            if (user.invalidForAgeRange) {
                ageNote = ' ' + content.notValidForAge;
            }

            subscriptions.push(
                <div key={ 'pb_u_' + i } className='price-breakdown__line-item'>
                    <span className='price-breakdown__line-item-desc'>{ userName }{ ageNote }</span>
                    <span className='price-breakdown__line-item-price'>{ utils.formatPrice(amount) }</span>
                </div>
            );
        }

        if (monthPrices) {
            for (key in monthPrices.extras.items) {
                extraObj = monthPrices.extras.items[key];

                monthlyExtras.push(
                    <div key={ 'pb_e_' + key } className='price-breakdown__line-item'>
                        <span className='price-breakdown__line-item-desc'>{ extraObj.count > 1 ? (extraObj.count + ' * ') : '' }{ extraObj.extra.desc }</span>
                        <span className='price-breakdown__line-item-price'>{ utils.formatPrice(extraObj.count * extraObj.extra.listPriceInPence) }</span>
                    </div>
                );
            }
        }

        if (yearPrices) {
            for (key in yearPrices.extras.items) {
                extraObj = yearPrices.extras.items[key];

                // if (monthlyView || shortView) {
                otherFees.push(
                    <div key={ 'pb_e_' + key } className='price-breakdown__line-item'>
                        <span className='price-breakdown__line-item-desc'>{ extraObj.count > 1 ? (extraObj.count + ' * ') : '' }{ extraObj.extra.desc }</span>
                        <span className='price-breakdown__line-item-price'>{ utils.formatPrice(extraObj.count * extraObj.extra.listPriceInPence) }</span>
                    </div>
                );
                // }
                // else {
                //     annualExtras.push(
                //         <div key={ 'pb_e_' + key } className='price-breakdown__line-item'>
                //             <span className='price-breakdown__line-item-desc'>{ extraObj.count > 1 ? (extraObj.count + ' * ') : '' }{ extraObj.extra.desc }</span>
                //             <span className='price-breakdown__line-item-price'>{ utils.formatPrice(extraObj.count * extraObj.extra.listPriceInPence) }</span>
                //         </div>
                //     );
                // }
            }
        }

        if (monthlyView) {
            subscriptions = subscriptions.concat(monthlyExtras);
            otherExtras = annualExtras;

            if (monthPrices) {
                fees = monthPrices.fees;

                if (yearPrices) {
                    fees.total += yearPrices.extras.total;
                }
            } else {
                fees = 0;
            }
        } else if (annualView) {
            subscriptions = subscriptions.concat(annualExtras);
            otherExtras = monthlyExtras;
            otherExtrasTotal = (monthPrices && monthPrices.extras.total) || 0;

            if (yearPrices) {
                fees = yearPrices.fees;
                fees.total += yearPrices.extras.total;
            } else {
                fees = 0;
            }
        } else if (shortView) {
            subscriptions = subscriptions.concat(annualExtras);
            otherExtras = monthlyExtras;
            otherExtrasTotal = (monthPrices && monthPrices.extras.total) || 0;

            if (yearPrices) {
                fees = yearPrices.fees;
                fees.total += yearPrices.extras.total;
            } else {
                fees = 0;
            }

        }

        if (otherExtrasTotal) {
            let durationTitle = null;

            if (this.props.durationType === 0 && this.props.duration === 12) {
                durationTitle = content.perMonth;
            }
            else if (this.props.durationType === 0 && this.props.duration === 1) {
                durationTitle = content.perYear;
            }
            else {
                durationTitle = content.perMonth;
            }

            otherExtrasNode.push(
                <div key={ 'pb_o_' + key } className='price-breakdown__section'>
                    <span className='price-breakdown__section-title'>
                        <span className="price-breakdown__section-title-duration">{ durationTitle }</span>
                        <span className="price-breakdown__section-title-price">Price</span>
                    </span>
                    { otherExtras }
                </div>
            );

            otherExtrasNode.push(
                <div key={ 'pb_oe_' + key } className='price-breakdown__section price-breakdown__section--sub-total'>
                    <div className='price-breakdown__line-item'>
                        <span className='price-breakdown__line-item-desc'>{ content.subTotal }</span>
                        <span className='price-breakdown__line-item-price'>{ utils.formatPrice(otherExtrasTotal) }</span>
                    </div>
                </div>
            );
        }

        if(isLessons) {
            for (i = 0; i < monthPrices.users.length; i++) {
                var lessonUser = monthPrices.users[i],
                    member = users[i];

                if(lessonUser.now.subscriptions && lessonUser.now.subscriptions.length) {
                    typeDesc = member.typeDesc;
                    idInType = member.idInType + 1;
                    firstName = (member.info.firstName && member.info.firstName.value);
                    lastName = (member.info.lastName && member.info.lastName.value);

                    if (users.length === 1 && firstName) {
                        userName = ((firstName && lastName) ? firstName + ' ' + lastName : (firstName ? firstName : ''));
                    } else {
                        userName = typeDesc + ' ' + idInType + ((firstName && lastName) ? ': ' + firstName + ' ' + lastName : (firstName ? ': ' + firstName : ''));
                    }

                    lessonFees.push(
                        <div className='price-breakdown__line-item price-breakdown__line-item--lessons' key={ 'lessons_fee_' + i }>
                            <span className='price-breakdown__line-item-desc price-breakdown__line-item-name'>{ userName }</span>
                        </div>
                    );

                    for(var x = 0; x < lessonUser.now.subscriptions.length; x++) {
                        lessonFees.push(
                            <div className='price-breakdown__line-item price-breakdown__line-item--lessons' key={ 'lessons_fee_' + i + '' + x }>
                                <span className='price-breakdown__line-item-desc'>{ lessonUser.now.subscriptions[x].desc.split(' - ')[1] }</span>
                                <span className='price-breakdown__line-item-price'>{ utils.formatPrice(lessonUser.now.subscriptions[x].amount) }</span>
                            </div>
                        );
                    }
                }
            }
        } else {
            if (fees) {
                if (fees.adminTotal) {
                    feesNode = (
                        <div className='price-breakdown__line-item'>
                            <span className='price-breakdown__line-item-desc'>{ content.adminFees }</span>
                            <span className='price-breakdown__line-item-price'>{ utils.formatPrice(fees.adminTotal) }</span>
                        </div>
                    );
                }

                if (fees.adminDiscounts) {
                    feesDiscountNode = (
                        <div className='price-breakdown__line-item'>
                            <span className='price-breakdown__line-item-desc'>{ content.adminFeesDiscount }</span>
                            <span className='price-breakdown__line-item-price'>{ utils.formatPrice(-fees.adminDiscounts) }</span>
                        </div>
                    );
                }

                if (fees.lastMonthsTotal) {
                    lastMonthNode = (
                        <div className='price-breakdown__line-item'>
                            <span className='price-breakdown__line-item-desc'>
                                {/* Double check there is a subscription available */}
                                { (subscription ? subscription.desc : '').match(/golf/gi) ? content.finalMonthInAdvanceGolf : content.finalMonthInAdvance }
                            </span>
                            <span className='price-breakdown__line-item-price'>{ utils.formatPrice(fees.lastMonthsTotal) }</span>
                        </div>
                    );
                }

                if (fees.other && fees.other.length) {
                    for (i = 0; i < fees.other.length; i++) {
                        otherFees.push(
                            <div className='price-breakdown__line-item' key={ 'other_fee_' + i }>
                                <span className='price-breakdown__line-item-desc'>{ fees.other[i].desc }</span>
                                <span className='price-breakdown__line-item-price'>{ utils.formatPrice(fees.other[i].amount) }</span>
                            </div>
                        );
                    }
                }
            }
        }

        var priceTitle = null;

        if (this.props.durationType === 0 && this.props.duration === 1) {
            priceTitle = content.perMonth;
        }
        else if (this.props.durationType === 0 && this.props.duration === 12) {
            priceTitle = content.perYear;
        }
        else if (this.props.durationType === 1) {
            priceTitle = content.shortMembershipCost;
        }

        let subscriptionsTotal = 0;

        if (monthlyView) {
            subscriptionsTotal = utils.formatPrice(monthlyTotal);
        }
        else if (annualView) {
            subscriptionsTotal = utils.formatPrice(annualTotal);
        }
        else if (shortView) {
            subscriptionsTotal = utils.formatPrice(annualTotal);
        }

        return (<div className={ 'sidebar-expander price-breakdown' + (this.props.expanded ? ' sidebar-expander--expanded' : ' sidebar-expander--collapsed') }>
            <Button key='price-breakdown-toggle' className='sidebar-expander__toggle' onClick={ this.props.toggleDetails } >{ content.viewCostBreakdown }<span className='sidebar-expander__icon' aria-label={ this.props.expanded ? 'collapse' : 'expand' }></span></Button>
            <div className='sidebar-expander__details price-breakdown__details'>
                <div className='inner'>
                    <div className='price-breakdown__section'>
                        <span className='price-breakdown__section-title'>
                            <span className="price-breakdown__section-title-duration">{ priceTitle }</span>
                            <span className="price-breakdown__section-title-price">Price</span>
                        </span>
                        { subscriptions }
                    </div>
                    <div className='price-breakdown__section price-breakdown__section--sub-total'>
                        <div className='price-breakdown__line-item'>
                            <span className='price-breakdown__line-item-desc'>{ content.subTotal }</span>
                            <span className='price-breakdown__line-item-price'>{ subscriptionsTotal}</span>
                        </div>
                    </div>
                    {!!fees && !!fees.total && <div>
                        { otherExtrasNode }
                        <div className='price-breakdown__section'>
                            <span className='price-breakdown__section-title'><span className="price-breakdown__section-title-duration">{ content.oneOffCosts }</span><span className="price-breakdown__section-title-price">Price</span></span>
                            { feesNode }
                            { feesDiscountNode }
                            { lastMonthNode }
                            { otherFees }
                            { lessonFees }
                        </div>
                        <div className='price-breakdown__section price-breakdown__section--sub-total'>
                            <div className='price-breakdown__line-item'>
                                <span className='price-breakdown__line-item-desc'>{ content.subTotal }</span>
                                <span className='price-breakdown__line-item-price'>
                                    {utils.formatPrice(getFeesTotal({ ...this.props, periods: this.props.price.periods }) || (fees && fees.total) || 0) || ''}
                                </span>
                            </div>
                        </div>
                    </div> }
                </div>
            </div>
        </div>);
    }
});

PriceBreakdown.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    duration: React.PropTypes.number,
    durationType: React.PropTypes.number,
    expanded: React.PropTypes.bool,
    price: React.PropTypes.object,
    toggleDetails: React.PropTypes.func,
    users: React.PropTypes.object
};

module.exports = connect()(PriceBreakdown);
