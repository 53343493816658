var React           = require('react'),
    connect         = require('react-redux').connect,
    Icons           = require('../common/icons'),
    utils           = require('../../utils/'),

    SelectedLesson;

SelectedLesson = React.createClass({
    render: function() {
        if(this.props.user.guardian) {
            return false;
        }

        var lessons = this.props.lessons,
            content = this.props.content,
            user = this.props.user,

            lessonType = utils.getLessonType(lessons),
            level = lessons.levelInfo[this.props.level],
            price = level.price && level.price !== 0 ? content.levelPrice.replace('[amount]', utils.formatPrice(level.price)) : null,
            priceDiscount = level.priceDiscount,
            color = level.color,

            typeDesc = user.typeDesc,
            idInType = user.idInType + 1,
            activity = user.lesson.selectedLessons[this.props.level].activity,
            firstName = (user.info.firstName && user.info.firstName.value),
            lastName = (user.info.lastName && user.info.lastName.value),
            userName,
            lessonTypeId;

        if (firstName) {
            userName = ((firstName && lastName) ? firstName + ' ' + lastName : firstName );
        } else {
            userName = typeDesc + ' ' + idInType;
        }

        if(level) {
            lessonTypeId = level.classes.lesson_type_id;

            if(lessonTypeId) {
                lessonType = utils.getLessonType(lessons, false, lessonTypeId);
            }
        }

        if(user.lesson.discountUser && priceDiscount !== 0) {
            price = content.levelPrice.replace('[amount]', utils.formatPrice(priceDiscount));
        }

        return (<div className='selected-lesson'>
            <div className='selected-lesson__header'>
                <div className='selected-lesson__header-icon'>
                    <Icons icon={lessonType} color={color} iconUrl={level.iconUrl}/>
                </div>
                <div className='selected-lesson__header-details'>
                    <div className='selected-lesson__header-name'>{ userName }:</div>
                    <h4>{ level.name } { (!this.props.noPrice ? <span>{ price }</span> : null) }</h4>
                </div>
            </div>
            <div className='selected-lesson__class'>
                <span className='selected-lesson__clock' style={{background: color}}>
                    <span className='selected-lesson__icon'></span>{ activity.time }
                    <span className='selected-lesson__name'>{ content.selectedClassDay.replace('[day]', activity.weekday) }</span>
                </span>
            </div>
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.lessons.selectedLesson,
        lessons: state.lessons
    };
}

module.exports = connect(mapStateToProps)(SelectedLesson);
