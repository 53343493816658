import React from "react";
import config from "../../../../config";
import ScriptCache from "../../../../utils/ScriptCache";

/**
 * @todo implement logic to "remove" markers.
 */
export default class GoogleMaps extends React.Component {
    constructor ( props ) {
        super( props );

        // Loaded Google Maps
        this.state = {
            map: null,
            markers: {},
            infoWindows: {},
            markerLibrary: null,

            // Load Google's API
            scriptCache: ScriptCache( {
                google: config.services.googleapis.mapscript
                    .replace( '{key}', config.services.googleapis.apikey )
            } )
        };
    }

    /**
     * Initialise maps
     * @todo refactor this at some point, that's call-back hell and unnecessary.
     * @todo remove global state dependency somehow (Google's API on window[])
     * @returns void
     */
    componentDidMount () {
        const self = this;

        // Update state appropriately when Google's API loads
        this.state.scriptCache.google.onLoad(() => {
            Promise.all([
                google.maps.importLibrary("marker")
            ]).then(([markerLibrary]) => {
                this.setState({
                    markerLibrary,
                    map: new google.maps.Map( document.getElementById( this.props.mapId ), {
                        center: this.props.defaultCentre || { lat: 0, lng: 0 },
                        zoom: this.props.zoom || 8, // 8 being landmass / continent - we don't want to bring in City details by default
                        mapId: "DEMO_MAP_ID",
                    } )
                }, () => {
                    // Once Google Maps have been initialised, we can focus on adding the markers from props
                    this.generateMarkers( this.props.markers );

                    // Setup event listeners callbacks
                    if ( this.props.hasOwnProperty( 'onCenterChange' ) ) {
                        this.state.map.addListener( 'center_changed', function () {
                            // Pass over center to callback
                            self.props.onCenterChange( this.getCenter() );
                        } );
                    };
                } );
            } );
        })
    }

    /**
     * Handles updating markers appropriately - this allows us to keep the same
     * zoom level on the map and add markers without re-rendering it
     * @param {object} previousProps Previous props based to this component
     */
    componentDidUpdate ( previousProps ) {
        const someDidUpdate = ( markers, previousMarkers ) => previousMarkers
            .some( ( marker, index ) => ( markers[ index ].title || null ) != marker.title );

        // Centres have updated
        if ( previousProps.markers.length != this.props.markers.length ||
            someDidUpdate( this.props.markers, previousProps.markers )
        ) {
            // Clear markers
            Object.values( this.state.markers ).forEach( marker => marker.setMap( null ) );

            this.state.scriptCache.google.onLoad( () => {
                this.generateMarkers( this.props.markers );
            } );
        }
    }

    /**
     * Generates map markers and eventually stores them into state
     * @param {array} markers Google Map Marker
     */
    generateMarkers(markers) {
        const loadMarkers = async () => {
            const markerMap = markers
                .map(marker => {

                    // Initialisation of Map Marker
                    const pin = new this.state.markerLibrary.PinElement({
                        background: `#${marker.pinColour}` || '#EA4335',
                        borderColor: `#${marker.pinColour}`,
                        glyphColor: '#000000',
                    });

                    const mapMarker = new this.state.markerLibrary.AdvancedMarkerElement({
                        map: this.state.map,
                        position: marker.position,
                        content: pin.element,
                    });

                    // Information window
                    const informationWindow = new google.maps.InfoWindow({
                        content: marker.content
                    });

                    // Add some listeners to work with contextual information against information window
                    mapMarker.addListener('click', () => {
                        // Collapse all other information windows
                        this.closeMarkers();

                        informationWindow.open(this.state.map, mapMarker);
                    });

                    return [
                        // We return the markers title along with the actual marker
                        [marker.title, mapMarker],

                        // We return the markers title along with the related information window
                        [marker.title, informationWindow]
                    ];
                });

            // Set state in one go
            this.setState({
                // "Markers" are composed of previous markers set and new Markers
                markers: {
                    ...this.state.markers,
                    ...Object.fromEntries(markerMap.map(([mapMarker, _]) => mapMarker))
                },

                infoWindows: {
                    ...this.state.infoWindows,
                    ...Object.fromEntries(markerMap.map(([_, infoWindow]) => infoWindow))
                }
            });
        };

        // Load markers
        loadMarkers();
    }

    /**
     * Close info window markers when another pin is closed
     */
    closeMarkers () {
        Object.values( this.state.infoWindows ).forEach( infoWindow => infoWindow.close() );
    }

    /**
     * Renders Google Maps from props
     * @returns {JSX}
     */
    render () {
        return (
            <div>
                { this.props.children }

                <div id={ this.props.mapId } style={ { height: this.props.mapHeight } }></div>
            </div>
        );
    }
}
