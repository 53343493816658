var React = require("react"),
    connect = require("react-redux").connect,
    Button = require("../../common/button"),
    DesktopStages;

DesktopStages = React.createClass({
    render: function render() {
        var content = this.props.content,
            stages = [],
            n = 0,
            classNames,
            props,
            stg,
            i;

        for (i = 0; i < this.props.flow.length; i++) {
            stg = this.props.steps[this.props.flow[i]];

            if (!stg || !stg.navBar) {
                continue;
            }

            n++;

            classNames = ["stage"];

            props = {
                "data-path": stg.path,
                "data-step": i,
                disabled: true,
                key: "stage_" + i,
                onClick: this.props.handleStageClick,
                tabIndex: -1
            };

            if (i === this.props.showStep) {
                classNames.push("stage--active");
            }

            if (i <= this.props.currentStep) {
                classNames.push("stage--complete");
                props.disabled = false;
                props.tabIndex = 0;
            }

            props.className = classNames.join(" ");

            stages.push(
                <Button key={"stages-" + i} {...props}>
                    <span className="stage__step stage__step--desktop">
                        {i + 1}.
                    </span>
                    <span className="stage__title">
                        {content["stage" + (this.props.flow[i] + 1)].title}
                    </span>
                </Button>
            );
        }

        return (
            <div className="stages-band">
                <nav
                    className={
                        "stages stages--active-" +
                        (this.props.currentStep + 1) +
                        " stages--" +
                        n
                    }
                >
                    {stages}
                </nav>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.stages,
        currentStep: state.stages.currentStep,
        flow: state.stages.flow,
        showStep: state.stages.showStep,
        steps: state.stages.steps
    };
}

module.exports = connect(mapStateToProps)(DesktopStages);
