var debug           = require('debug')('actions-faqs'),
    actionTypes     = require('./actionTypes.js'),
    httpFetch       = require('../utils/httpFetch'),

    config          = require('../config'),

    exports;

module.exports = exports = {

    discountSelected: function discountSelected(discount, isDeepLinkDiscount) {
        return {
            type: actionTypes.DISCOUNTS.DISCOUNT_SELECTED,
            discount: discount,
            isDeepLinkDiscount
        };
    },

    discountsReceived: function discountsReceived(discounts) {
        return {
            type: actionTypes.DISCOUNTS.DISCOUNTS_RECEIVED,
            discounts: discounts
        };
    },

    expandDiscount: function expandDiscount(expanded) {
        return {
            type: actionTypes.DISCOUNTS.EXPAND_DISCOUNT,
            expanded: expanded
        };
    },

    getDiscounts: function getDiscounts() {
        return function(dispatch) {
            return httpFetch.fetch(config.services.centreFinder.urls.getCorporates, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.discountsReceived(response));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    removeDiscount: function removeDiscount() {
        return function(dispatch, getState) {
            var selectedCentre = getState().centreFinder.selected;
            dispatch(exports.discountRemoved());
            let centreActions = require('./centreFinder.js');
            dispatch(centreActions.selectCentre(selectedCentre && selectedCentre.info.site_id));
        };
    },

    discountRemoved: function discountRemoved() {
        return {
            type: actionTypes.DISCOUNTS.DISCOUNT_REMOVED
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.DISCOUNTS.WIPEOUT
        };
    }
};
