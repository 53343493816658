var React = require('react'),
    connect = require('react-redux').connect,
    actions = require('../../actions'),

    Modal = require('../common/modal'),
    Button = require('../common/button');

ConfirmExtrasChange = React.createClass({
    displayName: 'ConfirmExtrasChange',

    onClose(e) {
        e.preventDefault();
        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    onConfirm(e) {
        e.preventDefault();
        const callback = this.props.options.callback;
        if (callback) {
            callback();
        }
        this.onClose(e);
    },

    render() {
        const { content, options } = this.props;
        const { unavailable } = options;

        return (
            <Modal className="modal--confirm modal--confirm-centre-reset" title={content.title}>
                <p dangerouslySetInnerHTML={{ __html: content.desc }}></p>

                {(!Object.keys(unavailable).length || !content.summary) ? '' : (<p>{content.summary}</p>)}
                <ul>
                    {Object.values(unavailable).sort(([extraA], [extraB]) => extraA.sortOrder - extraB.sortOrder).map(([extra, sub]) => (
                        <li id={`${extra.id} ${sub.id}`} title={sub.desc} key={extra.id}>
                            {extra.groupTitle}
                        </li>
                    ))}
                </ul>

                <div className="modal__buttons">
                    <Button className="button button--greyed-out" onClick={this.onClose}>
                        {content.buttonNo || content.buttonCancel || 'Close'}
                    </Button>
                    <Button className="button button--primed" onClick={this.onConfirm}>
                        {content.buttonYes}
                    </Button>
                </div>
            </Modal>
        );
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.confirmExtrasChange
    };
}

module.exports = connect(mapStateToProps)(ConfirmExtrasChange);
