import React from "react";
import { connect } from "react-redux";

// Components
import Button from "../../common/button";

class CentreDetailed extends React.Component {
    /**
     * Action centre button (select centre)
     * @return {JSX}
     */
    actionButtonCentre() {
        return this.smallScreens ? null : (
            <Button
                tabIndex={this.props.isExpanded ? 0 : -1}
                key={"csc" + this.props.centre.site_id}
                className="button button--primed centre-finder__results-details-button"
                onClick={this.props.onSelectCentre}
            >
                {this.props.content.buttonSelectCentre}
            </Button>
        );
    }

    moreTimes() {
        // Expand / collapse state
        // Invoke parent method for toggling information (@todo manage side effects!)
        this.props.showMoreTimes(this.props.centre.site_id);
    }

    render() {
        const { props } = this;
        const { centre } = props;

        if (!centre) {
            return null;
        }

        return (
            <div
                className={
                    "module__list-item-details module__list-item-details--centre-finder-" +
                    centre.site_id
                }
            >
                <div className="inner">
                    <div className="centre-finder__results-details-col--alt centre-finder__results-details-col--alt--stacked">
                        <h5>{props.locationTitle}</h5>
                        {/* Static image of where the centre is on Google maps */}
                        <img
                            className="centre-finder__results-details-map"
                            src={
                                centre.static_map_url ||
                                "/img/joinonline/staticMap.png"
                            }
                            alt={centre.name + " Location Map"}
                        />

                        {/* Address */}
                        <div>
                            <div className="centre-finder__results-details-address">
                                {centre.address}
                            </div>
                        </div>

                        {/* View on map button */}
                        <div>
                            <a
                                tabIndex={props.isExpanded ? 0 : -1}
                                className="centre-finder__results-details-link link--external"
                                target="_blank"
                                href={
                                    "http://www.google.com/maps/place/" +
                                    centre.lat +
                                    "," +
                                    centre.lon
                                }
                            >
                                {props.content.buttonViewOnMap}
                            </a>
                        </div>

                        {/* View centre page button */}
                        <div>
                            <a
                                tabIndex={props.isExpanded ? 0 : -1}
                                className="centre-finder__results-details-link link--external"
                                target="_blank"
                                href={centre.legacy_wordpress_url}
                            >
                                {props.content.buttonViewCentrePage}
                            </a>
                        </div>
                    </div>

                    {/* Facilities listing */}
                    <div className="centre-finder__results-details-col--alt centre-finder__results-details-col--alt--facilities">
                        <h5>{props.content.facilities}</h5>
                        <ul className="centre-finder__results-details-list">
                            {centre.highlighted_facilities.map(
                                (facilityName, index) => (
                                    <li
                                        key={"cf_" + index}
                                        className="centre-finder__results-details-list-item"
                                    >
                                        {facilityName}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>

                    {/* Opening times */}
                    <div className="centre-finder__results-details-col--alt">
                        <h5>{props.content.openingTimes}</h5>

                        {/* Today's information */}
                        {centre.opening_times?.today?.length > 1 && (
                            <div className="centre-finder__results-details-today">
                                {props.content.openToday}{' '}
                                <span>
                                    {centre.opening_times.today[0]}
                                    -
                                    {centre.opening_times.today[centre.opening_times.today.length - 1]}
                                </span>
                                <Button
                                    key={'ci' + centre.site_id}
                                    className="button__more-info centre-finder__results-details-times"
                                    onClick={this.moreTimes.bind(this)}
                                >
                                    &nbsp;
                                </Button>
                            </div>
                        )}

                        {/* Opening times for this centre */}
                        {Object.keys(centre.opening_times?.standard || {}).length &&
                            <div
                                className={
                                    "centre-finder__results-details-list-container centre-finder__results-details-list-container-" +
                                    centre.site_id
                                }
                            >
                                <ul className="centre-finder__results-details-list">
                                    {Object.entries(
                                        props.centre.opening_times.standard
                                    ).map(([day, [from, to]]) => (
                                        <li
                                            key={"co_" + day}
                                            className="centre-finder__results-details-list-item"
                                        >
                                            {day}{" "}
                                            <span>
                                                {from}-{to}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }

                        {props.isRestricted && this.actionButtonCentre()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // CMS fed text
        content: {
            ...state.app.content.stage1.centreDetails
        },

        // Restricted centre
        isRestricted: state.restrictions.restricted
    };
};

export default connect(mapStateToProps)(CentreDetailed);
