var React           = require('react'),
    connect         = require('react-redux').connect,
    Autosuggest     = require('react-autosuggest'),

    actions         = require('../../actions'),

    FindAddress;

FindAddress = React.createClass({
    displayName: 'FindAddress',

    componentWillMount: function componentWillMount() {
        this._delayedFetchRequested = window.debounce(this._delayedFetchRequested, 200);
    },

    getInitialState: function() {
        return {
            location: '',
            noMatch: false,
            matchesFound: false,
            suggestions: []
        };
    },

    _onGBGSuccess: function _onGBGSuccess(results) {
        if (results && results.length) {
            this.setState({ suggestions: results });

        } else {
            this.setState({
                noMatch: true,
                matchesFound: false,
                suggestions: []
            });
        }

        this.props.dispatch(actions.personalDetails.addressSuggestionsLoading(false));
    },

    // Since suggestions are the result of an API fetch, we simply return
    //      them all to the autosuggest plugin.
    getSuggestions: function getSuggestions() {
        return this.props.results;
    },

    onChange: function onChange(event, data) {
        if (this.state.location !== data.newValue) {
            this.setState({
                location: data.newValue || ''
            });
        }
    },

    _delayedFetchRequested: function _delayedFetchRequested(originalValue) {
        var value = originalValue;

        if (originalValue && originalValue.length >= 3) {
            /*if ('uk' !== originalValue.substr(-2).toLowerCase()) {
                value += ', UK';
            }*/

            this.props.dispatch(actions.personalDetails.addressSuggestionsLoading(true));
            this.props.dispatch(actions.personalDetails.addressSearch(this.props.uid, value));
            this.setState({ location: originalValue });

        } else {
            this.props.dispatch(actions.personalDetails.addressSuggestionsLoading(false));

            this.setState({
                location: originalValue || '',
                matchesFound: false,
                noMatch: false
            });
        }
    },

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    _onSuggestionsFetchRequested: function _onSuggestionsFetchRequested(data) {
        var postcodeRule = /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/ig;

        if (postcodeRule.test(data.value)) {
            this._delayedFetchRequested(data.value);
        }
    },

    // Autosuggest will call this function every time you need to clear suggestions.
    _onSuggestionsClearRequested: function _onSuggestionsClearRequested() {
        this.props.dispatch(actions.centreFinder.resetGeolocResults());
    },

    // This function is called when suggestion is selected with tap/click, or 'enter'
    _onSuggestionSelected: function _onSuggestionSelected(event, data) {
        if (data.suggestionValue) {
            this.props.dispatch(actions.personalDetails.addressSuggestionSelected(this.props.uid, data.suggestionValue));
        }
    },

    // When suggestion is clicked, Autosuggest needs to populate the input field
    // based on the clicked suggestion.
    _getSuggestionValue: function _getSuggestionValue(suggestion) {
        return suggestion;
    },

    _renderSuggestion: function _renderSuggestion(suggestion) {

        var cleanSuggestion = suggestion.split(',');

        cleanSuggestion = cleanSuggestion.filter(Boolean);

        return (
            <span>{ cleanSuggestion.join(', ') }</span>
        );
    },

    render: function() {
        var resultClass = '',
            content = this.props.content;

        if (this.state.noMatch) {
            resultClass = 'autosuggest__container--nomatch';
        }

        if (this.state.matchesFound) {
            resultClass = 'autosuggest__container--matches';
        }

        // Autosuggest will pass through all these props to the input field.
        var inputProps = {
                id: this.props.id,
                placeholder: content.placeholder,
                value: this.state.location,
                onChange: this.onChange
            },
            theme = {
                container:            'autosuggest__container ' + resultClass,
                containerOpen:        'autosuggest__container--open',
                input:                'autosuggest__input forms__input',
                suggestionsContainer: 'autosuggest__suggestions-container',
                suggestionsList:      'autosuggest__suggestions-list',
                suggestion:           'autosuggest__suggestion',
                suggestionFocused:    'autosuggest__suggestion--focused',
                sectionContainer:     'autosuggest__section-container',
                sectionTitle:         'autosuggest__section-title'
            },
            loading = this.props.loading;

        return <div style={ { position: 'relative' } }>
            <Autosuggest
                suggestions={ this.props.suggestions }
                onSuggestionsFetchRequested={ this._onSuggestionsFetchRequested }
                onSuggestionsClearRequested={ this._onSuggestionsClearRequested }
                onSuggestionSelected={ this._onSuggestionSelected }
                getSuggestionValue={ this._getSuggestionValue }
                renderSuggestion={ this._renderSuggestion }
                inputProps={ inputProps }
                theme={ theme } />
            <ul style={ { display: (loading ? 'block' : 'none') } } className='loader__spinner loader__spinner--small'>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>;
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.stage3.userForm.fields.addressSearch,
        loading: state.personalDetails.addressSuggestionsLoading
    };
}

module.exports = connect(mapStateToProps)(FindAddress);
