import React from "react";
import { connect } from "react-redux";

// Configuration
import config from "../../../config";

// Redux actions
import actions from "../../../actions";

// Components
import CentreSummary from "./centre-summary";
import CentreDetailed from "../centreFinder/centre-detailed";
import Button from "../../common/button";
import GoogleMaps from "../../common/google/maps/map";

// Side effects & view mutations
import viewUtils from "../../../utils/viewUtils";
import GoogleMapMarkerInfoWindow from "../../common/google/maps/google-map-marker-info-window";
import SiteBandingGoogleMapMarkerInfoWindow from "./google-map-marker-info-window";
import { Legend } from "../../common/google/maps/legend";
import { LegendIcon } from "../../common/google/maps/legend-icon";

// Scroll into view
import scrollIntoView from "scroll-into-view-if-needed";
import httpFetch from "../../../utils/httpFetch";
import { push } from "../../common/google/analytics/datalayer";

class BandedCentresMapViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // All centres for map markers
            allCentres: [],

            // Used to dispatch the "centre finder" action to draw in centres
            // By default we will be looking at the searched location or the selected centres location (if deep linked is used)
            findCentreDispatchActions: [],

            // The focused centre used to calculate other centres around
            // By default it'll be the user's searched location or selected centre until it's overriden by the map
            focusCentred: {
                lat:
                    this.props.userSearchedLocation.lat ||
                    this.props.selectedCentre.lat,
                lng:
                    this.props.userSearchedLocation.lng ||
                    this.props.selectedCentre.lon
            },

            currentlyExpandedCentreId: null,

            // Google Maps API specific - we always want the selected centre to be shown
            // i.e. "default" centre for this component
            defaultCentre: { lat: 0, lng: 0, info: null },
            markers: []
        };
    }

    /**
     * Update state for changes to Google Maps
     */
    componentDidMount() {
        scrollIntoView(document.getElementById("site-banding-viewer"), {
            block: "center",
            inline: "center"
        });

        // Fetch entirety of centres
        httpFetch
            .fetch(config.services.centreFinder.urls.find, {
                method: "GET",
                params: {
                    // All centres
                    per_page: Number.MAX_SAFE_INTEGER,

                    // Location to search with
                    // By default we used the selected centre's location
                    lat: this.props.selectedCentre.lat,
                    lon: this.props.selectedCentre.lon,

                    // Centres to be visible on
                    // @todo the UI really shouldn't be telling the back-end of this. It's not responsible for driving that business behaviour (knows too much etc)
                    visibility: 'join',

                    // This will be used to calculate the distance against this lat lng
                    // By default we use the selected centres location (deep linked)
                    distanceFromLat:
                        this.props.userSearchedLocation.lat ||
                        this.props.selectedCentre.lat,
                    distanceFromLon:
                        this.props.userSearchedLocation.lng ||
                        this.props.selectedCentre.lon,

                    // Always include these centres in the search result ("stinky centres")
                    alwaysInclude: [this.props.selectedCentre.site_id]
                },
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            // .then( response => response.json )
            .then(response => response.data)
            .then(centres => {
                // Update state to reflect all centres
                // Update state (markers) once done
                this.setState(
                    {
                        allCentres: centres
                    },
                    this.updateCentresState
                );
            });

        // Ensure initial map state
        // Yes, the map markers won't appear but they will do once fetch() returns
        this.updateCentresState();
    }

    /**
     * Handles updating props scenario - this will be used when transforming local state
     * @param {object} previousProps Previous props based to this component
     */
    componentDidUpdate(previousProps) {
        const someDidUpdate = (centres, previousCentres) =>
            previousCentres.some(
                (centre, index) =>
                    (centres[index].site_id || null) != centre.site_id
            );

        // Centres have updated
        if (
            previousProps.centres.length != this.props.centres.length ||
            someDidUpdate(this.props.centres, previousProps.centres)
        ) {
            this.updateCentresState();
        }
    }

    /**
     * This is the source of truth for how the Maps look and feel, so we update it here
     * @returns void
     */
    updateCentresState() {
        /**
         * Determine banding colour for marker
         * @param {bool} isSelected
         * @param {number} centerBand
         * @param {number} selectedCentreBand
         * @param {mixed} upgradeBand
         */
        const determineBandingForMarker = (
            isSelected,
            centreBand,
            selectedCentreBand,
            upgradeBand
        ) => {
            // Already selected in membership
            if (isSelected) {
                return config.services.googleapis.markericon.sameCentreColour
            }

            // If this centre is within an upgrade band, it's an orange marker
            // We say it's "orange" if the centre's band sits between the selected band and the ugprade band

            // console.log('upgradeBand', upgradeBand);
            // console.log('centreBand', centreBand);
            // console.log('selectedCentreBand', selectedCentreBand);
            
            if (
                upgradeBand &&
                centreBand &&
                centreBand > selectedCentreBand &&
                centreBand <= upgradeBand
            ) {
                return config.services.googleapis.markericon.includedInUpgradeColour
            }

            // Compare selected centre's band to this centre's band
            return (centreBand)
                ? (centreBand > selectedCentreBand
                    ? config.services.googleapis.markericon.notIncludedColour
                    : config.services.googleapis.markericon.includedColour
                )
                : config.services.googleapis.markericon.notIncludedColour

        };

        this.setState({
            // Default to the currently selected centre's location
            defaultCentre: {
                lat: this.props.selectedCentre.lat,
                lng: this.props.selectedCentre.lon,

                info: this.state.defaultCentre.info
                    ? this.state.defaultCentre.info
                    : this.props.centres.find(
                          centre =>
                              centre.site_id ===
                              this.props.selectedCentre.site_id
                      )
            },

            // Map centre details to Google Maps consumption (React component)
            markers: this.state.allCentres.map(centre => ({
                position: {
                    lat: centre.lat,
                    lng: centre.lon
                },
                title: centre.name,
                pinColour: determineBandingForMarker(
                    this.props.selectedCentre.site_id == centre.site_id,
                    centre.banding,
                    this.props.selectedCentre.banding,
                    this.props.showUpgradesUntil
                ),

                // Content will be purely for the Marker information
                content: GoogleMapMarkerInfoWindow({
                    heading: centre.name,
                    body: SiteBandingGoogleMapMarkerInfoWindow(
                        centre,
                        this.props.selectedCentre.banding,
                        this.props.showUpgradesUntil,
                        this.props.content,
                        this.props.showChangeCentreButton == false
                            ? false
                            : true
                    )
                })
            }))
        });
    }

    /**
     * Dispatches action to update centre listing (paginated)
     * @param {ClickEvent} e Mouse click event
     */
    loadMore(e) {
        e.preventDefault();

        this.props.dispatch(
            actions.centreFinder.findCentres({
                page: this.props.centreFinder.currentPage + 1,
                lat: this.state.focusCentred.lat,
                lng: this.state.focusCentred.lng,

                // This will be used to calculate the distance against this lat lng
                distanceFromLat:
                    this.props.userSearchedLocation.lat ||
                    this.props.selectedCentre.lat,
                distanceFromLon:
                    this.props.userSearchedLocation.lng ||
                    this.props.selectedCentre.lon,

                // Always include these centres in the search result ("stinky centres")
                alwaysInclude: [this.props.selectedCentre.site_id]
            })
        );
    }

    /**
     * Refreshes listings based on map centre location
     * Action is derived from the continuously updated "findCentreDispatchActions" state
     */
    refreshListingsForMap() {
        this.state.findCentreDispatchActions.forEach(this.props.dispatch);
    }

    /**
     * Handles expanding / collapsing detailed information for centre
     * @todo refactor this, we shouldn't have side effects like this (see existing CentreFinder code)
     * @todo refactor, very costly
     * @param {string} centreID  The id of the centre / site
     * @return void
     */
    toggleDetailedCentreInformation(centreId) {
        /**
         * Element to be toggled (collapse / expand)
         * @param {string} centreId
         */
        const find = centreId =>
            document.querySelector(
                `.module__list-item-details--centre-finder-${centreId}`
            );

        // If the element being "expanded" has already been expanded
        if (this.state.currentlyExpandedCentreId === centreId) {
            this.setState({ currentlyExpandedCentreId: null });
            viewUtils.furl(find(this.state.currentlyExpandedCentreId));
        } else {
            // Collapse the old element
            viewUtils.furl(find(this.state.currentlyExpandedCentreId));

            // Expand the new element
            viewUtils.furl(find(centreId));

            // Set currently "expanded" centre
            this.setState({ currentlyExpandedCentreId: centreId });
        }
    }

    /**
     * Shows more times
     * Derived from centreResults.js
     * @param {string} centreId Centre id in question
     */
    showMoreTimes(centreId) {
        const parent = document.querySelector(
            `.module__list-item-details--centre-finder-${centreId}`
        );
        const child = document.querySelector(
            `.centre-finder__results-details-list-container-${centreId}`
        );

        if (child.classList.contains("button__more-info--expanded")) {
            child.classList.remove("button__more-info--expanded");
        } else {
            child.classList.add("button__more-info--expanded");
        }

        viewUtils.furl(child, parent);
    }

    /**
     * Mutate state for showing the map
     * @return void
     */
    showHideMap() {
        this.props.dispatch(
            actions.siteBanding.toggleMap(!this.props.isMapShown)
        );
        setTimeout(function showHideMapEvent() {
            push( {
                event: "virtual pageview",
                virtualPagePath: "/virtual/memberships-options-maps/",
                virtualPageTitle: "Join - Everyone Active – Maps",
            } )
        });
    }

    /**
     * Dispatches new action to fetch additional centres when centre changes
     * @param {object} center Centre information from google maps
     */
    locationCenterChanged(center) {
        this.setState({
            findCentreDispatchActions: [
                actions.centreFinder.findCentres({
                    lat: center.lat(),
                    lng: center.lng(),

                    // This will be used to calculate the distance against this lat lng
                    distanceFromLat:
                        this.props.userSearchedLocation.lat ||
                        this.props.selectedCentre.lat,
                    distanceFromLon:
                        this.props.userSearchedLocation.lng ||
                        this.props.selectedCentre.lon,

                    // Always include these centres in the search result ("stinky centres")
                    alwaysInclude: [this.props.selectedCentre.site_id]
                })
            ],

            // Store focused centre point on map
            // Used to calculate centres around map as opposed to searched location or selected centre's location
            focusCentred: {
                lat: center.lat(),
                lng: center.lng()
            }
        });
    }

    /**
     * Renders map
     * @return {JSX}
     */
    googleMaps() {
        // Potential ugprades
        // Scenario, user selected band 1 centre, but with an add-on could access band 4 centres
        // This constant will hold that value
        const potentialUpgrade = this.props.showUpgradesUntil;

        return (
            // Property to determine if the map is to be shown
            this.props.isMapShown ? (
                [
                    // The legend
                    <Legend key="map-legend">
                        <LegendIcon
                            key="this-centre-map-marker"
                            text={this.props.content.buttonSelected}
                            colour={config.services.googleapis.markericon.sameCentreColour}
                        ></LegendIcon>

                        <LegendIcon
                            key="included-map-marker"
                            text={this.props.content.included}
                            colour={config.services.googleapis.markericon.includedColour}
                        ></LegendIcon>

                        {potentialUpgrade ? (
                            <LegendIcon
                                key="upgradable-map-marker"
                                text={this.props.content.upgradable}
                                colour={config.services.googleapis.markericon.includedInUpgradeColour}
                            ></LegendIcon>
                        ) : null}

                        <LegendIcon
                            key="not-included-map-marker"
                            text={this.props.content.notIncluded}
                            colour={config.services.googleapis.markericon.notIncludedColour}
                        ></LegendIcon>
                    </Legend>,

                    !Boolean(this.state.markers.length) && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: '50%',
                            left: '50%',
                            padding: '20px',
                            background: '#fff',
                            height: '400px',
                            textAlign: 'center',
                        }}>Loading Map...</div>
                    ),

                    Boolean(this.state.markers.length) && (
                        <GoogleMaps
                            key={`map-${this.props.isMapShown}`}
                            onCenterChange={this.locationCenterChanged.bind(this)}
                            mapId="google_maps-site-banding"
                            mapHeight="400px"
                            defaultCentre={this.state.defaultCentre}
                            markers={this.state.markers}
                            zoom={10}
                            content={this.props.content}
                        />
                    ),

                    // If this is the last page, do not render a "load more" button
                    this.props.lastPage ? null : (
                        <div
                            key="map-refresh-listing"
                            className="centre-finder__results-load-more"
                        >
                            <Button
                                key="button_load_more"
                                className="button__load-more centre-finder__results-load-more__button"
                                onClick={this.refreshListingsForMap.bind(this)}
                            >
                                <span>{this.props.content.refresh}</span>
                            </Button>
                        </div>
                    )
                ] // We always want the legend shown
            ) : (
                <div>
                    <Legend key="map-legend">
                        <LegendIcon
                            key="this-centre-map-marker"
                            text={this.props.content.buttonSelected}
                            colour={config.services.googleapis.markericon.sameCentreColour}
                        ></LegendIcon>

                        <LegendIcon
                            key="included-map-marker"
                            text={this.props.content.included}
                            colour={config.services.googleapis.markericon.includedColour}
                        ></LegendIcon>

                        {potentialUpgrade ? (
                            <LegendIcon
                                key="ugpradable-map-marker"
                                text={this.props.content.upgradable}
                                colour={config.services.googleapis.markericon.includedInUpgradeColour}
                            ></LegendIcon>
                        ) : null}

                        <LegendIcon
                            key="not-included-map-marker"
                            text={this.props.content.notIncluded}
                            colour={config.services.googleapis.markericon.notIncludedColour}
                        ></LegendIcon>
                    </Legend>
                    <hr />
                </div>
            )
        );
    }

    /**
     * Refreshes listings based on map centre location
     * Action is derived from the continuously updated "findCentreDispatchActions" state
     */
    refreshListingsForMap() {
        this.state.findCentreDispatchActions.forEach(this.props.dispatch);
    }

    render() {
        if (!this.props.centres?.length) {
            return null;
        }

        return (
            <div
                className="module module--site-banding"
                id="site-banding-viewer"
            >
                <div className="site-banding-panel__help site-banding-panel__help-item-map">
                    <p>{this.props.content.seeWhatsIncluded}</p>
                    <Button
                        key="google-maps-site-banding"
                        className="button"
                        onClick={this.showHideMap.bind(this)}
                    >
                        {this.props.isMapShown ? "Hide" : "Show"} map
                    </Button>
                </div>
                {/* Render map for bigger screens */}
                {this.googleMaps()}

                <ul
                    className="module__list centre-module-list_site_banding"
                    id="centre-module-list_site_banding"
                >
                    {/* Additional centres */}
                    {this.props.centres.map(centre => (
                        <li
                            key={"centre-finder-banding-" + centre.site_id}
                            className="centre-finder__results-list-item module__list-item module__list-item--map"
                        >
                            <CentreSummary
                                showChangeCentreButton={
                                    this.props.showChangeCentreButton == false
                                        ? false
                                        : true
                                }
                                showUpgradesUntil={this.props.showUpgradesUntil}
                                showUpgradeHeadlinePrices={
                                    this.props.showUpgradeHeadlinePrices ==
                                    false
                                        ? false
                                        : true
                                }
                                distanceProp={"distance_from"}
                                key={`summary-${centre.site_id}`}
                                isExpanded={
                                    centre.site_id ==
                                    this.state.currentlyExpandedCentreId
                                }
                                centre={centre}
                                isSelected={
                                    centre.site_id ==
                                    this.props.selectedCentre.site_id
                                }
                                selectedCentreBanding={
                                    this.props.selectedCentre.banding
                                }
                                toggleDetailedCentreInformation={this.toggleDetailedCentreInformation.bind(
                                    this
                                )}
                            />
                            <CentreDetailed
                                key={`detailed-${centre.site_id}`}
                                centre={centre}
                                showMoreTimes={this.showMoreTimes.bind(this)}
                            />
                        </li>
                    ))}
                </ul>
                {/* If this is the last page, do not render a "load more" button */}
                {this.props.lastPage ? null : (
                    <div className="centre-finder__results-load-more">
                        <Button
                            key="button_load_more"
                            className="button__load-more centre-finder__results-load-more__button"
                            onClick={this.loadMore.bind(this)}
                        >
                            <span>{this.props.content.buttonLoadMore}</span>
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // Breakpoints (small screen detection)
        breakpoint: state.app.breakpoint,

        centres: state.centreFinder.results,
        lastPage: state.centreFinder.lastPage == state.centreFinder.currentPage,
        centreFinder: state.centreFinder,

        // CMS fed text
        content: {
            ...state.app.content.stage1.centreResults,
            ...state.app.content.stage1.centreDetails,
            ...state.app.content.siteBanding
        },

        // Search location - this will be the postcode the user enters
        // We will use this to calculate the distance from our newly created centres
        userSearchedLocation: state.centreFinder.location,

        // Is the map currently visible?
        isMapShown: state.siteBanding.mapIsVisible
    };
}

export default connect(mapStateToProps)(BandedCentresMapViewer);
