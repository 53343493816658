var React       = require('react'),
    connect     = require('react-redux').connect,
    actions     = require('../../actions'),

    Modal       = require('../common/modal'),
    Button      = require('../common/button'),

    ConfirmLevel;

ConfirmLevel = React.createClass({
    onClose: function onClose(e) {
        e.preventDefault();

        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    onConfirm: function onConfirm(e) {
        e.preventDefault();
        var callback = this.props.options.callback;

        callback && callback();
        this.props.dispatch(actions.app.hideModal(this.props.id));
    },

    render: function() {
        var content = this.props.content;

        return (<Modal title={ content.title } className='modal--confirm modal--confirm-centre-reset'>
            <p dangerouslySetInnerHTML={ {__html: content.desc } }></p>
            <div className='modal__buttons'>
                <Button className='button button--greyed-out' onClick={ this.onClose }>{ content.buttonCancel }</Button>
                <Button className='button button--primed' onClick={ this.onConfirm }>{ content.buttonYes }</Button>
            </div>
        </Modal>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.confirmLevel
    };
}

module.exports = connect(mapStateToProps)(ConfirmLevel);
