var LOCATION_CHANGE = require('react-router-redux').LOCATION_CHANGE,
    initialState = {
        locationBeforeTransitions: null
    };

module.exports = function (state, action) {

    state = state || initialState;

    if (action.type === LOCATION_CHANGE) {
        return Object.assign({}, state, {
            locationBeforeTransitions: action.payload
        });
    }

    return state;
};
