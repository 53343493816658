var debug           = require('debug')('actions-testimonials'),
    actionTypes     = require('./actionTypes.js'),
    httpFetch       = require('../utils/httpFetch'),

    config          = require('../config'),

    exports;

module.exports = exports = {

    resultsReceived: function resultsReceived(response) {
        return {
            type: actionTypes.TESTIMONIALS.RESULTS_RECEIVED,
            results: response.data
        };
    },

    fetchTestimonials: function fetchTestimonials(tags) {

        var params = {
            'per_page': 1,
            tags: tags
        };

        return function(dispatch) {
            return httpFetch.fetch(config.services.testimonials.urls.get, {
                method: 'GET',
                params: params,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.resultsReceived(response));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.TESTIMONIALS.WIPEOUT
        };
    }
};
