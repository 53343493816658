var React           = require('react'),
    connect         = require('react-redux').connect,
    Slider          = require('react-slick').default,
    isMatch         = require('lodash').isMatch,

    Input;

Input = React.createClass({
    displayName: 'Input',

    componentWillMount: function componentWillMount() {
        if (!this.props.input.onChange) {
            this.props.input.onChange = function () {}; // no-op
        }

        // If we don't have an onBlur, use the onchange.
        // Lower down this class, the onBlur is only attached to some input types.
        if (!this.props.input.onBlur) {
            this.props.input.onBlur = this.props.input.onChange;
        }

        this.originalOnChange = (function() { return this.props.input.onChange; }.bind(this))();
        this.originalOnBlur = (function() { return this.props.input.onBlur; }.bind(this))();

        if ('text' === this.props.type ||
               'tel' === this.props.type ||
               'email' === this.props.type ||
               'postcode' === this.props.type ||
               'password' === this.props.type ||
               'bankAccount' === this.props.type ||
               'sortCode' === this.props.type ||
               'radio' === this.props.type ||
               'select' === this.props.type ||
               'checkbox' === this.props.type
           ) {

            this.props.input.onChange = this._onChangeInput;
            this.props.input.onBlur = this._onBlurInput;

            this._onChangeDebounced = window.debounce(function (value) {
                this._delayedOnChange.apply(this, [value]);
            }, 200);
        }
    },

    originalOnChange: function () {},
    originalOnBlur: function () {},

    shouldComponentUpdate: function shouldComponentUpdate(nextProps) {
        return !isMatch(this.props, nextProps);
    },

    _onBlurInput: function _onChangeInput(e) {
        var val;

        if ('checkbox' === this.props.type) {
            this.originalOnBlur(e.target.checked);

        } else {
            if ('select' === this.props.type) {
                val = e.target.value ? e.target.value : null;

            } else {
                val = e.target.value;
            }

            this.originalOnBlur(val);
        }
    },

    _onChangeInput: function _onChangeInput(e) {
        var val;

        if ('checkbox' === this.props.type) {

            if (e.target.checked !== this.props.value) {

                if (this._onChangeDebounced) {
                    this._onChangeDebounced(e.target.checked);

                } else {
                    this.originalOnChange(e.target.checked);
                }
            }

        } else {
            if ('select' === this.props.type) {
                val = e.target.value ? e.target.value : null;
            } else {
                val = e.target.value;
            }

            if (val !== this.props.value) {

                if (this._onChangeDebounced) {
                    this._onChangeDebounced(val);

                } else {
                    this.originalOnChange(val);
                }
            }
        }
    },

    _delayedOnChange: function _delayedOnChange(value) {
        this.originalOnChange(value);
    },

    render: function render() {
        var props = this.props,
            input = props.input || {},
            label = props.label || {},
            inputProps = {
                className: input.className || '',
                name: props.name
            },
            labelProps = {
                className: label.className || '',
                htmlFor: props.name
            },
            radioContent,
            classes = [],
            options = [],
            labelLinks = [],

            validityMessage,
            validityMessageText,
            validNode,
            labelText,
            labelElt,
            inputElt,
            subtitle,
            inputId,
            option,
            key,
            id,
            i;

        if (props.className) {
            classes = classes.concat(props.className.split(' '));
        }

        if (!props.disableHints) {
            classes.push('input--hints');
        }

        if (props.input.disabled) {
            classes.push('input--disabled');
        }

        if (props.isHidden) {
            classes.push('input--hidden');
        }

        if(props.valid === null) {
            validNode = <span className='form__input-validity-icon'></span>;
        }
        else if (props.valid !== undefined && !props.valid) {
            classes.push('input--invalid');
            validNode = <span className='form__input-validity-icon'></span>;
            // The validationErrors are not passed in the input from
            //  the form right now, so you will need to add a propType for it
            //  and pass them from the user form or any other form that includes
            //  an <Input />.
            // Based on the content, you "only" have to select the right error
            //  message and place in in here.
            // If this is an email field or a telephone field (also emmergency)
            //  then the validation may or may not have the results from the
            //  GBG validation. If it does, the vlaidationErrors property is
            //  likely to be either null, or an empty ARRAY.
            // In this case, you'll use a generic message.
            // In other cases of validation, the error keys possible come from
            //  the fieldValidator component, and the validationErrors property
            //  is an OBJECT.
            validityMessageText = props.validityMessage || 'This field is invalid';
            validityMessage = <span className='form__input-validity-message'>{validityMessageText}</span>;

        } else if (props.valid && props.valid !== 'ignore' && props.value) {
            classes.push('input--valid');
            validNode = <span className='form__input-validity-icon'></span>;
        }

        for (key in label) {
            if ('text' === key || 'accountNumber' === key || 'sortCode' === key) {
                labelText = label[key];
            } else if ('links' === key) {
                var linkDivider = '';

                for (i = 0; i < label[key].length; i++) {
                    if(i > 0) {
                        linkDivider = ' and  ';
                    }

                    labelLinks.push(<span key={ props.name + '_links_' + i }>
                        { linkDivider }
                        <a href={label[key][i]['link']} className='personal-details__link' target='_blank'>{label[key][i]['linkText']}</a>
                    </span>);
                }
            } else {
                if(!label[key] !== null && label[key] !== undefined) {
                    labelProps[key] = label[key];
                }
            }
        }

        if(props.subtitle) {
            subtitle = <p className='personal-details__subtitle'>{ props.subtitle }</p>;
        }

        if ('radio' === props.type) {
            labelElt = <label { ...labelProps }><span></span>{ labelText } { labelLinks } </label>;

            for (i = 0; i < props.options.length; i++) {
                var labelClass = props.radioLabelClassName;
                option = props.options[i];
                id = props.name + '_' + option.value ;

                inputProps.id = id;
                inputProps.required = input.required;
                inputProps.type = props.type;
                inputProps.key = 'radio_' + id;
                inputProps.defaultValue = option.value;
                inputProps.checked = (option.value === props.value);
                inputProps.onChange = this._onChangeInput;

                if(props.highlighted && props.highlighted === option.value) {
                    inputId = 'highlighted-input';
                    radioContent = <div>
                        <span></span>
                        <div className='input-text'>{ option.text }</div>
                        <div className='badge--recommended'>Recommended</div>
                    </div>;
                } else {
                    radioContent = <div>
                        <span></span>
                        { option.text }
                    </div>;
                }

                if(props.slider) {
                    options.push(<div key={'option_ ' + i}>
                        <input { ...inputProps } />
                        <label key={ 'label_' + id } className={ labelClass } htmlFor={ id } id={ inputId }>
                            { radioContent }
                            { option.content || null }
                        </label>
                    </div>);
                } else {
                    options.push(<input { ...inputProps } />);
                    options.push(<label key={ 'label_' + id } className={ labelClass } htmlFor={ id } id={ inputId }>
                            { radioContent }
                            { option.content || null }
                        </label>);
                }
            }

            if(props.slider) {
                var settings = {
                    dots: false,
                    infinite: false,
                    initialSlide: props.slideIndex || 0,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    touchMove: false,
                    vertical: true
                };

                inputElt = <Slider {...settings}>
                        { options }
                    </Slider>;
            } else {
                inputElt = options;
            }

        } else {
            inputProps.id = props.name;

            for (key in input) {
                if (!input[key] !== null && input[key] !== undefined) {
                    if (key === 'onChange') {
                        inputProps[key] = this._onChangeInput;

                    } else if (key === 'onBlur') {
                        inputProps[key] = this._onBlurInput;

                    } else {
                        inputProps[key] = input[key];
                    }
                }
            }

            labelElt = <label { ...labelProps }>{ labelText }</label>;

            if ('select' === props.type) {
                inputProps.value = props.value || '';

                if(props.placeholder) {
                    options.push(<option key={ 'o_d' } value="0">{ props.placeholder }</option>);
                }

                for (i = 0; i < props.options.length; i++) {
                    options.push(<option key={ 'o_' + i } value={ props.options[i].value || '' } disabled={ props.options[i].disabled ? 'disabled' : null}>{ props.options[i].text }</option>);
                }

                inputElt = <div><span className='select__wrapper'><select { ...inputProps }>{ options }</select></span>{ validNode }{ validityMessage }</div>;

            } else if ('checkbox' === props.type) {
                inputProps.checked = props.value || false;
                inputElt = <input type={ props.type } { ...inputProps } />;

                labelElt = <label { ...labelProps }><span></span>{ labelText } { labelLinks } </label>;

            } else {
                if (props.type === 'email') {
                    inputProps.type = 'text';
                } else if (props.type === 'postcode') {
                    inputProps.type = 'text';
                } else if ('bankAccount' === props.type || 'sortCode' === props.type) {
                    inputProps.type = 'text';
                } else {
                    inputProps.type = props.type;
                }

                if (props.maxLength) {
                    inputProps.maxLength = props.maxLength;
                }

                inputProps.defaultValue = props.value;

                if (props.autocompleteOff) {
                    inputProps['autoComplete'] = 'off';
                }

                if (props.noPaste) {
                    inputProps.onPaste = e => e.preventDefault();
                }

                inputElt = <div><input { ...inputProps } />{ validNode }{ validityMessage }</div>;
            }
        }

        if (this.props.inputFirst) {
            return (<div className={ classes.join(' ') }>
                <div className='input'>
                    { inputElt }
                    { labelElt }
                </div>
            </div>);

        } else {
            return (<div className={ classes.join(' ') }>
                <div className='input'>
                    { labelElt }
                    { subtitle }
                    { inputElt }
                </div>
            </div>);
        }
    }
});

Input.propTypes = {
    className: React.PropTypes.string,
    id: React.PropTypes.string,
    inputFirst: React.PropTypes.bool,
    name: React.PropTypes.string,
    onChange: React.PropTypes.func,
    placeholder: React.PropTypes.string,
    noPaste: React.PropTypes.bool,
};

module.exports = connect()(Input);
