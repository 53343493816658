var debug       = require('debug')('actions-staffLogin'),
    actionTypes = require('./actionTypes.js'),
    httpFetch   = require('../utils/httpFetch'),
    config      = require('../config');

module.exports = {
    logout: function logout() {
        return function (dispatch) {
            dispatch({
                type: actionTypes.STAFF.STORE,
                id: null,
                name: null
            });

            return httpFetch.fetch(config.services.staff.urls.logout, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function () {
                return true;
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    showLogin: function showLogin(value) {
        return function (dispatch) {
            dispatch({
                type: actionTypes.STAFF.SHOW_LOGIN,
                showLogin: value
            });
        };
    },

    store: function store(id, name) {
        return function (dispatch) {
            dispatch({
                type: actionTypes.STAFF.STORE,
                id: id,
                name: name
            });
        };
    },

    checkLoggedIn: function logout() {
        return function (dispatch) {
            return httpFetch.fetch(config.services.staff.urls.user, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function (response) {
                dispatch({
                    type: actionTypes.STAFF.STORE,
                    id: response.user.id,
                    name: response.user.name
                });
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },
};
