const { textOverrides } = require("./utils/TextOverrides");

require("./utils/polyfills");

var stringify = require("json-stringify-safe"),
    React = require("react"),
    cookie = require("react-cookie"),
    ReactDOM = require("react-dom"),
    Provider = require("react-redux").Provider,
    createTransform = require("redux-persist").createTransform,
    persistStore = require("redux-persist").persistStore,
    asyncSessionStorage = require("redux-persist/storages").asyncSessionStorage,
    createEncryptor = require("redux-persist-transform-encrypt").default,
    config = require("./config"),
    appActions = require("./actions/app"),
    App = require("./components/app"),
    viewUtils = require("./utils/viewUtils"),
    store = require("./store"),
    AppProvider,
     encryptor;

const { simpleBrowserFingerprintString } = require("./utils/simpleBrowserFingerprint");

if (window.top !== window.self) {
    window.top.location.href = window.location.href;
}

window.config = config;

const entryTypes = [
    'booking-profile',
    'gym-profile',
    'lessons',
    'memberships',
    'short-memberships'
];

const allowedEntryPoints = entryTypes.map(entryPath => '/' + entryPath);

const path = location.pathname === '/' ? 'memberships/' : location.pathname;

const isStartPage = allowedEntryPoints.find(x => path.indexOf(x) >= 0);

const appModeKey = 'appmode';
const setupAs = sessionStorage.getItem(appModeKey) || '';

let clearSession =
    document.referrer === "" &&
    setupAs.indexOf(path) < 0 &&
    isStartPage;

// if a homepage and app is setup in a different mode
if (
    !clearSession &&
    isStartPage &&
    setupAs !== path
) {
    clearSession = true;
}

if (clearSession) {
    sessionStorage.clear();
}

if (isStartPage) {
    sessionStorage.setItem(appModeKey, allowedEntryPoints.find(x => path.indexOf(x) >= 0));
}

function gtag() {
    window.dataLayer.push(arguments);
}

AppProvider = React.createClass({
    displayName: 'AppProvider',

    getInitialState: function getInitialState() {
        return {
            rehydrated: false
        };
    },

    componentWillMount: function componentWillMount() {
        var self = this,
            sessionCookie =
                (cookie.load(config.app.laravelSessionCookieName) || '') + simpleBrowserFingerprintString(),
            // Transform to remove some keys from the persisted 'app' store.
            appStoreTransform = createTransform(
                function(inboundState) {
                    if (typeof inboundState !== "string") {
                        var newState = JSON.parse(stringify(inboundState));

                        delete newState.breakpoint;
                        delete newState.content;
                        delete newState.settings;
                        delete newState.profileLoaded;
                        delete newState.showLoader;
                        delete newState.showLoaderCentreFinder;
                        delete newState.showModals;
                        delete newState.showSidebar;
                        delete newState.started;
                        delete newState.timedOut;

                        return newState;
                    } else {
                        return inboundState;
                    }
                },

                function(outboundState) {
                    return outboundState;
                },

                {
                    whitelist: ["app"]
                }
            ),
            // Transform to remove some keys from the persisted 'liveChat' store.
            liveChatStoreTransform = createTransform(
                function(inboundState) {
                    if (typeof inboundState !== "string") {
                        var newState = JSON.parse(stringify(inboundState));
                        delete newState.show;
                        delete newState.ready;
                        delete newState.online;

                        return newState;
                    } else {
                        return inboundState;
                    }
                },

                function(outboundState) {
                    return outboundState;
                },

                {
                    whitelist: ["liveChat"]
                }
            ),
            // Transform to remove some keys from the persisted 'payments' store.
            paymentsStoreTransform = createTransform(
                function(inboundState) {
                    if (typeof inboundState !== "string") {
                        var newState = JSON.parse(stringify(inboundState));
                        delete newState.error;
                        delete newState.gatewayError;

                        return newState;
                    } else {
                        return inboundState;
                    }
                },

                function(outboundState) {
                    return outboundState;
                },

                {
                    whitelist: ["payments"]
                }
            ),
            // Transform to remove some keys from the persisted 'selections' store.
            selectionsStoreTransform = createTransform(
                function(inboundState) {
                    if (typeof inboundState !== "string") {
                        var newState = JSON.parse(stringify(inboundState));
                        delete newState.postSelectionsError;
                        delete newState.validating;

                        return newState;
                    } else {
                        return inboundState;
                    }
                },

                function(outboundState) {
                    return outboundState;
                },

                {
                    whitelist: ["selections"]
                }
            );

        encryptor = createEncryptor({
            secretKey: config.app.secretKey + sessionCookie
        });

        persistStore(
            store,
            {
                storage: asyncSessionStorage,
                transforms: [
                    appStoreTransform,
                    liveChatStoreTransform,
                    paymentsStoreTransform,
                    selectionsStoreTransform,
                    encryptor
                ],
                blacklist: ["routing"],
                keyPrefix: config.app.storeKey
            },
            function() {
                self.setState({ rehydrated: true });
                const state = store.getState();
                textOverrides
                    .setOverrides(state.centreFinder.textOverrides);
            }
        );

        if (document.referrer.match(/\/kiosk\//)) {
            store.dispatch(appActions.setKioskHomeUrl(document.referrer));
        }
    },

    _cookieBotConfigure: function _cookieBotConfigure() {
        const defaultConsents = {
            ad_personalization: "denied",
            ad_storage: "denied",
            ad_user_data: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 500
        }

        function initialize() {
            window.addEventListener('CookiebotOnAccept', updateConsents);
            window.addEventListener('CookieConsentsDeclaration', updateConsents);

            gtag("consent", "default", defaultConsents);
            gtag("set", "ads_data_redaction", true);
            gtag("set", "url_passthrough", true);
        }

        function updateConsents() {
            const { bulkconsentsubmitted = false, consent } = window.Cookiebot;
            const { marketing, statistics, preferences } = consent;

            if (bulkconsentsubmitted) {
                gtag("consent", "update", {
                  ad_personalization: "granted",
                  ad_storage: "granted",
                  ad_user_data: "granted",
                  analytics_storage: "granted",
                  functionality_storage: "granted",
                  personalization_storage: "granted",
                  security_storage: "granted",
                });
                return;
              } else {
                const consentMapping = {
                  ad_personalization: marketing,
                  ad_storage: marketing,
                  ad_user_data: marketing,
                  analytics_storage: statistics,
                  functionality_storage: preferences,
                  personalization_storage: preferences,
                  security_storage: true,
                };
                const updatedConsents = {
                  ...defaultConsents,
                  ...Object.fromEntries(
                    Object.entries(consentMapping).map(([key, value]) => [
                      key,
                      value ? "granted" : "denied",
                    ])
                  ),
                }
                gtag("consent", "update", updatedConsents);
            }
        }

        initialize();
    },

    render: function render() {
        if (!viewUtils.hasClass(document.documentElement, "ie9")) {
            return <div><App rehydrated={this.state.rehydrated} /></div>;
        }
    }
});

ReactDOM.render(
    <Provider store={store}>
        <AppProvider />
    </Provider>,
    document.getElementById("joinonline-app")
);
