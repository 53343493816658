var React           = require('react'),
    scrollToComponent = require('react-scroll-to-component'),

    Icons           = require('../../common/icons'),
    Input           = require('../../common/forms/input'),

    utils           = require('../../../utils/'),

    ChosenLevel;

ChosenLevel = React.createClass({

    componentDidMount() {
        var breakdown = document.getElementById('chosenLevel');
        scrollToComponent(breakdown, {
            align: 'top',
            offset: -100
        });
    },

    render: function() {
        var user = this.props.user,
            content = this.props.content,
            loggedInUser = this.props.loggedInUser,
            selectedLessons = user.selectedLessons,
            unlockedLevels = user.unlockedLevels,
            errors = this.props.errors,
            chosenLevels = [],
            unlocked = [],
            unlockedLevelsBlock,
            unlockSuccessBlock,
            chosenLevelBlock,
            firstName,
            differentLessonExists;

        if(loggedInUser.loggedIn && user.loggedInUser) {
            firstName = loggedInUser.user.info.firstName;
        } else {
            firstName = user.info.firstName.value;
        }

        //check if any unlockable levels and list them
        if(unlockedLevels && unlockedLevels.length) {
            for(var i = 0; i < unlockedLevels.length; i++) {
                var lesson = unlockedLevels[i].lesson_type_id === 1 ? 'Swimming ' : '',
                    unlockLevelName = lesson + '' + unlockedLevels[i].description,
                    selected = false,
                    key;

                //check if this lesson is different from the chosen one
                if (user.chosenLevel !== unlockedLevels[i].mrm_stage_id) {
                    differentLessonExists = true;
                }

                for (key in user.selectedLessons) {
                    //check if this lesson is selected
                    if (user.selectedLessons.hasOwnProperty(key) && (key === unlockedLevels[i].mrm_stage_id)) {
                        selected = true;
                        break;
                    }
                }

                var unlockedLevelType = utils.getLessonType(this.props.lessons, false, unlockedLevels[i].lesson_type_id);
                var journeyType = utils.getLessonType(this.props.lessons, false);

                //don't show as an alternative level if its the same type as the main journey
                if(unlockedLevelType !== journeyType) {
                    unlocked.push(<Input className='personal-details__input--cb'
                            key={'level_' + i}
                            input={ {
                                onChange: this.props.onAddLesson.bind(null, unlockedLevels[i].mrm_stage_id, unlockLevelName),
                                className:  'forms__input--checkbox'
                            } }
                            label={ {
                                text: unlockLevelName
                            } }
                            name={ 'add_lesson' + i}
                            type='checkbox'
                            valid={ true }
                            inputFirst={ true }
                            value={ selected }
                        />);
                }
            }
        }

        if(unlocked.length) {
            var listTitle = content.unlockBlock.success.listTitle;

            if((errors && errors.chosenLevel) || (user.ineligible && unlocked.length)) {
                listTitle = content.unlockBlock.failure.listTitle;
            }

            if(differentLessonExists) {
                unlockedLevelsBlock = (<div className='unlock-options'>
                        <h5>{ listTitle }</h5>
                        <ul className='unlock-list'>
                            { unlocked }
                        </ul>
                    </div>);
            }

            if(this.props.lessonCategory !== 'swimming') {
                unlockSuccessBlock = (<div className='unlock-message'>
                        <div className='input--valid input--hints'>
                            <span className='form__input-validity-icon'></span>
                            <span className='form__input-validity-message'>Great</span>
                        </div>
                        <p>{ content.unlockBlock.success.message[this.props.lessonCategory].replace('{name}', firstName) }</p>
                    </div>);
            }
        }

        //if the user has not unlocked the correct level
        //  OR
        //if the user's level is not available at this centre,
        //but there are unlocked levels available
        if((errors && errors.chosenLevel) || (user.ineligible && unlocked.length)) {
            chosenLevelBlock = (<div className='module'>
                    <span className='form__error form__error--lessons'>
                        { (errors && errors.chosenLevel) &&
                            this.props.disclaimers.levelNotUnlocked.replace('{name}', firstName).replace('{lesson}', this.props.lessonName) }


                        { (user.ineligible && unlocked.length && differentLessonExists) &&
                            this.props.disclaimers.levelNotAvailable.replace('{name}', firstName).replace('{lesson}', this.props.lessonName) }
                    </span>
                    { unlockedLevelsBlock }
                </div>);
        } else if(this.props.levelInfo) {
            for(var lesson in selectedLessons) {
                if(this.props.levelInfo[lesson]) {
                    var levelInfo = this.props.levelInfo[lesson],
                        price = levelInfo.price && levelInfo.price !== 0 ? this.props.content.levelPrice.replace('[amount]', utils.formatPrice(levelInfo.price)) : null,
                        lessonType = this.props.lessonType,
                        lessonTypeId,
                        lessonAccess;

                    if(levelInfo) {
                        lessonTypeId = levelInfo.classes.lesson_type_id;
                        lessonAccess = levelInfo.classes.lesson_access;

                        if(lessonTypeId) {
                            lessonType = utils.getLessonType(this.props.lessons, false, lessonTypeId);
                        }
                    }

                    // lesson_access: 0 = Lessons Online Only, 1 = Transfer Only, 2 = Lessons Online and Transfer
                    if (lessonAccess !== 1) {
                        chosenLevels.push(<div className='level-breakdown__chosen' key={'chosen-level_' + lesson }>
                                <div className='level-breakdown__chosen-icon'>
                                    <Icons icon={lessonType} color={levelInfo.color} iconUrl={levelInfo.iconUrl}/>
                                </div>
                                <div className='level-breakdown__chosen-details'>
                                    <div className='level-breakdown__chosen-name'>{ firstName }:</div>
                                    <h4>{ levelInfo.name } <span>{ price }</span></h4>
                                    <p dangerouslySetInnerHTML={ {__html: levelInfo.comment } } />
                                </div>
                            </div>);
                    }
                }
            }

            chosenLevelBlock = (<div className='module'>
                    { unlockSuccessBlock }
                    { chosenLevels }
                    { unlockedLevelsBlock }
                </div>);
        }

        return (<div className='module-container' id="chosenLevel">
            { chosenLevelBlock }
        </div>);
    }
});

ChosenLevel.propTypes = {
    content: React.PropTypes.object,
    user: React.PropTypes.object
};

module.exports = ChosenLevel;
