var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),
    Faqs            = require('../faqs'),

    Button          = require('../common/button'),
    SaveSelections  = require('../common/saveSelectionsButton'),
    CurrentExtras   = require('./currentExtras'),
    Extra           = require('./extra'),
    FooterSecurity   = require('../common/footerSecurity'),

    SelectedLesson  = require('../lessons/selectedLesson'),
    LessonsTimeout  = require('../lessons/lessonsTimeout'),

    Stage2Content;

import { filterOutNonAddons, noSubscriptions } from "../../../common/subscriptions/subscriptions";
// Configuration
import config from "../../config";

const sortObjects = function sortObjects ( objects ) {
    var sortedArray = [];

    for ( var key in objects ) {
        sortedArray.push( [ key, objects[ key ] ] );
    }

    sortedArray.sort( function ( a, b ) {
        return a[ 1 ].sortOrder - b[ 1 ].sortOrder;
    } );

    return sortedArray;
};

Stage2Content = React.createClass({

    componentDidMount: function componentDidMount() {
        // EA-60 If we have applicable addons and we've determined there are none, move onto "more about you"
        if ( this.props.feature_flags.conditional_removal_of_addons ) {
            if ( this.props.fetchedApplicableAddonIds && this.props.noAddonsAvailable ) {
                this.props.dispatch( actions.stages.disableStep( '/add-ons' ) );
                this._next();
            }
        }

        window.scrollTo(0,0);

        // Retrieve addon group ids from API
        this.props.dispatch(
            actions.stages.prepareApplicableAddonGroupIds(this.props.addonGroupIds)
        );
    },

    componentWillUnmount() {
        // Wipe addon group ids from API
        this.props.dispatch(
            actions.stages.unmountApplicableAddonGroupIds( this.props.addonGroupIds )
        );
    },

    componentWillReceiveProps(props) {
        // EA-60 If we have applicable addons and we've determined there are none, move onto "more about you"
        if ( this.props.feature_flags.conditional_removal_of_addons ) {
            if ( this.props.fetchedApplicableAddonIds && this.props.noAddonsAvailable ) {
                props.dispatch( actions.stages.disableStep( '/add-ons' ) );
                this._next();
            }
        }
    },

    _next: function _next() {

        // TODO: THIS BLOCK IS DUPLICATED IN THE SIDEBAR, UPDATE THERE IF YOU UPDATE HERE!
        this.props.dispatch(actions.personalDetails.unfreezeAll());
        this.props.dispatch(actions.stages.next());
    },

    /**
     * Filters out addons based on their subscriptions.
     * Each addons subscription is filtered based on their tags
     * e.g. JAP% will not be included
     * @param {array} addonsWithSubscriptions applicable addons with their subscriptions
     */
    filterOutNonAddons([tagName, addon]) {
        return [
            tagName,

            // Addon details
            {
                ...addon,

                // Create fresh list of subscriptions for this addon
                // by filtering out non-addon group ids
                subs: this.filterSubscriptions(addon.subs)
            }
        ];
    },

    /**
     * Filters out subscriptions based on non-addon group ids
     * @param {object} subscriptions
     */
    filterSubscriptions(subscriptions) {
        return subscriptions
            .filter( subscription => {
                return this.props.applicableAddonIds.includes(subscription.id);
            });
    },

    /**
     * Filters out any add-on that doesn't have any subscriptions attached to it
     * This may be because there's no JAD subscription ids for instance
     * @param {object} subscriptions
     */
    noSubscriptions([tagName, addon]) {
        return addon.subs.length > 0;
    },

    render: function render() {
        if (!this.props.centre) {
            return null;
        }

        var content = this.props.content.content,
            lessons = this.props.lessons,
            isLessons = lessons && lessons.inView,
            selectedLessons = [],
            addonsNodes = [],
            extrasNodes = [],
            lessonsTimeout,
            saveSelections,
            pageContent,
            addOns,
            extras,
            key;

        // We want to sort the addons but also filter out specific
        // subscriptions based on their tags for this particular part of the journey
        // (specialisation)
        const sortedAddons = sortObjects( this.props.centre.addons )
            .map( filterOutNonAddons( this.props.applicableAddonIds ) )
            .filter( noSubscriptions );
        const sortedExtras = sortObjects( this.props.centre.extras )
            .map( filterOutNonAddons( this.props.applicableAddonIds ) )
            .filter( noSubscriptions );

        for (var a = 0; a < sortedAddons.length; a++) {
            addonsNodes.push(<Extra filterFunc={this.filterSubscriptions } id={ sortedAddons[a][0] } key={ 'extras_a_' + sortedAddons[a][0] } type='addon' />);
        }

        for (var e = 0; e < sortedExtras.length; e++) {
            extrasNodes.push(<Extra filterFunc={this.filterSubscriptions } id={ sortedExtras[e][0] } key={ 'extras_e_' + sortedExtras[e][0] } type='extra' />);
        }

        if (addonsNodes.length) {
            addOns = <div className='module-container'>
                <div className='module module--inactive'>
                    <h2 className='module__title extras__list-title'>{ content.featuredExtras }</h2>
                    <ul id='addOnsList' className='extras__list'>{ addonsNodes }</ul>
                </div>
            </div>;
        }

        if (extrasNodes.length) {
            extras = <div className='module-container'>
                <div className='module module--inactive'>
                    <h2 className='module__title extras__list-title'>{ content.moreExtras }</h2>
                    <ul id='extrasList' className='extras__list extras__list--extras'>{ extrasNodes }</ul>
                </div>
            </div>;
        }

        var extraHasBeenAssigned = {};
        var buttonEnabled = true;

        if (Object.keys(this.props.extras).length) {
            for (key in this.props.extras) {
                extraHasBeenAssigned[key] = false;
                for (var d = 0; d < this.props.users.objects.length; d++) {
                    var userExtras = this.props.users.objects[d].extras || {};
                    if (userExtras[key] && Object.keys(userExtras[key]).length) {
                        extraHasBeenAssigned[key] = true;
                    }
                }
            }
        }

        var newKey;

        for (newKey in extraHasBeenAssigned) {
            if (extraHasBeenAssigned[newKey] === false) {
                buttonEnabled = false;
            }
        }

        var buttonProps;

        buttonProps = {
            className: 'button extras__next-button' + (buttonEnabled ? '' : ' button--disabled'),
            disabled: !buttonEnabled,
            onClick: this._next
        };

        if(isLessons && this.props.users.objects && this.props.users.objects.length) {
            for(var x = 0; x < this.props.users.objects.length; x++) {
                var user = this.props.users.objects[x];

                if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                    for(var l in user.lesson.selectedLessons){
                        selectedLessons.push(<SelectedLesson
                            user={ user }
                            key={'user_' + x + l}
                            level={ l } />);
                    }
                }
            }

            lessonsTimeout = <LessonsTimeout />;
        }

        if(!isLessons) {
            saveSelections = <SaveSelections />;
        }

        if(!addOns && !extras) {
            pageContent = <div className='module-container'>
                <div className='module module--inactive'>
                    <h2 className="module__title">{ content.noneAvailableTitle }</h2>
                </div>
            </div>;
        } else {
            pageContent = <div>
                { addOns }
                { extras }
            </div>;
        }

        return (
            <div key={ this.props.applicableAddonIds.length } className='container'>
                <h1 className='content__title'>{ content.title }</h1>
                <p className='content__intro'>{ content.description }</p>

                <div className='module-container'>
                    { selectedLessons }
                    { lessonsTimeout }
                </div>

                { pageContent }

                <div className='stage-options'>
                    { saveSelections }
                    <Button {...buttonProps}>{ content.buttonMoreAboutYou }</Button>
                </div>

                <Faqs />
            </div>
        );
    }
});

function mapStateToProps(state) {

    return {
        centre: state.centreFinder.selected,
        content: state.app.content.stage2,
        feature_flags: state.app.settings,
        d: state.selections.duration,
        extras: state.selections.extras,
        dt: state.selections.durationType,
        lessons: state.lessons,
        user: state.user.user,
        users: state.selections.users,
        stageStep: state.stages.showStep,
        staffId: state.staffLogin.staffId,
        staffName: state.staffLogin.staffName,

        noAddonsAvailable: sortObjects( state.centreFinder.selected.addons )
            .map( filterOutNonAddons( state.stages.applicableAddonIds ) )
            .filter( noSubscriptions ).length == 0 &&

            sortObjects( state.centreFinder.selected.extras )
                .map( filterOutNonAddons( state.stages.applicableAddonIds ) )
                .filter( noSubscriptions ).length == 0,

        // Available addon group ids from siteinfo
        // Ignore JAP% for this particular session (joinonline)
        addonGroupIds: state.selections.users.objects
            .flatMap( user => user.availableSubscriptions )
            .flatMap( subscription => subscription.groups)
            // Filter out JAD groups
            .filter( tagGroupId => /^JAD\d+$/.test(tagGroupId) )
            .flatMap( tagGroupId => {
                /**
                 * Attempts to find the related addon group id related to the provided
                 * tag group id
                 * A.k.a N-N of tag groups
                 * @param {string} tagGroupId The tag group id
                 */
                const findGroupIdsFromSiteInfo = ( tagGroupId ) => state.centreFinder.selected.addOnGroups
                    .find( group => group.groupId == tagGroupId );

                /**
                 * Recursively merges addon group ids with their related addon group ids
                 * @param {object|undefined} addonGroup Addon group & related addon group ids
                 */
                const merge = ( addonGroup ) => {
                    // If addonGroup is falsey
                    if ( !addonGroup ) {
                        return [];
                    }

                    // If we have related addon groups, get those too
                    if ( addonGroup.addOnGroupIds.length > 0 ) {
                        return [
                            // This group id
                            addonGroup.groupId,

                            // The related group ids
                            ...addonGroup.addOnGroupIds
                                .map( findGroupIdsFromSiteInfo )
                                .flatMap( merge ),

                            // Splat the addon group ids as there may be no mapping for them
                            // If there's no relation in the siteinfo we will see "undefined" otherwise
                            // See note below about JAD groupings for an example
                            ...addonGroup.addOnGroupIds,
                        ];
                    }

                    // Last branch model which contains just an addon group id
                    // NOTE: this edge branch may never be reached if there's no mapping
                    // available in the SiteInfo
                    // E.g. JAD65 may point to JAD66, but it's possible the data doesn't contain
                    // JAD66 for whatever reason
                    return [ addonGroup.groupId ];
                };

                return [
                    // Merge in related add-on group subscription ids
                    ...merge(
                        findGroupIdsFromSiteInfo( tagGroupId ),
                    ),

                    // We always want to include this group id because it doesn't
                    // necessarily mean it isn't an invalid add-on id
                    // Some add-on ids aren't actually mapped globally (e.g. PROF1, EAO)
                    tagGroupId
                ];
            } ),

        // Those that are applicable
        applicableAddonIds: state.stages.applicableAddonIds,
        fetchedApplicableAddonIds: state.stages.fetchedApplicableAddonIds,
    };
}

module.exports = connect(mapStateToProps)(Stage2Content);
