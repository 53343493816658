var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    SidebarCentreInfo;

SidebarCentreInfo = React.createClass({
    _handleChangeClick: function _handleChangeClick(e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal('confirmCentreReset'));
    },

    render: function render() {
        if (!this.props.centre || !this.props.centre.info || (!this.props.centre.info.name && !this.props.centre.info.siteDesc)) {
            return null;
        }

        var content = this.props.content;

        return (<div className='sidebar-selected-centre'>
            <p className='sidebar-selected-centre__title'>{ content.title }</p>
            <h3 className='sidebar-selected-centre__name'>{ this.props.centre.info.name || this.props.centre.info.siteDesc }</h3>
        </div>);
    }
});

SidebarCentreInfo.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object
};

module.exports = connect()(SidebarCentreInfo);
