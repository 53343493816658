const { textOverrides } = require('../../utils/TextOverrides');
const { default: Tooltip } = require('../common/tooltip');

var React = require('react'),
    connect = require('react-redux').connect,
    utils = require('../../utils'),
    viewUtils = require('../../utils/viewUtils'),
    moment = require('moment'),
    Button = require('../common/button'),

PaymentBreakdown = React.createClass({
    displayName: 'PaymentBreakdown',

    getInitialState: function getInitialState() {
        return {
            expanded: {}
        };
    },
    componentDidUpdate: function componentDidUpdate() {
        var key, elt;

        for (key in this.state.expanded) {
            elt = document.querySelector(
                ".payment-breakdown__block-details--" + key
            );
            elt && viewUtils.adjustHeight(elt);
        }
    },

    _handleExpandClick: function _handleExpandClick(key, e) {
        e.preventDefault();

        var elt = document.querySelector(
                ".payment-breakdown__block-details--" + key
            ),
            newExpanded = Object.assign({}, this.state.expanded);

        if (this.state.expanded && this.state.expanded[key]) {
            delete newExpanded[key];
            this.setState({ expanded: newExpanded });
            elt && viewUtils.furl(elt);
        } else {
            newExpanded[key] = true;
            this.setState({ expanded: newExpanded });
            elt && viewUtils.furl(elt);
        }
    },

    _buildBlocks: function _buildBlocks(options) {
        var period = null,
            content = this.props.content,
            ranges = null,
            users = options.users,
            blockId = 1,
            blocks = [],
            total = 0,
            paymentMethodTypeText,
            paymentMethodType,
            paymentMethodText,
            paymentMethod,
            priceItems,
            rangeKeys = [],
            expanded,
            title,
            items,
            key,
            sub,
            i,
            j,
            k;

        if (options.dt === 0) {
            period = options.pricing.periods[options.dt][options.d];
        } else if (options.dt === 1) {
            period = Object.values(options.pricing.periods[1]).reduce(
                function(period, item) {
                    period.total += item.total;
                    period.extras.total += item.extras.total;
                    period.fees.adminDiscounts += item.fees.adminDiscounts;
                    period.fees.adminPayable += item.fees.adminPayable;
                    period.fees.adminTotal += item.fees.adminTotal;
                    period.fees.lastMonthsTotal += item.fees.lastMonthsTotal;
                    period.fees.other = period.fees.other.concat(
                        item.fees.other
                    );
                    period.fees.total += item.fees.total;

                    period.priceOverridesPerUser = period.priceOverridesPerUser.concat(
                        item.priceOverridesPerUser
                    );

                    // merge in extras
                    Object.assign(period.extras, item.extras);

                    const alreadyHasRangeInfo = Object.values(period.ranges).length;
                    if (alreadyHasRangeInfo) {
                        Object.entries(item.ranges).forEach(([key = '', item = {}]) => {
                            if (!period.ranges[key]) {
                                period.ranges[key] = item;
                                return;
                            }
                            period.ranges[key].total += item.total || 0;
                            item.users.forEach((userList, i) => {
                                userList.forEach((user, j) => {
                                    if (!period.ranges[key].users) {
                                        period.ranges[key].users = [];
                                    }
                                    if (!period.ranges[key].users[i]) {
                                        period.ranges[key].users[i] = [];
                                    }
                                    period.ranges[key].users[i][j] = user; // add in user
                                });
                            });
                        });
                    }
                    if (!alreadyHasRangeInfo) {
                        // merge in range info
                        Object.assign(period.ranges, item.ranges);
                    }

                    item.users.forEach(function(user, i) {
                        Object.keys(user).forEach(function(key) {
                            if (!period.users[i]) {
                                period.users.push(user);
                            }

                            if (!period.users[i][key]) {
                                period.users[i][key].push({
                                    additionalAmount: 0,
                                    subscriptions: []
                                });
                            }

                            user[key].subscriptions.forEach(function(sub) {
                                var hasSub = period.users[i][
                                    key
                                ].subscriptions.find(x => x.id === sub.id);

                                if (!hasSub) {
                                    period.users[i][key].additionalAmount +=
                                        user[key].additionalAmount;
                                    period.users[i][key].subscriptions.push(
                                        sub
                                    );
                                }
                            });
                        });
                    });

                    return period;
                },
                Object.assign(
                    {
                        total: 0,
                        priceOverridesPerUser: [],
                        users: [],
                        extras: {
                            total: 0
                        },
                        fees: {
                            adminDiscounts: 0,
                            adminPayable: 0,
                            adminTotal: 0,
                            other: [],
                            total: 0
                        },
                        ranges: {}
                    },
                    options.pricing.periods[0]
                        ? options.pricing.periods[0][12]
                        : null
                )
            );
        }

        rangeKeys = Object.keys(period.ranges).sort((a, b) => {
            if (a === "now") {
                return -1;
            }

            if (b === "now") {
                return 1;
            }

            if (a < b) {
                return -1;
            }

            if (a > b) {
                return 1;
            }

            return 0;
        });

        for (i = 0; i < rangeKeys.length; i++) {
            paymentMethodText = content.paymentMethod;
            paymentMethodTypeText = content.paymentMethodDD;
            paymentMethodType = "direct-debit";
            key = rangeKeys[i];
            expanded = this.state.expanded[key];
            priceItems = [];
            items = [];
            total = 0;

            let periodHasDiscount = false;

            if ("now" === key) {
                title = content.titleNow;
                paymentMethodText = content.paymentMethod;
                paymentMethodTypeText = content.paymentMethodCard;
                paymentMethodType = "card";
            } else if (rangeKeys.length - 1 === i) {
                title = content.titleProRata.replace(
                    "{date}",
                    viewUtils.formatDateWithTextMonths(
                        key,
                        this.props.generalContent.monthNamesLong
                    )
                );
            } else {
                title = content.titleLater.replace(
                    "{date}",
                    viewUtils.formatDateWithTextMonths(
                        key,
                        this.props.generalContent.monthNamesLong
                    )
                );
            }

            paymentMethod = (
                <span
                    className={
                        "payment-breakdown__payment-method payment-breakdown__payment-method--" +
                        paymentMethodType
                    }
                >
                    {paymentMethodText}
                    <span className="payment-breakdown__payment-method-type">
                        {paymentMethodTypeText}
                    </span>
                </span>
            );

            for (j = 0; j < period.users.length; j++) {
                // If there are no items for this user, skip it
                if (
                    !period.users[j] ||
                    !period.users[j][key] ||
                    !period.users[j][key].subscriptions ||
                    !period.users[j][key].subscriptions.length
                ) {
                    continue;
                }

                total += period.users[j][key].additionalAmount;
                priceItems = [];

                var proRataAmount = 0;
                var proRataDesc = "";
                var itemHasFuturePrice;
                var proRataDiscountDesc;

                for (
                    k = 0;
                    k < period.users[j][key].subscriptions.length;
                    k++
                ) {
                    sub = period.users[j][key].subscriptions[k];
                    itemHasFuturePrice = !!sub.futurePrice;

                    periodHasDiscount =
                        periodHasDiscount ||
                        !!sub.originalDiscount ||
                        sub.amount == 0;

                    // combine all pro rata subs into one line
                    if (sub.isProRata) {
                        proRataDesc = sub.desc;
                        proRataAmount += sub.amount; // running total of proRata amount in this period
                    } else {
                        let feeDesc = sub.desc;

                        if (key !== "now" && feeDesc.match(/lessons/gi)) {
                            if (i < rangeKeys.length - 1) {
                                feeDesc +=
                                    " - for lessons in " +
                                    moment(window.now)
                                        .add(i + 1, "months")
                                        .format("MMMM");
                            } else if (i === rangeKeys.length - 1) {
                                const dayOfMonthTimestamp = moment(`${this.props.cutOffDate}-MM-YYYY`, 'DD-MM-YYYY');
                                const monthsToAdd = moment(dayOfMonthTimestamp).isBefore(moment()) ? i + 2 : i + 1;
                                feeDesc +=
                                    " - lessons each month from " +
                                    moment(window.now)
                                        .add(monthsToAdd, "months")
                                        .format("MMMM");
                            }
                        } else if (key === "now" && sub.isLastMonth) {
                            if (sub.desc.match(/golf/gi)) {
                                feeDesc =
                                    sub.desc +
                                    " - " +
                                    content.finalMonthInAdvanceGolf;
                            } else {
                                feeDesc =
                                    sub.desc +
                                    " - " +
                                    content.finalMonthInAdvance;
                            }
                        }

                        const subsAll = Object.values(period.users[j]).reduce((sum, item) => {
                            const mergeIn = item.subscriptions.reduce((sumIn, itemIn) => {
                                sumIn[itemIn.id] = item;
                                return sumIn;
                            }, {});
                            return { ...sum, ...mergeIn };
                        }, {});
                        const subIds = Object.keys(subsAll);
                        const subSourceId = subIds.find(subId => this.props.centre.subscriptions[subId]);
                        const subSource = this.props.centre.subscriptions[(sub || {}).id] ||
                            this.props.centre.subscriptions[subSourceId];

                        // needs info appending?
                        const descAppend = (() => {
                            if (feeDesc.indexOf('-') !== -1) {
                                return '';
                            }
                            const monthList = this.props.generalContent.monthNamesLong;
                            const isAnnual = options.d === 12;
                            const rangeKeyCurrent = rangeKeys.find((rangeKey) => rangeKey === key);
                            const rangeKeyI = rangeKeys.indexOf(rangeKeyCurrent);
                            // const rangeKeyFirst = rangeKeys[1];
                            const rangeKeyLast = rangeKeys[rangeKeys.length - 1];
                            const isNow = key === rangeKeys[0];
                            // const isFirst = key === rangeKeyFirst;
                            const isLast = key === rangeKeyLast;
                            const months = rangeKeys.map((rangeKey) => Number(rangeKey.indexOf('-') === -1 ? 0 : rangeKey.split('-')[1]) - 1)
                                .map((monthI) => String(monthList[monthI] || ''));
                            const monthCurrent = months[rangeKeyI] || '';
                            const datesNice = rangeKeys.map((rangeKey) => rangeKey.indexOf('-') === -1 ? '' : new Date(rangeKey).toLocaleDateString());
                            const dateFirst = subSource.startDate ? (new Date(subSource.startDate).toLocaleDateString()) : (datesNice[1] || '');
                            const dateLast = subSource.endDate ? (new Date(subSource.endDate).toLocaleDateString()) : (datesNice[datesNice.length - 1] || '');
                            // const dateCurrent = String(datesNice[rangeKeyI] || '');
                            let keyText = '';
                            if (!isAnnual && !isNow && monthCurrent) {
                                keyText = 'forTheMonthOf';
                            }
                            if (!isAnnual && isLast) {
                                keyText = 'forEachMonthGoingForward';
                            }
                            if (isAnnual && isNow && dateFirst && dateLast) {
                                keyText = 'forAccessFrom';
                            }
                            // console.assert(keyText && content[keyText], { error: `Does ${keyText} exist?`, content });
                            const contentVal = String(content[keyText] || '');
                            const toAppend = !contentVal ? '' :
                                contentVal
                                    .replace('{month}', monthCurrent)
                                    .replace('{startDate}', dateFirst)
                                    .replace('{endDate}', dateLast);
                            return toAppend;
                        })();
                        if (descAppend) {
                            feeDesc += descAppend;
                        }
                        const feeDescPrev = feeDesc.trim();
                        feeDesc = textOverrides.setSub(subSource).replace(feeDesc) + ' ';
                        const info = textOverrides.getInfo(feeDescPrev) || textOverrides.getInfo(feeDesc);

                        priceItems.push(
                            <li
                                className={
                                    "payment-breakdown__item " +
                                    (sub.isProRata
                                        ? " payment-breakdown__item--prorata"
                                        : "")
                                }
                                key={"u_" + j + "_i_" + k}
                            >
                                <span className="payment-breakdown__item-label">
                                    {feeDesc}
                                    <Tooltip>{info}</Tooltip>
                                </span>
                                <span className="payment-breakdown__item-amount">
                                   {utils.formatPrice(sub.amount)}
                                </span>
                                {
                                    !!sub.discountDesc ? <span className="payment-breakdown__item-label--discount-description payment-breakdown__item-label--has-discount"
                                        dangerouslySetInnerHTML={{ __html: sub.discountDesc }}>
                                        </span> :
                                    (sub.amount == 0 && (
                                    <span className="payment-breakdown__item-label--discount-description payment-breakdown__item-label--has-discount">
                                            Discount applied
                                            {sub.discountDesc && `:`}&nbsp;
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: sub.discountDesc
                                                }}
                                            />
                                        </span>
                                    ))
                                }
                                {itemHasFuturePrice && (
                                    <span className="payment-breakdown__item-label--description">
                                        Future price:{" "}
                                        {utils.formatPrice(sub.futurePrice)}
                                    </span>
                                )}
                            </li>
                        );
                    }
                }

                if (proRataDesc && proRataAmount >= 0) {
                    // add the
                    priceItems.push(
                        <li
                            className="payment-breakdown__item payment-breakdown__item--prorata"
                            key={"u_" + j + "_i_" + k}
                        >
                            <span className="payment-breakdown__item-label">
                                Pro rata
                                <br />
                                <span className="payment-breakdown__item-label--description">
                                    {proRataDesc}
                                </span>
                                {proRataDiscountDesc && (
                                    <span className="payment-breakdown__item-label--discount-description payment-breakdown__item-label--has-discount">
                                        Discount applied:{" "}
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: proRataDiscountDesc
                                            }}
                                        />
                                    </span>
                                )}
                            </span>
                            <span className="payment-breakdown__item-amount">
                                <span className='payment-breakdown__item-amount'>{ utils.formatPrice( proRataAmount ) }</span>
                            </span>
                        </li>
                    );
                }

                items.push(
                    <div
                        className="payment-breakdown__item-container"
                        key={"u_" + j}
                    >
                        <div className="payment-breakdown__item-username">
                            {users.objects[j].info.firstName.value +
                                " " +
                                users.objects[j].info.lastName.value}{" "}
                            <span>({users.objects[j].typeDesc})</span>
                        </div>
                        {priceItems}
                    </div>
                );
            }

            // If there's nothing to pay in this current block, simply don't show it.
            if ((options.dt === 0 && total) || options.dt === 1) {
                blocks.push(
                    <li className="payment-breakdown__block" key={"r_" + key}>
                        <span className="payment-breakdown__block-title">
                            <span className="payment-breakdown__block-title-number">
                                {blockId + "."}
                            </span>
                            <span className="payment-breakdown__block-title-text">
                                {title}
                            </span>
                        </span>
                        {paymentMethod}
                        <div className="payment-breakdown__item payment-breakdown__item--total">
                            <span className="payment-breakdown__item-label">
                                {content.total}
                            </span>
                            {periodHasDiscount && (
                                <span className="payment-breakdown__item-label--has-discount ">
                                    {content.periodHasDiscount}
                                </span>
                            )}
                            <span className="payment-breakdown__item-amount">
                                <span className='payment-breakdown__item-amount'>{ utils.formatPrice( total ) }</span>
                            </span>
                            <Button
                                className={
                                    "button__more-info payment-breakdown__item-button" +
                                    (expanded
                                        ? " button__more-info--expanded"
                                        : "")
                                }
                                onClick={this._handleExpandClick.bind(
                                    null,
                                    key
                                )}
                            >
                                {expanded
                                    ? content.hideBreakdown
                                    : content.seeBreakdown}
                            </Button>
                        </div>
                        <div
                            className={
                                "payment-breakdown__block-details payment-breakdown__block-details--expanded payment-breakdown__block-details--" +
                                key
                            }
                        >
                            <div className="inner">
                                <ul className="payment-breakdown__block-details-list">
                                    {items}
                                </ul>
                            </div>
                        </div>
                    </li>
                );
            }

            blockId++;
        }

        return <ul className="payment-breakdown__blocks">{blocks}</ul>;
    },

    hasPromotionCodeApplied: function() {
        var self = this;
        var user, subscription;

        for (var i = 0; i < this.props.users.objects.length; i++) {
            user = this.props.users.objects[i];

            subscription = user.availableSubscriptions.find(function(s) {
                return (
                    s.duration === self.props.d &&
                    s.durationType === self.props.dt
                );
            });

            if (!subscription) {
                continue;
            }
        }

        if (subscription && subscription.promotionCodeApplied) {
            return true;
        }

        return false;
    },

    checkLinkedMemberSubs: function() {
        var content = this.props.content,
            users = this.props.users.objects,
            memberDiscounts = [];

        for (var i = 0; i < users.length; i++) {
            var user = users[i];
            var userName =
                user.info.firstName.value + " " + user.info.lastName.value;

            if (
                user.linkedMemberDiscount &&
                (user.linkedMemberDiscount.annual ||
                    user.linkedMemberDiscount.monthly)
            ) {
                var annualDiscount = utils.formatPrice(
                    user.linkedMemberDiscount.annual
                );
                var monthlyDiscount = utils.formatPrice(
                    user.linkedMemberDiscount.monthly
                );

                memberDiscounts.push(
                    <div
                        className="payment-breakdown__notice-member"
                        key={"discount-" + i}
                    >
                        <p>
                            {content.linkedMembershipNotice.replace(
                                "{userName}",
                                userName
                            )}
                        </p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: content.linkedMembershipNoticeAnnual.replace(
                                    "{price}",
                                    annualDiscount
                                )
                            }}
                        ></p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: content.linkedMembershipNoticeMonthly.replace(
                                    "{price}",
                                    monthlyDiscount
                                )
                            }}
                        ></p>
                    </div>
                );
            }
        }

        if (memberDiscounts.length) {
            return (
                <div className="payment-breakdown__notice">
                    {memberDiscounts}
                </div>
            );
        }

        return null;
    },

    getMonthNames() {
        return [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
    },

    render: function() {
        if (!this.props.pricing.periods) {
            return null;
        }

        var content = this.props.content,
            users = this.props.users,
            dt = this.props.dt,
            d = this.props.d,
            monthlyView = dt === 0 && 1 === d,
            annualView = (dt === 0 && 12 === d) || dt === 1,
            // @todo refactor logic so "annual" and "monthly" works in all contexts
            // titleText = monthlyView ? content.monthlyPaymentPlan : ( annualView ? content.annualCost : ''),
            titleText = "costs";
        blocks = this._buildBlocks({
            pricing: this.props.pricing,
            users: users,
            dt: dt,
            d: d
        });

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = "0" + dd;
        }

        if (mm < 10) {
            mm = "0" + mm;
        }

        today = yyyy + "-" + mm + "-" + dd;

        var currentDate = viewUtils.formatDateWithTextMonths(
            today,
            this.getMonthNames()
        );

        return (
            <div className="module-container">
                <div className="module module--inactive">
                    <div className="payment-breakdown">
                        <div className="payment-breakdown__header">
                            <h2>{content.title}</h2>
                            <p>
                                {content.description.replace(
                                    "{plan}",
                                    titleText
                                )}{" "}
                                {currentDate}
                            </p>

                            {this.checkLinkedMemberSubs()}
                        </div>
                        {blocks}
                    </div>
                </div>
            </div>
        );
    }
});

PaymentBreakdown.propTypes = {
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    generalContent: React.PropTypes.object,
    d: React.PropTypes.number,
    dt: React.PropTypes.number,
    pricing: React.PropTypes.object,
    users: React.PropTypes.object
};

function mapStateToProps(state) {
    return {
        cutOffDate: state.app.cutoffDate
    };
}

module.exports = connect(mapStateToProps)(PaymentBreakdown);
