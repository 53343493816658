/**
 * Pushes data into data layer for google analytics library
 * 
 * @todo wrap this in a service - [] is to default the library so it doesn't break the JS entirely
 * 
 * @param {mixed} data Accepts arbitrary data into datalayer
 * @returns void
 */
export const push = data => {
    console.info('Google Analytics - up - ', data);

    (window.dataLayer || []).push(data);
};