import React from "react";

export class VimeoPlayer extends React.Component {
    render () {
        const { video_id } = this.props.video;

        return ( <div>
            <div
                data-vimeo-id={ video_id }
                style={ { textAlign: "center", position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", width: "100%", height: "100%", } }
                // Ensure Vimeo player is responsive so it scretches to fit width
                data-vimeo-responsive="true"
            >
            </div>
        </div> );
    }
}