var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    SalesLines;

SalesLines = React.createClass({
    componentWillMount: function componentWillMount() {
        var site = this.props.currentCentre;

        if(site && !this.props.staffId) {
            this.fetchSalesLines(site.info.site_id);
        }
    },

    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        var site = nextProps.currentCentre;

        if(site && !this.props.currentCentre && !this.props.staffId) {
            this.fetchSalesLines(site.info.site_id);
        } else if(!site || this.props.staffId) {
            this.props.dispatch(actions.salesLines.wipeout());
        }
    },

    fetchSalesLines: function fetchSalesLines(siteID) {
        var tags = [ ];

        if (siteID) {
            tags.push('site-' + siteID);
        }

        this.props.dispatch(actions.salesLines.fetchSalesLines(tags));
    },

    render: function render() {

        if (!this.props.currentCentre || !this.props.results || !this.props.results.length || this.props.isRenewals || this.props.isLessons) {
            return null;
        }

        var content = this.props.content,
            results = [],
            centreName,
            salesLine,
            i;

        if (this.props.currentCentre) {
            centreName = this.props.currentCentre.info.name;
        }

        for (i = 0; i < this.props.results.length; i++) {
            salesLine = this.props.results[i];
            results.push(<li key={'salesline_' + i} className='salesline__list-item'>{ salesLine.copy }</li>);
        }

        return (
            <section className='sidebar-module'>
                <h3 className='sidebar-module__title'>{ content.title.replace('{centre}', centreName) }</h3>
                <ul className='sidebar-module__list'>
                    { results }
                </ul>
            </section>
        );
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.salesLines,
        results: state.salesLines.results,
        currentCentre: state.centreFinder.selected,
        staffId: state.staffLogin.staffId
    };
}

module.exports = connect(mapStateToProps)(SalesLines);
