var queryString = require('query-string');


module.exports = exports = {

    decodeParam: function decodeParam(val) {
        if (!(typeof val === 'string' || val.length === 0)) {
            return val;
        }

        try {
            return decodeURIComponent(val);
        } catch (err) {
            if (err instanceof URIError) {
                err.message = 'Failed to decode param ' + val;
                err.status = 400;
            }

            throw err;
        }
    },

    // Simply returns the first matching route for the location pathname
    findRoute:  function findRoute(routes, pathname) {
        var params,
            route,
            i;

        for (i = 0; i < routes.length; i++) {
            route = routes[i];
            params = exports.matchURI(route, location.error ? '/error' : pathname);

            if (!params) {
                continue;
            }

            return {
                route: route,
                params: params
            };
        }

        return null;
    },


    // Match the provided URL path pattern to an actual URI string. For example:
    //   matchURI({ path: '/posts/:id' }, '/dummy') => null
    //   matchURI({ path: '/posts/:id' }, '/posts/123') => { id: 123 }
    matchURI: function matchURI(route, path) {
        var match = route.pattern && route.pattern.exec(path),
            params;

        if (!match) {
            return null;
        }

        params = Object.create(null);

        for (let i = 1; i < match.length; i++) {
            params[route.keys[i - 1].name] = match[i] !== undefined
                ? exports.decodeParam(match[i])
                : undefined;
        }

        return params;
    },

    // Find the route matching the specified location (context), fetch the required data,
    // instantiate and return a React component
    resolve: function resolve(routes, location) {
        var resolvedRoute;
        var routeObj = exports.findRoute(routes.appRoutes, location.pathname);

        if (routeObj) {
            resolvedRoute = routeObj.route.handler(routeObj.params, queryString.parse(location.search));
        } else if (routes.error404 && routes.error404[0]) {
            resolvedRoute = routes.error404[0].handler();
        }

        return resolvedRoute;
    }
};
