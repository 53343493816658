var React           = require('react'),
    connect         = require('react-redux').connect,
    moment          = require('moment'),

    actions         = require('../../actions'),

    Sidebar         = require('../sidebar'),
    Content         = require('./content'),

    config          = require('../../config'),
    httpFetch       = require('../../utils/httpFetch'),
    queryString     = require('query-string'),

    RenewalsIndex;

RenewalsIndex = React.createClass({
    getInitialState: function getInitialState() {
        return {
            memberSubs: null,
            owedSubs: null,
            showFSG: false
        };
    },

    _redirectIfPropsNotValid: function _redirectIfPropsNotValid() {
        this.props.dispatch(actions.stages.goHome());
        this.props.dispatch(actions.stages.setStep(0));
    },

    componentWillMount: function componentWillMount() {
        var promises = [],
            query = queryString.parse(location.search),
            currentTime = moment(window.now).valueOf(),
            subscriptionDetailsByTypeUrl = config.services.memberships.urls.subscriptionDetailsByType + '?' + currentTime,
            self = this;

        this.props.dispatch(actions.app.showLoader(true));

        promises.push(httpFetch.fetch(subscriptionDetailsByTypeUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }));

        promises.push(httpFetch.fetch(config.services.memberships.urls.owedSubscriptions, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }));

        Promise.all(promises)
            .then(function(response) {
                var showFSG = false;

                if(query.renewalSub && query.renewalSub === 'fsg') {
                    showFSG = true;
                }

                self.setState({
                    memberSubs: response[0],
                    owedSubs: response[1],
                    showFSG: showFSG
                });
                self.props.dispatch(actions.app.showLoader(false));
            })
            .catch(function(error) {
                console.log(error);
                // self._redirectIfPropsNotValid();
            });
    },

    render: function() {
        return (<div className='page'>
                <Content memberSubs={ this.state.memberSubs } owedSubs={ this.state.owedSubs } showFSG={ this.state.showFSG }/>
                <Sidebar isRenewals={ true } />
            </div>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content,
        deeplinkAddons: state.selections.deeplinkAddons,
        hasCentre: state.centreFinder.selected,
        hasSelectedMemberships: state.memberships.selected
    };
}

module.exports = connect(mapStateToProps)(RenewalsIndex);
