var
    React = require( 'react' ),
    // actions = require('../../../actions'),
    Schedule;

Schedule = React.createClass( {
    buildSchedule: function ( timetable, staffLogin ) {

        var schedule = timetable.times.reduce( function ( schedule, dayObj ) {
            var day = null;
            var lessons = [];
            var visibility;

            Object.keys( dayObj ).forEach( function ( lessonType ) {
                var typeLessons = dayObj[ lessonType ];

                typeLessons.forEach( function ( lesson ) {
                    visibility = staffLogin ? ( lesson.filter_visibility === 0 || lesson.filter_visibility === 1 ) : ( lesson.filter_visibility === 0 || lesson.filter_visibility === 2 );
                    if ( lesson.lesson_access !== 1 && visibility ) {
                        day = lesson.weekday;
                        lessons.push( {
                            time: lesson.time,
                            type: lessonType,
                            color: timetable.levels[ lessonType ].group_color,
                            lessonType: lessonType,
                            isFull: lesson.is_full,

                            // @todo refactor at later date.Put in place for bug 316 - possibly caused by lesson transfers
                            bookable: lesson.bookable,
                        } );
                    }
                } );
            } );

            lessons.sort( function ( a, b ) {
                return a.time > b.time ? 1 : -1;
            } );

            if ( day ) {
                schedule.push( {
                    name: day,
                    lessons: lessons
                } );
            }

            return schedule;
        }, [] );

        return schedule;
    },

    render: function () {
        var timetable = this.props.lessons.timetable,
            schedule = null,
            index = 0;

        if ( !this.props.lessons.timetable ) {
            return false;
        }

        schedule = this.buildSchedule( timetable, this.props.staffLogin );

        return (
            <div className="timetable__schedule">
                {schedule.map( function ( day ) {
                    return (
                        <div key={index++}>
                            <h5 className="timetable__schedule--day">{day.name + 's'}:</h5>
                            <div>
                                {day.lessons.map( function ( lesson ) {
                                    const style = {
                                        backgroundColor: lesson.isFull ? 'white' : '#' + lesson.color,
                                        color: lesson.isFull ? '#' + lesson.color : 'white',
                                        border: '1px solid #' + lesson.color
                                    };

                                    return (
                                        <span key={index++} title={lesson.type} className="timetable__schedule--lesson" style={style}>
                                            {!lesson.isFull ?
                                                lesson.time
                                                : 'FULL'}
                                        </span>
                                    );
                                } )}
                            </div>
                        </div>
                    );
                } )}
            </div>
        );
    }
} );

module.exports = Schedule;
