var React = require("react"), // eslint-disable-line no-unused-vars
    Header = require("../components/common/noStagesHeader"),
    Component = require("../components/inCentrePayment/inCentrePaymentPage.js");

module.exports = [
    {
        path: "/in-centre-payment",

        handler: function(params) {
            return {
                noBg: true,
                header: <Header />,
                component: <Component />
            };
        }
    }
];
