const { virtualPageTitle } = require( "../../../../common/google/analytics/lessons" );
const { products, castToUndefinedIfEmpty, hasSelections } = require( "../../../../common/google/analytics/products" );
const { push } = require( "../../common/google/analytics/datalayer" );

var React = require("react"),
    connect = require("react-redux").connect,
    viewUtils = require("../../../utils/viewUtils"),
    actions = require("../../../actions"),
    centreModel = require("../../../models/centre"),
    userModel = require("../../../models/user"),
    Button = require("../../common/button"),
    panels = require("./panels"),
    Membership = require("./membership"),
    scrollToComponent = require("react-scroll-to-component"),
    Memberships;

Memberships = React.createClass({
    getInitialState: function getInitialState() {
        setTimeout(() => this.forceUpdate()); // nasty little hack!

        return {
            preselectionsProcessed: false,
            preselectionSubProcessed: false,
            expanded: false,
            initialSearch: true,
            chosenMembership: false,
            taggingFired: false
        };
    },

    componentDidMount() {
        // EA-60 - Ensure step is visible from this stage at all times
        this.props.dispatch( actions.stages.enableStep( '/add-ons' ) );
    },

    componentDidUpdate: function componentDidUpdate() {
        var membershipSelection = document.getElementById(
            "membership-selection"
        );
        const { preselectedUsers, preselectedSubs } = this.props;

        // If preselectedUsers are set through query params then handle them here
        // to select relevant membership types.
        if (
            preselectedUsers &&
            !this.state.preselectionsProcessed &&
            this.props.centre
        ) {
            var linked = false,
                users = {};

            if (preselectedUsers.adults) {
                var adultType = centreModel.getMembershipTypeFor(
                    this.props.centre,
                    "adult"
                );
                adultType = adultType && adultType.id;
                users[adultType] = preselectedUsers.adults;
            }

            if (preselectedUsers.students) {
                var studentType = centreModel.getMembershipTypeFor(
                    this.props.centre,
                    "student"
                );
                studentType = studentType && studentType.id;
                users[studentType] = preselectedUsers.students;
            }

            if (preselectedUsers.juniors) {
                var juniorType = centreModel.getMembershipTypeFor(
                    this.props.centre,
                    "junior"
                );
                juniorType = juniorType && juniorType.id;
                users[juniorType] = preselectedUsers.juniors;
                // Juniors require at least 1 adult; if none included, add a freeprofile one
                if (!preselectedUsers.adults) {
                    users["freeprofile"] = 1;
                    linked = true;
                }
            }

            if (preselectedUsers.concessions) {
                var concessionType = centreModel.getMembershipTypeFor(
                    this.props.centre,
                    "conc"
                );
                concessionType = concessionType && concessionType.id;
                users[concessionType] = preselectedUsers.concessions;
            }

            if (preselectedUsers.seniors) {
                var seniorType = centreModel.getMembershipTypeFor(
                    this.props.centre,
                    "senior"
                );
                seniorType = seniorType && seniorType.id;
                users[seniorType] = preselectedUsers.seniors;
            }

            this.props.dispatch(actions.selections.setUsers(users, linked));
            this.props.dispatch(actions.memberships.selectMemberships());

            this.setState({
                preselectionsProcessed: true
            });
        }

        // if deeplink subsciption is provided then assign subsciptions to users
        if (
            this.props.users.objects.length &&
            preselectedSubs &&
            this.state.preselectionsProcessed &&
            !this.state.preselectionSubProcessed
        ) {
            for (var userId in this.props.users.objects) {
                const userType = this.props.users.objects[userId].type;

                if (userType) {
                    const { centre } = this.props;
                    const {
                        facilities,
                        permittedSites,
                        permittedTimes
                    } = centre;

                    const { facility, sub } = facilities.reduce((o, f) => {
                        f.subs.some(s => {
                            const isMatch =
                                s.tags.includes(userType) &&
                                preselectedSubs.includes(s.id);

                            if (isMatch) {
                                o = { sub: s, facility: f };

                                return true;
                            }
                        });

                        return o;
                    }, {});

                    if (sub) {
                        const timesTag = Object.values(permittedTimes).find(
                            x => {
                                return sub.tags.includes(x.id);
                            }
                        ).id;

                        const sitesTag = Object.values(permittedSites).find(
                            x => {
                                return sub.tags.includes(x.id);
                            }
                        ).id;

                        this.props.dispatch(
                            actions.selections.setFacilityForUser(
                                userId,
                                facility.id,
                                "on"
                            )
                        );
                        this.props.dispatch(
                            actions.selections.setFacilitySitesForUser(
                                userId,
                                facility.id,
                                sitesTag
                            )
                        );
                        this.props.dispatch(
                            actions.selections.setFacilityTimesForUser(
                                userId,
                                facility.id,
                                timesTag
                            )
                        );
                    }
                }
            }

            this.setState({
                preselectionSubProcessed: true
            });
        }

        // If subsciption selected and are already assigned to users then go to next stage
        if (
            preselectedSubs &&
            this.state.preselectionSubProcessed &&
            !this.props.preselectionSubProcessed
        ) {
            if (this.props.centre.facilities.length > 0) {
                this.props.dispatch(
                    actions.selections.preselectionSubProcessed()
                );
            }
        }

        if (
            this.state.initialSearch &&
            membershipSelection &&
            !this.props.staffId
        ) {
            window.requestAnimationFrame(function() {
                scrollToComponent(membershipSelection, {
                    align: "top",
                    offset: -100
                });
            });

            this.setState({
                initialSearch: false
            });
        }

        if (this.props.centre && !this.state.taggingFired) {
            push({
                event: "virtual pageview",
                virtualPagePath: "/virtual/who-is-your-membership-for/",
                virtualPageTitle: "Join - Everyone Active - Who is Your Membership For",
                products: this.props.products
            });
            this.setState({
                taggingFired: true
            });
        }
    },

    _handleChangeClick: function _handleChangeClick(e) {
        e.preventDefault();
        this.props.dispatch(actions.memberships.resetSelection());
        this.props.dispatch(actions.personalDetails.unfreezeAll());
        this.props.dispatch(actions.memberships.unfreeze());
    },

    _handleMoreInfoClick: function _handleMoreInfoClick(key, e) {
        e.preventDefault();

        var oldElet = document.querySelector(
                ".module__list-item-details--membership-selection-" +
                    this.state.expanded
            ),
            elt = document.querySelector(
                ".module__list-item-details--membership-selection-" + key
            );

        if (this.state.expanded && key === this.state.expanded) {
            this.setState({ expanded: false });
            oldElet && viewUtils.furl(oldElet);
        } else {
            this.setState({ expanded: key });
            oldElet && viewUtils.furl(oldElet);
            elt && viewUtils.furl(elt);
        }
    },

    /**
     * Auto expand description pane
     * @param {string} classSelector Class selector
     */
    _handlAutoExpand: function _handlAutoExpand(classSelector) {
        document.querySelector(`.button__more-info.membership-${classSelector }`)
            .click();
    },

    _handleAdd: function _handleAdd(id, e) {
        e.preventDefault();

        // Handle auto expansion of description pane
        this._handlAutoExpand(id);

        var users = Object.assign({}, this.props.users.count),
            keys = Object.keys(this.props.users.count),
            siteId = this.props.centre.info.site_id;

        if (!users[id]) {
            users[id] = 1;
        } else if (users[id] + 1 <= 20) {
            users[id]++;
        }

        if (!/junior/.test(id.toLowerCase()) && users[id]) {
            this.props.dispatch(actions.memberships.showSpecialPanel());
        }

        //if parent/guardian member exist, remove it when adding a new member
        if (
            !/freeprofile/.test(id.toLowerCase()) &&
            this.props.users.total > 0
        ) {
            var juniorAmount = users[siteId + "-JUNIOR"];

            if (juniorAmount) {
                var guardianPlace =
                    this.props.users.objects.length - 1 - juniorAmount;

                if (guardianPlace >= 0) {
                    if (
                        this.props.users.objects[guardianPlace].guardian ===
                        true
                    ) {
                        users["freeprofile"]--;
                    }
                }
            }
        }

        this.props.dispatch(actions.selections.setUsers(users));
    },

    _handleRemove: function _handleRemove(id, e) {
        e.preventDefault();

        // Handle auto expansion of description pane
        this._handlAutoExpand(id);

        var users = Object.assign({}, this.props.users.count);

        if (users[id] && users[id] - 1 >= 0) {
            users[id]--;
        }

        if (/junior/.test(id.toLowerCase()) && !users[id]) {
            this.props.dispatch(actions.memberships.showSpecialPanel());
        }

        this.props.dispatch(actions.selections.setUsers(users));
    },

    /**
     * [getUserType description]
     * @param  {[type]} users [description]
     * @return {[type]}       [description]
     */
    _getUserType: function getUserType(users) {
        var response = [];
        for (var i = 0; i < users.objects.length; i++) {
            response.push(users.objects[i].typeDesc);
        }

        if (response.length === 1) {
            response = response[0];
        } else {
            response = response.sort();
        }

        return response;
    },

    _handleUseFreeProfile: function _handleUseFreeProfile(e) {
        if (this.props.isLessons) {
            this._onLoginComplete();
        } else {
            var users = Object.assign({}, this.props.users.count);

            users.freeprofile = 1;

            // Hide junior panel.
            this.props.dispatch(actions.memberships.showSpecialPanel());

            // Set new user totals.
            this.props.dispatch(actions.selections.setUsers(users, true));
            this.props.dispatch(actions.memberships.selectMemberships());
        }
    },

    _handleContinue: function _handleContinue() {
        var keys = Object.keys(this.props.users.count),
            users = Object.assign({}, this.props.users.count),
            adultType = centreModel.getMembershipTypeFor(
                this.props.centre,
                "adult"
            ),
            hasJuniors = false,
            hasOthers = false,
            i;

        adultType = adultType && adultType.id;

        for (i = 0; i < keys.length; i++) {
            if (
                /junior/.test(keys[i].toLowerCase()) &&
                this.props.users.count[keys[i]]
            ) {
                hasJuniors = true;
            }

            if (
                !/junior/.test(keys[i].toLowerCase()) &&
                this.props.users.count[keys[i]]
            ) {
                hasOthers = true;
                break;
            }
        }

        if (hasJuniors && !hasOthers) {
            // If we're logged in, add one adult that will take the
            //  logged in user's details from this step onwards.
            // Worth to note that if we're in this block, it means
            //  that we have only jusnior selections, so it's safe
            //  to set adults to 1.
            if (this.props.loggedIn) {
                users[adultType] = 1;
                this.props.dispatch(actions.selections.setUsers(users));
                this.props.dispatch(actions.memberships.selectMemberships());

                // otherwise, we need to show the login panel.
            } else {
                this._handleUseFreeProfile();
            }
        } else {
            this.props.dispatch(actions.memberships.selectMemberships());
        }
    },

    _formatUserNumbers: function _formatUserNumbers(userCounts) {
        var keys = Object.keys(userCounts),
            users = this.props.users,
            types = [],
            key,
            mt,
            nb,
            i;

        keys.sort(function(a, b) {
            return (
                userModel.userTypesOrder.indexOf(a) -
                userModel.userTypesOrder.indexOf(b)
            );
        });

        for (i = 0; i < keys.length; i++) {
            key = keys[i];
            nb = userCounts[key];
            mt = this.props.memberships.find(function(m) {
                return m.id === key;
            });

            if (nb && mt) {
                if (
                    key === "freeprofile" &&
                    users.objects[0].typeDesc === "Parent / Guardian"
                ) {
                    types.push(
                        nb + " " + mt.guardianDesc + (nb > 1 ? "s" : "")
                    );
                } else {
                    types.push(nb + " " + mt.desc + (nb > 1 ? "s" : ""));
                }
            }
        }

        return types.join(", ");
    },

    render: function() {
        if (!this.props.centre) {
            return null;
        }

        var content = this.props.content,
            memberships = this.props.memberships,
            buttonText = content.buttonApply,
            mlen = memberships.length,
            dynamicMemberhips = [],
            membershipTypes = [],
            selectedMemberships,
            membershipTitle,
            continueButton,
            selectionView,
            specialPanel,
            buttonProps,
            titleClass,
            membership,
            adultType,
            expanded,
            count,
            x = 0,
            i = 0;
        
        if (this.props.membershipsSelected) {
            count = this.props.users.count;

            for (var key in count) {
                x += count[key];
            }

            titleClass = x > 1 ? "family" : "adult";

            selectedMemberships = (
                <div className="membership-selection__summary">
                    <h2 className="module__title module__title--inactive">
                        {content.selectedTitle}
                    </h2>
                    <span className="membership-selection__text">
                        <h4
                            className={
                                "memberships__type memberships__type--" +
                                titleClass
                            }
                        >
                            {this._formatUserNumbers(this.props.users.count)}
                        </h4>
                    </span>
                    {!this.props.isRestricted && (
                        <Button
                            className="button membership-selection__change"
                            onClick={this._handleChangeClick}
                        >
                            {content.buttonChange}
                        </Button>
                    )}
                </div>
            );
        } else {
            while (i < mlen) {
                if (!memberships[i].static) {
                    dynamicMemberhips.push(memberships[i]);
                }
                i++;
            }

            adultType = centreModel.getMembershipTypeFor(
                this.props.centre,
                "adult"
            );
            const adultTypeId = adultType ? adultType.id : null;

            if (memberships) {
                for (i = 0; i < mlen; i++) {
                    expanded =
                        this.state.expanded && i + 1 === this.state.expanded
                            ? true
                            : false;

                    membership = memberships[i];
                    specialPanel = null;

                    if (
                        membership.id
                            .toLowerCase()
                            .indexOf(this.props.showSpecialPanel) !== -1 &&
                        panels[this.props.showSpecialPanel]
                    ) {
                        specialPanel = panels[this.props.showSpecialPanel];
                    }

                    membershipTypes.push(
                        <Membership
                            adultType={adultTypeId}
                            count={ parseInt(this.props.users.count[membership.id]) }
                            content={content.membership}
                            details={membership}
                            expanded={expanded}
                            index={i}
                            key={"ms_" + i}
                            onAdd={this._handleAdd}
                            onRemove={this._handleRemove}
                            specialPanel={specialPanel}
                            users={this.props.users}
                            onShowMore={this._handleMoreInfoClick.bind(
                                null,
                                i + 1
                            )}
                        />
                    );
                }
            }

            buttonProps = {
                className: "membership-selection__continue",
                key: "continue-button-active",
                onClick: this._handleContinue
            };

            if (!this.props.users.total) {
                buttonText = content.buttonSelectToContinue;
                buttonProps.className +=
                    " membership-selection__continue-disabled";
                buttonProps.disabled = true;
                buttonProps.key = "continue-button-disabled";
            }

            continueButton = <Button {...buttonProps}>{buttonText}</Button>;

            membershipTitle = (
                <div className="module module--inactive">
                    <h2 className="module__title">{content.title}</h2>
                </div>
            );

            selectionView = (
                <div>
                    <ul className="module__list">{membershipTypes}</ul>
                    <div className="membership-selection__action">
                        {continueButton}
                    </div>
                </div>
            );
        }

        return (
            <div className="membership-selection" id="membership-selection">
                <div className="module-container">
                    {membershipTitle}
                    {selectedMemberships}
                    {selectionView}
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.stage1.membershipSelection,
        duration: state.selections.duration,
        durationType: state.selections.durationType,
        frozen: state.memberships.frozen,
        memberships: state.selections.memberships,
        membershipsSelected: state.memberships.selected,
        isRestricted: state.restrictions.restricted,
        preselectedUsers: state.selections.preselectedUsers,
        preselectedSubs: state.selections.preselectedSubs,
        preselectedSubsByGroups: state.selections.preselectedSubsByGroups,
        preselectionSubProcessed: state.selections.preselectionSubProcessed,
        showSpecialPanel: state.memberships.showSpecialPanel,
        staffId: state.staffLogin.staffId,
        isStaffLoggedIn: !!state.staffLogin.staffId,
        users: state.selections.users,
        user: state.user.loggedIn,

        // Membership options that are available to everyone
        // i.e. subscriptions don't contain 0000-STAFF tags
        availableToNonEAStaff: state.selections.memberships
            // Map over membership ids
            .map( ( { id: id } ) => id )

            // Reduce those membership ids into boolean
            // boolean being whether this is a staff only membership
            .reduce( ( mappedToGroups, membershipId ) => {
                return {
                    // Spread previous mapped object
                    ...mappedToGroups,

                    // Validate whether this membership id contains all 0000-STAFF
                    // If the above statement is true, we can safely assume this shouldn't be shown to a customer at home (EA staff only)
                    [ membershipId ]: !!state.centreFinder.selected && Object.values( state.centreFinder.selected.subscriptions )
                        .filter( subscription => {
                            return subscription.tags.includes( membershipId );
                        } )
                        .some( subscription => {
                            return !subscription.tags.includes( '0000-STAFF' );
                        } )
                };
            }, {} ),

        // GA
        products: hasSelections(state) ? castToUndefinedIfEmpty(
            products( state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined )( virtualPageTitle( state, 'Membership' ) )( state )
        ) : [ {
            id: undefined,
            name: virtualPageTitle( state, 'Membership' ),
            brand: state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined,
        } ]
    };
}

module.exports = connect(mapStateToProps)(Memberships);
