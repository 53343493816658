var utils = require( "../utils" ),
    validator = require( "../utils/fieldValidator" ),
    moment = require( "moment" ),
    exports;

module.exports = exports = {
    userTypesOrder: [
        "adult",
        "senior",
        "concession",
        "student",
        "free card / pay as you go",
        "parent / guardian",
        "junior",
        "junior (under 16)",
    ],
    leadUserTypesOrder: [
        "adult",
        "senior",
        "concession",
        "student",
        "parent / guardian",
        "free card / pay as you go"
    ],
    marketingChannelsList: [ "EMAIL", "MAIL", "SMS", "TELEPHONE" ],
    titlesOrder: [ "MR", "MRS", "MS", "MISS", "MAST", "DR", "DRF" ],

    promoTermsValidationRules: {
        required: true,
        validators: {
            isTrue: true
        }
    },

    bankDetailsValidationRules: {
        accountNumber: {
            required: true,
            validators: {
                isBankAccountNumber: true
            },
            except: {
                useLeadBankDetails: true
            }
        },
        holderName: {
            required: true,
            validators: {
                isBankHolderName: true,
                isAlpha: true
            },
            except: {
                useLeadBankDetails: true
            }
        },
        sortCode: {
            required: true,
            validators: {
                isBankAccountSortCode: true
            },
            except: {
                useLeadBankDetails: true
            }
        },
        confirmAccountHolder: {
            required: true,
            except: {
                useLeadBankDetails: true
            }
        },
        confirmAuth: {
            required: true,
            except: {
                useLeadBankDetails: true
            }
        },
        staffDisclaimer: {
            required: true,
            validators: {
                isTrue: true
            }
        }
    },

    userValidationRules: {
        title: {
            required: true
        },
        firstName: {
            required: true,
            except: {
                isStaff: true
            },
            validators: {
                isAlpha: true
            }
        },
        lastName: {
            required: true,
            except: {
                isStaff: true
            },
            validators: {
                isAlpha: true
            }
        },
        card: {
            required: false,
            validators: {
                isNumeric: true
            }
        },
        memberId: {
            required: false,
            validators: {
                isNumeric: true
            }
        },
        dob: {
            required: true,
            except: {
                useLeadContact: true
            },
            range: true // Range to be given -> first sub?
        },
        addressProperty: {
            required: false,
            except: {
                useLeadContact: true
            }
        },
        addressStreet: {
            required: true,
            except: {
                useLeadContact: true
            }
        },
        addressTown: {
            required: true,
            except: {
                useLeadContact: true
            }
        },
        addressPostCode: {
            required: true,
            except: {
                useLeadContact: true
            },
            validators: {
                isPostCode: true
            }
        },
        addressLocality: {
            required: false,
            except: {
                useLeadContact: true
            }
        },
        addressRegion: {
            required: false,
            except: {
                useLeadContact: true
            }
        },
        email: {
            required: true,
            except: {
                useLeadContact: true,
                isStaff: true
            },
            validators: {
                isEmail: true
            },
            remoteValidator: {
                validateEmail: true
            }
        },
        telephone: {
            required: true,
            except: {
                useLeadContact: true
            },
            validators: {
                isPhone: true
            },
            remoteValidator: {
                validatePhone: true
            }
        },
        homeTelephone: {
            required: false,
            validators: {
                isLandline: true
            },
            remoteValidator: {
                validatePhone: true
            }
        },
        mobileTelephone: {
            required: false,
            validators: {
                isMobilePhone: true
            },
            remoteValidator: {
                validatePhone: true
            }
        },
        emmergencyName: {
            required: false,
            except: {
                useLeadEmmergencyDetails: true
            }
        },
        emmergencyPhone: {
            required: false,
            validators: {
                isPhone: true
            },
            remoteValidator: {
                validatePhone: true
            },
            except: {
                useLeadEmmergencyDetails: true
            }
        },
        medicalConditions: {
            required: false
        },
        marketingChannels: {
            required: false
        },
        medicalFollowup: {
            required: false
        },
        concessionType: {
            required: false
        },
        statement: {
            required: true,
            validators: {
                isTrue: true
            }
        },
        tandc: {
            required: true,
            validators: {
                isTrue: true
            }
        },
        staffDisclaimer: {
            required: true,
            validators: {
                isTrue: true
            }
        }
    },

    staffValidationRules: {
        firstName: {
            validators: {
                isAlpha: true
            }
        },
        lastName: {
            validators: {
                isAlpha: true
            }
        },
        card: {
            required: false,
            validators: {
                isNumeric: true
            }
        },
        memberId: {
            required: false,
            validators: {
                isNumeric: true
            }
        },
        dob: {
            required: false,
            range: true // Range to be given -> first sub?
        },
        email: {
            validators: {
                isEmail: true
            },
            remoteValidator: {
                validateEmail: true
            }
        },
        homeTelephone: {
            validators: {
                isLandline: true
            },
            remoteValidator: {
                validatePhone: true
            }
        },
        mobileTelephone: {
            validators: {
                isMobilePhone: true
            },
            remoteValidator: {
                validatePhone: true
            }
        }
    },

    getUserValidationRules: function getUserValidationRules ( args = {} ) {
        const { centre, rules = { ...exports.userValidationRules } } = args;

        // Ethnicity
        if (
            (centre || {}).ethnicity_require &&
            !(rules.ethnicity || {}).required
        ) {
            rules.ethnicity = rules.ethnicity || {};
            rules.ethnicity.required = true;
        }

        return rules;
    },

    canLead: function canLead ( key ) {
        return !!exports.leadUserTypesOrder.find( function ( t ) {
            return key.toLowerCase().match( t.toLowerCase() );
        } );
    },

    anyMarketingSelected: function anyMarketingSelected ( user ) {
        var fields = [
            "marketingGuest",
            "marketingPartners",
            "marketingCouncil",
            "marketingChannels"
        ];

        for ( var c = 0; c < fields.length; c++ ) {
            if (
                user.info &&
                user.info[ fields[ c ] ] &&
                user.info[ fields[ c ] ].value === "true"
            ) {
                return true;
            }
        }

        return false;
    },

    noChannelsSelected: function noChannelsSelected ( user ) {
        if ( user.info.marketingChannels ) {
            for ( var channel in user.info.marketingChannels.value ) {
                if ( user.info.marketingChannels.value[ channel ] === true ) {
                    return false;
                }
            }
        }

        return true;
    },

    formatUserForAddMember: function formatUserForAddMember ( user, options ) {
        var homeNumber =
            user.info.currentHomeNo && user.info.currentHomeNo.value,
            mobileNumber =
                user.info.currentMobNo && user.info.currentMobNo.value,
            mobilePattern = /^(07[\d]{8,12}|447[\d]{7,11})$/,
            medicalFirst = "A - None",
            medicalSecond = "A - None",
            channels = [];

        if ( user.info.telephone || user.info.telephone.value ) {
            var tel = (
                user.info.telephone && user.info.telephone.value
            ).replace( / /g, "" );

            if ( mobilePattern.test( tel ) ) {
                mobileNumber = tel;
            } else {
                homeNumber = tel;
            }
        }

        if ( user.info.medicalConditions && user.info.medicalConditions.value ) {
            medicalFirst = user.info.medicalConditions.value[ 0 ]
                ? user.info.medicalConditions.value[ 0 ]
                : "A - None";
            medicalSecond = user.info.medicalConditions.value[ 1 ]
                ? user.info.medicalConditions.value[ 1 ]
                : "A - None";
        }

        //build consent channels object
        if ( user.info.marketingChannels && user.info.marketingChannels.value ) {
            for ( var c = 0; c < this.marketingChannelsList.length; c++ ) {
                var channel = this.marketingChannelsList[ c ];

                channels.push( {
                    id: channel,
                    optedIn:
                        user.info.marketingChannels.value[ channel ] || false,
                    captureDate: window.today
                } );
            }
        }

        //if marketing selected but no channels selected, set EMAIL true by default
        if (
            exports.anyMarketingSelected( user ) &&
            exports.noChannelsSelected( user )
        ) {
            channels[ 0 ] = {
                id: "EMAIL",
                optedIn: true,
                captureDate: window.today
            };
        }

        var formattedUser = {
            id: ( user.info.mrmId && user.info.mrmId.value ) || null,
            title: ( user.info.title && user.info.title.value ) || null,
            siteId: options.siteId,
            firstNames:
                ( user.info.firstName && user.info.firstName.value ) || null,
            lastName:
                ( user.info.lastName && user.info.lastName.value ) || null,
            homeDetails: {
                email: ( user.info.email && user.info.email.value ) || null,
                propertyName:
                    ( user.info.addressProperty &&
                        user.info.addressProperty.value ) ||
                    null,
                street:
                    ( user.info.addressStreet &&
                        user.info.addressStreet.value ) ||
                    null,
                locality:
                    ( user.info.addressLocality &&
                        user.info.addressLocality.value ) ||
                    null,
                town:
                    ( user.info.addressTown &&
                        user.info.addressTown.value ) ||
                    null,
                region:
                    ( user.info.addressRegion &&
                        user.info.addressRegion.value ) ||
                    null,
                country: "United Kingdom",
                postcode:
                    ( user.info.addressPostCode &&
                        user.info.addressPostCode.value ) ||
                    null,
                telephone: homeNumber || null,
                mobile: mobileNumber || null
            },
            consentPreferences: {
                consentChannels: channels,
                consentCampaigns: [
                    {
                        id: "GUESTPASSESANDPR",
                        optedIn:
                            user.info.marketingGuest &&
                                user.info.marketingGuest.value &&
                                user.info.marketingGuest.value === "true"
                                ? true
                                : false,
                        captureDate: window.today,
                        guardianId: null,
                        guardianName: null
                    },
                    {
                        id: "SELECTEDPARTNERS",
                        optedIn:
                            user.info.marketingPartners &&
                                user.info.marketingPartners.value &&
                                user.info.marketingPartners.value === "true"
                                ? true
                                : false,
                        captureDate: window.today,
                        guardianId: null,
                        guardianName: null
                    },
                    {
                        id: "COUNCILDATASHARE",
                        optedIn:
                            user.info.marketingCouncil &&
                                user.info.marketingCouncil.value &&
                                user.info.marketingCouncil.value === "true"
                                ? true
                                : false,
                        captureDate: window.today,
                        guardianId: null,
                        guardianName: null
                    }
                ]
            },
            correspondence: {
                healthStatement:
                    ( user.info.statement &&
                        user.info.statement.value &&
                        user.info.statement.value === "true" ) ||
                    null,
                tsAndCs:
                    ( user.info.tandc &&
                        user.info.tandc.value &&
                        user.info.tandc.value === "true" ) ||
                    null
            },
            agreeToPrivacyPolicy: {
                privacyPolicySignatureDate: window.today
            },
            dateOfBirth: utils.getShortDate(
                ( user.info.dob && user.info.dob.value ) || null,
                "-"
            ),
            userFields: [
                {
                    id: 3,
                    value: options.staffId
                },
                {
                    id: 5,
                    value: medicalFirst
                },
                {
                    id: 6,
                    value: medicalSecond
                },
                {
                    id: 8,
                    value:
                        user.info.emmergencyName &&
                        user.info.emmergencyName.value
                },
                {
                    id: 9,
                    value:
                        user.info.emmergencyPhone &&
                        user.info.emmergencyPhone.value
                }
            ],
            subscriptions: []
        },
            sub,
            i;

        if ( user.info.partner ) {
            formattedUser.userFields.push( {
                id: 31,
                value: user.info.partner
            } );
        }

        if ( (user.info.ethnicity || {}).value ) {
            formattedUser.userFields.push( {
                id: 2,
                value: user.info.ethnicity.value
            } );
        }

        if ( user.availableSubscriptions && user.availableSubscriptions.length ) {
            for ( i = 0; i < user.availableSubscriptions.length; i++ ) {
                sub = user.availableSubscriptions[ i ];

                if ( sub.duration === options.duration && sub.durationType === options.durationType
                    || sub.durationType === options.durationType && options.durationType === 1
                ) {
                    formattedUser.subscriptions.push( {
                        id: sub.id,
                        duration: sub.duration,
                        subTypeGroups: sub.groups,
                        startDate: utils.getShortDate( options.startDate, '-' ),
                        promotionCode: !!sub.discountDesc ? options.promotionCode : null
                    } );

                    if ( sub.discountDesc && sub.discountDesc === "Corporate" ) {
                        formattedUser.corporateContactId =
                            options.corporateContactId;
                    } else {
                        formattedUser.corporateContactId = 0;
                    }
                }
            }
        }

        // put the extras into the main subscriptions payload
        for ( var key in user.extras ) {
            var subTypes = user.extras[ key ];
            for ( var key2 in subTypes ) {
                sub = subTypes[ key2 ];

                //removed duration condition as it excluded montly add ons with an annual subscription
                // if (sub.duration === options.duration && sub.durationType === options.durationType) {
                if (
                    sub.durationType === options.durationType ||
                    ( 0 === sub.durationType && 1 === options.durationType )
                ) {
                    formattedUser.subscriptions.push( {
                        id: sub.id,
                        duration: sub.duration,
                        subTypeGroups: sub.groups,
                        startDate: utils.getShortDate( options.startDate, '-' ),
                        promotionCode: !!sub.discountDesc ? options.promotionCode : null
                    } );
                }
            }
        }

        return formattedUser;
    },

    formatMarketingChannels: function formatMarketingChannels (
        key,
        value,
        user
    ) {
        var userMarketingChannels =
            ( user.info &&
                user.info.marketingChannels &&
                user.info.marketingChannels.value ) ||
            {};

        for ( var channel in value ) {
            userMarketingChannels[ channel ] = value[ channel ];
        }

        return Promise.resolve( {
            key: key,
            value: userMarketingChannels,
            valid: true,
            validationErrors: {}
        } );
    },

    getAge: function getAge ( user, duration, durationType ) {
        return {
            age: exports.getAgeValue( user ),
            ageRange: exports.setValidForRange( user, duration, durationType )
        };
    },

    getAgeValue: function getAgeValue ( user ) {
        if ( !user || !user.info || !user.info.dob ) {
            return NaN;
        }
        var dob = user.info.dob.value ? user.info.dob.value : user.info.dob,
            dateParts = utils.getDateParts( dob ),
            birthday,
            ageDifMs,
            ageDate;

        if ( dateParts && dateParts.day ) {
            birthday = new Date(
                dateParts.year,
                dateParts.month - 1,
                dateParts.day
            );
            ageDifMs = Date.now() - birthday.getTime();
            ageDate = new Date( ageDifMs ); // miliseconds from epoch
            return ageDate.getUTCFullYear() - 1970;
        }

        return NaN;
    },

    getAgeInMonths: function getAgeInMonths ( user ) {
        var dobValue = user.info && user.info.dob && user.info.dob.value;
    
        // Validate if dobValue exists and is a valid date
        if (!dobValue) {
            return NaN;
        }
    
        var birthday = new Date(dobValue);
        var currentDate = new Date();
    
        // Check if the date is valid
        if (isNaN(birthday.getTime())) {
            return NaN;
        }
    
        var yearsDiff = currentDate.getFullYear() - birthday.getFullYear();
        var monthsDiff = currentDate.getMonth() - birthday.getMonth();
        var daysDiff = currentDate.getDate() - birthday.getDate();
    
        // Adjust if daysDiff is negative
        if (daysDiff < 0) {
            monthsDiff -= 1; // Borrow a month
            // Get the correct number of days in the previous month
            var previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            daysDiff += previousMonth.getDate();
        }
    
        // Adjust if monthsDiff is negative
        if (monthsDiff < 0) {
            yearsDiff -= 1;
            monthsDiff += 12;
        }
    
        // Calculate total months
        var totalMonths = (yearsDiff * 12) + monthsDiff;
    
        return totalMonths;
    },

    isSingleFreeUser: function isSingleFreeUser ( users ) {
        return users.length === 1 && users[ 0 ].type === "freeprofile";
    },

    isValidForAge: function isValidForAge (
        subscriptions,
        age,
        duration,
        durationType
    ) {
        var sub = subscriptions.find( function ( s ) {
            return (
                s.duration === duration && s.durationType === durationType
            );
        } ),
            valid = null;

        if ( sub ) {
            valid = utils.inRange( age, { min: sub.minAge, max: sub.maxAge } );
        }

        return {
            sub: sub,
            valid: valid
        };
    },

    normalizeAddressFromGBG: function normalizeAddressFromGBG ( address ) {
        var splitAddress = address.split( "," );

        return {
            addressProperty: splitAddress[ 2 ] + " " + splitAddress[ 3 ],
            addressStreet: splitAddress[ 4 ] + " " + splitAddress[ 7 ],
            addressTown: splitAddress[ 10 ],
            addressPostCode: splitAddress[ 12 ],
            addressLocality: splitAddress[ 9 ],
            addressRegion: splitAddress[ 11 ]
        };
    },

    normalizeMarketingChannels: function normalizeMarketingChannels ( params ) {
        var channels = {};

        for ( var c in params ) {
            channels[ c ] = params[ c ].toString();
        }

        return channels;
    },

    normalizeUserData: function normalizeUserData ( data ) {
        var channels =
            data.marketing &&
            exports.normalizeMarketingChannels( data.marketing.channels ),
            emmergencyName = data.raw.MemberUserFields.MemberUserField.find(
                function ( f ) {
                    return f.ID === 8;
                }
            ),
            emmergencyPhone = data.raw.MemberUserFields.MemberUserField.find(
                function ( f ) {
                    return f.ID === 9;
                }
            ),
            statement = data.raw.MemberUserFields.MemberUserField.find( function (
                f
            ) {
                return f.ID === 30 && f.Val;
            } ),
            user = {
                info: {
                    addressLocality: data.address.locality,
                    addressPostCode: data.address.postCode,
                    addressProperty: data.address.property,
                    addressRegion: data.address.region,
                    addressStreet: data.address.street,
                    addressTown: data.address.town,
                    dob: moment.utc(data.dob).format("YYYY/MM/DD"),
                    email: data.email,
                    emmergencyName: emmergencyName && emmergencyName.Val,
                    emmergencyPhone: emmergencyPhone && emmergencyPhone.Val,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    mrmId: data.mrmId,
                    telephone: data.mobile || data.telephone,
                    marketingCouncil:
                        data.marketing &&
                        data.marketing.campaigns.COUNCILDATASHARE.toString(),
                    marketingGuest:
                        data.marketing &&
                        data.marketing.campaigns.GUESTPASSESANDPR.toString(),
                    marketingPartners:
                        data.marketing &&
                        data.marketing.campaigns.SELECTEDPARTNERS.toString(),
                    marketingChannels:
                        data.marketing && data.marketing.channels,
                    siteId: data.siteId,
                    statement: null,
                    tandc: null,
                    title: data.title,
                    currentHomeNo: data.telephone,
                    currentMobNo: data.mobile
                }
            };

        if ( data.subscriptions ) {
            user.subscriptions = data.subscriptions;
        }

        if ( data.relations ) {
            user.relations = data.relations;
        }

        if (
            data.direct_debit &&
            data.direct_debit.account &&
            !data.direct_debit.account.error
        ) {
            user.directDebit = {
                account: {
                    holderName: data.direct_debit.account.name,
                    sortCode: data.direct_debit.account.sort_code_masked,
                    accountNumber:
                        data.direct_debit.account.account_number_masked
                },
                collections: data.direct_debit.collections,
                totals: data.direct_debit.totals
            };
        }

        // Ethnicity
        const ethnicityUserField = data.raw.MemberUserFields.MemberUserField.find(
            field => field.Description === "Ethnic Origin"
        );
        const ethnicityOptions = (ethnicityUserField || {}).Options || [];
        user.info.ethnicity = (ethnicityUserField || {}).Val || "";
        user.info.ethnicityOptions = ethnicityOptions.filter(
            value => value.toLowerCase().indexOf("unknown") === -1
        );
        user.info.ethnicity_initial = user.info.ethnicity;

        return user;
    },

    onlyFreeProfile: function onlyFreeProfile ( users ) {
        var key;

        for ( key in users.count ) {
            if ( key !== "freeprofile" && users.count[ key ] ) {
                return false;
            }
        }

        if ( users.count && users.count.freeprofile ) {
            return true;
        } else {
            return false;
        }
    },

    onlyJuniorProfile: function onlyJuniorProfile ( users, siteId ) {
        var key;

        for ( key in users.count ) {
            if (
                ( key !== siteId + "-JUNIOR" || key !== "0000-JUNIOR" ) &&
                users.count[ key ]
            ) {
                return false;
            }
        }

        if (
            users.count &&
            ( users.count[ siteId + "-JUNIOR" ] || users.count[ "0000-JUNIOR" ] )
        ) {
            return true;
        } else {
            return false;
        }
    },

    // check is junior on booking-profile
    onlyFreeJuniorProfile: function onlyFreeJuniorProfile ( users ) {
        var key,
            user = users.objects[ 0 ];

        for ( key in users.count ) {
            if ( key !== "freeprofile" && users.count[ key ] ) {
                return false;
            }
        }

        if ( user.info && user.info.age && user.info.age < 16 ) {
            return true;
        } else {
            return false;
        }
    },

    withAnAdult: function withAnAdult(users) {
        // an adult (16 and over) is in the list
        var foundAdult = (users.objects || []).find(function (item) {
            return ((item || {}).info || {}).age && item.info.age >= 16;
        })
        return foundAdult;
    },

    // check if is guardian user with free junior(s) user
    isGuardianWithFreeJunior: function isGuardianWithFreeJunior ( users, siteId ) {
        var key,
            leadUser = users.objects[ 0 ];

        //if first user is not a guardian
        if ( leadUser && !leadUser.guardian ) {
            return false;
        }

        //if any other userTypes exist
        for ( key in users.count ) {
            if (
                key !== "freeprofile" &&
                ( key !== siteId + "-JUNIOR" || key !== "0000-JUNIOR" ) &&
                users.count[ key ]
            ) {
                return false;
            }
        }

        //if juniors have any available subs
        for ( var u = 0; u < users.objects.length; u++ ) {
            var user = users.objects[ u ];

            if (
                user.typeDesc === "Junior" &&
                user.availableSubscriptions &&
                user.availableSubscriptions.length > 0
            ) {
                return false;
            }
        }

        return true;
    },

    setLead: function setLead ( users ) {
        var ulen = users.objects.length,
            typeLen = exports.leadUserTypesOrder.length,
            type,
            i,
            j;

        // If we have users, and not logged in
        if ( ulen ) {
            // First, make all the users be not the lead
            for ( j = 0; j < ulen; j++ ) {
                if ( users.objects[ j ].lead ) {
                    users.objects[ j ].lead = false;
                    users.objects[ j ].useLeadContact = false;
                    users.objects[ j ].useLeadEmmergencyDetails = false;
                }
            }

            // Step through each of the types (the are ordered by preference) and select the first matching user
            for ( i = 0; i < typeLen; i++ ) {
                type = exports.leadUserTypesOrder[ i ];

                for ( j = 0; j < ulen; j++ ) {
                    if (
                        users.objects[ j ].typeDesc
                            .toLowerCase()
                            .match( type.toLowerCase() )
                    ) {
                        users.objects[ j ].lead = true;
                        users.objects[ j ].useLeadContact = false;
                        users.objects[ j ].useLeadEmmergencyDetails = false;
                        users.hasLead = true;

                        // exit at the first match.
                        return;
                    }
                }
            }
        }

        // If we didn't find any lead at this point, reset it
        users.hasLead = false;
    },

    setValidForRange: function setValidForRange ( user, duration, durationType ) {
        var result;

        if ( user.availableSubscriptions && user.availableSubscriptions.length ) {
            result = exports.isValidForAge(
                user.availableSubscriptions,
                user.info.age,
                duration,
                durationType
            );

            if ( result && result.sub ) {
                // there might not be a sub with the given duration and type
                return {
                    min: result.sub.minAge,
                    max: result.sub.maxAge
                };
            }
        }

        return {
            min: 0,
            max: 115
        };
    },

    usersHaveSelections: function usersHaveSelections ( users ) {
        if (
            !users.objects ||
            !users.objects.length ||
            users.objects.find( function ( u ) {
                return !u.hasMadeSelection && u.type !== "freeprofile";
            } )
        ) {
            return false;
        }

        return true;
    },

    usersBankDetailsValid: function usersBankDetailsValid ( users ) {
        for ( var i = 0; i < users.length; i++ ) {
            if ( users[ i ].directDebit ) {
                // if a user has dd bank details, assume valid
                return true;
            }

            if (
                users[ i ].typeDesc &&
                ( users[ i ].type.toLowerCase() !== "freeprofile" ||
                    ( users[ i ].type.toLowerCase() === "freeprofile" &&
                        Object.keys( users[ i ].extras ).length ) ) &&
                ( ( !users[ i ].useLeadBankDetails &&
                    !users[ i ].bankDetails.valid ) ||
                    ( users[ i ].lead && !users[ i ].bankDetails.valid ) )
            ) {
                return false;
            }
        }

        return true;
    },

    validateFieldDynamic: function validateFieldDynamic ( args ) {
        const { key, value, ruleOptions = {}, centre } = args;
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[key] =
            ruleOptions.rules[key] ||
            exports.getUserValidationRules({ centre })[key];

        return validator.validate( key, value, ruleOptions );
    },

    validateField: function validateField(key, value, ruleOptions) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[key] =
            ruleOptions.rules[key] || exports.userValidationRules[key];

        return validator.validate( key, value, ruleOptions );
    },

    validateFsgField: function validateFsgField ( key, value, ruleOptions ) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[ key ] =
            ruleOptions.rules[ key ] || exports.userValidationRules[ key ];
        ruleOptions.rules[ key ][ "remoteValidator" ] = {
            validateFsgPostcode: true
        };

        return validator.validate( key, value, ruleOptions );
    },

    validateMarketingPreferences: function ( user, marketingPreferences ) {
        // Early return when we select "no" for marketing preferences
        if (
            ( !user.marketingCouncil || user.marketingCouncil.value == 'false' ) &&
            ( !user.marketingGuest || user.marketingGuest.value == 'false' ) &&
            ( !user.marketingPartners || user.marketingPartners.value == 'false' )
         ) {
            return Promise.resolve( {
                key: 'marketingChannels',
                value: marketingPreferences,
                valid: true
            } );
        }

        // Map preferences validity
        const preferences = Object.entries( marketingPreferences )
            .flatMap( ([channel, checked]) => ({
                field: channel,
                value: !!checked
            }));

        // Are some of the preferences checked
        const somePreferencesChecked = preferences.some(channel => !!channel.value);

        return Promise.resolve( {
            key: 'marketingChannels',
            value: marketingPreferences,
            valid: somePreferencesChecked,
            validationErrors: preferences
        } );
    },

    validateEmergencyField: function validateEmergencyField (
        key,
        value,
        user,
        ruleOptions
    ) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[ key ] =
            ruleOptions.rules[ key ] || exports.userValidationRules[ key ];

        return validator.validate( key, value, ruleOptions, user );
    },

    validateMaskedBankDetailField: function validateMaskedBankDetailField (
        key,
        value,
        ruleOptions
    ) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[ key ] =
            ruleOptions.rules[ key ] || exports.bankDetailsValidationRules[ key ];

        return validator.validateMaskedBank( key, value, ruleOptions );
    },

    validateBankDetailField: function validateBankDetailField (
        key,
        value,
        ruleOptions
    ) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[ key ] =
            ruleOptions.rules[ key ] || exports.bankDetailsValidationRules[ key ];

        return validator.validate( key, value, ruleOptions );
    },

    validatePromoTerms: function validatePromoTerms ( key, value, ruleOptions ) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[ key ] = exports.promoTermsValidationRules;

        return validator.validate( key, value, ruleOptions );
    },

    validateStaffField: function validateStaffField ( key, value, ruleOptions ) {
        ruleOptions = ruleOptions || {};
        ruleOptions.rules = ruleOptions.rules || {};
        ruleOptions.rules[ key ] =
            ruleOptions.rules[ key ] || exports.staffValidationRules[ key ];

        return validator.validate( key, value, ruleOptions );
    },

    validateDynamic: function validateDynamic ( args ) {
        const {
            user,
            options,
            isStaff,
            isLessons,
            centre,
            rules = exports.getUserValidationRules({ centre }),
        } = args;
        return exports.validate( user, options, isStaff, isLessons, rules );
    },

    validate: function validate ( user, options, isStaff, isLessons, rules ) {
        if (!rules) {
            rules = exports.getUserValidationRules();
        }
        var freeSwimGym = false,
            promises = [],
            ruleOptions,
            facility,
            value,
            key;

        for ( key in rules ) {
            ruleOptions = ( options && options[ key ] ) || {};
            ruleOptions.rules = {};
            ruleOptions.rules[ key ] = rules[ key ];
            ruleOptions.useLeadContact = user.useLeadContact;
            ruleOptions.isStaff = isStaff;
            ruleOptions.useLeadEmmergencyDetails =
                user.useLeadEmmergencyDetails;

            if ( key === "dob" ) {
                value = exports.getAgeValue( user );
            } else if ( key === "staffDisclaimer" && !isStaff ) {
                continue;
            } else {
                value = user.info[ key ] && user.info[ key ].value;
            }

            for ( facility in user.facilities ) {
                if (
                    facility.toLowerCase().indexOf( "fsg" ) !== -1 &&
                    user.facilities[ facility ].selected === "on"
                ) {
                    freeSwimGym = true;
                }
            }

            if ( "emmergencyName" === key || "emmergencyPhone" === key ) {
                promises.push(
                    exports.validateEmergencyField(
                        key,
                        value,
                        user,
                        ruleOptions
                    )
                );
            } else if ( freeSwimGym && "addressPostCode" === key && !isLessons ) {
                promises.push(
                    exports.validateFsgField( key, value, ruleOptions )
                );
            } else if ( 'marketingChannels' === key ) {
                promises.push(
                    exports.validateMarketingPreferences( user.info, {
                        // Default those unchecked for checking
                        ...{
                            EMAIL: false,
                            SMS: false,
                            MAIL: false,
                            TELEPHONE: false,
                        },

                        // Spread in known values (mutates existing key/value pairs)
                        ...value || {}
                    })
                );

            } else {
                promises.push( exports.validateField( key, value, ruleOptions ) );
            }
        }

        return Promise.all( promises ).then( function ( results ) {
            let valid = true;
            let errors = {};

            results.forEach( result => {
                if ( !result.valid ) {
                    valid = false;
                    errors[ result.key ] =
                        errors[ result.key ] || result.validationErrors;

                    return;
                }

                // If this is invalid for the customer's age range
                if ( result.key === "dob" && user.invalidForAgeRange) {
                    valid = false;
                }
            } );

            user.valid = valid;

            return {
                valid: valid,
                errors: errors
            };
        } );
    },

    validateBankDetails: function validateBankDetails ( user, options, isStaff ) {
        var rules = exports.bankDetailsValidationRules,
            promises = [],
            ruleOptions,
            value,
            key;

        for ( key in rules ) {
            ruleOptions = ( options && options[ key ] ) || {};
            ruleOptions.rules = {};
            ruleOptions.rules[ key ] = rules[ key ];
            ruleOptions.useLeadBankDetails = user.useLeadBankDetails;

            if ( key === "staffDisclaimer" && !isStaff ) {
                continue;
            }

            value =
                user.bankDetails.fields[ key ] &&
                user.bankDetails.fields[ key ].value;

            promises.push(
                exports.validateBankDetailField( key, value, ruleOptions )
            );
        }

        return Promise.all( promises ).then( function ( results ) {
            var valid = true,
                errors = {},
                result,
                i;

            for ( i = 0; i < results.length; i++ ) {
                result = results[ i ];

                if ( !result.valid ) {
                    valid = false;
                    errors[ result.key ] =
                        errors[ result.key ] || result.validationErrors;
                }
            }

            user.bankDetailsValid = valid;

            if ( user.directDebit ) {
                // if user has direct debit details, then assume they are valid
                valid = true;
                errors = {};
            }

            return {
                valid: valid,
                errors: errors
            };
        } );
    }
};
