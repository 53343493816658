var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var newState;
    var defaultState = {
        discounts: [],
        discount: null,
        expanded: false
    };

    state = state || defaultState;

    switch (action.type) {

        case actionTypes.CENTRE_FINDER.CENTRE_SELECTED:
            newState = Object.assign({}, state);
            if (!action.centre) {
                newState.discounts = [];
                newState.discount = null;
            }
            return newState;

        case actionTypes.DISCOUNTS.DISCOUNTS_RECEIVED:
            return Object.assign({}, state, {
                discounts: action.discounts || []
            });

        case actionTypes.DISCOUNTS.DISCOUNT_SELECTED:
            return Object.assign({}, state, {
                discount: action.discount
            });

        case actionTypes.DISCOUNTS.EXPAND_DISCOUNT:
            return Object.assign({}, state, {
                expanded: action.expanded
            });

        case actionTypes.DISCOUNTS.DISCOUNT_REMOVED:
            return Object.assign({}, state, {
                // discounts: [],
                discount: null
            });

        case actionTypes.DISCOUNTS.WIPEOUT:
            return Object.assign({}, state, defaultState);

        default:
            return state;
    }
};
