var actionTypes = require('./actionTypes.js'),

    history     = require('../utils/history');

import config from "../config";

// Sadly, we can't update the history in the reducer as
//  'history' here will dispatch an action, and reducers
//  are not allowed to do so.

// showStep should set the state and update the path with history.push
// setStep should only be done on deep link, or at first load, to make sure the state reflects the current path.
// next should find the next set in the flow to pick the index and path from
// previous should find the previous set in the flow to pick the index and path from

module.exports = {
    back: function back() {
        return function (dispatch, getState) {
            var state = getState().stages,
                prevStep = state.showStep - 1;

            if (prevStep !== -1 && prevStep < state.currentStep) {
                dispatch({
                    type: actionTypes.STAGES.SHOW_STEP,
                    step: prevStep
                });

                history.push(state.steps[state.flow[prevStep]].path);
            }
        };
    },

    goHome: function goHome() {
        return function (dispatch, getState) {
            var state = getState().stages,
                homePath = state.steps[state.flow[0]].path;

            dispatch({
                type: actionTypes.STAGES.SHOW_STEP,
                step: 0
            });

            history.push(homePath);
        };
    },

    next: function next() {
        return function (dispatch, getState) {
            var state = getState().stages,
                nextStep = state.showStep + 1;

            // If the next step is undefined, reset nextStep to the current step
            if (!state.flow[nextStep] || !state.steps[state.flow[nextStep]] || !state.steps[state.flow[nextStep]].path) {
                nextStep = state.showStep;
            }

            // If we haven't visited this step previously,
            //  make sure it's the current max step visited.
            if (nextStep > state.currentStep) {
                dispatch({
                    type: actionTypes.STAGES.SET_STEP,
                    step: nextStep
                });
            }

            dispatch({
                type: actionTypes.STAGES.SHOW_STEP,
                step: nextStep
            });


            history.push(state.steps[state.flow[nextStep]].path);
        };
    },

    prepareStep: function prepareStep(pathname) {
        return function (dispatch, getState) {
            var state = getState().stages,
                stepIndex,
                i;

            for (i = 0; i < state.flow.length; i++) {
                stepIndex = state.flow[i];

                if (pathname === state.steps[stepIndex].path) {
                    dispatch({
                        type: actionTypes.STAGES.SHOW_STEP,
                        step: i
                    });

                    break;
                }
            }
        };
    },

    setFlow: function setFlow(flow) {
        return {
            type: actionTypes.STAGES.SET_FLOW,
            flow: flow
        };
    },

    setStep: function setStep(stepIndex) {
        return {
            type: actionTypes.STAGES.SET_STEP,
            step: stepIndex
        };
    },

    showStep: function showStep(stepIndex) {
        return function (dispatch, getState) {
            var state = getState().stages;

            history.push(state.steps[state.flow[stepIndex]].path);

            dispatch({
                type: actionTypes.STAGES.SHOW_STEP,
                step: stepIndex
            });
        };
    },

    disableStep: function disableStep ( stepIndex ) {
        return {
            type: actionTypes.STAGES.DISABLE_STEP_NAVBAR,
            step: stepIndex,
        };
    },

    enableStep: function enableStep ( stepIndex ) {
        return {
            type: actionTypes.STAGES.ENABLE_STEP_NAVBAR,
            step: stepIndex,
        };
    },

    showStepFromPath: function showStepFromPath(path) {
        return function (dispatch, getState) {
            var state = getState().stages,
                stepIndex,
                i;

            for (i = 0; i < state.flow.length; i++) {
                stepIndex = state.flow[i];

                if (path === state.steps[stepIndex].path) {
                    dispatch({
                        type: actionTypes.STAGES.SHOW_STEP,
                        step: i
                    });

                    break;
                }
            }
        };
    },

    setShortMembershipsPath: function setRootPath(path) {
        return function (dispatch, getState) {
            dispatch({
                type: actionTypes.STAGES.SET_SHORT_MEMBERSHIPS_PATH
            });
        }
    },

    /**
     * Fetches applicable mapped subscription ids in relation to provided addon group ids
     * @param {array} addonGroupIds Addon group ids
     */
    prepareApplicableAddonGroupIds(addonGroupIds) {
        return dispatch => fetch( `${config.services.subscriptionGroupMappings.urls.get}?groups=${addonGroupIds.join(',')}` )
            .then(response => response.json())
            .then(response => response.subsciptions)
            .then(subscriptionIds => {
                dispatch({
                    type: actionTypes.STAGES.APPLICABLE_ADDON_SUBSCRIPTION_IDS,
                    ids: subscriptionIds
                })
            })
    },

    /**
     * Unmounts applicable add on ids
     * @returns object
     */
    unmountApplicableAddonGroupIds() {
        return { type: actionTypes.STAGES.UNMOUNT_APPLICABLE_ADDON_SUBSCRIPTION_IDS };
    }
};
