import actions from "../actions";
import history from "./history";

export function redirectToPayment(config) {
    const { bankDetails = {}, dispatch, settings = {}, staffId } = config;
    const isIcp = staffId && settings.in_centre_payments;
    const redirectUrl = isIcp ? '/in-centre-payment' : bankDetails.paymentUrl;
    setTimeout(() => {
        if (isIcp) {
            return history.push(redirectUrl);
        }
        dispatch(actions.app.showLoader(true));
        window.location.href = redirectUrl;
    });
}
