var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../../../actions'),

    UserSearchForm  = require('../../../common/userSearchForm'),

    Login           = require('../../../common/loginForm'),
    Button          = require('../../../common/button'),

    scrollToComponent = require('react-scroll-to-component'),
    Panel;

Panel = React.createClass({
    componentDidMount() {
        scrollToComponent(document.getElementById('juniorPanel'), {
            align: 'top',
            offset: -100
        });
    },

    _handleUseFreeProfile: function _handleUseFreeProfile(e) {
        e.preventDefault();

        if(this.props.isLessons) {
            this._onLoginComplete();
        } else {
            var users = Object.assign({}, this.props.users.count);

            users.freeprofile = 1;

            // Hide junior panel.
            this.props.dispatch(actions.memberships.showSpecialPanel());

            // Set new user totals.
            this.props.dispatch(actions.selections.setUsers(users, true));
            this.props.dispatch(actions.memberships.selectMemberships());
        }
    },

    _onLoginComplete: function _onLoginComplete() {
        var users = Object.assign({}, this.props.users.count);
        users.freeprofile = 1;

        if(this.props.isLessons) {
            var callback = this.props.callback,
                promises = [],
                self = this;

            // Set new user totals.
            promises.push(this.props.dispatch(actions.selections.setUsers(users, true)));

            Promise.all(promises)
                .then(function() {
                    self.props.dispatch(actions.memberships.selectMemberships());

                    //hide modal
                    self.props.dispatch(actions.memberships.showSpecialPanel());

                    callback && callback();
                });
        } else {
            // Hide junior panel.
            this.props.dispatch(actions.memberships.showSpecialPanel());

            // Set new user totals.
            this.props.dispatch(actions.selections.setUsers(users, true));
            this.props.dispatch(actions.memberships.selectMemberships());
        }
    },


    render: function() {
        var content = this.props.content,
            extraButtons = [
                <span key='extra-sep' className='junior-panel__controls-separator'>- or -</span>,
                <Button key='extra-button-freeprofile' className='button button--primed' onClick={ this._handleUseFreeProfile }>{ content.buttonFreeAccount }</Button>
            ],
            panelContent;

        if(this.props.staffId) {
            panelContent = <div>
                <div className='junior-panel__search-header'>
                    <Button key='extra-button-freeprofile' className='button button--primed' onClick={ this._handleUseFreeProfile }>{ content.buttonFreeAccount }</Button>
                    <div key='extra-sep' className='junior-panel__controls-separator'>- or -</div>
                </div>
                
                <UserSearchForm
                    isGuardian={ true }
                    isJuniorPanel={ true }
                    uid={ 0 }
                    userType={ 'Adult' }
                    callback={ this._onLoginComplete }
                />
            </div>;
        } else {
            panelContent = <Login
                callback={ this._onLoginComplete }
                content={ content }
                extraButtons={ extraButtons }
                showLabels={ true }
                isJuniorPanel={ true }
                loggedInUser={this.props.loggedInUser}
            />
        }

        return (<div className='junior-panel' id='juniorPanel'>
            <div className='junior-panel__info'>{ content.info }</div>
            { panelContent }
        </div>);
    }
});

Panel.propTypes = {
    onComplete: React.PropTypes.func
};

function mapStateToProps(state) {
    return {
        content: state.app.content.stage1.membershipSelection.panels.junior,
        staffId: state.staffLogin.staffId,
        users: state.selections.users,
        loggedInUser: state.user
    };
}

module.exports = connect(mapStateToProps)(Panel);
