var React           = require('react'),
    connect         = require('react-redux').connect,
    Diving          = require('./diving.js'),
    RookieLifeguard = require('./rookielifeguard.js'),
    Swimming        = require('./swimming.js'),
    Synchronised    = require('./synchronised.js'),
    Waterpolo       = require('./waterpolo.js'),

    IconsIndex;

IconsIndex = React.createClass({
    render: function() {
        var icon = this.props.icon;
        var iconUrl = this.props.iconUrl;

        switch(icon) {
            case 'swimming':
            case 'juniorfitnessswimming':
                return <Swimming color={ this.props.color } />;
            case 'diving':
            case 'divinglesson':
                return <Diving color={ this.props.color } />;
            case 'rookielifeguard':
                return <RookieLifeguard color={ this.props.color } />;
            case 'artisticswimming':
                return <Synchronised color={ this.props.color } />;
            case 'waterpolo':
                return <Waterpolo color={ this.props.color } />;
        }

        return iconUrl ? <img src={iconUrl} /> : null;
    }
});

module.exports = connect()(IconsIndex);
