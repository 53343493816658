var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/noStagesHeader'),
    Component       = require('../components/directDebit');

module.exports = [{

    path: '/direct-debit/:status?',

    handler: function (params) {

        return {
            noBg: true,
            header: <Header />,
            component: <Component status={params.status} />
        };
    }
}];
