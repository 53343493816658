import React from "react";
import { renderToString } from "react-dom/server";

/**
 * Renders JSX to string for Google Map Markers
 * @todo convert this into a React component at a later date to simplify working with
 * Google's Maps API
 * @param {object} content 
 */
const GoogleMapMarkerInfoWindow = content =>
    renderToString(
        <div>
            <h4>{ content.heading }</h4>
            <div dangerouslySetInnerHTML={ { __html: content.body } }></div>
        </div>
    );

export default GoogleMapMarkerInfoWindow;