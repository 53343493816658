var React       = require('react'),
    Diving;

Diving = React.createClass({
    render: function () {
        var props = Object.assign({}, this.props),
            color = props.color;

        return (<svg width="46px" height="55px" viewBox="0 0 46 45" version="1.1">
            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="selection" transform="translate(-11.000000, -7.000000)">
                    <g id="Group-14" transform="translate(5.000000, 5.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="60" height="50"></rect>
                        <path d="M31.2795305,45.2509324 C29.5911742,45.7029974 27.8647832,46.1475547 26.195122,46.4891507 C32.1414367,48.1724626 43.6633884,45.275064 45.1280488,43.8679246 C44.0637203,43.7242076 42.8466082,43.6282175 41.4883162,43.625 C38.0561632,43.625 34.9244203,44.2749439 31.2795305,45.2509324" id="Fill-6" fill={ color }></path>
                        <path d="M6.74005534,39.0328697 C6.77206094,39.0455717 9.73617151,40.7809094 15.218927,40.8202252 C19.2111358,40.8202252 23.3633727,39.7937811 27.2497671,38.7933459 C31.2746348,37.7584338 35.0735037,36.764652 38.7802748,36.7501354 C47.640601,36.7156585 51.3806841,43.2765537 51.4041984,43.347927 C51.5903534,43.9140741 50.9829002,44.0483526 50.7013815,43.9128644 C50.6648037,43.8953235 46.9887318,41.8430402 40.6588077,41.8279187 C36.8063784,41.8279187 33.3576115,42.6481062 29.6149157,43.7634644 C26.5436844,44.6786147 22.8205838,45.7661494 20.0432814,45.7498183 C10.7002586,45.6947762 6.25409274,39.8663641 6.06271231,39.6401471 C5.8732914,39.4169545 6.11692588,38.7903216 6.74005534,39.0328697" id="Fill-9" fill={ color }></path>
                        <g id="Group-3" transform="translate(6.000000, 2.000000)" fill="#333333">
                            <polygon id="Fill-1" points="0.481096721 32.8248277 10.2101407 32.8248277 10.2101407 30.1694998 0.481096721 30.1694998"></polygon>
                            <path d="M27.562954,9.40673656 C27.5702212,6.94762344 25.5901852,4.97094148 23.1416934,4.96591033 C20.6926426,4.95864311 18.7064573,6.92638082 18.6997491,9.38381689 C18.6935999,11.842371 20.6702819,13.821289 23.1193327,13.8263202 C25.5695016,13.8335874 27.5568048,11.8647316 27.562954,9.40673656" id="Fill-2"></path>
                            <path d="M13.2746561,7.0893341 C12.0951315,7.08653902 11.0268512,7.08374393 10.0558397,7.08150787 L16.203902,3.90182262 C17.0720545,3.45237344 17.4119365,2.38465213 16.9630463,1.51594066 C16.5141561,0.648347213 15.4469938,0.30790623 14.5771643,0.75735541 L2.74502335,6.87690787 C1.93389056,7.29672918 0.918157774,8.30854885 0.780639741,10.5367882 C0.614611872,13.2709374 2.59464794,14.8166177 4.0799545,14.6880439 L10.2084512,14.6880439 L6.69613549,18.7097058 C6.37577294,19.0765264 6.3662606,19.6207935 6.67360933,19.9985848 L12.0123971,26.5609931 C12.4489889,27.0965308 13.0845906,27.374362 13.7263414,27.376039 C14.2177168,27.3782751 14.7135643,27.2150423 15.1261184,26.8801915 C16.0742102,26.1081898 16.2173184,24.714562 15.4464348,23.7675882 L11.9324578,19.4486275 C11.9324578,19.4486275 15.1473611,15.7926603 16.5722938,14.1480341 C19.7251463,10.5116325 16.3609856,7.09827836 13.2746561,7.0893341 L7.38588589,19.4486275" id="Fill-4" transform="translate(9.304054, 13.967544) scale(-1, 1) translate(-9.304054, -13.967544) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>);
    }
});

module.exports = Diving;
