var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {

    state = state || {
        tokens: {},
        user: {},
        validating: false
    };

    switch (action.type) {

        case actionTypes.SAVE_SELECTIONS.TOKEN_RECEIVED:
            var newTokens = Object.assign({}, state.tokens);
            newTokens[action.email] = action.token;

            return Object.assign({}, state, {
                tokens: newTokens
            });

        case actionTypes.SAVE_SELECTIONS.USER_DATA_VALIDATED:
            var newState = Object.assign({}, state);

            newState.user[action.field] = {
                valid: action.valid,
                value: action.value,
                validationErrors: action.validationErrors
            };

            return Object.assign({}, state, newState);

        case actionTypes.SAVE_SELECTIONS.VALIDATING:

            return Object.assign({}, state, {
                validating: action.validating
            });

        default:
            return state;
    }
};
