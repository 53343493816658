var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {

    var showFullFormUIDs,
        suggestions,
        sections,
        useLogin,
        users,
        defaultState = {
            frozen: false,
            frozenUsers: {},
            medicalConditions: null,
            unfrozenSections: {},
            showFullFormUIDs: {},
            useLogin: {},
            addressSuggestionsLoading: false,
            addressSuggestions:[],
            manualAddressUsers: {}
        };

    state = state || defaultState;

    switch (action.type) {

        case actionTypes.PERSONAL_DETAILS.ADDRESS_SUGGESTIONS_LOADING:
            return Object.assign({}, state, {
                addressSuggestionsLoading: action.loading
            });

        case actionTypes.PERSONAL_DETAILS.ADDRESS_SUGGESTIONS_RECEIVED:
            suggestions = Object.assign({}, state.addressSuggestions);

            suggestions[action.uid] = action.suggestions;

            return Object.assign({}, state, {
                addressSuggestions: suggestions
            });

        case actionTypes.PERSONAL_DETAILS.FREEZE:
            sections = Object.assign({}, state.unfrozenSections);

            if (sections[action.uid]) {
                delete sections[action.uid][action.section];
            }

            return Object.assign({}, state, {
                unfrozenSections: sections
            });

        case actionTypes.PERSONAL_DETAILS.FREEZE_ALL:
            return Object.assign({}, state, {
                frozen: true,
                frozenUsers: {},
                unfrozenSections: {}
            });

        case actionTypes.PERSONAL_DETAILS.FREEZE_USER:
            users = Object.assign({}, state.frozenUsers);

            users[action.uid] = true;

            return Object.assign({}, state, {
                frozenUsers: users
            });

        case actionTypes.PERSONAL_DETAILS.SET_MEDICAL_CONDITIONS_LIST:
            var conditionsArray = [];

            action.conditons = [
                'A - None',
                'Addisons Disease',
                'Allergic to Penicillin',
                'Allergic to Plasters',
                'Allergies leading to Anaphylactic shock ',
                'Allergy To Foam',
                'Allergy to Latex',
                'Amputee',
                'Anaemia',
                'Anxiety',
                'Arthritis',
                'Aspergers Syndrome',
                'Asthma',
                'Attention Deficit Disorder - ADD',
                'Attention Deficit Hyperactivity Disorder - ADHD',
                'Autism',
                'Bi Lateral Talpiax',
                'Bladder Problems',
                'Blood/Vitamin Deficiency Related Illness',
                'C O P D',
                'Cancer',
                'Cerebal Palsy',
                'Cholesterol',
                'Chronic Fatigue Syndrome',
                'Coeliac Disease',
                'Coffin-Lowry Syndrome',
                'Colitis',
                'Colostomy',
                'Crohns Disease',
                'Cystic Fibrosis',
                'Dementia',
                'Depression',
                'Diabetes',
                'Dizziness',
                'Downs Syndrome',
                'Dubowitz Syndrome',
                'Dwarfism',
                'Dyslexia',
                'Dyspraxia',
                'Eczema',
                'Ehlers-Danlos Syndrome',
                'Epilepsy',
                'Fibromyalgia',
                'Genetic Disorder',
                'Global Development Delay',
                'H I V',
                'Had Stroke',
                'Haemophilia',
                'Hayfever',
                'Heart condition-ht attack/by pass/angina/hole in h',
                'Hiatus Hernia',
                'High Blood Pressure',
                'Hip Dysplasia',
                'Hirschsprungs Disease',
                'Hydrocephalus',
                'Hypermobility Syndrome',
                'Hysterectomy',
                'Joint Dislocation',
                'Joint problem',
                'Joint Replacement',
                'Kidney Disorder',
                'Langerhens Cell Histiocytosis',
                'Learning Difficulties',
                'Leukemia',
                'Ligament Problems',
                'Liver Transplant',
                'Low Blood Pressure',
                'Lower Back Problem',
                'M.E.',
                'Mental Health Difficulties',
                'Migraines',
                'Mongolia Blue Spots',
                'MPHD - Hormone Deficiency',
                'MS',
                'Muscular Dystrophy',
                'Neurofibromatosis Type I (nf-1)',
                'Nose bleeds',
                'Nut Allergy',
                'O C D ',
                'Obesity / Overweight B M I 25+',
                'Obesity / Overweight B M I 30+',
                'Osteoarthritis',
                'Osteoporosis',
                'Other Food Allergy',
                'Other Medical Condition',
                'Parkinsons Disease',
                'Partially Sighted',
                'Permanent hearing problem',
                'Permanent Physical disability',
                'Permanent Sight Problem',
                'Perthes Disease',
                'Pregnant',
                'Prostate Problems',
                'Raynard’s Disease',
                'Rheumatism',
                'Rheumatoid Arthritis',
                'Rubinstein-Taybi Syndrome',
                'Sciatica',
                'Scoliosis - Curved Spine',
                'SED - Growth disorder',
                'Seizures',
                'Selective Mute',
                'Sensory Processing Disorder',
                'Speech Impediment',
                'Splenectomy',
                'Spondylitis',
                'Temporary hearing problem eg Gromits',
                'Temporary Physical disability eg Injury',
                'Tendon Problems',
                'Thyroid problems',
                'Tourettes Syndrome',
                'Tracheo-Oesophageal Fistula',
                'Tuberous Sclerosis',
                'Vaso Vagual Events',
                'Vauto Immune Disorder / Vittilgo',
                'Vertigo',
                'Wheelchair User / Mobility Problems',
                'Williams Syndrome',
                'Marfan Syndrome',
                'Osteogenesis Imperfecta (oi)',
                'Generalised Dystonia',
                'Oesophageal Dismobility',
                'Noonan Syndrome',
                'Hemi Hyperplasia',
                'Sturge Web Syndrome',
                'Episodic Ataxia',
                'Schizophrenia',
                'Laryngomalacia',
                'Bone Cyst'
            ];

            for(var i = 0; i < action.conditons.length; i++) {
                conditionsArray.push({name: action.conditons[i]});
            }

            return Object.assign({}, state, {
                medicalConditions: conditionsArray
            });

        case actionTypes.PERSONAL_DETAILS.TOGGLE_USER_LOGIN:
            useLogin  = Object.assign({}, state.useLogin);

            useLogin[action.uid] = action.visible;

            return Object.assign({}, state, {
                useLogin: useLogin
            });

        case actionTypes.PERSONAL_DETAILS.UNFREEZE:
            sections = Object.assign({}, state.unfrozenSections);
            sections[action.uid] = sections[action.uid] || {};
            sections[action.uid][action.section] = true;

            return Object.assign({}, state, {
                unfrozenSections: sections,
                checkedProfile: true
            });

        case actionTypes.PERSONAL_DETAILS.UNFREEZE_ALL:
            return Object.assign({}, state, {
                frozen: false,
                unfrozenSections: {}
            });

        case actionTypes.PERSONAL_DETAILS.UNFREEZE_USER:
            users = Object.assign({}, state.frozenUsers);

            users[action.uid] = false;

            return Object.assign({}, state, {
                frozenUsers: users
            });

        case actionTypes.PERSONAL_DETAILS.USE_MANUAL_ADDRESS_ENTRY:
            showFullFormUIDs = Object.assign({}, state.showFullFormUIDs);

            showFullFormUIDs[action.uid] = true;

            return Object.assign({}, state, {
                showFullFormUIDs: showFullFormUIDs
            });

        case actionTypes.PERSONAL_DETAILS.WIPEOUT:
            return  Object.assign({}, defaultState);

        default:
            return state;
    }
};
