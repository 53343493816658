var React           = require('react'),
    connect         = require('react-redux').connect,
    PartnerLogo;

PartnerLogo = React.createClass({

    render: function render() {
        const { preselectedPartner } = this.props;
        if (preselectedPartner) {
            return (
                <img
                    src={preselectedPartner.response.partner_logo}
                    alt={preselectedPartner.name}
                />
            );
        }

        return null;
    }
});

PartnerLogo.defaultProps = {
    delay: null,
};

PartnerLogo.propTypes = {
    preselectedPartner: React.PropTypes.object
};

function mapStateToProps(state) {
    return {
        preselectedPartner: state.selections.preselectedPartner
    };
}

module.exports = connect(mapStateToProps)(PartnerLogo);
