const { getFeesTotal } = require('../../utils/stateSelectors');

var React = require('react'),

    utils = require('../../utils'),

    Price = require('../common/price'),

    DurationToggle;

DurationToggle = React.createClass({
    displayName: 'DurationToggle',

    render: function() {
        var content = this.props.content,
            price = this.props.pricing,
            isDisabled = this.props.isDisabled,
            monthPrices = price && price.periods[0] && price.periods[0][1],
            yearPrices = price && price.periods[0] && price.periods[0][12],
            annualDisabled,
            ddDisabled,
            blocks = [],
            perMonth,
            extras,
            total,
            fees;

        const isMonthly = this.props.d === 1;

        if (monthPrices) {
            const monthlyTotal = monthPrices.total - monthPrices.discountTotal;
            fees = monthPrices && monthPrices.fees.total ? <span className='duration-toggle__block-note'>{ content.plusJoiningFeeOf.replace('{amount}', utils.formatPrice(monthPrices.fees.total)) }</span> : '';
            extras = yearPrices && yearPrices.extras.total ? <span className='duration-toggle__block-note'>{ content.plusAnnualExtrasOf.replace('{amount}', utils.formatPrice(yearPrices.extras.total)) }</span> : '';
            total = <Price amount={ monthlyTotal } />;

            if (isDisabled === 'dd' || isDisabled === 'both') {
                ddDisabled = true;
            } else {
                ddDisabled = false;
            }

            blocks.push(<button key='block_month' onClick={ this.props.onChangeDuration.bind(null, 1) } className={ 'duration-toggle__block' + (isMonthly ? ' duration-toggle__block--active' : '') + (ddDisabled ? ' duration-toggle__block--disabled' : '')} >
                <div className='duration-toggle__block-content'>
                    <div  className='duration-toggle__block-inner'>
                        <span className='duration-toggle__block-title'>{ content.billedMonthly }</span>
                        <span className='duration-toggle__block-duration'>{ content.pricePerMonth }</span>
                        <span className='duration-toggle__block-price'>{ total }</span>
                        { fees }
                        { extras }
                        { yearPrices && yearPrices.discountTotal > 0 && (
                            <div className='pricing__discount'>
                                <div className='pricing__discount__row'>
                                    <div className='pricing__discount__label'>
                                        Price before discount:
                                    </div>
                                    <div className='pricing__discount__price'>
                                        { utils.formatPrice(monthPrices.total) }
                                        &nbsp;
                                        <em>per month</em>
                                    </div>
                                </div>
                                <div className='pricing__discount__row--big'>
                                    <div className='pricing__discount__label'>
                                        You save:
                                    </div>
                                    <div className='pricing__discount__price'>
                                        { utils.formatPrice(monthPrices.discountTotal) }
                                        &nbsp;
                                        <em>per month</em>
                                    </div>
                                </div>
                            </div>
                        )}
                        <span className='duration-toggle__block-note'>{ content.noCancellationFeesNoContract }</span>
                    </div>
                </div>
            </button>);
        }

        if (yearPrices) {
            const yearlyTotal = yearPrices.total;
            // yearPrices.fees.total is probably wrong when mixing monthly and yearly pricing
            const yearlyFeesTotal = getFeesTotal({ duration: 12, durationType: 0, periods: this.props.pricing.periods }); // yearPrices.fees.total;
            fees = yearPrices && yearlyFeesTotal ? <span className='duration-toggle__block-note'>{ content.plusJoiningFeeOf.replace('{amount}', utils.formatPrice(yearlyFeesTotal) )}</span> : '';
            extras = monthPrices && monthPrices.extras.total ? <span className='duration-toggle__block-note'>{ content.plusMonthlyExtrasOf.replace('{amount}', utils.formatPrice(monthPrices.extras.total)) }</span> : '';
            total = <Price amount={ yearlyTotal } />;

            if(isDisabled === 'annual' || isDisabled === 'both') {
                annualDisabled = true;
            } else {
                annualDisabled = false;
            }

            perMonth = utils.formatPrice(yearPrices.total / 12);

            blocks.push(<button key='block_year' onClick={ this.props.onChangeDuration.bind(null, 12) } className={ 'duration-toggle__block' + (this.props.d === 12 ? ' duration-toggle__block--active' : '') + (annualDisabled ? ' duration-toggle__block--disabled' : '')}>
                <div  className='duration-toggle__block-content'>
                    <div  className='duration-toggle__block-inner'>
                        <span className='duration-toggle__block-title'>{ content.billedAnnually }</span>
                        <span className='duration-toggle__block-duration'>{ content.pricePerYear }</span>
                        <span className='duration-toggle__block-price'>{ total }</span>
                        { fees }
                        { extras }
                        { yearPrices.discountTotal > 0 && (
                            <div className='pricing__discount'>
                                <div className='pricing__discount__row'>
                                    <div className='pricing__discount__label'>
                                        Price before discount:
                                    </div>
                                    <div className='pricing__discount__price'>
                                        { utils.formatPrice(yearPrices.total + yearPrices.discountTotal) }
                                    </div>
                                </div>
                                <div className='pricing__discount__row--big'>
                                    <div className='pricing__discount__label'>
                                        You save:
                                    </div>
                                    <div className='pricing__discount__price'>
                                        { utils.formatPrice(yearPrices.discountTotal) }
                                    </div>
                                </div>
                            </div>
                        )}
                        <span className='duration-toggle__block-note'>Equivalent to <b>{ perMonth }</b> per month</span>
                    </div>
                </div>
            </button>);
        }

        return <div className='module-container'>
            <div className='module module--inactive'>
                <div className='duration-toggle'>
                    <h2>{ content.chooseYourBillingPlan }</h2>
                    <div className='duration-toggle__blocks'>
                        { blocks }
                    </div>
                </div>
            </div>
        </div>;
    }
});

DurationToggle.propTypes = {
    content: React.PropTypes.object,
    d: React.PropTypes.number,
    onChangeDuration: React.PropTypes.func,
    pricing: React.PropTypes.object,
    isDisabled: React.PropTypes.string
};

module.exports = DurationToggle;
