const { virtualPageTitle } = require( "../../../../common/google/analytics/lessons" );
const { products, castToUndefinedIfEmpty, hasSelections } = require( "../../../../common/google/analytics/products" );
const { push } = require( "../../common/google/analytics/datalayer" );

var React = require("react"),
    connect = require("react-redux").connect,
    moment = require("moment"),
    actions = require("../../../actions"),
    viewUtils = require("../../../utils/viewUtils"),
    FacilitiesPanel = require("./facilitiesPanel"),
    FrozenFacilitiesPanel = require("./frozenFacilitiesPanel"),
    Button = require("../../common/button"),
    Input = require("../../common/forms/input"),
    scrollToComponent = require("react-scroll-to-component"),
    FacilitiesSelection;

FacilitiesSelection = React.createClass({
    getInitialState: function getInitialState() {
        return {
            initialSearch: true,
            taggingFired: false
        };
    },

    componentDidUpdate: function componentDidUpdate() {
        var facilitiesSelection = document.getElementById(
            "facilities-selection"
        );

        if (this.state.initialSearch && facilitiesSelection) {
            scrollToComponent(facilitiesSelection, {
                align: "top",
                offset: -100
            });
            this.setState({
                initialSearch: false
            });
        }

        if (
            this.props.centre &&
            this.props.membershipsSelected &&
            !this.state.taggingFired
        ) {
            push({
                event: "virtual pageview",
                virtualPagePath: "/virtual/memberships-options/",
                virtualPageTitle: "Join - Everyone Active – Memberships Options",
                products: this.props.products
            })
            this.setState({
                taggingFired: true
            });
        }
    },

    _handleMoreDetails: function _handleMoreDetails(key, e) {
        e.preventDefault();

        var elt = document.querySelector(
                ".facilities-panel__list-item-details-list-" + key
            ),
            button = document.querySelector(
                ".facilities-panel__list-item-details-button-" + key
            );

        if (button.classList.contains("button__more-info--expanded")) {
            button.classList.remove("button__more-info--expanded");
        } else {
            button.classList.add("button__more-info--expanded");
        }

        elt && viewUtils.furl(elt);
    },

    _onChangeFacility: function _onChangeFacility(userId, facilityId, value) {
        this.props.dispatch(
            actions.selections.setFacilityForUser(userId, facilityId, value)
        );

        if (this.props.users.objects[userId].info.dob) {
            // fix for FSG
            // force dob to be re-validated so that age range for sub is correctly set
            let dob = this.props.users.objects[userId].info.dob.value;

            this.props.dispatch(
                actions.selections.changeData(userId, {
                    info: {
                        dob
                    }
                })
            );
        }
    },

    _onChangeFacilitySite: function _onChangeFacilitySite(
        userId,
        facilityId,
        value
    ) {
        this.props.dispatch(
            actions.selections.setFacilitySitesForUser(
                userId,
                facilityId,
                value
            )
        );
    },

    _onChangeFacilityTime: function _onChangeFacilityTime(
        userId,
        facilityId,
        value
    ) {
        this.props.dispatch(
            actions.selections.setFacilityTimesForUser(
                userId,
                facilityId,
                value
            )
        );
    },

    _onChangeData: function _onChangeData(userId, field, value) {
        var data = {
            info: {}
        };

        data.info[field] = value;

        this.props.dispatch(actions.selections.changeData(userId, data));
    },

    _onUnfreeze: function _onUnfreeze() {
        this.props.dispatch(actions.memberships.unfreeze());
    },

    render: function() {
        if (!this.props.centre) {
            return null;
        }

        var ulen = this.props.users.objects.length,
            content = this.props.content,
            single = ulen === 1,
            changeSelectionButton,
            panelsList = [],
            firstNameValid,
            lastNameValid,
            userTitle,
            firstName,
            lastName,
            panel,
            header,
            user,
            i;

        // Create a panel for each user.
        for (i = 0; i < ulen; i++) {
            user = this.props.users.objects[i];

            var firstNameDisabled = false,
                firstNamePlaceholder = content.fields.firstName.placeholder,
                lastNameDisabled = false,
                lastNamePlaceholder = content.fields.lastName.placeholder;

            if (user.isLoggedInUser) {
                firstName = user.info.firstName.value;
                firstNamePlaceholder = user.info.firstName.value;
                firstNameValid = true;
                firstNameDisabled = true;

                lastName = user.info.lastName.value;
                lastNamePlaceholder = user.info.lastName.value;
                lastNameValid = true;
                lastNameDisabled = true;
            } else {
                firstName =
                    (user.info &&
                        user.info.firstName &&
                        user.info.firstName.value) ||
                    "";
                firstNameValid =
                    !user.info ||
                    !user.info.firstName ||
                    user.info.firstName.valid
                        ? true
                        : false;

                lastName =
                    (user.info &&
                        user.info.lastName &&
                        user.info.lastName.value) ||
                    "";
                lastNameValid =
                    !user.info ||
                    !user.info.lastName ||
                    user.info.lastName.valid
                        ? true
                        : false;
            }

            if (!single) {
                if (this.props.frozen) {
                    if (!firstName || !lastName) {
                        firstName = user.typeDesc;
                        lastName = user.idInType + 1;
                    }

                    header = (
                        <div className="facilities-selection__item-header">
                            <h4
                                className={
                                    "facilities-selection__item-title facilities-selection__item-title--" +
                                    user.type
                                }
                            >
                                {firstName} {lastName}
                            </h4>
                        </div>
                    );
                } else {
                    if (user.guardian) {
                        userTitle = user.typeDesc;
                    } else {
                        userTitle = user.typeDesc + " " + (user.idInType + 1);
                    }

                    header = (
                        <div className="facilities-selection__item-header">
                            <div className="facilities-selection__item-header-inner">
                                <h4
                                    className={
                                        "facilities-selection__item-title facilities-selection__item-title--" +
                                        user.type
                                    }
                                >
                                    {content.selectFacilitiesFor.replace(
                                        "{user}",
                                        userTitle
                                    )}
                                </h4>

                                <Input
                                    className="facilities-selection__item-header-input"
                                    input={{
                                        onChange: this._onChangeData.bind(
                                            null,
                                            i,
                                            "firstName"
                                        ),
                                        placeholder: firstNamePlaceholder,
                                        "aria-label":
                                            content.fields.firstName.ariaLabel,
                                        disabled: firstNameDisabled
                                    }}
                                    disableHints={true}
                                    name={"firstName_" + i}
                                    type="text"
                                    valid={firstNameValid}
                                    value={firstName}
                                />

                                <Input
                                    className="facilities-selection__item-header-input"
                                    input={{
                                        onChange: this._onChangeData.bind(
                                            null,
                                            i,
                                            "lastName"
                                        ),
                                        placeholder: lastNamePlaceholder,
                                        "aria-label":
                                            content.fields.lastName.ariaLabel,
                                        disabled: lastNameDisabled
                                    }}
                                    disableHints={true}
                                    name={"lastName_" + i}
                                    type="text"
                                    valid={lastNameValid}
                                    value={lastName}
                                />
                            </div>
                        </div>
                    );
                }
            }

            if (this.props.frozen) {
                panel = (
                    <FrozenFacilitiesPanel
                        centre={this.props.centre}
                        content={content.frozenFacilitiesPanel}
                        id={"ffp_" + i}
                        index={i}
                        user={user}
                    />
                );
                changeSelectionButton = (
                    <div className="facilities-panel__selected-list">
                        <Button
                            className="button button--change facilities-selection__change"
                            onClick={this._onUnfreeze}
                        >
                            {content.buttonChange}
                        </Button>
                    </div>
                );
            } else {
                panel = (
                    <FacilitiesPanel
                        centre={this.props.centre}
                        content={content.facilitiesPanel}
                        id={"fp_" + i}
                        index={i}
                        groupRestrictions={this.props.groupRestrictions}
                        onChangeFacility={this._onChangeFacility}
                        breakpoint={this.props.breakpoint}
                        onChangeFacilitySite={this._onChangeFacilitySite}
                        onChangeFacilityTime={this._onChangeFacilityTime}
                        onShowMore={this._handleMoreDetails.bind(null, i + 1)}
                        user={user}
                        promo={this.props.promo}
                        staffId={this.props.staffId}
                        staffPromo={this.props.staffPromo}
                        duration={this.props.duration}
                    />
                );
            }

            panelsList.push(
                <li
                    key={"facilities_" + i}
                    className="facilities-selection__membership-list-item"
                >
                    {header}
                    {panel}
                </li>
            );
        }

        // If no membership has been selected, don't show the facilities panel.
        if (!this.props.membershipsSelected) {
            return null;
        }

        var frozenClass =
            this.props.frozen === true ? "module__title--inactive" : "";

        return (
            <div className="facilities-selection" id="facilities-selection">
                <div className="module-container">
                    <div className="module module--memberships module--inactive">
                        <h2
                            className={
                                "module__title module__title--memberships " +
                                frozenClass
                            }
                        >
                            {content.title}
                        </h2>
                    </div>
                    <ul className="facilities-selection__membership-list">
                        {panelsList}
                    </ul>
                    {changeSelectionButton}
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.stage1.facilitiesSelection,
        frozen: state.memberships.frozen,
        groupRestrictions: state.selections.deeplinkGroups,
        duration: state.selections.duration,
        loggedInUser: state.user,
        membershipsSelected: state.memberships.selected,
        promo: state.selections.promo,
        staffPromo: state.selections.staffPromo,
        staffId: state.staffLogin.staffId,
        users: state.selections.users,
        breakpoint: state.app.breakpoint,

        // GA
        products: hasSelections(state) ? castToUndefinedIfEmpty(
            products( state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined )( virtualPageTitle( state, 'Membership' ) )(state)
        ) : [ {
            id: undefined,
            name: virtualPageTitle( state, 'Membership' ),
            brand: state.centreFinder.selected ? state.centreFinder.selected.info.name : undefined,
        } ]
    };
}

module.exports = connect(mapStateToProps)(FacilitiesSelection);
