var React           = require('react'),
    connect         = require('react-redux').connect,

    MembershipProgress;

MembershipProgress = React.createClass({

    render: function render() {

        var content = this.props.content,
            centreClass = this.props.centre ? 'pricing__steps-item--complete' : '',
            membershipClass = this.props.membershipsSelected ? 'pricing__steps-item--complete' : '',
            membershipFrozenClass = this.props.membershipsFrozen ? 'pricing__steps-item--complete' : '';

        return (
            <div className='pricing__details'>
                <h4 className='pricing__title'>{ content.title }</h4>
                <ul className='pricing__steps-list'>
                    <li className={'pricing__steps-item ' + centreClass }>{ content.yourChosenCentre }</li>
                    <li className={'pricing__steps-item ' + membershipClass }>{ content.whosJoining }</li>
                    <li className={'pricing__steps-item ' + membershipFrozenClass }>{ content.membershipTypes }</li>
                </ul>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.sidebar.membershipProgress,
        membershipsFrozen: state.memberships.frozen,
        membershipsSelected: state.memberships.selected
    };
}

module.exports = connect(mapStateToProps)(MembershipProgress);
