var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    state = state || {
        ready: false,
        show: false,
        online: false
    };

    switch (action.type) {

        case actionTypes.LIVECHAT.HIDE:
            return Object.assign({}, state, {
                show: false
            });

        case actionTypes.LIVECHAT.OFFLINE:
            return Object.assign({}, state, {
                online: false
            });

        case actionTypes.LIVECHAT.ONLINE:
            return Object.assign({}, state, {
                online: true
            });

        case actionTypes.LIVECHAT.READY:
            return Object.assign({}, state, {
                ready: true
            });

        case actionTypes.LIVECHAT.SHOW:
            return Object.assign({}, state, {
                show: true
            });

        default:
            return state;
    }
};
