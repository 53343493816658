var React       = require('react'),
    connect     = require('react-redux').connect,

    actions     = require('../../actions'),

    Modal       = require('../common/modal'),

    SuccessModal;

import { VideoPlayer } from "../../../common/videos/video-player";

SuccessModal = React.createClass({
    componentDidMount: function componentDidMount() {
        // Brighcove player
        const brightcovePlayerScript = document.createElement('script');
        brightcovePlayerScript.src = '//players.brightcove.net/713339181001/SJwRH6vne_default/index.min.js';
        brightcovePlayerScript.async = true;
        brightcovePlayerScript.id = 'brightcove-player';

        const brightcovePlayerElement = document.querySelector('#brightcove-player');
        if(!brightcovePlayerElement) {
            document.body.appendChild( brightcovePlayerScript);
        }

        // Vimeo player
        const vimeoPlayerScript = document.createElement( 'script' );
        vimeoPlayerScript.src = 'https://player.vimeo.com/api/player.js';
        vimeoPlayerScript.async = true;
        vimeoPlayerScript.id = 'brightcove-player';

        const vimeoPlayerElement = document.querySelector( '#vimeo-player' );
        if ( !vimeoPlayerElement ) {
            document.body.appendChild( vimeoPlayerScript );
        }
    },

    componentWillUnmount() {
        const brightcovePlayerElement = document.querySelector('#brightcove-player');
        if(brightcovePlayerElement) {
            document.body.removeChild(brightcovePlayerElement);
        }

        var vimeoPlayerElement = document.querySelector( '#vimeo-player' );
        if ( vimeoPlayerElement ) {
            document.body.removeChild( vimeoPlayerElement );
        }
    },

    render: function render() {
        const { videoId, videoProvider } = this.props.options;

        return <Modal id={ this.props.id } className='modal--video'>
            <div style={{display: 'block', position: 'relative'}}>
                <div style={{paddingTop: '56.25%'}}>
                    {/* @todo we will eventually be removing these as it's here just for the Brightcove -> Vimeo work. Eventually, we'd use the Vimeo player component directly */ }
                    <VideoPlayer video={ { video_id: videoId, video_provider_name: videoProvider } } brightcove_player_id="SJwRH6vne"></VideoPlayer>
                </div>
            </div>
        </Modal>;
    }
});

SuccessModal.propTypes = {
    options: React.PropTypes.object
};

module.exports = connect()(SuccessModal);
