var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    config          = require('../../config'),

    Button          = require('../common/button'),
    Input           = require('../common/forms/input'),
    Modal           = require('../common/modal'),

    SaveSelectionsModal;

SaveSelectionsModal = React.createClass({
    fields: {},

    _hasToken: function _hasToken(email) {
        return this.props.tokens[email];
    },

    _onChangeInput: function _onChangeInput(field, value) {
        this.props.dispatch(actions.saveSelections.changeUserData(field, value));
    },

    _validateForm: function _validateForm(e) {
        e.preventDefault();
        this._saveSelections();
    },

    _saveSelections: function _saveSelections() {
        var payload = {
                email: (this.props.email && this.props.email.value) || (this.props.user && this.props.user.info.email),
                firstName: (this.props.firstName && this.props.firstName.value) || (this.props.user && this.props.user.info.firstName),
                lastName: (this.props.lastName && this.props.lastName.value) || (this.props.user && this.props.user.info.lastName),
                label: this.props.label && this.props.label.value
            },
            self = this;

        // If we've got an email, then we can send data
        if (payload.email) {
            // If we've got a token, we know it's an update
            if (this.props.tokens[payload.email]) {
                payload.token = this.props.tokens[payload.email];
            }

            this.props.dispatch(actions.saveSelections.save(payload, function () {
                self.props.dispatch(actions.app.hideModal(self.props.id));
            }));
        }
    },

    _isValid: function _isValid() {
        return (this.props.user && this.props.label && this.props.label.valid) ||
            (!this.props.user &&
                this.props.firstName && this.props.firstName.valid &&
                this.props.lastName && this.props.lastName.valid &&
                this.props.email && this.props.email.valid &&
                this.props.tandc && this.props.tandc.valid);
    },

    render: function render() {
        var content = this.props.content,
            valid = !this.props.validating && this._isValid(),
            modalButtonClass = 'button' + (valid ? '' : ' button--disabled'),
            modalButtonText,
            form;

        if (this.props.user) {
            form = <form className='modal__form' onSubmit={ this._validateForm }>
                    <fieldset>
                    <p dangerouslySetInnerHTML={ {__html: content.loggedInText.replace('{email}', this.props.user && this.props.user.info.email) } }></p>

                    <Input className= 'modal__input input--hints'
                        input={ {
                            className: 'login__input',
                            onChange: this._onChangeInput.bind(null, 'label'),
                            placeholder: content.fields.label.placeholder.replace('{name}', (this.props.firstName || (this.props.user && this.props.user.info.firstName) || 'Tim')),
                            autoFocus: true,
                            required: true
                        } }
                        label={ {
                            text: content.fields.label.label
                        } }
                        minLength={ 3 }
                        disableHints={ true }
                        name='save_selections_label'
                        type='text'
                        valid={ !this.props.label || (this.props.label.valid) }
                        value={ (this.props.label && this.props.label.value) || '' }
                    />

                    <Button className={ modalButtonClass } disabled={ !valid }>{ content.buttonSave }</Button>
                </fieldset>
            </form>;

        } else {
            modalButtonText =  this._hasToken(this.props.email) ? content.buttonUpdate : content.buttonSave;

            form = <form className='modal__form' onSubmit={ this._validateForm }>
                    <fieldset>
                    <p dangerouslySetInnerHTML={ {__html: content.loggedOutText } }></p>

                    <div className='modal__input'>
                        <label className='save-selections__label disable-speak' htmlFor='save_selections_firstName'>{ content.yourName }</label>
                    </div>

                    <Input className='modal__input'
                        input={ {
                            onChange: this._onChangeInput.bind(null, 'firstName'),
                            placeholder: content.fields.firstName.placeholder,
                            autoFocus: true,
                            required: true
                        } }
                        label={ {
                            className: 'a11y',
                            text: content.fields.firstName.label
                        } }
                        name='save_selections_firstName'
                        type='text'
                        valid={ !this.props.firstName || (this.props.firstName.valid) }
                        value={ (this.props.firstName && this.props.firstName.value) || '' }
                    />

                    <Input className='modal__input'
                        input={ {
                            onChange: this._onChangeInput.bind(null, 'lastName'),
                            placeholder: content.fields.lastName.placeholder,
                            required: true
                        } }
                        label={ {
                            className: 'a11y',
                            text: content.fields.lastName.label
                        } }
                        name='save_selections_lastName'
                        type='text'
                        valid={ !this.props.lastName || (this.props.lastName.valid) }
                        value={ (this.props.lastName && this.props.lastName.value) || '' }
                    />

                    <Input className= 'modal__input'
                        input={ {
                            onChange: this._onChangeInput.bind(null, 'email'),
                            placeholder: content.fields.email.placeholder,
                            required: true
                        } }
                        label={ {
                            text: content.fields.email.label
                        } }
                        name='save_selections_email'
                        type='email'
                        valid={ !this.props.email || (this.props.email.valid) }
                        value={ (this.props.email && this.props.email.value) || '' }
                    />

                    <Input className='modal__input--radio'
                        input={ {
                            onChange: this._onChangeInput.bind(null, 'tandc'),
                            required: true,
                            className:  'forms__input--radio'
                        } }
                        label={ {
                            text: content.fields.acceptTandC.label,
                            links: [ 
                                {
                                    link: config.services.userForm.urls.Tandc,
                                    linkText: config.services.userForm.titles.Tandc
                                }, {
                                    link: config.services.userForm.urls.privacy,
                                    linkText: config.services.userForm.titles.privacy
                                }
                            ]
                        } }
                        options={ [
                            {
                                value: 'true',
                                text: 'Yes'
                            }, {
                                value: 'false',
                                text: 'No'
                            }
                        ] }
                        name={ 'radio_tandc' }
                        radioLabelClassName={ 'modal__input-label' }
                        type='radio'
                        valid={ !this.props.tandc || (this.props.tandc.valid) }
                        value={ (this.props.tandc && this.props.tandc.value) || '' }
                    />

                    <Button className={ modalButtonClass } disabled={ !valid }>{ modalButtonText }</Button>
                </fieldset>
            </form>;
        }

        return <Modal title={ content.title } className='modal--save-selections' id={ this.props.id }>
            <div className='save-selections__modal'>
                { form }
            </div>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.modals.saveSelections,
        email: state.saveSelections.user.email,
        firstName: state.saveSelections.user.firstName,
        label: state.saveSelections.user.label,
        lastName: state.saveSelections.user.lastName,
        tandc: state.saveSelections.user.tandc,
        user: state.user.user,
        tokens: state.saveSelections.tokens,
        validating: state.saveSelections.validating
    };
}

module.exports = connect(mapStateToProps)(SaveSelectionsModal);
