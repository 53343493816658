var exports;

module.exports = exports = {

    addClass: function addClass(elem, className) {
        if (!this.hasClass(elem, className)) {
            elem.className += ' ' + className;
        }
    },

    adjustHeight: function adjustHeight(element) {
        var wrapper = element.querySelector('.inner');
        element.style.height = wrapper.clientHeight + 'px';
    },

    checkIE: function checkIE() {
        var check = false;

        if (navigator.appName === 'Microsoft Internet Explorer'
            ||  !!(navigator.userAgent.match(/Trident/)
            || navigator.userAgent.match(/rv:11/))) {

            check = true;
        }

        return check;
    },


    isTouchDevice: function isTouchDevice() {
        return (('ontouchstart' in window)
            || (navigator.MaxTouchPoints > 0)
            || (navigator.msMaxTouchPoints > 0));
    },


    formatDate: function formatDate(dateStr, sep) {
        var date = new Date(dateStr);
        sep = sep || '/';

        return exports.zeroPad(date.getDate(), 2) + sep + exports.zeroPad(date.getMonth() + 1, 2) + sep + date.getFullYear();
    },

    formatDateWithTextMonths: function formatDateWithTextMonths(dateStr, monthNames) {

        var parts = dateStr.split('-'),
            year = parts[0],
            month = parseInt(parts[1], 10),
            day = parts[2];

        if (parts.length !== 3 || !month || month > 12) {
            return dateStr;
        }
        return day + ' ' + monthNames[month -1] + ' ' + year;
    },


    formatDateForMRM: function formatDateForMRM(dateStr) {
        var date = new Date(dateStr);
        var sep = '-';

        return date.getFullYear() + sep + exports.zeroPad(date.getMonth() + 1, 2) + sep + exports.zeroPad(date.getDate(), 2);
    },



    furl: function furl(element, parent) {
        // Handle no elements
        if (!element) {
            return;
        }

        if(parent) {
            var inner = element.querySelector('ul');

            if (element.clientHeight) {
                element.style.height = 0;
                parent.style.height = (parent.clientHeight - inner.clientHeight) + 2 + 'px';
            } else {
                element.style.height = inner.clientHeight + 'px';
                parent.style.height = (parent.clientHeight + inner.clientHeight) + 2 + 'px';
            }
        } else {
            if (element.clientHeight) {
                element.style.height = 0;
            } else {
                var wrapper = element.querySelector('.inner');
                element.style.height = wrapper.clientHeight + 2 + 'px';
            }
        }
    },

    hasClass: function hasClass(elem, className) {
        return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
    },

    removeClass: function removeClass(elem, className) {
        var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
        if (this.hasClass(elem, className)) {
            while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
                newClass = newClass.replace(' ' + className + ' ', ' ');
            }
            elem.className = newClass.replace(/^\s+|\s+$/g, '');
        }
    },

    toggleClass: function toggleClass(elem, className) {
        if (this.hasClass(elem, className)) {
            this.removeClass(elem, className);
        } else {
            this.addClass(elem, className);
        }
    },

    capitalizeFirstLetter: function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    // Pads a string 'str' with the zeroes for the length 'len'
    //  pad('a', 4); => '000a';
    zeroPad: function(str, len) {
        if (!str && str !== 0) {
            return '';
        }

        if (!len) {
            return str;
        }

        function padRec(str, len) {
            return str.length >= len ? str : padRec('0' + str, len);
        }

        if (typeof str === 'number') {
            str += '';
        }

        return padRec(str, len);
    }
};
