var React               = require('react'),
    connect             = require('react-redux').connect,

    config              = require('../../config'),
    actions             = require('../../actions'),

    Stages              = require('./stages'),
    scrollToComponent   = require('react-scroll-to-component'),

    Button              = require('./button'),
    HeaderPrices        = require('./headerPrices'),
    KioskHeader         = require('./kioskHeader'),


    utils               = require('../../utils'),

    Header;

Header = React.createClass({

    _toggleSidebar: function _toggleSidebar() {
        document.querySelector('html').classList.toggle('noscroll');
        document.querySelector('body').classList.toggle('noscroll');
        this.props.dispatch(actions.app.toggleSidebar());
    },


    /**
     * [_goHome description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    _goHome: function (e) {
        e.preventDefault();
        this.props.dispatch(actions.stages.goHome());
    },


    /**
     * [_scrollToFAQs description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    _scrollToFAQs: function(e) {
        e.preventDefault();
        var faqSection = document.getElementById('faqs');
        scrollToComponent(faqSection, {
            align: 'top',
            offset: -100
        });
    },

    render: function() {
        var centreId = this.props.centre && this.props.centre.info && this.props.centre.info.site_id,
            enquiryUrl = config.services.app.urls.enquiries + (centreId ? '&siteid=' + centreId: ''),
            homepageUrl = config.services.app.urls.homepage,
            stages = this.props.showStages ? <Stages /> : null,
            content = this.props.content,
            lessons = this.props.lessons,
            lessonType = utils.getLessonType(lessons),
            headerClass = 'header',
            enquiriesText = content.header.enquiries_text,
            enquiriesChoices,
            headerPrices,
            enquiries;

        if(this.props.showSidebar) {
            headerClass += ' header--open';
        }

        if ((['bp-s', '"bp-s"', 'bp-sm', '"bp-sm"', 'bp-m', '"bp-m"'].indexOf(this.props.breakpoint) !== -1)) {
            headerPrices = <HeaderPrices
                allUsersHaveSelections={ this.props.allUsersHaveSelections }
                content={ this.props.content.headerPrice }
                d={ this.props.d }
                dt={ this.props.dt }
                membershipsSelected={ this.props.membershipsSelected }
                pricing={ this.props.pricing }
                />;
        }

        if(lessons && lessons.inView) {
            enquiriesText = content.header.enquiries_lessons_text;
            enquiriesChoices = config.services.lessons.enquiries[lessonType] || config.services.lessons.enquiries['default'];
            enquiryUrl = config.services.lessons.urls.enquiry + enquiriesChoices + (centreId ? '&siteid=' + centreId: '');
        }

        enquiries = (<div className='header__enquiries'>
                <a href={ enquiryUrl } className='header__enquiries__title' target='_blank'>{ enquiriesText }</a>
                { (this.props.faqs && this.props.faqs.length ?
                    <p className='header__enquiries__text'>Or <a onClick={ this._scrollToFAQs }>{ content.header.faqs_text }</a></p> : null) }
            </div>);

        return (
            <header className={ headerClass }>
                { this.props.kioskHomeUrl && (
                    <KioskHeader
                        kioskHomeUrl={ this.props.kioskHomeUrl }
                        logoutUrl={ config.services.app.urls.logout }
                        showLogout={ this.props.isUserLoggedIn }
                    />
                )}
                <div className='header__band'>
                    <div className='header__logo-container'>
                        <a key='gohome' href={ this.props.kioskHomeUrl || homepageUrl } title={ content.header.title }>
                            <img className='header__logo mobile' src='/img/joinonline/logo-block-white.svg' />
                            <img className='header__logo desktop' src='/img/joinonline/logo-block.svg' />
                        </a>
                    </div>

                    <Button className='header__sidebar-button' onClick={ this._toggleSidebar }><span></span></Button>

                    { headerPrices }
                    { stages }

                    { enquiries }
                </div>

                { stages }
            </header>
        );
    }
});

function mapStateToProps(state) {
    return {
        kioskHomeUrl: state.app.kioskHomeUrl,
        allUsersHaveSelections: state.selections.allUsersHaveSelections,
        breakpoint: state.app.breakpoint,
        centre: state.centreFinder.selected,
        content: state.app.content,
        d: state.selections.duration,
        dt: state.selections.durationType,
        faqs: state.faqs.results,
        lessons: state.lessons,
        membershipsSelected: state.memberships.selected,
        pricing: state.selections.pricing,
        showSidebar: state.app.showSidebar,
        isUserLoggedIn: state.user.loggedIn
    };
}

module.exports = connect(mapStateToProps)(Header);
