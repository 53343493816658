var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),
    history         = require('../../utils/history'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    CompletePaymentAtTillModal;

CompletePaymentAtTillModal = React.createClass({
    getInitialState: function getInitialState() {
        return { };
    },

    _onContinue: function _onRetry() {
        // this.props.dispatch(actions.app.hideModal('completePaymentAtTill'));

        this.props.dispatch(actions.directDebitPage.checkPaid());
    },

    _onCancel: function _onCancel() {
        this.props.dispatch(actions.app.hideModal('completePaymentAtTill'));
    },

    render: function render() {
        var { modal } = this.props;

        let mainText = modal.text;

        return (
            <Modal title={ modal.title } className='modal--confirm' noClose>
                <p dangerouslySetInnerHTML={ {__html: mainText } }></p>
                <div className='modal__buttons'>
                    <Button className='button button--greyed-out' onClick={ this._onCancel }>{ modal.buttonCancel }</Button>
                    <Button className='button button--primed' onClick={ this._onContinue }>{ modal.buttonContinue }</Button>
                </div>
            </Modal>
        );
    }
});
    
function mapStateToProps(state) {
    return {
        modal: state.app.content.modals.completePaymentAtTill,
        mrmId: state.selections.users.objects[0].info.mrmId.value
    };
}

module.exports = connect(mapStateToProps)(CompletePaymentAtTillModal);