var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {

    state = state || {
        results: null,
        votes: {}
    };

    switch (action.type) {

        case actionTypes.FAQS.RESULTS_RECEIVED:
            return Object.assign({}, state, {
                results: action.results
            });

        case actionTypes.FAQS.VOTE_SUBMITTED:
            const { payload = {} } = action;
            const { id = 0 } = payload;
            const stateNew = { ...state };
            if (!stateNew.votes) {
                stateNew.votes = {};
            }
            const vote = { ...(stateNew.votes[id] || {}), ...payload };
            stateNew.votes[id] = vote;
            return stateNew;

        case actionTypes.FAQS.ANSWER_SCORING:
            return { ...state, answerScoring: action.answerScoring };

        default:
            return state;
    }
};
