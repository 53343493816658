var React       = require('react'),
    RookieLifeguard;

RookieLifeguard = React.createClass({
    render: function () {
        var props = Object.assign({}, this.props),
            color = props.color;

        return (<svg width="43px" height="50px" viewBox="0 0 43 40" version="1.1">
            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="selection" transform="translate(-9.000000, -9.000000)">
                    <g id="Group-14" transform="translate(6.000000, 5.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="60" height="50"></rect>
                        <path d="M17.8811453,40.0678644 C16.1693644,39.8398532 13.0959077,39.6536747 11.4816894,40.1469328 C12.1800325,41.7066948 23.9150915,45.4523302 29.5690568,43.2200271 C28.6317085,42.9855801 21.4354131,40.5413553 17.8811453,40.0678644" id="Fill-1" fill={ color }></path>
                        <path d="M4.31915219,40.2014072 C4.35251076,40.1825595 7.76354177,38.0969007 13.6355894,38.0821903 C17.2087148,38.0821903 20.4078485,38.9151667 23.8808982,40.0497062 C26.7704079,40.9934703 30.2852734,42.1013472 32.8736163,42.0682488 C41.5397963,41.9565417 45.5691354,36.1013431 45.727941,35.8581618 C46.0493109,35.3649037 45.4413392,34.9989826 45.0997663,35.2403251 C45.0729855,35.2587131 42.3206687,37.0175252 37.2346617,37.0575191 C33.5313909,37.0575191 29.6791811,36.0144598 26.0741066,34.9971438 C22.3412359,33.9448905 18.816034,32.9491804 15.3777523,32.9197596 C7.15838916,32.9197596 3.70554248,39.5619645 3.66748552,39.6272419 C3.48612768,39.9379991 3.83098949,40.4671138 4.31915219,40.2014072" id="Fill-4" fill={ color }></path>
                        <path d="M12.3437667,33.9379667 L21.7251,33.9379667 L20.6857667,29.1253 L13.3827667,29.1253 L12.3437667,33.9379667 Z M14.0614333,25.9836333 L20.0071,25.9836333 L19.5814333,24.0133 L14.4871,24.0133 L14.0614333,25.9836333 Z M11.5984333,22.5036333 L7.58576667,10.1296333 L10.5737667,9.16096667 L14.3717667,20.8713 L23.5437667,20.8713 L23.5437667,24.0133 L22.7961,24.0133 L27.0691,43.7973 L23.8541,43.7973 L22.4037667,37.0799667 L11.6654333,37.0799667 L10.2144333,43.7973 L7.0001,43.7973 L11.5984333,22.5036333 Z M21.8761,7.47363333 C21.8761,5.54563333 20.3211,4.0003 18.3997667,4.0003 C16.4794333,4.0003 14.9257667,5.54563333 14.9257667,7.47363333 C14.9257667,9.40163333 16.4794333,10.9489667 18.3997667,10.9489667 C20.3211,10.9489667 21.8761,9.40163333 21.8761,7.47363333 Z M15.2747667,19.7716333 L20.6151,19.7716333 L21.0047667,15.1209667 C21.1864333,13.9649667 20.0927667,12.3326333 17.9451,12.3136333 C15.7971,12.3326333 14.7034333,13.9649667 14.8851,15.1209667 L15.2747667,19.7716333 Z M28.8757667,23.8120876 L28.8757667,30.7019667 C28.8757667,31.6603 28.0987667,32.4369667 27.1407667,32.4369667 C26.1824333,32.4369667 25.4057667,31.6603 25.4057667,30.7019667 L25.4057667,26.3426333 L24.6727667,26.1713 L24.6727667,22.9609275 C24.6727667,22.8062878 24.7981269,22.6809275 24.9527667,22.6809275 C24.9742126,22.6809275 24.9955875,22.6833914 25.0164709,22.6882706 L28.6594709,23.5394308 C28.7861573,23.5690301 28.8757667,23.6819894 28.8757667,23.8120876 Z M12.34,33.94 L21.72,33.94 L22.395,37.09 L11.67,37.08 L12.34,33.94 Z" id="Fill-1" fill="#333333"></path>
                    </g>
                </g>
            </g>
        </svg>);
    }
});

module.exports = RookieLifeguard;
