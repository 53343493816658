var React       = require('react'),
    Swimming;

Swimming = React.createClass({
    render: function () {
        var props = Object.assign({}, this.props),
            color = props.color;

        return (<svg width="60px" height="50px" viewBox="0 0 60 50" version="1.1">
            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="selection">
                    <g id="Group-14">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="60" height="50"></rect>
                        <path d="M45.9684444,28.8156111 C48.6962222,28.7578333 50.8584444,26.5011667 50.8012222,23.7750556 C50.744,21.0489444 48.4862222,18.8856111 45.7606667,18.9428333 C43.0334444,19.0006111 40.8712222,21.2567222 40.9284444,23.9833889 C40.9856667,26.7089444 43.2423333,28.8728333 45.9684444,28.8156111" id="Fill-1" fill="#333333"></path>
                        <path d="M32.5558889,27.4098889 C35.5847778,28.3721111 38.7808889,28.8165556 40.5375556,28.981 L38.5153333,21.1198889 C38.4308889,20.7421111 38.2664444,20.3771111 37.9986667,20.0615556 L37.9975556,20.0615556 L33.6092222,14.6965556 L40.132,13.3304444 C41.4164444,12.906 42.1142222,11.5193333 41.6897778,10.236 C41.2658889,8.95211111 39.8808889,8.25544444 38.597,8.67988889 L28.1925556,10.6826667 C27.4131111,10.9393333 26.8208889,11.5615556 26.6008889,12.3493333 C26.382,13.1376667 26.7675556,13.8498889 27.0986667,14.6004444 C27.7375556,16.0432222 29.4486667,18.6682222 30.6375556,20.7848889 L20.9403333,24.5432222 C22.8764444,24.7615556 29.5725556,26.4615556 32.5558889,27.4098889" id="Fill-8" fill="#333333"></path>
                        <path d="M19.2795556,35.1985556 C17.2423333,34.923 13.5845556,34.698 11.6634444,35.2941111 C12.4945556,37.1791111 26.4606667,41.7057778 33.1895556,39.008 C32.074,38.7246667 23.5095556,35.7707778 19.2795556,35.1985556" id="Fill-3" fill="#333333"></path>
                        <path d="M4.82855556,35.3599444 C4.868,35.3371667 8.90133333,32.8166111 15.8446667,32.7988333 C20.0696667,32.7988333 23.8524444,33.8055 27.9591111,35.1766111 C31.3757778,36.3171667 35.5318889,37.6560556 38.5924444,37.6160556 C48.8396667,37.4810556 53.6041111,30.4049444 53.7918889,30.1110556 C54.1718889,29.5149444 53.453,29.0727222 53.0491111,29.3643889 C53.0174444,29.3866111 49.763,31.5121667 43.7491111,31.5605 C39.3702222,31.5605 34.8152222,30.2999444 30.5524444,29.0705 C26.1385556,27.7988333 21.9702222,26.5955 17.9046667,26.5599444 C8.18577778,26.5599444 4.103,34.5871667 4.058,34.6660556 C3.84355556,35.0416111 4.25133333,35.6810556 4.82855556,35.3599444" id="Fill-6" fill={ color }></path>
                    </g>
                </g>
            </g>
        </svg>);
    }
});

module.exports = Swimming;
