var React               = require('react'),
    Button              = require('../common/button'),
    PriceDetails        = require('./priceDetails'),
    MembershipProgress  = require('./membershipProgress'),
    LessonsProgress     = require('./lessonsProgress'),
    Sticky              = require('react-sticky').Sticky,

    Pricing;

Pricing = React.createClass({

    _noop: function _noop() {},

    getInitialState: function() {
        return {
            scrollPosition: 0,
            durationDisabled: null
        };
    },

    componentDidMount: function componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);

        this._checkIfDurationAvailable(this.props.users);
    },

    componentWillUnmount: function componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if(this.props.users !== newProps.users) {
            this._checkIfDurationAvailable(newProps.users);
        }
    },

    _checkIfDurationAvailable: function _checkIfDurationAvailable(users) {
        if(!this._findDurationInSubs(users, 1) && this._findDurationInSubs(users, 12)) {
            this.props.changeDuration(12);
            this.setState({ durationDisabled: 'dd' });

        } else if(this._findDurationInSubs(users, 1) && !this._findDurationInSubs(users, 12)) {
            this.props.changeDuration(1);
            this.setState({ durationDisabled: 'annual' });

        } else if (!this._findDurationInSubs(users, 1) && !this._findDurationInSubs(users, 12)) {
            this.setState({ durationDisabled: 'both' });

        } else {
            this.setState({ durationDisabled: null });
        }
    },

    _findDurationInSubs: function _findDurationInSubs(users, duration) {
        var checkIfAvailable = false;

        for (var i = 0; i < users.objects.length; i++) {
            var availableSubs = users.objects[i].availableSubscriptions;

            if(availableSubs.length) {
                for(var sub = 0; sub < availableSubs.length; sub++) {
                    if(availableSubs[sub].desc.indexOf('Plus1') !== -1 || availableSubs[sub].desc.indexOf('Free Swim') !== -1) {
                        if(availableSubs[sub].duration === duration) {
                            checkIfAvailable = true;
                        }
                    } else {
                        checkIfAvailable = true;
                    }
                }

                if(!checkIfAvailable) {
                    return false;
                }
            }
        }

        return true;
    },

    render: function render() {

        var monthlyView = (1 === this.props.duration && 0 === this.props.durationType),
            annualView = (12 === this.props.duration && 0 === this.props.durationType),
            content = this.props.content.pricing,
            currentStep = this.props.currentStep,
            firstStage = false,
            durationToggle,
            monthlyButtonProps = {
                onClick: this.props.changeDuration.bind(null, 1)
            },
            annualButtonProps = {
                onClick: this.props.changeDuration.bind(null, 12)
            },
            priceBlock;

        if (!this.props.deeplinkAddons && (currentStep === 0 || currentStep === 5 || currentStep === 6 || currentStep === 7)) {

            if (this.props.usersHaveSelections && this.props.centre && this.props.membershipsSelected) {

                priceBlock = <PriceDetails />;
                firstStage = false;

            } else {
                // TODO: Fix disable annual toggle until we have a price
                monthlyButtonProps.onClick = this._noop;
                monthlyButtonProps.disabled = true;
                monthlyButtonProps.tabIndex = -1;

                annualButtonProps.onClick = this._noop;
                annualButtonProps.disabled = true;
                annualButtonProps.tabIndex = -1;

                if (this.props.isLessons) {
                    priceBlock = <LessonsProgress />;
                } else {
                    priceBlock = <MembershipProgress />;
                }
                firstStage = true;
            }

        } else {
            priceBlock = <PriceDetails />;
        }

        if (this.props.selections && this.props.selections.preselectedPartner && Object.keys(this.props.selections.pricing.periods).length === 0) {
            priceBlock = null;
        }

        if(this.state.durationDisabled === 'dd') {
            monthlyButtonProps.disabled = true;
            monthlyButtonProps.tabIndex = -1;
        } else if(this.state.durationDisabled === 'annual') {
            annualButtonProps.disabled = true;
            annualButtonProps.tabIndex = -1;
        }

        let hasOtherPeriods = this.props.pricing
            && this.props.pricing.periods
            && this.props.pricing.periods[0]
            && Object.keys(this.props.pricing.periods[0]).length > 1;

        if(!this.props.isLessons && this.props.durationType === 0 && hasOtherPeriods) {
            durationToggle = (<div className={ 'pricing__tabs' + (firstStage ? ' pricing__tabs--incative' : '') }>
                    <Button key={'pricetabmonth' + monthlyButtonProps.disabled} { ...monthlyButtonProps } className={ 'pricing__tab' + (monthlyView ? ' pricing__tab--selected' : '') }>{ content.monthly }</Button>
                    <Button key={'pricetabyear' + annualButtonProps.disabled} { ...annualButtonProps } className={ 'pricing__tab' + (annualView ? ' pricing__tab--selected' : '') }>{ content.annually }</Button>
                </div>);
        }

        if ((['bp-s', '"bp-s"', 'bp-sm', '"bp-sm"', 'bp-m', '"bp-m"'].indexOf(this.props.breakpoint) !== -1)) {
            return (<div className='pricing'>
                    { durationToggle }
                    { priceBlock }
            </div>);

        } else {
            return (<Sticky topOffset={-120}>
                <div className='pricing' style={{WebkitBackfaceVisibility: 'hidden'}}>
                    { durationToggle }
                    { priceBlock }
                </div>
            </Sticky>);
        }
    }
});

Pricing.propTypes = {
    breakpoint: React.PropTypes.string,
    centre: React.PropTypes.object,
    changeDuration: React.PropTypes.func,
    content: React.PropTypes.object,
    deeplinkAddons: React.PropTypes.array,
    duration: React.PropTypes.number,
    durationType: React.PropTypes.number,
    membershipsSelected: React.PropTypes.bool,
    pricing: React.PropTypes.object,
    stageStep: React.PropTypes.number,
    usersHaveSelections: React.PropTypes.bool,
    users: React.PropTypes.object
};

module.exports = Pricing;
