var React       = require('react'),
    connect     = require('react-redux').connect,

    Modal       = require('../common/modal'),
    Button      = require('../common/button'),

    ConvenientSession;

ConvenientSession = React.createClass({

    render: function render() {
        var content = this.props.content;

        return <Modal title={ content.title } >
            
        </Modal>;
    }
});


function mapStateToProps(state) {
    return {
        content: state.app.content.modals.convenientSession
    };
}

module.exports = connect(mapStateToProps)(ConvenientSession);
