var React           = require('react'),
    connect         = require('react-redux').connect,

    config          = require('../../config'),

    actions         = require('../../actions'),

    Input           = require('../common/forms/input'),
    SmartDate       = require('../common/forms/smartDate'),
    SmartSelect     = require('../common/forms/smartSelect'),

    Button          = require('../common/button'),
    Input           = require('../common/forms/input'),

    httpFetch       = require('../../utils/httpFetch'),
    viewUtils       = require('../../utils/viewUtils'),
    utils           = require('../../utils'),

    UserModel       = require('../../models/user'),

    scrollToComponent = require('react-scroll-to-component'),

    UserSearchForm;

UserSearchForm = React.createClass({
    displayName: 'UserSearchForm',

    getInitialState: function getInitialState() {
        return ({
            error: null,
            relationsExpanded: false,
            valid: false,
            validationErrors: []
        });
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if (this.props.staffSearch.users !== newProps.staffSearch.users) {
            var newUser = newProps.staffSearch.users[newProps.uid];

            // this.validateUser(newUser)
            //     .then(function (result) {
            //         if (result.valid !== this.state.valid) {
            //             this.setState({
            //                 validationErrors: result.validationErrors,
            //                 valid: result.valid
            //             });
            //         }
            //     }.bind(this));

            if(newUser && newUser.searchResults && newUser.searchResults.length) {
                scrollToComponent(document.getElementById('search-form'), {
                    align: 'top',
                    offset: -130
                });
            }
        }
    },

    handleMoreInfoClick: function handleMoreInfoClick(key, e) {
        e.preventDefault();

        var elt = document.querySelector('.staff-search__relations-list--' + key);
        elt && viewUtils.furl(elt);
        this.setState({ relationsExpanded: !this.state.relationsExpanded });
    },

    onBlurInput: function onBlurInput(field, value) {
        var data = {
            info: {}
        };

        data.info[field] = value;

        this.props.dispatch(actions.staffSearch.changeData(this.props.uid, data));
    },

    onChangeInput: function onChangeInput(field, value) {
        var data = {
            info: {},
            userType: this.props.userType
        };

        data.info[field] = value;

        this.props.dispatch(actions.staffSearch.changeData(this.props.uid, data));
    },

    onCheckError: function onCheckError(e) {
        if (e && e.success === false) {
            if(e.reason === 'AlreadyLoggedIn') {
                this.setState({ error: 'AlreadyLoggedIn' });
            } else {
                this.setState({ error: 401 });
            }
        } else if (e && e.status) {
            this.setState({ error: e.status });
        } else if (e && e.reason) {
            this.setState({ error: e.reason });
        }

        scrollToComponent(document.getElementById('error-message'), {
            align: 'top',
            offset: -130
        });
    },

    onRemoveResults: function onRemoveResults(userId) {
        this.props.dispatch(actions.staffSearch.removeResults(userId));
        this.setState({error: null});
    },

    onSelectUser: function onSelectUser(memberId) {
        this.props.dispatch(actions.staffSearch.impersonateMember({
            uid: this.props.uid,
            id: memberId,
            onError: this.onCheckError,
            onSuccess: this.onSuccess,
            isGuardian: this.props.isGuardian,
            isJuniorPanel: this.props.isJuniorPanel || false
        }));
        this.props.callback && this.props.callback();
    },

    onSubmitForm: function onSubmitForm() {
        var self = this,
            user = this.props.staffSearch.users[this.props.uid];

        this.props.dispatch(actions.app.showLoader(true));
        this.setState({error: null});

        if(this.validateFields(user)) {
            return httpFetch.fetch(config.services.staff.urls.search, {
                method: 'POST',
                params: {
                    'emailAddress': user.info.email && user.info.email.value,
                    'card': user.info.card && user.info.card.value,
                    'memberId': user.info.memberId && user.info.memberId.value,
                    'firstName': user.info.firstName && user.info.firstName.value,
                    'lastName': user.info.lastName && user.info.lastName.value,
                    'birthDate': user.info.dob && user.info.dob.value,
                    'homeTelephone': user.info.homeTelephone && user.info.homeTelephone.value,
                    'mobileTelephone': user.info.mobileTelephone && user.info.mobileTelephone.value
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                self.props.dispatch(actions.staffSearch.setSearchResults(response, self.props.uid));
                self.props.dispatch(actions.app.showLoader(false));
            })
            .catch(function(e) {
                if (e == 'Error: Unauthenticated.') {
                    // We know user is staff since they reached this point, but they need to log in again
                    self.props.dispatch(actions.app.showLoader(false));
                    self.props.dispatch(actions.staffLogin.showLogin(true));
                    return;
                }
                self.setState({ error: 'noResults' });

                scrollToComponent(document.getElementById('error-message'), {
                    align: 'top',
                    offset: -130
                });

                self.props.dispatch(actions.app.showLoader(false));
            });
        } else {
            self.setState({ error: 'notValid' });

            scrollToComponent(document.getElementById('error-message'), {
                align: 'top',
                offset: -130
            });

            self.props.dispatch(actions.app.showLoader(false));
        }
    },

    onSuccess: function onSuccess(data) {
        this.setState({error: null});
        this.props.dispatch(actions.selections.userFetched(data.user));
        this.props.dispatch(actions.app.showLoader(false));
    },

    validateFields: function validateFields(user) {
        var userInfo = user && user.info,
            count = 0;

        for(var key in userInfo) {
            //if any of these have values, continue
            if((key === 'email' || key === 'memberId' || key === 'card') && userInfo[key] && userInfo[key].value && userInfo[key].valid) {
                return true;
            //otherwise you need to have filled out 3 other fields
            } else {
                if(userInfo[key] && userInfo[key].value && userInfo[key].valid) {
                    count++;
                }
            }

            if(count === 3) {
                return true;
            }
        }

        return false;
    },

    validateUser: function validateUser(user) {
        var promises = [],
            valid = true,
            isStaff = true,
            errors = [];

        promises.push(UserModel.validate(user, null, isStaff));

        return Promise.all(promises)
            .then(function (results) {
                for (var i = 0; i < results.length; i++) {
                    if (!results[i].valid) {
                        errors[i] = results[i].errors,
                        valid = false;

                    } else {
                        errors[i] = {};
                    }
                }

                return {
                    validationErrors: errors,
                    valid:valid
                };
            });
    },

    render: function() {
        var content = this.props.content,
            user = this.props.staffSearch.users[this.props.uid],
            isLead = this.props.selections.users.objects[this.props.uid].lead,
            userType = this.props.userType,
            memberRelations = this.props.staffSearch.memberRelations,
            memberRelationsArray = [],
            searchResultsArray = [],
            memberRelationsBlock,
            searchResultsBlock,
            leadUserLoggedIn,
            leadUserName,
            errorTitle,
            errorMsg,
            error,
            searchForm,

            currentYear = new Date().getFullYear(),
            dateParts = user && user.info && user.info.dob && utils.getDateParts(user.info.dob.value),
            dobIsValid = dateParts && dateParts.year >= 1900 && dateParts.year <= currentYear;

        if('notValid' === this.state.error) {
            errorMsg = content.errors.errorNV;

        } else if('noResults' === this.state.error) {
            errorMsg = content.errors.errorNR;

        } else if ('juniorError' === this.state.error) {
            errorMsg = content.errors.errorJE;

        } else if ('AlreadyLoggedIn' === this.state.error) {
            errorMsg = content.errors.errorALI;

        } else if ('NoMember' === this.state.error) {
            errorMsg = content.errors.errorNM;

        } else if (500 === this.state.error) {
            errorMsg = content.errors.error500;
        }

        if (errorMsg) {
            error = <span className='form__error' id='error-message' dangerouslySetInnerHTML={ {__html: errorMsg } }></span>;
        }

        for(var u = 0; u < this.props.selections.users.objects.length; u++) {
            var userObj = this.props.selections.users.objects[u];

            if(userObj.lead) {
                var firstName = userObj.info.firstName && userObj.info.firstName.value,
                    lastName = userObj.info.lastName && userObj.info.lastName.value;

                leadUserName = (firstName && lastName ? firstName + ' ' + lastName : (firstName ? firstName : 'Lead User'));
                leadUserLoggedIn = userObj.isLoggedInUser;
                break;
            }
        }

        if(user && user.searchResults && user.searchResults.length) {
            for(var i = 0; i < user.searchResults.length; i++) {
                var result = user.searchResults[i],
                    userName = (result.FirstNames && result.LastName) ? result.FirstNames + ' ' + result.LastName : (result.FirstNames ? result.FirstNames : '');

                searchResultsArray.push(<div className='staff-search__user' key={'result_' + this.props.uid + '_' + i}>
                        <div className='staff-search__user-details'>
                            <h2 className={ 'module__title personal-details__user-title memberships__type memberships__type--' + userType } >{ userName }</h2>
                            { result.Id &&
                                <p>ID Number: { result.Id }</p> }
                            { result.MobileTelephone  && result.MobileTelephone !== 'NA' &&
                                <p>Mobile: { result.MobileTelephone }</p> }
                            { result.HomeTelephone && result.HomeTelephone !== 'NA' &&
                                <p>Home Phone: { result.HomeTelephone }</p> }
                            { result.HomePostCode &&
                                <p>Postcode: { result.HomePostCode }</p> }
                            { result.HomeEmail &&
                                <p>Email: { result.HomeEmail }</p> }
                            { result.StatusID &&
                                <p className={ 'staff-search__status--' + result.StatusID }>Status: { result.StatusID }</p> }
                        </div>

                        <div className='staff-search__button'>
                            <Button className='button' onClick={ this.onSelectUser.bind(null, result.Id) }>{ content.buttonSelect }</Button>
                        </div>
                    </div>);
            }

            searchResultsBlock = <div className='staff-search__results'>
                    { error }
                    { searchResultsArray }
                </div>;

        } else if (user && user.searchResults) {
            searchResultsBlock = <div className='staff-search__results'>
                    { content.noSearchResults }
                </div>;

        } else {
            searchForm = (<div>
                    <div className='staff-search__container'>
                        <h4>{ content.complete.one }</h4>

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'email'),
                                onBlur: this.onBlurInput.bind(null, 'email'),
                                placeholder: content.fields.email.placeholder.replace('{name}', (this.props.userName || 'sarah'))
                            } }
                            label={ {
                                text: content.fields.email.label
                            } }
                            name={ 'user_search_email_' + this.props.uid }
                            type='email'
                            valid={ (this.state.validationErrors && this.state.validationErrors.email && (!user || !user.info || !user.info.email || !user.info.email.valid)) ? false : (!user || !user.info || !user.info.email || user.info.email.valid) }
                            value={ (user && user.info && user.info.email && user.info.email.value) || '' }
                        />

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'card'),
                                onBlur: this.onBlurInput.bind(null, 'card'),
                                placeholder: content.fields.card.placeholder
                            } }
                            label={ {
                                text: content.fields.card.label
                            } }
                            name={ 'user_search_card_' + this.props.uid }
                            type='text'
                            valid={ (this.state.validationErrors && this.state.validationErrors.card && (!user || !user.info || !user.info.card || !user.info.card.valid)) ? false : (!user || !user.info || !user.info.card || user.info.card.valid) }
                            value={ (user && user.info && user.info.card && user.info.card.value) || '' }
                        />

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'memberId'),
                                onBlur: this.onBlurInput.bind(null, 'memberId'),
                                placeholder: content.fields.memberId.placeholder
                            } }
                            label={ {
                                text: content.fields.memberId.label
                            } }
                            name={ 'user_search_memberId_' + this.props.uid }
                            type='text'
                            valid={ (this.state.validationErrors && this.state.validationErrors.memberId && (!user || !user.info || !user.info.memberId || !user.info.memberId.valid)) ? false : (!user || !user.info || !user.info.memberId || user.info.memberId.valid) }
                            value={ (user && user.info && user.info.memberId && user.info.memberId.value) || '' }
                        />
                    </div>

                    <div className='staff-search__container'>
                        <h4>{ content.complete.three }</h4>

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'firstName'),
                                onBlur: this.onBlurInput.bind(null, 'firstName'),
                                placeholder: content.fields.firstName.placeholder
                            } }
                            label={ {
                                text: content.fields.firstName.label
                            } }
                            name={ 'user_search_first_name_' + this.props.uid }
                            type='text'
                            valid={ (this.state.validationErrors && this.state.validationErrors.firstName && (!user || !user.info || !user.info.firstName || !user.info.firstName.valid)) ? false : (!user || !user.info || !user.info.firstName || user.info.firstName.valid) }
                            value={ (user && user.info && user.info.firstName && user.info.firstName.value) || '' }
                        />

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'lastName'),
                                onBlur: this.onBlurInput.bind(null, 'lastName'),
                                placeholder: content.fields.lastName.placeholder
                            } }
                            label={ {
                                className: 'a11y',
                                text: content.fields.lastName.label
                            } }
                            name={ 'user_search_last_name_' + this.props.uid }
                            type='text'
                            valid={ (this.state.validationErrors && this.state.validationErrors.lastName && (!user || !user.info || !user.info.lastName || !user.info.lastName.valid)) ? false : (!user || !user.info || !user.info.lastName || user.info.lastName.valid) }
                            value={ (user && user.info && user.info.lastName && user.info.lastName.value) || '' }
                        />

                        <SmartDate className='personal-details__input--date'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'dob'),
                                onBlur: this.onBlurInput.bind(null, 'dob'),
                                placeholder: content.fields.dob.placeholder
                            } }
                            label={ {
                                text: content.fields.dob.label
                            } }
                            name={ 'user_search_dob_' + this.props.uid }
                            valid={ dobIsValid && (this.state.validationErrors && this.state.validationErrors.dob && (!user || !user.info || !user.info.dob || !user.info.dob.valid)) ? false : (!user || !user.info || !user.info.dob || user.info.dob.valid) }
                            value={ (user && user.info && user.info.dob && user.info.dob.value) }
                            invalidAgeRange={ user && user.invalidForAgeRange }
                            extras={ user && user.extras || null }
                            age={ user && user.info && user.info.age || 0 }
                        />

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'mobileTelephone'),
                                onBlur: this.onBlurInput.bind(null, 'mobileTelephone'),
                                placeholder: content.fields.mobileTelephone.placeholder
                            } }
                            label={ {
                                text: content.fields.mobileTelephone.label
                            } }
                            name={ 'user_search_homephone_' + this.props.uid }
                            type='tel'
                            valid={ (this.state.validationErrors && this.state.validationErrors.mobileTelephone && (!user || !user.info || !user.info.mobileTelephone || !user.info.mobileTelephone.valid)) ? false : (!user || !user.info || !user.info.mobileTelephone || user.info.mobileTelephone.valid) }
                            value={ (user && user.info && user.info.mobileTelephone && user.info.mobileTelephone.value) || '' }
                        />

                        <Input className='personal-details__input'
                            input={ {
                                onChange: this.onChangeInput.bind(null, 'homeTelephone'),
                                onBlur: this.onBlurInput.bind(null, 'homeTelephone'),
                                placeholder: content.fields.homeTelephone.placeholder
                            } }
                            label={ {
                                className: 'a11y',
                                text: content.fields.homeTelephone.label
                            } }
                            name={ 'user_search_homephone_' + this.props.uid }
                            type='tel'
                            valid={ (this.state.validationErrors && this.state.validationErrors.homeTelephone && (!user || !user.info || !user.info.homeTelephone || !user.info.homeTelephone.valid)) ? false : (!user || !user.info || !user.info.homeTelephone || user.info.homeTelephone.valid) }
                            value={ (user && user.info && user.info.homeTelephone && user.info.homeTelephone.value) || '' }
                        />
                    </div>

                    <div className='module__footer'>
                        <Button className='button' onClick={ this.onSubmitForm }>{ content.buttonFetch }</Button>
                    </div>

                    { error }
                </div>);
        }

        //show linked members for the lead user if they have any
        //and only if they're logged in
        if(!isLead && leadUserLoggedIn && memberRelations && memberRelations.length) {
            for(var r = 0; r < memberRelations.length; r++) {
                var relation = memberRelations[r],
                    userName = (relation.FirstNames && relation.LastName) ? relation.FirstNames + ' ' + relation.LastName : (relation.FirstNames ? relation.FirstNames : '');

                memberRelationsArray.push(<div className='staff-search__user' key={'relation_' + this.props.uid + '_' + r}>
                        <div className='staff-search__user-details'>
                            <h2 className={ 'module__title personal-details__user-title memberships__type memberships__type--' + userType } >{ userName }</h2>
                            { relation.MemberId &&
                                <p>ID Number: { relation.MemberId }</p> }
                            { relation.MobileTelephone  && relation.MobileTelephone !== 'NA' &&
                                <p>Mobile: { relation.MobileTelephone }</p> }
                            { relation.HomeTelephone && relation.HomeTelephone !== 'NA' &&
                                <p>Home Phone: { relation.HomeTelephone }</p> }
                            { relation.HomePostCode &&
                                <p>Postcode: { relation.HomePostCode }</p> }
                            { relation.HomeEmail &&
                                <p>Email: { relation.HomeEmail }</p> }
                            { relation.StatusID &&
                                <p className={ 'staff-search__status--' + relation.StatusID }>Status: { relation.StatusID }</p> }
                        </div>

                        <div className='staff-search__button'>
                            <Button className='button' onClick={ this.onSelectUser.bind(null, relation.MemberId) }>{ content.buttonSelect }</Button>
                        </div>
                    </div>);
            }

            memberRelationsBlock = <div className='staff-search__relations'>
                        <div className={'staff-search__expand ' + ( this.state.relationsExpanded ? 'staff-search__expand--active' : '') } onClick={ this.handleMoreInfoClick.bind(null, this.props.uid) } >{ content.viewMemberRelations.replace('{name}', leadUserName) }</div>
                        <div className={ 'staff-search__relations-list staff-search__relations-list--' + this.props.uid }>
                            <div className='inner'>
                                <div>
                                    { error }
                                    { memberRelationsArray }
                                </div>
                            </div>
                        </div>
                </div>;
        }

        return (<div className='user-search-form' id='search-form'>
            <div className='personal-details__container'>
                { this.props.staffSearch.memberRelations &&
                   memberRelationsBlock }

                <div className='staff-search__header'>
                    <div className='staff-search__titles'>
                        <h2 className="module__title">{ content.title }</h2>
                        <p className="module__subtitle">{ content.subtitle }</p>
                    </div>

                    { user && user.searchResults &&
                        <div className='staff-search__button staff-search__button--change'>
                            <Button className='button button--change' onClick={ this.onRemoveResults.bind(null, this.props.uid) }>{ content.buttonChange }</Button>
                        </div> }
                </div>

                { searchForm }
                { searchResultsBlock }
            </div>
        </div>);
    }
});

UserSearchForm.propTypes = {
    uid: React.PropTypes.number
};

function mapStateToProps(state) {
    return {
        content: state.app.content.userSearchForm,
        staffSearch: state.staffSearch,
        selections: state.selections
    };
}

module.exports = connect(mapStateToProps)(UserSearchForm);
