var actionTypes = require('./actionTypes.js');

module.exports = {
    setRestrictions: function setRestrictions(restricted, type) {
        return function (dispatch) {
            dispatch({
                type: actionTypes.RESTRICTIONS.RESTRICT,
                restricted: restricted,
                restrictedType: type || null
            });
        };
    }
};
