var actionTypes     = require('./actionTypes.js'),
    config          = require('../config'),
    httpFetch       = require('../utils/httpFetch'),
    exports,
    activityTimeout = null;

module.exports = exports = {
    debug: function debug() {
        return {
            type: actionTypes.APP.DEBUG
        };
    },

    deepLink: function deepLink(params) {
        return {
            type: actionTypes.APP.DEEP_LINK,
            params: params
        };
    },

    error: function error(e, notifyUser) {
        return {
            type: actionTypes.APP.ERROR,
            error: e,
            notifyUser: notifyUser
        };
    },

    expandPriceBreakDown: function expandPriceBreakDown(expanded) {
        return {
            type: actionTypes.APP.EXPAND_PRICE_BREAKDOWN,
            expanded: expanded
        };
    },

    hideModal: function hideModal(key) {
        return {
            type: actionTypes.APP.HIDE_MODAL,
            key: key
        };
    },

    hideSidebar: function hideSidebar() {
        return {
            type: actionTypes.APP.HIDE_SIDEBAR,
        };
    },

    profileLoaded: function profileLoaded() {
        return {
            type: actionTypes.APP.PROFILE_LOADED
        };
    },

    resetAll: function resetAll() {
        return {
            type: actionTypes.APP.RESET_ALL
        };
    },

    sessionTimeout: function sessionTimeout() {
        return {
            type: actionTypes.APP.SESSION_TIMEOUT
        };
    },

    setBreakpoint: function setBreakpoint(breakpoint) {
        return {
            type: actionTypes.APP.SET_BREAKPOINT,
            breakpoint: breakpoint
        };
    },

    showLoader: function showLoader(visible) {
        return {
            type: actionTypes.APP.SHOW_LOADER,
            visible: visible
        };
    },

    showModal: function showModal(key, options) {
        return {
            type: actionTypes.APP.SHOW_MODAL,
            key: key,
            options: options
        };
    },

    start: function start() {
        return function(dispatch, getState) {
            var state = getState(),
                isKiosk = !!state.app.kioskHomeUrl,
                timeoutMs = isKiosk ? config.app.iframeSessionTimeout * 1000 : config.app.sessionTimeout * 1000;

            dispatch(exports.started(Date.now()));

            function initTimeout() {
                var timeout = 0,
                    interval = 5000;

                function resetTimer() {
                    timeout = timeoutMs;
                }

                window.addEventListener('mousemove', resetTimer, false);
                window.addEventListener('mousedown', resetTimer, false);
                window.addEventListener('keypress', resetTimer, false);
                window.addEventListener('DOMMouseScroll', resetTimer, false);
                window.addEventListener('mousewheel', resetTimer, false);
                window.addEventListener('touchmove', resetTimer, false);
                window.addEventListener('MSPointerMove', resetTimer, false);

                resetTimer();

                function tick () {
                    timeout -= interval;

                    if (timeout <= 10000 && isKiosk) {
                        dispatch(exports.sessionTimeout());

                        if (timeout <= 0 && getState().app.showModals.timedOut) {
                            httpFetch.fetch(config.services.app.urls.logout, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                }
                            }).then(function() {
                                console.log('logged out');

                                if (state.app.kioskHomeUrl) {
                                    window.location = state.app.kioskHomeUrl;
                                }
                            });
                        }
                    }
                    else if (timeout <= 0 && !isKiosk) {
                        dispatch(exports.sessionTimeout());
                    }

                    activityTimeout = setTimeout(tick, interval);
                }

                if (!activityTimeout) {
                    tick();
                }
            }

            initTimeout();
        };
    },

    started: function started(time) {
        return {
            type: actionTypes.APP.STARTED,
            time: time
        };
    },

    startSession: function startSession(ms) {
        return {
            type: actionTypes.APP.START_SESSION,
            ms: ms
        };
    },

    toggleSidebar: function toggleSidebar() {
        return {
            type: actionTypes.APP.TOGGLE_SIDEBAR,
        };
    },

    setKioskHomeUrl: function setKioskHomeUrl(kioskHomeUrl) {
        return {
            type: actionTypes.APP.SET_KIOSK_HOME_URL,
            kioskHomeUrl: kioskHomeUrl
        };
    },

    setCutoffDate: function setCutoffDate(cutoffDate) {
        return {
            type: actionTypes.APP.SET_CUTOFF_DATE,
            cutoffDate
        };
    },

    showLoaderCentreFinder: function showLoaderCentreFinder(visible) {
        return {
            type: actionTypes.APP.SHOW_LOADER_CENTRE_FINDER,
            visible: visible
        };
    },

    setTagsBlackList: function setTagsBlackList(...tags) {
        return {
            type: actionTypes.APP.SET_TAGS_BLACK_LIST,
            tagsBlackList: tags
        };
    },

    setTagsWhiteList: function setTagsWhiteList(...tags) {
        return {
            type: actionTypes.APP.SET_TAGS_WHITE_LIST,
            tagsWhiteList: tags
        };
    },

    resetTagsBlackList: function resetTagsBlackList() {
        return {
            type: actionTypes.APP.SET_TAGS_BLACK_LIST,
            tagsBlackList: null
        };
    },

    resetTagsWhiteList: function resetTagsWhiteList() {
        return {
            type: actionTypes.APP.SET_TAGS_WHITE_LIST,
            tagsWhiteList: null
        };
    },

};
