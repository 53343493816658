var React       = require('react'),
    Waterpolo;

Waterpolo = React.createClass({
    render: function () {
        var props = Object.assign({}, this.props),
            color = props.color;

        return (<svg width="39px" height="48px" viewBox="0 0 39 39" version="1.1">
            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="selection" transform="translate(-12.000000, -12.000000)">
                    <g id="Group-14" transform="translate(5.000000, 5.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="60" height="50"></rect>
                        <path d="M27.028227,44.4452732 C25.6905921,44.8471088 24.3228234,45.2422709 23,45.5459117 C27.7110899,47.042189 36.8395928,44.4667235 38,43.215933 C37.1567639,43.0881845 36.1924819,43.00286 35.1163472,43 C32.3971535,43 29.9159658,43.577728 27.028227,44.4452732" id="Fill-6" fill={ color }></path>
                        <path d="M7.58632404,38.0292175 C7.61168113,38.0405082 9.96005859,39.5830306 14.3038842,39.617978 C17.4667936,39.617978 20.7564885,38.7055832 23.8355643,37.8163075 C27.0243483,36.8963856 30.0340802,36.013024 32.9708458,36.0001204 C39.990621,35.9694742 42.9537787,41.8013811 42.9724084,41.864824 C43.1198935,42.3680659 42.6386262,42.4874245 42.4155873,42.3669906 C42.3866077,42.3513987 39.4741643,40.5271468 34.459152,40.5137055 C31.4069858,40.5137055 28.6746294,41.2427611 25.7094018,42.2341906 C23.2761557,43.0476575 20.3264529,44.0143551 18.1260781,43.9998385 C10.7238764,43.9509122 7.20131019,38.7701014 7.04968511,38.5690197 C6.89961251,38.3706262 7.09263693,37.8136192 7.58632404,38.0292175" id="Fill-9" fill={ color }></path>
                        <g id="Group-5" transform="translate(12.000000, 7.000000)" fill="#333333">
                            <path d="M22.342875,15.1307841 C24.3525303,13.1968756 24.415363,9.99447343 22.4809089,7.98289193 C20.5449706,5.97131042 17.3439686,5.90847942 15.3333237,7.84486162 C13.3216894,9.77827542 13.2608357,12.9772144 15.196774,14.9887959 C17.1327123,17.0008722 20.3312406,17.0646926 22.342875,15.1307841" id="Fill-1"></path>
                            <path d="M30.879833,29.9558996 C31.4070492,30.2633922 32.600608,30.7637356 33.495411,29.5527103 C34.4522107,28.2595899 33.845424,27.3133095 33.3357817,26.935866 C31.4700222,25.5523923 25.7702297,21.2086348 23.2347104,19.6638854 C22.6152314,19.2869277 21.7985345,19.0333556 21.2347061,18.8944253 L8.51854507,16.4791757 L5.86733118,10.4439661 C6.97448513,10.3191231 8.05186113,9.84986909 8.9178625,9.02163082 C11.0120823,7.01637124 11.0784725,3.69418851 9.06187062,1.60829107 C7.04429246,-0.478092141 3.70818571,-0.543671125 1.61201328,1.46498885 C-0.401171425,3.39058211 -0.534439956,6.52331444 1.24442645,8.61649843 C1.09407221,8.99054152 1.22050645,9.29414793 1.22050645,9.29414793 L3.66181021,19.7066332 C3.64033103,20.3080167 4.74260335,21.4102294 5.3283991,21.5491597 L11.2288267,22.5921085 L10.2622637,27.859801 C13.1678106,27.0597374 15.9488759,26.3398259 18.7587428,26.3160232 C19.8634559,26.3160232 20.8744418,26.4442665 21.7980464,26.6618916 C21.9918471,25.753987 22.1490357,25.0122158 22.1490357,25.0122158 C22.1490357,25.0122158 28.5937652,28.6214888 30.879833,29.9558996" id="Fill-3"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>);
    }
});

module.exports = Waterpolo;
