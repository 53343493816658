var actionTypes     = require('./actionTypes.js');

module.exports = {
    selectMemberships: function selectMemberships() {
        return {
            type: actionTypes.MEMBERSHIPS.MEMBERSHIPS_SELECTED
        };
    },

    resetSelection: function resetSelection() {
        return {
            type: actionTypes.MEMBERSHIPS.RESET
        };
    },

    showSpecialPanel: function showSpecialPanel(id) {
        return {
            type: actionTypes.MEMBERSHIPS.SPECIAL_PANEL_VISIBILITY,
            id: id
        };
    },

    hideSpecialPanel: function hideSpecialPanel() {
        return {
            type: actionTypes.MEMBERSHIPS.SPECIAL_PANEL_VISIBILITY,
            id: null
        };
    },

    freeze: function freeze() {
        return {
            type: actionTypes.MEMBERSHIPS.FREEZE
        };
    },

    unfreeze: function unfreeze() {
        return {
            type: actionTypes.MEMBERSHIPS.UNFREEZE
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.MEMBERSHIPS.WIPEOUT
        };
    }
};
