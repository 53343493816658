var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions"),
    utils = require("../../utils"),
    UserModel = require("../../models/user"),
    Extra;

import _ from "lodash";
import { getCommonGroups } from "../../utils/stateSelectors";
import ButtonExtras from "./buttonExtras";

// Banded centre map viewer
import BandedCentresMapViewer from "../stage1/siteBanding/banded-centres-map-viewer";

Extra = React.createClass({
    displayName: 'Extra',

    /**
     * [disabled description]
     * @type {Object}
     */
    disabled: {},

    getInitialState: function() {
        return {
            videoActive: false
        };
    },

    /**
     * [componentDidMount description]
     * @return {[type]} [description]
     */
    componentDidMount: function() {
        var key = this.props.id,
            siteId = this.props.centre.info.site_id;

        if (key.toLowerCase() === "0000-addnut" && !this.allUsersDisabled()) {
            var noEligibleUsers = !this.props.users.objects.find(
                user =>
                    ![
                        "freeprofile",
                        siteId + "-JUNIOR",
                        "0000-JUNIOR"
                    ].includes(user.type)
            );
            var isSingleFreeUser = UserModel.isSingleFreeUser(
                this.props.users.objects
            );
            var isFSGUser = this.props.promo === "fsg";
            var isFreeUser = isFSGUser || isSingleFreeUser;
            // this.setNutritionCompleteForAllElegible({isFreeUser});

            // if (isFreeUser || noEligibleUsers) {
            //     this._onDeselectExtra(key, this.props.extras[key]);
            // }
        }
    },

    /**
     * [setNutritionCompleteForAllElegible description]
     */
    setNutritionCompleteForAllElegible: function({ isFreeUser }) {
        var users = this.props.users.objects,
            key = this.props.id,
            type = this.props.type,
            e =
                type === "addon"
                    ? this.props.centre.addons[key]
                    : this.props.centre.extras[key],
            subs = e && e.subs;

        for (var j = 0; j < subs.length; j++) {
            var sub = subs[j];

            if (!isFreeUser) {
                this.props.dispatch(
                    actions.selections.selectExtra(key, sub.id)
                );
            }

            for (var i = 0; i < users.length; i++) {
                var user = users[i];
                const userHasAlreadySelectedNoExtras =
                    user.extras["0000-AddNut"] &&
                    !user.extras["0000-AddNut"]["01NC1"];

                if (
                    !this.disabled[i] &&
                    user.type !== "freeprofile" &&
                    !userHasAlreadySelectedNoExtras
                ) {
                    this.props.dispatch(
                        actions.selections.setExtraForUser(i, key, sub.id, true)
                    );
                }
            }
        }
    },

    _onSelectExtra: function _onSelectExtra(tag, id) {
        this.props.dispatch(actions.selections.selectExtra(tag, id));
    },

    _onDeselectExtra: function _onDeselectExtra(tag, eid) {
        var users = this.props.users.objects;
        for (var i = 0; i < users.length; i++) {
            this._onSetExtraForUser(i, tag, eid);
        }
        this.props.dispatch(actions.selections.deselectExtra(tag));
    },

    _onSetExtraForUser: function _onSetExtraForUser(uid, tag, eid, e) {
        var f;

        if (e && e.target) {
            f = e.target.checked;
        } else {
            f = false;
        }

        this.props.dispatch(
            actions.selections.setExtraForUser(uid, tag, eid, f)
        );
    },

    componentDidUpdate: function componentDidUpdate() {
        this.props.dispatch(
            actions.selections.recalculateExtras(this.props.centre, "addon")
        );
    },

    allUsersDisabled: function allUsersDisabled() {
        var self = this,
            users = this.props.users.objects,
            key = this.props.id,
            type = this.props.type,
            e =
                type === "addon"
                    ? this.props.centre.addons[key]
                    : this.props.centre.extras[key],
            subs = e && e.subs,
            inAGroup = false;

        //loop through each users
        for (var i = 0; i < users.length; i++) {
            var user = users[i];

            if (
                !user.guardian &&
                user.availableSubscriptions &&
                user.availableSubscriptions.length
            ) {
                //get the users active subscription
                var subscription = user.availableSubscriptions.find(function(
                        s
                    ) {
                        return (
                            (s.duration === self.props.d &&
                                s.durationType === self.props.dt) ||
                            (self.props.dt === 1 && s.durationType === 1)
                        );
                    }),
                    //get the subgroups of the active subscription
                    subGroups = this.props.centre.addOnGroups.filter(function(
                        g
                    ) {
                        return subscription
                            ? subscription.groups.includes(g.groupId)
                            : false;
                    });

                //check if the add on's groups exist in the active subscriptions group
                inAGroup = subGroups.find(function(g) {
                    var foundInAGroup = false;

                    for (var x = 0; x < g.addOnGroupIds.length; x++) {
                        for (var s = 0; s < subs.length; s++) {
                            if (subs[s].groups.includes(g.addOnGroupIds[x])) {
                                foundInAGroup = true;
                                break;
                            }
                        }
                    }

                    return foundInAGroup;
                });

                //break the loop if any user can get subscription
                if (inAGroup) {
                    break;
                }
            }
        }

        //returns true if no user can use this add on
        return !inAGroup;
    },

    /**
     * [toggleVideo description]
     * @return {[type]} [description]
     */
    toggleVideo: function toggleVideo(videoId) {
        this.props.dispatch(
            actions.app.showModal("video", { videoId: videoId })
        );
    },

    /**
     * Simply toggles the ability to "view" other centres
     */
    allowShowingMoreCentres() {
        this.props.dispatch(
            actions.siteBanding.toggleShowCentres(
                !this.props.showMoreCentresIsVisible
            )
        );
    },

    render: function render() {
        var smallScreens =
                ["bp-s", '"bp-s"', "bp-sm", '"bp-sm"'].indexOf(
                    this.props.breakpoint
                ) !== -1,
            key = this.props.id,
            type = this.props.type,
            e =
                type === "addon"
                    ? this.props.centre.addons[key]
                    : this.props.centre.extras[key],
            subs = e && this.props.filterFunc(e.subs)
                .filter((sub) => {
                    // 3 month addons break the system currently
                    return !(sub.duration === 3 && sub.durationType === 0);
                })
                .sort((subA, subB) => {
                    return (subA.desc || '').localeCompare(subB.desc || '')
                })
                .sort((subA, subB) => {
                    const daysA = (subA.durationType || 28) * (subA.duration || 0);
                    const daysB = (subB.durationType || 28) * (subB.duration || 0);
                    return daysA - daysB
                }),
            ulen = this.props.users.objects.length,
            optionKey = "e_" + type + "_" + key,
            extraSelected = subs.find(
                function(s) {
                    return s.id === this.props.extras[key];
                }.bind(this)
            ),
            title = e.groupTitle,
            desc = e.groupDesc,
            content = this.props.content,
            currentSubscriptions = [],
            loggedInUser = this.props.user,
            extraAgeRange = {
                minAge: null,
                maxAge: null
            },
            options = [],
            optionsHidden = [],
            self = this,
            users = [],
            subscriptionAgeRange,
            extraChangeFunction,
            usersPanelMobile,
            userOptionKey,
            subscription,
            usersPanel,
            inAGroup,
            disabled,
            userName,
            checked,
            media,
            note,
            user,
            sub,
            i;

            console.log(this.props.centre.addons);


        // Tier upgrade (may be nothing)
        const tierUpgrade =
            parseInt(
                (
                    this.props.centre.addons[this.props.id].subs
                        // Flat map tag ids that match "0000-TierXXUp"
                        .flatMap(subscriptions => {
                            return subscriptions.tags.filter(tag =>
                                tag.match(/\d{2}Up$/gi)
                            );
                        })
                        .pop() || ""
                ).replace(/^\d+.*(?=\d{2})|[^\d+]$/gi, "")
            ) || 0; // Default no tier

        // If there are no subscriptions present, fail early
        if (subs.length == 0) {
            return null;
        }

        const callbackDeselect = this._onDeselectExtra.bind(null, key, this.props.extras[key]);
        optionsHidden.push(key);

        options.push(
            <li key={optionKey + "_none"} className="extras__list-item-option extras__list-item-option-large hide">
                <label htmlFor={optionKey + "_none"}>
                    <span></span>
                    {content.none}
                </label>
                {' '}
                <ButtonExtras
                    onDeselect={callbackDeselect}
                    onSelect={callbackDeselect}
                    name={optionKey}
                    id={optionKey + "_none"}
                    value="-1"
                    checked={!extraSelected}
                    disabled={!extraSelected}
                />
            </li>
        );

        if ("extra" !== type) {
            if (e.videoId) {
                media = (
                    <div
                        className="extras__list-item-media extras__list-item-media--video"
                        onClick={this.toggleVideo.bind(null, e.videoId)}
                    >
                        <img src={e.imageUrl} />
                    </div>
                );
            } else if (e.imageUrl) {
                media = (
                    <div className="extras__list-item-media">
                        <img src={e.imageUrl} />
                    </div>
                );
            }
        }

        // If the current extra has been selected.
        // It will have the value of the id of the selected extra subscription.
        if (this.props.extras[key]) {
            usersPanel = "";
            usersPanelMobile = "";

            for (i = 0; i < ulen; i++) {
                checked = false;
                disabled = false;
                note = null;
                user = this.props.users.objects[i];
                subscription = user.availableSubscriptions.find(function(s) {
                    return (
                        (s.duration === self.props.d &&
                            s.durationType === self.props.dt) ||
                        (self.props.dt === 1 && s.durationType === 1)
                    );
                });

                if (extraSelected) {
                    extraAgeRange = {
                        min: extraSelected.minAge,
                        max: extraSelected.maxAge
                    };
                }

                var typeDesc = user.typeDesc,
                    idInType = user.idInType + 1,
                    firstName =
                        user.info.firstName && user.info.firstName.value,
                    lastName = user.info.lastName && user.info.lastName.value;

                if (this.props.users.objects.length === 1 && firstName) {
                    userName =
                        firstName && lastName
                            ? firstName + " " + lastName
                            : firstName
                            ? firstName
                            : "";
                } else {
                    userName =
                        typeDesc +
                        " " +
                        idInType +
                        (firstName && lastName
                            ? ": " + firstName + " " + lastName
                            : firstName
                            ? ": " + firstName
                            : "");
                }

                if (subscription) {
                    subscriptionAgeRange = {
                        min: subscription.minAge,
                        max: subscription.maxAge
                    };

                    // Disabling because of age restrcitions.
                    if (
                        !utils.hasRangeOverlap(
                            subscriptionAgeRange,
                            extraAgeRange
                        )
                    ) {
                        disabled = true;
                        note = (
                            <small className="extras__disabled-note">
                                {content.extraNotAvailable}
                            </small>
                        );
                    }
                }

                if (
                    user.extras[key] &&
                    user.extras[key][this.props.extras[key]]
                ) {
                    checked = true;
                }

                // Disabling if this extra is not in an addon group
                //  matching any of the selected subscription's groups.
                if (extraSelected && subscription) {
                    var subGroups = this.props.centre.addOnGroups
                        .filter(function(g) {
                            return subscription.groups.includes(g.groupId);
                        })
                        // Filter JAD groups
                        .filter(addonId => /^JAD\d+/i.test(addonId.groupId));

                    inAGroup = subGroups.find(function(g) {
                        var foundInAGroup = false,
                            i;

                        for (i = 0; i < g.addOnGroupIds.length; i++) {
                            if (
                                extraSelected.groups.includes(
                                    g.addOnGroupIds[i]
                                )
                            ) {
                                foundInAGroup = true;
                                break;
                            }
                        }

                        return foundInAGroup;
                    });

                    if (!inAGroup) {
                        checked = false;
                        disabled = true;
                        note = (
                            <small className="extras__disabled-note">
                                {content.extraNotAvailable}
                            </small>
                        );
                    }

                    // Disabling if user has free membership
                    if (user.type === "freeprofile") {
                        disabled = true;
                    }
                }

                if (user.extraMemberSubs && user.extraMemberSubs.length) {
                    for (var x = 0; x < user.extraMemberSubs.length; x++) {
                        if (
                            this.props.extras[key].indexOf(
                                user.extraMemberSubs[x]
                            ) === -1
                        ) {
                            checked = false;
                            disabled = true;
                            note = (
                                <small className="extras__disabled-note">
                                    {content.extraNotAvailable}
                                </small>
                            );
                        }
                    }
                }

                // Disabling because the user is currently logged in and already
                //  a subscriber to this extra.
                if (loggedInUser && loggedInUser.mrmId === user.info.mrmId) {
                    currentSubscriptions = loggedInUser.subscriptions.filter(
                        function(s) {
                            return self.props.centre.info.site_id === s.SiteID;
                        }
                    );

                    if (
                        currentSubscriptions.find(function(s) {
                            return s.SubTypeId === self.props.extras[key];
                        })
                    ) {
                        checked = true;
                        disabled = true;
                        note = (
                            <small className="extras__disabled-note">
                                {content.extraAlreadySubscribed.replace(
                                    "{user}",
                                    userName
                                )}
                            </small>
                        );
                    }
                }

                userOptionKey =
                    type +
                    "_" +
                    user.type +
                    "_" +
                    user.idInType +
                    "_" +
                    this.props.extras[key];

                // Disabling if user has free membership
                if (user.type === "freeprofile") {
                    disabled = true;
                }

                this.disabled[i] = disabled;

                // EA-61 - if we have only one user and none of the addon subscriptions are disabled
                // then auto check it by default
                if ( this.props.feature_flags.default_addon_select_for_single_user ) {
                    const userExtras = this.props.users.objects[ i ].extras;

                    if ( this.props.users.objects.length === 1 && _.every( this.disabled, disabled => disabled === false ) ) {
                        // Pre-select addon if addon group and subscription id doesn't exist
                        if ( !userExtras[ key ] || ( !userExtras[ key ] && !userExtras[ key ][ this.props.extras[ key ] ] ) ) {
                            this._onSetExtraForUser( i, key, this.props.extras[ key ], { target: { checked: true } } );
                        }
                    }
                }

                users.push(
                    <li
                        className={
                            "extras__panel-list-item" +
                            (disabled
                                ? " extras__panel-list-item--disabled"
                                : "")
                        }
                        key={userOptionKey}
                    >
                        <input
                            className={
                                "forms__input--checkbox" +
                                (disabled ? " input--disabled" : "")
                            }
                            onChange={this._onSetExtraForUser.bind(
                                null,
                                i,
                                key,
                                this.props.extras[key]
                            )}
                            type="checkbox"
                            disabled={disabled}
                            name={userOptionKey}
                            id={userOptionKey}
                            checked={checked}
                        />
                        <label htmlFor={userOptionKey}>
                            <span></span>
                            {userName}
                        </label>
                        {note}
                    </li>
                );
            }

            // EA-61 - if we have only one user and none of the addon subscriptions are disabled
            // then don't bother showing the options since it'll already be checked
            if ( !this.props.feature_flags.default_addon_select_for_single_user || this.props.users.objects.length > 1 || _.some( this.disabled, disabled => disabled === true ) ) {
                if (smallScreens) {
                    usersPanelMobile = (
                        <div className="extras__panel">
                            <span className="extras__panel-title">
                                {content.whoIsExtraFor}
                            </span>
                            <ul className="extras__panel-list">{users}</ul>
                        </div>
                    );
                } else {
                    usersPanel = (
                        <div className="extras__panel">
                            <span className="extras__panel-title">
                                {content.whoIsExtraFor}
                            </span>
                            <ul className="extras__panel-list">{users}</ul>
                        </div>
                    );
                }
            } else {
                usersPanelMobile = null;
                usersPanel = null;
            }
        }

        for (i = 0; i < subs.length; i++) {
            sub = subs[i];
            checked = sub.id === this.props.extras[key];
            extraChangeFunction = this._onSelectExtra.bind(null, key, sub.id);

            const extraDeselectFunction = this._onDeselectExtra.bind(null, key, sub.id);
            const isRetailPack = !!sub.tags.find(
                tag => tag.toLowerCase().indexOf("retail") !== -1
            );
            const timePeriod = isRetailPack
                ? ""
                : " " +
                content.per +
                " " +
                (1 === sub.duration ? content.month : content.year);

            const commonGroups = getCommonGroups({
                extraSubGroups: sub.groups,
                addOnGroups: this.props.centre.addOnGroups,
                usersObjects: this.props.users.objects,
                duration: this.props.d,
                durationType: this.props.dt,
                groupFilter: 'jad'
            });
            const wrongSelect = commonGroups && !commonGroups.length;
            const classNameMaybeHide = (checked || !wrongSelect) ? '' : ' hide';
            const maybeError = (!checked || !wrongSelect) ? '' : content.extraNotAvailable;
            if (wrongSelect && !checked) {
                optionsHidden.push(sub.id);
            }
            // Subscription options
            options.push(
                <li
                    key={optionKey + "_" + i}
                    className={`extras__list-item-option extras__list-item-option-large${classNameMaybeHide}`}
                >
                    <label
                        key={optionKey + "_l_" + i}
                        htmlFor={optionKey + "_" + i}
                    >
                        <span></span>
                        {content.yesPleaseAddForOnly.replace(
                            "{price}",
                            utils.formatPrice(sub.listPriceInPence) + timePeriod
                        )}
                    </label>
                    {' '}
                    <ButtonExtras
                        key={optionKey + "_o_" + i}
                        onDeselect={extraDeselectFunction}
                        onSelect={extraChangeFunction}
                        name={optionKey}
                        id={optionKey + "_" + i}
                        value={sub.id}
                        checked={checked}
                        disabled={checked ? false : (wrongSelect || extraSelected)}
                        error={maybeError}
                    />
                    {usersPanelMobile}
                </li>
            );
        }

        //only show add on if it's available to at least one user
        if (this.allUsersDisabled()) {
            return null;
        } else {
            // Applicable subscription id for map view
            const applicableSubscriptionId = subs.some(subscription =>
                this.props.mapAddonSubscriptionIds.includes(subscription.id)
            );
            const shouldHide = options.length === optionsHidden.length;
            return (
                <li
                    className={
                        "extras__list-item " +
                        (usersPanel ? 'extras__list-item--active' : '') +
                        (shouldHide ? ' hide' : '')
                    }
                >
                    {media}
                    <div className="extras__list-content">
                        <h4
                            className="extras__list-item-tilte"
                            dangerouslySetInnerHTML={{ __html: title }}
                        ></h4>
                        <p
                            className="extras__list-item-desc"
                            dangerouslySetInnerHTML={{ __html: desc }}
                        />
                        <ul className="extras__list-item-options">{options}</ul>
                        {usersPanel}

                        {/* Are we allowed to show the "show me more centres" button? */}
                        {/* Essentially, this will allow the customer to view the "other" centres to entice them into upgrading */}
                        {/* By default we actually hide this view because we don't believe the traffic will be great */}
                        {applicableSubscriptionId ? (
                            <button
                                onClick={this.allowShowingMoreCentres}
                                className="button"
                                key="showMeMoreCentres"
                            >
                                {
                                    this.props.siteBandingContent
                                        .showMeMoreCentres
                                }
                            </button>
                        ) : null}

                        {/* Only show banded centre map viewer if this subscription id is within the MAP02 group */}
                        {applicableSubscriptionId &&
                        this.props.showMoreCentresIsVisible ? (
                            <BandedCentresMapViewer
                                showUpgradeHeadlinePrices={false}
                                showChangeCentreButton={false}
                                showUpgradesUntil={tierUpgrade}
                                key={`addons-${this.props.selectedCentre.site_id}`}
                                selectedCentre={this.props.selectedCentre}
                            />
                        ) : null}
                    </div>
                </li>
            );
        }
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.stage2.extra,
        feature_flags: state.app.settings,
        siteBandingContent: state.app.content.siteBanding,
        d: state.selections.duration,
        dt: state.selections.durationType,
        extras: state.selections.extras,
        promo: state.selections.promo,
        user: state.user.user,
        users: state.selections.users,
        stageStep: state.stages.showStep,
        breakpoint: state.app.breakpoint,
        usersHaveExtras: state.app.usersHaveExtras,

        // For site banding
        mapAddonSubscriptionIds: state.siteBanding.MAP02,
        selectedCentre: state.centreFinder.selected.info,

        // Is the "show more centres" currently visible
        showMoreCentresIsVisible: state.siteBanding.showMoreCentresIsVisible
    };
}

module.exports = connect(mapStateToProps)(Extra);
