const React = require('react'),
    httpFetch = require('../../utils/httpFetch');

const KioskHeader = ({ kioskHomeUrl, logoutUrl, showLogout }) => {
    const logout = () => {
        httpFetch
            .fetch(logoutUrl, {
                method: 'GET'
            })
            .then(() => {
                window.location = kioskHomeUrl;
            });
    };

    return (
        <div className="kiosk-menu__intro">
            <div className="kiosk_top__home">
                <a href={kioskHomeUrl}>Kiosk Home</a>
            </div>
            <div className="kiosk_top__logo">
                <a href={kioskHomeUrl} />
            </div>
            <div className="kiosk_top__logout">
                {showLogout && <a onClick={logout}>Logout</a>}
            </div>
        </div>
    );
};

module.exports = KioskHeader;
