var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../actions'),

    RetrieveSelections;

RetrieveSelections = React.createClass({
    componentDidMount: function componentDidMount() {
        if (this.props.token) {
            this.props.dispatch(actions.app.showLoader(true));
            this.props.dispatch(actions.saveSelections.retrieveSelections(this.props.token));
        } else {
            this.props.dispatch(actions.stages.goHome());
        }
    },

    render: function() {
        return (<div>{ this.props.content.loading }</div>);
    }
});


function mapStateToProps(state) {
    return {
        content: state.app.content.retrieveSelections
    };
}

module.exports = connect(mapStateToProps)(RetrieveSelections);
