var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../../actions'),

    viewUtils       = require('../../../utils/viewUtils'),

    Button          = require('../../common/button'),
    CentreDetails   = require('./centreDetails'),

    scrollToComponent = require('react-scroll-to-component'),
    moment          = require('moment'),

    CentreResults;

CentreResults = React.createClass({

    getInitialState: function() {
        return {
            expanded: null,
            initialSearch: true
        };
    },

    componentDidUpdate: function componentDidUpdate() {
        var centreModuleList = document.getElementById('centre-module-list');

        if (this.state.initialSearch && centreModuleList) {
            scrollToComponent(centreModuleList, {
                align: 'top',
                offset: -100
            });
            this.setState({
                initialSearch: false
            });
        }
    },

    _handleMoreInfoClick: function _handleMoreInfoClick(key, e) {
        e.preventDefault();

        var oldElet = document.querySelector('.module__list-item-details--centre-finder-' + this.state.expanded),
            elt = document.querySelector('.module__list-item-details--centre-finder-' + key);

        if (this.state.expanded && key === this.state.expanded) {
            this.props.dispatch(actions.centreFinder.collapseResult(key));
            this.setState({ expanded: null });
            oldElet && viewUtils.furl(oldElet);
        } else{
            this.props.dispatch(actions.centreFinder.expandResult(key));
            this.setState({ expanded: key });
            oldElet && viewUtils.furl(oldElet);
            elt && viewUtils.furl(elt);
        }
    },

    _handleMoreTimesClick: function _handleMoreTimesClick(key, e) {
        e.preventDefault();

        var parent = document.querySelector('.module__list-item-details--centre-finder-' + key),
            elt = document.querySelector('.centre-finder__results-details-list-container-' + key);

        if (elt.classList.contains('button__more-info--expanded')) {
            elt.classList.remove('button__more-info--expanded');
        } else {
            elt.classList.add('button__more-info--expanded');
        }

        elt && viewUtils.furl(elt, parent);
    },

    _handleSelectClick: function _handleSelectClick({siteId, tagsWhiteList, tagsBlackList}, e) {
        e.preventDefault();

        const date = this.props.startDate
            ? moment(this.props.startDate).format('YYYY-MM-DD')
            : 'now';

        if (!this.props.centre || siteId !== this.props.centre.info.site_id) {
            if(this.props.isBooking || this.props.isGymProfile) {
                var users = {},
                    self = this;

                users['freeprofile'] = 1;

                this.props.dispatch(actions.centreFinder.selectCentre(siteId, { date, tagsWhiteList, tagsBlackList }))
                .then(function() {
                    self.props.dispatch(actions.selections.setUsers(users));
                });
            } else {
                this.props.dispatch(actions.centreFinder.selectCentre(siteId, { date, tagsWhiteList, tagsBlackList }));
            }
        }
    },

    _handleChangeClick: function _handleChangeClick(e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal('confirmCentreReset'));
    },

    _loadMore: function _loadMore(e) {
        e.preventDefault();
        this.props.dispatch(actions.centreFinder.findCentres({
            page: this.props.currentPage + 1,
            lat: this.props.location.lat,
            lng: this.props.location.lng,
            facilityTerm: this.props.lookupCentreByFacilityNameTerm
        }));
    },

    removeLessonsUser: function removeLessonsUser(userId, level) {
        this.props.dispatch(actions.app.showModal('removeLessonsUser', {userId: userId, level: level}));
    },

    render: function() {
        var content = this.props.content,
            centresList = null,
            centres = [],
            self = this,
            tagsWhiteList = this.props.tagsWhiteList,
            tagsBlackList = this.props.tagsBlackList,
            loadMoreButton,
            selected,
            expanded,
            result,
            i;

        if (this.props.results && this.props.results.length) {
            for (i = 0; i < this.props.results.length; i++) {
                result = this.props.results[i];
                selected = false;
                expanded = (this.state.expanded && ((i+1) === this.state.expanded)) ? true : false;

                if (this.props.centre && this.props.centre.info) {
                    if (result.site_id !== this.props.centre.info.site_id) {
                        continue;
                    } else {
                        selected = true;
                    }
                }

                centres.push(<CentreDetails
                    canChange={!this.props.isRestricted}
                    content={ this.props.centreDetailsContent }
                    details={ { info: result } }
                    expanded={ expanded }
                    breakpoint={ this.props.breakpoint }
                    index={ i }
                    key={ 'centre_' + i }
                    lessons={ this.props.lessons }
                    onShowMore={ self._handleMoreInfoClick.bind(null, i+1) }
                    onShowMoreTimes={ self._handleMoreTimesClick.bind(null, i+1) }
                    onSelectCentre={ self._handleSelectClick.bind(null, { siteId: result.site_id, tagsWhiteList, tagsBlackList}) }
                    onChangeCentre={ self._handleChangeClick }
                    removeLessonsUser={ this.removeLessonsUser }
                    selected={ selected }
                    users={ this.props.users }
                />);
            }

        } else if (this.props.centre) {
            centres.push(<CentreDetails
                canChange={!this.props.isRestricted}
                content={ this.props.centreDetailsContent }
                details={ this.props.centre }
                expanded={ expanded }
                breakpoint={ this.props.breakpoint }
                index={ 0 }
                key={ 'centre_0' }
                lessons={ this.props.lessons }
                onShowMore={ self._handleMoreInfoClick.bind(null, 1) }
                onShowMoreTimes={ self._handleMoreTimesClick.bind(null, 1) }
                onChangeCentre={ self._handleChangeClick }
                removeLessonsUser={ this.removeLessonsUser }
                selected={ true }
                users={ this.props.users }
            />);
        }

        if (!this.props.centre && this.props.lastPage !== this.props.currentPage) {
            loadMoreButton = <div className='centre-finder__results-load-more'><Button key='button_load_more' className='button__load-more centre-finder__results-load-more__button' onClick={ self._loadMore }><span>{ content.buttonLoadMore }</span></Button></div>;
        }

        if (centres.length && (!this.props.lessons.inView || this.props.lessons.typeId)) {
            centresList = (<div>
                    <ul className='module__list' id='centre-module-list'>{ centres }</ul>
                    { loadMoreButton }
                </div>);
        }

        return centresList;
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        centreDetailsContent: state.app.content.stage1.centreDetails,
        content: state.app.content.stage1.centreResults,
        isRestricted: state.restrictions.restricted,
        results: state.centreFinder.results,
        lessons: state.lessons,
        location: state.centreFinder.location,
        currentPage: state.centreFinder.currentPage,
        lastPage: state.centreFinder.lastPage,
        users: state.selections.users,
        tagsWhiteList: state.app.tagsWhiteList,
        tagsBlackList: state.app.tagsBlackList,
        breakpoint: state.app.breakpoint,
        startDate: state.selections.startDate,
        lookupCentreByFacilityNameTerm: state.centreFinder.lookupCentreByFacilityNameTerm,
    };
}

module.exports = connect(mapStateToProps)(CentreResults);
