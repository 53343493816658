// TODO: This should extend a sidebar Expander component
var React           = require('react'),
    connect         = require('react-redux').connect,

    Button          = require('../common/button'),
    SaveSelections  = require('../common/saveSelectionsButton'),
    Config          = require('../../config'),

    SidebarLinks;

SidebarLinks = React.createClass({
    render: function render() {

        if (this.props.isRenewals) {
            return null;
        }

        var content = this.props.content,
            showDiscountLinks = this.props.centre && this.props.centre.info && !this.props.discount && !Config.disabledFeatures.discountLinks,
            // discountLink = showDiscountLinks ? (<Button key='sdmld' className='sidebar-link' onClick={ this.props.showModal.bind(null, 'discount') }>{ content.discountModalLink }</Button>) : null,
            // corporateLink = showDiscountLinks ? (<Button key='sdmlc' className='sidebar-link' onClick={ this.props.showModal.bind(null, 'corporateDiscount') }>{ content.corporateModalLink }</Button>) : null,
            saveSelectionsButton;

        var removeDiscount = (this.props.centre && this.props.discount) ? <Button key='remove' className='sidebar-link' onClick={ this.props.removeDiscount }>Remove Discount</Button> : null ;

        if (this.props.allUsersHaveSelections) {
            saveSelectionsButton = <SaveSelections />;
        }

        return <div className='sidebar-discounts'>
            <div className='sidebar-links'>
                { saveSelectionsButton }
                { removeDiscount }
            </div>
        </div>;
    }
});

SidebarLinks.propTypes = {
    allUsersHaveSelections: React.PropTypes.bool,
    centre: React.PropTypes.object,
    content: React.PropTypes.object,
    discount: React.PropTypes.object,
    showModal: React.PropTypes.func,
    removeDiscount: React.PropTypes.func
};

module.exports = connect()(SidebarLinks);
