var viewUtils         = require('./viewUtils'),
    exports;

module.exports = exports = {

    buildDiscountCode: function buildDiscountCode(options) {
        return {
            type: options.type,
            id: options.id || (options.value && options.value.trim().toLowerCase().replace(/[\s-]+/g, '')) || '',
            details: options.details || { name: options.value }
        };
    },

    formatPrice: function formatPrice(amountInPence, currency) {
        if (!amountInPence) {
            return (currency || '£') + (amountInPence / 100).toFixed(2);
        }

        var sign = '';

        if (amountInPence < 0) {
            sign = '-';
            amountInPence = -amountInPence;
        }

        return sign + (currency || '£') + (amountInPence / 100).toFixed(2);
    },

    getDateParts: function getDateParts(source, padded) {
        var dateParts = {},
            day, month, year,
            splitSource,
            date;

        if (source) {
            // If a string is passed, it's a '10-10-1980' format
            if (source.indexOf && source.indexOf('-') !== -1) {
                splitSource = source.split('-');
                day = parseInt(splitSource[2], 10);
                month = parseInt(splitSource[1], 10);
                year = parseInt(splitSource[0], 10);

            } else {
                date = new Date(source);

                day = date.getDate();
                month = date.getMonth() + 1;
                year = date.getFullYear();
            }

            dateParts = {
                day: (padded && day < 10) ? '0'+day : day,
                month: (padded && month < 10) ? '0'+month : month,
                year: year
            };
        }

        return dateParts;
    },

    getDateWithOffset: function getDateWithOffset(date, options) {
        if (!options) {
            return date;
        }

        var dateWithoutTime = exports.getDateWithoutTime(date),
            dayInMilliseconds = 86400000,
            newDate;

        if (null !== options.days && undefined !== options.days) {
            newDate = new Date(dateWithoutTime.getTime() + (options.days * dayInMilliseconds));
        }

        // If we need to use this for date ranges in birthdays,
        //  maybe we need to be able to pass years as parameters
        //  to calculate ranges based on that.
        // if (options.years) {
        //     newDate = new Date(dateWithoutTime.getTime() + (options.days * dayInMilliseconds));
        // }

        return newDate;
    },

    getShortDate: function getShortDate(date, sep) {
        sep = sep || '-';
        var parts = exports.getDateParts(date, true);
        return parts.year + sep + parts.month + sep + parts.day;
    },

    getDateWithoutTime: function getDateWithoutTime(date) {
        var dateParts = exports.getDateParts(date);
        return new Date(dateParts.year, (dateParts.month - 1), dateParts.day);
    },

    getDateWithoutTimeString: function getDateWithoutTimeString(date) {
        var dateParts = exports.getDateParts(date);
        return dateParts.year + '-' + viewUtils.zeroPad(dateParts.month, 2) + '-' + viewUtils.zeroPad(dateParts.day, 2);
    },

    getMinNoOfStartDaysDate: function getMinNoOfStartDaysDate(users) {
        var minDaysFromNow,
            maxDate,
            u,
            i,
            j;

        if (users.length) {
            if (users[0] && users[0].availableSubscriptions.length) {
                minDaysFromNow = users[0].availableSubscriptions[0].noOfStartDays;
            }

            for (i = 0; i < users.length; i++) {
                u = users[i];

                for (j = 0; j < u.availableSubscriptions.length; j++) {
                    if (u.availableSubscriptions[j].noOfStartDays < minDaysFromNow) {
                        minDaysFromNow = u.availableSubscriptions[j].noOfStartDays;
                    }
                }
            }
        }

        maxDate = exports.getDateWithOffset(new Date(), { days: minDaysFromNow });

        return {
            days: minDaysFromNow,
            date: maxDate && maxDate.toString()
        };
    },

    getOffsetDaysFromNow: function getOffsetDaysFromNow(valueDate) {
        var et = exports.getDateWithoutTime(Date.now()).getTime(),
            ev = valueDate && valueDate.getTime();

        if (ev) {
            return Math.floor((ev - et) / (24 * 3600 * 1000));
        }

        return undefined;
    },

    //  By default inclusion is lax: if the two ranges overlap by any
    //      amount, return true.
    //  If this should be made strict (only return true if range2
    //      is fully included in range1), uncomment the lower block.
    //
    // Note: this methods assumes that min < max for any data given...
    hasRangeOverlap: function hasRangeOverlap(r1, r2) {
        var valid = true;

        // If values are undefined, set them up so rules can be applied.
        r1.min = r1.min || 0;
        r2.min = r2.min || 0;

        // If no intersection, return false
        if ( (r1.max !== null && r1.max !== undefined && r2.min > r1.max) ||
             (r2.max !== null && r2.max !== undefined && r2.max < r1.min)
            ) {

            valid = false;
        }

        // Uncomment this to make the validation STRICT.
        // If r2 is fully outside r1, retur false;
        // if (r2.min < r1.min || (r1.max !== null && r1.max !== undefined && r2.max !== null && r2.max !== undefined && r2.max > r1.max)) {
        //     valid = false;
        // }

        return valid;
    },

    inRange: function inRange(val, range) {
        var inRange = true;

        if (val === null || val === undefined || (range.min && range.min > val) || (range.max && range.max < val)) {
            inRange = false;
        }

        return inRange;
    },

    getLessonType: function getLessonType(lessons, unformatted, lessonTypeId) {
        for(var l in lessons.typesList) {
            if(lessons.typesList[l]['id'] === parseInt(lessonTypeId ? lessonTypeId : lessons.typeId)) {
                return unformatted ? lessons.typesList[l]['name'] : lessons.typesList[l]['name'].toLowerCase().replace(/\s/g, '');
            }
        }

        return null;
    },

    formatLessonPrice: function formatLessonPrice(lessons, weekdays) {
        var prices = lessons.prices ? (lessons.discountedUser ? lessons.prices.discounts : lessons.prices.prices) : [],
            priceCount = {};

        for(var i = 0; i < prices.length; i++) {
            var price = prices[i];
            var dayType = weekdays.indexOf(price.weekday) !== -1 ? 'Weekday ' : 'Weekend ';

            priceCount[price.category] = priceCount[price.category] || {};
            priceCount[price.category][price.duration] = priceCount[price.category][price.duration] || {};
            priceCount[price.category][price.duration][price.month_fee] = dayType;
        }

        return priceCount;
    },

    sortObjectByPropertyName: function sortObjectByPropertyName(o) {
        var sorted = {}, 
            a = [],
            key;

        for (key in o) {
            if (o.hasOwnProperty(key)) {
                a.push(key);
            }
        }

        a.sort(function(a, b){return a-b});

        for (key = 0; key < a.length; key++) {
            sorted[a[key]] = o[a[key]];
        }
        return sorted;
    },

    getFacilitiesObjectsFromIds: function getFacilitiesObjectsFromIds(obj, ids) {
        var results = [],
            item,
            i;

        for (i = 0; i < ids.length; i++) {
            item = obj[ids[i]];
            item && results.push(item);
        }

        return results;
    }
};
