import React from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import actions from "../../../actions";

class FacilityFilter extends React.Component {
    constructor ( props ) {
        super( props );
        this.state = { displayAutosuggest: !!props.lookupCentreByFacilityNameTerm };
    }

    onClickDisplayAutosuggest () {
        this.setState( { displayAutosuggest: true } );
    }

    onClickHideAutosuggest () {
        this.setState( { displayAutosuggest: false } );
        this.props.clearSearchTerm();
        this.props.onSuggestionSelect( this.props.centreFinder )( null, { suggestion: '' } );
    }

    render () {
        return ( <div className="facility-filter--autosuggest__container-parent facility-filter-align-fix">
            <button
                className={ `facility-filter--button with-image ${ this.state.displayAutosuggest ? 'active' : 'inactive' }` }
                onClick={ this.state.displayAutosuggest ? this.onClickHideAutosuggest.bind( this ) : this.onClickDisplayAutosuggest.bind( this ) }
            >
                <img src="/img/profile/show-more-red.svg" height="20px" width="20px" />
            </button>

            { this.state.displayAutosuggest ? <Autosuggest
                suggestions={ this.props.suggestions }
                onSuggestionsFetchRequested={ () => null }
                onSuggestionsClearRequested={ () => null }
                onSuggestionSelected={ this.props.onSuggestionSelect( this.props.centreFinder ) }
                getSuggestionValue={ suggestion => suggestion }
                renderSuggestion={ suggestion => <span>{ suggestion }</span> }
                inputProps={ {
                    placeholder: this.props.translations.facilityFilter.placeholderText,
                    onChange: this.props.onChange,
                    value: this.props.lookupCentreByFacilityNameTerm || ''
                } }
                theme={ {
                    container: "facility-filter--autosuggest__container",
                    containerOpen: "facility-filter--autosuggest__container--open",
                    input: "facility-filter--autosuggest__input",
                    suggestionsContainer: "facility-filter--autosuggest__suggestions-container",
                    suggestionsList: "facility-filter--autosuggest__suggestions-list",
                    suggestion: "facility-filter--autosuggest__suggestion",
                    suggestionFocused: "facility-filter--autosuggest__suggestion--focused",
                    sectionContainer: "facility-filter--autosuggest__section-container",
                    sectionTitle: "facility-filter--autosuggest__section-title"
                } }
            /> : <button
                className={ `facility-filter--button with-text ${ this.state.displayAutosuggest ? 'active' : 'inactive' }` }
                onClick={ this.state.displayAutosuggest ? this.onClickHideAutosuggest.bind( this ) : this.onClickDisplayAutosuggest.bind( this ) }
            >{ this.props.translations.facilityFilter.addAFacilityText }</button> }
        </div> );
    }
}

export default connect(
    // MapStateToProps
    state => ( {
        // Language translations
        translations: state.app.content.stage1.centreFinder,

        centreFinder: state.centreFinder,

        lookupCentreByFacilityNameTerm: state.centreFinder.lookupCentreByFacilityNameTerm,
        suggestions: state.centreFinder.filteredLookupFacilityNames,
    } ),

    // MapDispatchToProps
    dispatch => ( {
        clearSearchTerm: () => {
            dispatch( actions.centreFinder.clearFacilityFilterSearchTerm() );
        },
        onChange: ( event, { newValue } ) => {
            dispatch( actions.centreFinder.setFacilityFilterSearchTerm( newValue ) );
        },
        onSuggestionSelect: ( { currentPage, location: { lat, lng } } ) => ( event, { suggestion } ) => {
            dispatch( actions.centreFinder.setFacilityFilterSearchTerm( suggestion ) );
            dispatch( actions.centreFinder.resetGeolocResults() );
            dispatch( actions.centreFinder.findCentres( { lat, lng, facilityTerm: suggestion } ) );
        }
    } ),
)( FacilityFilter );
