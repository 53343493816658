var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var defaultState = {
            collections: null,
            errors: {},
            fields: {},
            paymentUrl: null,
            startDateValid: true,
            total: null,
            validating: false,
            valid: true,
            validated: false
        },
        newState,
        key;

    state = state || defaultState;

    switch (action.type) {

        case actionTypes.BANK_DETAILS.CHANGE_FIELD:
            newState = Object.assign({}, state);
            newState.fields[action.field] = action.data;

            newState.valid = true;

            for (key in newState.fields) {
                if (!newState.fields[key].valid) {
                    newState.valid = false;
                    break;
                }
            }

            return Object.assign({}, state, newState);

        case actionTypes.BANK_DETAILS.FORMS_VALIDATED:
            newState = Object.assign({}, state);

            newState.errors = action.validationErrors || {};
    // TODO: WHY IS THIS COMMENTED OUT?
            newState.valid = action.valid;
            newState.validated = true;

            return Object.assign({}, state, newState);

        case actionTypes.BANK_DETAILS.SET_DETAILS:
            return Object.assign({}, state, {
                collections: action.details.collections,
                fields: action.details.account,
                totals: action.details.totals,
                // Since the data comes from a logged in user, we're
                //      assuming they have valid bank details.
                valid: true
            });

        case actionTypes.BANK_DETAILS.SET_PAYMENT_URL:
            return Object.assign({}, state, {
                paymentUrl: action.paymentUrl
            });

        case actionTypes.BANK_DETAILS.SET_START_DATE:
            return Object.assign({}, state, {
                startDateValid: action.value
            });

        case actionTypes.BANK_DETAILS.VALIDATING:
            return Object.assign({}, state, {
                validating: action.validating
            });

        case actionTypes.BANK_DETAILS.WIPEOUT:
            return defaultState;

        default:
            return state;
    }
};
