var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var newState,
        newModals;

    state = state || {
        content: window.appContent,
        settings: window.appSettings,
        debug: false,
        error: null,
        expiryMs: null,
        priceBreakdownExpanded: false,
        profileLoaded: false,
        showLoader: false,
        showLoaderCentreFinder: false,
        showModals: {},
        showSidebar: false,
        started: null,
        timedOut: false,
        cutoffDate: window.cutOffDay,
        kioskHomeUrl: localStorage.getItem('kioskHome')
    };

    switch (action.type) {

        case actionTypes.APP.DEBUG:
            return Object.assign({}, state, {
                debug: true,
                showModal: 'error',
                error: action.error
            });

        case actionTypes.APP.ERROR:
            newModals = Object.assign({}, state.showModals);

            newState = {
                error: action.error
            };

            if (action.notifyUser) {
                newModals.error = true;
            }

            newState.showModals = newModals;

            return Object.assign({}, state, newState);

        case actionTypes.APP.EXPAND_PRICE_BREAKDOWN:
            return Object.assign({}, state, {
                priceBreakdownExpanded: action.expanded
            });

        case actionTypes.APP.HIDE_MODAL:
            newModals = {};

            // If no key is past, close all modals.
            if (action.key) {
                newModals = Object.assign({}, state.showModals);
                delete newModals[action.key];
            }

            return Object.assign({}, state, {
                showModals: newModals
            });

        case actionTypes.APP.PROFILE_LOADED:
            return Object.assign({}, state, {
                profileLoaded: true
            });

        case actionTypes.APP.SESSION_TIMEOUT:
            newModals = Object.assign({}, state.showModals);
            newModals['timedOut'] = true;

            return Object.assign({}, state, {
                showModals: newModals,
                timedOut: true
            });

        case actionTypes.APP.SET_BREAKPOINT:
            return Object.assign({}, state, {
                breakpoint: action.breakpoint
            });

        case actionTypes.APP.SHOW_LOADER:
            return Object.assign({}, state, {
                showLoader: action.visible
            });

        case actionTypes.APP.SHOW_LOADER_CENTRE_FINDER:
            return Object.assign({}, state, {
                showLoaderCentreFinder: action.visible
            });

        case actionTypes.APP.SHOW_MODAL:
            newModals = Object.assign({}, state.showModals);
            newModals[action.key] = action.options ? { options: action.options } : true;

            return Object.assign({}, state, {
                showModals: newModals
            });

        case actionTypes.APP.START_SESSION:
            return Object.assign({}, state, {
                expiryMs: action.ms
            });

        case actionTypes.APP.STARTED:
            return Object.assign({}, state, {
                started: action.time
            });

        case actionTypes.APP.TOGGLE_SIDEBAR:
            return Object.assign({}, state, {
                showSidebar: !state.showSidebar
            });

        case actionTypes.APP.SET_CUTOFF_DATE:
            return Object.assign({}, state, {
                cutoffDate: parseInt(action.cutoffDate)
            });

        case actionTypes.APP.SET_KIOSK_HOME_URL:
            return Object.assign({}, state, {
                kioskHomeUrl: action.kioskHomeUrl
            });

        case actionTypes.APP.SET_TAGS_WHITE_LIST:
            return Object.assign({}, state, {
                tagsWhiteList: action.tagsWhiteList
            });

        case actionTypes.APP.SET_TAGS_BLACK_LIST:
            return Object.assign({}, state, {
                tagsBlackList: action.tagsBlackList
            });

        default:
            return state;
    }
};
