var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions"),
    config = require("../../config"),
    Input = require("../common/forms/input"),
    Button = require("../common/button"),
    PromoCodeTerms;

PromoCodeTerms = React.createClass({
    displayName: 'PromoCodeTerms',

    onChangeInput: function onChangeInput(field, value) {
        this.props.dispatch(actions.selections.changePromoTerms(field, value));
    },

    render: function() {
        const {
            discounts: { discount } = {},
            content,
            durationType,
            duration,
            users
        } = this.props;

        const hasPromoCode =
            discount && discount.details && this.props.discounts.discount.type === "code";

        const subs = users.objects
            .map(function(x) {
                return x.availableSubscriptions.find(function(s) {
                    return (
                        s.durationType === durationType &&
                        s.duration === duration
                    );
                });
            })
            .filter(x => !!x);

        const discountTags = subs.reduce(function(acc, x) {
            x.tags
                .map(t => t.match(/9999-(.*)/))
                .forEach(function(m) {
                    if (m) {
                        const s = m[1];
                        acc.indexOf(s) < 0 && acc.push(s);
                    }
                });

            return acc;
        }, []);

        if (!hasPromoCode && discountTags.length === 0) {
            return null;
        }

        var url =
            discount && config.services.app.urls.legal + discount.details.name;

        return (
            <div className="module-container">
                <div className="module module--inactive">
                    <div className="payment-breakdown">
                        <div className="payment-breakdown__header">
                            <h2>{content.title}</h2>
                            {hasPromoCode && (
                                <div>
                                    <Input
                                        className="personal-details__input--radio"
                                        input={{
                                            onChange: this.onChangeInput.bind(
                                                null,
                                                "promoCodePref"
                                            ),
                                            required: true,
                                            className: "forms__input--radio"
                                        }}
                                        label={{
                                            text: content.checkbox.label,
                                            links: [
                                                {
                                                    link: url,
                                                    linkText:
                                                        content.checkbox
                                                            .linkText
                                                }
                                            ]
                                        }}
                                        options={[
                                            {
                                                value: "true",
                                                text: "Yes"
                                            },
                                            {
                                                value: "false",
                                                text: "No"
                                            }
                                        ]}
                                        name={"promoCodePref"}
                                        radioLabelClassName={
                                            "personal-details__radio-label"
                                        }
                                        type="radio"
                                        valid={
                                            this.props.promoTerms[
                                                "promoCodePref"
                                            ].valid
                                        }
                                        value={
                                            this.props.promoTerms[
                                                "promoCodePref"
                                            ] &&
                                            this.props.promoTerms[
                                                "promoCodePref"
                                            ].value
                                        }
                                    />

                                    <p className="personal-details__info">
                                        {content.promotionNote}
                                    </p>
                                </div>
                            )}
                            {discountTags.map(tag => (
                                <div>
                                    <Input
                                        className="personal-details__input--radio"
                                        input={{
                                            onChange: this.onChangeInput.bind(
                                                null,
                                                tag
                                            ),
                                            required: true,
                                            className: "forms__input--radio"
                                        }}
                                        label={{
                                            text: `${content.checkbox.label} (code ${tag})`,
                                            links: [
                                                {
                                                    link:
                                                        config.services.app.urls
                                                            .legal + tag,
                                                    linkText:
                                                        content.checkbox
                                                            .linkText
                                                }
                                            ]
                                        }}
                                        options={[
                                            {
                                                value: "true",
                                                text: "Yes"
                                            },
                                            {
                                                value: "false",
                                                text: "No"
                                            }
                                        ]}
                                        name={tag}
                                        radioLabelClassName={
                                            "personal-details__radio-label"
                                        }
                                        type="radio"
                                        valid={
                                            this.props.promoTerms[tag] &&
                                            this.props.promoTerms[tag].valid
                                        }
                                        value={
                                            this.props.promoTerms[tag] &&
                                            this.props.promoTerms[tag].value
                                        }
                                    />
                                    <p className="personal-details__info">
                                        {content.discountSubNote}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

PromoCodeTerms.propTypes = {
    content: React.PropTypes.object,
    discounts: React.PropTypes.object
};

function mapStateToProps(state) {
    return {
        content: state.app.content.stage4.promoCodeTerms,
        duration: state.selections.duration,
        durationType: state.selections.durationType,
        discounts: state.discounts,
        users: state.selections.users,
        promoTerms: state.selections.promoTerms
    };
}

module.exports = connect(mapStateToProps)(PromoCodeTerms);
