var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),
    history         = require('../../utils/history'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    PaymentFoundModal;

PaymentFoundModal = React.createClass({
    getInitialState: function getInitialState() {
        return { };
    },

    _onOk: function _onRetry() {
        this.props.dispatch(actions.app.hideModal('paymentFound'));

        history.push('/payment/status/success');
    },

    render: function render() {
        var { modal } = this.props;

        return (
            <Modal title={ modal.title } className='modal--confirm' noClose>
                <p dangerouslySetInnerHTML={ {__html: modal.text } }></p>
                <div className='modal__buttons'>
                    <Button className='button button--primed' onClick={ this._onOk }>{ modal.buttonOk }</Button>
                </div>
            </Modal>
        );
    }
});
    
function mapStateToProps(state) {
    return {
        modal: state.app.content.modals.paymentFound
    };
}

module.exports = connect(mapStateToProps)(PaymentFoundModal);