var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/noStagesHeader'),
    Component       = require('../components/gymProfile'),
    actions     = require('../actions');

module.exports = [{

    path: '/gym-profile',

    handler: function(params, query) {
        const store = require('../store');

        store.dispatch(actions.app.setTagsWhiteList('0000-PARTNER'));
        store.dispatch(actions.app.resetTagsBlackList());

        return {
            header: <Header showStages={true} />,
            component: <Component query={query} />
        };
    }
}];
