import React from "react";
import Bold from "../typography/bold";
import Paragraph from "../typography/paragraph";

// This is essentially a notice that can be used to draw attention to a page
export default props => (
    <div className={`alert alert-${ props.type || 'warning' }`}>
        <Bold text={ props.title } />

        <Paragraph text={ props.message } />
    </div>
);