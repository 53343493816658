var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Button          = require('./button'),
    SaveSelectionsButton;

SaveSelectionsButton = React.createClass({
    _onshowModal: function _onshowModal(e) {
        e.preventDefault();
        this.props.dispatch(actions.app.showModal('saveSelections'));
    },

    render: function render() {
        if (!this.props.centre) {
            return null;
        }

        return (<Button className='button__save-selection save-selections-button' onClick={ this._onshowModal }>{ this.props.content.saveSelectionsButton }</Button>);
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.saveSelections,
        centre: state.centreFinder.selected
    };
}

module.exports = connect(mapStateToProps)(SaveSelectionsButton);
