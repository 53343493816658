/**
 * Determines if a "proof of eligibility" is required the given data
 * @todo move these to a "rule engine" of sorts and ensure those pattern matches are dynamic (DB driven)
 * @param {any} data "open" data
 * @returns boolean
 */
export const requiresProofOfEligibility = data => {
    // Data to be tested
    const testable = data.id || data.type || null;

    // Dataset can contain a sub id, types etc
    return /^\d+\-\bCONC\b$/i.test(testable) ||
        /^\d+\-\bJUNIOR\b$/i.test(testable) ||
        /^\d+\-\bSENIOR\b$/i.test(testable) ||
        /^\d+\-\bSTUDENT\b$/i.test(testable);
}