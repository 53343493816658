var React       = require('react'),
    Synchronised;

Synchronised = React.createClass({
    render: function () {
        var props = Object.assign({}, this.props),
            color = props.color;

        return (<svg width="47px" height="46px" viewBox="0 0 47 34" version="1.1">
            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="selection" transform="translate(-8.000000, -16.000000)">
                    <g id="Group-14" transform="translate(5.000000, 5.000000)">
                        <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="60" height="50"></rect>
                        <path d="M17.1457044,41.397146 C15.8140508,41.849211 14.452398,42.2937684 13.1354897,42.6353643 C17.8255138,44.3186762 26.9131983,41.4212776 28.0684167,40.0141382 C27.2289512,39.8704213 26.268981,39.7744312 25.1976582,39.7712137 C22.4906235,39.7712137 20.0205306,40.4211576 17.1457044,41.397146" id="Fill-6" fill={ color } transform="translate(20.601953, 41.458714) scale(-1, 1) rotate(-6.000000) translate(-20.601953, -41.458714) "></path>
                        <path d="M6.71979344,35.8764352 C6.75039031,35.8891372 9.58403547,37.624475 14.8254673,37.6637907 C18.6419589,37.6637907 22.611435,36.6373466 26.3267697,35.6369114 C30.1744826,34.6019993 33.8061442,33.6082175 37.3497617,33.5937009 C45.8201003,33.559224 49.3955636,40.1201193 49.4180429,40.1914925 C49.5960043,40.7576396 49.0152881,40.8919181 48.7461605,40.7564299 C48.7111927,40.738889 45.1969232,38.6866057 39.1456109,38.6714842 C35.4627464,38.6714842 32.1657771,39.4916717 28.5878162,40.60703 C25.6517652,41.5221802 22.092537,42.609715 19.4374777,42.5933838 C10.5056882,42.5383417 6.2552205,36.7099296 6.07226368,36.4837127 C5.89118015,36.2605201 6.12409104,35.6338871 6.71979344,35.8764352" id="Fill-9" fill={ color } transform="translate(27.731824, 38.093566) scale(-1, 1) rotate(-9.000000) translate(-27.731824, -38.093566) "></path>
                        <g id="Group-4" transform="translate(3.000000, 11.000000)" fill="#333333">
                            <path d="M2.72918055,1.92015921 C1.73736213,1.56736716 0.73081113,1.86837081 0.233060349,2.84341233 C-0.258113387,3.80820131 0.0533754739,4.76431509 0.920230032,5.3571214 L9.58798637,11.1101029 L9.58798637,19.8607019 C10.7442309,19.6191103 12.0251762,19.4705801 13.4502904,19.4705801 C15.4449767,19.4705801 17.3107529,19.7495014 19.3556878,20.2555556 L19.3556878,16.1138614 L24.8888889,18.5997047 L24.8888889,13.8948468 L2.72918055,1.92015921 Z" id="Fill-1"></path>
                            <path d="M23.6769594,6.67067538 C23.2816196,8.13132898 22.0470708,9.36374728 20.7958087,10.135512 L25.8092859,13.4002614 L25.8092859,22.6052288 C27.8235254,23.2462745 28.9294554,22.9647277 31,24 C33,25 34.6007422,25.7631373 35.4217131,25.6015686 L35.4217131,18.3769935 L44.3110334,21.8546405 C44.5745933,21.9534641 44.8376354,22.0028758 45.0905804,22.0028758 C45.7556936,22.0028758 46.3537519,21.6619608 46.7221661,20.9803922 C47.2381532,20.0282353 46.9269558,19.0501961 46.0948522,18.4554248 L23.6769594,6.67067538 Z" id="Fill-3"></path>
                            <path d="M23.1111111,4.44471017 C23.1111111,1.98971661 21.1207163,0 18.6661353,0 C16.2118199,0 14.2222222,1.98971661 14.2222222,4.44471017 C14.2222222,6.89917228 16.2118199,8.88888889 18.6661353,8.88888889 C21.1207163,8.88888889 23.1111111,6.89917228 23.1111111,4.44471017" id="Fill-10"></path>
                            <path d="M38.8148148,6.07407407 C38.8148148,8.52877411 36.8254829,10.5185185 34.3703704,10.5185185 C31.9157893,10.5185185 29.9259259,8.52877411 29.9259259,6.07407407 C29.9259259,3.61937404 31.9157893,1.62962963 34.3703704,1.62962963 C36.8254829,1.62962963 38.8148148,3.61937404 38.8148148,6.07407407" id="Fill-12"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>);
    }
});

module.exports = Synchronised;
