var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),

    Sidebar         = require('../sidebar'),
    Content         = require('./content'),

    Stage3Index;

Stage3Index = React.createClass({
    displayName: 'Stage3Index',

    _redirectIfPropsNotValid: function _redirectIfPropsNotValid(props) {
        if (!props.hasCentre || (!props.deeplinkAddons && !props.hasSelectedMemberships)) {
            props.dispatch(actions.stages.goHome());
            props.dispatch(actions.stages.setStep(0));
        }
    },

    componentWillMount: function componentWillMount() {
        this._redirectIfPropsNotValid(this.props);
    },

    componentWillUpdate: function componentWillUpdate(nextProps) {
        this._redirectIfPropsNotValid(nextProps);
    },

    render: function () {
        return (<div className='page'>
                    <Content />
                    <Sidebar />
                </div>);
    }
});

function mapStateToProps(state) {
    return {
        hasCentre: state.centreFinder.selected,
        hasSelectedMemberships: state.memberships.selected,
        deeplinkAddons: state.selections.deeplinkAddons
    };
}

module.exports = connect(mapStateToProps)(Stage3Index);
