var React           = require('react'),
    connect         = require('react-redux').connect,
    Autosuggest     = require('react-autosuggest'),

    config          = require('../../config'),
    actions         = require('../../actions'),
    utils           = require('../../utils'),

    Button          = require('../common/button'),
    Modal           = require('../common/modal'),

    CorporateDiscountsModal;

CorporateDiscountsModal = React.createClass({

    getInitialState: function getInitialState() {
        return {
            inputValue: null,
            suggestions: [],
            value: null
        };
    },

    componentWillMount: function componentWillMount() {
        this.props.dispatch(actions.discounts.getDiscounts(this.props.centre.info.site_id));
    },

    _onChangeInput: function _onChangeInput(field, e, data) {
        this.setState({
            value: data.newValue
        });
    },

    _submitForm: function _submitForm(e) {
        e.preventDefault();

        if (this.state.suggestion) {
            this._applyDiscount(utils.buildDiscountCode({
                type: 'corporate',
                id: this.state.suggestion.id,
                details: this.state.suggestion
            }));
        }
    },

    _applyDiscount: function _applyDiscount(discount) {
        this.props.dispatch(actions.app.showLoader(true));
        this.props.dispatch(actions.app.hideModal(this.props.id));
        this.props.dispatch(actions.centreFinder.selectCentre(this.props.centre.info.site_id, { discount: discount }));
    },

    _onSuggestionSelected: function _onSuggestionSelected(event, data) {
        event.preventDefault();

        if (data.suggestionValue) {
            this.setState({
                valid: true,
                value: data.suggestionValue,
                suggestion: data.suggestion
            });
        }
    },

    _getSuggestions: function _getSuggestions (value) {
        var inputValue = (value && value.trim().toLowerCase()) || '';

        if (inputValue.length) {
            return this.props.discounts.filter(function (discount) {
                return discount.companyName.trim().toLowerCase().indexOf(inputValue) !== -1;
            });

        } else {
            return this.props.discounts;
        }
    },

    _onSuggestionsFetchRequested: function _onSuggestionsFetchRequested(data) {
        this.setState({
            suggestions: this._getSuggestions(data.value)
        });
    },

    _onSuggestionsClearRequested: function onSuggestionsClearRequested() {
        this.setState({ suggestions: [] });
    },

    // When suggestion is clicked, Autosuggest needs to populate the input field
    // based on the clicked suggestion.
    _getSuggestionValue: function _getSuggestionValue(suggestion) {
        return (suggestion && suggestion.companyName) || '';
    },

    _renderSuggestion: function _renderSuggestion(suggestion) {
        return (
            <span>{ suggestion.companyName }</span>
        );
    },

    render: function render() {
        var enquiryUrl = config.services.app.urls.enquiries + ((this.props.centre && this.props.centre.info && this.props.centre.info.site_id) ? '&siteid=' + this.props.centre.info.site_id : ''),
            modalButtonClass = 'button' + (this.state.valid ? '' : ' button--disabled'),
            content = this.props.content,
            autosuggestTest,
            inputProps,
            theme,
            form;

        if (!this.props.discounts || !this.props.discounts.length) {
            form = <span className='corporate-discounts__form'>{ content.loading }</span>;

        } else {
            // Autosuggest will pass through all these props to the input field.
            inputProps = {
                id: 'corpNameAutosuggest',
                placeholder: content.fields.corpName.placeholder,
                value: this.state.value || '',
                onChange: this._onChangeInput.bind(null, 'corpName'),
                autoFocus: true
            };

            theme = {
                container:            'autosuggest__container',
                containerOpen:        'autosuggest__container--open',
                input:                'autosuggest__input',
                suggestionsContainer: 'autosuggest__suggestions-container',
                suggestionsList:      'autosuggest__suggestions-list',
                suggestion:           'autosuggest__suggestion',
                suggestionFocused:    'autosuggest__suggestion--focused',
                sectionContainer:     'autosuggest__section-container',
                sectionTitle:         'autosuggest__section-title'
            };

            autosuggestTest = (
                <Autosuggest
                suggestions={ this.state.suggestions }
                onSuggestionsFetchRequested={ this._onSuggestionsFetchRequested }
                onSuggestionsClearRequested={ this._onSuggestionsClearRequested }
                onSuggestionSelected={ this._onSuggestionSelected }
                getSuggestionValue={ this._getSuggestionValue }
                renderSuggestion={ this._renderSuggestion }
                inputProps={ inputProps }
                theme={ theme } />
            );

            form = <form className='corporate-discounts__form corporate-discounts__form--narrow' onSubmit={ this._submitForm }>
                <p dangerouslySetInnerHTML={ {__html: content.desc } }></p>
                <fieldset>
                    <label className='corporate-discounts__header' htmlFor='corpNameAutosuggest'>{ content.fields.corpName.label }</label>
                    <div className='input'>
                        { autosuggestTest }
                    </div>
                    <Button className={ modalButtonClass } disabled={ !this.state.valid }>{ content.buttonApply }</Button>
                </fieldset>
                <div className='corporate-discounts__help'>
                    <span className='corporate-discounts__header'>{ content.cantFindCompany }</span>
                    <a href={ enquiryUrl }>{ content.enquiryLink }</a>
                </div>
            </form>;
        }

        return <Modal title={ content.title } className='modal--corporate-discounts' id={ this.props.id }>
            <div className='corporate-discounts__modal'>
                { form }
            </div>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.modals.corpDiscounts,
        discounts: state.discounts.discounts,
        selectedDiscount: state.discounts.selected,
        users: state.selections.users.objects
    };
}

module.exports = connect(mapStateToProps)(CorporateDiscountsModal);
