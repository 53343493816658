var React       = require('react'),
    connect     = require('react-redux').connect,

    actions     = require('../../actions'),
    Modal       = require('../common/modal'),
    Button      = require('../common/button'),

    RemoveLessonsUserModal;

RemoveLessonsUserModal = React.createClass({
    onAccept: function onAccept(e) {
        e.preventDefault();

        var users = this.props.users.objects,
            userId = this.props.options.userId,
            promises = [],
            self = this;

        this.props.dispatch(actions.app.showLoader(true));

        var user = users[userId];
        if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length && user.lesson.selectedLessons[this.props.options.level]) {
            var lesson = user.lesson.selectedLessons[this.props.options.level];

            if(lesson.bookingRef) {
                promises.push(this.props.dispatch(actions.lessons.releaseReservation(userId, lesson.level)));
            }

            promises.push(this.props.dispatch(actions.selections.removeLessonFromUser(lesson.level, userId)));
            
            Promise.all(promises)
                .then(function () {

                    if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length === 0) {
                        self.removeUser();
                    } else {
                        self.props.dispatch(actions.app.showLoader(false));
                        self.props.dispatch(actions.app.hideModal(self.props.id));
                    }
                });
        } else {
            self.removeUser();
        }
    },

    removeUser: function removeUser() {
        var users = this.props.users,
            userId = this.props.options.userId;
            siteId = this.props.siteId;
            
        this.props.dispatch(actions.app.hideModal(this.props.id));

        if(users && users.total === 1) {
            this.props.dispatch(actions.stages.showStep(1));
            this.props.dispatch(actions.stages.setStep(1));
            this.props.dispatch(actions.lessons.refreshTimer('clear'));
        } else if(users && users.total === 2) {
            var juniorAmount = users.count[siteId + '-JUNIOR'] || users.count['0000-JUNIOR'];

            if(juniorAmount && juniorAmount === 1) {
                var guardianPlace = (users.objects.length - 1) - juniorAmount;

                if(users.objects[guardianPlace] && users.objects[guardianPlace].guardian === true) {
                    this.props.dispatch(actions.selections.removeUser(guardianPlace));
                    this.props.dispatch(actions.stages.showStep(1));
                    this.props.dispatch(actions.stages.setStep(1));
                    this.props.dispatch(actions.lessons.refreshTimer('clear'));

                    userId--;
                }
            }
        }

        this.props.dispatch(actions.selections.removeUser(userId));
        this.props.dispatch(actions.app.showLoader(false));
    },

    render: function render() {
        var content = this.props.content,
            user = this.props.users.objects[this.props.options.userId],
            name = 'this user',
            level = 'this level';

        if(user) {
            name = user.info && user.info.firstName && user.info.firstName.value || 'this user',
            level = user.lesson.selectedLessons && user.lesson.selectedLessons[this.props.options.level] && user.lesson.selectedLessons[this.props.options.level].levelName;
        }
        
        return <Modal title={ content.title.replace('{name}', name).replace('{lesson}', level) } >
            <Button className='button' onClick={ this.onAccept }>{ content.accept }</Button>
        </Modal>;
    }
});

RemoveLessonsUserModal.propTypes = {
    options: React.PropTypes.object
};

function mapStateToProps(state) {
    return {
        siteId: state.centreFinder.selected.info.siteId,
        content: state.app.content.modals.removeLessonsUser,
        lessons: state.lessons,
        users: state.selections.users
    };
}

module.exports = connect(mapStateToProps)(RemoveLessonsUserModal);
