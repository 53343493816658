var combineReducers = require('redux').combineReducers,
    actionTypes     = require('../actions/actionTypes.js'),

    app             = require('./app'),
    bankDetails     = require('./bankDetails'),
    booking         = require('./booking'),
    centreFinder    = require('./centreFinder'),
    discounts       = require('./discounts'),
    discountCodes   = require('./discountCodes'),
    faqs            = require('./faqs'),
    lessons         = require('./lessons'),
    liveChat        = require('./liveChat'),
    memberships     = require('./memberships'),
    payments        = require('./payments'),
    personalDetails = require('./personalDetails'),
    restrictions    = require('./restrictions'),
    selections      = require('./selections'),
    routing         = require('./routing'),
    salesLines      = require('./salesLines'),
    saveSelections  = require('./saveSelections'),
    siteBanding     = require('./siteBanding'),
    staffLogin      = require('./staffLogin'),
    staffSearch      = require('./staffSearch'),
    stages          = require('./stages'),
    testimonials    = require('./testimonials'),
    user            = require('./user'),

    appReducer,
    rootReducer;

appReducer = combineReducers({
    app: app,
    bankDetails: bankDetails,
    booking: booking,
    centreFinder: centreFinder,
    discounts: discounts,
    discountCodes: discountCodes,
    faqs: faqs,
    lessons: lessons,
    liveChat: liveChat,
    memberships: memberships,
    payments: payments,
    personalDetails: personalDetails,
    restrictions: restrictions,
    selections: selections,
    routing: routing,
    salesLines: salesLines,
    saveSelections: saveSelections,
    siteBanding,
    staffLogin: staffLogin,
    staffSearch: staffSearch,
    stages: stages,
    testimonials: testimonials,
    user: user
});

rootReducer = function (state, action) {

    switch (action.type) {

        case actionTypes.APP.RESET_ALL:
            state = undefined;
            break;
    }

    return appReducer(state, action);
};

module.exports = rootReducer;
