var actionTypes     = require('./actionTypes.js'),

    config          = require('../config'),
    httpFetch       = require('../utils/httpFetch'),
    UserModel       = require('../models/user'),

    exports;

module.exports = exports = {

    changeField: function changeField(userId, field, value) {
        return function(dispatch) {
            UserModel.validateBankDetailField(field, value)
                .then(function(result) {
                    dispatch(exports.fieldChanged(userId, field, result));
                });
        };
    },

    fieldChanged: function fieldChanged(userId, field, data) {
        return {
            type: actionTypes.BANK_DETAILS.CHANGE_FIELD,
            userId: userId,
            field: field,
            data: data
        };
    },

    formsValidated: function formsValidated(valid, errors) {
        return {
            type: actionTypes.BANK_DETAILS.FORMS_VALIDATED,
            valid: valid,
            validationErrors: errors
        };
    },

    setDetails: function setDetails(details) {
        return {
            type: actionTypes.BANK_DETAILS.SET_DETAILS,
            details: details
        };
    },

    setPaymentUrl: function setPaymentUrl(paymentUrl) {
        return {
            type: actionTypes.BANK_DETAILS.SET_PAYMENT_URL,
            paymentUrl: paymentUrl
        };
    },

    setStartDate: function setStartDate(value) {
        return {
            type: actionTypes.BANK_DETAILS.SET_START_DATE,
            value: value
        };
    },

    validateForms: function validateForms(callback, error) {
        return function (dispatch, getState) {
            var state = getState(),
                // bankDetails = getState().bankDetails,
                users = state.selections.users.objects,
                url = config.services.gbg.urls.validateBank,
                promises = [],
                i;

                const {
                    remoteValidation: {
                        bank = false,
                    } = {}
                } = config.disabledFeatures || {};

                if (state.selections.bankDetailsAllValid && bank){
                    dispatch(exports.formsValidated(true, []));
                    callback();
                } if (state.selections.bankDetailsAllValid) {

                dispatch(exports.validating(true));

                for (i = 0; i < users.length; i++) {

                    if(state.selections.duration === 1 || (state.selections.duration === 12 && Object.keys(users[i].extras).length)) {

                        if ((!users[i].lead && users[i].useLeadBankDetails)) {
                            continue;
                        }

                        if(users[i].type.toLowerCase() !== 'freeprofile' || (users[i].type.toLowerCase() === 'freeprofile' && Object.keys(users[i].extras).length)) {
                            promises.push(httpFetch.fetch(url, {
                                method: 'GET',
                                params: {
                                    sort_code: users[i].bankDetails.fields.sortCode.value,  // eslint-disable-line camelcase
                                    account_number: users[i].bankDetails.fields.accountNumber.value // eslint-disable-line camelcase
                                },
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                }
                            }));
                        }
                    }
                }

                Promise.all(promises)
                    .then(function(responses) {
                        var validationErrors = [],
                            valid = true,
                            response,
                            i;

                        for (i = 0; i < responses.length; i++) {
                            response = responses[i];

                            if (response.validationCodes && response.validationCodes.item && response.validationCodes.item.length) {
                                validationErrors[i] = response.validationCodes.item;

                            } else {
                                validationErrors[i] = null;
                            }

                            if (!response.valid) {
                                valid = false;
                            }
                        }
                        dispatch(exports.formsValidated(valid, validationErrors));
                        dispatch(exports.validating(false));

                        if (response.error) {
                            error && error();
                        }
                        else if (response.valid) {
                            callback && callback();
                        }
                        else {
                            error && error();
                        }
                    })
                    .catch(function() {
                        dispatch(exports.validating(false));
                        error && error();
                    });
            }
        };
    },

    validating: function validating(status) {
        return {
            type: actionTypes.BANK_DETAILS.VALIDATING,
            validating: status
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.BANK_DETAILS.WIPEOUT
        };
    }
};
