var React   = require('react'),
    connect = require('react-redux').connect,
    actions         = require('../../actions'),

    ErrorPage404;

ErrorPage404 = React.createClass({
    _goHome: function (e) {
        e.preventDefault();
        this.props.dispatch(actions.stages.goHome());
    },

    render: function() {
        return (
            <div className='page'>
                <div className='container'>
                    <h1>{ this.props.content.title }</h1>
                    <a key='gohome' href='/' onClick={ this._goHome } title={ this.props.content.goHome }>{ this.props.content.goHome }</a>
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.errors.error404
    };
}

module.exports = connect(mapStateToProps)(ErrorPage404);
