var React           = require('react'),
    connect         = require('react-redux').connect,

    config          = require('../../config'),

    actions         = require('../../actions'),

    Button          = require('../common/button'),
    Input           = require('../common/forms/input'),

    LoginForm;

LoginForm = React.createClass({

    getInitialState: function getInitialState() {
        return {
            email: '',
            password: '',
            card: '',
            error: null,
            attemptsRemaining: 10
        };
    },

    _onChangeInput: function _onChangeInput(field, value) {
        var state = Object.assign({}, this.state);

        state[field] = value;

        this.setState(state);
    },

    _onLoginSubmit: function _onLoginSubmit(e) {
        e.preventDefault();

        if(!this.state.email && !this.state.password) {
            this.setState({
                error: 'invalid',
                field: 'both'
            });
        } else if(this.state.email && !this.state.password) {
            this.setState({
                error: 'invalid',
                field: 'password'
            });

        } else if(!this.state.email && this.state.password) {
            this.setState({
                error: 'invalid',
                field: 'email'
            });

        } else if(this.state.email && this.state.password) {
            var isGuardian = this.props.user && this.props.user.guardian ? true : false;

            //if using the login form on the About Page (doesn't actually log the user in)
            if (this.props.uid !== null && this.props.uid !== undefined) {
                this.props.dispatch(actions.user.checkProfile({
                    uid: this.props.uid,
                    email: this.state.email,
                    password: this.state.password,
                    card: this.state.card,
                    onError: this._onCheckError,
                    onSuccess: this._onSuccess,
                    isGuardian: isGuardian
                }));

            //using the login in for Junior Only members (does log user in)
            } else {
                this.props.dispatch(actions.user.login({
                    email: this.state.email,
                    password: this.state.password,
                    card: this.state.card
                },
                this._onSuccessLogin,
                this._onLoginError));
            }
        }
    },

    _onCheckError: function _onCheckError(e) {
        if (e && e.success === false) {
            if(e.reason === 'MultipleMatch') {
                this.setState({ error: 'MultipleMatch' });
            } else if(e.reason === 'AlreadyLoggedIn') {
                this.setState({ error: 'AlreadyLoggedIn' });
            } else if(e.ForcedPasswordReset && e.ForcedPasswordReset) {
                this.setState({
                    error: 'resetPassword',
                    resetURL: 'https:' + config.services.userForm.urls.setPassword + e.ResetToken
                });

                window.open(
                    this.state.resetURL,
                    '_blank'
                );

            } else {
                if(e.LoginAttempts) {
                    this.setState({
                        error: 'loginAttempts',
                        attemptsRemaining: (3 - e.LoginAttempts)
                    });
                } else {
                    this.setState({
                        error: 401
                    });
                }
            }
        } else if (e && e.status) {
            this.setState({ error: e.status });
        }
    },

    _onLoginError: function _onLoginError(e) {
        if(e.message && e.message === 'MultipleMatch') {
            this.setState({ error: 'MultipleMatch' });
        } else if(e.ForcedPasswordReset && e.ForcedPasswordReset) {
            this.setState({
                error: 'resetPassword',
                resetURL: e.resetURL
            });

        } else if (e.message && e.message) {
            if(e.attemptsRemaining) {
                this.setState({
                    error: 'loginAttempts',
                    attemptsRemaining: e.attemptsRemaining
                });
            } else {
                this.setState({
                    error: 401
                });
            }
        } else {
            this.setState({ error: e.status });
        }
    },

    _onSuccess: function _onSuccess(data) {
        this.props.dispatch(actions.selections.userFetched(data.user));
        this.setState({error: null});

        this.props.callback && this.props.callback();
    },


    _onSuccessLogin: function _onSuccessLogin(data) {
        this.props.dispatch(actions.selections.userFetched(data));
        this.setState({error: null});

        this.props.callback && this.props.callback();
    },

    _onUseLoggedInUser: function _onUseLoggedInUser(e) {
        e.preventDefault();
        var self = this;

        this.props.dispatch(actions.user.fetchProfile(true, function (profile) {
            self.props.dispatch(actions.app.profileLoaded());
            self._onSuccessLogin(profile);
        }));
    },

    render: function() {
        var content = this.props.content,
            formContent = this.props.formContent,
            isMultipleMatch = false,
            errorTitle,
            errorMsg,
            error,
            emailName,
            passwordName,
            cardName,
            loginForm;

        if ('invalid' === this.state.error) {
            errorTitle = 'Missing details!';
            errorMsg = formContent.missingDetails[this.state.field];

        } else if ('juniorError' === this.state.error) {
            errorTitle = 'Junior user login';
            errorMsg = formContent.errors.errorJE;

        } else if ('loginAttempts' === this.state.error) {
            errorTitle = 'Incorrect login details!';

            if(this.state.attemptsRemaining >= 2) {
                errorMsg = formContent.attempts.multipleRemaining.replace('{attempts}', this.state.attemptsRemaining);
            } else if(this.state.attemptsRemaining === 1) {
                errorMsg = formContent.attempts.oneRemaining;
            } else if(this.state.attemptsRemaining <= 0) {
                errorMsg = formContent.attempts.noneRemaining;
            }

        }  else if ('resetPassword' === this.state.error) {
            errorTitle = 'Weak password';
            errorMsg = formContent.errors.errorPR.replace('{link}', this.state.resetURL);

        } else if (401 === this.state.error) {
            errorTitle = 'Incorrect login details!';
            errorMsg = formContent.errors.error401;

        } else if ('MultipleMatch' === this.state.error) {
            errorTitle = 'Multiple matches found';
            errorMsg = formContent.errors.errorMM;
            isMultipleMatch = true;

        } else if ('PolicyNotSigned' === this.state.error) {
            errorTitle = 'Privacy Policy has not been signed';
            errorMsg = formContent.errors.errorPP.replace('{link}', config.services.app.urls.profile);

        } else if ('RequiresParentalConsent' === this.state.error) {
            errorTitle = 'Requires parental consent!';
            errorMsg = formContent.errors.errorJuniorPP.replace('{link}', config.services.app.urls.profile);

        } else if ('AlreadyLoggedIn' === this.state.error) {
            errorTitle = 'User already logged in';
            errorMsg = formContent.errors.errorALI;

        } else if (500 === this.state.error) {
            errorTitle = '';
            errorMsg = formContent.errors.error500;

        }

        if (errorMsg) {
            error = <span className='form__error'><strong>{ errorTitle }</strong><p dangerouslySetInnerHTML={ {__html: errorMsg } }></p></span>;
        }

        if(this.props.uid >= 0) {
            emailName = 'login_email_' + this.props.uid;
            passwordName = 'login_password_' + this.props.uid;
            cardName = 'login_card_' + this.props.uid;
        } else {
            emailName = 'login_email';
            passwordName = 'login_password';
            cardName = 'login_card';
        }

        if(this.props.isJuniorPanel && this.props.loggedInUser.loggedIn) {
            loginForm = (<div>
                    { error }
                    <Button className='button' onClick={ this._onUseLoggedInUser }>{ content.useLoggedInUser }</Button>
                    { this.props.extraButtons }
                </div>);
        } else {
            loginForm = (<div>
                    <Input
                        input={ {
                            className: 'login__input',
                            onChange: this._onChangeInput.bind(null, 'email'),
                            placeholder: this.props.userName ? content.fields.email.placeholder.replace('{firstname}', this.props.userName) : (content.fields.email.placeholder || ''),
                            autoFocus: false
                        } }
                        label={ {
                            className: this.props.showLabels ? '' : 'a11y',
                            text: content.fields.email.label
                        } }
                        autocompleteOff={ true }
                        disableHints={ true }
                        name={ emailName }
                        type='email'
                        valid={ true }
                        value={ this.state.email }
                    />

                    <Input
                        input={ {
                            className: 'login__input',
                            onChange: this._onChangeInput.bind(null, 'password'),
                            placeholder: content.fields.password.placeholder
                        } }
                        label={ {
                            className: this.props.showLabels ? '' : 'a11y',
                            text: content.fields.password.label
                        } }
                        autocompleteOff={ true }
                        disableHints={ true }
                        name={ passwordName }
                        type='password'
                        valid={ true }
                        value={ this.state.password }
                    />

                    <Input
                        input={ {
                            className: 'login__input',
                            onChange: this._onChangeInput.bind(null, 'card'),
                            placeholder: content.fields.cardId.placeholder
                        } }
                        label={ {
                            className: this.props.showLabels ? '' : 'a11y',
                            text: content.fields.cardId.label
                        } }
                        disableHints={ true }
                        name={ cardName }
                        type='password'
                        valid={ true }
                        value={ this.state.card }
                        isHidden={ !isMultipleMatch }
                    />
                    <div className="personal-details__login-forgot-password">
                        <a href={ config.services.userForm.urls.forgotPassword } className='login-form__forgot' target='_blank'>Forgot Password?</a>
                    </div>

                    { error }

                    <Button className='button' onClick={ this._onLoginSubmit }>{ content.buttonFetch }</Button>
                    { this.props.extraButtons }
                </div>);
        }

        return (<div className='login-form'>
            <form className='personal-details__login-form'>
                { loginForm }
            </form>
        </div>);
    }
});

LoginForm.propTypes = {
    callback: React.PropTypes.func,
    content: React.PropTypes.object,
    extraButtons: React.PropTypes.array,
    showLabels: React.PropTypes.bool,
    uid: React.PropTypes.number,
    user: React.PropTypes.object
};

function mapStateToProps(state) {
    return {
        formContent: state.app.content.loginForm,
    };
}

module.exports = connect(mapStateToProps)(LoginForm);
