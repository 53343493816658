var React       = require('react'),
    connect     = require('react-redux').connect,

    actions     = require('../../actions'),

    Modal       = require('../common/modal'),

    SuccessModal;

SuccessModal = React.createClass({
    componentDidMount: function componentDidMount() {
        var self = this;
        setTimeout( function () {
            self.props.dispatch(actions.app.hideModal(self.props.id));
        }, 5000);
    },

    render: function render() {
        return <Modal id={ this.props.id } className='modal--success'>
            <p>{ this.props.options.text }</p>
        </Modal>;
    }
});

SuccessModal.propTypes = {
    options: React.PropTypes.object
};

module.exports = connect()(SuccessModal);
