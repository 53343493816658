var React               = require('react'),
    actions         = require('../../../actions'),
    Input               = require('../../common/forms/input'),
    // Button              = require('../../common/button'),
    viewUtils           = require('../../../utils/viewUtils'),
    scrollToComponent   = require('react-scroll-to-component'),
    LevelBreakdown;

LevelBreakdown = React.createClass({

    getInitialState() {
        return {
            expanded: {},
            levels: this.props.user.levels
        };
    },

    componentDidMount() {
        var breakdown = document.getElementById('levelBreakdown'),
            highlighted = document.getElementById('highlighted-input'),
            isGeneric = this.props.isGeneric,
            levels =  this.getLevels();

        if(highlighted) {
            breakdown = highlighted;
        }

        if(levels !== 'static' && isGeneric) {
            if (!levels.validAgeForGenericLesson && levels.levels.length === 0) {
                this.props.dispatch(actions.lessons.setAgeError());
            }
        }

        scrollToComponent(breakdown, {
            align: 'top',
            offset: -150
        });
    },

    handleMoreInfoClick: function handleMoreInfoClick(key, e) {
        if (e) {
            e.preventDefault();
        }

        var elt = document.querySelector('.level-breakdown__description--' + key),
            expanded = Object.assign({}, this.state.expanded);

        if (this.state.expanded[key]) {
            delete expanded[key];

            this.setState({ expanded: expanded });
            elt && viewUtils.furl(elt);

        } else{
            expanded[key] = true;
            this.setState({ expanded: expanded });
            elt && viewUtils.furl(elt);
        }
    },


    getLevels: function() {
        var user = this.props.user,
            isGeneric = this.props.isGeneric,
            staffLogin = this.props.staffLogin,
            recommendedLevel = user.recommendedLevel,
            levels =  user.levels,
            levelBlacklist = ['preschool:all', '(7+):7', '(11+):11'],
            validAge,
            validAgeForGenericLesson = true,
            nextType,
            text,
            type,
            selectedLessonType = this.props.lessons.typesList.find(x => x.id === parseInt(this.props.lessons.typeId));

        levels = levels.filter(function(obj) {
            // lesson_access: 0 = Lessons Online Only, 1 = Transfer Only, 2 = Lessons Online and Transfer
            // filter_visibility 0=centre and home, 1=centre only
            if (obj.lesson_access === 1 || (obj.filter_visibility === 1 && !staffLogin)) {
                return false;
            }

            // the blacklist filter applies to swimming only (hard coded, not the best practice but they wanted a quick patch)
            if (selectedLessonType && selectedLessonType.name.toLowerCase() === 'swimming') {
                for(var y = 0; y < levelBlacklist.length; y++) {
                    text = levelBlacklist[y].split(':')[0];
                    type = levelBlacklist[y].split(':')[1];
                    validAge = false;

                    //check if theres an age limit
                    if(levelBlacklist[y].indexOf(':all') === -1) {
                        nextType = null;
                        //does the next blacklist item also have an age
                        if(levelBlacklist[y+1]) {
                            nextType = levelBlacklist[y+1].split(':')[1];
                        }

                        //if the users age is greater than the blacklisted item age and
                        // less then the next blacklisted age (if it exists), allow the blacklisted item to show
                        if(user.info.age >= parseInt(type) && (nextType ? user.info.age < parseInt(nextType) : true)) {
                            validAge = true;
                        }
                    }

                    //check if blacklisted level is the recommended level
                    var isRecommended = false;
                    if(recommendedLevel && obj.mrm_stage_id === recommendedLevel) {
                        isRecommended = true;
                    }

                    //if the age is not valid
                    //and the level title contains a blacklisted item
                    //and is not the recommended level
                    //don't include it
                    if(obj.title.toLowerCase().replace(/\s/g, '').indexOf(text) !== -1 && !validAge && !isRecommended) {
                        return false;
                    }
                }
            }

            if (isGeneric) {
                validAgeForGenericLesson = false;
                return ((!obj.max_age || obj.max_age >= user.info.ageInMonths) && (!obj.min_age || obj.min_age <= user.info.ageInMonths))
            }

            return true;
        });

        return {
            validAgeForGenericLesson: validAgeForGenericLesson,
            levels: levels
        };
    },

    render: function() {
        var content = this.props.content,
            user = this.props.user,
            chosenLevel = user.chosenLevel,
            recommendedLevel = user.recommendedLevel,
            levels = user.levels,
            loggedInUser = this.props.loggedInUser,
            showSlider = levels.length > 4 ? true : false,
            recommendedBefore,
            recommendedAfter,
            slideIndex = 0,
            levelView,
            firstName,
            nosessionsLink,
            enquiryLink = this.props.enquiryLink;

        let selectedLessonType = this.props.lessons.centreTypesList.filter(lessonType => {
            return lessonType.id === this.props.lessons.typeId * 1;
        });

        // test if we have custom enquiry link and fall back to hard coded if not exists
        enquiryLink = selectedLessonType.length === 1 ? selectedLessonType[0].url_unsure : enquiryLink;
        nosessionsLink = selectedLessonType.length === 1 ? selectedLessonType[0].url_nosessions : nosessionsLink;

        if(!user.info) {
            return null;
        }

        if(loggedInUser.loggedIn && user.loggedInUser) {
            firstName = loggedInUser.user.info.firstName;
        } else {
            firstName = user.info.firstName.value;
        }

        if(levels !== 'static') {
            var choicesArray = [],
                recommendedFound = false,
                levelInputs = [];

            //exclude blacklisted levels
            levels = this.getLevels().levels;

            if(recommendedLevel && recommendedLevel !== 'test') {
                var newLevels = levels.slice();

                for(var r = 0; r < newLevels.length; r++) {
                    if(newLevels[r].mrm_stage_id === recommendedLevel) {
                        recommendedFound = true;
                        recommendedBefore = r - 1 >= 0 ? r - 1 : r;
                        recommendedAfter = r + 1 >= 0 ? r + 1 : r;
                        break;
                    }
                }

                if(recommendedFound) {
                    levels = newLevels.splice(recommendedBefore, recommendedAfter - (recommendedBefore - 1));

                    //get new index of recommended for slider
                    for(var x = 0; x < levels.length; x++) {
                        if(levels[x].mrm_stage_id === recommendedLevel) {
                            slideIndex = x === 0 ? 0 : x - 1;
                            break;
                        }
                    }
                }
            }

            for(var l = 0; l < levels.length; l++) {
                var inputContent = null;

                //hide description on mobile by default
                // if ((['bp-m', '"bp-m"', 'bp-l', '"bp-l"', 'bp-xl', '"bp-xl"', 'bp-xxl', '"bp-xxl"'].indexOf(this.props.breakpoint) !== -1)) {
                //     inputContent = (<p className='level-breakdown__description-text level-breakdown__description-text--desktop' dangerouslySetInnerHTML={ {__html: levels[l].description } }></p>);
                // } else {
                //     inputContent = (<div>
                //         <div className={'level-breakdown__description level-breakdown__description--' + l}>
                //             <div className='inner'>
                //                 <p className='level-breakdown__description-text' dangerouslySetInnerHTML={ {__html: levels[l].description } }></p>
                //             </div>
                //         </div>
                //         <Button className='level-breakdown__description-button' onClick={ this.handleMoreInfoClick.bind(null, l) }>{ (this.state.expanded[l] ? content.buttonHide : content.buttonView) }</Button>
                //     </div>);
                // }

                inputContent = (<p className='level-breakdown__description-text level-breakdown__description-text--desktop' dangerouslySetInnerHTML={ {__html: levels[l].description } }></p>);
                choicesArray.push({
                    value: levels[l].mrm_stage_id,
                    text: levels[l].title,
                    content: inputContent,
                });
            }

            showSlider = choicesArray.length > 4 ? true : false;

            levelInputs = (<li>
                    <Input className='level-breakdown__level'
                        input={ {
                            onChange: this.props.onSelectLevel,
                            className:  'forms__input--radio'
                        } }
                        options={ choicesArray }
                        name={'radio_levels'}
                        radioLabelClassName={ 'personal-details__radio-label' }
                        highlighted={ recommendedLevel }
                        slider={ showSlider }
                        slideIndex={ slideIndex }
                        type='radio'
                        valid={ true }
                        value={ chosenLevel }
                    />
                </li>);

            levelView = (<div className='module module--no-padding'>
                    <div className='level-breakdown__levels-list'>

                        <h4 className='level-breakdown__levels-title' dangerouslySetInnerHTML={ {__html: content.listTitle.replace('[name]', firstName) } }></h4>

                        <ul className='personal-details__options-list'>
                            { levelInputs }
                        </ul>


                        {levels.length === 0 && nosessionsLink ? (
                        <div className='personal-details__options-link'>
                            <a className='personal-details__link'  href={ nosessionsLink } target='_blank'>{ this.props.links.level }</a>
                        </div>
                        ):(
                        <div className='personal-details__options-link'>
                            <a className='personal-details__link'  href={ enquiryLink } target='_blank'>{ this.props.links.level }</a>
                        </div>
                        )}
                    </div>
                </div>);
        }

        return (<div>
            <div className='module-container' id="levelBreakdown">
                { levelView }
            </div>
        </div>);
    }
});

LevelBreakdown.propTypes = {
    content: React.PropTypes.object,
    user: React.PropTypes.object
};

module.exports = LevelBreakdown;
