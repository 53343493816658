const { textOverrides } = require('../utils/TextOverrides.js');

var debug = require( 'debug' )( 'actions-centreFinder' ),
    actionTypes = require( './actionTypes.js' ),
    appActions = require( '../actions/app.js' ),
    discountActions = require( '../actions/discounts.js' ),
    selectionsActions = require( '../actions/selections.js' ),
    httpFetch = require( '../utils/httpFetch' ),
    utils = require( '../utils' ),
    config = require( '../config' ),
    CentreModel = require( '../models/centre' ),

    exports;

module.exports = exports = {

    centreDataReceived: function centreDataReceived ( options, data ) {
        return {
            type: actionTypes.CENTRE_FINDER.CENTRE_DATA_RECEIVED,
            siteId: options.siteId,
            date: options.date,
            data: data,
            discount: options.discount
        };
    },

    centreSelected: function centreSelected ( centre, date, isStaff ) {
        return function ( dispatch, getState ) {
            const state = getState();
            const isLessons = !!state.lessons.typeId;

            dispatch( {
                type: actionTypes.CENTRE_FINDER.CENTRE_SELECTED,
                centre: centre,
                date: date,
                isStaff,
                isLessons
            } );
        };
    },

    clearFacilities: function clearFacilities () {
        return {
            type: actionTypes.CENTRE_FINDER.SET_FACILITIES,
            facilities: []
        };
    },

    collapseResult: function collapseResult () {
        return {
            type: actionTypes.CENTRE_FINDER.TOGGLE_EXPANDED_CENTRE,
            key: null
        };
    },

    expandResult: function expandResult ( key ) {
        return {
            type: actionTypes.CENTRE_FINDER.TOGGLE_EXPANDED_CENTRE,
            key: key
        };
    },

    findCentres: function findCentres ( options ) {
        var url = config.services.centreFinder.urls.find,
            payload = {
                per_page: config.services.centreFinder.fetchLimit, // eslint-disable-line camelcase
                visibility: 'join'
            };

        if ( options.lat !== null && options.lat !== undefined && options.lng !== null && options.lng !== undefined ) {
            payload.lat = options.lat;
            payload.lon = options.lng;
        }

        if ( options.page ) {
            payload.page = options.page;
        }

        // Instruct API to calculate the distance of this centre against this lat lng
        if ( options.distanceFromLat && options.distanceFromLon ) {
            payload.distanceFromLat = options.distanceFromLat;
            payload.distanceFromLon = options.distanceFromLon;
        }

        // Allows for "sticky centres" - always show these centres regardless
        // Allows for "pinning" centres
        if ( options.alwaysInclude ) {
            payload.alwaysInclude = options.alwaysInclude.join( ',' );
        }

        // ALlows for finding centres by facilities on offer
        if (options.facilityTerm) {
            // var key = 'facilityTerm';
            var key = 'filterable_facility';
            payload[key] = options.facilityTerm;
        }

        return function ( dispatch, getState ) {
            dispatch( appActions.showLoader( true ) );

            var state = getState();

            if ( state.centreFinder.centreFacilities.length ) {
                payload.facilities = state.centreFinder.centreFacilities;
            }

            if ( state.lessons && state.lessons.inView && state.lessons.typeId ) {
                payload.lesson = state.lessons.typeId;
            }

            return httpFetch.fetch( url, {
                method: 'GET',
                params: payload,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            } )
                .then( function ( response ) {
                    // If using a url it means we placed an offset request
                    if ( options.page > 1 ) {
                        dispatch( exports.moreResultsReceived( response ) );
                    } else {
                        dispatch( exports.resultsReceived( response ) );
                        dispatch( exports.setButtonText( state.app.content.stage1.centreGeoloc.buttonFoundCentre, 3 ) );
                    }

                    dispatch( appActions.showLoader( false ) );
                } )
                .catch( function ( e ) {
                    debug( 'error: ' + JSON.stringify( e ) );
                    dispatch( appActions.error( e ) );
                    dispatch( appActions.showLoader( false ) );
                } );
        };
    },

    geolocResultsReceived: function geolocResultsReceived ( geolocResults ) {
        return {
            type: actionTypes.CENTRE_FINDER.GEOLOC_RESULTS_RECEIVED,
            geolocResults: geolocResults
        };
    },

    moreResultsReceived: function moreResultsReceived ( response ) {
        return {
            type: actionTypes.CENTRE_FINDER.MORE_RESULTS_RECEIVED,
            response: response
        };
    },

    resetGeolocResults: function resetGeolocResults () {
        return {
            type: actionTypes.CENTRE_FINDER.RESET_GEOLOC_RESULTS
        };
    },

    resetSelection: function resetSelection () {
        return {
            type: actionTypes.CENTRE_FINDER.CENTRE_SELECTED,
            centre: null
        };
    },

    resultsReceived: function resultsReceived ( response ) {
        return {
            type: actionTypes.CENTRE_FINDER.RESULTS_RECEIVED,
            response: response
        };
    },

    selectCentre: function selectCentre ( siteId, options ) {
        var self = this;

        return function ( dispatch, getState ) {
            var state = getState(),
                isStaff = !!state.staffLogin.staffId;

            dispatch( appActions.showLoaderCentreFinder( true ) );

            options = options || {};

            var state = getState(),
                isStaff = !!state.staffLogin.staffId,
                cachedSite = state.centreFinder.centresData[ siteId ],
                date = options.date || ( state.selections.startDate && utils.getShortDate( state.selections.startDate ) ) || 'now',
                discount = options.discount || state.discounts.discount,
                deeplinkAddons = state.selections.deeplinkAddons,
                preselectedSubs = state.selections.preselectedSubs,
                preselectedSubsByGroups = state.selections.preselectedSubsByGroups,
                facilitiesArray = ( options && options.facilities ) || state.centreFinder.centreFacilities || [],
                quite = options.quite || false,
                params = {
                    with: 'subscriptions,ethnicity'
                },
                now = Date.now(),
                existingData,
                modalContent,
                removeDiscountOnError = options.removeDiscountOnError || false;

            function corporateDiscountIsAvailable () {
                var corporateDiscount = state.selections.users.objects.find( user => user.availableSubscriptions.find( sub => sub.discountDesc === 'Corporate' ) );
                return !!corporateDiscount;
            }

            // if (discount) {
            //     if (discount.type === 'corporate') {
            //         modalContent = state.app.content.modals.corpDiscounts;
            //     } else {
            //         modalContent = state.app.content.modals.discounts;
            //     }
            // }

            if ( cachedSite && cachedSite[ date ] ) {
                existingData = cachedSite[ date ];

                if ( discount && existingData.withDiscount[ discount.id ] && ( existingData.withDiscount[ discount.id ].ts + config.services.centreFinder.resultsCacheTime * 1000 ) >= now ) {

                    dispatch( appActions.showLoaderCentreFinder( false ) );
                    dispatch( appActions.showLoader( false ) );

                    dispatch( exports.centreSelected( existingData.withDiscount[ discount.id ].details, date, isStaff ) );

                    if ( corporateDiscountIsAvailable() ) {
                        dispatch( discountActions.discountSelected( discount ) );
                        // !quite && dispatch(appActions.showModal('success', { text: modalContent.success }));
                    } else {
                        // TODO confirm what is this? Is there any popup?
                        // !quite && dispatch(appActions.showModal('noDiscount', { text: modalContent.noDiscounts }));
                    }

                } else if ( !discount && existingData.withoutDiscount && ( existingData.withoutDiscount.ts + config.services.centreFinder.resultsCacheTime * 1000 ) >= now ) {
                    dispatch( appActions.showLoaderCentreFinder( false ) );
                    dispatch( appActions.showLoader( false ) );

                    return dispatch( exports.centreSelected( existingData.withoutDiscount.details, date, isStaff ) );
                }
            }

            if ( 'now' !== date ) {
                params.startDate = date;
            }

            if ( discount && discount.type === 'corporate' ) {
                params.corporateContactId = discount.id;
            } else if ( discount && discount.type === 'code' ) {
                params.promotionCode = discount.id;
            }

            if ( self.getParameterByName( 'gladstoneApi' ) === 'true' ) {
                params.gladstoneApi = true;
            }

            let promise = null;
            let siteData = null;
            let debugCentreDataKey = 'site-data-' + siteId;

            if ( localStorage[ debugCentreDataKey ] ) {
                siteData = JSON.parse( localStorage[ debugCentreDataKey ] );
            }

            if ( siteData ) {
                promise = Promise.resolve( siteData );
            }
            else {
                promise = httpFetch.fetch( config.services.centreFinder.urls.getCentre.replace( '{siteId}', siteId ), {
                    method: 'GET',
                    params: params,
                    headers: {
                        'Accept': 'application/json'
                    }
                } );
            }

            promise.then(function (response) {
                // init overrides helper
                textOverrides
                    .setOverrides((response || {}).overrides);
                dispatch(exports.setTextOverrides((response || {}).overrides));

                let preselectedSubsIds = options.subIds || deeplinkAddons || preselectedSubs || preselectedSubsByGroups;
                let normalizationOptions = {
                    subIds: options.subIds || deeplinkAddons,
                    tagsWhiteList: state.app.tagsWhiteList,
                    tagsBlackList: state.app.tagsBlackList
                };
                let normalizedCentreData = CentreModel.normalizeCentreData( response, normalizationOptions );

                if ( typeof preselectedSubsIds === 'string' ) {
                    let firstSubId = preselectedSubsIds.split( ',' ).pop();
                    let firstPreselectedSub = normalizedCentreData.subscriptions[ firstSubId ];

                    if (firstPreselectedSub) {
                        dispatch(selectionsActions.changeDuration(firstPreselectedSub.duration, firstPreselectedSub.durationType));
                    }
                }

                if ( facilitiesArray ) {
                    var lowercaseHighlightFacilities = normalizedCentreData.info.highlighted_facilities.map( function ( value ) {
                        return value.toLowerCase();
                    } ),
                        filteredFacilities = [];

                    for ( var i = 0; i < facilitiesArray.length; i++ ) {
                        if ( lowercaseHighlightFacilities.indexOf( facilitiesArray[ i ].toLowerCase() ) !== -1 ) {
                            filteredFacilities.push( facilitiesArray[ i ].toLowerCase() );
                        }
                    }

                    dispatch( exports.setFacilities( facilitiesArray ) );
                    dispatch( exports.setFilteredFacilities( filteredFacilities ) );
                }

                if ( !( facilitiesArray.length && !filteredFacilities.length ) ) {
                    dispatch( exports.centreDataReceived( { siteId: siteId, date: date, discount: discount && discount.id }, normalizedCentreData ) );
                    dispatch( exports.centreSelected( normalizedCentreData, date, isStaff ) );

                    if ( discount ) {
                        if ( discount.type === 'corporate' && ( 'now' === date || null === date ) ) {
                            if ( corporateDiscountIsAvailable() ) {
                                dispatch( discountActions.discountSelected( discount ) );
                                !quite && dispatch( appActions.showModal( 'success', { text: modalContent.success } ) );
                            } else {
                                !quite && dispatch( appActions.showModal( 'noDiscount', { text: modalContent.noDiscounts } ) );
                            }
                        }
                    }
                }

                dispatch( appActions.showLoaderCentreFinder( false ) );
                dispatch( appActions.showLoader( false ) );
            } )
                .catch( function ( e ) {
                    dispatch( appActions.showLoaderCentreFinder( false ) );
                    dispatch( appActions.showLoader( false ) );

                    if ( removeDiscountOnError ) {
                        dispatch( discountActions.removeDiscount() );
                    }

                    if ( discount && discount.type === 'code' && state.selections.users.objects.length > 0 ) {
                        dispatch( selectionsActions.setUserPromoCode( {
                            userId: 0,
                            isValid: false
                        } ) );
                    }

                    console.log( e );

                    return e;
                } );

            return promise;
        };
    },

    getParameterByName: function ( name, url ) {
        if ( !url ) {
            url = window.location.href;
        }

        name = name.replace( /[\[\]]/g, '\\$&' );
        var regex = new RegExp( '[?&]' + name + '(=([^&#]*)|&|#|$)' ),
            results = regex.exec( url );

        if ( !results ) {
            return null;
        }

        if ( !results[ 2 ] ) {
            return '';
        }

        return decodeURIComponent( results[ 2 ].replace( /\+/g, ' ' ) );
    },


    setButtonText: function setButtonText ( buttonText, geoStep ) {
        return {
            type: actionTypes.CENTRE_FINDER.SET_BUTTON_TEXT,
            buttonText: buttonText,
            geoStep: geoStep
        };
    },

    setFacilities: function setFacilities ( facilities ) {
        return {
            type: actionTypes.CENTRE_FINDER.SET_FACILITIES,
            facilities: facilities
        };
    },

    setFilteredFacilities: function setFilteredFacilities ( facilities ) {
        return {
            type: actionTypes.CENTRE_FINDER.SET_FILTERED_FACILITIES,
            facilities: facilities
        };
    },

    setTextOverrides: function setTextOverrides(textOverrides) {
        return {
            type: actionTypes.CENTRE_FINDER.SET_TEXT_OVERRIDES,
            textOverrides
        };
    },

    setLocation: function setLocation ( lat, lng, formattedAddress ) {
        return {
            type: actionTypes.CENTRE_FINDER.SET_LOCATION,
            lat: lat,
            lng: lng,
            formattedAddress: formattedAddress
        };
    },

    wipeout: function wipeout () {
        return {
            type: actionTypes.CENTRE_FINDER.WIPEOUT
        };
    },

    /**
     * Sets facility search term list for facility lookup autocomplete
     * @param {array} facilities
     * @returns
     */
    setFacilityFilterSearchTerm: name => ( {
        type: actionTypes.CENTRE_FINDER.SET_FACILITY_FILTER_SEARCH_TERM,
        name
    } ),

    /**
     * Clears any set search term that's used to filter centres by facility
     * @param {string} name
     * @returns
     */
    clearFacilityFilterSearchTerm: () => ( {
        type: actionTypes.CENTRE_FINDER.CLEAR_FACILITY_FILTER_SEARCH_TERM
    } ),

    /**
     * Sets facility search term list for facility lookup autocomplete
     * @param {array} facilities
     * @returns
     */
    setFacilityTermList: facilities => ( {
        type: actionTypes.CENTRE_FINDER.SET_FACILITY_FILTER_TERM_LIST,
        facilities
    } ),

    /**
     * Sets available facility search terms for use in lookup autocomplete
     * @returns Function
     */
    fetchAvailableFacilities: function fetchAvailableFacilities () {
        return dispatch => fetch( config.services.centreFinder.urls.getAllFacilities )
            .then( response => response.json() )
            .then( exports.setFacilityTermList )
            .then( dispatch );
    },
};
