var actionTypes = require("../actions/actionTypes.js");

module.exports = function(state, action) {
    var steps = [
            {
                navBar: true,
                path: "/memberships"
            },
            {
                navBar: true,
                path: "/add-ons"
            },
            {
                navBar: true,
                path: "/about-you"
            },
            {
                navBar: true,
                path: "/payment"
            },
            {
                path: "/direct-debit"
            },
            {
                navBar: true,
                path: "/lessons"
            },
            {
                navBar: true,
                path: "/set-level"
            },
            {
                navBar: true,
                path: "/select-lesson"
            },
            {
                navBar: true,
                path: "/in-centre-payment"
            }
        ],
        flows = {
            newCustomer: {
                newMembership: [0, 1, 2, 3, 4]
            },
            existingCustomer: {
                newMembership: [0, 2, 1, 3, 4],
                renewMembership: [1, 2, 3, 4],
                buyAddons: [1, 2, 3, 4]
            },
            lessonsCustomer: {
                newMembership: [5, 6, 7, 1, 2, 3, 4]
            }
        },
        defaultFlow = flows.newCustomer.newMembership,
        defaultAllowedPaths = [],
        allowedPaths,
        newState,
        i;

    for (i = 0; i < defaultFlow.length; i++) {
        defaultAllowedPaths.push(steps[defaultFlow[i]].path);
    }

    state = state || {
        showStep: 0,
        currentStep: 0,
        availableFlows: flows,
        flow: defaultFlow,
        allowedPaths: defaultAllowedPaths,
        steps: steps,
        fetchedApplicableAddonIds: false,
        applicableAddonIds: []
    };

    switch (action.type) {
        case actionTypes.STAGES.SET_SHORT_MEMBERSHIPS_PATH:
            state.steps[0].path = "/short-memberships";
            return state;

        case actionTypes.STAGES.SET_FLOW:
            allowedPaths = [];

            for (i = 0; i < action.flow.length; i++) {
                allowedPaths.push(steps[action.flow[i]].path);
            }

            return Object.assign({}, state, {
                flow: action.flow,
                allowedPaths: allowedPaths
            });

        case actionTypes.STAGES.SET_STEP:
            return Object.assign({}, state, {
                currentStep: action.step
            });

        case actionTypes.STAGES.SHOW_STEP:
            if (state.steps[action.step]) {
                newState = {
                    showStep: action.step
                };

                if (state.currentStep === null) {
                    newState.currentStep = action.step;
                }

                return Object.assign({}, state, newState);
            } else {
                return state;
            }
            break;

        case actionTypes.STAGES.APPLICABLE_ADDON_SUBSCRIPTION_IDS:
            return {
                ...state,

                applicableAddonIds: action.ids,
                fetchedApplicableAddonIds: true // Status of "fetch"
            };
            break;

        case actionTypes.STAGES.UNMOUNT_APPLICABLE_ADDON_SUBSCRIPTION_IDS:
            return {
                ...state,
                fetchedApplicableAddonIds: false,
                applicableAddonIds: []
            };
            break;

        default:
            return state;
    }
};
