var React           = require('react'),
    connect         = require('react-redux').connect,

    UserForm        = require('./userForm'),
    Faqs            = require('../faqs'),
    Button          = require('../common/button'),
    SaveSelections  = require('../common/saveSelectionsButton'),
    FooterSecurity  = require('../common/footerSecurity'),

    SelectedLesson  = require('../lessons/selectedLesson'),
    LessonsTimeout  = require('../lessons/lessonsTimeout'),

    actions         = require('../../actions'),
    UserModel       = require('../../models/user'),
    config          = require('../../config'),

    history         = require('../../utils/history'),

    scrollToComponent = require('react-scroll-to-component'),

    Stage3Content;

const { goToConfirmPage } = require('../../utils/confirmPage');

const busy = {
    validateForm: false,
    next: false
};

Stage3Content = React.createClass({
    displayName: 'Stage3Content',
    makeLive: false,

    _logoutUser: function logoutUser(userId) {
        var self = this;

        this.props.dispatch(actions.user.logout()).then(function(){
            self.props.dispatch(actions.selections.cleanUser(userId));
            self._onUnfreezeUser(userId, null);
            self._onUseLoginForm(userId, true);
            self.props.dispatch(actions.selections.validating(false));
        });
    },

    getInitialState: function getInitialState() {
        return {
            isLive: false,
            onlyJunior: false,
            valid: false,
            validationErrors: [],
            busy
        };
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if (this.state.isLive && JSON.stringify(this.props.users) !== JSON.stringify(newProps.users)) {

            this._validateUsers(newProps.users.objects)
                .then(function (result) {
                    if (result.valid !== this.state.valid) {
                        this.setState({
                            validationErrors: result.validationErrors,
                            valid: result.valid
                        });
                    }
                }.bind(this));
        }
    },

    componentDidMount: function componentDidMount() {
        if (this.props.preselectedSubs) {
            const doesSubExistInCentreSubs = Object.keys(this.props.centre.subscriptions).includes(this.props.preselectedSubs);

            if (!doesSubExistInCentreSubs) {
                this.props.dispatch(actions.selections.preselectSubs(null));
                window.location.href = '/memberships';
            }
        }

        var isLessons = this.props.lessons && this.props.lessons.inView ? true : false;

        if (isLessons && !this.props.medicalConditions) {
            this.props.dispatch(actions.personalDetails.getMedicalConditionsList());
        }

        window.scrollTo(0,0);
    },

    createParentUser: function createParentUser() {
        //if freeprofile and junior exists
        if(UserModel.onlyFreeJuniorProfile(this.props.users)) {
            this.props.dispatch(actions.selections.convertToJuniorMember(this.props.users.objects[0]));
        }

        var users = Object.assign({}, this.props.users.count);
        users.freeprofile += 1;

        // Set new user totals.
        this.props.dispatch(actions.selections.setUsers(users, true));

        this.setState({onlyJunior: false});

        // after setState
        setTimeout(scrollToFillableSection);

        function scrollToFillableSection() {
            var personalDetailsBlock = document.querySelectorAll('.personal-details, .personal-details__user');
            if (!personalDetailsBlock.length) {
                return; // nothing found
            }
            var elLast = personalDetailsBlock[personalDetailsBlock.length - 1];
            scrollToComponent(elLast, {
                align: 'top',
                offset: -100
            });
        }
    },

    _onBlurInput: function _onBlurInput(uid, field, value) {
        var data = {
            info: {}
        };

        data.info[field] = value;

        this.props.dispatch(actions.selections.changeData(uid, data));
    },

    _onChangeInput: function _onChangeInput(uid, field, value) {
        var data = {
            info: {}
        };

        data.info[field] = value;

        this.props.dispatch(actions.selections.changeData(uid, data));
    },

    _onChangeCheckboxInput: function _onChangeCheckboxInput(uid, field, name, value) {
        var data = {
            info: {}
        };

        data.info[field] = data.info[field] || {};
        data.info[field][name] = value;

        this.props.dispatch(actions.selections.changeData(uid, data));
    },

    _onUnfreezeSection: function _onUnfreezeSection(uid, sectionId, e) {
        e.preventDefault();
        this.props.dispatch(actions.personalDetails.unfreeze(uid, sectionId));
    },

    _onUnfreezeUser: function _onUnfreezeUser(uid, e) {
        if (e) {
            e.preventDefault();
        }
        this.props.dispatch(actions.personalDetails.unfreezeUser(uid));
    },

    _onUseLoginForm: function _onUseLoginForm(uid, visible) {
        var t = this.props.useLogin[uid],
            user = this.props.users.objects[uid],
            useLoginForm;

        if (!user.isLoggedInUser && t === true) {
            useLoginForm = true;
            this.makeLive = true;
        } else {
            useLoginForm = false;
            this.makeLive = false;
        }

        if (visible !== useLoginForm) {
            this.props.dispatch(actions.personalDetails.toggleUserLogin(uid, visible));
        }
    },

    _onUseManualEntry: function _onUseManualEntry(uid, e) {
        e.preventDefault();
        this.props.dispatch(actions.personalDetails.useManualAddressEntry(uid));
    },

    _onUseLeadContact: function _onUseLeadContact(uid, e) {
        this.props.dispatch(actions.selections.useLeadContact(uid, e.target.checked));
    },

    _onUseLeadEmmergencyDetails: function _onUseLeadEmmergencyDetails(uid, e) {
        this.props.dispatch(actions.selections.useLeadEmmergencyDetails(uid, e.target.checked));
    },

    _setEmmergencyDetails: function _setEmmergencyDetails(uid, value) {
        this.props.dispatch(actions.selections.setEmmergencyDetails(uid, value));
    },

    _validateForm: function _validateForm(e) {
        e.preventDefault();

        if (busy.validateForm || this.state.busy.validateForm) {
            return;
        }

        busy.validateForm = true;
        this.setState({
            busy,
            onlyJunior: false
        });

        var users = this.props.users.objects;

        this.props.dispatch(actions.selections.validating(true));

        this._validateUsers(users)
            .then(function (result) {
                if (result.valid) {
                    this._next();
                } else {

                    this.setState({
                        makeLive: true,
                        validationErrors: result.validationErrors,
                        valid: false
                    });

                    for(var i = 0; i < users.length; i++) {
                        var user = users[i];

                        if(!user.valid) {
                            this.props.dispatch(actions.personalDetails.unfreezeUser(i));
                        }
                    }

                    if (result.validationErrors) {
                        window.requestAnimationFrame(function() {
                            var invalidFields = document.querySelectorAll('.input--invalid');
                            scrollToComponent(invalidFields[0], {
                                align: 'top',
                                offset: -100
                            });
                        });
                    }

                    this.props.dispatch(actions.selections.validating(false));
                }
            }.bind(this))
            .finally(_ => {
                setTimeout(_ => {
                    busy.validateForm = false;
                    this.setState({ busy });
                });
            });
    },

    _next: function _next() {
        if (busy.next || this.state.busy.next) {
            return;
        }

        busy.next = true;
        this.setState({ busy });

        // TODO: THIS BLOCK IS DUPLICATED IN THE SIDEBAR, UPDATE THERE IF YOU UPDATE HERE!
        var self = this,
            siteId = this.props.centre.info.site_id;

        this.props.dispatch(actions.app.showLoader(true));

        if (
            UserModel.onlyJuniorProfile(this.props.users, siteId) ||
            (UserModel.onlyFreeJuniorProfile(this.props.users) && !UserModel.withAnAdult(this.props.users))
        ) {
            stopBusy();
            this.setState({onlyJunior: true});

            this.props.dispatch(actions.app.showLoader(false));
            this.props.dispatch(actions.selections.validating(false));
        } else if((UserModel.onlyFreeProfile(this.props.users) || UserModel.isGuardianWithFreeJunior(this.props.users, siteId)) && !Object.keys(this.props.extras).length) {
            this.props.dispatch(actions.selections.checkUsers(function () {
                stopBusy(999); // I don't want to mess things below
                self.props.dispatch(actions.personalDetails.freezeAll());

                self.props.dispatch(actions.selections.postSelections(function () {
                    var reportPayload = {
                        members: this.props.users.objects && this.props.users.objects.sort((a, b) => b.info.age - a.info.age).map(function (u) {
                            return {
                                mrmId: u.info && u.info.mrmId && u.info.mrmId.value,
                                lesson_session_id: u.lesson && u.lesson.session && u.lesson.session.mrm_id,
                                subscriptions: (u.availableSubscriptions.length > 0 || Object.keys(u.extras).length) ? true : false
                            };
                        })
                    };

                    reportPayload.status = 'success';

                    this.props.dispatch(
                        actions.payments.report(
                            { payload: reportPayload },
                            goToConfirmPage
                        )
                    );

                    self.props.dispatch(actions.selections.validating(false));
                }.bind(self)));
            }, function () {
                self.props.dispatch(actions.selections.validating(false));
            })); // formatting in this section is horrible
        } else {
            this.props.dispatch(actions.selections.checkUsers(
                function () {
                    if (!self.props.staffId && !self.props.reportingTransactionId) {
                        self.props.dispatch(
                            actions.selections.reportingTransactionStart(
                                { payload: {
                                    users: self.props.users.objects,
                                    isLessons: self.props.lessons && self.props.lessons.inView
                                } },
                            )
                        );
                    }
                    stopBusy();
                    self.props.dispatch(actions.personalDetails.freezeAll());
                    self.props.dispatch(actions.stages.next());

                    self.props.dispatch(actions.selections.validating(false));
                },
                function () {
                    stopBusy();
                    self.props.dispatch(actions.selections.validating(false));
                })
            );
        }

        function stopBusy(delay) {
            setTimeout(_ => {
                busy.next = false;
                self.setState({ busy });
            }, delay);
        }
    },

    _validateUsers: function _validateUsers(users) {
        const centre = this.props.centre;

        var promises = [],
            isStaff = this.props.staffId ? true : false,
            isLessons = this.props.lessons && this.props.lessons.inView ? true : false,
            valid = true,
            errors = [],
            i;

        for (i = 0; i < users.length; i++) {
            const user = users[i];

            if (users[i].valid) {
                promises.push(Promise.resolve({ valid: true }));
            } else {
                const validate = UserModel.validateDynamic({
                    user,
                    options: null,
                    isStaff,
                    isLessons,
                    centre
                });
                promises.push(validate);
            }
        }

        return Promise.all(promises)
            .then(function (results) {
                for (i = 0; i < results.length; i++) {
                    if (!results[i].valid) {
                        errors[i] = results[i].errors,
                        valid = false;

                    } else {
                        errors[i] = {};
                    }
                }

                return {
                    validationErrors: errors,
                    valid:valid
                };
            });
    },

    // needs to return true if ANY login forms are showing
    anyLoginFormsAreShowing: function anyLoginFormsAreShowing(showForms) {
        var showLoginForm,
            frozen;

        for (var i = 0; i < this.props.users.objects.length; i++) {
            var user = this.props.users.objects[i];

            showLoginForm = !user.isLoggedInUser && this.props.useLogin[i];
            frozen = ((this.props.frozen || this.props.frozenUsers[i]) && this.props.frozenUsers[i] !== false) || (!this.props.frozen && this.props.frozenUsers[i] === true);

            if(showLoginForm && !frozen) {
                return true;
            }
        }

        return false;
    },

    anyMarketingSelected: function anyMarketingSelected(user) {
        var fields = ['marketingGuest', 'marketingPartners', 'marketingCouncil'];

        for(var c = 0; c < fields.length; c++) {
            if(user.info && user.info[fields[c]] && user.info[fields[c]].value === 'true') {
                return true;
            }
        }

        return false;
    },

    render: function render() {
        if (!this.props.centre) {
            return null;
        }

        var content = this.props.content.content,
            siteId = this.props.centre.info.site_id,
            disabled = this.props.validating || this.anyLoginFormsAreShowing(),
            lessons = this.props.lessons,
            isLessons = lessons && lessons.inView,
            leadUser = this.props.users.objects.find(function(u) { return u.lead; }),
            freeUserOnly = UserModel.onlyFreeProfile(this.props.users) || UserModel.isGuardianWithFreeJunior(this.props.users, siteId),
            matchingUsers = this.props.matchingUsers,
            clashedUsers = this.props.clashedUsers,
            expiredShortUsers = this.props.expiredShortUsers,
            hasMonthlyPayment = this.props.totalPrice.monthly,
            hasOneOffPayment = this.props.totalPrice.oneOff,
            selectedLessons = [],
            existingUsers = [],
            sectionsNodes = [],
            buttonProps = {},
            sections = {},

            existingUsersError,
            lessonsTimeout,
            saveSelections,
            buttonText,
            firstName,
            lastName,
            userName,
            userForm,
            typeDesc,
            idInType,
            section,
            header,
            loader,
            user,
            key,
            i;

        if((this.props.frozen || this.props.checkedProfile) && !this.anyLoginFormsAreShowing()) {
            buttonProps = {
                className: 'button extras__next-button',
                disabled: false,
                onClick: this._validateForm
            };
        } else {
            buttonProps = {
                className: 'button extras__next-button' + (disabled ? ' button--disabled' : ''),
                disabled: disabled,
                onClick: this._validateForm
            };
        }

        if(freeUserOnly && !Object.keys(this.props.extras).length) {
            buttonText = content.buttonConfirm;
        } else {
            buttonText =  (hasMonthlyPayment || hasOneOffPayment) ? content.buttonPayment : content.buttonConfirm;
        }

        for (i = 0; i < this.props.users.objects.length; i++) {
            userForm = null;
            user = this.props.users.objects[i];

            sections[user.type] = sections[user.type] || {
                type: user.type,
                typeDesc: user.typeDesc,
                users: []
            };

            typeDesc = user.typeDesc;
            idInType = user.idInType + 1;
            firstName = (user.info.firstName && user.info.firstName.value);
            lastName = (user.info.lastName && user.info.lastName.value);

            if (this.props.users.objects.length === 1 && firstName) {
                userName = ((firstName && lastName) ? firstName + ' ' + lastName : (firstName ? firstName : ''));
            } else {
                userName = typeDesc + ' ' + idInType + ((firstName && lastName) ? ': ' + firstName + ' ' + lastName : (firstName ? ': ' + firstName : ''));
            }

            if (this.props.existingUsers[i]) {
                existingUsers.push(userName);
            }

            key = 'personal-details_user_' + i;

            userForm = <UserForm
                addressSuggestions={ (this.props.addressSuggestions && this.props.addressSuggestions[i]) || [] }
                centre={ this.props.centre }
                contentForm={ this.props.content.userForm }
                contentLoginForm={ this.props.content.userLoginForm }
                contentToggle={ this.props.content.toggle }
                duration={ this.props.d }
                durationType={ this.props.dt }
                frozen={ ((this.props.frozen || this.props.frozenUsers[i]) && this.props.frozenUsers[i] !== false) || (!this.props.frozen && this.props.frozenUsers[i] === true) }
                index={ i }
                isStaff={ this.props.staffId || null }
                key={ key }
                leadUser={ leadUser }
                lessons={ this.props.lessons }
                logoutUser={ this._logoutUser }
                medicalConditions={ this.props.medicalConditions }
                onBlurInput={ this._onBlurInput.bind(null, i) }
                onChangeInput={ this._onChangeInput.bind(null, i) }
                onChangeCheckboxInput={ this._onChangeCheckboxInput.bind(null, i) }
                onUnfreeze={ this._onUnfreezeUser.bind(null, i) }
                onUnfreezeSection={ this._onUnfreezeSection.bind(null, i) }
                onUseLeadContact={ this._onUseLeadContact }
                onUseLeadEmmergencyDetails={ this._onUseLeadEmmergencyDetails }
                onUseLoginForm={ this._onUseLoginForm }
                onUseManualEntry={ this._onUseManualEntry.bind(null, i) }
                restrictions={ this.props.restrictions }
                setEmmergencyDetails={ this._setEmmergencyDetails }
                showMarketingChannels={ this.anyMarketingSelected(user) }
                showFullForm={ !config.disabledFeatures.remoteValidationEnabled || this.props.showFullFormUIDs[i] || this.props.manualAddressUsers[i] }
                titles={ this.props.centre.titles }
                unfrozenSections={ this.props.unfrozenSections[i] }
                useLeadContact={ user.useLeadContact }
                useLeadEmmergencyDetails={ user.useLeadEmmergencyDetails }
                useLogin={ this.props.useLogin[i] }
                user={ user }
                userFirstName={ firstName }
                userName={ userName }
                validationErrors={ this.state.validationErrors[i] || {} }
            />;

            sections[user.type].users.push(<div className='personal-details__user' key={ 'u_' + i}>
                { userForm }
            </div>);
        }

        for (key in sections) {
            section = sections[key];

            sectionsNodes.push(<section key={ 's_' + section.type } className='personal-details__section'>
                { section.users }
            </section>);
        }

        if (existingUsers && existingUsers.length) {
            var error = this.props.content.errors.userAlreadyExists[
                existingUsers.length === 1 ? 'singleUser' : 'multipleUsers'
            ];
            // this content contains html
            var content = error
                .replace('{user}', existingUsers[0])
                .replace('{users}', existingUsers.join(', '));
            existingUsersError = (
                <div className='form__error-zone'>
                    <span className='personal-details__error' dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            );
        } else if (matchingUsers && matchingUsers.length) {
            existingUsersError = (
                <div className='form__error-zone'>
                    <span className='personal-details__error'>{this.props.content.errors.matchingUsersFound}</span>
                </div>
            );
        } else if (expiredShortUsers  && expiredShortUsers.length) {
            existingUsersError = (
                <div className='form__error-zone'>
                    <span className='personal-details__error'>{this.props.content.errors.expiredShortSubClash.replace('{name}', expiredShortUsers[0].firstName + ' ' + expiredShortUsers[0].lastName)}</span>
                </div>
            );
        // } else if (clashedUsers && clashedUsers.length) {
        //     existingUsersError = (
        //         <div className='form__error-zone'>
        //             <span className='personal-details__error'>{this.props.content.errors.userSubClash.replace('{name}', clashedUsers[0].firstName + ' ' + clashedUsers[0].lastName)}</span>
        //         </div>
        //     );
        } else if (this.state.onlyJunior) {
            var juniorButton = <a href='#' className='link' onClick={this.createParentUser}>click here</a>;

            existingUsersError = (
                <div className='form__error-zone'>
                    <span className='personal-details__error'>{this.props.content.errors.onlyJuniorProfile} {juniorButton}.</span>
                </div>
            );

            // this.createParentUser(); // we want to scroll to the form on click
        }

        if (this.props.validating) {
            loader = (
                <ul style={{ display: 'block' }} className='loader__spinner loader__spinner--small simple-payment__loader'>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            );
        }

        if(isLessons && this.props.users.objects && this.props.users.objects.length) {
            for(var x = 0; x < this.props.users.objects.length; x++) {
                var user = this.props.users.objects[x];

                if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                    for(var l in user.lesson.selectedLessons){
                        selectedLessons.push(<SelectedLesson
                            user={ user }
                            key={'user_' + x + l}
                            level={ l } />);
                    }
                }
            }

            lessonsTimeout = <LessonsTimeout />;
        }

        if(!isLessons) {
            saveSelections = <SaveSelections />;
        }

        if(this.props.staffId) {
            header = <div>
                <h1 className='content__title'>{ content.staffTitle }</h1>
                <p className='content__intro'>{ content.staffDescription }</p>
            </div>;
        } else {
            header = <div>
                <h1 className='content__title'>{ content.title }</h1>
                <p className='content__intro'>{ content.description }</p>
            </div>;
        }

        return (
            <div className='container'>
                { header }

                <div className='module-container'>
                    { selectedLessons }
                    { lessonsTimeout }
                </div>

                <div className='personal-details'>
                    { sectionsNodes }
                    <div className='stage-options'>
                        { saveSelections }
                        <Button { ...buttonProps }>{ buttonText }</Button>
                        { loader }
                        { existingUsersError }
                    </div>
                </div>

                <Faqs />
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        addressSuggestions: state.personalDetails.addressSuggestions,
        centre: state.centreFinder.selected,
        clashedUsers: state.selections.clashedUsers,
        expiredShortUsers: state.selections.expiredShortUsers,
        content: state.app.content.stage3,
        d: state.selections.duration,
        dt: state.selections.durationType,
        existingUsers: state.selections.existingUsers,
        extras: state.selections.extras,
        frozen: state.personalDetails.frozen,
        frozenUsers: state.personalDetails.frozenUsers,
        restrictions: state.restrictions,
        lessons: state.lessons,
        manualAddressUsers: state.personalDetails.manualAddressUsers,
        matchingUsers: state.selections.matchingUsers,
        medicalConditions: state.personalDetails.medicalConditions,
        showFullFormUIDs: state.personalDetails.showFullFormUIDs,
        staffId: state.staffLogin.staffId,
        unfrozenSections: state.personalDetails.unfrozenSections,
        checkedProfile: state.personalDetails.checkedProfile,
        useLogin: state.personalDetails.useLogin,
        users: state.selections.users,
        validating: state.selections.validating,
        totalPrice: state.selections.totalPrice,
        staffName: state.staffLogin.staffName,
        reportingTransactionId: state.selections.reportingTransactionId,
        preselectedSubs: state.selections.preselectedSubs
    };
}

module.exports = connect(mapStateToProps)(Stage3Content);
