var React           = require('react'),

    actions         = require('../../actions'),
    Button          = require('../common/button'),

    ConfirmUser;

ConfirmUser = React.createClass({


    render: function() {
        var content = this.props.content,
            user = this.props.user,
            index = this.props.index,
            buttonText = content.user.buttonProfile,
            userProfile = user,
            whatsNext = [],
            benefits = [],
            whatsNextSection,
            userTypeContent,
            showMoreButton,
            siteId = this.props.centre ? this.props.centre.info.site_id : null,
            profileButton,
            userService,
            mrmId,
            key;

        userService = siteId ? user.service.replace(siteId, '0000') : user.service;
        userTypeContent = content.userTypes[userService];

        showMoreButton = <Button className={ 'button__more-info confirm-page__more-info ' + (this.props.expanded ? ' button__more-info--expanded' : '')} onClick={ this.props.onShowMore }>{ this.props.expanded ? content.user.buttonShowLess : content.user.buttonShowMore }</Button>;

        for (var i = 0; i < userTypeContent.benefitsList.length; i++) {
            let key = userTypeContent.benefitsList[i].title;
            let html = userTypeContent.benefitsList[i].text;
            benefits.push(<li key={ 'bl_' + key } className='confirm-page__list-item'>
                    <span>{ key.replace('benefit', '') }.</span>
                    <div dangerouslySetInnerHTML={ { __html: html }}></div>
                </li>);
        }

        if(user.lesson && user.lesson.selectedLessons && Object.values(user.lesson.selectedLessons).length > 0) {
            buttonText = content.user.buttonProfileLessons;
            userProfile = (user.typeDesc === 'Junior' && this.props.leadUser ? this.props.leadUser : user);
        }

        profileButton = <button className='button confirm-page__button' onClick={ this.props.reset.bind(null, {user: userProfile}) }>{ buttonText }</button>;
        if (userTypeContent.whatsNextList.length === 1) {
            whatsNext.push(
                <div key={ 'bl_0' } dangerouslySetInnerHTML={ { __html: userTypeContent.whatsNextList[0].text }}></div>
            );
        } else {
            for (var i = 0; i < userTypeContent.whatsNextList.length; i++) {
                let key = userTypeContent.whatsNextList[i].title;
                let html = userTypeContent.whatsNextList[i].text;
                whatsNext.push(<li key={ 'bl_' + key } className='confirm-page__list-item'>
                        <span>{ key.replace('step', '') }.</span>
                        <div dangerouslySetInnerHTML={ { __html: html }}></div>
                    </li>);
            }
        }

        if (!whatsNext.length) {
            whatsNextSection = null;
        } else if (whatsNext.length === 1) {
            whatsNextSection = <span className='confirm-page__list'>{ whatsNext }</span>;
        } else if (whatsNext.length) {
            whatsNextSection = <ol className='confirm-page__list'>{ whatsNext }</ol>;
        }

        if(user.info.mrmId){
            mrmId = user.info.mrmId.value;
        }

        var membershipNumber = mrmId ? <p>{ content.user.mrmNumber.replace('{id}', mrmId) }</p> : null;

        return (<li className='module__list-item'>
            <div className='confirm-page__items-meta'>
                <div className='confirm-page__item-title'>
                    <h4 className={'confirm-page__item-title-text confirm-page__item-title-text--' + user.type }>{ user.info.firstName.value } { user.info.lastName.value }</h4>
                    { showMoreButton }
                </div>
            </div>
            <div className={ 'module__list-item-details module__list-item-details--confirm-page-' + index }>
                <div className='inner'>
                    <div className='confirm-page__container'>
                        <header className='confirm-page__header'>
                            <h1 className='confirm-page__title'>{ userTypeContent.title }</h1>
                            { membershipNumber }
                        </header>
                        <div className='confirm-page__content'>
                            { profileButton }

                            <h2 className="confirm-page__subtitle">{ content.user.benefitsTitle }</h2>
                            <p className='confirm-page__description'>{ userTypeContent.benefitsDescription.replace('{ centre }', this.props.centreName || '') }</p>
                            <ol className='confirm-page__list'>
                                { benefits }
                            </ol>
                            <p className='confirm-page__appendix'>{ userTypeContent.benefitsAppendix }</p>
                            <h2 className='confirm-page__subtitle'>{ userTypeContent.closingLine }</h2>
                            <ol className='confirm-page__list'>
                               { whatsNextSection }
                            </ol>
                            <p className='confirm-page__description'>{ userTypeContent.whatsNextTitle }</p>
                            { profileButton }
                        </div>
                    </div>
                </div>
            </div>
        </li>);
    }
});

ConfirmUser.propTypes = {
    content: React.PropTypes.object,
    details: React.PropTypes.object,
    expanded: React.PropTypes.bool,
    index: React.PropTypes.number,
    onShowMore: React.PropTypes.func,
    user: React.PropTypes.object
};

module.exports = ConfirmUser;
