import React from "react";
import { renderToString } from 'react-dom/server';

import config from "../../../config";

/**
 * Specific JSX for rendering InfoWindow - to be used in GoogleMapMarkerInfoWindow (common)
 * @param {object} centre Centre information
 */
const SiteBandingGoogleMapMarkerInfoWindow = ( centre, selectedBanding, upgradableBand, content, showChangeCentreButton ) => {
    // Determines text to display in relation to "upgradable" centres
    const text = (centreBand, againstBand, upgradableBand) => {
        if ( upgradableBand && centreBand && centreBand <= upgradableBand) {
            return content.includedInUpgrade;
        }

        return centreBand ? (
            centreBand <= againstBand ? content.includedInMembership : content.notIncludedInMembership)
            : content.notIncludedInMembership;
    }

    // Determines whether to display headline prices
    const price = ( centreBand, againstBand, upgradableBand ) => {
        // Don't show prices
        if ( upgradableBand ) {
            return null;
        }

        return centreBand > againstBand && againstBand ? <p>
            <small>
                { centre.headline_monthly_price_in_pence > 0 ? <span>£{ (centre.headline_monthly_price_in_pence / 100 || 0.00).toFixed(2) } monthly</span> : null}
                {
                    centre.headline_monthly_price_in_pence &&
                    centre.headline_annual_price_in_pence ? <span> / </span> : null
                }
                { centre.headline_annual_price_in_pence > 0 ? <span>£{ (centre.headline_annual_price_in_pence / 100 || 0.00).toFixed(2) } annually</span> : null}
            </small>
        </p> : null;
    }

    return renderToString( <div>
        <p><small>{ centre.address }</small></p>
        <p><small><b>{ text(centre.banding, selectedBanding, upgradableBand) }</b></small></p>
        { price(centre.banding, selectedBanding, upgradableBand) }
        <p><small>{ centre.contact_telephone }</small></p>
        <a href={ `${ config.services.app.urls.enquiries }?siteid=${ centre.site_id }` } target="_blank">{ content.membershipEnquiriesLink }</a>
        <br />

        {/* Do we want the user to be able to change their centre within the map? */}
        { showChangeCentreButton ?
            <a href={ `?siteid=${ centre.site_id }` }>{ content.selectThisCentre }</a>
            : null
        }
    </div> );
};

export default SiteBandingGoogleMapMarkerInfoWindow;