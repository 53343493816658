export function simpleBrowserFingerprintMap() {
    const map = {};
    for (let key in window.navigator) {
        const value = typeof navigator[key] === 'object' ? JSON.parse(JSON.stringify(navigator[key])) : navigator[key];
        if (
            !value ||
            typeof value === 'function' ||
            (typeof value === 'object' && !Object.keys(value).length)
        ) {
            continue;
        }
        map[key] = value;
    }
    return map;
}

export function simpleBrowserFingerprintString() {
    try {
        const map = simpleBrowserFingerprintMap();
        return JSON.stringify(map);
    } catch (err) {
        return '';
    }
}
