var React           = require('react'),
    connect         = require('react-redux').connect,

    actions         = require('../../actions'),
    utils           = require('../../utils'),

    Button          = require('../common/button'),
    Input           = require('../common/forms/input'),
    Modal           = require('../common/modal'),

    DiscountsModal;

DiscountsModal = React.createClass({
    getInitialState: function getInitialState() {
        return {
            value: null,
            isLoading: false,
            appliedinValidDiscount: false,
            timedout: false
        };
    },

    _onChangeInput: function _onChangeInput(field, value) {
        this.setState({
            value: value
        });
    },

    _submitForm: function _submitForm(e) {
        e.preventDefault();

        if (this.state.value) {
            this._applyDiscount(utils.buildDiscountCode({
                type: 'code',
                value: this.state.value
            }));
        }
    },

    _applyDiscount: function _applyDiscount(discount) {
        var self = this;
        self.setState({
            appliedinValidDiscount: false,
            timedout: false,
            isLoading: true
        });

        this.props.dispatch(actions.app.showLoader(true));
        var d = this.props.dispatch(actions.centreFinder.selectCentre(this.props.centre.info.site_id, {
            discount: discount
        }));

        d.then(function(err) {
            self.setState({
                isLoading: false
            });

            if (err) {
                if(err.status === 404) {
                    self.setState({
                        appliedinValidDiscount: true
                    });
                } else {
                    self.setState({
                        timedout: true
                    });
                }
            } else {
                var modalContent = self.props.modal;
                self.props.dispatch(actions.app.hideModal());
                self.props.dispatch(actions.app.showModal('success', { text: modalContent.success }));
                self.props.dispatch(actions.discounts.discountSelected(discount));
            }

            self.props.dispatch(actions.app.showLoader(false));
        });
    },

    render: function render() {
        var modalButtonClass = 'button' + (this.state.value ? '' : ' button--disabled'),
            content = this.props.content;

        var appliedInvalidDiscount = null;

        if (this.state.appliedinValidDiscount) {
            appliedInvalidDiscount = <p>Invalid Code Applied</p>;
        }

        if (this.state.timedout) {
            appliedInvalidDiscount = <p>Technical Error. Please try again</p>;
        }

        if (this.state.isLoading) {
            appliedInvalidDiscount = <p>Loading...</p>;
        }

        return <Modal title={ content.title } className='modal--discounts' id={ this.props.id }>
            <div className='discounts__modal'>
                <p dangerouslySetInnerHTML={ {__html: content.desc } }></p>
                <form className='modal__form modal__form--narrow' onSubmit={ this._submitForm }>
                    <fieldset>
                        <Input className='modal__input'
                            onValidate={ this._onValidateField }
                            onUnmount={ this._unmountField }
                            input={ {
                                onChange: this._onChangeInput.bind(null, 'discountCode'),
                                placeholder: content.fields.discount.placeholder,
                                autoFocus: true,
                                required: true
                            } }
                            label={ {
                                className: 'a11y',
                                text: content.fields.discount.label
                            } }
                            disableHints={ true }
                            name='save_selections_discountCode'
                            type='text'
                            valid={ true }
                            value={ this.state.value }
                        />
                        { appliedInvalidDiscount }
                        <Button className={ modalButtonClass } disabled={ !this.state.value }>{ content.buttonApply }</Button>
                    </fieldset>
                </form>
            </div>
        </Modal>;
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.modals.discounts,
        modal: state.app.content.modals.discounts
    };
}

module.exports = connect(mapStateToProps)(DiscountsModal);
