var actionTypes     = require('../actions/actionTypes.js'),
    utils           = require('../utils');

module.exports = function (state, action) {
    var baseUserObject = {
            info: {},
            searchResults: null
        },
        defaultState = {
            memberRelations: null,
            users: [],
            validating: false
        },

        blackList = ['DUPL', 'DECD', 'ARCH'], //blacklist these member types (duplicated, deceased, archived)
        passed = [],

        newState,
        usersObj,
        users,
        user,
        key,
        id,
        i,
        j;

    state = state || defaultState;

    switch (action.type) {

        case actionTypes.STAFF_SEARCH.CHANGE_DATA:
            // Deep object clone.
            users = JSON.parse(JSON.stringify(state.users));

            if(!users[action.userId]) {
                users[action.userId] = Object.assign({}, baseUserObject);
            }

            if (users.length >= action.userId) {
                user = users[action.userId];

                if (action.data.info) {
                    for (key in action.data.info) {
                        user.info[key] = action.data.info[key];
                    }

                    if(user.info.dob) {
                        if(user.info.dob.validationErrors && user.info.dob.validationErrors.outOfRange) {
                            user.invalidForAgeRange = true;
                        } else {
                            user.invalidForAgeRange = false;
                        }
                    }
                }
            }

            return Object.assign({}, state, {
                    users: users
                });

        case actionTypes.STAFF_SEARCH.CHANGE_DATA_VALIDITY:
            // Deep object clone.
            users = JSON.parse(JSON.stringify(state.users));

            if (users.length >= action.userId) {

                user = users[action.userId];

                if (action.data.info) {
                    for (key in action.data.info) {
                        user.info[key] = user.info[key] || {
                            value: null,
                            valid: true,
                            validationErrors: {}
                        };

                        user.info[key].valid = action.data.info[key].valid;
                        user.info[key].validationErrors = action.data.info[key].validationErrors || null;
                    }
                }

                newState = Object.assign({}, state, {
                    users: users
                });
            }

            return newState || state;

        case actionTypes.STAFF_SEARCH.REMOVE_RESULTS:
            users = JSON.parse(JSON.stringify(state.users));
            users[action.userId].searchResults = null;

            return Object.assign({}, state, {
                users: users
            });

        case actionTypes.STAFF_SEARCH.SET_SEARCH_RESULTS:
            users = JSON.parse(JSON.stringify(state.users));
            passed = [];
            
            //only add results who's StatusID is not blacklisted
            for(var i = 0; i < action.results.length; i++) {
                if(blackList.indexOf(action.results[i].StatusID) === -1) {
                    passed.push(action.results[i]);
                }
            }

            users[action.userId].searchResults = passed;

            return Object.assign({}, state, {
                users: users
            });

        case actionTypes.STAFF_SEARCH.VALIDATING:
            return Object.assign({}, state, {
                validating: action.validating
            });

        case actionTypes.STAFF_SEARCH.WIPEOUT:
            return  Object.assign({}, defaultState);

        default:
            return state;
    }
};
