var debug                   = require('debug')('actions-selections'),
    actionTypes             = require('./actionTypes.js'),

    appActions              = require('../actions/app.js'),
    selectionsActions       = require('../actions/selections.js'),
    personalDetailsActions  = require('../actions/personalDetails.js'),
    membershipsActions      = require('../actions/memberships.js'),
    userActions             = require('../actions/user.js'),

    userModel               = require('../models/user.js'),
    httpFetch               = require('../utils/httpFetch'),

    config                  = require('../config'),

    tracking                = require('../utils/tracking'),

    exports;

module.exports = exports = {

    changeData: function changeData(userId, data) {
        return function(dispatch, getState) {
            dispatch(exports.validating(true));

            var state = getState();

            var user = state.staffSearch.users[userId] ? JSON.parse(JSON.stringify(state.staffSearch.users[userId])) : {info:{}},// Deep object clone.
                validatedData = {},
                promises = [],
                infoKey,
                result,
                value,
                key,
                i;

            for (key in data) {
                if ('info' === key) {
                    validatedData.info = {};

                    for (infoKey in data.info) {
                        value = data.info[infoKey];

                        if ('dob' === infoKey) {
                            user.info[infoKey] = {
                                value: value,
                                valid: true,
                                validationErrors: {}
                            };

                            user.availableSubscriptions = state.selections.users.objects[userId].availableSubscriptions;

                            result = userModel.getAge(user, state.selections.duration, state.selections.durationType);
                            validatedData.info.age = result.age;

                            if (data.userType == 'Adult') {
                                // Do not validate age range
                                promises.push(userModel.validateStaffField(infoKey, result.age, {}));
                            }
                            else {
                                // Validate age range
                                promises.push(userModel.validateStaffField(infoKey, result.age, { range: result.ageRange }));
                            }

                        } else {
                            promises.push(userModel.validateStaffField(infoKey, value));
                        }
                    }

                } else {
                    validatedData[key] = data[key];
                }
            }

            if (promises.length) {
                Promise.all(promises)
                    .then(function(results) {
                        for (i = 0; i < results.length; i++) {
                            result = results[i];
                            if ('dob' === result.key) {
                                result.value = data.info[result.key];
                            }

                            validatedData.info[result.key] = result;
                        }

                        dispatch(exports.dataValidated(userId, validatedData));
                        dispatch(exports.validating(false));

                        data.callback && data.callback();
                    });

            } else {
                dispatch(exports.dataValidated(userId, validatedData));
                dispatch(exports.validating(false));

                data.callback && data.callback();
            }
        }
    },

    dataValidated: function dataValidated(userId, data) {
        return {
            type: actionTypes.STAFF_SEARCH.CHANGE_DATA,
            userId: userId,
            data: data
        };
    },

    fieldValidated: function fieldValidated(userId, field, valid, errors) {
        var info = {};
        info[field] = {
            valid: valid,
            validationErrors: errors
        };

        return {
            type: actionTypes.STAFF_SEARCH.CHANGE_DATA_VALIDITY,
            userId: userId,
            data: {
                info: info
            }
        };
    },

    impersonateMember: function impersonateMember(data) {
        return function(dispatch, getState) {
            var state = getState();
            
            dispatch(appActions.showLoader(true));

            if(data.isJuniorPanel) {
                var users = Object.assign({}, state.selections.users.count);
                users.freeprofile = 1;

                // Set new user totals.
                dispatch(selectionsActions.setUsers(users, true));
            }

            return httpFetch.fetch(config.services.staff.urls.impersonate, {
                method: 'POST',
                params: { 
                    id: data.id 
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                var age = 20,
                    userObj;

                state = getState();

                if(response.success && data.isGuardian) {
                    userObj = {
                        info: {
                            dob: {
                                value: response.user.dob
                            }
                        }
                    };
                    age = userModel.getAgeValue(userObj);
                } else if (!response.success) {
                    if (data.onError) {
                        var noMember = {
                            status: 'NoMember'
                        };
                        data.onError(noMember);
                    }

                    dispatch(appActions.showLoader(false));
                }

                //if you're adding to a guardian user, you know its the first in the array
                if (data.isJuniorPanel) {
                    userObj = state.selections.users.objects[0];
                } else {
                    userObj = state.selections.users.objects[data.uid];
                }

                if(age >= 16) {
                    var normalizedUser = userModel.normalizeUserData(response.user),
                        fetchedUserIDs = [],
                        d = state.selections.duration,
                        dt = state.selections.durationType,
                        sub = userObj.availableSubscriptions.find(function(s) {
                            return s.duration === d && s.durationType === dt;
                        }.bind(this)),
                        dateRange = {
                            min: Date.now() - ((sub && sub.maxAge) || 115) * 31536000000,
                            max: Date.now() - ((sub && sub.minAge) || 0) * 31536000000
                        };

                    for(var i = 0; i < state.selections.fetchedUsers.length; i++) {
                        fetchedUserIDs.push(state.selections.fetchedUsers[i].mrmId);
                    }

                    if(fetchedUserIDs.indexOf(normalizedUser.info.mrmId) === -1){
                        userModel.validate(normalizedUser, { dob: { range: dateRange }})
                            .then(function(result) {
                                dispatch(selectionsActions.changeData(data.uid, {
                                    fromUserForm: true,
                                    isLoggedInUser: true,
                                    info: normalizedUser.info,
                                    directDebit: normalizedUser.directDebit,
                                    subscriptions: normalizedUser.subscriptions
                                }));

                                //if is lessons journey, get medical details for user
                                if (state.lessons && state.lessons.inView) {
                                    dispatch(userActions.getMedicalDetails(normalizedUser.info.mrmId, data.uid));
                                }
                                
                                dispatch(personalDetailsActions.useManualAddressEntry(data.uid));
                                dispatch(personalDetailsActions.freezeUser(data.uid));
                                dispatch(personalDetailsActions.unfreeze(data.uid, 'subscriptions'));

                                if (data.isJuniorPanel) {
                                    // Hide junior panel.
                                    dispatch(membershipsActions.showSpecialPanel());
                                    dispatch(membershipsActions.selectMemberships());
                                }

                                if (data.onSuccess) {
                                    data.onSuccess(response);
                                }
                            });
                    } else {
                        if (data.onError) {
                            var error = {
                                success: false,
                                reason: 'AlreadyLoggedIn'
                            };

                            data.onError(error);
                        }

                        dispatch(appActions.showLoader(false));
                    }
                } else {
                    if (data.onError) {
                        var juniorError = {
                            status: 'juniorError'
                        };
                        data.onError(juniorError);
                    }

                    dispatch(appActions.showLoader(false));
                }
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
                dispatch(appActions.showLoader(false));
                data.onError(e);
                if (e == 'Error: Unauthenticated.') {
                    dispatch(actions.staffLogin.showLogin(true));
                }
            });
        }
    },

    removeResults: function removeResults(userId) {
        return {
            type: actionTypes.STAFF_SEARCH.REMOVE_RESULTS,
            userId: userId
        };
    },

    setSearchResults: function setSearchResults(results, userId) {
        return {
            type: actionTypes.STAFF_SEARCH.SET_SEARCH_RESULTS,
            results: results,
            userId: userId
        };
    },

    validating: function validating(status) {
        return {
            type: actionTypes.STAFF_SEARCH.VALIDATING,
            validating: status
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.STAFF_SEARCH.WIPEOUT
        };
    }
};
