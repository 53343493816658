var debug                   = require('debug')('actions-app'),
    actionTypes             = require('./actionTypes.js'),

    config                  = require('../config'),

    httpFetch               = require('../utils/httpFetch'),
    UserModel               = require('../models/user'),

    appActions              = require('./app'),
    selectionsActions       = require('./selections'),
    lessonsActions          = require('./lessons'),
    personalDetailsActions  = require('./personalDetails'),

    exports;

module.exports = exports = {
    checkProfile: function checkProfile(data) {
        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.app.urls.checkProfile, {
                method: 'POST',
                params: {
                    email: data.email,
                    password: data.password,
                    card: data.card
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }, true)
            .then((response) => {
                if (response.success) {
                    dispatch(exports.getExpiredSubscriptions(response.user.mrmId, data.uid, data.onError));
                }

                return response;
            })
            .then(function(response) {
                var age = 20;

                if(response.success && data.isGuardian) {
                    var userObj = {
                        info: {
                            dob: {
                                value: response.user.dob
                            }
                        }
                    };
                    age = UserModel.getAgeValue(userObj);
                }

                if(age >= 16) {
                    if (response.redirectTo && response.redirectTo.indexOf('set-password') !== -1) {
                        window.location.href = response.redirectTo + '&referralUrl=' + window.location.href;
                    } else if (response.success) {
                        var normalizedUser = UserModel.normalizeUserData(response.user),
                            state = getState(),
                            fetchedUserIDs = [],
                            userObj = state.selections.users.objects[data.uid],
                            d = state.selections.duration,
                            dt = state.selections.durationType,
                            sub = userObj.availableSubscriptions.find(function(s) {
                                return s.duration === d && s.durationType === dt;
                            }.bind(this)),
                            dateRange = {
                                min: Date.now() - ((sub && sub.maxAge) || 115) * 31536000000,
                                max: Date.now() - ((sub && sub.minAge) || 0) * 31536000000
                            };

                        for(var i = 0; i < state.selections.fetchedUsers.length; i++) {
                            fetchedUserIDs.push(state.selections.fetchedUsers[i].mrmId);
                        }

                        if(fetchedUserIDs.indexOf(normalizedUser.info.mrmId) === -1){
                            UserModel.validate(normalizedUser, { dob: { range: dateRange }})
                                .then(function(result) {
                                    dispatch(selectionsActions.changeData(data.uid, {
                                        fromUserForm: true,
                                        isLoggedInUser: true,
                                        info: normalizedUser.info,
                                        directDebit: normalizedUser.directDebit,
                                        subscriptions: normalizedUser.subscriptions,
                                        updatePrice: true
                                    }));

                                    //if is lessons journey, get medical details for user
                                    if (state.lessons && state.lessons.inView) {
                                        dispatch(exports.getMedicalDetails(normalizedUser.info.mrmId, data.uid));
                                    }

                                    dispatch(personalDetailsActions.useManualAddressEntry(data.uid));

                                    // If the user is fully valid, or if the only
                                    //  error is the Terms and Conditions
                                    if (result.valid || (Object.keys(result.errors) && result.errors.tandc )) {
                                        dispatch(personalDetailsActions.freezeUser(data.uid));
                                        dispatch(personalDetailsActions.unfreeze(data.uid, 'subscriptions'));
                                    }

                                    if (data.onSuccess) {
                                        data.onSuccess(response);
                                    }

                                    dispatch(appActions.showLoader(false));
                                });
                        } else {
                            if (data.onError) {
                                var error = {
                                    success: false,
                                    reason: 'AlreadyLoggedIn'
                                };

                                data.onError(error);
                            }

                            dispatch(appActions.showLoader(false));
                        }

                    } else {
                        if (data.onError) {

                            data.onError(response);
                        }

                        dispatch(appActions.showLoader(false));
                    }
                } else {
                    if (data.onError) {
                        var juniorError = {
                            status: 'juniorError'
                        };
                        data.onError(juniorError);
                    }

                    dispatch(appActions.showLoader(false));
                }
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));

                dispatch(appActions.error(e));

                if (data.onError) {
                    data.onError(e);
                }

                dispatch(appActions.showLoader(false));
            });
        };
    },

    getExpiredSubscriptions: function getExpiredSubscriptions(mrmId, uid = 0, onError) {
        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));

            const url = config.services.memberships.urls.expiredSubscriptions.replace('{mrmId}', mrmId);

            return httpFetch.fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }, true)
            .then(function (expiredSubscriptions) {
                dispatch(selectionsActions.setUserExpiredSubscriptions(uid, expiredSubscriptions));
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));

                dispatch(appActions.error(e));

                if (onError) {
                    onError(e);
                }

                dispatch(appActions.showLoader(false));
            });
        };
    },

    deleteProfile: function deleteProfile() {
        return {
            type: actionTypes.USER.DELETE_PROFILE
        };
    },

    fetchProfile: function fetchProfile(loginIfSuccessful, callback) {
        return function(dispatch, getState) {
            var profile;

            dispatch(appActions.showLoader(true));

            //get the current loggedin users profile only
            return httpFetch.fetch(config.services.memberships.urls.profile, {
                method: 'GET',
                params: {
                    with: 'subscriptions,direct_debit,relations,marketing'
                },
                headers: {
                    'Accept': 'application/json'
                }
            }, true)
            .then(function(response) {
                if (response.mrmId === null || response.mrmId === undefined) {
                    throw new Error('no id in profile response');
                }

                profile = response;

                dispatch(exports.profileSuccess(UserModel.normalizeUserData(profile)));
                dispatch(selectionsActions.profileSuccess(UserModel.normalizeUserData(profile)));

                if (loginIfSuccessful) {
                    dispatch(exports.loginSuccess());
                }

                if (callback) {
                    callback(profile);
                }

                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                var state = getState();

                debug('error: ' + JSON.stringify(e));

                // TODO: this should perhaps check for 403? 401?
                if (/unauthenticated/.test(e.message.toLowerCase())) {
                    dispatch(exports.logoutSuccess());
                    dispatch(exports.deleteProfile());

                    if(state.lessons.user && state.lessons.user.loggedInUser) {
                        dispatch(lessonsActions.clearEligibilityUser());
                    }
                }

                dispatch(appActions.error(e));

                if (callback) {
                    callback(profile);
                }

                dispatch(appActions.showLoader(false));
            });
        };
    },

    getMedicalDetails: function getMedicalDetails(mrmId, userId) {
        return function(dispatch) {
            return httpFetch.fetch(config.services.memberships.urls.getUserMedical, {
                method: 'GET',
                params: {
                    id: mrmId
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }, true)
            .then(function(response) {
                response = response.filter(function(el) {
                    return el.toLowerCase().indexOf('none') === -1;
                });

                var info = {
                    'medicalConditions': response
                };
                dispatch(selectionsActions.changeData(userId, {
                    info: info
                }));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    login: function login(data, onSuccess, onError) {

        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            //authenticate login and then fetch users profile
            return httpFetch.fetch(config.services.app.urls.login, {
                method: 'POST',
                params: {
                    email: data.email,
                    password: data.password,
                    card: data.card
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }, true)
            .then(function(response) {
                var userObj = {
                    info: {
                        dob: {
                            value: response.user.dob
                        }
                    }
                };
                var age = UserModel.getAgeValue(userObj);

                if(age >= 16) {
                    if (response.redirectTo && response.redirectTo.indexOf('set-password') !== -1) {
                        window.open(
                          response.redirectTo + '&referralUrl=' + window.location.href,
                          '_blank'
                        );

                        var error = {
                            ForcedPasswordReset: true,
                            resetURL: response.redirectTo + '&referralUrl=' + window.location.href
                        };

                        onError && onError(error);
                        dispatch(appActions.showLoader(false));

                    } else {
                        dispatch(exports.fetchProfile(true, function (profile) {
                            dispatch(appActions.profileLoaded());
                            onSuccess && onSuccess(profile);

                            dispatch(appActions.showLoader(false));
                        }));
                    }
                } else {
                    var juniorError = {
                        status: 'juniorError'
                    };

                    onError && onError(juniorError);
                    dispatch(exports.logout());
                    dispatch(appActions.showLoader(false));
                }
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));

                if (data && data.onError) {
                    data.onError(e);

                } else {
                    dispatch(appActions.error(e));
                }

                onError && onError(e);

                dispatch(appActions.showLoader(false));
            });
        };
    },

    loginSuccess: function loginSuccess() {
        return {
            type: actionTypes.USER.LOGIN_SUCCESS
        };
    },

    logout: function logout(data) {
        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.app.urls.logout, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }, true)
            .then(function() {
                var state = getState();

                dispatch(exports.logoutSuccess());
                dispatch(exports.deleteProfile());
                dispatch(selectionsActions.profileSuccess(null));
                dispatch(personalDetailsActions.unfreezeAll());

                if(state.lessons.user && state.lessons.user.loggedInUser) {
                    dispatch(lessonsActions.clearEligibilityUser());
                }

                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));

                if (data && data.onError) {
                    data.onError(e);

                } else {
                    dispatch(appActions.error(e));
                }

                dispatch(appActions.showLoader(false));
            });
        };
    },

    logoutSuccess: function logoutSuccess() {
        return {
            type: actionTypes.USER.LOGOUT_SUCCESS
        };
    },

    profileSuccess: function profileSuccess(profile) {
        return {
            type: actionTypes.USER.PROFILE_RECEIVED,
            profile: profile
        };
    },

    setPassword: function setPassword(data, onSuccess, onError) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(config.services.app.urls.setPassword, {
                method: 'POST',
                params: {
                    password: data.password,
                    mrmId: data.mrmId
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }, true)
            .then(function(response) {
                onSuccess && onSuccess(response);
                dispatch(appActions.showLoader(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));

                onError && onError(e);

                dispatch(appActions.showLoader(false));
            });
        };
    },

    unauthorized: function unauthorized() {
        return {
            type: actionTypes.USER.UNAUTHORIZED
        };
    }
};
