var debug           = require('debug')('actions-faqs'),
    actionTypes     = require('./actionTypes.js'),
    httpFetch       = require('../utils/httpFetch'),

    config          = require('../config'),

    exports;

module.exports = exports = {

    resultsReceived: function resultsReceived(response) {
        return {
            type: actionTypes.FAQS.RESULTS_RECEIVED,
            results: response.data
        };
    },

    fetchFAQs: function fetchFAQs(tags) {

        var payload = {
            tags: tags,
            mode: 'any'
        };

        return function(dispatch) {
            return httpFetch.fetch(config.services.faqs.urls.get, {
                method: 'GET',
                params: payload,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.resultsReceived(response));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    voteSubmitted: function voteSubmitted(payload) {
        return {
            type: actionTypes.FAQS.VOTE_SUBMITTED,
            payload
        };
    },

    vote: function (id = 0, choice = '') {
        const payload = {
            faq_id: id,
            choice,
            referer: window.location.href
        };
        const url = config.services.faqs.urls.vote;
        return (dispatch) => {
            dispatch(exports.voteSubmitted(makePayload({ complete: false })));
            return httpFetch.fetch(url, {
                method: 'POST',
                params: payload,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                const payload = makePayload({ ...response, complete: true });
                dispatch(exports.voteSubmitted(payload));
                return [payload, null];
            }).catch(err => {
                dispatch(exports.voteSubmitted(makePayload({ choice: '' })));
                return [null, err];
            });
        }

        function makePayload(input = {}) {
            return { id: Number(id), choice, time: (new Date).toISOString(), ...input };
        }
    },

    answerScoring: function answerScoring(choice) {
        const deselect = [false, 'false', 0, '0', 'no', 'n'].includes(choice);
        const result = deselect ? false : true;
        return {
            type: actionTypes.FAQS.ANSWER_SCORING,
            answerScoring: result
        };
    },
};
