var React           = require('react'),
    moment          = require('moment'),

    utils           = require('../../utils/'),
    viewUtils       = require('../../utils/viewUtils'),

    Button          = require('../common/button'),

    OwedSubscriptions;

OwedSubscriptions = React.createClass({
    render: function() {
        var content = this.props.content,
            memberSubs = this.props.memberSubs,
            owedSubs = this.props.owedSubs,
            btnText = content.buttonMakePayment,
            owedSubsBlock,
            fullList = [],
            i;

        if(owedSubs) {
            for(var sub in owedSubs) {
                var currentSub = owedSubs[sub];

                if(currentSub) {
                    if(currentSub.subs) {
                        var buttonProps = {
                                onClick: this.props.payNow.bind(null, currentSub.url),
                                className: 'button simple-payment__button'
                            },
                            totalOwed = 0,
                            subsList = [];

                        for(i = 0; i < currentSub.subs.length; i++) {
                            var owedSubRef = currentSub.subs[i]; 

                            for(var memberSub in memberSubs) {
                                if(owedSubRef === memberSubs[memberSub].SubscriptionRef && parseInt(memberSubs[memberSub].AmountOwing) !== 0) {
                                    totalOwed += parseInt(memberSubs[memberSub].AmountOwing*100);

                                    subsList.push(<div key={i}>
                                        <p>{memberSubs[memberSub].Description} ({ utils.formatPrice(memberSubs[memberSub].AmountOwing*100) })</p>
                                    </div>);

                                    break;
                                }
                            }
                        }

                        if(subsList.length) {
                            fullList.push(<div className='renewals__owed-container' key={'subs_' + sub}>
                                    <h4>{ content.containerTitle }</h4>
                                    { subsList }

                                    <div className='renewals__total-container'>
                                        <h4>{ content.amountOwed } <strong>{ utils.formatPrice(totalOwed) }</strong></h4>
                                        <Button { ...buttonProps }>{ btnText }</Button>
                                    </div>
                                </div>);
                        }
                    }
                }
            }

            owedSubsBlock = (<form className='renewals__form'>
                    { fullList }
                </form>);
        }

        if(fullList.length === 0) {
            return null;
        }

        return <div className='renewals__owed'>
            <div className='module-container'>
                <div className='module module--inactive'>
                    <div className='renewals__subscriptions'>
                        <h2>{ content.title }</h2>
                        <p>{ content.description }</p>

                        { owedSubsBlock }
                    </div>
                </div>
            </div>
        </div>;
    }
});

OwedSubscriptions.propTypes = {
    content: React.PropTypes.object
};

module.exports = OwedSubscriptions;
