var React = require("react"),
    connect = require("react-redux").connect,
    config = require("../../config"),
    viewUtils = require("../../utils/viewUtils"),
    Modal = require("../common/modal"),
    DirectDebitModal;

DirectDebitModal = React.createClass({
    getInitialState: function getInitialState() {
        return {
            value: null
        };
    },

    render: function render() {
        var content = this.props.content,
            user = this.props.showModals.directDebit.options.user;

        return (
            <Modal
                title={content.modal.title}
                className="modal--direct-debit-page"
            >
                <div className="direct-debit-page__modal">
                    <div className="direct-debit-page__modal-header">
                        <div className="direct-debit-page__modal-column">
                            <span
                                className="direct-debit-page__section-text"
                                dangerouslySetInnerHTML={{
                                    __html: content.eaAddress
                                }}
                            ></span>
                        </div>
                        <div className="direct-debit-page__modal-column">
                            <div className="direct-debit-page__dd-guarantee-logo"></div>
                            <span className="direct-debit-page__section-title">
                                {content.modal.instructionText}
                            </span>
                        </div>
                    </div>
                    <div className="direct-debit-page__modal-column">
                        <span className="direct-debit-page__section-title">
                            {content.modal.holderName}
                        </span>
                        <span className="direct-debit-page__field-copy">
                            {user.bankDetails.fields.holderName.value ||
                                user.bankDetails.fields.holderName}
                        </span>

                        <span className="direct-debit-page__section-title">
                            {content.modal.accountNumber}
                        </span>
                        <span className="direct-debit-page__field-copy">
                            {user.bankDetails.fields.accountNumber.value ||
                                user.bankDetails.fields.accountNumber}
                        </span>

                        <span className="direct-debit-page__section-title">
                            {content.modal.sortCode}
                        </span>
                        <span className="direct-debit-page__field-copy">
                            {user.bankDetails.fields.sortCode.value ||
                                user.bankDetails.fields.sortCode}
                        </span>

                        <span className="direct-debit-page__section-title">
                            {content.modal.bankName}
                        </span>
                        <span className="direct-debit-page__field-copy">
                            {user.bankAddress && user.bankAddress.name}
                            {user.bankAddress && user.bankAddress.address_1}
                            <br />
                            {user.bankAddress && user.bankAddress.address_2}
                            <br />
                            {user.bankAddress && user.bankAddress.address_3}
                            <br />
                            {user.bankAddress && user.bankAddress.postcode}
                        </span>
                    </div>

                    <div className="direct-debit-page__modal-column">
                        <span className="direct-debit-page__section-title">
                            {content.modal.userNumber}
                        </span>
                        <span className="direct-debit-page__field-copy">
                            {config.app.serviceUserNumber}
                        </span>

                        <span className="direct-debit-page__section-title">
                            {content.modal.reference}
                        </span>
                        <span className="direct-debit-page__field-copy">
                            {user.info.bankAccountReference.value}
                        </span>

                        <h4 className="direct-debit-page__section-title">
                            {content.modal.instructionTitle}
                        </h4>
                        <span className="direct-debit-page__section-text">
                            {content.modal.instructionText1}
                        </span>

                        <h4 className="direct-debit-page__section-title direct-debit-page__section-title--no-margin">
                            {content.date}
                        </h4>
                        <span className="direct-debit-page__section-text">
                            {viewUtils.formatDate(Date.now())}
                        </span>
                        <span
                            className="direct-debit-page__section-text"
                            dangerouslySetInnerHTML={{
                                __html: content.banksAcceptNotice
                            }}
                        ></span>
                    </div>
                </div>
            </Modal>
        );
    }
});

function mapStateToProps(state) {
    return {
        content: state.app.content.directDebitPage,
        showModals: state.app.showModals
    };
}

module.exports = connect(mapStateToProps)(DirectDebitModal);
