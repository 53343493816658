var React           = require('react'),
    connect         = require('react-redux').connect,
    history         = require('../utils/history'),

    actions         = require('../actions'),

    PaymentHandler;

const { goToConfirmPage } = require('../utils/confirmPage');

PaymentHandler = React.createClass({
    displayName: 'PaymentHandler',

    componentDidMount: function componentDidMount() {

        const {users, discount, dispatch, d, dt, reportingTransactionId, content, pricing, status} = this.props;

        // If we reach this route without a status parameter
        //  something is not right, so we redirect to root.
        if (!status) {
            return history.push('/');
        }

        var reportPayload = {
                members: users && users.map(function (u) {
                    var lessonSessions = [];

                    if(u.lesson.selectedLessons && Object.keys(u.lesson.selectedLessons).length){
                        for(var l in u.lesson.selectedLessons) {
                            if(u.lesson.selectedLessons[l].bookingRef) {
                                lessonSessions.push(u.lesson.selectedLessons[l].session.mrm_id);
                            }
                        }
                    }

                    return {
                        mrmId: u.info && u.info.mrmId && u.info.mrmId.value,
                        lessonSessions: lessonSessions,
                        subscriptions: (u.availableSubscriptions.length > 0 || Object.keys(u.extras).length) ? true : false
                    };
                }),
                promocode: null,
                corpcode: null
            },
            gatewayError;

        if (discount) {
            if (discount.type === 'corporate') {
                reportPayload.corpcode = discount.id;
            }

            if (discount.type === 'code') {
                reportPayload.promocode = discount.id;
            }
        }

        switch (status) {
            case 'success':
                reportPayload.status = 'success';

                if(reportingTransactionId){
                    dispatch(actions.selections.markTransactionAsComplete({
                        payload: reportingTransactionId
                    }));
                }

                return dispatch(
                    actions.payments.report(
                        { payload: reportPayload },
                        goToConfirmPage
                    )
                );

            case 'cancel':
            case 'failure':
                var redirUrl;

                if (0 === dt && 12 === d && !pricing.hasMonthlyFees) {
                    redirUrl = '/payment';

                } else {
                    redirUrl = '/direct-debit';
                }

                if  ('cancel' === status) {
                    reportPayload.status = 'cancel';
                    gatewayError = content.errors.canceledByUser;

                } else {
                    reportPayload.status = 'failure';
                    gatewayError = content.errors.serverError;
                }

                return dispatch(actions.payments.report({ payload: reportPayload, gatewayError: gatewayError }, function() { history.push(redirUrl); }));

            default:
                return history.push('/');
        }
    },

    render: function() {
        return (<div>{ this.props.content.loading }</div>);
    }
});


PaymentHandler.propTypes = {
    status: React.PropTypes.string
};


function mapStateToProps(state) {
    let users = state.selections.users.objects;
    if (sessionStorage.getItem('users:objects') && (!users || !users.length)) {
        users = JSON.parse(sessionStorage.getItem('users:objects'));
    }

    return {
        content: state.app.content.paymentHandler,
        d: state.selections.duration,
        dt: state.selections.durationType,
        pricing: state.selections.pricing,
        users,
        discount: state.discounts.discount,
        reportingTransactionId: state.selections.reportingTransactionId,
    };
}

module.exports = connect(mapStateToProps)(PaymentHandler);
