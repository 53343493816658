const {
    virtualPageTitle
} = require("../../../../common/google/analytics/lessons");
const {
    castToUndefinedIfEmpty,
    products,
    hasSelections
} = require("../../../../common/google/analytics/products");
const { push } = require("../../common/google/analytics/datalayer");

var React = require("react"),
    connect = require("react-redux").connect,
    Autosuggest = require("react-autosuggest"),
    actions = require("../../../actions"),
    config = require("../../../config"),
    scriptCache = require("../../../utils/ScriptCache"),
    Button = require("../../common/button"),
    Input = require("../../common/forms/input"),
    viewUtils = require("../../../utils/viewUtils"),
    utils = require("../../../utils/"),
    CentreGeoloc;

CentreGeoloc = React.createClass({
    getInitialState: function() {
        return {
            geocoder: null,
            loaded: null,
            map: null,
            geoButton: false,
            // The below makes sure we are in secure mode in a browser.
            // There would be no point showing the 'use my location' link but
            //      it being blocked by serving the page over plain HTTP...
            // @TODO: swap those two lines when EA-78 is done. https://mediablaze.atlassian.net/browse/EA-78
            // locationApi: ('https' === window.location.protocol || 'https' === document.location.protocol) && !!navigator.geolocation
            locationApi: !!navigator.geolocation,
            tracked: false,
            centreList: []
        };
    },

    componentWillMount: function componentWillMount() {
        this._delayedFetchRequested = window.debounce(
            this._delayedFetchRequested,
            400
        );
    },

    componentDidMount: function () {
        console.log('this.props.hasSiteIdParam', this.props.hasSiteIdParam);
        var apiKey = config.services.googleapis.apikey,
            apiUrl = config.services.googleapis.mapscript.replace(
                "{key}",
                apiKey
            );

        this.scriptCache = scriptCache({ google: apiUrl });

        this.scriptCache.google.onLoad(this._onScriptLoad);

        if (!this.props.hasSiteIdParam) {
            console.log("request made:", true);
            fetch(
                `${config.services.centreFinder.urls.find}?per_page=500&visibility=join`
            )
                .then(response => response.json())
                .then(centres => {
                    this.setState({
                        centreList: centres.data
                    });
                });
        }

    },

    componentWillReceiveProps: function(nextProps) {
        var lessons = nextProps.lessons;

        if (lessons.typeId !== this.props.lessons.typeId) {
            const { lat, lng } = this.props.location;

            if (lessons.typeId && this.props.lessons.typeId && lat && lng) {
                // if lesson was type was changed reload centres
                this.props.dispatch(
                    actions.centreFinder.findCentres( { lat: lat, lng: lng, facilityTerm: this.props.facilityTerm })
                );
            }

            if (lessons.typeId && !this.state.tracked) {
                push({
                    event: "lesson selected",
                    lessonType: utils.getLessonType(lessons), // captures the lesson type user has selected
                    products: this.props.products
                });

                this.setState({ tracked: true });
            }
        }
    },

    onLessonSelect: function onLessonSelect(value) {
        if (value !== 0) {
            this.props.dispatch(actions.lessons.setType(value));

            push({
                event: "virtual pageview",
                virtualPagePath: "/virtual/lessons/timetable/",
                virtualPageTitle:
                    "Join - Everyone Active – Lessons - Timetable",
                products: this.props.products
            });
        }
    },

    _onScriptLoad: function _onScriptLoad() {
        //(err, tag) {
        this._gapi = window.google;
        this.setState({
            loaded: true,
            geocoder: new this._gapi.maps.Geocoder()
        });
    },

    _expireLocalStorageCache: function() {
        const currentTimeStamp = Date.now();

        Object.keys(sessionStorage).forEach(item => {
            const [itemPrefix] = item.split(":");
            if (itemPrefix === "keyCache") {
                const parsedData = JSON.parse(sessionStorage.getItem(item));
                if (parsedData && parsedData.created) {
                    const seconds =
                        (currentTimeStamp - parsedData.created) / 1000;
                    if (seconds > 300) {
                        sessionStorage.removeItem(item);
                    }
                }
            }
        });
    },

    _geocode: function _geocode(loc) {
        this._expireLocalStorageCache();

        const encodedLocation = encodeURIComponent(loc);
        const cacheKey = `keyCache:${encodedLocation}`;
        const locallyCachedItem = sessionStorage.getItem(cacheKey);

        if (locallyCachedItem !== null) {
            const parsedData = JSON.parse(locallyCachedItem);

            if (parsedData && parsedData.created) {
                this._onGeocodeSuccess(parsedData.data);
                return;
            }
        }

        fetch(
            `${config.services.googleapis.proxy}?location=${encodeURIComponent(
                loc
            )}`
        )
            .then(response => response.json())
            .then(data => {
                const storageValue = {
                    created: Date.now(),
                    data: data.results
                };
                sessionStorage.setItem(cacheKey, JSON.stringify(storageValue));
                this._onGeocodeSuccess(data.results);
            })
            .catch(error => console.error(error));
        // this is currently replaced with the above call, this might be good to replace with an action
        // this.state.geocoder.geocode({
        //     address: loc
        // }, this._onGeocodeSuccess);
    },

    _findMatchingCentresByName: function _findMatchingCentresByName(
        name,
        centres
    ) {
        const centreName = new RegExp(name, "gi");

        return centres.filter(centre =>
            centre.name.toLowerCase().match(centreName)
        );
    },

    _transformCentresToGoogleResponseFormat: function _transformCentresToGoogleResponseFormat(
        centres
    ) {
        return centres.map(centre => ({
            address_components: [],
            formatted_address: centre.name,
            geometry: {
                location: { lat: centre.lat, lng: centre.lon },
                location_type: "ROOFTOP",
                viewport: {}
            },
            place_id: null,
            types: []
        }));
    },

    _onGeocodeSuccess: function _onGeocodeSuccess(results) {
        //(results, status) {
        if (results && results.length) {
            // filter out non uk
            const ukResults = results.filter(result => {
                const countryComponent = result.address_components.find(
                    component => component.types.indexOf("country") !== -1
                );

                return countryComponent && countryComponent.short_name === "GB";
            });

            const centreName =
                this.props.location && this.props.location.formattedAddress;
            const matchingCentres = this._findMatchingCentresByName(
                centreName,
                this.state.centreList
            );
            const resultsWithMatchingCentres = [
                ...this._transformCentresToGoogleResponseFormat(
                    matchingCentres
                ),
                ...ukResults
            ];

            this.props.dispatch(
                actions.centreFinder.geolocResultsReceived(
                    resultsWithMatchingCentres
                )
            );

            this.setState({
                geoButton: false,
                noMatch: false
            });
        } else {
            this.setState({
                noMatch: true,
                matchesFound: false
            });
        }
    },

    _getUserLocation: function _getUserLocation(e) {
        e.preventDefault();

        var self = this,
            content = this.props.content;

        this.props.dispatch(
            actions.centreFinder.setButtonText(content.buttonFindingYou, 1)
        );

        this.setState({
            geoButton: true
        });

        navigator.geolocation.getCurrentPosition(function(data) {
            self.state.geocoder.geocode(
                {
                    location: {
                        lat: data.coords.latitude,
                        lng: data.coords.longitude
                    }
                },
                function(geodata) {
                    self._setLocation(
                        data.coords.latitude,
                        data.coords.longitude,
                        geodata[0].formatted_address
                    );

                    //parse address components for GA
                    var address = {};
                    var components = geodata[0].address_components;

                    for (var s = 0; s < components.length; s++) {
                        for (var t = 0; t < components[s].types.length; t++) {
                            address[components[s].types[t]] =
                                components[s].long_name;
                        }
                    }

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "use my location search",
                        route: address.route || "",
                        postal_town: address.postal_town || "",
                        neighborhood: address.neighborhood || "",
                        administrative_area_level_2:
                            address.administrative_area_level_2 || "",
                        postal_code: address.postal_code || ""
                    });
                }
            );
        });
    },

    _setLocation: function _setLocation(lat, lng, formattedAddress) {
        var content = this.props.content;

        this.props.dispatch(
            actions.centreFinder.setButtonText(content.buttonFindingCentre, 2)
        );

        this.props.dispatch(
            actions.centreFinder.setLocation(lat, lng, formattedAddress || "")
        );
        this.props.dispatch(
            actions.centreFinder.findCentres( { lat: lat, lng: lng, facilityTerm: this.props.facilityTerm })
        );
    },

    // Since suggestions are the result of an API fetch, we simply return
    //      them all to the autosuggest plugin.
    getSuggestions: function getSuggestions() {
        return this.props.results;
    },

    onChange: function onChange(event, data) {
        if (data.newValue !== undefined && data.newValue !== null) {
            this.props.dispatch(
                actions.centreFinder.setLocation(
                    this.props.location.lat,
                    this.props.location.lng,
                    data.newValue || ""
                )
            );
        }
    },

    _delayedFetchRequested: function _delayedFetchRequested(value) {
        if (value && value.length >= 3) {
            if ("uk" !== value.substr(-2).toLowerCase()) {
                value += ", UK";
            }

            this._geocode(value);
        } else {
            this.props.dispatch(actions.centreFinder.resetGeolocResults());

            this._onSuggestionsClearRequested();

            this.setState({
                noMatch: false,
                matchesFound: false
            });
        }
    },

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    _onSuggestionsFetchRequested: function _onSuggestionsFetchRequested(data) {
        this._delayedFetchRequested(data.value);
    },

    // Autosuggest will call this function every time you need to clear suggestions.
    _onSuggestionsClearRequested: function _onSuggestionsClearRequested() {
        this.props.dispatch(actions.centreFinder.resetGeolocResults());
    },

    // This function is called when suggestion is selected with tap/click, or 'enter'
    _onSuggestionSelected: function _onSuggestionSelected(event, data) {
        if (data.suggestionValue) {
            this._setLocation(
                data.suggestion.geometry.location.lat,
                data.suggestion.geometry.location.lng,
                data.suggestionValue
            );

            this.setState({
                noMatch: false,
                matchesFound: true
            });

            //parse address components for GA
            var address = {};
            var components = data.suggestion.address_components;

            for (var s = 0; s < components.length; s++) {
                for (var t = 0; t < components[s].types.length; t++) {
                    address[components[s].types[t]] = components[s].long_name;
                }
            }

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "location search",
                route: address.route || "",
                postal_town: address.postal_town || "",
                neighborhood: address.neighborhood || "",
                administrative_area_level_2:
                    address.administrative_area_level_2 || "",
                postal_code: address.postal_code || ""
            });
        }
    },

    render: function() {
        if (this.props.selected || !this.state.loaded) {
            return null;
        }

        var self = this,
            resultClass = "",
            moduleClass = "",
            buttonClass,
            buttonText,
            useLocation,
            content = this.props.content,
            lessons = this.props.lessons,
            legend = content.legend,
            lessonSelect,
            staffLogin = !!this.props.staffId;

        if (this.state.locationApi) {
            if (this.props.buttonText && this.state.geoButton) {
                buttonText = this.props.buttonText;

                if (this.props.geoStep !== 3) {
                    buttonClass = "centre-geoloc__location-button--active";
                } else {
                    buttonClass = "centre-geoloc__location-button--complete";
                    moduleClass = "module--inactive";
                }
            } else {
                buttonText = content.buttonUseMyLocation;
            }

            useLocation = (
                <Button
                    onClick={self._getUserLocation}
                    className={"centre-geoloc__location-button " + buttonClass}
                >
                    {buttonText}
                </Button>
            );
        }

        if (this.state.noMatch) {
            resultClass = "autosuggest__container--nomatch";
        }

        if (this.state.matchesFound) {
            resultClass = "autosuggest__container--matches";
            moduleClass = "module--inactive";
        }

        // When suggestion is clicked, Autosuggest needs to populate the input field
        // based on the clicked suggestion.
        function getSuggestionValue(suggestion) {
            return suggestion.formatted_address;
        }

        function renderSuggestion(suggestion) {
            return <span>{suggestion.formatted_address}</span>;
        }

        // Autosuggest will pass through all these props to the input field.
        var inputProps = {
                placeholder: content.locationInputPlaceholder,
                value: this.props.location.formattedAddress,
                onChange: this.onChange
            },
            theme = {
                container: "autosuggest__container " + resultClass,
                containerOpen: "autosuggest__container--open",
                input: "autosuggest__input",
                suggestionsContainer: "autosuggest__suggestions-container",
                suggestionsList: "autosuggest__suggestions-list",
                suggestion: "autosuggest__suggestion",
                suggestionFocused: "autosuggest__suggestion--focused",
                sectionContainer: "autosuggest__section-container",
                sectionTitle: "autosuggest__section-title"
            };

        var autosuggest = (
            <Autosuggest
                suggestions={this.props.geolocResults}
                onSuggestionsFetchRequested={this._onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this._onSuggestionsClearRequested}
                onSuggestionSelected={this._onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={theme}
            />
        );

        if (lessons && lessons.typesList && lessons.typesList.length) {
            var listOptions = [];

            for (var i = 0; i < lessons.typesList.length; i++) {
                if (
                    lessons.typesList[i].filter_visibility &&
                    (lessons.typesList[i].filter_visibility.includes(0) ||
                        (staffLogin &&
                            lessons.typesList[i].filter_visibility.includes(
                                1
                            )) ||
                        (!staffLogin &&
                            lessons.typesList[i].filter_visibility.includes(2)))
                ) {
                    listOptions.push({
                        value: lessons.typesList[i].id,
                        text: viewUtils.capitalizeFirstLetter(
                            lessons.typesList[i].name
                        )
                    });
                }
            }
            // reorder to put swimming ot top
            listOptions.sort(listOption => {
                return listOption.text.toLowerCase() === "swimming" ? -1 : 0;
            });

            lessonSelect = (
                <Input
                    className="personal-details__input"
                    input={{
                        onChange: this.onLessonSelect,
                        required: true
                    }}
                    label={{
                        text: content.lessons.typesList
                    }}
                    name={"lesson_select"}
                    type="select"
                    options={listOptions}
                    value={lessons && lessons.typeId}
                    valid={true}
                />
            );

            legend = content.lessonsLegend;
        }

        return (
            <div className={"module centre-geoloc " + moduleClass}>
                <h2 className="module__title">{content.title}</h2>
                <div className="centre-geoloc__form">
                    <legend>{legend}</legend>
                    {lessonSelect}

                    {(!this.props.lessons.inView ||
                        this.props.lessons.typeId) && (
                        <div>
                            {autosuggest}
                            {useLocation}
                            {this.props.children}
                        </div>
                    )}
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centresData: state.centreFinder,
        content: state.app.content.stage1.centreGeoloc,
        geolocResults: state.centreFinder.geolocResults,
        location: state.centreFinder.location,
        selected: state.centreFinder.selected,
        results: state.centreFinder.results,
        buttonText: state.centreFinder.buttonText,
        geoStep: state.centreFinder.geoStep,
        lessons: state.lessons,
        staffId: state.staffLogin.staffId,

        facilityTerm: state.centreFinder.lookupCentreByFacilityNameTerm,

        // GA
        products: hasSelections(state)
            ? castToUndefinedIfEmpty(
                  products(
                      state.centreFinder.selected
                          ? state.centreFinder.selected.info.name
                          : undefined
                  )(virtualPageTitle(state, "Membership"))(state)
              )
            : [
                  {
                      id: undefined,
                      name: virtualPageTitle(state, "Membership"),
                      brand: state.centreFinder.selected
                          ? state.centreFinder.selected.info.name
                          : undefined
                  }
              ]
    };
}

module.exports = connect(mapStateToProps)(CentreGeoloc);
