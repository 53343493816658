export function getCommonGroups(config) {
    const { extraSubGroups, addOnGroups, usersObjects, duration, durationType, groupFilter } = config;

    const subs = getMapOfSubsFromUsersObjects(usersObjects);
    const subsFiltered = keyBy(Object.values(subs).filter(sub =>
        sub.duration == duration && sub.durationType == durationType));
    if (!Object.values(subsFiltered).length) {
        return null; // not possible
    }

    const groupsAll = Object.values(subsFiltered).length > 1 ?
        getGroupsFromSubscriptions(subsFiltered) :
        Object.values(subsFiltered)[0].groups;
    const mapAddonsGroups = getMapAddons(groupsAll, addOnGroups);

    const groupsA = getAddonGroups(groupsAll, mapAddonsGroups);
    const groupsB = getAddonGroups(extraSubGroups, mapAddonsGroups);
    const intersection = arrayDiff(groupsA, groupsB);
    if (!groupFilter) {
        return intersection;
    }
    const filt = groupFilter.toLowerCase();
    return intersection.filter(group => group.toLowerCase().includes(filt));
}

export function getFeesTotal(config) {
    const { duration, durationType, periods } = config;
    const info = periods[durationType][duration];
    const { ranges } = info;
    const { now } = ranges;
    if (!now) return null;
    const { users } = now;
    const feesItems = Object.values(users)
        .map(user => Object.values(user).filter(item => {
            if (duration == 12 && durationType == 0 && !getExtra(item.id, periods)) {
                return false;
            }
            return item.amount;
        }))
        .reduce((sum, items) => [...sum, ...items]);
    const feesTotal = Object.values(feesItems)
        .reduce((sum, item) => (sum + item.amount), 0);
    return feesTotal;
}

function getGroupsFromSubscriptions(subscriptions) {
    return arrayUnique(Object.values(subscriptions).reduce((sum, sub) =>
        [...sum, ...sub.groups], []));
}

function getMapOfSubsFromUsersObjects(usersObjects) {
    const map = {};
    Object.values(usersObjects).forEach((user) => {
        Object.values(user.availableSubscriptions).forEach((sub) => {
            map[sub.id] = sub;
        });
    });
    return map;
}

function getAddonGroups(groups, mapAddons) {
    return arrayUnique(groups.filter(group => mapAddons[group])
        .reduce((sum, group) => [...sum, group, ...mapAddons[group]], []));
}

function getMapAddons(groups, addOnGroups) {
    return Object.values(groups).reduce((sum, group) => {
        const found = getGroupPair(group, addOnGroups);
        if (!found) {
            return sum;
        }
        if (!sum[found.groupId]) {
            sum[found.groupId] = [];
        }
        sum[found.groupId] = arrayUnique([...sum[found.groupId], ...found.addOnGroupIds]);
        found.addOnGroupIds.forEach(id => {
            if (!sum[id]) {
                sum[id] = [];
            }
            sum[id] = arrayUnique([...sum[id], found.groupId]);
        });
        return sum;
    }, {});
}

function getGroupPair(group, addOnGroups) {
    return Object.values(addOnGroups).find((groupCheck) =>
        groupCheck.groupId === group || groupCheck.addOnGroupIds.includes(group));
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function arrayUnique(input) {
    return input.filter(onlyUnique).sort();
}

function keyBy(list, key = 'id') {
    return Object.values(list).reduce((sum, item, i) =>
        ({ ...sum, [item[key] || i]: item }), {});
}

function arrayDiff(listA, listB) {
    return listA.filter(val => listB.includes(val))
}

function getExtra(id, periods) {
    let extra = null;
    Object.values(periods).forEach(valA => {
        Object.values(valA).forEach(valB => {
            if (!valB.extras.items[id]) return;
            extra = valB.extras.items[id].extra;
        });
    })
    return extra;
}
