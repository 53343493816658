var React           = require('react'),
    connect          = require('react-redux').connect,

    actions         = require('../../actions'),
    Input           = require('../common/forms/input'),
    DirectDebitForm;

DirectDebitForm = React.createClass({
    displayName: 'DirectDebitForm',

    componentDidMount: function() {
        if (this.props.user.directDebit && this.props.user.directDebit.account) {
            this.props.dispatch(actions.selections.checkMaskedBankField(this.props.index, 'holderName', this.props.user.directDebit.account.holderName));
            this.props.dispatch(actions.selections.checkMaskedBankField(this.props.index, 'sortCode', this.props.user.directDebit.account.sortCode));
            this.props.dispatch(actions.selections.checkMaskedBankField(this.props.index, 'accountNumber', this.props.user.directDebit.account.accountNumber));
        }
    },

    _onChangeInput: function _onChangeInput(userId, field, value) {
        this.props.dispatch(actions.selections.changeBankField(userId, field, value));
    },

    _onUseLeadBankDetails: function _onUseLeadBankDetails(uid, e) {
        this.props.dispatch(actions.selections.useLeadBankDetails(uid, e.target.checked));
    },

    render: function() {
        var user = this.props.user,
            index = this.props.index,
            userType = user.typeDesc,
            content = this.props.content,
            leadUser = this.props.leadUser,
            bankDetails = user.bankDetails.fields,
            firstName = user.info.firstName.value,
            fullName = firstName + ' ' + user.info.lastName.value,
            freeProfileForm = false,
            freeProfile = false,
            leadUserDirectDebit,
            leadFullName,
            leadUserType,
            secondaryInfo,
            extraInfo,
            ddForm,
            disabled = false,
            holderName,
            accountNumber,
            sortCode,
            holderNameValid,
            accountNumberValid,
            sortCodeValid,
            confirmHolderValid,
            confirmAuthValid,
            staffDisclaimer,
            staffDisclaimerValid,
            staffDisclaimerValue,
            holderNameValidityMessage;

        if(leadUser) {
            leadFullName = leadUser.info.firstName.value + ' ' + leadUser.info.lastName.value;
            leadUserDirectDebit = !leadUser.directDebit;
            leadUserType = leadUser.type;
        }

        // If we already have collections setup, they come from the logged in
        //  user, and they're not ammendable, so we disable the form.
        if (user.directDebit && user.directDebit.account) {
            disabled = true;
            holderName = user.directDebit.account.holderName;
            accountNumber = user.directDebit.account.accountNumber;
            sortCode = user.directDebit.account.sortCode;

            holderNameValid = true;
            accountNumberValid = true;
            sortCodeValid = true;
            confirmHolderValid = true;
            confirmAuthValid = true;

        } else {
            disabled = false;
            holderName = (bankDetails.holderName && bankDetails.holderName.value) || '';
            accountNumber = (bankDetails.accountNumber && bankDetails.accountNumber.value) || '';
            sortCode = (bankDetails.sortCode && bankDetails.sortCode.value) || '';

            holderNameValid = disabled || !bankDetails.holderName || (bankDetails.holderName && bankDetails.holderName.valid);
            accountNumberValid = disabled || !bankDetails.accountNumber || (bankDetails.accountNumber && bankDetails.accountNumber.valid);
            sortCodeValid = disabled || !bankDetails.sortCode || (bankDetails.sortCode && bankDetails.sortCode.valid);

            confirmHolderValid = disabled || !bankDetails.confirmAccountHolder || (bankDetails.confirmAccountHolder && bankDetails.confirmAccountHolder.valid);
            confirmAuthValid = disabled || !bankDetails.confirmAuth || (bankDetails.confirmAuth && bankDetails.confirmAuth.valid);
        }

        staffDisclaimerValue = (bankDetails.staffDisclaimer && bankDetails.staffDisclaimer.value) || '';
        staffDisclaimerValid = disabled || !bankDetails.staffDisclaimer || (bankDetails.staffDisclaimer && bankDetails.staffDisclaimer.valid);

        if (user.bankDetails.errors) {
            if (user.bankDetails.errors.isAlpha) {
                holderNameValidityMessage = 'Please only use characters a-z';
            } else {
                holderNameValidityMessage = 'Exceeds the 25 character limit';
            }
        }

        if(user.type.toLowerCase() === 'freeprofile') {
            freeProfile = true;

            if(Object.keys(user.extras).length) {
                freeProfileForm = true;
            } else {
                freeProfileForm = false;
            }
        }

        if (!user.lead && leadUserDirectDebit && !user.directDebit && leadUserType !== 'freeprofile') {
            if((freeProfile && freeProfileForm) || !freeProfile) {
                secondaryInfo = <div className='input personal-details__input--cb'>
                    <input className='forms__input--checkbox'
                        onChange={ this._onUseLeadBankDetails.bind(null, index) }
                        name={ 'use_lead_details_' + index }
                        checked={ user.useLeadBankDetails }
                        id={ 'use_lead_details_' + index }
                        key={ 'ddu' + index }
                        type='checkbox' />
                    <label htmlFor={ 'use_lead_details_' + index }><span></span>{ content.sameDetails.replace('{primaryFullName}', leadFullName) }</label>
                </div>;
            }
        } else {
            if(userType.toLowerCase() !== 'junior' ) {
                if((freeProfile && freeProfileForm) || !freeProfile) {
                    secondaryInfo = (<p>{ content.description }</p>);
                }
            }
        }

        if(userType.toLowerCase() === 'junior') {
            extraInfo = (<p>{ content.juniorDescription }</p>);
        } else if(freeProfile && !freeProfileForm) {
            extraInfo = (<p>{ content.freeProfileDescription }</p>);
        }

        if(user.lead || !user.useLeadBankDetails || user.directDebit || !leadUser) {
            if((freeProfile && freeProfileForm) || !freeProfile) {


                if(this.props.isStaff) {
                    staffDisclaimer = <div className='personal-details__staff-disclaimer'>
                        <span className='form__error'><strong className='form__error-title'>{ content.fields.staffDisclaimer.title }</strong>{ content.fields.staffDisclaimer.message }</span>

                        <Input className='personal-details__input--checkbox'
                            input={ {
                                onChange: this._onChangeInput.bind(null, index, 'staffDisclaimer'),
                                className:  'forms__input--checkbox'
                            } }
                            label={ {
                                text: content.fields.staffDisclaimer.label
                            } }
                            name={ 'radio_staffDisclaimer_' + index }
                            type='checkbox'
                            inputFirst={ true }
                            valid={ staffDisclaimerValid }
                            value={ staffDisclaimerValue }
                        />
                    </div>;
                }

                ddForm = (<div>
                    <form className='direct-debit__form'>
                        <fieldset>
                            <legend className='a11y'>{ content.legend }</legend>

                            <Input className='direct-debit__input'
                                input={ {
                                    onChange: this._onChangeInput.bind(null, index, 'holderName'),
                                    placeholder: content.fields.holderName.placeholder.replace('{name}', (bankDetails.holderName && bankDetails.holderName.value) || bankDetails.holderName),
                                    disabled: disabled,
                                    required: true
                                } }
                                label={ {
                                    text: content.fields.holderName.label
                                } }
                                disableHints={ disabled }
                                name={'holderName_' + index}
                                type='text'
                                valid={ holderNameValid }
                                value={ holderName }
                                validityMessage={ holderNameValidityMessage }
                            />

                            <Input
                                className='direct-debit__input'
                                input={ {
                                    onChange: this._onChangeInput.bind(null, index, 'accountNumber'),
                                    placeholder: content.fields.accountNumber.placeholder,
                                    disabled: disabled,
                                    required: true
                                } }
                                label={ {
                                    text: content.fields.accountNumber.label
                                } }
                                disableHints={ disabled }
                                name={'accountNumber_' + index}
                                type='bankAccount'
                                valid={ this.props.validBankDetails && accountNumberValid }
                                value={ accountNumber }
                            />

                            <Input
                                className='direct-debit__input'
                                input={ {
                                    onChange: this._onChangeInput.bind(null, index, 'sortCode'),
                                    placeholder: content.fields.accountSortCode.placeholder,
                                    disabled: disabled,
                                    required: true
                                } }
                                label={ {
                                    text: content.fields.accountSortCode.label
                                } }
                                disableHints={ disabled }
                                name={ 'sortCode_' + index}
                                type='sortCode'
                                valid={ this.props.validBankDetails && sortCodeValid }
                                value={ sortCode }
                            />

                            <Input className='direct-debit__input--cb'
                                input={ {
                                    onChange: this._onChangeInput.bind(null, index, 'confirmAccountHolder'),
                                    className:  'forms__input--checkbox',
                                    required: true,
                                    disabled: disabled
                                } }
                                label={ {
                                    text: content.fields.confirmAccountHolder
                                } }
                                name={ 'confirm_ah_' + index }
                                type='checkbox'
                                inputFirst={ true }
                                valid={ confirmHolderValid }
                                value={ bankDetails.confirmAccountHolder && bankDetails.confirmAccountHolder.value }
                            />

                            <Input className='direct-debit__input--cb'
                                input={ {
                                    onChange: this._onChangeInput.bind(null, index, 'confirmAuth'),
                                    className:  'forms__input--checkbox',
                                    disabled: disabled,
                                    required: true
                                } }
                                label={ {
                                    text: content.fields.confirmAuth
                                } }
                                name={ 'confirm_auth_' + index }
                                type='checkbox'
                                inputFirst={ true }
                                valid={ confirmAuthValid }
                                value={ bankDetails.confirmAuth && bankDetails.confirmAuth.value }
                            />
                        </fieldset>
                        <p className='personal-details__info'>{ content.paperDDText }</p>

                        { staffDisclaimer }
                    </form>
                </div>);
            }
        }

        return <div className='module module--inactive direct-debit__module'>
            <div className='direct-debit'>

                <div className='direct-debit__header'>
                    <h2>{ content.title.replace('{fullName}', fullName) }</h2>
                    { extraInfo }
                    { secondaryInfo }
                </div>
                { ddForm }
            </div>
        </div>;
    }
});

DirectDebitForm.propTypes = {
    content: React.PropTypes.object,
    index: React.PropTypes.number,
    user: React.PropTypes.object,
    primaryUser: React.PropTypes.object
};

module.exports = connect()(DirectDebitForm);
