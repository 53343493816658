const { has } = require('underscore');

var React           = require('react'),
    connect         = require('react-redux').connect,
    actions         = require('../../../actions'),
    Faqs            = require('../../faqs'),

    config          = require('../../../config'),
    Button          = require('../../common/button'),
    LessonTimes     = require('./lessonTimes'),

    panels          = require('../../stage1/membershipSelection/panels'),

    utils           = require('../../../utils/'),

    scrollToComponent = require('react-scroll-to-component'),

    selectLessonsIndex;

selectLessonsIndex = React.createClass({
    getInitialState: function() {
        return {
            showGuardianLogin: false,
            scrolledToNotice: false,
            lessons: this.props.lessons
        };
    },

    componentDidMount() {
        var lessons = document.getElementById('lessonSelect');
        scrollToComponent(lessons, {
            align: 'top',
            offset: -100
        });
    },

    componentWillReceiveProps(nextProps) {
        if(nextProps.lessons || this.props.lessons) {
            this.setState({
                lessons: nextProps.lessons
            });
        }

        //if all bookings have been removed, don't allow user to continue unless they make a new one
        if(!this.anyUserHasBooking() && this.props.hasSelectedMemberships) {
            this.props.dispatch(actions.memberships.resetSelection());
            this.props.dispatch(actions.stages.setStep(2));
            this.props.dispatch(actions.lessons.refreshTimer('clear'));
        }
    },

    componentDidUpdate() {
        if(this.anyUserHasBooking() && document.getElementById('reservedNotice') && !this.state.scrolledToNotice) {
            var notice = document.getElementById('reservedNotice');
            scrollToComponent(notice, {
                align: 'top',
                offset: -100
            });

            this.setState({
                scrolledToNotice: true
            });
        }
    },

    allUsersHaveBookings: function allUsersHaveBookings() {
        var users = this.props.users.objects;

        for(var i = 0; i < users.length; i++) {
            var u = users[i];

            if(u.lesson.selectedLessons && Object.keys(u.lesson.selectedLessons).length) {
                for(var l in u.lesson.selectedLessons) {
                    if(u.lesson.selectedLessons[l].level && !u.lesson.selectedLessons[l].bookingRef) {
                        return false;
                    }
                }
            }
        }
        return true;
    },

    anyUserHasBooking: function anyUserHasBooking() {
        var users = this.props.users.objects;

        for(var i = 0; i < users.length; i++) {
            var u = users[i];

            if(u.lesson.selectedLessons && Object.keys(u.lesson.selectedLessons).length) {
                for(var l in u.lesson.selectedLessons) {
                    if(u.lesson.selectedLessons[l].level && u.lesson.selectedLessons[l].bookingRef) {
                        return true;
                    }
                }
            }
        }
        return false;
    },

    allUsersHaveClasses: function allUsersHaveClasses(users, lessons) {
        for(var i = 0; i < users.length; i++) {
            var u = users[i];

            if(u.lesson.selectedLessons && Object.keys(u.lesson.selectedLessons).length) {
                for(var l in u.lesson.selectedLessons) {
                    var levelExists = lessons.levelInfo[u.lesson.selectedLessons[l].level] && lessons.levelInfo[u.lesson.selectedLessons[l].level].classes;

                    if(u.lesson.selectedLessons[l].level && !levelExists) {
                        return false;
                    }
                }
            }
        }
        return true;
    },

    isJuniorOnly: function isJuniorOnly() {
        var users = this.props.users,
        keys = Object.keys(users.count),
        userCount = Object.assign({}, users.count),
        hasJunior = false;
        hasOthers = false;


        for (var i = 0; i < keys.length; i++) {
            if(/junior/.test(keys[i].toLowerCase()) && userCount[keys[i]]) {
                hasJunior = true;
            }


            if (!/junior/.test(keys[i].toLowerCase()) && userCount[keys[i]]) {
                hasOthers = true;
                break;
            }
        }

        return hasJunior && !hasOthers;

    },

    next: function next(e) {
        e.preventDefault();

        var users = Object.assign({}, this.props.users.count);

        if (this.isJuniorOnly()) {
            users.freeprofile = 1;
        }  

        this.props.dispatch(actions.selections.setUsers(users, true));

        this.validateUsers();
    },

    validateUsers: function validateUsers() {
        var users = this.props.users.objects,
            lessons = this.props.lessons,
            promises = [];

        this.props.dispatch(actions.app.showLoader(true));

        for(var i = 0; i < users.length; i++) {
            if(!users[i].guardian && !lessons.timeoutStarted) {
                var user = users[i];

                if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                    for(var l in user.lesson.selectedLessons) {
                        var lesson = user.lesson.selectedLessons[l];

                        if(lesson.bookingRef && (user.availableSubscriptions && user.availableSubscriptions.length)) {
                            this.props.dispatch(actions.personalDetails.toggleUserLogin(i, true));
                            promises.push(actions.lessons.refreshReservation(lesson.bookingRef));

                        } else {
                            promises.push('error');
                        }
                    }
                }
            }
        }

        Promise.all(promises)
            .then(function (results) {
                var error = false;

                for(var x = 0; x < results.length; x++) {
                    if (results[x] === 'error') {
                        error = true;
                        break;
                    }
                }

                if(!error) {
                    if(!lessons.timeoutStarted) {
                        this.props.dispatch(actions.lessons.refreshTimer('start'));
                        this.props.dispatch(actions.app.start());
                    }

                    this.props.dispatch(actions.memberships.selectMemberships());
                    this.props.dispatch(actions.app.showLoader(false));
                    this.props.dispatch(actions.lessons.setError());
                    this.props.dispatch(actions.stages.next());
                } else {
                    this.props.dispatch(actions.app.showLoader(false));
                    this.props.dispatch(actions.lessons.setError('noSubs', 0));
                }
            }.bind(this));
    },

    render: function() {
        var buttonProps = {
                className: 'button ' + (this.state.showGuardianLogin ? 'button--disabled' : ''),
                disabled: this.state.showGuardianLogin,
                onClick: this.next
            },
            buttonText = this.props.content.main.buttonNext,
            users = this.props.users.objects,
            lessons = this.state.lessons,
            lessonType = lessons.isMulti && lessons.category ? lessons.category : utils.getLessonType(lessons),
            enquiriesChoices = config.services.lessons.enquiries[lessonType] || config.services.lessons.enquiries['default'],
            enquiryLink = config.services.lessons.urls.enquiry + enquiriesChoices + '&siteid=' + this.props.centre.info.site_id,
            errors = lessons.errors && lessons.errors[0] && lessons.errors[0]['noSubs'] ? true : false,
            staffLogin = !!this.props.staffId,
            errorBlock,
            extraOptions,
            actionButton,
            loader,
            notice,
            usersList = [];

        actionButton = <Button {...buttonProps}>{ buttonText }</Button>;

        loader = <div className='loader__wrapper'>
            <ul className="loader__spinner">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>;


        let selectedLessonType = this.props.lessons.centreTypesList.filter(lessonType => {
            return lessonType.id === this.props.lessons.typeId * 1;
        });

        // test if we have custom enquiry link and fall back to hard coded if not exists
        enquiryLink = selectedLessonType.length === 1 ? selectedLessonType[0].url_unsure : enquiryLink;
        for(var i = 0; i < users.length; i++) {
            var user = users[i];

            if(user.lesson.selectedLessons && Object.keys(user.lesson.selectedLessons).length) {
                for(var l in user.lesson.selectedLessons) {
                    var lesson = user.lesson.selectedLessons[l],
                        lessonTypeId;

                    if(lessons.levelInfo[l]) {
                        lessonTypeId = lessons.levelInfo[l].classes.lesson_type_id;

                        if(lessonTypeId) {
                            lessonType = utils.getLessonType(lessons, false, lessonTypeId);
                        }

                        usersList.push(<LessonTimes
                            index={ i }
                            key={'user_' + i + l}
                            enquiryLink={ enquiryLink }
                            classes={ lessons.levelInfo[l].classes }
                            lessonType={ lessonType }
                            lesson={ lesson }
                            links={ this.props.content.links } />);
                    }
                }
            }
        }

        if(this.anyUserHasBooking()) {
            notice = <div className='module-container lessons__notice--desktop' id='reservedNotice'>
                <span className='form__error form__error--lessons-select'>
                    { this.props.content.disclaimers.reserved }
                </span>
            </div>;
        }

        if(errors) {
            errorBlock = (<span className='form__error form__error--lessons'>
                { this.props.content.subsError }
            </span>);
        }



        if(this.allUsersHaveBookings()) {

            extraOptions = <div className='stage-options'>

                    {this.isJuniorOnly() ?
                        <div className='junior-panel__info'>{ this.props.juniorContent.info }</div>: null}

                    { actionButton }
                    { errorBlock }



                </div>;
        }

        return (<div id='lessonSelect'>
            { notice }
            { (this.allUsersHaveClasses(users, lessons) ? usersList : loader) }

            { extraOptions }

            <Faqs />
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.lessons.selectLessons,
        juniorContent: state.app.content.stage1.membershipSelection.panels.junior,
        hasSelectedMemberships: state.memberships.selected,
        lessons: state.lessons,
        users: state.selections.users,
        staffId: state.staffLogin.staffId
    };
}

module.exports = connect(mapStateToProps)(selectLessonsIndex);
