var React           = require('react'),
    connect         = require('react-redux').connect,

    config          = require('../../config'),
    actions         = require('../../actions'),
    UserModel       = require('../../models/user'),

    CentreFinder    = require('../stage1/centreFinder'),
    UserForm        = require('../stage3/userForm'),

    Faqs            = require('../faqs'),
    Button          = require('../common/button'),
    FooterSecurity  = require('../common/footerSecurity'),
    history         = require('../../utils/history'),

    scrollToComponent = require('react-scroll-to-component'),

    GymProfile;

const { confirmPathsMap } = require('../../utils/confirmPage');
let requiredFreeze = false;

GymProfile = React.createClass({
    displayName: 'GymProfile',


    getInitialState: function getInitialState() {
        return {
            isLive: false,
            nextCount: 0,
            valid: this._usersValid(this.props.users.objects),
            onlyJunior: false,
            validationErrors: [],
            showConcessionSelect: false
        };
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        if (this.props.frozen !== newProps.frozen || JSON.stringify(this.props.users.objects) !== JSON.stringify(newProps.users)) {
            var newValid = this._usersValid(newProps.users);

            if (newValid !== this.state.valid) {
                this.setState({
                    valid: newValid
                });
            }
        }
    },

    createParentUser: function createParentUser() {
        if (UserModel.onlyFreeJuniorProfile(this.props.users)) {
            //convert free user to junior user
            this.props.dispatch(actions.selections.convertToJuniorMember(this.props.users.objects[0]));
        }
        const users = Object.assign({}, this.props.users.count);
        users.freeprofile = 2;
        // Set new user totals.
        this.props.dispatch(actions.selections.setUsers(users, true));
        this.props.dispatch(actions.personalDetails.toggleUserLogin(1, true));
        this.setState({onlyJunior: false});
        // after setState
        setTimeout(scrollToFillableSection);
        function scrollToFillableSection() {
            const personalDetailsBlock = document.querySelectorAll('.personal-details, .personal-details__user');
            if (!personalDetailsBlock.length) {
                return; // nothing found
            }
            const elLast = personalDetailsBlock[personalDetailsBlock.length - 1];
            scrollToComponent(elLast, {
                align: 'top',
                offset: -100
            });
        }
    },

    _getSelectedFacilities: function _getSelectedFacilities(users) {

        var response = [];

        for (var i = 0; i < users.length; i++) {
            for (var key in users[i].facilities) {
                if (users[i].facilities[key].selected === 'on') {
                    response.push((this.props.centre.permittedFacilities[key].desc).trim());
                }
            }

            if (users[i].type === 'freeprofile') {
                response.push('Free Account');
            }
        }

        if (response.length === 1) {
            response = response[0];
        } else {
            response = response.sort();
        }

        return response;
    },


    _logoutUser: function logoutUser(userId) {

        var self = this;

        this.props.dispatch(actions.user.logout()).then(function(){
            self.props.dispatch(actions.selections.cleanUser(userId));
            self._onUnfreezeUser(userId, null);
            self._onUseLoginForm(userId, true);
            self.props.dispatch(actions.selections.validating(false));
        });
    },

    _onBlurInput: function _onBlurInput(uid, field, value) {
        var data = {
            info: {}
        };

        data.info[field] = value;

        this.props.dispatch(actions.selections.changeData(uid, data));
    },

    _onChangeInput: function _onChangeInput(uid, field, value) {
        var data = {
            info: {}
        };

        data.info[field] = value;

        this.props.dispatch(actions.selections.changeData(uid, data));
    },

    _onChangeCheckboxInput: function _onChangeCheckboxInput(uid, field, name, value) {
        var data = {
            info: {}
        };

        data.info[field] = data.info[field] || {};
        data.info[field][name] = value;

        this.props.dispatch(actions.selections.changeData(uid, data));
    },

    _onUnfreezeSection: function _onUnfreezeSection(uid, sectionId, e) {
        e.preventDefault();
        this.props.dispatch(actions.personalDetails.unfreeze(uid, sectionId));
    },

    _onUnfreezeUser: function _onUnfreezeUser(uid, e) {
        if (e) {
            e.preventDefault();
        }
        this.props.dispatch(actions.personalDetails.unfreezeUser(uid));
    },

    _onUseLoginForm: function _onUseLoginForm(uid, visible) {
        var t = this.props.useLogin[uid],
            user = this.props.users.objects[uid],
            useLoginForm;

        if (!user.isLoggedInUser && t === true) {
            useLoginForm = true;
            this.makeLive = true;
        } else {
            useLoginForm = false;
            this.makeLive = false;
        }

        if (visible !== useLoginForm) {
            this.props.dispatch(actions.personalDetails.toggleUserLogin(uid, visible));
        }
    },

    _onUseManualEntry: function _onUseManualEntry(uid, e) {
        e.preventDefault();
        this.props.dispatch(actions.personalDetails.useManualAddressEntry(uid));
    },

    _onUseLeadContact: function _onUseLeadContact(uid, e) {
        this.props.dispatch(actions.selections.useLeadContact(uid, e.target.checked));
    },

    _validateForm: function _validateForm(e) {
        e.preventDefault();
        var users = this.props.users.objects;

        this.props.dispatch(actions.selections.validating(true));

        this._validateUsers(users)
            .then(function (result) {
                if (result.valid) {
                    this._next();
                } else {

                    this.setState({
                        makeLive: true,
                        validationErrors: result.validationErrors,
                        valid: false
                    });

                    for(var i = 0; i < users.length; i++) {
                        var user = users[i];

                        if(!user.valid) {
                            this.props.dispatch(actions.personalDetails.unfreezeUser(i));
                        }
                    }

                    if (result.validationErrors) {
                        window.requestAnimationFrame(function() {
                            var invalidFields = document.querySelectorAll('.input--invalid');
                            scrollToComponent(invalidFields[0], {
                                align: 'top',
                                offset: -100
                            });
                        });
                    }

                    this.props.dispatch(actions.selections.validating(false));
                }
            }.bind(this));
    },

    _next: function _next() {
        var self = this,
            users = this.props.users.objects,
            siteId = this.props.centre.info.site_id;

        this.props.dispatch(actions.app.showLoader(true));

        if (
            UserModel.onlyJuniorProfile(this.props.users, siteId) ||
            (UserModel.onlyFreeJuniorProfile(this.props.users) && !UserModel.withAnAdult(this.props.users))
        ) {
            this.setState({onlyJunior: true});

            this.props.dispatch(actions.app.showLoader(false));
            this.props.dispatch(actions.selections.validating(false));
        } else {
            this.props.dispatch(actions.selections.checkUsers(function () {
                requiredFreeze = true;

                self.props.dispatch(actions.selections.postSelections(function () {
                    var userNumber = 0,
                        reportPayload = {
                            members: this.props.users.objects && this.props.users.objects.map(function (u) {
                                return {
                                    mrmId: u.info && u.info.mrmId && u.info.mrmId.value,
                                    lesson_session_id: u.lesson && u.lesson.session && u.lesson.session.mrm_id,
                                    subscriptions: (u.availableSubscriptions.length > 0 || Object.keys(u.extras).length) ? true : false
                                };
                            })
                        };

                    if(users[0] && users[0].guardian) {
                        userNumber = 1;
                    }

                    if(users[userNumber] && !users[userNumber].isLoggedInUser) {
                        reportPayload.status = 'success';

                        this.props.dispatch(
                            actions.payments.report(
                                { payload: reportPayload },
                                function() {
                                    history.push(
                                        confirmPathsMap["/gym-profile"]
                                    );
                                }
                            )
                        );
                    } else {
                        this.props.dispatch(actions.payments.report({ payload: reportPayload }, function() {
                            self.props.dispatch(actions.app.showLoader(true));
                            self.props.dispatch(actions.app.resetAll());

                            var params = self.serializeObj(self.props.bookingParams);
                            window.location = config.services.app.urls.booking + '?' + params;
                        }));
                    }
                    requiredFreeze = false;
                }.bind(self)));
            }));
        }
    },

    serializeObj: function serializeObj(obj) {
        var str = [];
        for(var p in obj) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
        return str.join('&');
    },


    _validateUsers: function _validateUsers(users) {
        const centre = this.props.centre;

        var promises = [],
            isStaff = this.props.staffId ? true : false,
            valid = true,
            errors = [],
            i;

        for (i = 0; i < users.length; i++) {
            const user = users[i];
            const validate = UserModel.validateDynamic({
                user,
                options: null,
                isStaff,
                centre
            });
            promises.push(validate);
        }

        return Promise.all(promises)
            .then(function (results) {
                for (i = 0; i < results.length; i++) {
                    if (!results[i].valid) {
                        errors[i] = results[i].errors,
                        valid = false;

                    } else {
                        errors[i] = {};
                    }
                }

                return {
                    validationErrors: errors,
                    valid:valid
                };
            });
    },

    _usersValid: function _usersValid(users) {
        var user,
            i;

        // If only one user is selected, we'll not display the
        //  name fields, to we consider it valid anyway.
        // Validation is only used to go to the next stage here.
        if (users.length === 1) {
            return true;
        }

        for (i = 0; i < users.length; i++) {
            user = users[i];

            // To be valid for this page, a user must have
            //  a valid firstName and lastName
            if (!user.info || !user.info.firstName || !user.info.firstName.valid || !user.info.lastName || !user.info.lastName.valid) {
                return false;
            }
        }

        return true;
    },


    _onClearFacilities: function _onClearFacilities() {
        this.props.dispatch(actions.centreFinder.clearFacilities());

        if(!this.props.centre) {
            this.props.dispatch(actions.centreFinder.wipeout());
        }
    },

    anyMarketingSelected: function anyMarketingSelected(user) {
        var fields = ['marketingGuest', 'marketingPartners', 'marketingCouncil'];

        for(var c = 0; c < fields.length; c++) {
            if(user.info && user.info[fields[c]] && user.info[fields[c]].value === 'true') {
                return true;
            }
        }

        return false;
    },

    // needs to return true if ANY login forms are showing
    anyLoginFormsAreShowing: function anyLoginFormsAreShowing(showForms) {
        var showLoginForm,
            frozen;

        for (var i = 0; i < this.props.users.objects.length; i++) {
            var user = this.props.users.objects[i];

            showLoginForm = !user.isLoggedInUser && this.props.useLogin[i];
            frozen = ((this.props.frozen || this.props.frozenUsers[i]) && this.props.frozenUsers[i] !== false) || (!this.props.frozen && this.props.frozenUsers[i] === true);

            if(showLoginForm && !frozen) {
                return true;
            }
        }

        return false;
    },

    _onChooseConcession: function _onChooseConcession(check) {
        this.setState({
            showConcessionSelect: check
        });
    },

    render: function() {
        var self = this,
            bookingContent = this.props.bookingContent,
            disabled = this.props.validating || this.anyLoginFormsAreShowing(),
            buttonText = bookingContent.buttonText,
            extraOptions,

            leadUser = this.props.users.objects.find(function(u) { return u.lead; }),
            freeUserOnly = UserModel.onlyFreeProfile(this.props.users),
            existingUsers = [],
            sectionsNodes = [],
            buttonProps = {},
            sections = {},

            existingUsersError,
            firstName,
            lastName,
            userName,
            userForm,
            typeDesc,
            idInType,
            section,
            loader,
            user,
            key,
            i,
            roUser = !this.props.users.objects.find(u => u.typeDesc === 'Junior');

        if((this.props.frozen || this.props.checkedProfile) && !this.anyLoginFormsAreShowing()) {
            buttonProps = {
                className: 'button extras__next-button',
                disabled: false,
                onClick: this._validateForm
            };
        } else {
            buttonProps = {
                className: 'button extras__next-button' + (disabled ? ' button--disabled' : null),
                disabled: disabled,
                onClick: this._validateForm
            };
        }

        if (this.props.centre && this.props.nbUsers) {
            for (i = 0; i < this.props.users.objects.length; i++) {
                userForm = null;
                user = this.props.users.objects[i];

                sections[user.type] = sections[user.type] || {
                    type: user.type,
                    typeDesc: user.typeDesc,
                    users: []
                };

                typeDesc = user.typeDesc;
                idInType = user.idInType + 1;
                firstName = (user.info.firstName && user.info.firstName.value);
                lastName = (user.info.lastName && user.info.lastName.value);

                if(firstName && lastName) {
                    userName = firstName + ' ' + lastName;
                } else {
                    userName = typeDesc;
                }

                if (this.props.existingUsers[i]) {
                    existingUsers.push(userName);
                }

                key = 'personal-details_user_' + i;

                userForm = <UserForm
                    addressSuggestions={ (this.props.addressSuggestions && this.props.addressSuggestions[i]) || [] }
                    centre={ this.props.centre }
                    contentForm={ this.props.stage3Content.userForm }
                    contentLoginForm={ this.props.stage3Content.userLoginForm }
                    contentToggle={ this.props.stage3Content.toggle }
                    duration={ this.props.d }
                    durationType={ this.props.dt }
                    frozen={ ((this.props.frozen || this.props.frozenUsers[i]) && this.props.frozenUsers[i] !== false) || (!this.props.frozen && this.props.frozenUsers[i] === true) || requiredFreeze}
                    index={ i }
                    isStaff={ this.props.staffId ? true : false }
                    isBooking={ this.props.isBooking }
                    key={ key }
                    leadUser={ leadUser }
                    logoutUser={ this._logoutUser }
                    onBlurInput={ this._onBlurInput.bind(null, i) }
                    onChangeInput={ this._onChangeInput.bind(null, i) }
                    onChangeCheckboxInput={ this._onChangeCheckboxInput.bind(null, i) }
                    onChooseConcession={ this._onChooseConcession }
                    onUnfreeze={ this._onUnfreezeUser.bind(null, i) }
                    onUnfreezeSection={ this._onUnfreezeSection.bind(null, i) }
                    onUseLeadContact={ this._onUseLeadContact }
                    onUseLoginForm={ this._onUseLoginForm }
                    onUseManualEntry={ this._onUseManualEntry.bind(null, i) }
                    showMarketingChannels={ this.anyMarketingSelected(user) }
                    showConcessionSelect={ this.state.showConcessionSelect }
                    showFullForm={ !config.disabledFeatures.remoteValidationEnabled || this.props.showFullFormUIDs[i] || this.props.manualAddressUsers[i] }
                    staffLogin={ this.props.staffId || null }
                    titles={ this.props.centre.titles }
                    unfrozenSections={ this.props.unfrozenSections[i] }
                    useLeadAddress={ user.useLeadAddress }
                    useLeadEmmergencyDetails={ false }
                    useLogin={ this.props.useLogin[i] }
                    user={ user }
                    userFirstName={ firstName }
                    userName={ userName }
                    validationErrors={ this.state.validationErrors[i] || {} }
                    roUser={roUser}
                />;

                sections[user.type].users.push(<div className='personal-details__user' key={ 'u_' + i}>
                    { userForm }
                </div>);
            }

            for (key in sections) {
                section = sections[key];

                sectionsNodes.push(<section key={ 's_' + section.type } className='personal-details__section'>
                    { section.users }
                </section>);
            }

            if (existingUsers.length) {
                existingUsersError = <div className='form__error-zone'>
                    <span className='personal-details__error'>{ existingUsers.length === 1 ? (this.props.stage3Content.errors.userAlreadyExists.singleUser.replace('{users}', existingUsers[0])) : (this.props.stage3Content.errors.userAlreadyExists.singleUser.replace('{users}', existingUsers.join(', '))) }</span>
                </div>;
            } else if ( this.state.onlyJunior ) {
                var juniorButton = <a href='#' className='link' onClick={ this.createParentUser }>click here</a>;

                existingUsersError = <div className='form__error-zone'>
                    <span className='personal-details__error'>{ this.props.stage3Content.errors.onlyJuniorProfile } { juniorButton }.</span>
                </div>;
            }

            if (this.props.validating) {
                loader = <ul style={ { display: 'block' } } className='loader__spinner loader__spinner--small simple-payment__loader'>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>;
            }

            userForm = <div className='personal-details'>
                { sectionsNodes }

                <div className='stage-options'>
                    <Button { ...buttonProps }>{ buttonText }</Button>
                    { loader }
                    { existingUsersError }
                </div>
            </div>;
        }

        return (<div className='container'>
            <CentreFinder
                isBooking={ this.props.isBooking }
                isGymProfile={ this.props.isGymProfile }
                onClearFacilities={ this._onClearFacilities }/>
            { userForm }
            { extraOptions }

            <Faqs />
        </div>);
    }
});

function mapStateToProps(state) {
    return {
        addressSuggestions: state.personalDetails.addressSuggestions,
        allUsersHaveSelections: state.selections.allUsersHaveSelections,
        checkedProfile: state.personalDetails.checkedProfile,
        centre: state.centreFinder.selected,
        bookingContent: state.app.content.bookingProfile.content,
        bookingParams: state.booking.urlParams,
        stage3Content: state.app.content.stage3,
        discounts: state.discounts,
        d: state.selections.duration,
        dt: state.selections.durationType,
        existingUsers: state.selections.existingUsers,
        extras: state.selections.extras,
        frozenUsers: state.personalDetails.frozenUsers,
        frozen: state.memberships.frozen,
        isBooking: state.booking.isBooking,
        isGymProfile: state.booking.isGymProfile,
        manualAddressUsers: state.personalDetails.manualAddressUsers,
        membershipSelected: state.memberships.selected,
        nbUsers: state.selections.users.total,
        nextCount: state.nextCount,
        showFullFormUIDs: state.personalDetails.showFullFormUIDs,
        subscriptionsAvailable: state.selections.subscriptionsAvailable,
        staffId: state.staffLogin.staffId,
        unfrozenSections: state.personalDetails.unfrozenSections,
        useLogin: state.personalDetails.useLogin,
        users: state.selections.users,
        validating: state.selections.validating
    };
}

module.exports = connect(mapStateToProps)(GymProfile);
