var React = require("react"),
    classnames = require("classnames"),
    FindDiscountCompany = require("../findDiscountCompany"),
    actions = require("../../actions"),
    connect = require("react-redux").connect,
    Input = require("../common/forms/input"),
    DiscountCodes;

const state = {
    appliedPromo: false
};

DiscountCodes = React.createClass({
    displayName: 'DiscountCodes',

    getInitialState: function getInitialState() {
        return { ...state };
    },

    componentDidMount: function () {
        if (this.props.selections.promo) {
            this.props.dispatch(actions.discountCodes.setApplyDisabled(true));
        }

        if (this.corporateDiscountApplied()) {
            this.props.dispatch(actions.discountCodes.toggleDiscountType(true));
        }

        if (
            !this.props.selections.users.objects[0].promoCode.code &&
            this.props.selections.userDiscountPromo
        ) {
            this.props.dispatch(
                actions.selections.setUserPromoCode({
                    code: this.props.selections.userDiscountPromo,
                    userId: 0,
                    preservePromo: true
                })
            );

            this.applyDiscount();

            document.querySelectorAll(
                ".discount-codes__user input"
            )[0].value = this.props.selections.userDiscountPromo;

            this.toggleExpanded(true);
        }

        this.toggleExpanded(true);

        this.maybeApplyPromoFromDeepLink();
    },

    maybeApplyPromoFromDeepLink: function maybeApplyPromoFromDeepLink(delay = 250) {
        const selections = this.props.selections;
        const promo = selections.promo;
        if (!promo || state.appliedPromo || selections.durationType !== 1) return;
        const users = this.props.users;
        // added this so that it doesn't fire twice
        state.appliedPromo = true;
        this.setState(state);
        setTimeout(applyPromo.bind(this), delay);

        function applyPromo() {
            users.forEach((user, i) => {
                setTimeout(_ => {
                    this.onChangeUserpromoCode(promo, i);
                }, i * delay);
            });
            setTimeout(_ => {
                this.applyDiscount().then(_ => {
                    const el = document.querySelector('.discount-codes__user input');
                    if (!el) return;
                    el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                });
            }, users.length * delay);
        }
    },

    applyDiscount: function() {
        return this.props.dispatch(
            actions.discountCodes.applyDiscount(this.props.content)
        );
    },

    onChangeUserpromoCode: function onChangeUserpromoCode(code, userId) {
        // @todo this is not how we should be handling a "removal" of something; a.k.a hacky workaround
        if (!code) {
            this.props.dispatch(actions.selections.setIsDiscountApplied(null));

            this.props.dispatch(
                actions.selections.setUserPromoCode( { code, userId } )
            );

            return;
        }

        document.querySelectorAll(".discount-codes__user input")[
            userId
        ].value = code; // nasty hack

        this.props.dispatch(
            actions.selections.setUserPromoCode({ code, userId })
        );
    },

    toggleDiscountType: function() {
        this.props.dispatch(actions.discountCodes.toggleDiscountType());
    },

    toggleExpanded: function(isExpanded) {
        this.props.dispatch(actions.discountCodes.toggleExpanded(isExpanded));
    },

    setCorporateDiscount: function(discount) {
        this.props.dispatch(
            actions.discountCodes.setCorporateDiscount(discount)
        );
    },

    setUserPromoCode: function(code, userId) {
        this.props.dispatch(
            actions.selections.setUserPromoCode({ code, userId })
        );
    },

    setApplyDisabled: function(disabled) {
        this.props.dispatch(actions.discountCodes.setApplyDisabled(disabled));
    },

    getUserPromoCode: function(userId) {
        var promoCode = this.props.users[userId].promoCode;

        return {
            ...promoCode,
            code: promoCode.code || "",
            isValid: promoCode.isValid !== undefined ? promoCode.isValid : null
        };
    },

    generateCssClasses: function generateCssClasses() {
        var { isPromoCode, isCorporateDiscount } = this.props;

        this.expandClasses = classnames("button--left", "button__more-info", {
            "button__more-info--expanded": !this.props.isExpanded
        });

        this.toggleClasses = classnames("toggle__slider", {
            "toggle__slider--one": isPromoCode,
            "toggle__slider--two": isCorporateDiscount
        });

        this.collapsibleClasses = classnames("discount-codes__collapsible", {
            "discount-codes__collapsible--collapsed": !this.props.isExpanded
        });

        this.togglePromoCodeClasses = classnames(
            "discount-codes__toggle__label",
            {
                "discount-codes__toggle__label--selected": isPromoCode
            }
        );

        this.toggleCorporateDiscountClasses = classnames(
            "discount-codes__toggle__label",
            {
                "discount-codes__toggle__label--selected": isCorporateDiscount
            }
        );

        this.promoBlockClasses = classnames(
            "module",
            "module--inactive",
            "discount-codes__promo",
            {
                "discount-codes--show": !isCorporateDiscount,
                "discount-codes--hide": isCorporateDiscount
            }
        );

        this.corporateBlockClasses = classnames(
            "module",
            "module--inactive",
            "discount-codes__corporate",
            {
                "discount-codes--show": isCorporateDiscount,
                "discount-codes--hide": !isCorporateDiscount
            }
        );

        this.applyButtonClasses = classnames("button", {
            "button--disabled": this.props.applyDisabled
        });
    },

    promoCodeApplied: function() {
        return (
            this.props.selections.isDiscountApplied &&
            this.props.selections.discount &&
            this.props.selections.discount.type === "code"
        );
    },

    corporateDiscountApplied: function() {
        return (
            this.props.selections.isDiscountApplied &&
            this.props.selections.discount &&
            this.props.selections.discount.type === "corporate"
        );
    },

    isDiscountApplied: function() {
        return this.props.selections.isDiscountApplied;
    },

    render: function() {
        var enquiryUrl =
                config.services.app.urls.enquiries +
                (this.props.centre &&
                this.props.centre.info &&
                this.props.centre.info.site_id
                    ? "&siteid=" + this.props.centre.info.site_id
                    : ""),
            content = this.props.content,
            hideCorporateSwitch = this.props.hideCorporateSwitch;

        this.generateCssClasses();

        return (
            <div className="module-container discount-codes">
                <div className="module module--inactive">
                    <h2>
                        {hideCorporateSwitch
                            ? content.titleNoCorporate
                            : content.title}
                    </h2>

                    {/* <button
                        className={this.expandClasses}
                        onClick={() => this.toggleExpanded()}
                    >
                        {content.expandText}
                    </button> */}
                </div>

                <div className={this.collapsibleClasses}>
                    {!hideCorporateSwitch && (
                        <div className="module module--inactive">
                            <div className="discount-codes__toggle">
                                <div className={this.togglePromoCodeClasses}>
                                    {content.togglePromotionCode}
                                </div>
                                <div
                                    className="toggle__buttons"
                                    onClick={() => this.toggleDiscountType()}
                                >
                                    <span className="toggle__label" />
                                    <div className={this.toggleClasses} />
                                </div>
                                <div
                                    className={
                                        this.toggleCorporateDiscountClasses
                                    }
                                >
                                    {content.toggleCorporateDiscount}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={this.promoBlockClasses}>
                        <div className="personal-details__input input--hints">
                            {this.props.users.map((user, i) => (
                                <div className="discount-codes__user" key={i}>
                                    <Input
                                        type="text"
                                        autocompleteOff
                                        input={{
                                            placeholder:
                                                "Enter promotional code",
                                            onChange: value =>
                                                this.onChangeUserpromoCode(
                                                    value,
                                                    i
                                                )
                                        }}
                                        value={this.getUserPromoCode(i).code}
                                        label={{
                                            text:
                                                user.info.firstName.value +
                                                " " +
                                                user.info.lastName.value +
                                                " (" +
                                                user.typeDesc +
                                                ")"
                                        }}
                                        minLength={3}
                                        maxLength={20}
                                        valid={this.getUserPromoCode(i).isValid}
                                        validityMessage={
                                            this.getUserPromoCode(i).errorMsg ||
                                            content.errorCodeInSession
                                        }
                                    />

                                    <div className="discount-codes--text-right">
                                        <a
                                            className={classnames(
                                                "discount-codes__remove-discount",
                                                {
                                                    hidden: !this.getUserPromoCode(
                                                        i
                                                    ).code
                                                }
                                            )}
                                            href={null}
                                            onChange={(e) => this.onChangeUserpromoCode(e.target.value, i)}
                                            onClick={(e) => this.onChangeUserpromoCode('', i)}
                                            >
                                            {content.removeCodeLink}
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={this.corporateBlockClasses}>
                        <div className="personal-details__input input--hints">
                            <p className="discount-codes__info">
                                {content.corporateDiscountInfo}
                            </p>

                            <div className="discount-codes__corporate__flex">
                                <div className="discount-codes__input">
                                    <label
                                        className=""
                                        htmlFor="details_address_locality_0"
                                    >
                                        {content.corporateDiscountFindCompany}
                                    </label>

                                    <FindDiscountCompany
                                        onChange={discount =>
                                            this.setCorporateDiscount(discount)
                                        }
                                        autoFocus={ false }
                                        inputFocused={ false }
                                    />

                                    <span className="form__input-validity-icon" />
                                </div>

                                <div className="">
                                    <p>{content.corporateDiscountCantFind}</p>
                                    <a
                                        className="discount-codes__link new-window"
                                        href={enquiryUrl}
                                    >
                                        {content.corporateDiscountContactLink}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="module module--inactive discount-codes__actions">
                        <button
                            disabled={this.props.applyDisabled}
                            className={this.applyButtonClasses}
                            onClick={() => this.applyDiscount()}
                        >
                            {content.buttonApplyDiscount}
                        </button>
                        {this.isDiscountApplied() === true && (
                            <p className="discount-codes__discount-applied">
                                {content.discountApplied}
                            </p>
                        )}
                        {this.isDiscountApplied() === false && (
                            <p className="discount-codes__discount-not-applied">
                                {content.discountNotApplied}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
});

DiscountCodes.propTypes = {
    content: React.PropTypes.object,
    discounts: React.PropTypes.object,
    users: React.PropTypes.array,
    isPromoCode: React.PropTypes.bool,
    isCorporateDiscount: React.PropTypes.bool,
    promoCode: React.PropTypes.string,
    selections: React.PropTypes.object
};

function mapStateToProps(state) {
    return {
        ...state.discountCodes,
        users: state.selections.users.objects,
        centre: state.centreFinder.selected,
        selections: state.selections
    };
}

module.exports = connect(mapStateToProps)(DiscountCodes);
