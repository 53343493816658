var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions");

var InCentrePayment = React.createClass({
    displayName: 'InCentrePayment',

    getInitialState: function() {
        return {
            error: false
        };
    },

    _redirectIfPropsNotValid: function _redirectIfPropsNotValid(props) {
        if (!props.userTotal) {
            props.dispatch(actions.stages.goHome());
            props.dispatch(actions.stages.setStep(0));
        }
    },

    componentWillMount: function componentWillMount() {
        this._redirectIfPropsNotValid(this.props);
        this._maybeProceedAutomatically();
    },

    componentWillUpdate: function componentWillUpdate(nextProps) {
        this._redirectIfPropsNotValid(nextProps);
    },

    componentDidMount: function componentDidMount() {
        window.scrollTo(0, 0);
    },

    _completePaymentAtTill: function _completePaymentAtTill() {
        this.props.dispatch(
            actions.directDebitPage.completePaymentAtTill(true)
        );
    },

    _paymentCancelConfirm: function _paymentCancelConfirm() {
        this.props.dispatch(actions.directDebitPage.paymentCancelConfirm());
    },

    _maybeProceedAutomatically: function _maybeProceedAutomatically() {
        const { props } = this;
        const { settings = {}, bankDetails = {}, payments = {},  isStaff = false, } = props;
        const total = this._getTotalCost();
        const fail = (
            total ||
            !bankDetails.paymentUrl ||
            !bankDetails.valid ||
            !isStaff ||
            payments.error ||
            settings.in_centre_payments !== 'on'
        );
        return fail ? null : this._next();
    },

    _getTotalCost: function _getTotalCost() {
        const { props } = this;
        const { selectionsPricingPeriods: periods = {} } = props;
        let total = 0;
        Object.values(periods).forEach((itemOut = {}) => {
            Object.values(itemOut).forEach((itemIn = {}) => {
                total += itemIn.total || 0;
            });
        });
        return total;
    },

    _next: function _next(e) {
        if (this.props.bankDetails.paymentUrl) {
            this.props.dispatch(actions.app.showLoader(true));
            if (!(e || {}).target) {
                window.location = this.props.bankDetails.paymentUrl;
            }
        } else {
            this.setState({ error: true });
        }
    },

    render: function() {
        var content = this.props.content;

        return (
            <div>
                {this.props.settings.in_centre_payments && (
                    <div className="direct-debit-page__in-centre">
                        <div className="direct-debit-page__in-centre__taking-online">
                            <div className="direct-debit-page__in-centre__wp-logo"></div>
                            <h2>{content.inCentre.paymentOnline.title}</h2>
                            <a href={this.props.bankDetails.paymentUrl} className="button" onClick={this._next}>
                                {content.inCentre.paymentOnline.continueButton}
                            </a>
                        </div>
                        <div className="direct-debit-page__in-centre__completeAtTill">
                            <h3>
                                {content.inCentre.completePaymentAtTill.title}
                            </h3>
                            <p>{content.inCentre.completePaymentAtTill.text}</p>
                            <h4>
                                <a
                                    onClick={() =>
                                        this._completePaymentAtTill()
                                    }
                                >
                                    {
                                        content.inCentre.completePaymentAtTill
                                            .link
                                    }
                                </a>
                            </h4>
                        </div>
                        <div className="direct-debit-page__in-centre__cancelled">
                            <h3>{content.inCentre.cancelled.title}</h3>
                            <p>{content.inCentre.cancelled.text}</p>
                            <h4>
                                <a onClick={() => this._paymentCancelConfirm()}>
                                    {content.inCentre.cancelled.link}
                                </a>
                            </h4>
                        </div>
                    </div>
                )}
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        bankDetails: state.bankDetails,
        content: state.app.content.directDebitPage,
        settings: state.app.settings,
        payments: state.payments,
        userTotal: state.selections.users.total,
        isStaff: !!state.staffLogin.staffId,
        selectionsPricingPeriods: state.selections.pricing.periods
    };
}

module.exports = connect(mapStateToProps)(InCentrePayment);
