var React           = require('react'),
    connect         = require('react-redux').connect,
    moment          = require('moment'),

    actions         = require('../../../actions'),

    Button          = require('../../common/button'),
    Icons           = require('../../common/icons'),

    utils           = require('../../../utils/'),
    viewUtils       = require('../../../utils/viewUtils'),

    LessonTimes;

LessonTimes = React.createClass({

    getInitialState: function() {
        return {
            expanded: null
        };
    },

    handleMoreInfoClick: function handleMoreInfoClick(key, e) {
        if(e) {
            e.preventDefault();
        }

        var oldElet = document.querySelector('.lessons__day__time-details--' + this.state.expanded),
            elt = document.querySelector('.lessons__day__time-details--' + key);

        if (this.state.expanded && key === this.state.expanded) {
            this.setState({ expanded: null });
            oldElet && viewUtils.furl(oldElet);
        } else{
            this.setState({ expanded: key });
            oldElet && viewUtils.furl(oldElet);
            elt && viewUtils.furl(elt);
        }
    },

    /**
     * Generates lambda to generate CTAs per location based on most spaces (twin courses etc)     * 
     * @param {string} locationName Where is the course located
     * @param {integer} availability Spaces available
     * @returns {object}
     */
    selectCourseButton ( day ) {
        let cache = day.times
            // Extract location names available
            .flatMap( day => day.available_sessions
                .map( availableSession => availableSession.name )
            )

            // Reduce into dictionary for unique locations to determine availability
            .reduce( ( accumlate, locationName ) => {
                return {
                    ...accumlate,
                    [ locationName ]: {
                        spaces: -1,
                        ctaButton: null
                    }
                };
            }, {} );

        return {
            /**
             * Given a location name and the number of spaces, determine which CTA to use
             * The result of CTA VDOM and spaces are cached in key-value pair above
             * @param {string}
             * @param {integer}
             */
            determineCourseSelectability: ( locationName, spaces, ctaVDom ) => {
                // If the number of spaces for this availability is greater or equal to the cache, use this one
                // This handles cases of zero
                if ( spaces >= cache[ locationName ].spaces ) {
                    cache[locationName].spaces = spaces; // Mutate spaces
                    return cache[ locationName ].ctaButton = ctaVDom;
                }

                // Otherwise, use cache VDom
                return cache[ locationName ].ctaButton;
            },

            /**
             * Fetches VDOM buttons from cache
             * @returns {Array}
             */
            getButtons: () => {
                return Object.values(cache).flatMap(location => location.ctaButton);
            }
        };
    },

    listTimes: function listTimes() {
        var index = this.props.index,
            content = this.props.content,
            lessons = this.props.lessons,
            lesson = this.props.lesson,
            user = this.props.users.objects[index],
            classes = this.props.classes,
            times = classes.times,
            activity = lesson.activity,
            session = lesson.session,
            errors = lessons.errors && lessons.errors[index] ? lessons.errors[index] : null,
            color = lessons.levelInfo[lesson.level].color,
            daysList = [];

        if(!session) {
            for(var i = 0; i < times.length; i++) {
                var day = times[i],
                    dayTimes = [],
                    dayIndex = index + '' + i;

                if (!day.times.length || day.times.length === 0) {
                    dayTimes.push(<li key='day_0'>
                            <p className='lessons__day__empty'>{ content.isEmptyDay }</p>
                        </li>);
                } else {
                    for(var x = 0; x < day.times.length; x++) {
                        var lessonClass = day.times[x],
                            sessions = [],
                            action,
                            lastIndex,
                            sessionAmount = 0,
                            timeIndex = index + '' + lesson.level + '' + i + '' + x,
                            errorBlock = null,
                            expanded = timeIndex === this.state.expanded;

                        // Generate actioner button against available sessions
                        const selectableCourse = this.selectCourseButton( day );

                        // Iterate over available sessions in order to generate the appropiate
                        // CTAs
                        for(var s = 0; s < lessonClass.available_sessions.length; s++) {
                            let sessionIndex = index + '-' + i + '-' + x + '-' + s;
                            var full = lessonClass.available_sessions[s].availability === 0,
                                buttonProps = {
                                    className: 'button '  + (full ? ' button--disabled' : ''),
                                    onClick: this.onSelectSession.bind(null, lessonClass, lessonClass.available_sessions[s], index, sessionIndex, lesson.level),
                                    disabled: full
                                };

                            if(errors && errors[sessionIndex]) {
                                let errorCode = errors.errorCode ? errors.errorCode : 0;
                                if (this.props.isStaff) {
                                    errorBlock = <span className='form__error form__error--lessons'> { content.reservationError[errorCode].staff } </span>;
                                } else {
                                    errorBlock = <span className='form__error form__error--lessons'> { content.reservationError[errorCode].user } </span>;
                                }
                            }

                            // Generates actionable button for this course id
                            // @see selectCourseButton
                            selectableCourse.determineCourseSelectability(
                                lessonClass.available_sessions[s].name,
                                lessonClass.available_sessions[ s ].availability,
                                <li key={ 'session_' + lastIndex } className='lesson__day__session'>
                                    <span className='lesson__day__session__label'>Location:</span> { lessonClass.available_sessions[ s ].name }
                                    <Button { ...buttonProps } data-courseid={ lessonClass.available_sessions[ s ].mrm_id }>{ ( full ? content.fullSession : content.selectSession ) }</Button>
                                </li>
                            );
                        }

                        if ( lessonClass.is_full ) {
                            action = ( <span>FULL</span> );
                        } else if ( lessonClass.unavailable ) {
                            action = ( <span>UNAVAILABLE</span> );
                        } else {
                            action = ( <Button onClick={ this.handleMoreInfoClick.bind( null, timeIndex ) }>{ ( expanded ? content.hide : content.viewMore ) }</Button> );
                        }

                        dayTimes.push( <li key={'day_' + x} className={'lessons__day__time' + ( lessonClass.is_full || lessonClass.unavailable ? ' lessons__day__time--full' : '' )}>
                            <div className={'lessons__day__time-header ' + ( expanded ? 'lessons__day__time-header--expanded' : '' )}
                                style={{ border: ( lessonClass.is_full || lessonClass.unavailable ? '' : '1px solid ' + color ) }}>
                                <span className='lessons__day__clock' style={{ background: ( lessonClass.is_full || lessonClass.unavailable ? '' : color ) }}>
                                    <span className='lessons__day__icon'></span>{lessonClass.time}
                                </span>
                                <span className='lessons__day__button'>{action}</span>
                            </div>
                            <div className={'lessons__day__time-details lessons__day__time-details--' + timeIndex}>
                                <div className='inner'>
                                    <p dangerouslySetInnerHTML={{ __html: classes.description }}></p>
                                    <ul className={( sessionAmount > 1 ? 'multiple-sessions' : '' )}>
                                        <li><span>First session:</span> {moment( lessonClass.first_session_date ).format( 'DD MMM YYYY' )}</li>
                                        <li><span>Duration:</span> {content.duration.replace( '[duration]', lessonClass.duration )}</li>
                                        <li className='lesson__day__session__locations'><span>Locations:</span></li>
                                        { selectableCourse.getButtons() }
                                        <li>
                                            {errorBlock}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li> );
                    }
                }

                daysList.push(<div className='lessons__day' key={'day_' + i}>
                        <h5>{ day.name }s:</h5>

                        <ul className='lessons__day__times'>
                            { dayTimes }
                        </ul>
                    </div>);
            }
        } else {
            daysList.push(<div className='lessons__day lessons__day--selected' key={'day_' + i}>
                    <ul className='lessons__day__times'>
                        <li className='lessons__day__time'>
                            <div className='lessons__day__time-header' style={{border: '1px solid ' + color}}>
                                <span className='lessons__day__clock' style={{background: color}}>
                                    <span className='lessons__day__icon'></span>{ activity.time }
                                    <span className='lessons__day__name'>{ content.selectedClassDay.replace('[day]', activity.weekday) }</span>
                                </span>
                            </div>
                            <div className='lessons__day__time-details lessons__day__time-details--selected'>
                                <div className='inner'>
                                    <p dangerouslySetInnerHTML={ {__html: classes.description } }></p>
                                    <ul>
                                        <li><span>First session:</span> { moment(activity.first_session_date).format('DD MMM YYYY') }</li>
                                        <li><span>Duration:</span> { content.duration.replace('[duration]', activity.duration) }</li>
                                        <li className='lesson__day__session'>
                                            <span>Location:</span> { session.name }
                                            <Button className='link' onClick={ this.onRemoveSelection.bind(null, index, lesson.level) }>{ content.removeSession }</Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>);
        }

        return daysList;
    },

    onRemoveSelection: function onRemoveSelection(userId, level) {
        this.props.dispatch(actions.lessons.releaseReservation(userId, level));
        this.handleMoreInfoClick(this.state.expanded, null);
    },

    onSelectSession: function onSelectSession(activity, session, userId, sessionIndex, level) {
        this.props.dispatch(actions.lessons.makeReservation(activity, session, userId, sessionIndex, level));
    },

    removeLessonsUser: function removeLessonsUser(userId, level) {
        this.props.dispatch(actions.app.showModal('removeLessonsUser', {userId: userId, level: level}));
        this.handleMoreInfoClick(this.state.expanded, null);
    },

    // After rendering, if we have added an error message, we may need to resize a box
    componentDidUpdate: function() {
        let key = this.state.expanded; // which box to resize
        let elt = document.querySelector('.lessons__day__time-details--' + key);
        if(elt) {
            elt.style.height = elt.querySelector('.inner').clientHeight + 2 + 'px';
        }
        // except for some people, this apparently doesn't work, so... do it again a second later
        window.setTimeout(function() {
            let key = this.state.expanded; // which box to resize
            let elt = document.querySelector('.lessons__day__time-details--' + key);
            if(elt) {
                elt.style.height = elt.querySelector('.inner').clientHeight + 2 + 'px';
            }
        }.bind(this), 500);
    },

    render: function() {
        var content = this.props.content,
            user = this.props.users.objects[this.props.index],
            lessons = this.props.lessons,
            lesson = this.props.lesson,
            levelInfo = lessons.levelInfo[lesson.level],
            typeDesc = user.typeDesc,
            idInType = user.idInType + 1,
            firstName = (user.info.firstName && user.info.firstName.value),
            lastName = (user.info.lastName && user.info.lastName.value),
            price = levelInfo && levelInfo.price && levelInfo.price !== 0 ? content.levelPrice.replace('[amount]', utils.formatPrice(levelInfo.price)) : null,
            userName,
            enquiry;

        if(!levelInfo) {
            return null;
        }

        if (firstName) {
            userName = ((firstName && lastName) ? firstName + ' ' + lastName : firstName );
        } else {
            userName = typeDesc + ' ' + idInType;
        }

        let selectedLessonType = this.props.lessons.centreTypesList.filter(lessonType => {
            return lessonType.id === this.props.lessons.typeId * 1;
        });

        // test if we have custom enquiry link and fall back to hard coded if not exists
        let enquiryLink = selectedLessonType.length === 1 ? selectedLessonType[0].url_unsure : this.props.enquiryLink;
        if(!lesson.session) {
            enquiry = (<div className='personal-details__options-link'>
                    <a className='personal-details__link'  href={ enquiryLink } target='_blank'>{ this.props.links.session }</a>
                </div>);
        }

        if(user.lesson.discountUser && levelInfo.priceDiscount !== 0) {
            price = content.levelPrice.replace('[amount]', utils.formatPrice(levelInfo.priceDiscount));
        }

        return (<div className={'lessons-select ' + ( lesson.session ? 'lessons-select--selected' : '') }>
                 <div className={'level-breakdown__chosen ' + ( lesson.session ? 'level-breakdown__chosen--selected' : '') }>
                    <div className='level-breakdown__chosen-icon'>
                        <Icons icon={this.props.lessonType} color={levelInfo.color} iconUrl={levelInfo.iconUrl}/>
                    </div>
                    <div className='level-breakdown__chosen-details'>
                        <div className='level-breakdown__chosen-name'>{ userName }:</div>
                        <h4>{ levelInfo.name } <span>{ price }</span></h4>
                        <p dangerouslySetInnerHTML={ {__html: levelInfo.comment } } />
                    </div>

                    <Button className='level-breakdown__chosen-button'  onClick={ this.removeLessonsUser.bind(null, this.props.index, levelInfo.level) }>x</Button>
                </div>

                { this.listTimes() }

                { enquiry }
            </div>);
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.lessons.selectLessons.lessonTimes,
        lessons: state.lessons,
        users: state.selections.users,
        isStaff: state.staffLogin.staffId
    };
}

module.exports = connect(mapStateToProps)(LessonTimes);
