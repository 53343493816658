const { getFeesTotal } = require('../../utils/stateSelectors');

var React           = require('react'),
    connect         = require('react-redux').connect,

    Price           = require('../common/price'),
    actions         = require('../../actions'),
    utils           = require('../../utils'),

    PriceDetails;

PriceDetails = React.createClass({
    displayName: 'PriceDetails',

    _onChangeDuration: function _onChangeDuration(duration) {
        this.props.dispatch(actions.selections.changeDuration(duration));
    },

    render: function render() {
        if (!this.props.centre || !this.props.pricing) {
            return null;
        }

        var monthlyView = 1 === this.props.d && 0 === this.props.dt,
            // annualView = 12 === this.props.d && 0 === this.props.dt || this.props.dt === 1,
            annualView = 12 === this.props.d && 0 === this.props.dt,
            shortTimeView = 1 === this.props.d && 1 === this.props.dt,
            content = this.props.content,
            customPeriod,
            footNotes = [],
            total = 0,
            monthPrices,
            yearPrices,
            asterisk,
            headNote,
            pricing;

        if (this.props.pricing.lowestPrice !== null && this.props.pricing.lowestPrice !== undefined) {
            total = this.props.pricing.lowestPrice;
            asterisk = content.asterisk;
            headNote = <span className='pricing__head-note'>{ content.fromOnly }</span>;
            footNotes.push(<small key='foot_note_adult_membership' className='pricing__foot-note'>{ asterisk + ' ' + content.basedOnAdultMembershipNote }</small>);

        } else {
            pricing = this.props.pricing;

            if (pricing && pricing.periods && pricing.periods[0]) {
                monthPrices = pricing.periods[0][1];
                yearPrices = pricing.periods[0][12];
            } else if (pricing && pricing.periods && pricing.periods[1]) {
                total = Object.values(pricing.periods[1]).reduce((total, x) => total += x.total, 0);
            }

            if (pricing.periods && pricing.periods[this.props.dt] && pricing.periods[this.props.dt][this.props.d]) {
                // this is the total for a specific period
                total = pricing.periods[this.props.dt][this.props.d].total;
                // correction for specific scenario
                const totalUpdate = (() => {
                    const usersCount = (this.props.users || {}).total || 1;
                    const periodCount = Object.values((pricing || {}).periods[1] || []).length;
                    if (
                        this.props.dt !== 1 ||
                        periodCount < 2 ||
                        usersCount < 2
                    ) {
                        return 0; // does not apply
                    }
                    /** @type {number} */
                    const updated = Object.values(pricing.periods[1]).reduce((sum, item) => sum += ((item || {}).total || 0), 0);
                    return (!updated || updated === total) ? 0 : updated;
                })();
                if (totalUpdate) {
                    // console.debug(`Total changed from: ${total} to ${totalUpdate}`);
                    total = totalUpdate;
                }
            } else if (pricing.periods && pricing.periods[this.props.dt]) {
                // if periond is not 1, 12 then process custom periods
                total = 0;

                for (var periodKey in pricing.periods[this.props.dt]) {
                    customPeriod = pricing.periods[this.props.dt][periodKey];
                    total += customPeriod.total + customPeriod.fees.total + customPeriod.extras.total;
                }

                total += (yearPrices) ? yearPrices.extras.total : 0;
                total += (monthPrices) ?  monthPrices.extras.total : 0;
            }

            if (this.props.dt === 1) {
                headNote = <span className='pricing__head-note'>{ content.priceOneOff }</span>;
            } else if (1 === this.props.d) {
                headNote = <span className='pricing__head-note'>{ content.pricePerMonth }</span>;
            } else if (12 === this.props.d) {
                headNote = <span className='pricing__head-note'>{ content.pricePerYear }</span>;
            }

            if (!shortTimeView) {
                if (monthlyView && monthPrices && monthPrices.fees.total) {
                    var text;

                    if(this.props.lessons && this.props.lessons.inView) {
                        text = content.plusLessonCost;
                    } else {
                        text = content.plusJoiningFee;
                    }

                    const monthlyFeesTotal = getFeesTotal({ duration: 1, durationType: 0, periods: this.props.pricing.periods }) || monthPrices.fees.total;
                    monthlyFeesTotal && footNotes.push(<span key='joining_fee' className='pricing__foot-note'>
                        {text.replace('{fee}', utils.formatPrice(monthlyFeesTotal))}
                    </span>);

                } else if (annualView && yearPrices && yearPrices.fees) {
                    // yearPrices.fees.total can be wrong when mixing yearly and monthly
                    const yearlyFeesTotal = getFeesTotal({ duration: 12, durationType: 0, periods: this.props.pricing.periods }); // yearPrices.fees.total;
                    yearlyFeesTotal && footNotes.push(<span key='joining_fee' className='pricing__foot-note'>
                        {content.plusJoiningFee.replace('{fee}', utils.formatPrice(yearlyFeesTotal))}
                    </span>);
                }

                if (monthlyView && yearPrices && yearPrices.extras.total) {
                    footNotes.push(<span key='monthly_extras' className='pricing__foot-note'>{ content.plusAnnualExtras.replace('{fee}', utils.formatPrice(yearPrices.extras.total)) }</span>);
                } else if (annualView && monthPrices && monthPrices.extras.total) {
                    footNotes.push(<span key='annual_extras' className='pricing__foot-note'>{ content.plusMonthlyExtras.replace('{fee}', utils.formatPrice(monthPrices.extras.total)) }</span>);
                }
            }
        }

        let discountInfo = '';

        if (monthlyView && monthPrices && monthPrices.discountTotal) {
            const originalTotal = total;
            total -= monthPrices.discountTotal;

            discountInfo = (
                <div className='pricing__discount'>
                    <div className='pricing__discount__row'>
                        <div className='pricing__discount__label'>
                            Price before discount:
                        </div>
                        <div className='pricing__discount__price'>
                            { utils.formatPrice(originalTotal) }
                            &nbsp;
                            <em>per month</em>
                        </div>
                    </div>
                    <div className='pricing__discount__row--big-green'>
                        <div className='pricing__discount__label'>
                            You save:
                        </div>
                        <div className='pricing__discount__price'>
                            { utils.formatPrice(monthPrices.discountTotal) }
                            &nbsp;
                            <em>per month</em>
                        </div>
                    </div>
                </div>
            );
        } else if (annualView && yearPrices && yearPrices.discountTotal){
            discountInfo = (
                <div className='pricing__discount'>
                    <div className='pricing__discount__row'>
                        <div className='pricing__discount__label'>
                            Price before discount:
                        </div>
                        <div className='pricing__discount__price'>
                            { utils.formatPrice(total + yearPrices.discountTotal) }
                            &nbsp;
                        </div>
                    </div>
                    <div className='pricing__discount__row--big-green'>
                        <div className='pricing__discount__label'>
                            You save:
                        </div>
                        <div className='pricing__discount__price'>
                            { utils.formatPrice(yearPrices.discountTotal) }
                            &nbsp;
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className='pricing__details'>
                { headNote }
                <Price amount={ total } asterisk={ asterisk} />
                { discountInfo }
                { footNotes }
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        centre: state.centreFinder.selected,
        content: state.app.content.sidebar.pricing,
        lessons: state.lessons,
        pricing: state.selections.pricing,
        dt: state.selections.durationType,
        d: state.selections.duration,
        users: state.selections.users
    };
}

module.exports = connect(mapStateToProps)(PriceDetails);
