var React = require("react"),
    connect = require("react-redux").connect,
    config = require("../../config"),
    viewUtils = require("../../utils/viewUtils"),
    AdvanceNotice = require("./advanceNotice"),
    Button = require("../common/button"),
    DirectDebitContent;

DirectDebitContent = React.createClass({
    displayName: 'DirectDebitContent',

    render: function() {
        var content = this.props.content,
            user = this.props.user,
            advanceNotice,
            startDate;

        if (this.props.startDate) {
            startDate = (
                <div>
                    <span className="direct-debit-page__field-name">
                        {content.startDate}
                    </span>
                    <span className="direct-debit-page__field-value">
                        {viewUtils.formatDate(this.props.startDate)}
                    </span>
                </div>
            );
        }

        if (user.directDebit && user.directDebit.totals) {
            advanceNotice = (
                <AdvanceNotice
                    content={content.advanceNotice}
                    collections={user.directDebit.collections}
                    totals={user.directDebit.totals}
                    pricing={this.props.pricing}
                />
            );
        }

        return (
            <div className="direct-debit-page__content">
                {advanceNotice}
                <div className="direct-debit-page__summary">
                    {startDate}

                    <h4 className="a11y">{content.fieldsDetailsA11y}</h4>
                    <span className="direct-debit-page__field-name">
                        {content.holderName}
                    </span>
                    <span className="direct-debit-page__field-value">
                        {user.bankDetails.fields.holderName.value ||
                            user.bankDetails.fields.holderName}
                    </span>

                    <span className="direct-debit-page__field-name">
                        {content.accountNumber}
                    </span>
                    <span className="direct-debit-page__field-value">
                        {user.bankDetails.fields.accountNumber.value ||
                            user.bankDetails.fields.accountNumber}
                    </span>

                    <span className="direct-debit-page__field-name">
                        {content.sortCode}
                    </span>
                    <span className="direct-debit-page__field-value">
                        {user.bankDetails.fields.sortCode.value ||
                            user.bankDetails.fields.sortCode}
                    </span>

                    <span className="direct-debit-page__field-name">
                        {content.userNumber}
                    </span>
                    <span className="direct-debit-page__field-value">
                        {config.app.serviceUserNumber}
                    </span>

                    <span className="direct-debit-page__field-name">
                        {content.reference}
                    </span>
                    <span className="direct-debit-page__field-value">
                        {user.info.bankAccountReference &&
                            user.info.bankAccountReference.value}
                    </span>

                    <Button
                        key="sdmld"
                        className="direct-debit-page__section-link"
                        onClick={this.props.showModal.bind(
                            null,
                            { user: user },
                            "directDebit"
                        )}
                    >
                        {content.modalLink}
                    </Button>

                    <h4 className="direct-debit-page__section-title">
                        {content.date}
                    </h4>
                    <span className="direct-debit-page__section-text">
                        {viewUtils.formatDate(Date.now())}
                    </span>

                    <h4 className="direct-debit-page__section-title">
                        {content.instructionTitle}
                    </h4>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{
                            __html: content.instructionText
                        }}
                    ></span>

                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{
                            __html: content.banksAcceptNotice
                        }}
                    ></span>
                </div>
                <div className="direct-debit-page__notices">
                    <h4 className="direct-debit-page__section-title">
                        {content.eaContactDetailsTitle}
                    </h4>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{ __html: content.eaAddress }}
                    ></span>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{ __html: content.eaTelEmail }}
                    ></span>

                    <h4 className="direct-debit-page__section-title">
                        {content.ddInstructionTitle}
                    </h4>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{
                            __html: content.ddInstructionText1
                        }}
                    ></span>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{
                            __html: content.ddInstructionText2
                        }}
                    ></span>

                    <h4 className="direct-debit-page__section-title">
                        {content.ddGuarantieTitle}
                    </h4>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{
                            __html: content.ddGuaranteeText1
                        }}
                    ></span>
                    <span
                        className="direct-debit-page__section-text"
                        dangerouslySetInnerHTML={{
                            __html: content.ddGuaranteeText2
                        }}
                    ></span>
                </div>
            </div>
        );
    }
});

DirectDebitContent.propTypes = {
    content: React.PropTypes.object,
    index: React.PropTypes.number,
    user: React.PropTypes.object,
    pricing: React.PropTypes.object,
    startDate: React.PropTypes.string,
    showModal: React.PropTypes.func
};

module.exports = connect()(DirectDebitContent);
