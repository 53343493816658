var React = require("react"),
    connect = require("react-redux").connect,
    utils = require("../../../utils"),
    viewUtils = require("../../../utils/viewUtils"),
    lessonsActions = require("../../../actions/lessons"),
    moment = require("moment"),
    SmartDate;

SmartDate = React.createClass({
    displayName: 'SmartDate',

    getInitialState: function getInitialState() {
        var state = {
            dateParts: utils.getDateParts(this.props.value),
            firstRender: true,
            partsValid: true,
            value: this.props.value,
            lessons: lessonsActions
        };

        return state;
    },

    componentDidMount: function componentDidMount() {
        this.setState({
            firstRender: false
        });
    },

    componentWillMount: function componentWillMount() {
        if (!this.props.input.onChange) {
            this.props.input.onChange = function() {};
        } else {
            this.originalOnChange = function() {
                return this.props.input.onChange;
            }.bind(this)();
        }

        this._onChangeDebounced = window.debounce(function(value) {
            this.originalOnChange.apply(this, [value]);
        }, 200);
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        var dateParts = utils.getDateParts(newProps.value);

        this.setState(function() {
            return {
                dateParts: dateParts
            };
        });
    },

    shouldComponentUpdate: function shouldComponentUpdate(
        nextProps,
        nextState
    ) {
        if (nextState.firstRender !== this.state.firstRender) {
            return false;
        }

        return true;
    },

    originalOnChange: function() {},

    _onDatePartChange: function _onDatePartChange(field, e) {
        var value = e.target.value,
            parsedValue = parseInt(value, 10),
            dateParts = Object.assign({}, this.state.dateParts),
            partsValid = true;

        if (!isNaN(parsedValue)) {
            dateParts[field] = value + "";
        } else {
            dateParts[field] = "";
        }

        if ("year" === field && parsedValue < 100) {
            partsValid = false;
        }

        if (
            (dateParts[field] === "0" || dateParts[field] === "") &&
            ("month" === field || "day" === field)
        ) {
            partsValid = false;
        }

        this.setState(function() {
            return {
                dateParts: dateParts,
                partsValid: partsValid
            };
        });

        if (!partsValid) {
            return;
        }

        this._onChangeDebounced(
            dateParts.year +
                "-" +
                viewUtils.zeroPad(dateParts.month, 2) +
                "-" +
                viewUtils.zeroPad(dateParts.day, 2)
        );
    },

    _onFocusDatePart: function _onFocusDatePart() {
        if (!this.state.datePartFocused) {
            this.setState(function() {
                return {
                    datePartFocused: true
                };
            });
        }
    },

    _onBlurDatePart: function _onBlurDatePart() {
        if (this.state.datePartFocused) {
            this.setState(function() {
                return {
                    datePartFocused: false
                };
            });
        }
    },

    _formatValue: function _formatValue(value, padLen) {
        var parsedValue;

        if (value === null || value === undefined || value === "") {
            return "";
        }

        // value is 0 as a number.
        if (!value) {
            return "0";
        }

        // Updated from store setState as a string
        if (typeof value === "string") {
            parsedValue = parseInt(value, 10);

            if (value.length > 0 && !isNaN(parsedValue) && parsedValue >= 10) {
                return viewUtils.zeroPad(value, padLen);
            }
        } else if (typeof value === "number" && this.state.firstRender) {
            return viewUtils.zeroPad(value, padLen);
        }

        return value;
    },

    _showAge: function _showAge() {
        if (!this.props.value) return;
        const birthDate = moment(this.props?.value);
        const today = moment();
        return (birthDate.isAfter(today) || today.diff(birthDate, 'years') > 115) ?  false :  true;
    },

    render: function render() {
        var props = this.props,
            input = props.input || {},
            label = props.label || {},
            labelProps = {
                className: label.className || "",
                htmlFor: props.name
            },
            parts = {
                day: this.state.dateParts.day,
                month: this.state.dateParts.month,
                year: this.state.dateParts.year
            },
            month = this._formatValue(parts.month, 2),
            day = this._formatValue(parts.day, 2),
            invalidExtraForAge = false,
            year = parts.year,
            classes = [],
            validityMessage,
            validNode,
            labelText,
            labelElt,
            inputElt,
            key;

        if (props.className) {
            classes = classes.concat(props.className.split(" "));
        }

        if (!props.disableHints) {
            classes.push("input--hints");
        }

        var currentYear = new Date().getFullYear();

        //loop through all extras and check if age is valid for all
        if (props.extras && Object.keys(props.extras).length) {
            for (var extra in props.extras) {
                var extraTypes = props.extras[extra];

                for (var subkey in extraTypes) {
                    var sub = extraTypes[subkey];

                    if (
                        (sub.maxAge &&
                            props.age !== 0 &&
                            props.age > sub.maxAge) ||
                        (sub.minAge &&
                            props.age !== 0 &&
                            props.age < sub.minAge)
                    ) {
                        invalidExtraForAge = true;
                    }
                }
            }
        }

       if (
            !props.skipInlineValidation &&
            (!props.valid ||
            !this.state.partsValid ||
            (this.state.dateParts.year && this.state.dateParts.year < 1900) ||
            (this.state.dateParts.month &&
                (this.state.dateParts.month < 1 ||
                    this.state.dateParts.month > 12)) ||
            (this.state.dateParts.day &&
                (this.state.dateParts.day < 1 || this.state.dateParts.day > 31)))
        ) {
            // The validationErrors are not passed in the input from
            //  the form right now, so you will need to add a propType for it
            //  and pass them from the user form or any other form that includes
            //  an <Input />.
            // Based on the content, you "only" have to select the right error
            //  message and place in in here.
            // If this is an email field or a telephone field (also emmergency)
            //  then the validation may or may not have the results from the
            //  GBG validation. If it does, the vlaidationErrors property is
            //  likely to be either null, or an empty ARRAY.
            // In this case, you'll use a generic message.
            // In other cases of validation, the error keys possible come from
            //  the fieldValidator component, and the validationErrors property
            //  is an OBJECT.

            // this is not needed as ageRange error is not needed without inline validation
            // if (props.invalidAgeRange && props.value?.length === 10) {
            //     validityMessage = (
            //         <span className="form__input-validity-message">
            //             Age not valid for this activity
            //         </span>
            //     );
            // } else
            if (invalidExtraForAge) {
                classes.push("input--invalid");
                validNode = <span className="form__input-validity-icon"></span>;
                validityMessage = (
                    <span className="form__input-validity-message">
                        Age not valid with selected extra
                    </span>
                );
            } else {
                classes.push("input--invalid");
                validNode = <span className="form__input-validity-icon"></span>;
                validityMessage = (
                    <span className="form__input-validity-message">
                        This field is invalid
                    </span>
                );
            }
        } else if ((props.skipInlineValidation || (props.valid && props.value)) && props.value?.length === 10) {
            classes.push("input--valid");
            validNode = <span className="form__input-validity-icon"></span>;
        }

        for (key in label) {
            if ("text" === key) {
                labelText = label[key];
            } else {
                if (!label[key] !== null && label[key] !== undefined) {
                    labelProps[key] = label[key];
                }
            }
        }

        var ageText = ![0, undefined, null].includes(this.props.age) && "(Age: " + this.props.age + ")";

        labelProps.htmlFor = props.name + "_day";
        labelElt = <label {...labelProps}>{labelText}</label>;

        inputElt = (
            <div
                className={
                    props.disabled ? "input input--disabled date" : "input date"
                }
            >
                <span
                    className={
                        "date__parts" +
                        (this.state.datePartFocused
                            ? " date__parts--focused"
                            : "")
                    }
                >
                    <span className="date__part date__part--day">
                        <input
                            disabled={props.disabled}
                            id={props.name + "_day"}
                            onFocus={this._onFocusDatePart}
                            onBlur={this._onBlurDatePart}
                            name={props.name + "_day"}
                            type="text"
                            maxLength="2"
                            placeholder="DD"
                            defaultValue={day}
                            {...(props.disableOnChange ? {} : { onChange: this._onDatePartChange.bind(null, "day") })}
                            onBlur={this._onDatePartChange.bind(null, "day")}
                            pattern="[0-9]*"
                            inputMode="numeric"
                        />
                    </span>
                    <span className="date__part date__part--month">
                        <input
                            disabled={props.disabled}
                            id={props.name + "_month"}
                            onFocus={this._onFocusDatePart}
                            onBlur={this._onBlurDatePart}
                            name={props.name + "_month"}
                            type="text"
                            maxLength="2"
                            placeholder="MM"
                            defaultValue={month}
                            {...(props.disableOnChange ? {} : { onChange: this._onDatePartChange.bind(null, "month") })}
                            onBlur={this._onDatePartChange.bind(null, "month")}
                            pattern="[0-9]*"
                            inputMode="numeric"
                        />
                    </span>
                    <span className="date__part date__part--year">
                        <input
                            disabled={props.disabled}
                            id={props.name + "_year"}
                            onFocus={this._onFocusDatePart}
                            onBlur={this._onBlurDatePart}
                            name={props.name + "_year"}
                            type="text"
                            maxLength="4"
                            placeholder="YYYY"
                            defaultValue={year}
                            {...(props.disableOnChange ? {} : { onChange: this._onDatePartChange.bind(null, "year") })}
                            onBlur={this._onDatePartChange.bind(null, "year")}
                            pattern="[0-9]*"
                            inputMode="numeric"
                        />
                    </span>
                </span>
                {validNode}
                {validityMessage}
                { props.value?.length === 10 && this._showAge() && (<div className="personal-details__age">{ageText}</div>)}
            </div>
        );

        if (this.props.inputFirst) {
            return (
                <div className={classes.join(" ")}>
                    {inputElt}
                    {labelElt}
                </div>
            );
        } else {
            return (
                <div className={classes.join(" ")}>
                    {labelElt}
                    {inputElt}
                </div>
            );
        }
    }
});

SmartDate.propTypes = {
    className: React.PropTypes.string,
    disabled: React.PropTypes.bool,
    id: React.PropTypes.string,
    inputFirst: React.PropTypes.bool,
    name: React.PropTypes.string,
    onChange: React.PropTypes.func,
    placeholder: React.PropTypes.string,
    disableOnChange: React.PropTypes.bool,
    lessonType: React.PropTypes.string,
    ageInMonths: React.PropTypes.number,
};

module.exports = connect()(SmartDate);
