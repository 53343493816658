var React = require("react"),
    connect = require("react-redux").connect,
    actions = require("../../actions"),
    config = require("../../config"),
    utils = require("../../utils"),
    history = require("../../utils/history"),
    UserModel = require("../../models/user"),
    DurationToggle = require("./durationToggle"),
    StartDate = require("./startDate"),
    PaymentBreakdown = require("./paymentBreakdown"),
    DirectDebitForm = require("./directDebitForm"),
    DiscountCodes = require("./discountCodes"),
    PromoCodeTerms = require("./promoCodeTerms"),
    Button = require("../common/button"),
    SaveSelections = require("../common/saveSelectionsButton"),
    FooterSecurity = require("../common/footerSecurity"),
    history = require("../../utils/history"),
    SelectedLesson = require("../lessons/selectedLesson"),
    LessonsTimeout = require("../lessons/lessonsTimeout"),
    scrollToComponent = require("react-scroll-to-component"),
    Stage4Content;

import centre from "../../models/centre";
import { redirectToPayment } from "../../utils/redirects";
import Notice from "../common/notices/notice";
import { requiresProofOfEligibility } from "../common/proof-of-eligibility";

let busy = false;

Stage4Content = React.createClass({
    displayName: 'Stage4Content',

    getInitialState: function getInitialState() {
        return {
            errorMessage: null,
            valid: false,
            validationErrors: [],
            durationDisabled: null,
            busy
        };
    },

    componentWillMount: function componentWillMount() {
        this.props.dispatch(
            actions.discounts.getDiscounts(this.props.centre.info.site_id)
        );
        const totalPrice = this.props.totalPrice;
        const shouldAutoClickMakePayment = totalPrice && !totalPrice.oneOff && !totalPrice.monthly;
        if (shouldAutoClickMakePayment) {
            this._validateForm();
        }
    },

    componentWillReceiveProps: function componentWillReceiveProps(newProps) {
        this._validateUsers(newProps.users.objects).then(
            function(result) {
                if (result.valid !== this.state.valid) {
                    this.setState({
                        validationErrors: result.validationErrors,
                        valid: result.valid
                    });
                }
            }.bind(this)
        );

        UserModel.usersBankDetailsValid(this.props.users);
    },

    componentDidMount: function componentDidMount() {
        window.scrollTo(0, 0);

        if (
            !this._checkIfDurationAvailable(1) &&
            this._checkIfDurationAvailable(12)
        ) {
            this.props.dispatch(actions.selections.changeDuration(12));
            this.setState({ durationDisabled: "dd" });
        } else if (
            this._checkIfDurationAvailable(1) &&
            !this._checkIfDurationAvailable(12)
        ) {
            this.props.dispatch(actions.selections.changeDuration(1));
            this.setState({ durationDisabled: "annual" });
        } else if (
            !this._checkIfDurationAvailable(1) &&
            !this._checkIfDurationAvailable(12)
        ) {
            this.setState({ durationDisabled: "both" });
        } else {
            this.setState({ durationDisabled: null });
        }
    },

    _onChangeDuration: function _onChangeDuration(duration) {
        if (this.props.d == duration) {
            return;
        }
        this.props.dispatch(actions.selections.checkExtras({
            duration,
            success: () => this.props.dispatch(actions.selections.changeDuration(duration))
        }));
    },

    _onChangeStartDateOffset: function _onChangeStartDateOffset(
        daysOffset,
        newDate
    ) {
        this.props.dispatch(
            actions.selections.setStartDateOffset({ days: daysOffset }, newDate)
        );
        this.props.dispatch(
            actions.centreFinder.selectCentre(this.props.centre.info.site_id, {
                date: utils.getShortDate(newDate)
            })
        );
    },

    _onCheckStartDate: function _onCheckStartDate(value) {
        this.props.dispatch(actions.bankDetails.setStartDate(value));
    },

    _checkIfDurationAvailable: function _checkIfDurationAvailable(duration) {
        var checkIfAvailable = false;

        for (var i = 0; i < this.props.users.objects.length; i++) {
            var availableSubs = this.props.users.objects[i]
                .availableSubscriptions;

            if (availableSubs.length) {
                for (var sub = 0; sub < availableSubs.length; sub++) {
                    if (
                        availableSubs[sub].desc.indexOf("Plus1") !== -1 ||
                        availableSubs[sub].desc.indexOf("Free Swim") !== -1
                    ) {
                        if (availableSubs[sub].duration === duration) {
                            checkIfAvailable = true;
                        }
                    } else {
                        checkIfAvailable = true;
                    }
                }

                if (!checkIfAvailable) {
                    return false;
                }
            }
        }

        return true;
    },

    // TODO: add that to sidebar (*Sob*...)
    _validateForm: function _validateForm(e) {
        e && e.preventDefault();

        if (busy || this.state.busy) {
            return;
        }

        busy = true;
        this.setState({ busy });

        var self = this;
        var props = this.props;

        this._validateUsers(this.props.users.objects).then(
            function(result) {
                if (result.valid) {
                    if (
                        (0 === this.props.dt &&
                            !this.props.pricing.hasMonthlyFees &&
                            this.props.pricing.periods[0] &&
                            this.props.pricing.periods[0][12] &&
                            this.props.pricing.periods[0][12].total === 0) ||
                        (1 === this.props.dt &&
                            !this.props.pricing.hasMonthlyFees) ||
                        (0 === this.props.dt &&
                            12 === this.props.d &&
                            !this.props.pricing.hasMonthlyFees)
                    ) {
                        this.props.dispatch(
                            actions.selections.postSelections(
                                function() {
                                    if (!this.props.bankDetails.paymentUrl) {
                                        history.push("/payment/status/success");

                                        return;
                                    }
                                    busy = true;
                                    this.setState({ busy });
                                    redirectToPayment(this.props);
                                }.bind(this)
                            )
                        );
                    } else {
                        props.dispatch(actions.app.showLoader(true));

                        return this.props.dispatch(
                            actions.bankDetails.validateForms(
                                function() {
                                    this.props.dispatch(
                                        actions.selections.postSelections(
                                            function() {
                                                var paymentTypeMonthly =
                                                    "Paid Monthly";
                                                var freeAccountFound;
                                                var subscriptionCount = 0;
                                                var promises = [];

                                                for (
                                                    var z = 0;
                                                    z <
                                                    this.props.users.objects
                                                        .length;
                                                    z++
                                                ) {
                                                    var user = this.props.users
                                                        .objects[z];

                                                    if (
                                                        user.type ===
                                                        "freeprofile"
                                                    ) {
                                                        freeAccountFound = true;
                                                    } else {
                                                        subscriptionCount++;
                                                    }

                                                    // loop over and count each users extras
                                                    for (var key in user.extras) {
                                                        // eslint-disable-line no-unused-vars
                                                        subscriptionCount++;
                                                    }

                                                    //transfer lessons booking from Member 0 to user
                                                    if (
                                                        user.lesson
                                                            .selectedLessons &&
                                                        Object.keys(
                                                            user.lesson
                                                                .selectedLessons
                                                        ).length
                                                    ) {
                                                        for (var l in user
                                                            .lesson
                                                            .selectedLessons) {
                                                            var lesson =
                                                                user.lesson
                                                                    .selectedLessons[
                                                                    l
                                                                ];

                                                            if (
                                                                lesson.bookingRef &&
                                                                !lesson.bookingAttached
                                                            ) {
                                                                promises.push(
                                                                    actions.lessons.attachBooking(
                                                                        user,
                                                                        z,
                                                                        lesson.bookingRef,
                                                                        l
                                                                    )
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                                sessionStorage.setItem('users:objects', JSON.stringify(this.props.users.objects));
                                                sessionStorage.setItem('centre:selected', JSON.stringify(this.props.centre));

                                                self.props.dispatch(
                                                    actions.app.showLoader(true)
                                                );
                                                self.props.dispatch(
                                                    actions.lessons.attachCallError(
                                                        null,
                                                        null
                                                    )
                                                );

                                                //if user has lessons bookings, transfer bookings
                                                Promise.all(promises).then(
                                                    function(results) {
                                                        var success = true,
                                                            error,
                                                            code;

                                                        props.dispatch(
                                                            actions.app.showLoader(
                                                                false
                                                            )
                                                        );

                                                        for (
                                                            var r = 0;
                                                            r < results.length;
                                                            r++
                                                        ) {
                                                            error =
                                                                results[r]
                                                                    .error;
                                                            code =
                                                                results[r].code;

                                                            if (error) {
                                                                success = false;
                                                                break;
                                                            } else {
                                                                var lesson = {};
                                                                lesson.selectedLessons = {};
                                                                lesson.selectedLessons[
                                                                    results[
                                                                        r
                                                                    ].lesson
                                                                ] = {
                                                                    bookingRef:
                                                                        results[
                                                                            r
                                                                        ]
                                                                            .booking,
                                                                    bookingAttached: true
                                                                };

                                                                var data = {
                                                                    lesson: lesson
                                                                };

                                                                self.props.dispatch(
                                                                    actions.selections.changeData(
                                                                        results[
                                                                            r
                                                                        ]
                                                                            .userId,
                                                                        data
                                                                    )
                                                                );
                                                            }
                                                        }

                                                        //continue if all bookings are successfully transferred
                                                        if (success) {
                                                            //extend timer to 35 mins for payment if in lessons journey
                                                            if (
                                                                self.props
                                                                    .lessons &&
                                                                self.props
                                                                    .lessons
                                                                    .inView
                                                            ) {
                                                                self.props.dispatch(
                                                                    actions.lessons.refreshTimer(
                                                                        "payment"
                                                                    )
                                                                );
                                                            }

                                                            if (
                                                                freeAccountFound
                                                            ) {
                                                                paymentTypeMonthly =
                                                                    "Paid Monthly & Free Membership";
                                                            }

                                                            self.props.dispatch(
                                                                actions.app.showLoader(
                                                                    false
                                                                )
                                                            );
                                                            self.props.dispatch(
                                                                actions.stages.next()
                                                            );
                                                        } else if (error) {
                                                            self.props.dispatch(
                                                                actions.app.showLoader(
                                                                    false
                                                                )
                                                            );
                                                            self.props.dispatch(
                                                                actions.lessons.attachCallError(
                                                                    error,
                                                                    code
                                                                )
                                                            );
                                                        }
                                                    }
                                                );
                                            }.bind(this)
                                        )
                                    );
                                }.bind(this),
                                function() {
                                    self.props.dispatch(
                                        actions.app.showLoader(false)
                                    );
                                }
                            )
                        );
                    }
                } else {
                    this.setState({
                        validationErrors: result.validationErrors,
                        valid: false
                    });

                    if (result.validationErrors) {
                        window.requestAnimationFrame(function() {
                            var invalidFields = document.querySelectorAll(
                                ".input--invalid"
                            );
                            scrollToComponent(invalidFields[0], {
                                align: "top",
                                offset: -100
                            });
                        });
                    }

                    this.props.dispatch(actions.selections.validating(false));
                }
            }.bind(this)
        ).finally(_ => {
            setTimeout(_ => {
                busy = false;
                this.setState({ busy });
            });
        });
    },

    _validateUsers: function _validateUsers(users) {
        var promises = [],
            valid = true,
            isStaff = this.props.staffId ? true : false,
            errors = [],
            i;

        for (i = 0; i < users.length; i++) {
            if (this.props.totalPrice.monthly && !users[i].guardian) {
                if (users[i].lead || !users[i].useLeadBankDetails) {
                    if (
                        users[i].type.toLowerCase() !== "freeprofile" ||
                        (users[i].typeDesc.toLowerCase() === "freeprofile" &&
                            Object.keys(users[i].extras).length)
                    ) {
                        promises.push(
                            UserModel.validateBankDetails(
                                users[i],
                                null,
                                isStaff
                            )
                        );
                    }
                }
            }
        }

        return Promise.all(promises).then(function(results) {
            for (i = 0; i < results.length; i++) {
                if (!results[i].valid) {
                    (errors[i] = results[i].errors), (valid = false);
                } else {
                    errors[i] = {};
                }
            }

            return {
                validationErrors: errors,
                valid: valid
            };
        });
    },

    isDiscountCodePanelDisplayed: function(
        isLessons,
        hasMonthlyPayment,
        hasOneOffPayment,
        promoCodeActive,
        isDeepLinkDiscount
    ) {
        const {
            isStaffMember,
            centre: {
                info: {
                    preConfiguredDiscounts: {
                        hideInCentre,
                        hideAtHome,
                        hideShortInCentre,
                        hideShortAtHome
                    }
                }
            }
        } = this.props;

        const isShortMembership =
            this.props.steps[0].path === "/short-memberships";

        const shouldDisplay =
            !isLessons &&
            (hasMonthlyPayment || hasOneOffPayment || promoCodeActive) &&
            !isDeepLinkDiscount;

        if (isShortMembership) {
            if (isStaffMember && hideShortInCentre) return false;
            if (!isStaffMember && hideShortAtHome) return false;

            return shouldDisplay;
        }

        if (isStaffMember && hideInCentre) return false;
        if (!isStaffMember && hideAtHome) return false;

        return shouldDisplay;
    },

    render: function render() {
        if (!this.props.centre) {
            return null;
        }

        if (console.error && errorMessages) {
            console.error(errorMessages);
        }

        var content = this.props.content.stage4.content,
            errorMessages = this.props.content.stage4.errors,
            users = this.props.users && this.props.users.objects,
            // isSingleFreeUser = UserModel.isSingleFreeUser(this.props.users.objects),
            pricingPeriodUnavailable =
                !this.props.pricing.periods ||
                !this.props.pricing.periods[this.props.dt] ||
                (this.props.dt === 1 &&
                    !this.props.pricing.periods[this.props.dt]) ||
                (this.props.dt === 0 &&
                    !this.props.pricing.periods[this.props.dt][this.props.d]),
            isLessons =
                this.props.lessons && this.props.lessons.inView ? true : false,
            description = content.description,
            bankDetailsAreValid = true,
            buttonDisabled,
            buttonProps = {
                onClick: this._validateForm
            },
            selectedLessons = [],
            promoCodeActive =
                this.props.discounts &&
                this.props.discounts.discount &&
                this.props.discounts.discount.details &&
                this.props.discounts.discount.type === "code"
                    ? true
                    : false,
            postSelectionsError,
            directDebits = [],
            paymentBreakdown,
            durationToggle,
            paymentsErrors,
            lessonsTimeout,
            saveSelections,
            discountCodes,
            promoCodeTerms,
            startDate,
            leadUser,
            btnText,
            errors,
            loader,
            user,
            i,
            hasMonthlyPayment = this.props.totalPrice.monthly,
            hasOneOffPayment = this.props.totalPrice.oneOff,
            isDeepLinkDiscount = this.props.isDeepLinkDiscount;

        const duration = this.props.d;
        const durationType = this.props.dt;

        const subs = users
            .map(function(x) {
                return x.availableSubscriptions.find(function(s) {
                    return (
                        s.durationType === durationType &&
                        s.duration === duration
                    );
                });
            })
            .filter(x => !!x);

        const discountTags = subs.reduce(function(acc, x) {
            x.tags
                .map(t => t.match(/9999-(.*)/))
                .forEach(function(m) {
                    if (m) {
                        const s = m[1];
                        acc.indexOf(s) < 0 && acc.push(s);
                    }
                });

            return acc;
        }, []);

        const {
            bankDetailsAllValid,
            d,
            pricing,
            bankDetails,
            promoTerms
        } = this.props;

        if (hasMonthlyPayment) {
            if (!bankDetailsAllValid) {
                btnText = content.buttonCompleteToContinue;
            } else if (1 === d || pricing.hasMonthlyFees) {
                btnText = content.buttonDirectDebit;
            }

            buttonDisabled =
                pricingPeriodUnavailable ||
                bankDetails.validating ||
                !this.state.valid ||
                !bankDetailsAllValid ||
                bankDetails.validationInProgress ||
                this.state.durationDisabled === "both" ||
                (promoCodeActive && !promoTerms["promoCodePref"].valid) || // just changed this
                (discountTags.length &&
                    !discountTags
                        .map(x => promoTerms[x])
                        .every(x => x && x.valid));
        } else {
            btnText =
                hasMonthlyPayment || hasOneOffPayment
                    ? content.buttonMakePayment
                    : content.buttonConfirm;

            const { promoTerms } = this.props;

            buttonDisabled =
                (discountTags.length > 0 &&
                    !discountTags
                        .map(x => {
                            return promoTerms && promoTerms[x];
                        })
                        .every(x => x && x.valid)) ||
                (promoCodeActive
                    ? promoTerms && !promoTerms["promoCodePref"].valid
                    : false);
        }

        buttonProps.disabled = buttonDisabled;
        buttonProps.className =
            "button simple-payment__button" +
            (buttonDisabled ? " button--disabled" : "");

        if (
            this.props.bankDetails.valid === false &&
            this.props.bankDetails.validated
        ) {
            bankDetailsAreValid = false;
        }

        if (!pricingPeriodUnavailable) {
            if (users.length) {
                for (i = 0; i < users.length; i++) {
                    user = users[i];
                    leadUser = users.find(function(u) {
                        return u.lead;
                    });

                    let hasExtras = false;

                    for (var property in user.extras) {
                        if (user.extras.hasOwnProperty(property)) {
                            let extra = user.extras[property];

                            for (var prop in extra) {
                                if (extra.hasOwnProperty(prop)) {
                                    hasExtras = true;
                                }
                            }
                        }
                    }

                    var showDirectDebitForm =
                        hasMonthlyPayment && !user.guardian;

                    if (showDirectDebitForm) {
                        directDebits.push(
                            <DirectDebitForm
                                validBankDetails={bankDetailsAreValid}
                                content={
                                    this.props.content.stage4.directDebitForm
                                }
                                index={i}
                                key={"dd_" + i}
                                leadUser={leadUser}
                                user={user}
                                isStaff={this.props.isStaffMember || null}
                            />
                        );
                    }
                }
            }

            if (!isLessons) {
                if (this.props.dt === 0) {
                    durationToggle = (
                        <DurationToggle
                            d={this.props.d}
                            content={this.props.content.stage4.durationToggle}
                            onChangeDuration={this._onChangeDuration}
                            pricing={this.props.pricing}
                            isDisabled={this.state.durationDisabled}
                        />
                    );
                }

                if (!config.disabledFeatures.changeStartDate) {
                    startDate = (
                        <StartDate
                            content={this.props.content.stage4.startDate}
                            duration={this.props.d}
                            durationType={this.props.dt}
                            originalMaxStartDate={
                                this.props.originalMaxStartDate
                            }
                            onChangeStartDateOffset={
                                this._onChangeStartDateOffset
                            }
                            onCheckStartDate={this._onCheckStartDate}
                            startDateOffset={this.props.startDateOffset}
                            users={this.props.users}
                            centerData={this.props.centre}
                            startDateIsRenewal={this.props.startDateIsRenewal}
                        />
                    );
                }
            }

            paymentBreakdown = (
                <PaymentBreakdown
                    centre={this.props.centre}
                    content={this.props.content.stage4.paymentBreakdown}
                    generalContent={this.props.content.general}
                    d={this.props.d}
                    dt={this.props.dt}
                    pricing={this.props.pricing}
                    users={this.props.users}
                />
            );
        }

        if (
            this.props.postSelectionsError ||
            this.props.attachCallError ||
            this.props.payments.error ||
            this.props.payments.gatewayError ||
            !this.props.bankDetails.valid
        ) {
            if (this.props.postSelectionsError) {
                // this.props.postSelectionsError is the string returned from mrm
                let code = this.props.postSelectionsErrorCode
                    ? this.props.postSelectionsErrorCode
                    : 0;
                if (this.props.isStaffMember) {
                    postSelectionsError = (
                        <span className="form__error personal-details__error">
                            {errorMessages.addMember[code].staff}
                        </span>
                    );
                } else {
                    postSelectionsError = (
                        <span className="form__error personal-details__error">
                            {errorMessages.addMember[code].user}
                        </span>
                    );
                }
            } else if (this.props.attachCallError) {
                let code = this.props.attachCallErrorCode
                    ? this.props.attachCallErrorCode
                    : 0;
                if (this.props.isStaffMember) {
                    postSelectionsError = (
                        <span className="form__error personal-details__error">
                            Attach Call Error:{" "}
                            {errorMessages.attachCall[code].staff.replace(
                                "{ errorcode }",
                                code
                            )}
                        </span>
                    );
                } else {
                    postSelectionsError = (
                        <span className="form__error personal-details__error">
                            {errorMessages.attachCall[code].user.replace(
                                "{ errorcode }",
                                code
                            )}
                        </span>
                    );
                }
            } else if (
                this.props.payments.error ||
                this.props.payments.gatewayError
            ) {
                paymentsErrors = (
                    <span className="form__error personal-details__error">
                        {this.props.payments.gatewayError ||
                            (this.props.payments.error &&
                                this.props.payments.error.message)}
                    </span>
                );
            } else if (
                this.props.bankDetails.valid === false &&
                this.props.bankDetails.validated
            ) {
                // show message
                paymentsErrors = (
                    <span className="form__error personal-details__error">
                        {errorMessages.bankDetails}
                    </span>
                );
            }

            errors = (
                <div className="form__error-zone">
                    {postSelectionsError}
                    {paymentsErrors}
                </div>
            );
        }

        if (this.props.bankDetails.validating) {
            loader = (
                <ul
                    style={{ display: "block" }}
                    className="loader__spinner loader__spinner--small simple-payment__loader"
                >
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            );
        }

        if (isLessons) {
            description = content.lessonsDescription;

            for (var x = 0; x < users.length; x++) {
                var user = users[x];

                if (
                    user.lesson.selectedLessons &&
                    Object.keys(user.lesson.selectedLessons).length
                ) {
                    for (var l in user.lesson.selectedLessons) {
                        selectedLessons.push(
                            <SelectedLesson
                                user={user}
                                key={"user_" + x + l}
                                level={l}
                            />
                        );
                    }
                }
            }

            lessonsTimeout = <LessonsTimeout />;
        } else {
            saveSelections = <SaveSelections />;
        }

        if (
            this.isDiscountCodePanelDisplayed(
                isLessons,
                hasMonthlyPayment,
                hasOneOffPayment,
                promoCodeActive,
                isDeepLinkDiscount
            )
        ) {
            discountCodes = (
                <DiscountCodes
                    content={this.props.content.stage4.promoCodes}
                    onChangePromoTerms={this._onChangePromo}
                    hideCorporateSwitch={
                        1 === this.props.dt && 1 === this.props.d
                    }
                />
            );
        }

        return (
            <div className="container">
                {this.props.staffName && (
                    <h3
                        onClick={this.resetStaffId}
                        className="staff-reset-link"
                    >
                        Applied Staff: {this.props.staffName} (
                        {this.props.staffId}). Reset?
                    </h3>
                )}
                <h1 className="content__title">{content.title}</h1>
                <p className="content__intro">{description}</p>

                <div className="module-container">
                    {selectedLessons}
                    {lessonsTimeout}
                </div>

                <div className="simple-payment">
                    {durationToggle}

                    {/* Concession message */}
                    {users.some(this.props.doesRequireProofOfEligibility) &&
                        this.props.concessionMessage && (
                            <div className="module-container">
                                <div className="module module--inactive">
                                    <Notice
                                        message={this.props.concessionMessage}
                                    />
                                </div>
                            </div>
                        )}

                    {startDate}
                    {discountCodes}
                    {hasMonthlyPayment || hasOneOffPayment || promoCodeActive
                        ? paymentBreakdown
                        : null}

                    <div className="module-container">
                        {directDebits}
                        <PromoCodeTerms />
                    </div>

                    <div className="module-container">
                        <div className="module module--inactive">
                            {saveSelections}
                            <Button {...buttonProps}>{btnText}</Button>
                            {loader}
                            {errors}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

function mapStateToProps(state) {
    return {
        attachCallError: state.lessons.attachCallError,
        attachCallErrorCode: state.lessons.attachCallErrorCode,
        bankDetails: state.bankDetails,
        bankDetailsAllValid: state.selections.bankDetailsAllValid,
        centre: state.centreFinder.selected,
        content: state.app.content,
        settings: state.app.settings,
        concessionMessage: state.app.content.notices.concession.message,
        d: state.selections.duration,
        dt: state.selections.durationType,
        lessons: state.lessons,
        discounts: state.discounts,
        originalMaxStartDate:
            state.selections.maxStartDate &&
            new Date(state.selections.maxStartDate.date),
        payments: state.payments,
        postSelectionsError: state.selections.postSelectionsError,
        postSelectionsErrorCode: state.selections.postSelectionsErrorCode,
        pricing: state.selections.pricing,
        promoTerms: state.selections.promoTerms,
        stageStep: state.stages.showStep,
        staffId: state.staffLogin.staffId,
        startDateOffset: state.selections.startDateOffset,
        startDateIsRenewal: state.selections.startDateIsRenewal,
        totalPrice: state.selections.totalPrice,
        users: state.selections.users,
        isStaffMember: !!state.staffLogin.staffId,
        discountCodes: state.discountCodes,
        isDeepLinkDiscount: !!(state.selections.discount || {})
            .isDeepLinkDiscount,
        staffName: state.staffLogin.staffName,
        steps: state.stages.steps,

        // Type of membership (adult, concession etc)
        // ONLY execute this in joinonline, not lessons online
        doesRequireProofOfEligibility: user =>
            !state.lessons.inView && requiresProofOfEligibility(user)
    };
}

module.exports = connect(mapStateToProps)(Stage4Content);
