var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    state = state || {
        restricted: false,
        restrictedType: null
    };

    switch (action.type) {

        case actionTypes.RESTRICTIONS.RESTRICT:
            return Object.assign({}, state, {
                restricted: action.restricted,
                restrictedType: action.restrictedType
            });

        default:
            return state;
    }
};
