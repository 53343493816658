var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    state = state || {
        staffId: null,
        staffName: null,
        showLogin: false
    };

    switch (action.type) {

        case actionTypes.STAFF.STORE:
            return Object.assign({}, state, {
                staffId: action.id,
                staffName: action.name
            });

        case actionTypes.STAFF.SHOW_LOGIN:
            return Object.assign({}, state, {
                showLogin: action.showLogin
            });

        default:
            return state;
    }
};
