var React           = require('react'), // eslint-disable-line no-unused-vars
    Header          = require('../components/common/header'),
    Component       = require('../components/stage2');

module.exports = [{

    path: '/add-ons',

    stage: true,

    handler: function () {

        return {
            header: <Header showStages={ true } />,
            component: <Component />
        };
    }
}];
