var React           = require('react'),
    connect         = require('react-redux').connect,
    actions         = require('../../actions'),
    Price           = require('../common/price'),
    HeaderPrices;

HeaderPrices = React.createClass({

    componentDidMount() {
        this.processPricingData(this.props);
        this.forceUpdate();
    },

    componentWillReceiveProps(nextProps) {
        this.processPricingData(nextProps);
    },

    processPricingData: function(props) {
        const {d, dt, dispatch, pricing} = props;

        if (pricing && pricing.periods && pricing.periods[0]) {
            if (dt === 0) {
                const month = pricing.periods[0][1];
                const year = pricing.periods[0][12];

                if (d === 1 ) {
                    if (month) {
                        this.monthlyCost = month.total - (month.discountTotal || 0);
                        this.oneOffCosts = month.fees.total;
                    }

                    if (year) {
                        this.oneOffCosts += year.extras.total
                    }
                } else if (d === 12 ) {
                    this.monthlyCost = 0;
                    this.oneOffCosts = 0;
                    
                    if (month) {
                        this.monthlyCost = month.extras.total;
                    }

                    this.oneOffCosts = year.total + year.fees.total;
                }
            } else if (dt === 1) {
                this.monthlyCost = 0;
                this.oneOffCosts = 0;

                for (var index in pricing.periods[dt]) {
                    this.oneOffCosts += pricing.periods[dt][index].fees.total
                        + pricing.periods[dt][index].total
                        + pricing.periods[dt][index].extras.total;
                }

                if (pricing.periods[0] && pricing.periods[0][12]) {
                    this.oneOffCosts += pricing.periods[0][12].total;
                    this.monthlyCost += pricing.periods[0][12].fees.total;
                }
            }

            dispatch(actions.selections.setTotalPrice({
                monthly: this.monthlyCost,
                oneOff: this.oneOffCosts
            }));
            
        }
    },

    render: function () {
        const {pricing, allUsersHaveSelections, content, membershipsSelected} = this.props;

        var displaySmall = false,
            blocks = [];

        if ((!membershipsSelected || !allUsersHaveSelections || !pricing || !pricing.periods || !Object.keys(pricing.periods).length)) {
            blocks.push(
                <div key='price-per-month' className='header-price__block'>
                    <Price />
                    <div className='header-price__meta'>{ content.perMonth }</div>
                </div>
            );

            blocks.push(<span key='price-divider' className='header-price__divider'>&amp;</span>);

            blocks.push(
                <div key='price-one-offs' className='header-price__block'>
                    <Price />
                    <div className='header-price__meta'>{ content.oneOffCosts }</div>
                </div>
            );

            blocks.push(
                <div key='price-per-month-small' className='header-price__block header-price__block--small'>
                    <Price />
                    <div className='header-price__meta'>{ content.perMonth }</div>
                </div>
            );
        } else {

            if (this.props.dt === 0) {
                if (this.props.d === 1 ) {
                    displaySmall = this.monthlyCost > 9999;

                    blocks.push(
                        <div key='price-per-month' className='header-price__block'>
                            <Price denoteZeroAsDash={ true } amount={ this.monthlyCost } />
                            <div className='header-price__meta'>{ content.perMonth }</div>
                        </div>
                    );

                    blocks.push(<span key='price-divider' className='header-price__divider'>&amp;</span>);

                    blocks.push(
                        <div key='price-one-offs' className='header-price__block'>
                            <Price denoteZeroAsDash={ true } amount={ this.oneOffCosts } />
                            <div className='header-price__meta'>{ content.oneOffCosts }</div>
                        </div>
                    );

                    blocks.push(
                        <div key='price-per-month-small' className='header-price__block header-price__block--small'>
                            <Price denoteZeroAsDash={ true } amount={ this.monthlyCost } />
                            <div className='header-price__meta'>{ content.perMonth }</div>
                        </div>
                    );

                } else if (this.props.d === 12 ) {
                    displaySmall = this.oneOffCosts > 9999;

                    if (this.monthlyCost) {
                        blocks.push(
                            <div key='price-per-month' className='header-price__block'>
                                <Price denoteZeroAsDash={ true } amount={ this.monthlyCost } />
                                <div className='header-price__meta'>{ content.perMonth }</div>
                            </div>
                        );

                        blocks.push(<span key='price-divider' className='header-price__divider'>&amp;</span>);
                    }

                    blocks.push(
                        <div key='price-one-offs' className='header-price__block'>
                            <Price denoteZeroAsDash={ true } amount={ this.oneOffCosts } />
                            <div className='header-price__meta'>{ content.oneOffCosts }</div>
                        </div>
                    );

                    blocks.push(
                        <div key='price-per-year-small' className='header-price__block header-price__block--small'>
                            <Price denoteZeroAsDash={ true } amount={ this.oneOffCosts } />
                            <div className='header-price__meta'>{ content.oneOffCosts }</div>
                        </div>
                    );
                }
            } else if (this.props.dt === 1) {
                const pricePeriods = Object.values(pricing.periods[1]);

                this.totalCost = pricePeriods.reduce((total, x) => {
                    return total + x.total;
                }, 0);

                blocks.push(
                    <div key='price-per-month' className='header-price__block'>
                        <Price denoteZeroAsDash={ true } amount={ this.totalCost } />
                        <div className='header-price__meta'>{content.totalPrice}</div>
                    </div>
                );

                blocks.push(
                    <div key='price-per-year-small' className='header-price__block header-price__block--small'>
                        <Price denoteZeroAsDash={ true } amount={ this.totalCost } />
                        <div className='header-price__meta'>{content.totalPrice}</div>
                    </div>
                    );
            }
        }

        return (
            <div className={'header-price' + (displaySmall ? ' header-price--small' : '')}>
                {blocks}
            </div>
        );
    }
});

HeaderPrices.propTypes = {
    content: React.PropTypes.object,
    d: React.PropTypes.number,
    dt: React.PropTypes.number,
    pricing: React.PropTypes.object
};

function mapStateToProps() {
    return {
    };
}

module.exports = connect(mapStateToProps)(HeaderPrices);
