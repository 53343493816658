const { getCommonGroups } = require("../utils/stateSelectors.js");

var debug = require("debug")("actions-selections"),
    actionTypes = require("./actionTypes.js"),
    voucher = require("../utils/voucher"),
    appActions = require("../actions/app.js"),
    discountActions = require("../actions/discounts.js"),
    bankActions = require("../actions/bankDetails.js"),
    userModel = require("../models/user.js"),
    CentreModel = require("../models/centre.js"),
    httpFetch = require("../utils/httpFetch"),
    moment = require("moment"),
    config = require("../config"),
    exports;

module.exports = exports = {
    applyUserData: function applyUserData(data) {
        return {
            type: actionTypes.SELECTIONS.APPLY_USER_DATA,
            users: data.users,
            extras: data.extras
        };
    },

    bankFieldChanged: function bankFieldChanged(userId, field, data) {
        return {
            type: actionTypes.SELECTIONS.CHANGE_BANK_FIELD,
            userId: userId,
            field: field,
            data: data
        };
    },

    promoTermsChanged: function promoTermsChanged(field, data) {
        return {
            type: actionTypes.SELECTIONS.CHANGE_PROMO_TERMS,
            field: field,
            data: data
        };
    },

    changeBankField: function changeBankField(userId, field, value) {
        return function(dispatch) {
            userModel
                .validateBankDetailField(field, value)
                .then(function(result) {
                    dispatch(exports.bankFieldChanged(userId, field, result));
                });
        };
    },

    changePromoTerms: function changePromoTerms(field, value) {
        return function(dispatch) {
            userModel.validatePromoTerms(field, value).then(function(result) {
                dispatch(exports.promoTermsChanged(field, result));
            });
        };
    },

    checkMaskedBankField: function checkMaskedBankField(userId, field, value) {
        return function(dispatch) {
            userModel
                .validateMaskedBankDetailField(field, value)
                .then(function(result) {
                    dispatch(exports.bankFieldChanged(userId, field, result));
                });
        };
    },

    cleanUser: function cleanUser(userID) {
        return {
            type: actionTypes.SELECTIONS.CLEAN_USER,
            userId: userID
        };
    },

    changeData: function changeData(userId, data) {
        return function(dispatch, getState) {
            dispatch(exports.validating(true));

            var state = getState();
            const { selected: centre } = state.centreFinder;

            if (
                state.selections.users.objects &&
                state.selections.users.objects[userId]
            ) {
                var user = JSON.parse(
                        JSON.stringify(state.selections.users.objects[userId])
                    ), // Deep object clone.
                    freeSwimGym = false,
                    validatedData = {},
                    promises = [],
                    facility,
                    infoKey,
                    result,
                    value,
                    key,
                    i;

                for (key in data) {
                    if ("info" === key) {
                        validatedData.info = {};

                        for (infoKey in data.info) {
                            value = data.info[infoKey];

                            for (facility in user.facilities) {
                                if (
                                    facility.toLowerCase().indexOf("fsg") !==
                                        -1 &&
                                    user.facilities[facility].selected === "on"
                                ) {
                                    freeSwimGym = true;
                                }
                            }

                            if ("dob" === infoKey) {
                                user.info[infoKey] = {
                                    value: value,
                                    valid: true,
                                    validationErrors: {}
                                };

                                result = userModel.getAge(
                                    user,
                                    state.selections.duration,
                                    state.selections.durationType
                                );
                                validatedData.info.age = result.age;

                                promises.push(
                                    userModel.validateField(
                                        infoKey,
                                        result.age,
                                        { range: result.ageRange }
                                    )
                                );
                            } else if (
                                "emmergencyName" === infoKey ||
                                "emmergencyPhone" === infoKey
                            ) {
                                promises.push(
                                    userModel.validateEmergencyField(
                                        infoKey,
                                        value,
                                        user
                                    )
                                );
                            } else if ("marketingChannels" === infoKey) {
                                promises.push(
                                    userModel.formatMarketingChannels(
                                        infoKey,
                                        value,
                                        user
                                    )
                                );
                            } else if (
                                freeSwimGym &&
                                "addressPostCode" === infoKey &&
                                !state.lessons.inView
                            ) {
                                promises.push(
                                    userModel.validateFsgField(infoKey, value)
                                );
                            } else {
                                const validate = userModel.validateFieldDynamic(
                                    { key: infoKey, value, centre }
                                );
                                promises.push(validate);
                            }
                        }
                    } else {
                        validatedData[key] = data[key];
                    }
                }

                if (promises.length) {
                    Promise.all(promises).then(function(results) {
                        for (i = 0; i < results.length; i++) {
                            result = results[i];
                            if ("dob" === result.key) {
                                result.value = data.info[result.key];
                            }

                            validatedData.info[result.key] = result;
                        }

                        dispatch(exports.dataValidated(userId, validatedData));
                        dispatch(exports.validating(false));

                        data.callback && data.callback();
                        dispatch(exports.userFetched(null));
                    });
                } else {
                    dispatch(exports.dataValidated(userId, validatedData));
                    dispatch(exports.validating(false));

                    data.callback && data.callback();
                }
            }
        };
    },

    changeDuration: function changeDuration(duration, durationType) {
        return {
            type: actionTypes.SELECTIONS.CHANGE_DURATION,
            duration: duration,
            durationType: durationType
        };
    },

    convertToJuniorMember: function convertToJuniorMember(user) {
        return function(dispatch, getState) {
            var state = getState(),
                siteId = state.centreFinder.selected.info.site_id;

            return {
                type: actionTypes.SELECTIONS.CONVERT_TO_JUNIOR,
                user: user,
                siteId
            };
        };
    },

    dataValidated: function dataValidated(userId, data) {
        return {
            type: actionTypes.SELECTIONS.CHANGE_DATA,
            userId: userId,
            data: data
        };
    },

    fieldValidated: function fieldValidated(userId, field, valid, errors) {
        var info = {};
        info[field] = {
            valid: valid,
            validationErrors: errors
        };

        return {
            type: actionTypes.SELECTIONS.CHANGE_DATA_VALIDITY,
            userId: userId,
            data: {
                info: info
            }
        };
    },

    checkUsers: function checkUsers(callback, onError) {
        const centreFinderActions = require("../actions/centreFinder.js");

        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));

            var url = config.services.memberships.urls.memberLookup,
                state = getState(),
                leadUser = state.selections.users.objects.find(function(u) {
                    return u.lead;
                }),
                promises = [],
                payload;

            promises = state.selections.users.objects.map(function(u) {
                // Forcing validation for logged in users, or users
                //  for whom we've fetched data.
                if (u.info && u.info.mrmId && u.info.mrmId.value) {
                    return Promise.resolve({ memberExists: false });
                }

                payload = {
                    dateOfBirth: u.info.dob.value,
                    firstNames: u.info.firstName.value,
                    lastName: u.info.lastName.value
                };

                // If set to use the lead user's details, do the check with this.
                if (
                    u.useLeadContact &&
                    leadUser &&
                    leadUser.info.addressPostCode &&
                    leadUser.info.addressPostCode.value &&
                    leadUser.info.email &&
                    leadUser.info.email.value &&
                    leadUser.info.telephone &&
                    leadUser.info.telephone.value
                ) {
                    u.info.addressLocality = leadUser.info.addressLocality;
                    u.info.addressPostCode = leadUser.info.addressPostCode;
                    u.info.addressProperty = leadUser.info.addressProperty;
                    u.info.addressRegion = leadUser.info.addressRegion;
                    u.info.addressStreet = leadUser.info.addressStreet;
                    u.info.addressTown = leadUser.info.addressTown;
                    u.info.telephone = leadUser.info.telephone;
                    // Only change their email, if it is missing
                    if (!u.info.email) {
                        u.info.email = leadUser.info.email;
                    }
                }

                return httpFetch.fetch(url, {
                    method: "GET",
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
            });

            Promise.all(promises)
                .then(function(responses) {
                    var foundExistingUsers = false,
                        existingUsers = [],
                        memberExists,
                        usersMatch = false,
                        userClash = false,
                        storeUsers = [],
                        matchedUsers = [],
                        clashedUsers = [],
                        expiredShortUsers = [],
                        i,
                        u;

                    for (i = 0; i < responses.length; i++) {
                        memberExists = responses[i].memberExists;
                        existingUsers[i] = memberExists;

                        if (memberExists) {
                            foundExistingUsers = true;
                        }
                    }

                    if (!foundExistingUsers) {
                        for (
                            u = 0;
                            u < state.selections.users.objects.length;
                            u++
                        ) {
                            var user = state.selections.users.objects[u],
                                userInfo =
                                    state.selections.users.objects[u].info;

                            storeUsers[u] = {
                                firstName: userInfo.firstName.value.toLowerCase(),
                                lastName: userInfo.lastName.value.toLowerCase(),
                                dob: userInfo.dob.value
                            };

                            if (
                                u !== 0 &&
                                storeUsers[u].firstName ===
                                    storeUsers[u - 1].firstName &&
                                storeUsers[u].lastName ===
                                    storeUsers[u - 1].lastName &&
                                storeUsers[u].dob === storeUsers[u - 1].dob
                            ) {
                                usersMatch = true;
                                matchedUsers = storeUsers;
                                break;
                            }

                            if (
                                user.activeSubscriptions &&
                                user.activeSubscriptions.length
                            ) {
                                let clashingSubs = user.activeSubscriptions
                                    .filter(activeSub => {
                                        return user.availableSubscriptions.some(
                                            sub =>
                                                sub.id.toLowerCase() ===
                                                activeSub.SubTypeId.toLowerCase()
                                        );
                                    })
                                    .sort((a, b) => a.EndDate < b.EndDate);

                                // For testing:
                                // clashingSubs = user.activeSubscriptions.map(
                                //     x => ({
                                //         ...x,
                                //         EndDate: '2019-11-06T00:00:00',
                                //         ID: '01EAD'
                                //     })
                                // );

                                console.log(
                                    "Active subscriptions:",
                                    user.activeSubscriptions
                                );

                                if (clashingSubs.length) {
                                    console.info(
                                        "Found active subs matching selected subs:",
                                        clashingSubs
                                    );
                                } else {
                                    console.info(
                                        "No active matching subs found."
                                    );
                                }

                                let expires = null;
                                let expiryOffset = null;

                                if (clashingSubs.length) {
                                    let firstSub = clashingSubs[0];
                                    let now = moment().startOf("day");

                                    expires = moment(firstSub.EndDate).endOf(
                                        "day"
                                    );
                                    expiryOffset = expires.diff(now, "days");

                                    console.log("first sub expiry", expires);
                                }

                                if (
                                    expiryOffset !== null &&
                                    expiryOffset <= 7
                                ) {
                                    console.log(
                                        "using start date",
                                        expires.toLocaleString()
                                    );

                                    let dateOffset = { days: expiryOffset + 1 };
                                    let date = expires
                                        .clone()
                                        .add("days", 1)
                                        .startOf("day")
                                        .format("YYYY-MM-DD");
                                    let isRenewal = true;

                                    dispatch(
                                        exports.setStartDateOffset(
                                            dateOffset,
                                            date,
                                            isRenewal
                                        )
                                    );

                                    dispatch(
                                        centreFinderActions.selectCentre(
                                            state.centreFinder.selected.info
                                                .site_id,
                                            { date: date }
                                        )
                                    );
                                } else {
                                    console.warn("expires > 7 days");
                                }
                            } else {
                                console.info(
                                    "User has no active subscriptions."
                                );
                            }

                            // if(user.activeSubscriptions && user.activeSubscriptions.length) {
                            //     for(var l = 0; l < user.activeSubscriptions.length; l++) {
                            //         for(var m = 0; m < user.availableSubscriptions.length; m++) {
                            //             if(user.activeSubscriptions[l].SubTypeId.toLowerCase() === user.availableSubscriptions[m].id.toLowerCase()) {
                            //                 userClash = true;
                            //                 clashedUsers[0] = storeUsers[u];
                            //             }
                            //         }
                            //     }
                            // }

                            if (user.expiredSubscriptions) {
                                Object.values(user.expiredSubscriptions)
                                    .filter(sub => {
                                        const endDate = moment(sub.EndDate);
                                        const oneDayAgo = moment().subtract(
                                            1,
                                            "days"
                                        );

                                        return endDate > oneDayAgo;
                                    })
                                    .some(sub => {
                                        return user.availableSubscriptions.some(
                                            actSub => {
                                                const isShort = actSub.tags.some(
                                                    tag => tag === "0000-SHORT"
                                                );

                                                if (
                                                    isShort &&
                                                    actSub.id === sub.SubTypeId
                                                ) {
                                                    expiredShortUsers.push({
                                                        firstName:
                                                            user.info.firstName
                                                                .value,
                                                        lastName:
                                                            user.info.lastName
                                                                .value
                                                    });
                                                }
                                            }
                                        );
                                    });

                                // newState.users.objects[action.uid].expiredSubscriptions = action.expiredSubscriptions;
                            }
                        }
                    }

                    if (
                        !foundExistingUsers &&
                        !usersMatch &&
                        !userClash &&
                        !expiredShortUsers.length
                    ) {
                        callback && callback();
                    } else if (foundExistingUsers) {
                        console.error("Found matching existing member");
                        onError && onError();
                    } else if (usersMatch) {
                        console.error("Member has duplicate details");
                        onError && onError();
                    } else if (expiredShortUsers.length) {
                        console.error("Member has expired short membership");
                        onError && onError();
                    } else if (userClash) {
                        console.error(
                            "Member already has the same active subscription"
                        );
                        onError && onError();
                    }

                    dispatch(exports.setExistingUsers(existingUsers));
                    dispatch(exports.setMatchingUsers(matchedUsers));
                    dispatch(exports.setClashedUser(clashedUsers));
                    dispatch(exports.setExpiredShortUsers(expiredShortUsers));

                    dispatch(appActions.showLoader(false));
                })
                .catch(function(e) {
                    debug("error: " + JSON.stringify(e));
                    dispatch(appActions.error(e));
                    dispatch(appActions.showLoader(false));
                });
        };
    },

    setUserExpiredSubscriptions: function setUserExpiredSubscriptions(
        uid,
        expiredSubscriptions
    ) {
        return {
            type: actionTypes.SELECTIONS.SET_USER_EXPIRED_SUBSCRIPTIONS,
            uid,
            expiredSubscriptions
        };
    },

    createDeeplinkUser: function createDeeplinkUser(user) {
        return {
            type: actionTypes.SELECTIONS.CREATE_DEEPLINK_USER,
            user: user
        };
    },

    deeplinkAddons: function deeplinkAddons(addons) {
        return {
            type: actionTypes.SELECTIONS.DEEPLINK_ADDONS,
            addons: addons
        };
    },

    deeplinkGroups: function deeplinkGroups(groups) {
        return {
            type: actionTypes.SELECTIONS.DEEPLINK_GROUPS,
            groups: groups
        };
    },

    deselectExtra: function deselectExtra(tag) {
        return {
            type: actionTypes.SELECTIONS.DESELECT_EXTRA,
            tag: tag
        };
    },

    preselectUsers: function preselectUsers(users) {
        return {
            type: actionTypes.SELECTIONS.PRESELECT_USERS,
            users: users
        };
    },

    considerPreselectedSubscriptionsOnly: function considerPreselectedSubscriptionsOnly(
        toggle
    ) {
        return {
            type:
                actionTypes.SELECTIONS.CONSIDER_PRESELECTED_SUBSCRIPTIONS_ONLY,
            considerPreselectedSubscriptionsOnly: toggle
        };
    },

    preselectSubs: function preselectSubs(preselectedSubs) {
        return {
            type: actionTypes.SELECTIONS.PRESELECT_SUBS,
            preselectedSubs: preselectedSubs
        };
    },

    preselectionSubProcessed: function preselectionSubProcessed() {
        return {
            type: actionTypes.SELECTIONS.PRESELECT_SUBS_PROCESSED,
            preselectionSubProcessed: true
        };
    },

    preloadPartner: function preloadPartner(partner) {
        return function(dispatch, getState) {
            let state = getState();

            if (state.app.tagsBlackList) {
                // partner set so allow partner tags
                let tagsBlackList = state.app.tagsBlackList.filter(
                    x => x !== "0000-PARTNER"
                );

                dispatch(appActions.setTagsBlackList(...tagsBlackList));
            }

            dispatch(appActions.showLoader(true));

            var url = `${config.services.partners.urls.get}/${partner}`;

            return httpFetch
                .fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    dispatch(exports.preselectPartner(partner, response));
                })
                .catch(function(e) {
                    debug("error: " + JSON.stringify(e));
                    console.log(e);
                });
        };
    },

    preselectPartner: function preselectPartner(preselectedPartner, response) {
        return {
            type: actionTypes.SELECTIONS.PRESELECT_PARTNER,
            preselectedPartner: {
                name: preselectedPartner,
                response
            }
        };
    },

    preloadGroup: function preloadGrou(preselectedGroups) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            var url = config.services.subscriptionGroupMappings.urls.get;

            return httpFetch
                .fetch(url, {
                    method: "GET",
                    params: {
                        groups: preselectedGroups
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    dispatch(exports.preselectGroup(response.subsciptions));
                })
                .catch(function(e) {
                    debug("error: " + JSON.stringify(e));
                    console.log(e);
                });
        };
    },

    preselectGroup: function preselectGroup(preselectedSubsByGroups) {
        return {
            type: actionTypes.SELECTIONS.PRESELECT_GROUPS,
            preselectedSubsByGroups: preselectedSubsByGroups
        };
    },
    recalculateExtras: function recalculateExtras(centre, type) {
        return {
            type: actionTypes.SELECTIONS.RECALCULATE_EXTRAS,
            centre: centre,
            extraType: type
        };
    },

    checkExtras: function checkExtras(config) {
        const { duration, success } = config;
        return (dispatch, getState) => {
            const state = getState();
            const { selections } = state;
            const { durationType, extras: selectedExtras, users } = selections;
            const { objects: usersObjects } = users;
            const centreSelected = state.centreFinder.selected;
            const { addons = {}, extras = {}, subscriptions, addOnGroups } = centreSelected;
            const addonExtras = { ...addons, ...extras };

            const extrasDetails = Object.entries(selectedExtras)
                .reduce((sum, [key = '', val = '']) => ({
                    ...sum,
                    [key]: [{
                        id: key,
                        ...({ ...addonExtras }[key] || {}),
                    }, subscriptions[val]]
                }), {});

            const mapGroupsCommon = Object.values(extrasDetails).map(([extra, sub]) => {
                const config = {
                    extraSubGroups: sub.groups,
                    addOnGroups,
                    usersObjects,
                    duration,
                    durationType,
                    groupFilter: 'jad'
                };
                const commonGroups = getCommonGroups(config);
                return [extra.id, commonGroups];
            }).reduce((sum, [key, val]) => ({ ...sum, [key]: val }), {});

            const unavailable = Object.values(extrasDetails)
                .filter(([extra]) => {
                    const commonGroups = mapGroupsCommon[extra.id];
                    return commonGroups && !commonGroups.length;
                })
                .reduce((sum, item) => {
                    const [extra] = item;
                    return { ...sum, [extra.id]: item };
                }, {});

            const unavailableKeys = Object.keys(unavailable);
            const error = !!unavailableKeys.length;
            const successCallback = success ? success :
                (() => dispatch(exports.changeDuration(duration)));
            const result = error ? null : successCallback();

            if (error) {
                const callback = () => {
                    const results = {};
                    Object.values(unavailable).forEach(([extra, sub]) => {
                        const result = dispatch(exports.deselectExtra(extra.id));
                        const key = ['deselectExtra', extra.id].join('.');
                        results[key] = result;
                        Object.keys(usersObjects).forEach(userKey => {
                            const result = dispatch(exports.setExtraForUser(userKey, extra.id, sub.id));
                            const key = ['setExtraForUser', userKey, extra.id, sub.id].join('.');
                            results[key] = result;
                        });
                    });
                    results.success = successCallback();
                    return results;
                };
                // show modal
                dispatch(appActions.showModal('confirmExtrasChange', { unavailable, callback }));
            }

            return {
                type: actionTypes.SELECTIONS.CHECK_EXTRAS,
                unavailable,
                error,
                result
            };
        }
    },

    makeUniqueBankDetails: function makeUniqueBankDetails(userId, value) {
        return {
            type: actionTypes.SELECTIONS.MAKE_UNIQUE_BANK_DETAILS,
            userId: userId,
            value: value
        };
    },

    postSelections: function postSelections(callback) {
        var selectionsActions = require("../actions/selections.js");

        return async function(dispatch, getState) {
            dispatch(appActions.showLoader(true));
            dispatch(exports.postSelectionsError(null));

            var url = config.services.memberships.urls.postSelections,
                state = getState(),
                leadUser = state.selections.users.objects.find(function(u) {
                    return u.lead;
                }),
                users = state.selections.users.objects,
                formattedUser,
                directDebits,
                corpDiscount = "",
                promoDiscount = "",
                userDiscount,
                payload = [],
                key;

            if (state.discounts && state.discounts.discount) {
                if (state.discounts.discount.type !== "code") {
                    corpDiscount = state.discounts.discount.id;
                    userDiscount = corpDiscount;
                } else {
                    // Note that promo codes are case sensitive
                    promoDiscount = state.discounts.discount.id.toUpperCase();
                    userDiscount = promoDiscount;
                }
            }

            for (var i = 0; i < state.selections.users.objects.length; i++) {
                var promoCode = state.selections.users.objects[i].promoCode;

                if (!promoCode || !promoCode.redemptionId) {
                    continue;
                }

                try {
                    await voucher.confirmRedeemVoucher(promoCode.redemptionId);

                    dispatch(
                        selectionsActions.setUserPromoCode({
                            ...promoCode,
                            userId: i,
                            isConfirmRedeemed: true
                        })
                    );
                } catch (e) {
                    debug("error: " + JSON.stringify(e));
                    dispatch(exports.postSelectionsError(e.message));

                    dispatch(appActions.showLoader(false));

                    return null;
                }
            }

            const usersSortedByAge = [...users];
            usersSortedByAge.sort((a, b) => b.info.age - a.info.age);

            payload = {
                startDate: state.selections.currentSelectedStartDate,
                isGenericLesson:
                    (state.lessons && state.lessons.isGeneric) || false,
                isLessonsMembers:
                    (state.lessons && state.lessons.inView) || false,
                reportingTransactionId: state.selections.reportingTransactionId || '',
                members: usersSortedByAge.map(function(u, idx) {
                    // If set to use the lead user's details, use their details
                    if (
                        u.useLeadContact &&
                        !u.lead &&
                        leadUser &&
                        leadUser.info.addressPostCode &&
                        leadUser.info.addressPostCode.value &&
                        leadUser.info.email &&
                        leadUser.info.email.value &&
                        leadUser.info.telephone &&
                        leadUser.info.telephone.value
                    ) {
                        u.info.addressLocality = leadUser.info.addressLocality;
                        u.info.addressPostCode = leadUser.info.addressPostCode;
                        u.info.addressProperty = leadUser.info.addressProperty;
                        u.info.addressRegion = leadUser.info.addressRegion;
                        u.info.addressStreet = leadUser.info.addressStreet;
                        u.info.addressTown = leadUser.info.addressTown;
                        u.info.telephone = leadUser.info.telephone;
                        // Only change their email, if it is missing
                        if (!u.info.email || !u.info.email.value) {
                            u.info.email = leadUser.info.email;
                        }
                    }

                    if (u.useLeadBankDetails && !u.lead) {
                        u.bankDetails.fields.holderName =
                            leadUser.bankDetails.fields.holderName;
                        u.bankDetails.fields.accountNumber =
                            leadUser.bankDetails.fields.accountNumber;
                        u.bankDetails.fields.sortCode =
                            leadUser.bankDetails.fields.sortCode;
                    }

                    if (state.selections.preselectedPartner) {
                        u.info.partner =
                            state.selections.preselectedPartner.name;
                    }

                    formattedUser = userModel.formatUserForAddMember(u, {
                        corporateContactId: corpDiscount,
                        promotionCode: promoDiscount,
                        duration: state.selections.duration,
                        durationType: state.selections.durationType,
                        siteId: state.centreFinder.selected.info.site_id,
                        staffId: state.staffLogin.staffId,
                        startDate: state.selections.currentSelectedStartDate
                    });

                    if (
                        state.selections.durationType === 0 &&
                        state.selections.pricing.periods[
                            state.selections.durationType
                        ] &&
                        state.selections.pricing.periods[
                            state.selections.durationType
                        ][state.selections.duration]
                    ) {
                        directDebits =
                            state.selections.pricing.periods[
                                state.selections.durationType
                            ][state.selections.duration].users[idx];
                    } else if (
                        state.selections.durationType === 1 &&
                        state.selections.pricing.periods[0] &&
                        state.selections.pricing.periods[0][1]
                    ) {
                        directDebits =
                            state.selections.pricing.periods[0][1].users[idx];
                    }

                    const bankDetailsCheck = [
                        state.selections.durationType === 0 &&
                            state.selections.duration === 1,

                        state.selections.durationType === 1 &&
                            Object.keys(u.extras).length,

                        state.selections.duration === 12 &&
                            Object.values(u.extras || {}).find(map =>
                                Object.values(map || {}).find(
                                    item => (item || {}).duration === 1
                                )
                            )
                    ];
                    const bankDetailsRequired = bankDetailsCheck.find(Boolean);

                    if (bankDetailsRequired) {
                        if (
                            u.bankDetails.fields.accountNumber &&
                            u.bankDetails.fields.accountNumber.value !==
                                "undefined"
                        ) {
                            formattedUser.bankDetails = {
                                accountNumber:
                                    u.bankDetails.fields &&
                                    u.bankDetails.fields.accountNumber &&
                                    u.bankDetails.fields.accountNumber.value,
                                sortCode:
                                    u.bankDetails.fields &&
                                    u.bankDetails.fields.sortCode &&
                                    u.bankDetails.fields.sortCode.value,
                                accountName:
                                    u.bankDetails.fields &&
                                    u.bankDetails.fields.holderName &&
                                    u.bankDetails.fields.holderName.value
                            };
                        }
                    }

                    if (
                        state.selections.durationType === 0 ||
                        (state.selections.durationType === 1 &&
                            state.selections.pricing.periods[
                                state.selections.durationType
                            ])
                    ) {
                        if (
                            (state.selections.durationType === 1 &&
                                state.selections.pricing.periods[
                                    state.selections.durationType
                                ]) ||
                            (state.selections.pricing.periods[
                                state.selections.durationType
                            ] &&
                                state.selections.pricing.periods[
                                    state.selections.durationType
                                ][state.selections.duration] &&
                                state.selections.pricing.periods[
                                    state.selections.durationType
                                ][state.selections.duration]
                                    .priceOverridesPerUser[idx])
                        ) {
                            var userPriceOverrides = null;

                            if (state.selections.durationType === 0) {
                                userPriceOverrides =
                                    state.selections.pricing.periods[
                                        state.selections.durationType
                                    ][state.selections.duration]
                                        .priceOverridesPerUser[idx];
                            } else if (state.selections.durationType === 1) {
                                userPriceOverrides =
                                    Object.values(
                                        state.selections.pricing.periods[1]
                                    ).pop().priceOverridesPerUser[idx] || [];
                            }

                            formattedUser.subscriptions.forEach(sub => {
                                sub.priceOverrides = userPriceOverrides
                                    .filter(
                                        po => po.subId === sub.id && !po.ignore
                                    )
                                    .map(override => {
                                        let {
                                            subId,
                                            productId,
                                            overridePriceInPence,
                                            overrideCode
                                        } = override;

                                        return overridePriceInPence
                                            ? {
                                                  subId,
                                                  productId,
                                                  overridePriceInPence
                                              }
                                            : {
                                                  subId,
                                                  productId,
                                                  overrideCode
                                              };
                                    });
                            });
                        }
                    }

                    formattedUser.directDebits = {};
                    if (directDebits) {
                        for (key in directDebits) {
                            if (key !== "now") {
                                formattedUser.directDebits[key] =
                                    directDebits[key];
                            }
                        }
                    }

                    if (u.typeDesc === "Junior") {
                        formattedUser.consentPreferences = {};
                    }

                    if (
                        u.lesson.selectedLessons &&
                        Object.keys(u.lesson.selectedLessons).length
                    ) {
                        formattedUser.lessonBookingRefs = [];

                        for (var l in u.lesson.selectedLessons) {
                            formattedUser.lessonBookingRefs.push(
                                u.lesson.selectedLessons[l].bookingRef
                            );
                        }
                    }

                    return formattedUser;
                })
            };

            return httpFetch
                .fetch(url, {
                    method: "POST",
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    var memberIds = [],
                        member,
                        i;

                    for (i = 0; i < response.members.length; i++) {
                        member = response.members[i];

                        const userIndex = users.indexOf(usersSortedByAge[i]);
                        users[userIndex].service = member.services.onboard; // Check this is in the same order as the response
                        users[userIndex].bankAddress = member.bank;

                        memberIds.push(member.id);

                        dispatch(
                            exports.changeData(userIndex, {
                                info: {
                                    bankAccountReference:
                                        member.bankAccountReference,
                                    mrmId: member.id,
                                    profileUrl: member.profileUrl
                                }
                            })
                        );
                    }

                    if (state.discounts && state.discounts.discount) {
                        dispatch(
                            exports.savePromoTerms(memberIds, userDiscount)
                        );
                    }

                    dispatch(exports.postSelectionsSavePayload(response));

                    return response;
                })
                .then(function(res) {
                    dispatch(bankActions.setPaymentUrl(res.paymentUrl));
                    return res;
                })
                .then(function() {
                    dispatch(appActions.showLoader(false));
                    callback && callback();
                })
                .catch(function(e) {
                    debug("error: " + JSON.stringify(e));
                    dispatch(exports.postSelectionsError(e.message));

                    dispatch(appActions.showLoader(false));
                });
        };
    },

    postSelectionsSavePayload: function postSelectionsSavePayload(response) {
        return {
            type: actionTypes.SELECTIONS.POST_SELECTIONS_SAVE_RESPONSE,
            response
        };
    },

    cancelSelections: function cancelTransaction() {
        return function(dispatch, getState) {
            var url = config.services.memberships.urls.cancelSelections,
                membersResponse = getState().selections.membersResponse,
                members = null;

            if (!membersResponse) {
                return Promise.resolve();
            }

            members = getState().selections.membersResponse.members.map(m => ({
                id: m.id,
                subscriptionReferences: m.subscriptionReferences
            }));

            dispatch(appActions.showLoader(true));

            return httpFetch.fetch(url, {
                method: "POST",
                params: { members },
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            // .then(function(response) {
            //     dispatch(appActions.showLoader(true));
            //     return Promise.resolve("data");
            // });
        };
    },

    postSelectionsError: function postSelectionsError(e) {
        return {
            type: actionTypes.SELECTIONS.POST_SELECTIONS_ERROR,
            error: e
        };
    },

    removeUser: function removeUser(userId) {
        return {
            type: actionTypes.SELECTIONS.REMOVE_USER,
            userId: userId
        };
    },

    setPriceLevelId: function setPriceLevelId(user) {
        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));

            var url = config.services.memberships.urls.setPriceLevel,
                state = getState(),
                centre = state.centreFinder.selected,
                payload = [],
                priceLevel;

            if (user.info.concessionType && user.info.concessionType.value) {
                priceLevel = user.info.concessionType.value;
            } else {
                for (var ageLevel in CentreModel.priceLevelCentres[
                    centre.info.site_id
                ].ageLevels) {
                    if (
                        user.info.age >= ageLevel.min &&
                        user.info.age <= ageLevel.max
                    ) {
                        priceLevel = ageLevel.level;
                    }
                }
            }

            payload = {
                member_id: user.info.mrmId.value,
                price_level: priceLevel
            };

            if (
                centre &&
                centre.info.site_id in CentreModel.priceLevelCentres
            ) {
                return httpFetch.fetch(url, {
                    method: "POST",
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                });
            } else {
                dispatch(appActions.showLoader(false));
            }
        };
    },

    savePromoTerms: function savePromoTerms(memberIds, code) {
        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));
            var url = config.services.promoTerms.urls.post;

            return httpFetch
                .fetch(url, {
                    method: "POST",
                    params: {
                        member_id: memberIds,
                        promocode: code
                    },
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    console.log(response);
                })
                .catch(function(e) {
                    debug("error: " + JSON.stringify(e));
                    console.log(e);
                });
        };
    },

    removeLessonFromUser: function removeLessonFromUser(lesson, userId) {
        return {
            type: actionTypes.SELECTIONS.REMOVE_LESSON_FROM_USER,
            lesson: lesson,
            userId: userId
        };
    },

    renewSelections: function renewSelections(callback) {
        return function(dispatch, getState) {
            dispatch(appActions.showLoader(true));
            dispatch(exports.postSelectionsError(null));

            var url = config.services.memberships.urls.renewSubscriptions,
                state = getState(),
                renewedSubs = state.selections.renewalSubs,
                user = state.user.user,
                subscriptions = [],
                payload = [],
                urlSubs = [],
                i;

            for (i = 0; i < renewedSubs.length; i++) {
                var renewedSub = renewedSubs[i];

                var sub = {
                    saleType: "renewal",
                    subscriptionRef: renewedSub.SubscriptionRef
                };

                subscriptions.push(sub);
                urlSubs.push(renewedSub.SubscriptionRef);
            }

            payload = {
                members: [
                    {
                        id: user.info.mrmId,
                        siteId: user.info.siteId,
                        subscriptions: subscriptions
                    }
                ],
                paymentProvider: "PaymentManager",
                successUrl:
                    config.services.renewals.urls.paymentSuccess +
                    "?subs=" +
                    urlSubs,
                failureURL: config.services.renewals.urls.paymentFailure
            };

            return httpFetch
                .fetch(url, {
                    method: "POST",
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(function(response) {
                    dispatch(appActions.showLoader(false));
                    callback && callback(response);
                })
                .catch(function(e) {
                    debug("error: " + JSON.stringify(e));

                    dispatch(appActions.showLoader(false));
                    dispatch(exports.postSelectionsError(e.message));
                });
        };
    },

    selectExtra: function selectExtra(tag, id) {
        return {
            type: actionTypes.SELECTIONS.SELECT_EXTRA,
            tag: tag,
            id: id
        };
    },

    setClashedUser: function setClashedUser(clashedUsers) {
        return {
            type: actionTypes.SELECTIONS.SET_CLASHED_USERS,
            clashedUsers: clashedUsers
        };
    },

    setExpiredShortUsers: function setExpiredShortUsers(expiredShortUsers) {
        return {
            type: actionTypes.SELECTIONS.SET_EXPIRED_SHORT_USERS,
            expiredShortUsers: expiredShortUsers
        };
    },

    setExistingUsers: function setExistingUsers(existingUsers) {
        return {
            type: actionTypes.SELECTIONS.SET_EXISTING_USERS,
            existingUsers: existingUsers
        };
    },

    setExtraForUser: function setExtraForUser(userId, tag, extraId, value) {
        return {
            type: actionTypes.SELECTIONS.SET_EXTRA_FOR_USER,
            userId: userId,
            tag: tag,
            extraId: extraId,
            value: value
        };
    },

    setFacilityForUser: function setFacilityForUser(userId, facilityId, value) {
        return {
            type: actionTypes.SELECTIONS.SET_FACILITY_FOR_USER,
            userId: userId,
            facilityId: facilityId,
            value: value
        };
    },

    setFacilitySitesForUser: function setFacilitySitesForUser(
        userId,
        facilityId,
        value
    ) {
        return {
            type: actionTypes.SELECTIONS.SET_FACILITY_PERMITTED_SITES_FOR_USER,
            userId: userId,
            facilityId: facilityId,
            value: value
        };
    },

    setFacilityTimesForUser: function setFacilityTimesForUser(
        userId,
        facilityId,
        value
    ) {
        return {
            type: actionTypes.SELECTIONS.SET_FACILITY_PERMITTED_TIMES_FOR_USER,
            userId: userId,
            facilityId: facilityId,
            value: value
        };
    },

    setLessonForUser: function setLessonForUser(userId, subId) {
        return {
            type: actionTypes.SELECTIONS.SET_LESSON_FOR_USER,
            userId: userId,
            subId: subId
        };
    },

    setFirstNameForUser: function setFirstNameForUser(userId, value) {
        return {
            type: actionTypes.SELECTIONS.CHANGE_PERSONAL_DATA,
            userId: userId,
            value: value,
            field: "firstName"
        };
    },

    setLastNameForUser: function setLastNameForUser(userId, value) {
        return {
            type: actionTypes.SELECTIONS.CHANGE_PERSONAL_DATA,
            userId: userId,
            value: value,
            field: "lastName"
        };
    },

    setIsDiscountApplied: function(isDiscountApplied) {
        return {
            type: actionTypes.SELECTIONS.SET_IS_DISCOUNT_APPLIED,
            isDiscountApplied
        };
    },

    setMatchingUsers: function setMatchingUsers(matchingUsers) {
        return {
            type: actionTypes.SELECTIONS.SET_MATCHING_USERS,
            matchingUsers: matchingUsers
        };
    },

    setPromo: function setPromo(promo) {
        return {
            type: actionTypes.SELECTIONS.SET_PROMO,
            promo: promo
        };
    },

    setRenewalSubs: function setRenewalSubs(subs) {
        return {
            type: actionTypes.SELECTIONS.SET_RENEWAL_SUBS,
            subs: subs
        };
    },

    setStaffPromo: function setStaffPromo(promo) {
        return {
            type: actionTypes.SELECTIONS.SET_STAFF_PROMO,
            promo: promo
        };
    },

    setUserDiscountPromo: function setUserDiscountPromo(promo) {
        return {
            type: actionTypes.SELECTIONS.SET_USER_DISCOUNT_PROMO,
            userDiscountPromo: promo
        };
    },

    setStartDateOffset: function setStartDateOffset(
        dateOffset,
        date,
        isRenewal
    ) {
        return {
            type: actionTypes.SELECTIONS.SET_START_DATE_OFFSET,
            dateOffset: dateOffset,
            date: date,
            isRenewal: isRenewal
        };
    },

    setTotalPrice: function setTotalPrice(totalPrice) {
        return {
            type: actionTypes.SELECTIONS.SET_TOTAL_PRICE,
            totalPrice: totalPrice
        };
    },

    setUsers: function setUsers(users, linked, userData) {
        var isLinked = linked || false;

        return {
            type: actionTypes.SELECTIONS.SET_USERS,
            users: users,
            userData: userData,
            isLinked: isLinked
        };
    },

    useLeadContact: function useLeadContact(userId, use) {
        return {
            type: actionTypes.SELECTIONS.USE_LEAD_CONTACT,
            userId: userId,
            use: use
        };
    },

    useLeadEmmergencyDetails: function useLeadEmmergencyDetails(userId, use) {
        return {
            type: actionTypes.SELECTIONS.USE_LEAD_EMMERGENCY_DETAILS,
            userId: userId,
            use: use
        };
    },

    setEmmergencyDetails: function setEmmergencyDetails(userId, value) {
        return {
            type: actionTypes.SELECTIONS.SET_EMMERGENCY_DETAILS,
            userId: userId,
            value: value
        };
    },

    validating: function validating(status) {
        return {
            type: actionTypes.SELECTIONS.VALIDATING,
            validating: status
        };
    },

    useLeadBankDetails: function useLeadBankDetails(userId, value) {
        return {
            type: actionTypes.SELECTIONS.USE_LEAD_BANK_DETAILS,
            userId: userId,
            value: value
        };
    },

    profileSuccess: function profileSuccess(profile) {
        return {
            type: actionTypes.SELECTIONS.PROFILE_RECEIVED,
            profile: profile
        };
    },

    userFetched: function userFetched(user) {
        return {
            type: actionTypes.SELECTIONS.USER_FETCHED,
            fetchedUser: user
        };
    },

    setUserPromoCode: function(options) {
        return function(dispatch, getState) {
            var state = getState(),
                centreActions = require("../actions/centreFinder.js");

            dispatch({
                type: actionTypes.SELECTIONS.SET_USER_PROMO_CODE,
                ...options
            });

            if (!options.code) {
                dispatch(exports.setPromo(null));

                dispatch(exports.setIsDiscountApplied(null));
                dispatch(discountActions.removeDiscount());

                dispatch(
                    centreActions.selectCentre(
                        state.centreFinder.selected.info.site_id,
                        {
                            removeDiscountOnError: true
                        }
                    )
                );
            }

            dispatch({
                type: actionTypes.DISCOUNT_CODES.SET_APPLY_DISABLED,
                applyDisabled: !state.selections.users.objects.some(
                    x => x.promoCode.code
                )
            });
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.SELECTIONS.WIPEOUT
        };
    },

    reportingTransactionStart: function reportingTransactionStart(data) {
        return function(dispatch, getState) {
            const state = getState();
            dispatch(appActions.showLoader(true));
            let { users, isLessons } = data.payload;
            users = users.map((u) => userModel.formatUserForAddMember(u, {
                corporateContactId: '',
                promotionCode: '',
                duration: state.selections.duration,
                durationType: state.selections.durationType,
                siteId: state.centreFinder.selected.info.site_id,
                staffId: state.staffLogin.staffId,
                startDate: state.selections.currentSelectedStartDate
            }));

            return httpFetch.fetch(config.services.app.urls.reportingTransactionStart, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                params: { users, application: (isLessons ? 'lessons' : 'join') }
            }, true)
            .then(function(response) {
                dispatch(exports.setReportingId(response.id));
                dispatch(appActions.showLoader(false));
            })
            .catch(function() {
                dispatch(appActions.showLoader(false));
            });
        };
    },

    markTransactionAsComplete: function markTransactionAsComplete(data) {
        return function(dispatch) {
            dispatch(appActions.showLoader(true));

            const url = config.services.app.urls.reportingTransactionComplete.replace('{id}', data.payload);
            return httpFetch.fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }, true)
            .then(function() {
                dispatch({
                    type: actionTypes.SELECTIONS.POST_REPORTING_AS_COMPLETE
                });
                dispatch(appActions.showLoader(false));
            })
            .catch(function() {
                dispatch(appActions.showLoader(false));
            });
        };
    },

    setReportingId: function setReportingId(data) {
        return {
            id: data,
            type: actionTypes.SELECTIONS.SET_REPORTING_ID
        };
    },
};
