/**
 * These subscriptions are not renewable
 * introducded for Short Term Membersip
 */

module.exports = {
    blacklist: [
        '01T1D',
        '01TWM',
        '01T7D',
    ]
};
