var actionTypes = require('../actions/actionTypes.js');

module.exports = function (state, action) {
    var defaultState = {
        results: [],
        total: 0,
        buttonText: null,
        geoStep: 0,
        currentPage: 0,
        lastPage: null,
        geolocResults: [],
        expanded: null,
        location: {
            lat: null,
            lng: null,
            formattedAddress: ''
        },
        selected: null,
        centreFacilities: [],
        filteredFacilities: [],
        centresData: {},
        lookupCentreByFacilityNameTerm: '',
        lookupFacilityNames: [],
        filteredLookupFacilityNames: [],
    };

    state = state || defaultState;

    switch (action.type) {

        // This adds the data to the centres Data cache.
        case actionTypes.CENTRE_FINDER.CENTRE_DATA_RECEIVED:
            var newData = Object.assign({}, state.centresData),
                newSiteData = { ts: Date.now(), details: action.data };

            newData[action.siteId] = newData[action.siteId] || {};
            newData[action.siteId][action.date] = newData[action.siteId][action.date] || { withDiscount: {}, withoutDiscount: null };

            if (action.discount) {
                newData[action.siteId][action.date].withDiscount[action.discount] = newSiteData;
            } else {
                newData[action.siteId][action.date].withoutDiscount = newSiteData;
            }

            return Object.assign({}, state, {
                centresData: newData
            });

        case actionTypes.CENTRE_FINDER.CENTRE_SELECTED:
            var selectedCentre = action.centre ? Object.assign({}, action.centre) : null,
                isStaff = action.isStaff;

            // remove addons tagged as staff if staff member not logged in
            if (selectedCentre && !isStaff) {
                var filteredAddons = Object.entries(selectedCentre.addons).filter((entry) => {
                    var [key, item] = entry;

                    return item.subs.some((sub) => {
                        return !sub.tags.some((tag) => tag === '0000-STAFF');
                    }) && [];
                });

                if (filteredAddons && filteredAddons.length > 0) {
                    selectedCentre.addons = Object.assign(...filteredAddons.map(([key, val]) => ({[key]: val})));
                }
            }

            return Object.assign({}, state, {
                selected: selectedCentre || null,
            });

        case actionTypes.CENTRE_FINDER.GEOLOC_RESULTS_RECEIVED:
            return Object.assign({}, state, {
                geolocResults: action.geolocResults
            });

        case actionTypes.CENTRE_FINDER.MORE_RESULTS_RECEIVED:
            return Object.assign({}, state, {
                total: action.response.total,
                currentPage: action.response.current_page,
                lastPage: action.response.last_page,
                results: state.results.concat( action.response.data )
            });

        case actionTypes.CENTRE_FINDER.RESET_GEOLOC_RESULTS:
            return Object.assign({}, state, {
                geolocResults: []
            });

        case actionTypes.CENTRE_FINDER.RESULTS_RECEIVED:
            return Object.assign({}, state, {
                total: action.response.total,
                currentPage: action.response.current_page,
                lastPage: action.response.last_page,
                results: action.response.data
            });

        case actionTypes.CENTRE_FINDER.SET_BUTTON_TEXT:
            return Object.assign({}, state, {
                buttonText: action.buttonText,
                geoStep: action.geoStep
            });

        case actionTypes.CENTRE_FINDER.SET_FACILITIES:
            return Object.assign({}, state, {
                centreFacilities: action.facilities
            });

        case actionTypes.CENTRE_FINDER.SET_FILTERED_FACILITIES:
            return Object.assign({}, state, {
                filteredFacilities: action.facilities
            });

        case actionTypes.CENTRE_FINDER.SET_TEXT_OVERRIDES:
            return Object.assign({}, state, {
                textOverrides: action.textOverrides
            });

        case actionTypes.CENTRE_FINDER.SET_LOCATION:
            return Object.assign({}, state, {
                location: {
                    formattedAddress: action.formattedAddress,
                    lat: action.lat,
                    lng: action.lng
                }
            });

        case actionTypes.CENTRE_FINDER.TOGGLE_EXPANDED_CENTRE:
            return Object.assign({}, state, {
                expanded: action.key
            });

        case actionTypes.CENTRE_FINDER.WIPEOUT:
            return Object.assign({}, state, defaultState);

        // Set search term to be used for filtering centres by facility
        // Filter out lookup terms to only include relevant suggestions
        case actionTypes.CENTRE_FINDER.SET_FACILITY_FILTER_SEARCH_TERM:
            return {
                ...state,

                lookupCentreByFacilityNameTerm: action.name,

                filteredLookupFacilityNames: state.lookupFacilityNames
                    .filter( name => new RegExp( `${ action.name }`, 'i' ).test( name ) ),
            };
            break;

        // Clear existing search term to be used for filtering centres by facility
        case actionTypes.CENTRE_FINDER.CLEAR_FACILITY_FILTER_SEARCH_TERM:
            return { ...state, filteredLookupFacilityNames: state.lookupFacilityNames, lookupCentreByFacilityNameTerm: '' };
            break;

        // Set facility search term list for use in lookup autocompletes
        case actionTypes.CENTRE_FINDER.SET_FACILITY_FILTER_TERM_LIST:
            return { ...state, lookupFacilityNames: action.facilities };
            break;

        default:
            return state;
    }
};
