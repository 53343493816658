var debug               = require('debug')('actions-personalDetails'),
    actionTypes         = require('./actionTypes.js'),

    appActions          = require('../actions/app.js'),
    selectionsActions   = require('../actions/selections.js'),
    httpFetch           = require('../utils/httpFetch'),
    config              = require('../config'),

    userModel           = require('../models/user'),

    exports;

module.exports = exports = {

    addressSearch: function addressSearch(uid, search) {
        var url = config.services.gbg.urls.validateAddress;

        return function(dispatch) {
            return httpFetch.fetch(url, {
                method: 'GET',
                params: {
                    address: search
                },
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.addressSuggestionsReceived(uid, response.data));
                dispatch(exports.addressSuggestionsLoading(false));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
                dispatch(appActions.error(e));
                dispatch(exports.addressSuggestionsLoading(false));
            });
        };
    },

    addressSuggestionsLoading: function addressSuggestionsLoading(loading) {
        return {
            type: actionTypes.PERSONAL_DETAILS.ADDRESS_SUGGESTIONS_LOADING,
            loading: loading
        };
    },

    addressSuggestionsReceived: function addressSuggestionsReceived(uid, suggestions) {
        return {
            type: actionTypes.PERSONAL_DETAILS.ADDRESS_SUGGESTIONS_RECEIVED,
            suggestions: suggestions,
            uid: uid
        };
    },

    addressSuggestionSelected: function addressSuggestionSelected(uid, suggestion) {
        return function (dispatch) {
            dispatch(selectionsActions.changeData(uid, {
                info: userModel.normalizeAddressFromGBG(suggestion),
                callback: function() { dispatch(exports.useManualAddressEntry(uid)); }
            }));
        };
    },

    freeze: function freeze(uid, section) {
        return {
            type: actionTypes.PERSONAL_DETAILS.FREEZE,
            section: section,
            uid: uid
        };
    },

    freezeAll: function freezeAll() {
        return {
            type: actionTypes.PERSONAL_DETAILS.FREEZE_ALL
        };
    },

    freezeUser: function freezeUser(uid) {
        return {
            type: actionTypes.PERSONAL_DETAILS.FREEZE_USER,
            uid: uid
        };
    },

    getMedicalConditionsList: function getMedicalConditionsList() {
        return function(dispatch) {
            return httpFetch.fetch(config.services.memberships.urls.getMedicalList, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(function(response) {
                dispatch(exports.setMedicalConditionsList(response));
            })
            .catch(function (e) {
                debug('error: ' + JSON.stringify(e));
            });
        };
    },

    setMedicalConditionsList: function setMedicalConditionsList(response) {
        return {
            type: actionTypes.PERSONAL_DETAILS.SET_MEDICAL_CONDITIONS_LIST,
            conditions: response
        };
    },

    toggleUserLogin: function toggleUserLogin(uid, visible) {
        return {
            type: actionTypes.PERSONAL_DETAILS.TOGGLE_USER_LOGIN,
            uid: uid,
            visible: visible
        };
    },

    unfreeze: function unfreeze(uid, section) {
        return {
            type: actionTypes.PERSONAL_DETAILS.UNFREEZE,
            section: section,
            uid: uid
        };
    },

    unfreezeUser: function unfreezeUser(uid) {
        return {
            type: actionTypes.PERSONAL_DETAILS.UNFREEZE_USER,
            uid: uid
        };
    },

    unfreezeAll: function unfreezeAll() {
        return {
            type: actionTypes.PERSONAL_DETAILS.UNFREEZE_ALL
        };
    },

    useManualAddressEntry: function useManualAddressEntry(uid) {
        return {
            type: actionTypes.PERSONAL_DETAILS.USE_MANUAL_ADDRESS_ENTRY,
            uid: uid
        };
    },

    wipeout: function wipeout() {
        return {
            type: actionTypes.PERSONAL_DETAILS.WIPEOUT
        };
    }
};
